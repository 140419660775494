//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component, } from 'react';
import {  Icon, Image } from 'semantic-ui-react';
import defaultAvatar from '../../content/img/avatar.png'
import { globalCallbacks } from '../../util/callback';

class UserAvatar extends Component 
{
    constructor(props)
    {
        super(props);
        this.state =
        {
          avatarSource:  undefined,
          loadingAvatar: false
        };
    }

    FetchProfilePicture =  async (forceRefetch) =>
    {
      this.setState({ loadingAvatar: true })

      let avatar = undefined

      let locl_key = "_v2_avatar" + (this.props.user ? this.props.user : "current")
      
      try       { avatar = sessionStorage.getItem(locl_key) } 
      catch(_)  { avatar = undefined }

      if( ! avatar || avatar === "undefined" || forceRefetch)
      {
        let res = await window.LR_GetUserAvatarAsync({User: this.props.user ? this.props.user: ""})
        avatar = res && res.Src

        let storageFunction = async () =>  { sessionStorage.setItem(locl_key, avatar) }
        storageFunction();
        
      }
      
      this.setState({ loadingAvatar: false, avatarSource:  avatar})
    }

    componentDidMount = () =>
    {
      this.setUpCallbacks()
      this.FetchProfilePicture();

    }

    componentDidUpdate = (prevProps, prevState) =>
    {
      if(( prevProps.user !== this.props.user ))
      {
        this.FetchProfilePicture(true)
      }
    }

    render = () => 
    {    
      let avatar = defaultAvatar
      if(this.state.avatarSource !== undefined && this.state.avatarSource !=="undefined")
      {
        avatar =  'data:image/png;base64,' + this.state.avatarSource
      }
        return (
            <>
            {this.state.loadingAvatar ? <Icon loading name="spinner"/> : <Image avatar spaced='right' src={avatar} size={this.props.size ? this.props.size : "mini"}></Image>}                
            </>
        );
    }

    setUpCallbacks()
    {
      globalCallbacks.addEventListener("refetch-user-avatar", () => {
        this.FetchProfilePicture(true)
      })
    }
}


export default UserAvatar
