import { Dimmer, Form, Loader, Message } from "semantic-ui-react"
import LRModal from "../Basics/BasicModal"
import React, { useState } from "react"
import LocalizedStrings from "../../localization/AdminResourceManager";

interface returnVal {
    file: File,
    name: string,
    manufacturer: string,
    description: string
}

export function AddNewResourceModal({
    open,
    onOkClick,
    onCancelClick,
    currentCategory,
    uploading,
    requiresFile
}: {
    open: boolean,
    currentCategory: string
    onOkClick: (ret: returnVal) => void,
    onCancelClick: () => void,
    uploading: boolean
    requiresFile?: boolean
}) {
    let [file, setFile] = useState<File>()
    let [name, setName] = useState<string>()
    let [manufacturer, setManufacturer] = useState<string>()
    let [description, setDescription] = useState<string>()
    let [triedOk, setTriedOk] = useState(false)

    const readFile = (e) => {
        let files = e.target.files;
        let fileToUpload = undefined
        for (let file of files) {
            if (file.name.endsWith('.lrwx')) {
                fileToUpload = file
                break;
            }
        }
        setFile(fileToUpload)
    }

    const onOk = () => {
        if (!name || !manufacturer || !description || (requiresFile && !file)) {
            setTriedOk(true)
            return
        } else {
            onOkClick({
                file,
                name,
                manufacturer,
                description
            })
            setFile(undefined)
            setName(undefined)
            setManufacturer(undefined)
            setDescription(undefined)
        }
    }

    const onClose = ()=>{
        setFile(undefined)
        setName(undefined)
        setManufacturer(undefined)
        setDescription(undefined)
        onCancelClick()
    }

    return (
        <LRModal open={open}
            title={LocalizedStrings.UploadResourceWithCategory.replace("$1", currentCategory)}
            onOkClick={onOk}
            onCancelClick={onClose}>
            <Dimmer active={uploading}>
                <Loader />
            </Dimmer>
            <Form>
                <Form.Input name='name' label={LocalizedStrings.Name} value={name} onChange={(_, { value }) => setName(value)} control={Form.Input} error={(triedOk && !name) ? { content: LocalizedStrings.Error_Name } : undefined} />
                <Form.TextArea name='manufacturer' label={LocalizedStrings.Manufacturer} value={manufacturer} onChange={(_, { value }) => setManufacturer(String(value))} control={Form.Input} error={(triedOk && !manufacturer) ? { content: LocalizedStrings.Error_Manufacturer } : undefined} />
                <Form.TextArea name='desc' label={LocalizedStrings.Description} value={description} onChange={(_, { value }) => setDescription(String(value))} control={Form.Input} error={(triedOk && !description) ? { content: LocalizedStrings.Error_Desc } : undefined} />
                {
                    requiresFile ?
                        <>
                            <Form.Input name='file ' type='file' accept=".lrwx" onChange={readFile}/>
                            {(triedOk && !file) ? <Message negative>{LocalizedStrings.Error_File}</Message> : null}
                        </>
                        :
                        null
                }

            </Form>
        </LRModal>
    )
}