//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import LocalizedStrings from "../../localization/SetProperty";
import AlignValuesDouble from "./Components/AlignValueDouble";
import AlignValueColor from "./Components/AlignValueColor";
import LRModal from '../Basics/BasicModal';
import { BASE_UNIT_COLOR, BASE_UNIT_LENGTH } from '../../util/defines';



import { globalCallbacks } from "../../util/callback";


//-----------------------------------------------------------------------------
// The component for the TreeControl
class SetPropertyModal extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
      BaseUnit: BASE_UNIT_LENGTH
    }
    this.child = React.createRef();

  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }

  show = (argument) => 
  {
    let baseUnit = argument.BaseUnit ? argument.BaseUnit : BASE_UNIT_LENGTH
    this.setState( { open : true, BaseUnit: baseUnit, Property: argument.Property, ShowColor: baseUnit === BASE_UNIT_COLOR, Preset: argument.Preset ? argument.Preset : 0, InitValue: argument.InitValue});
  }

  onOkButton = () => 
  {
    this.child.current.OnDefaultButton();
    this.close()
  }

  close = (e) => 
  {
    if (e) { e.stopPropagation(); } 
    this.setState({open : false});
  }
    
  render() 
  {
    let open = this.state.open;
    if(!open) {return <div/>}
    return (
      <LRModal  open={open}
                title={LocalizedStrings.Header}
                onOkClick={this.onOkButton}
                onCancelClick={this.close}
                closeOnDimmerClick={false}>
        {this.GetDisplayComponent()}
      </LRModal>
    )
  }

  GetDisplayComponent = () =>
  {
    let property = this.state.Property;
    let preset = this.state.Preset;
    console.log("Display set property: ", property)

    if (this.state.ShowColor)
    {
      return <AlignValueColor ref={this.child} Property={property} Preset={preset} InitValue={this.state.InitValue}/>
    }
    // TODO:
    // return <SetStringValue ref={this.child} Property={property} Preset={preset}/>
    return <AlignValuesDouble ref={this.child} Property={property} Preset={preset} BaseUnit={this.state.BaseUnit} InitValue={this.state.InitValue}/>
  }

  setUpCallbacks()
  {
    if(this.props.tableView) {
      globalCallbacks.showModalDialogWorksheet = (args) => {
        this.show(args);
      } 
    } else {
      globalCallbacks.showModalDialog = (argument) => 
      { 
        this.show(argument);
      }
    }
  }
}

export default SetPropertyModal