//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Grid, Icon, Select, Form, Table, Segment, Button } from 'semantic-ui-react'
// @ts-ignore
import LocalizedStrings from "../../localization/CalculateTrussCrossSection";
import { BASE_UNIT_ANGLE, BASE_UNIT_AREA, BASE_UNIT_AREA_MOMENT_OF_INERTIA, BASE_UNIT_FORCE, BASE_UNIT_LENGTH, BASE_UNIT_PAGE_LENGTH, BASE_UNIT_STRING, BASE_UNIT_TORQUE, BASE_UNIT_WEIGHT, BASE_UNIT_WEIGHT_PER_DISTANCE } from '../../util/defines';
import LRModal from '../Basics/BasicModal';
import UnitInput from '../Basics/BasicUnitInput';

import { globalCallbacks } from '../../util/callback';
import { radToDeg } from '../../util/defines';

declare const  window: any;


interface CalculateTrussCrossSectionState {
  open: Boolean
  DiameterTube: number,
  Brand: string,
  Code: string,
  Name: string,
  Origin: string,
  TrussType: string,
  WallThicknessTube: number,
  DiameterBraceing: number,
  WallThicknessBraceing: number,
  Width: number,
  Height: number,
  AngleHorizontal: number,
  AngleVertical: number,
  LoadPerDistance: number,
  CountRow: number,
  CountColumn: number,
  SelectedLoadCase: any,
  LengthData: any
  LoadData: any
}

let TRUSS_TYPE_SQUARE = "SQUARE"
let TRUSS_TYPE_TRIANGLE = "TRIANGLE"

let CODE_ANSI = "ANSI"
let CODE_DIN = "DIN"
let CODE_EC = "EUROCODE"

//-----------------------------------------------------------------------------
// The component for the TreeControl
class CalculateTrussCrossSection<CalculateTrussCrossSectionMembers> extends Component<{}, CalculateTrussCrossSectionState> 
{
  public CrossSectionAreaTube : number = 0
  public InertionX : number  = 0
  public InertionY : number  = 0
  public InertionZ : number  = 0
  public Mby : number = 0
  public Vz : number  = 0
  public Ft : number  = 0
  public Fb : number  = 0

  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
      TrussType: TRUSS_TYPE_TRIANGLE,
      DiameterTube: 48,
      WallThicknessTube: 3,
      DiameterBraceing: 22,
      WallThicknessBraceing: 2,
      Width: 287,
      Height: 287,
      LoadPerDistance: 6,
      AngleHorizontal: Math.PI / 4,
      AngleVertical: Math.PI / 4,
      CountRow: 6,
      CountColumn: 2,
      Code: CODE_EC,
      Brand: "",
      Name: "",
      Origin: "",
      LengthData: {},
      LoadData: {},
      SelectedLoadCase: {
        "0":"Distributed Load",
        "1":"Center Load"
      },
    }

    this.CrossSectionAreaTube = 0
    this.InertionX = 0
    this.InertionY = 0
    this.InertionZ = 0

    this.Mby = 0
    this.Vz  = 0
    this.Ft  = 0
    this.Fb  = 0
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }

  show = async() => 
  {
    this.setState( { 
        open : true, 
      });
  }

  close = () => 
  {
    this.setState({open : false});
  }
    
  render() 
  {
    let open = this.state.open;
    if(!open) {return <div/>}

    this.Mby = 0
    this.Vz  = 0
    this.Ft  = 0
    this.Fb  = 0

    return (
      <LRModal  open={open} 
                onCancelClick={this.close} 
                closeOnDimmerClick={false} 
                size="fullscreen"
                scrolling={false}
                title={LocalizedStrings.Header}
                customOKText={LocalizedStrings.Close}
                onOkClick={this.close}>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width="12">
                        <Table>
                        <Table.Header>
                          <Table.Row>
                          <Table.HeaderCell>
                            {LocalizedStrings.Length}
                          </Table.HeaderCell>
                          {[...new Array(this.state.CountColumn)].map((e,i)=>this.renderTableRowHeader(i))}
                          <Table.HeaderCell>
                            <Icon name="plus"  onClick={()=>{this.setState({CountColumn: this.state.CountColumn+1})}}/>
                            <Icon name="minus" onClick={()=>{this.setState({CountColumn: this.state.CountColumn-1})}}/>
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {[...new Array(this.state.CountRow)].map((e,i)=>this.renderTableRow(i))}
                          <Table.Row>
                            <Table.Cell>
                              <Icon name="plus" onClick={()=>{this.setState({CountRow: this.state.CountRow+1})}}/>
                              <Icon name="minus" onClick={()=>{this.setState({CountRow: this.state.CountRow-1})}}/>
                            </Table.Cell>
                            {[...new Array(this.state.CountColumn)].map(()=><Table.Cell></Table.Cell>)}
                            <Table.Cell></Table.Cell>
                          </Table.Row>
                        </Table.Body>
                        </Table>
                       
                      </Grid.Column>
                      <Grid.Column width="4">
                        <Segment>
                        <Button positive fluid onClick={this.exportToXML}>Export to XML</Button>

                        <Form>
                        <Select 
                                    compact
                                    fluid
                                    options = {[
                                      {
                                        text: "Square",
                                        value: TRUSS_TYPE_SQUARE,
                                        key: TRUSS_TYPE_SQUARE
                                      },
                                      {
                                        text: "Triangle",
                                        value: TRUSS_TYPE_TRIANGLE,
                                        key: TRUSS_TYPE_TRIANGLE
                                      },
                                    ]}
                                    value = {this.state.TrussType}
                                    onChange = {(event, {value})=>{ this.setState({TrussType: String(value)} )}}/>
                        <Select 
                                    compact
                                    fluid
                                    options = {[
                                      {
                                        text: "DIN",
                                        value: CODE_DIN,
                                        key: CODE_DIN
                                      },
                                      {
                                        text: "Eurocode",
                                        value: CODE_EC,
                                        key: CODE_EC
                                      },
                                      {
                                        text: "ANSI",
                                        value: CODE_ANSI,
                                        key: CODE_ANSI
                                      },
                                    ]}
                                    value = {this.state.Code}
                                    onChange = {(event, {value})=>{ this.setState({Code: String(value)} )}}/>
                        <UnitInput label="Brand" baseUnit = {BASE_UNIT_STRING} value={this.state.Brand} onStateUpdate = {(name, value) => { this.setState({Brand:value}) }} />
                        <UnitInput label="Name" baseUnit = {BASE_UNIT_STRING} value={this.state.Name} onStateUpdate = {(name, value) => { this.setState({Name:value}) }} />
                        <UnitInput label="Origin" baseUnit = {BASE_UNIT_STRING} value={this.state.Origin} onStateUpdate = {(name, value) => { this.setState({Origin:value}) }} />
                        <UnitInput label="Height" baseUnit = {BASE_UNIT_PAGE_LENGTH} value={this.state.Height} onStateUpdate = {(name, value) => { this.setState({Height:value}) }} />
                        <UnitInput label="Width" baseUnit = {BASE_UNIT_PAGE_LENGTH} value={this.state.Width} onStateUpdate = {(name, value) => { this.setState({Width:value}) }} />
                        
                        <UnitInput label="LoadPerDistance" baseUnit = {BASE_UNIT_WEIGHT_PER_DISTANCE} value={this.state.LoadPerDistance} onStateUpdate = {(name, value) => { this.setState({LoadPerDistance:value}) }} />

                        <UnitInput label="DiameterTube" baseUnit = {BASE_UNIT_PAGE_LENGTH} value={this.state.DiameterTube} onStateUpdate = {(name, value) => { this.setState({DiameterTube:value}) }} />
                        <UnitInput label="WallThicknessTube" baseUnit = {BASE_UNIT_PAGE_LENGTH} value={this.state.WallThicknessTube} onStateUpdate = {(name, value) => { this.setState({WallThicknessTube:value}) }} />
                        <UnitInput label="DiameterBraceing" baseUnit = {BASE_UNIT_PAGE_LENGTH} value={this.state.DiameterBraceing} onStateUpdate = {(name, value) => { this.setState({DiameterBraceing:value}) }} />
                        <UnitInput label="WallThicknessBraceing" baseUnit = {BASE_UNIT_PAGE_LENGTH} value={this.state.WallThicknessBraceing} onStateUpdate = {(name, value) => { this.setState({WallThicknessBraceing:value}) }} />

                        <UnitInput label="AngleHorizontal" baseUnit = {BASE_UNIT_ANGLE} value={this.state.AngleHorizontal} onStateUpdate = {(name, value) => { this.setState({AngleHorizontal:value}) }} />
                        <UnitInput label="AngleVertical" baseUnit = {BASE_UNIT_ANGLE} value={this.state.AngleVertical} onStateUpdate = {(name, value) => { this.setState({AngleVertical:value}) }} />
                        </Form>
                        </Segment>
                        <Segment inverted color='green'>
                          {this.renderResultInertion()}
                        </Segment>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  
      </LRModal>
    )
  }

  renderResultInertion()
  {
    //----------------------------------------------------------------------------------------------------
    let Ag = Math.PI * 0.25 * (Math.pow(this.state.DiameterTube, 2) - Math.pow(this.state.DiameterTube - 2 * this.state.WallThicknessTube, 2))
    let I  = Math.PI * 1/64 * (Math.pow(this.state.DiameterTube, 4) - Math.pow(this.state.DiameterTube - 2 * this.state.WallThicknessTube, 4))

    let ey = this.state.Width - this.state.DiameterTube
    let ez = this.state.Height - this.state.DiameterTube
    
    //----------------------------------------------------------------------------------------------------
    let factorAg = 1
    let factorI  = 4
    if(this.state.TrussType === TRUSS_TYPE_TRIANGLE)
    {
      factorAg = 0.5
      factorI  = 3
    }

    let Iy = Ag * Math.pow(ez, 2) * factorAg + factorI * I
    let Iz = Ag * Math.pow(ey, 2) * factorAg + factorI * I
    
    //----------------------------------------------------------------------------------------------------
    let A_b = Math.PI * 0.25 * (Math.pow(this.state.DiameterBraceing, 2) - Math.pow(this.state.DiameterBraceing - 2 * this.state.WallThicknessBraceing, 2))
    let alpha = Math.tan(this.state.AngleVertical) * ez
    let E = 69
    let G = 26.5

    let te = E / G *                                                              (ez * alpha 
                                                                                      / 
                  (Math.pow(ez, 3) * 
                                     (1 / (A_b * Math.pow(Math.sin(this.state.AngleVertical), 3))+ 
                                      1 / (Ag  * Math.pow(Math.tan(this.state.AngleVertical), 3)))))
    
    let Ix = Math.pow(ez, 3) * te
    let Area = 4

    if(this.state.TrussType === TRUSS_TYPE_TRIANGLE)
    {
      Ix = 0.25 * Math.pow(ez, 3) * te
      Area = 3
    }

    
    this.CrossSectionAreaTube = Ag*Area
    this.InertionX = Ix / Math.pow(10, 4)
    this.InertionY = Iy / Math.pow(10, 4)
    this.InertionZ = Iz / Math.pow(10, 4)

    return(
      <>
        <Form>
          <UnitInput label="CrossSectionAreaTube" baseUnit = {BASE_UNIT_AREA} value={Ag*Area}  />
          <UnitInput label="InertionTube" baseUnit = {BASE_UNIT_AREA_MOMENT_OF_INERTIA} value={I / Math.pow(10, 4)}  />
          <UnitInput label="InertionX" baseUnit = {BASE_UNIT_AREA_MOMENT_OF_INERTIA} value={Ix / Math.pow(10, 4)}  />
          <UnitInput label="InertionY" baseUnit = {BASE_UNIT_AREA_MOMENT_OF_INERTIA} value={Iy / Math.pow(10, 4)}  />
          <UnitInput label="InertionZ" baseUnit = {BASE_UNIT_AREA_MOMENT_OF_INERTIA} value={Iz / Math.pow(10, 4)}  />
        </Form>

      </>
    )
  }

  renderTableRowHeader(r:Number)
  {
    let options = [
      {
        text: "Distributed Load",
        value: "Distributed Load",
        key: "Distributed Load"
      },
      {
        text: "Center Load",
        value: "Center Load",
        key: "Center Load"
      },
    ]

    let value = this.state.SelectedLoadCase[""+r]

    if(value === undefined)
    {
      value = options[0].value
    }

    return<>
    <Table.HeaderCell>
      <>
        <Select 
                                    compact
                                    search
                                    options = {options}
                                    value = {value}
                                    onChange = {(event, {value})=>{ this.setState({SelectedLoadCase:{...this.state.SelectedLoadCase, [""+r]: value} })}}/>

      </>
    </Table.HeaderCell>
    </>
  }

  renderTableRow(r:number)
  {
    return<>
    <Table.Row>
    {[...new Array(this.state.CountColumn+1)].map((e, i)=>this.renderTableColumn(r, i))}
    <Table.Cell></Table.Cell>
    </Table.Row>
    </>
  }

  renderTableColumn(r:number,c:number)
  {
    if(c === 0)
    {
      let value = this.state.LengthData[""+ r]
      if(value === undefined)
      {
        value = 0
      }
      return(
    <Table.Cell>
      <Form>
      <UnitInput label="Length" baseUnit = {BASE_UNIT_LENGTH} value={value} onStateUpdate = {(name, value: any) => { this.setState({LengthData: {...this.state.LengthData, [""+r]:value }}) }} />
      </Form>
    </Table.Cell>
    )

    }

    let Length = this.state.LengthData["" +r]
    let Load   = this.state.LoadData["" +r+"_"+c]

    let columnLoadCase = c-1
    let isLineLoad = false
    let loadCase   = this.state.SelectedLoadCase[""+columnLoadCase]
    if(loadCase === undefined || loadCase === "Distributed Load")
    {
      isLineLoad = true
    }



    if(Length === undefined)
    {
      Length = 0
    }
    if(Load === undefined)
    {
      Load = 0
    }

    let E = this.state.LoadPerDistance

    let F = Load * 9.81
    let V = 0
    let M = 0

    if(isLineLoad)
    {
      V = F * Length / 2 + E * Length / 2
      M = F * Math.pow(Length, 2) / 8 + E * 9.81 * Math.pow(Length, 2) / 8
    }
    else
    {
      V = F / 2 + E * Length / 2
      M = F * Length * 0.25 + E * 9.81 * Math.pow(Length, 2) / 8
    }





    let Ft = M / 2 / (this.state.Height - this.state.DiameterTube)
    let Fb = V / 2 / Math.sin(this.state.AngleVertical)

    let factor = 1
    if(this.state.Code == CODE_EC)
    {
      factor = 1.5
    }

    this.Mby = Math.max(this.Mby, factor * M  / Math.pow(10, 6))
    this.Vz  = Math.max(this.Vz, factor * V / Math.pow(10, 6))
    this.Ft  = Math.max(this.Ft, factor * M / 2 / (this.state.Height - this.state.DiameterTube)/ Math.pow(10, 6))
    this.Fb  = Math.max(this.Fb, factor * V / 2 / Math.sin(this.state.AngleVertical)/ Math.pow(10, 6))

    return(
    <Table.Cell>
      <Segment>
      <Form>
        <UnitInput label="Load" baseUnit = {isLineLoad ? BASE_UNIT_WEIGHT_PER_DISTANCE :  BASE_UNIT_WEIGHT} value={Load} onStateUpdate = {(name, value: any) => { this.setState({LoadData: {...this.state.LoadData, ["" +r+"_"+c]:value }}) }} />
      </Form>
      </Segment>
      <Segment inverted color='green'>
      <Form>
      <UnitInput label="Moment" baseUnit = {BASE_UNIT_TORQUE} value={M  / Math.pow(10, 6)}  />
      <UnitInput label="MomentEurocode" baseUnit = {BASE_UNIT_TORQUE} value={1.5 * M  / Math.pow(10, 6)}  />
      <UnitInput label="ForceTube" baseUnit = {BASE_UNIT_FORCE} value={Ft  / Math.pow(10, 6)}  />
      <UnitInput label="ForceTubeEurocode" baseUnit = {BASE_UNIT_FORCE} value={1.5 * Ft  / Math.pow(10, 6)}  />
      <UnitInput label="ShearForce" baseUnit = {BASE_UNIT_FORCE} value={V / Math.pow(10, 6)}  />
      <UnitInput label="ShearForceEurocode" baseUnit = {BASE_UNIT_FORCE} value={1.5 * V / Math.pow(10, 6)}  />
      <UnitInput label="ForceBracing" baseUnit = {BASE_UNIT_FORCE} value={Fb  / Math.pow(10, 6)}  />
      <UnitInput label="ForceBracingEurocode" baseUnit = {BASE_UNIT_FORCE} value={1.5 * Fb  / Math.pow(10, 6)}  />
      </Form>
      </Segment>
    </Table.Cell>
    )
  }

  setUpCallbacks()
  {
    globalCallbacks.ShowCalculateTrussData = async () => 
    {       
      this.show();
    } 
  }
  exportToXML = () =>
  {
    window.LR_WriteCrossSection(
      {
        "AngleBracingHorizontal": radToDeg(this.state.AngleHorizontal) ,
        "AngleBracingVertical": radToDeg(this.state.AngleVertical),
        "BracingDesign": 2, // Circular
        "BracingDiameter": this.state.DiameterBraceing,
        "Brand": this.state.Brand,
        "CalculationCode": this.state.Code == CODE_EC ? 0: 2,
        "ChordDiameter": this.state.DiameterTube,
        "CrossDectionAreaX": this.CrossSectionAreaTube,
        "DeadLoadPerDistance": this.state.LoadPerDistance,
        "Default": true,
        "DeflectionLimit": 180.0,
        "Design": this.state.TrussType === TRUSS_TYPE_TRIANGLE ? 4 : 3,
        "ForceBracingHorizontal": 0.0,
        "ForceBracingVertical": this.Fb,
        "ForceChords": this.Ft,
        "FtmXX": this.InertionX,
        "FtmYY": this.InertionY,
        "FtmZZ": this.InertionZ,
        "Hight": this.state.Height,
        "Internal": true,
        "LinkedMaterial": "EN-AW-6082-T6",
        "Mby": this.Mby,
        "Mbz": 0.0,
        "Mxx": 0.0,
        "Name": this.state.Name,
        "Nx": 0.0,
        "Origen": this.state.Origin,
        "Vy": 0,
        "Vz": this.Vz,
        "WallthicknessBracing": this.state.WallThicknessBraceing,
        "WallthicknessTube": this.state.WallThicknessTube,
        "Width": this.state.Width,
      }
    )
  }
}

export default CalculateTrussCrossSection