
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component, createRef } from 'react';
import { Table, Grid, Menu, Sticky, Message, Icon, Input } from "semantic-ui-react";
import LocalizedStrings from "../../../localization/LightingPaperwork";
import ColorInputField from '../../ColorPicker/ColorInputField';
import {escapeRegExp} from "../../../util/defines";

import { globalCallbacks } from "../../../util/callback";

class PresetList extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = 
        {
            presets         : [],
            activePreset    : "",

            searchText      : "",
            searchOptions   : [],
            searchResults   : [],
        };

        this.contextRef = createRef();
    }

    componentDidMount = () => 
    {
        this.setUpCallbacks();

        if(globalCallbacks.getAllPresetsContent)
        {
            globalCallbacks.getAllPresetsContent();
        }
    }

    componentWillUnmount = () => 
    {
        this.takeDownCallbacks();
    }

    render() 
    {
        return (
            <Grid columns={2}>
                <Grid.Column width={2}>
                    <div style={{height: '100%'}} ref={this.contextRef}>
                    <Sticky context={this.contextRef}>
                        <Menu fluid secondary vertical>
                            <Input
                                icon        = "search"
                                fluid
                                placeholder = "Search..."
                                value       = {this.state.searchText}
                                onChange    = {this.onChangeSearch}/>
                            {
                                this.state.searchResults.map(preset =>
                                {
                                    return(
                                        <Menu.Item  key         = {preset.UUID}
                                                    active      = {preset.UUID === this.state.activePreset}
                                                    onClick     = {() => this.onPresetClicked(preset)}>
                                                        
                                            {preset.title}
                                        </Menu.Item>
                                    );
                                })
                            }
                        </Menu>
                    </Sticky>
                    </div>
                </Grid.Column>
                <Grid.Column width={14}>
                    {
                        this.state.presets.map((preset) =>
                        {
                            return(this.renderPreset(preset));
                        })
                    }
                    {
                        this.state.presets.length > 0 ? null : <Message warning><Icon name='warning'/>{LocalizedStrings.NoPreset}</Message>
                    }
                </Grid.Column>
            </Grid>

        );
    }

    renderPreset = (preset) =>
    {
        if(!preset) { return null; }

        return(<div id={preset.UUID} key={preset.UUID} style={{marginTop: "0.5em", marginLeft: "0.5em"}}>
            <Table fixed size="small" compact striped>
            <Table.Header>
                <Table.Row>
                <Table.HeaderCell>{preset.Name}</Table.HeaderCell>
                <Table.HeaderCell>{LocalizedStrings.Property}</Table.HeaderCell>
                <Table.HeaderCell>{LocalizedStrings.Value}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {this.renderPresetContent(preset.Content, preset.HasValue)}
                </Table.Body>
            </Table></div>
        )
    }

    renderPresetContent = (content, hasValue) =>
    { 

        return(
        <React.Fragment>
            {
                content.map(object =>
                {
                    return(
                        Object.entries(object.Properties).map(key => 
                        {
                            return this.renderEntry(object.Object, key[0], key[1])
                        })
                    )
                })
            }
            {
                hasValue ? null : <Table.Row> <Table.Cell>{LocalizedStrings.NoContent}</Table.Cell> </Table.Row>
            }
        </React.Fragment>)
    }

    renderEntry = (object, propertyName, value) =>
    {
        if(!object.FixtureId) { return null; }
        return(
        <Table.Row>
            <Table.Cell>{object.FixtureId + " " + object.Name}</Table.Cell>
            <Table.Cell>{propertyName}</Table.Cell>
            <Table.Cell>
                {
                    propertyName === "LightColor" ? this.renderColorField(propertyName, value)
                    : Object.entries(value).map(([key, val]) => {
                        if (val instanceof Object)
                        {
                            return null;
                        }
                        return <div key={key}>{val}</div>
                    })
                }
            </Table.Cell>
        </Table.Row>)
    }

    renderColorField = (propertyName, item) =>
    {
        return(
            <ColorInputField    name            = {propertyName}
                                readOnly
                                size            = "mini"
                                label           = ""
                                colorX          = {item[propertyName+"X"]} 
                                colorY          = {item[propertyName+"Y"]} 
                                colorL          = {item[propertyName+"Z"]}/>
        );
    }

    onChangeSearch = (_, {value}) =>
    {
        let searchResults = this.state.searchOptions;

        if(value)
        {
            const re = new RegExp(escapeRegExp(value), 'i')
            const isMatch = (result) => re.test(result.title);

            searchResults = this.state.searchOptions.filter(isMatch);

            //Avoid crash
            if(searchResults.length === 0) { searchResults.push([]); }
        }

        this.setState({ searchText : value, searchResults });
    }

    onPresetClicked = (preset) =>
    {
        let jumpHere = preset.UUID;
        window.location.href = window.location.origin + window.location.search + "#" + jumpHere;
        this.setState({activePreset : preset});
    }

    setUpCallbacks()
    {
        globalCallbacks.getAllPresetsContent = async () =>
        {
            let presets = await window.LR_GetAllPresetsContent();

            let searchOptions = presets.map(preset =>
            {
                return({
                    UUID    : preset.UUID,
                    title   : preset.Name,
                })
            })

            let searchResults = [...searchOptions];

            this.setState({presets, searchOptions, searchResults});
        }
    }

    takeDownCallbacks()
    {
        globalCallbacks.getAllPresetsContent = undefined;
    }


}

export default PresetList;

