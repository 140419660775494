
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Table, Icon, Popup } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/NavigationContainer";
import { addNotification } from '../NotificationDisplay/NotificationDisplay';

import { globalCallbacks as mainCB } from '../../util/callback';
import { globalCallbacks as mockCB } from '../../util/mock_callback';
import { EMPTY_UUID, SELECT_Single } from '../../util/defines';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB

class DrawingErrorNode extends Component 
{
    render() 
    {
        return (
            <Table.Row>
                <Table.Cell width={2} collapsing>
                    <span style={{paddingLeft:"1.5rem"}}>{this.props.j}.{this.props.i}</span>
                </Table.Cell>
                 <Table.Cell width={1} collapsing>
                    <Popup content={LocalizedStrings.HighlightError}
                    trigger={
                    <Icon  link name    = "selected radio"
                            onClick = {() => this.onHighlight()}
                            />
                    }/>
                </Table.Cell>
                <Table.Cell>
                    {this.props.entry.Message}
                </Table.Cell>
            </Table.Row>
        );
    }

    onHighlight = () =>
    {
        if(this.props.entry.Object !== EMPTY_UUID || this.props.entry.Objects.length> 0)
        {
            let request = 
            {
                Selected      : true,
                SelectionMode : SELECT_Single,
                SelectionGroupMode : 0,
                ShiftKey      : false,
                MetaKey       : false,
                AltKey        : false,
                ZoomToSelection        : true,
                SelectedList  : this.props.entry.Objects.length> 0 ?this.props.entry.Objects : [this.props.entry.Object]
            }
        
            window.LR_Select(request);
        }
        
        if(globalCallbacks.zoomToPoint)
        {
            globalCallbacks.zoomToPoint(this.props.entry.Position)
        }
        else
        {
            addNotification(LocalizedStrings.ZoomToPointNotSupported, LocalizedStrings.ZoomToPointNotSupportedText, false, false, true)
        }
        
    }


}

export default DrawingErrorNode;

