
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Search, Table, Label, Icon, Segment, Checkbox, Popup } from 'semantic-ui-react'
import LayerNode from "./LayerNode";
import LocalizedStrings from "../../localization/NavigationContainer";
import { EMPTY_UUID, DEFAULT_LAYER_UUID, IsElectronContext } from '../../util/defines';

import { globalCallbacks as mainCB } from '../../util/callback';
import { globalCallbacks as mockCB } from '../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB


const SORT_BY = "Name"

class LayerTable extends Component 
{
    constructor(props)
    {
        super(props);

        this.draggedUUID = null;
        this.state = 
        {
            layers  : [],

            // search
            value    : "",

            sorting: null,
            indexMap: [],
            selected:{},
            allSelected:{},
            multiVisible: true,
            multiOnlyVisible: true,
            multiDontCalc: true
        };

    }

    componentDidMount = () => 
    {
        this.setUpCallbacks();
        globalCallbacks.getNavigationLayers();
        globalCallbacks.updateActiveLayer()
    }

    componentWillUnmount = () => 
    {
        this.takeDownCallbacks();
    }

    render() 
    {        
        return (
            <div style={{width:"100%", height:"100%"}}>
                <Table style={{borderBottom: "none", margin:0}}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan='3' style={{zIndex: 0}}>
                                <Icon link style={{zIndex: 1, floated: 'left', position: 'absolute'}}  onClick={this.onHeaderClick} name={this.state.sorting === null ? "sort" : this.state.sorting === "ascending" ? "sort alphabet up": "sort alphabet down"}/>
                                <Search open    = {false}
                                        loading = {this.state.isLoading}
                                        value   = {this.state.value}
                                        onSearchChange = {(e, {value}) => this.onSearchChange(value)}
                                        aligned = "right"/>
                            </Table.HeaderCell>
                        </Table.Row>
                        
                        <Table.Row>
                            <Table.HeaderCell collapsing> <Checkbox checked={this.hasSelection()} onChange={(e)=>{this.onAllClick()}} /></Table.HeaderCell>
                            <Table.HeaderCell><p>{LocalizedStrings.SelectedLayers}</p></Table.HeaderCell>
                            <Table.HeaderCell collapsing>
                                <Popup content={LocalizedStrings.Lock} 
                                       trigger={<Icon name ={this.state.multiLock ? "lock": "lock open"} link onClick = {() => {window.LR_SetLayers({LayerObjects: this.getNodes("Locked")}); this.setState({multiLock:!this.state.multiLock})}} />}/>
                                <Popup content={LocalizedStrings.Visibility}
                                       trigger={<Icon name ={this.state.multiVisible ? "eye" : "eye slash"} link onClick={() =>{ window.LR_SetLayers({LayerObjects: this.getNodes("Visible")}); this.setState({multiVisible:!this.state.multiVisible})}} />}/>
                                <Popup content={LocalizedStrings.OnlyVisibility}
                                       trigger={<Icon name ={this.state.multiOnlyVisible ? "eye slash outline" : "eye"} color={this.state.multiOnlyVisible ? "grey" : "green"} link onClick = {() => {window.LR_SetLayers({LayerObjects: this.getNodes("OnlyVisible")}); this.setState({multiOnlyVisible:!this.state.multiOnlyVisible})}} />}/>
                                <Popup content={LocalizedStrings.NotApplyLayerActivation} 
                                       trigger={<span><Icon name = "check circle" disabled /></span>}/>
                                <Popup content={LocalizedStrings.NotApplyAssemblySheetCreationFromLayers}
                                       trigger={<span><Icon name = "file outline" disabled/></span>}/>
                                <Popup content={LocalizedStrings.Visibility}
                                       trigger={<Icon name="pause circle" color ={this.state.multiDontCalc ? "red" : "grey"} link onClick={() =>{ window.LR_SetLayers({LayerObjects: this.getNodes("DontUseForCalculation")}); this.setState({multiDontCalc:!this.state.multiDontCalc})}} />}/>

                                <Popup content={LocalizedStrings.Delete}
                                       trigger={<Icon name = "delete" color   = "red" link onClick = {() => window.LR_DeleteLayers({UUIDList: this.getSelectedLayerNodes()})} />}/>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                </Table>
                
                <div style={{ width:"100%", maxHeight: IsElectronContext() ? "calc(100vh - 30em)" :"calc(100vh - 35em)", overflowY:"auto", overflowX: this.props.small ? undefined : "clip", marginBottom:"5em" }}>
                    <Table
                        striped
                        structured
                        compact='very'
                        size="small"
                    >
                        
                        <Table.Body>  
                            {
                                // window.LR_ChangeLayerOrder(this.draggedUUID)
                                this.getSortedLayers().map((layer,idx) => 
                                    (layer.Name.toLowerCase().includes(this.state.value.toLowerCase())) && (
                                        <LayerNode
                                            draggable={this.state.sorting === null}
                                            onDragStart={()=> this.draggedUUID = layer.UUID }
                                            onDrop={ ()=> window.LR_ChangeLayerOrder({UUID:this.draggedUUID,Index:idx })} 
                                            onSelectLayerNode={this.onSelectLayerNode}
                                            checked={this.state.selected[layer.UUID]}
                                            small={this.props.small}
                                            key={layer.UUID}
                                            layer={layer}
                                            activeUuid={this.state.activeLayer}
                                        />
                                    )  
                                )
                            }
                        </Table.Body>

                    </Table>
                </div>

                <Segment vertical textAlign="center" style={{position:"absolute", bottom: IsElectronContext() ? "1.5em" : "6em", width:"100%", border:"none"}}>
                    <Label as="a" color="green" onClick={this.onAddNewLayer}>
                        <Icon name="plus"/>{LocalizedStrings.AddNewLayer}
                    </Label>
                </Segment>
            </div>
        );
    }

    onHeaderClick = () => {
        let newVal = null
        switch (this.state.sorting) {
            case null:          newVal = "ascending";   break;
            case "ascending":   newVal = "descending";  break;
            default:            newVal = null;          break;
        }
        this.setState({
            sorting: newVal
        })
    }

    getSortedLayers = () =>
    {
        let showData =this.state.layers
        if(this.state.sorting) 
        {
            let indexMap = []
            showData.forEach((_, i) => indexMap.push(i))
            indexMap.sort((a,b) => showData[a][SORT_BY] === showData[b][SORT_BY]  ? 0 : (showData[a][SORT_BY]  < showData[b][SORT_BY]  ? -1 : 1))
            
            showData =  indexMap.map((_, i) => {
                let calcIndex = this.state.sorting === 'ascending' ? i : indexMap.length - i - 1
                return showData[indexMap[calcIndex]]
            })
        }
        return showData
    }

    onSearchChange = (value) => 
    {
      this.setState({value: value})

    }


    setUpCallbacks()
    {
        globalCallbacks.getNavigationLayers = async () =>
        {
            let ret = await window.LR_GetLayers();
            let layers = ret.Layers;

            this.setState({layers});
        }

        globalCallbacks.updateActiveLayer = async () =>
        {
            let res = await window.LR_GetActiveLayer()

            if(res.Layer === EMPTY_UUID)
            {
                res.Layer = DEFAULT_LAYER_UUID
            }

            this.setState({activeLayer: res.Layer})
        }
    }

    takeDownCallbacks()
    {
        globalCallbacks.getNavigationLayers = undefined;
    }

    onAddNewLayer = () =>
    {
        window.LR_AddNewLayer({});
    }

    hasSelection = () =>
    {
        let hasSelection = false
        Object.keys(this.state.selected).every((key) => 
        {
            hasSelection = hasSelection || this.state.selected[key]
            return !hasSelection
        })
        return hasSelection
    }

    onAllClick = () =>
    {
        let selectedValue = this.hasSelection()
        let selected      = {}
        
        this.getSortedLayers().forEach((layer) => 
        {
            selected[layer.UUID] =!selectedValue
        })
        this.setState({selected})
    }

    getSelectedLayerNodes = () =>
    {
        let layerNodes = []
        this.getSortedLayers().forEach((layer) => 
        {
            if(this.state.selected[layer.UUID])
            {
                layerNodes.push(layer.UUID)
            }
        })

        return layerNodes
    }

    getNodes = (name) =>
    {
        let layerNodes = []
        this.getSortedLayers().forEach((layer) =>
        {
            if(this.state.selected[layer.UUID])
            {
                let layerNode   = {}
                layerNode.UUID  = layer.UUID
                layerNode[name] = !layer[name]
                layerNodes.push(layerNode)
            }
        })

        return layerNodes
    }

    onSelectLayerNode = (layerUUID, value) =>
    {
        this.setState({selected: {...this.state.selected, [layerUUID]: value}})
    }

}

export default LayerTable;

