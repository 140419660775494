//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Tab, Menu, Icon, Form, Select, Checkbox } from 'semantic-ui-react';

import LocalizedStrings from "../../../localization/TableViewComponent";
import { BASE_UNIT_DATE, BASE_UNIT_POWER, SELECT_Single } from '../../../util/defines';
import LRTableHeaderCell from '../../Basics/BasicTableView';
import UnitInput from '../../Basics/BasicUnitInput';
import DynamicTable from '../../Basics/DynamicTableView';

import { globalCallbacks as mainCB } from '../../../util/callback';
import { globalCallbacks as mockCB } from '../../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB
class ElectricalList extends Component
{
    constructor(prop)
    {
        super(prop);
        this.state = 
        {
            generators:[],
            departmentOptions: [],
            showOnlyTrueGenerators: false,
            activeIndex: 0
        }        

        this.totalHeaderCells = [   {ident: "Department", label: LocalizedStrings.Department,sortable: false},
                                    {ident: "Name", label: LocalizedStrings.NameIdentifier,sortable: true},
                                    {ident: "AssemblyGroupName", label: LocalizedStrings.AssemblyGroupName,sortable: true},
                                    {ident: "Count", label: LocalizedStrings.Count,sortable: true},
                                    {ident: "Load", label: LocalizedStrings.Load, unit: BASE_UNIT_POWER,sortable: true},
                                    {ident: "TotalLoad", label: LocalizedStrings.TotalLoad, unit: BASE_UNIT_POWER,sortable: true},
                                    {ident: "Generator", label: LocalizedStrings.Generator,sortable: true},
                                    {ident: "NeededFrom", label: LocalizedStrings.NeededFrom, unit: BASE_UNIT_DATE,sortable: true},
                                    {ident: "NeededTo", label: LocalizedStrings.NeededTo, unit: BASE_UNIT_DATE,sortable: true}]

        this.generatorHeaderCells = [{ident: "NameIdentifier", label: LocalizedStrings.NameIdentifier,sortable: true},
                                    {ident: "AssemblyGroupName", label: LocalizedStrings.AssemblyGroupName, sortable: true},
                                    {ident: "Load", label: LocalizedStrings.Load, unit: BASE_UNIT_POWER, sortable: true},
                                ]
    }

    componentDidMount()
    {
        this.setUpCallbacks();
        globalCallbacks.UpdateGenerators()
        this.updateDepartmentOptions()
    }

    render()
    {
        let panes = [
            { menuItem: <Menu.Item key={0}><Icon name="power cord"/>{LocalizedStrings.TotalLoadGenerator}</Menu.Item>},
            ...this.state.generators.map((element, i) => ({ menuItem: <Menu.Item key={0}><Icon name="power off"/>{element.NameIdentifier}</Menu.Item>}))
        ]

        return (
            <>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
                    <Tab style={{overflowX: "scroll", marginRight: 10}} panes={panes} menu={{ text: true }} activeIndex={this.state.activeIndex} onTabChange={(e, d) =>this.setState({activeIndex: d.activeIndex})}/>
                    <div style={{
                        flexShrink: "0",
                        display: "flex", 
                        flexDirection: "row", 
                        alignItems: "center"
                        }}>  
                        <div style={{marginRight: 5}} data-tooltip={LocalizedStrings.ShowAllTopLevelObjects_tooltip} data-position="bottom center">{LocalizedStrings.ShowAllTopLevelObjects}</div>
                        <Checkbox toggle value={this.state.showOnlyTrueGenerators} onClick={()=>{
                            this.setState({showOnlyTrueGenerators: !this.state.showOnlyTrueGenerators}, ()=>{
                                globalCallbacks.UpdateGenerators?.()
                            })
                        }}/>
                        <div style={{marginLeft: 5}} data-tooltip={LocalizedStrings.ShowOnlyDistributors_tooltip} data-position="bottom right">{LocalizedStrings.ShowOnlyDistributors}</div>
                    </div>
                </div>
                {this.state.activeIndex === 0 ? this.renderTotalList() : this.renderGenerator(false, this.state.generators[this.state.activeIndex - 1])}
            </>
        )
    }

    updateLoad = (name, value, numberGenerator, numberConsumer) =>
    {
        let newGenerators = this.state.generators.map((g,i) =>
            {
                if(i !== numberGenerator) {return g }
                return {
                    ...g,
                    Grouped_Consumer: g.Grouped_Consumer.map((c, j) =>
                    {
                        if(j !== numberConsumer) {return c }
                        return {
                            ...c,
                            [name] : value
                        }
                    })
                }
            })
        this.setState({generators: newGenerators})  
    }

    renderTotalList()
    {
        let generatorsCellIdents = this.totalHeaderCells.map(e => ({...e}))
        let foundGenerator = generatorsCellIdents.findIndex(val => val.ident === "Generator")
        if (foundGenerator !== -1)
        {
            generatorsCellIdents.splice(foundGenerator, 1)
        }
        let departmentCellIdents = this.totalHeaderCells.map(e => ({...e}))

        let departmentCellRendering = (entry, rowIndex) =>
        {
            return {
                Department: <Select options={this.state.departmentOptions}
                                    multiple
                                    value = {entry.Consumer.LinkedDepartmentsObjects.map(depObj => {
                                        return depObj.UUID
                                    })}
                                    onChange={this.updateDepartments(entry)}/>,
                Name: <Form.Input 
                                    value={entry.Consumer.Name}
                                    transparent
                                    onChange={(e, {value}) => { this.updateLoad("Name", value, entry.GeneratorIndex, entry.ConsumerIndex); }}
                                    onBlur={()=>{window.LR_SetMultipleObjects(entry.Consumer.UUIDS.map(uuid => ({UUID: uuid, Name: entry.Consumer.Name})))}}
                                    onKeyDown={(e) => {if(e.keyCode === 13) { e.target.blur()}}}/>,
                AssemblyGroupName: entry.Consumer.AssemblyGroupName,
                Count: entry.Consumer.CountDrawing,
                Load: <UnitInput    value         = {entry.Consumer.Load} 
                                    label         = {false}
                                    baseUnit      = {BASE_UNIT_POWER}
                                    cellChild
                                    transparent
                                    noStepper
                                    onStateUpdate = {(_, value) => {this.updateLoad("Load", value, entry.GeneratorIndex, entry.ConsumerIndex); }}
                                    onUpdateData  = {(_, value) => {window.LR_SetDefiningResourceGeometry({ObjectUUID: entry.Consumer.UUIDS[0], GeometryUUID: entry.Consumer.LoadGeoUUID, ElectricalPayLoad: value})}}/>,
                TotalLoad: <UnitInput   value    = {entry.Consumer.TotalLoad} 
                                        label    = {false}
                                        baseUnit = {BASE_UNIT_POWER}
                                        transparent
                                        readOnly/>,
                Generator: entry.Generator.NameIdentifier,
                NeededFrom: <UnitInput  value={entry.Consumer.NeededStart} 
                                        baseUnit={BASE_UNIT_DATE} 
                                        onStateUpdate={(_, value) => {window.LR_SetMultipleObjects(entry.Consumer.UUIDS.map(uuid => ({UUID: uuid, AvailableStart: value})))}}/>,
                NeededTo:   <UnitInput  value        = {entry.Consumer.NeededEnd} 
                                        baseUnit     = {BASE_UNIT_DATE} 
                                        onStateUpdate = {(_, value) => {window.LR_SetMultipleObjects(entry.Consumer.UUIDS.map(uuid => ({UUID: uuid, AvailableEnd: value})))}}/>,
            }
        }

        let generatorCellRendering = (entry, rowIndex) =>
        {
            return {
                Department:  <Select options={this.state.departmentOptions}
                                    multiple
                                    value = {entry.LinkedDepartmentsObjects.map(depObj => {
                                        return depObj.UUID
                                    })}
                                    onChange={this.updateDepartments(entry)}/>,
                Name: entry.NameIdentifier,
                AssemblyGroupName: entry.AssemblyGroupName,
                Count: null,
                Load: <UnitInput  value    = {entry.ElectricalConnections[0].CurrentConsumption}
                                    baseUnit = {BASE_UNIT_POWER}
                                    readOnly
                                    transparent
                                    label    = {false}/>,
                TotalLoad: null,
                Generator: null,
                NeededFrom: <UnitInput  value={entry.AvailableStart} 
                                        baseUnit={BASE_UNIT_DATE} 
                                        onStateUpdate={(_, value) => {window.LR_SetObject({UUID: entry.UUID, AvailableStart: value})}}/>,
                NeededTo: <UnitInput  value        = {entry.AvailableEnd} 
                                      baseUnit     = {BASE_UNIT_DATE} 
                                      onStateUpdate = {(_, value) => {window.LR_SetObject({UUID: entry.UUID, AvailableEnd: value})}}/>,
            }
        }

        let patchedData = []
        let desiredData = []
        this.state.generators.forEach((generator, genIndex) => {
            generator.Grouped_Consumer.forEach((consumer, conIndex) => {
                patchedData.push({Generator: generator, GeneratorIndex: genIndex, Consumer: consumer, ConsumerIndex: conIndex, editable: true})
            })

            generator.Grouped_Desired_Consumer.forEach((consumer, conIndex) => {
                desiredData.push({Generator: generator, GeneratorIndex: genIndex, Consumer: consumer, ConsumerIndex: conIndex, editable: true})
            })

        })

         

        let filterData = (data => 
        {
            return data.filter(value => {
                // Filter the departments
                let getValue = (header) =>
                {
                    switch (header.ident)
                    {
                    case "Department":
                    {
                        let result = ""
                        for (let department of value.Consumer.LinkedDepartmentsObjects)
                        {
                            result += department.Name
                        }
                        return result
                    }
                    case "Count": return value.Consumer.CountDrawing
                    case "Generator": return value.Generator.NameIdentifier
                    case "NeededFrom": return value.Consumer.NeededStart
                    case "NeededTo": return value.Consumer.NeededEnd
                    default: return value.Consumer[header.ident]
                    }
                }
    
                for (let header of departmentCellIdents)
                {
                    let value = getValue(header)
                    
                    if (value && value.toString().toLowerCase().includes(this.props.searchFilter.toLowerCase()))
                    {
                        return true;
                    }
                }
                return false;
            })

        })

       patchedData = filterData(patchedData)
       desiredData = filterData(desiredData)

        let filteredGenerators = this.state.generators.filter(value => {
            let getValue = (header) =>
            {
                switch (header.ident)
                {
                case "Count": return value.CountDrawing
                case "Department":
                {
                    let result = ""
                    for (let department of value.LinkedDepartmentsObjects)
                    {
                        result += department.Name
                    }
                    return result
                }
                case "Load": return value.ElectricalConnections[0].Consumption
                case "NeededFrom": return value.AvailableStart
                case "NeededTo": return value.AvailableEnd
                default: return value[header.ident]
                }
            }
            for (let header of departmentCellIdents)
            {
                let value = getValue(header)
                
                if (value && value.toString().toLowerCase().includes(this.props.searchFilter.toLowerCase()))
                {
                    return true;
                }
            }
            return false;
        })

        filteredGenerators = filteredGenerators.map(gen => ({...gen, editable: true}))

        return <>
            <div style={{zIndex:10, position:"relative"}}>
            <h2>{LocalizedStrings.PatchedConsumers}</h2>
            <DynamicTable headerIdent={departmentCellIdents}
                          cellRender={departmentCellRendering}
                          sortFunction={this.sortDepartmentCells}
                          headerData={this.props.headerData}
                          tableName="ElectricalList_Dep"
                          pages={this.props.pages}
                          showPages={this.props.showPages}
                          fitColumnWidthToContent={this.props.showPages}
                          worksheet={this.props.worksheet}
                          cellData={patchedData}
                          printMargin={this.props.printMargin}
                          printScale={this.props.worksheetScale}/>
            <h2>{LocalizedStrings.DesiredConsumers}</h2>
            <DynamicTable headerIdent={departmentCellIdents}
                          cellRender={departmentCellRendering}
                          sortFunction={this.sortDepartmentCells}
                          headerData={this.props.headerData}
                          tableName="ElectricalList_Des"
                          pages={this.props.pages}
                          showPages={this.props.showPages}
                          fitColumnWidthToContent={this.props.showPages}
                          worksheet={this.props.worksheet}
                          cellData={desiredData}
                          printMargin={this.props.printMargin}
                          printScale={this.props.worksheetScale}/>
            </div>    
            <h2>{LocalizedStrings.SummeryGenerators}</h2>     
            <DynamicTable headerIdent={generatorsCellIdents}
                          cellRender={generatorCellRendering}
                          sortFunction={this.sortGeneratorCells}
                          headerData={this.props.headerData}
                          tableName="ElectricalList_Gen"
                          pages={this.props.pages}
                          showPages={this.props.showPages}
                          fitColumnWidthToContent={this.props.showPages}
                          worksheet={this.props.worksheet}
                          cellData={filteredGenerators}
                          printMargin={this.props.printMargin}
                          printScale={this.props.worksheetScale}/>
        </>
    }

    updateDepartments = (entry) => (_,{value}) => {
        if(entry.Consumer) {
            window.LR_SetMultipleObjects(entry.Consumer.UUIDS.map((uuid) => ({UUID: uuid, LinkedDepartments: value})))
        } else {
            window.LR_SetObject({UUID:entry.UUID, LinkedDepartments: value})
        }
    }

    renderGenerator(totalLoadList, element)
    {
        let total = 0
        let total2 = 0

        if (element.Electrical_Consumer)
        {
            element.Electrical_Consumer.forEach(consumer => 
            {
                total += consumer.ElectricalConnections[0].Consumption
            })
        }

        if (element.Electrical_DesiredConsumer)
        {
            element.Electrical_DesiredConsumer.forEach(consumer => 
            {
                total2 += consumer.ElectricalConnections[0].Consumption
            })

            
        }



        let generatorCellRendering = (entry, rowIndex) =>
        {
            return {
                NameIdentifier: <span style={{cursor: "pointer"}} onClick={()=>{this.selectObjects([entry.UUID])}}>{entry.NameIdentifier}</span>,
                AssemblyGroupName: entry.AssemblyGroupName,
                Load: <UnitInput  baseUnit = {BASE_UNIT_POWER}
                                    value    = {entry.ElectricalConnections[0].Consumption}
                                    label    = {false}
                                    transparent
                                    readOnly/>,
            }
        }
        let footer = <>
            <LRTableHeaderCell/>
            <LRTableHeaderCell>
                {LocalizedStrings.Total}
            </LRTableHeaderCell>
            <LRTableHeaderCell>
                <UnitInput  baseUnit={BASE_UNIT_POWER}
                            value = {total}
                            label = {false}
                            transparent
                            readOnly/>
            </LRTableHeaderCell>
        </>

        let footer2 = <>
        <LRTableHeaderCell/>
        <LRTableHeaderCell>
            {LocalizedStrings.Total}
        </LRTableHeaderCell>
        <LRTableHeaderCell>
            <UnitInput  baseUnit={BASE_UNIT_POWER}
                        value = {total2}
                        label = {false}
                        transparent
                        readOnly/>
        </LRTableHeaderCell>
        </>

        return <>
            <h2>{LocalizedStrings.PatchedConsumers}</h2>
            <DynamicTable headerIdent={this.generatorHeaderCells}
                            cellRender={generatorCellRendering}
                            sortFunction={this.sortGeneratorCells}
                            headerData={this.props.headerData}
                            tableName="ElectricalList_Gen"
                            pages={this.props.pages}
                            worksheet={this.props.worksheet}
                            cellData={element.Electrical_Consumer}
                            footerRow={footer}
                            />                
            <h2>{LocalizedStrings.DesiredConsumers}</h2>
            <DynamicTable headerIdent={this.generatorHeaderCells}
                            cellRender={generatorCellRendering}
                            sortFunction={this.sortGeneratorCells}
                            headerData={this.props.headerData}
                            tableName="ElectricalList_Des"
                            pages={this.props.pages}
                            worksheet={this.props.worksheet}
                            cellData={element.Electrical_DesiredConsumer}
                            footerRow={footer2}
                            />
            </>
    }

    
    async updateDepartmentOptions() {
        let res = await window.LR_GetDepartments()
        this.setState({departmentOptions: res.Departments.map((dep) => ({key: dep.UUID, value: dep.UUID, text: dep.Name}))})
    }

    setUpCallbacks()
    {
        globalCallbacks.addEventListener('department-changed', () => {
            this.updateDepartmentOptions()
        })
        globalCallbacks.addEventListener('object-change-complete', () => {
            globalCallbacks.UpdateGenerators()
        })
        globalCallbacks.UpdateGenerators = () => {
            window.LR_GetGenerators({onlyTrueGenerators: this.state.showOnlyTrueGenerators ?? false}).then(res => {
                // Transform the object so we have a grouped overview
                let generators = res.Generators.map(gen => {
                    gen.Grouped_Consumer = []
                    gen.Grouped_Desired_Consumer = []
                    for(let consumer of gen.Electrical_Consumer){
                        let found_group = gen.Grouped_Consumer.find(v => v.Name === consumer.Name)
                        if (found_group)
                        {
                            found_group.CountDrawing++;
                            found_group.UUIDS.push(consumer.UUID)
                            found_group.TotalLoad = found_group.Load * found_group.CountDrawing;
                        }
                        else
                        {
                        let load = consumer.ElectricalConnections[0].Consumption
                        gen.Grouped_Consumer.push({
                            ...consumer, 
                            CountDrawing: 1, 
                            Load: load, 
                            LoadGeoUUID: consumer.ElectricalConnections[0].OwnWire, 
                            TotalLoad: load, 
                            NeededStart: consumer.AvailableStart, 
                            NeededEnd: consumer.AvailableEnd, 
                            UUIDS: [consumer.UUID]})
                        }
                    }

                    for(let consumer of gen.Electrical_DesiredConsumer){
                        let found_group = gen.Grouped_Desired_Consumer.find(v => v.Name === consumer.Name)
                        if (found_group)
                        {
                            found_group.CountDrawing++;
                            found_group.UUIDS.push(consumer.UUID)
                            found_group.TotalLoad = found_group.Load * found_group.CountDrawing;
                        }
                        else
                        {
                            let load = consumer.ElectricalConnections[0].Consumption
                            gen.Grouped_Desired_Consumer.push({
                                ...consumer, 
                                CountDrawing: 1, 
                                Load: load, 
                                LoadGeoUUID: consumer.ElectricalConnections[0].OwnWire, 
                                TotalLoad: load, 
                                NeededStart: consumer.AvailableStart, 
                                NeededEnd: consumer.AvailableEnd, 
                                UUIDS: [consumer.UUID]})
                        }
                    }

                    return gen
                })

                this.setState({
                    generators: generators, 
                    activeIndex: (this.state.activeIndex > generators.length + 1) ? 0 : this.state.activeIndex // reset index if list is shorter
                })
            })    
        }
    }

    selectObjects = (array) =>
    {
        let request = 
        {
            Selected      : true,
            SelectionMode : SELECT_Single,
            SelectionGroupMode : globalCallbacks.selectionGroupMode,
            ShiftKey      : false,
            MetaKey       : false,
            AltKey        : false,
            SelectedList  : array
        }

        window.LR_Select(request);
    }

    sortDepartmentCells = (objectA, objectB, propertyIdentifer) => 
    {
        if (propertyIdentifer=== "Count") 
        {
            return {First:  objectA.Consumer.CountDrawing, 
                    Second: objectB.Consumer.CountDrawing }
        }
        if (propertyIdentifer=== "Generator") 
        {
            return {First:  objectA.Generator.NameIdentifier, 
                    Second: objectB.Generator.NameIdentifier }
        }
        if (propertyIdentifer=== "NeededFrom") 
        {
            return {First:  objectA.Consumer.NeededStart, 
                    Second: objectB.Consumer.NeededStart }
        }
        if (propertyIdentifer=== "NeededTo") 
        {
            return {First:  objectA.Consumer.NeededEnd, 
                    Second: objectB.Consumer.NeededEnd }
        }
        else 
        {
            return {First: objectA.Consumer[propertyIdentifer], Second: objectB.Consumer[propertyIdentifer]}
        }
    }

    sortGeneratorCells = (objectA, objectB, propertyIdentifer) => 
    {
        if (propertyIdentifer=== "Name") 
        {
            return {First:  objectA.NameIdentifier, 
                    Second: objectB.NameIdentifier }
        }
        if (propertyIdentifer=== "Load") 
        {
            return {First:  objectA.ElectricalConnections[0].Consumption, 
                    Second: objectB.ElectricalConnections[0].Consumption }
        }
        if (propertyIdentifer=== "NeededFrom") 
        {
            return {First:  objectA.AvailableStart, 
                    Second: objectB.AvailableStart }
        }
        if (propertyIdentifer=== "NeededTo") 
        {
            return {First:  objectA.AvailableEnd, 
                    Second: objectB.AvailableEnd }
        }
        else 
        {
            return {First: objectA[propertyIdentifer], Second: objectB[propertyIdentifer]}
        }
    }
}

export default ElectricalList;