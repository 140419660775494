//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component, useReducer, useContext, createContext } from 'react';
import { Grid, Tab} from 'semantic-ui-react'
import LocalizedStrings from "../../../localization/AlignDialog";
import UnitInput from "../../Basics/BasicUnitInput";

import { degToRad, } from'../../../util/defines';

//-----------------------------------------------------------------------------
const initialState = 
{
  value1: 0, 
  value2: 1,
  type:   0
};

let currentState =
{
  ...initialState
}

function reducer(state, action) 
{
  let newState = state;

  switch (action.type) 
  {
    case 'V1':
    {
      newState            = {...state, value1: action.value};
      currentState.value1 = action.value;
      break;
    }
    case 'V2':
    {
      newState            = {...state, value2: action.value};
      currentState.value2 = action.value;
      break;
    }
    case 'type':
    {
      newState          = {...state, type: action.value};
      currentState.type = action.value;
      break;
    }
    default: break;
  }
  return newState
}

const useEditData = () =>
{
  const [state, dispatch] = useReducer(reducer, initialState);
  return [state, dispatch];
}

const EditContext = createContext();




//-----------------------------------------------------------------------------
class AlignValues extends Component 
{   
  render() 
  {
    
    return(<ProviderData BaseUnit={this.props.BaseUnit}/>);
  }

  OnDefaultButton = () =>
  {
    let arg = {};

    let property = this.props.Property;
    //TODO take GeometryTransform Properties into account
    if(property === "Rx" || property === "Ry" || property === "Rz")
    {
      arg = 
      {
        ...currentState,
        value1: degToRad(currentState.value1),
        value2: degToRad(currentState.value2),
        Property: property};
    } else
    {
      arg = {...currentState, Property: property, Preset: this.props.Preset};
    }

    console.log("Align values: ", arg)
    window.LR_SetAlignedValue(arg);
  }

}

//-----------------------------------------------------------------------------
function ProviderData(props)
{     
  return (
    <EditContext.Provider value={useEditData()}>  
      <TabControl {...props}/>
    </EditContext.Provider>
)
}
//-----------------------------------------------------------------------------
function TabControl(props)
{     
  const  [state, dispatch]  = useContext(EditContext);

    
  //-----------------------------------------------------------------------------

  const panes = 
  [
    { menuItem: LocalizedStrings.Distribute, render: () => 
      <Tab.Pane >
        <DistributeComponent BaseUnit = {props.BaseUnit}/>
      </Tab.Pane> 
    },
    { menuItem: LocalizedStrings.Alternate,  render: () => 
      <Tab.Pane >
        <AlternateComponent BaseUnit = {props.BaseUnit}/>
      </Tab.Pane> 
    },
    { menuItem: LocalizedStrings.Increment,  render: () => 
      <Tab.Pane >
        <IncrementComponent BaseUnit = {props.BaseUnit}/>
      </Tab.Pane> 
    },
    {
      menuItem: LocalizedStrings.Set,  render: () => 
      <Tab.Pane >
        <SetComponent BaseUnit = {props.BaseUnit}/>
      </Tab.Pane> 
    },
  ]

  return (
    <EditContext.Provider value={useEditData()}>  
      <Tab  panes={panes} 
            onTabChange={(e, { activeIndex }) => {dispatch({type: "type", value: activeIndex })}}
            activeIndex = {state.type}/>
    </EditContext.Provider>
)
}

//-----------------------------------------------------------------------------
function AlternateComponent(props) 
{   
  const  [state, dispatch]  = useContext(EditContext);

  return (
    <Grid columns={2} divided>
      <Grid.Column>
        <UnitInput  onStateUpdate = {(e, { value }) => {dispatch({type: "V1", value: value })}} 
                    baseUnit      = {props.BaseUnit}
                    unitStr       = "°"
                    value         = {state.value1} 
                    label         = {LocalizedStrings.ValueStart}
                    name          = "left"
                    labelPosition = "right"
                    step          = "1"
                    fluid
        />
      </Grid.Column>
      <Grid.Column>
        <UnitInput  onStateUpdate = {(e, { value }) => {dispatch({type: "V2", value: value })}}
                    baseUnit      = {props.BaseUnit}
                    unitStr       = "°"
                    value         = {state.value2}
                    label         = {LocalizedStrings.ValueEnd}
                    name          = "right"
                    labelPosition = "right"
                    step          = "1"
                    fluid
        />
      </Grid.Column>
    </Grid>
  )
  
}

//-----------------------------------------------------------------------------
function IncrementComponent(props) 
{
  const  [state, dispatch]  = useContext(EditContext);

  return (
    <Grid columns={2} divided>
      <Grid.Column>
        <UnitInput  baseUnit      = {props.BaseUnit}
                    unitStr       = "°"
                    onStateUpdate = {(name, value, e) => {dispatch({type: "V1", value: value })}}
                    value         = {state.value1}
                    label         = {LocalizedStrings.Value}
                    name          = "left"
                    labelPosition = "right"
                    step          = "1"
                    fluid>
        </UnitInput>
      </Grid.Column>
      <Grid.Column>
        <UnitInput  baseUnit      = {props.BaseUnit}
                    unitStr       = "°"
                    onStateUpdate = {(name, value, e) => {dispatch({type: "V2", value: value })}}
                    value         = {state.value2}
                    label         = {LocalizedStrings.IncrementBy}
                    name          = "right"
                    labelPosition = "right"
                    step          = "1"
                    fluid
                    />
      </Grid.Column>
    </Grid>

  )

}
//-----------------------------------------------------------------------------
function DistributeComponent (props)  
{   
  const  [state, dispatch]  = useContext(EditContext);

  return (
    <Grid columns={2} divided>
      <Grid.Column>
        <UnitInput  baseUnit      = {props.BaseUnit}
                    unitStr       = "°"
                    onStateUpdate = {(name, value, e) => {dispatch({type: "V1", value: value })}}
                    value         = {state.value1}
                    label         = {LocalizedStrings.Value1}
                    name          = "left"
                    labelPosition = "right"
                    step          = "1"
                    fluid
                    />
      </Grid.Column>
      <Grid.Column>
        <UnitInput  baseUnit      = {props.BaseUnit}
                    unitStr       = "°"
                    onStateUpdate = {(name, value, e) => {dispatch({type: "V2", value: value })}}
                    value         = {state.value2}
                    label         = {LocalizedStrings.Value2}
                    name          = "right"
                    labelPosition = "right"
                    step          = "1"
                    fluid
                    />
      </Grid.Column>
  </Grid>

  )
}
//-----------------------------------------------------------------------------
function SetComponent (props) 
{
  const [state, dispatch] = useContext(EditContext);

  return (
    <Grid columns={1} divided>
      <Grid.Column>
      <UnitInput    onStateUpdate = {(name, value, e) => {dispatch({type: "V1", value: value})}}
                    baseUnit      = {props.BaseUnit}
                    unitStr       = "°"
                    value         = {state.value1}
                    label         = {LocalizedStrings.Value1}
                    name          = "left"
                    labelPosition = "right"
                    step          = "1"
                    fluid
      />
      </Grid.Column>
    </Grid>
  );
}
export default AlignValues