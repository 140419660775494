import React, { Component } from "react";
import { Table, Checkbox, Form } from "semantic-ui-react";
import LocalizedStrings from "../../localization/ResourceImportMap";
import LRModal from "../Basics/BasicModal";
import SymbolMapSelector from "../RessourceManager/SymbolMapSelector";
import omit from "lodash/omit"
import { globalCallbacks } from "../../util/callback";


class ResourceImportMap extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
      Array:[],
      importGroups: true,

      options: undefined, // PA symbols
      additionalMaps: [], // new maps,
      ExistingEntries: []
    }
  }

  componentDidMount = () =>
  {
    this.setUpCallbacks();
    
  }

  render() 
  {
    return (
      <LRModal  open={this.state.open}
                title={LocalizedStrings.Header}
                onCancelClick={this.close}
                scrolling={true}
                onOkClick={this.ok}
                >
                  <Table striped celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>{LocalizedStrings.Incoming}</Table.HeaderCell>
                        <Table.HeaderCell>{LocalizedStrings.ReplaceWith}</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.state.open ? this.state.Array.map((entry, i) => this.renderRow(entry.MvrSymbolName, i)) : null}
                    </Table.Body>
                  </Table>
                  <Checkbox label={LocalizedStrings.ImportGroups} checked={this.state.importGroups} onChange={()=>{this.setState({importGroups: !this.state.importGroups})}}/> 
      </LRModal>
    )
  }

  renderRow = (entry, i) => {     
    let value = this.getReplaceWith(entry)    

      return (<Table.Row key={entry + i}>
                <Table.Cell>
                  {entry}
                </Table.Cell>
                <Table.Cell>
                  <Form>
                  <SymbolMapSelector   updateData    = {(updateObj) => this.updateData(updateObj, this.state.Array[i].MvrSymbolName)}
                                        entry={entry}
                                        key={entry + i}
                                       value         = {value ? value : ""}
                                       options       = {this.state.options}/>
                  </Form>
                </Table.Cell>
              </Table.Row>
    )
  }

  updateData = (updateObj, entry) => {
    
    console.log("updateData", updateObj, entry)
    if (!updateObj && this.state.additionalMaps[entry]) // "none" is selected and there was a map before
    {
      // remove from additional maps
      this.setState({additionalMaps: omit(this.state.additionalMaps, entry)})
    }
    else if (updateObj)
    {
      this.setState({additionalMaps: {
                                      ...this.state.additionalMaps, 
                                      [entry]: updateObj
                                    }})
    }                                    
  }


  getReplaceWith = (entry) => {

    // First look in new
    let newe = this.state.additionalMaps[entry]?.newTo
    if(newe)
    {
      return newe
    }
    // Then look in old
    return this.state.ExistingEntries[entry]?.ToName
  }

  close =  () =>
  {
    this.setState({open: false})
  }

  ok = async () =>
  {
    for(let map in this.state.additionalMaps)
    {
      if(this.state.additionalMaps[map].newTo)
      {
        await window.LR_AddSymbolMap(
        {
          From: map, 
          ToName: this.state.additionalMaps[map].newTo,
          ToUUID: this.state.additionalMaps[map].newUUID,
          ToRessourceIdent: this.state.additionalMaps[map].newIdent,
          User: this.state.additionalMaps[map].User
        });
        await window.LR_AddSymbolMap(
          {
            From: map + "_3d", 
            ToName: this.state.additionalMaps[map].newTo,
            ToUUID: this.state.additionalMaps[map].newUUID,
            ToRessourceIdent: this.state.additionalMaps[map].newIdent,
            User: this.state.additionalMaps[map].User
          })
      }
    }

    if(this.state.Arg.Path)
    {
      window.LR_ReadMVR({...this.state.Arg, SymbolMap: false, ImportGroups: this.state.importGroups})
    }

    
    this.setState({open: false}) 
  }

  setUpCallbacks()
  {
    globalCallbacks.openimportmap = async (arg) => 
    {
      this.setState({Arg: arg, Array:arg.Array})
      let replaceWith = await window.LR_GetSymbolMap()
      let ExistingEntries = {}
      for(let e of replaceWith.ResourceMap.symbolMap)
      {
        ExistingEntries[e.From] = e
      }

      this.setState({open:true, ExistingEntries: ExistingEntries})
    }
  } 
}


export default ResourceImportMap;