//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component, } from 'react';
import LRModal from '../Basics/BasicModal';

const QrReader = React.lazy(()=> import('@blackbox-vision/react-qr-reader').then(i => ({default: i.QrReader})))

import { ViewFinder } from './ViewFinder';
import {ViewError} from "./ViewError";
import {ViewResult} from "./ViewResult";
import { Message } from 'semantic-ui-react';
import LocalizedStrings from "../../localization/QrCodeSite";

class QrCodeReader extends Component 
{
  constructor(props)
  {
      super(props);
      this.state = {
        qrcode:true,
        result:0,
   
        
      }
  }
  render() 
  {
    return (
      <>
      <LRModal {...this.props} dimmer={""}>
        {JSON.stringify(this.state.lastError) }
      <QrReader 
        constraints={{
          aspectRatio: 1,
          facingMode: 'environment',
        }}
        ViewFinder={this.handleView()}
        videoStyle={{ height: 'auto', "WebkitPlaysinline":true }}
        containerStyle={{ width: '100%' }}
        onResult={this.handleScan}/>
        <Message warning={this.state.result===2}>
          <Message.Header>{this.handleMessage()}</Message.Header>
        </Message>
      </LRModal>
      </>
    );
  }

 handleView = () => {
 switch(this.state.result){
    case 1 : 
    return ViewResult ;

    case 2 : 
    return ViewError ;

    default:
       return ViewFinder;
 }
}

handleMessage = () => {
  switch(this.state.result){
     case 1 : 
     return  LocalizedStrings.Message_OK;
 
     case 2 : 
     return LocalizedStrings.Message_Error;
 
     default:
        return LocalizedStrings.Message_NotFound;
  }
 }
 

  handleScan = async (result, error) =>
  {
    
    if(result){
      this.setState({result:1})
    }

    let lib = await import('@zxing/library')

    if(error instanceof lib.ChecksumException){
      this.setState({result:2, errorText: "Warning"})
    }
   
    if (error instanceof lib.NotFoundException)
      {
        this.setState({result:0})
        
      }
    
   
    if(result && this.props.onScan)
    {
      if(await this.props.onScan(result.text) && this.props.onOkClick)
      {
        setTimeout(()=>
        {
          this.props.onOkClick()
        }, 500)
      }
    }
  }

}

export default QrCodeReader;

