//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------

import React, { Component } from "react";
import { Header, Segment,} from "semantic-ui-react";

let rehypeRaw
const ReactMarkdown = React.lazy(async ()=>{
  rehypeRaw = (await import("rehype-raw")).default
  return await import("react-markdown")
})

import gfm from "remark-gfm";
import { NavLink } from "react-router-dom";
const Nav = React.lazy(()=>import("../ProductionAssistLandingPage/LandingPageNav"));
import "./documentation.css";
import remarkToc from 'remark-toc'
import {slugify} from '../../util/defines'
import { Navigate } from "react-router-dom";
import { withRouter } from "../../webApp/WebRouter";

import CONST from "../../webApp/CONST";


class AcceptanceTests extends Component {
  constructor() {
    super();
    this.state = {
      searchString: "",
      intro: "",
      loading: false,
      results: [{}],
      value: "",
    };
  }

  componentDidMount = () => {
    this.reload()
  };


  reload = async () =>
  {
    let url = new URL( `${CONST.BASE_URL}/api/acceptance-test/all`);
    let response = await fetch(url, { method: "GET" });
    let array = await response.json();

    if(! Array.isArray(array))
    {
      array = []
    }

    array.forEach( async (item) => {
        let url = new URL(
          `${CONST.BASE_URL}/api/acceptance-test/${
            item.markdown
          }`
        );
        let response = await fetch(url, { method: "GET" });
        let text = await response.text();
        this.setState({ [item.markdown]: text });
      });

      this.setState({results: array})
  }

  getDefinition()
  {
    return this.state.results
  }

  getActive() {
    return this.props.match.params.topic
      ? this.props.match.params.topic
      : this.getDefinition()[0].markdown;
  }

  resultRenderer = (object) => {
    return (
      <>
        <Header>{object.markdown}</Header>
        <Segment>
          <ReactMarkdown
            remarkPlugins={[gfm,  [remarkToc,{tight: true, ordered: true, heading:"inhaltsverzeichnis|toc|table[ -]of[ -]contents?"}]]}
            components={{ img: Image }}
            transformImageUri={(uri) => {
              return `${
                CONST.BASE_URL
              }/api/acceptance-test/${uri}`;
            }}
          >{object.description}</ReactMarkdown>
        </Segment>
      </>
    );
  };

  render() {

    return (
      <div className="documentation">
        <header>
          <Nav />
          <div>
            <h1>Acceptance Tests</h1>
          </div>

          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0, 0, 100, 38.5896"
          >
            <path
              width="100%"
              height="100%"
              d="M 100 38.5896 
              L 0 38.5896 
              L 78.9959 24.6768 
              C 91.3502 22.3542 100 12.1998 100 0 
              L 100 0 
              z"
            />
          </svg>
        </header>
        <div>
          <div>
            {this.state.selected_header ? (
              <Navigate to={`/acceptance/${this.state.selected_header}`} />
            ) : null}

            <div className="sidebar">
              <div>{this.getDefinition().map((entry) => this.renderGroup(entry))}</div>
            </div>
            <div className="document-container">
              <ReactMarkdown
                remarkPlugins={[gfm]}
                rehypePlugins={[rehypeRaw]}
                components={{ 
                  img: Image,
                  h1: h1, 
                  h2: h2, 
                  h3: h3, 
                  h4: h4, 
                 }}
                transformImageUri={(uri) => {
                  return `${
                    CONST.BASE_URL
                  }/api/acceptance-test/${uri}`;
                }}
              >{this.state[this.getActive()]}</ReactMarkdown>
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderGroup(entry) {
    return (
      <div className="group">
            <li>
              <NavLink
                to={`/acceptance-test/${entry.markdown}`}
                key={entry.markdown}
                name={entry.markdown}
                activeStyle={{
                  width: "100%",
                  padding: "2px 0 2px 0.5rem",
                  border: "none",
                  borderRadius: "5px",
                  backgroundColor: "#ECD1DC",
                }}
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                {entry.markdown}
              </NavLink>
            </li>
      </div>
    );
  }
}

function Image(props) {
  return (
    <img
      {...props}
      style={{
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "50%",
      }}
      alt=""
    />
  );
}

function id(props)
{
  let id = props.children[0].toLowerCase()
  id = slugify(id);
  return id;
}

function h1(props) {

  return<h1 id={id(props)}>{props.children}</h1>
}

function h2(props) {

  return<h2 id={id(props)}>{props.children}</h2>
}

function h3(props) {

  return<h3 id={id(props)}>{props.children}</h3>
}
function h4(props) {

  return<h4 id={id(props)}>{props.children}</h4>
}


export default withRouter(AcceptanceTests);
