import React, { Component } from "react";
import { Form, Header, Message } from "semantic-ui-react";
import { globalCallbacks } from "../../util/callback";
import LocalizedStrings from "../../localization/Security"
import { lrServerConnection } from '../../redux/light_right_server_connection';

class Security extends Component
{
  constructor(props) {
    super(props)
    this.state = {
      oldpw: "",
      newpw: "",
      conpw: "",
      newusername: "",
      passwordsinging: "",
      passwordsinging2: ""
    }
  }

  inputChange = (_, {name, value}) => this.setState({
    [name]: value
  })

  onSubmit = () => {
    const {
      oldpw,
      newpw
    } = this.state
    globalCallbacks.changePassword(oldpw, newpw)
  }

  render()
  {
    const {
      oldpw,
      newpw,
      conpw
    } = this.state

    const areEq = newpw === conpw

    return <div style={{maxWidth: 450}}>
      <Header>{LocalizedStrings.NewPassword}</Header>
      <Form>
        <Form.Input 
          label={LocalizedStrings.OldPassword}
          name="oldpw"
          type="password"
          value={oldpw}
          onKeyDown = {(e) => {if(e.keyCode === 13){e.target.blur()}}}
          onChange={this.inputChange}/>
        <Form.Input
          label={LocalizedStrings.NewPassword}
          name="newpw"
          type="password"
          value={newpw}
          onKeyDown = {(e) => {if(e.keyCode === 13){e.target.blur()}}}
          onChange={this.inputChange}/>
        <Form.Input
          label={LocalizedStrings.ConfirmPassword}
          name="conpw"
          type="password"
          value={conpw}
          onKeyDown = {(e) => {if(e.keyCode === 13){e.target.blur()}}}
          onChange={this.inputChange}
          error={!areEq ? {content: "new passwords must match", pointing: "above"} : undefined}/>
        <Form.Button disabled={!areEq || newpw === "" || oldpw === ""} floated="right" onClick={this.onSubmit}>{LocalizedStrings.ButtonNewPassword}</Form.Button>
      </Form> 
      {this.renderChangeDisplayName()}
      {this.renderSingingPasswordGenerate()}
    </div> 
  }

  renderChangeDisplayName()
  {
    return(
      <>
    <Header>{LocalizedStrings.ChangeDisplayName}</Header>
    <Message>
      {LocalizedStrings.ChangeDisplayNameInfo}
    </Message>
      <Form>
        <Form.Input
          label={LocalizedStrings.NewDisplayName}
          value={this.state.newusername}
          name={"newusername"}
          onKeyDown = {(e) => {if(e.keyCode === 13){e.target.blur()}}}
          onChange={this.inputChange}/>
        <Form.Button disabled={this.state.newusername === ""} floated="right" onClick={ async ()=>
        {
          lrServerConnection.changeDisplayName(this.state.newusername)
        }}>{LocalizedStrings.UpdateDisplayName}</Form.Button>
      </Form> 
    </> 
    )
  }
  renderSingingPasswordGenerate()
  {
    return(
      <>
    <Header>{LocalizedStrings.GenerateSigningCertificate}</Header>
    <Message>
      {LocalizedStrings.GenerateSigningCertificateInfo}
    </Message>
      <Form>
        <Form.Input
          label={LocalizedStrings.Password}
          value={this.state.passwordsinging}
          name={"passwordsinging"}
          onKeyDown = {(e) => {if(e.keyCode === 13){e.target.blur()}}}
          onChange={this.inputChange}/>
          <Form.Input
          label={LocalizedStrings.PasswordRepeat}
          value={this.state.passwordsinging2}
          error={this.state.passwordsinging2!==this.state.passwordsinging}

          name={"passwordsinging2"}
          onKeyDown = {(e) => {if(e.keyCode === 13){e.target.blur()}}}
          onChange={this.inputChange}/>
           <Form.Input
          label={LocalizedStrings.LocationSigning}
          value={this.state.locationsinging}
          name={"locationsinging"}
          onKeyDown = {(e) => {if(e.keyCode === 13){e.target.blur()}}}
          onChange={this.inputChange}/>
        <Form.Button disabled={this.state.passwordsinging2!==this.state.passwordsinging || this.state.passwordsinging === "" || this.state.locationsinging === ""} floated="right" onClick={ async ()=>
        {
          lrServerConnection.generateSigningCertificate(this.state.locationsinging, this.state.passwordsinging)
        }}>{LocalizedStrings.GenerateSingingPassword}</Form.Button>
      </Form> 
    </> 
    )
  }
}

export default Security