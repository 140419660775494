
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Search, Table, Label, Icon, Segment, Popup, Checkbox} from 'semantic-ui-react'
import ClassNode from "./ClassNode";
import LocalizedStrings from "../../localization/NavigationContainer";

import { EMPTY_UUID, DEFAULT_CLASS_UUID, IsElectronContext } from '../../util/defines';

const SORT_BY = "Name"

import { globalCallbacks as mainCB } from '../../util/callback';
import { globalCallbacks as mockCB } from '../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB
//class StructNode generates class node for the class tree
class StructNode{
    constructor(name){
        this.parent = undefined
        this.children = []
        this.rawData = undefined
        this.name = name
        this.expand= false
    }

    addChild(newChild){
        this.children.push(newChild)
        newChild.parent = this
    }

    // Get all children nodes including all lower levels of a tree node
    *getLeafs(){
        if(!this.children.length){
            yield this
            return
        }
        for(let i of this.children){
            yield* i.getLeafs()
        }
    }

    // Check if all children nodes has Visible equal false
    checkIfAllVisible(){
        for(let i of this.getLeafs()){
            if(i.rawData.Visible){
                return true
            }
        }
        return false
    }

    // Check if all children nodes has OnlyVisible equal true
    checkIfAllOnlyVisible(){
        for(let i of this.getLeafs()){
            if(!i.rawData.OnlyVisible){
                return false
            }
        }
        return true
    }

    // Check if all children nodes has OnlyVisibleUsed equal true
    checkIfAllOnlyVisibleUsed()
    {
        for(let i of this.getLeafs()){
            if(!i.rawData.OnlyVisibleUsed){
                return false
            }
        }
            return true
        
    }
}

class ClassTable extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = 
        {
            classes : [],
            activeClass: undefined,
            showTree: false,

            // search
            isLoading: false,
            value    : "",
            indexMap: [],
            sorting: null,
            selected: {},
            allSelected: false,
            multiLock: false,
            multiVisible: true,
            multiOnlyVisible: true
        };

    }

    componentDidMount = () => 
    {
        this.setUpCallbacks();
        globalCallbacks.getNavigationClasses();
        globalCallbacks.updateActiveClass();
    }

    componentWillUnmount = () => 
    {
        this.takeDownCallbacks();
    }

    render() 
    {   
        return (
            <>
                <div style={{width:"100%", height:"100%"}}>
                    <Table style={{borderBottom: "none", margin:0}}>
                        <Table.Header>
                            <Table.Row>
                            <Table.HeaderCell colSpan='1' style={{zIndex: 0}}  >
                       
                                {<Icon onClick={this.onHeaderClick} link style={{floated: 'left', position: 'absolute', marginTop:"0.2rem"}} name={this.state.sorting === null ? "sort" : this.state.sorting === "ascending" ? "sort alphabet up": "sort alphabet down"}/>}
                            
                                    <Checkbox checked={this.state.showTree}  label={LocalizedStrings.ShowClassTree}  onChange={(e) => {this.setState({showTree:!this.state.showTree }); e.stopPropagation()}} style={{fontWeight:"normal", fontSize:"0.9rem", marginLeft:"2rem"}} />
                                  
                            </Table.HeaderCell>
                                <Table.HeaderCell colSpan='3' style={{zIndex: 0}} >
                                   <Search open    = {false}
                                            loading = {this.state.isLoading}
                                            value   = {this.state.value}
                                            onClick = {(e)=>{e.stopPropagation()}}
                                            onSearchChange = {(e, {value}) => this.onSearchChange(value)}
                                            aligned = "right"/>
                                </Table.HeaderCell>
                            </Table.Row>

                           {!this.state.showTree
                            ?
                            <Table.Row colSpan= "3">
                                <Table.HeaderCell collapsing><Checkbox checked={this.hasSelection()} onChange={(e) => {this.onAllClick()}} /> </Table.HeaderCell> 
                                <Table.HeaderCell><p style={{marginLeft:"-5.3rem"}}>{LocalizedStrings.SelectedClasses}</p></Table.HeaderCell>
                                <Table.HeaderCell collapsing>
                                    <Popup content={LocalizedStrings.VisibilityClass}
                                           trigger={<Icon name ={this.state.multiVisible ? "eye" : "eye slash"} link onClick={() => {window.LR_SetClasses({ClassObjects: this.getNodes("Visible")}); this.setState({multiVisible:!this.state.multiVisible})}}/>}/>
                                    <Popup content={LocalizedStrings.OnlyVisibilityClass}
                                           trigger={<Icon name ={this.state.multiOnlyVisible ? "eye slash outline" : "eye"} color={this.state.multiOnlyVisible ? "grey" : "green"} link onClick={() => {window.LR_SetClasses({ClassObjects: this.getNodes("OnlyVisible")}); this.setState({multiOnlyVisible:!this.state.multiOnlyVisible})}}/>}/>
                                    <Popup content={LocalizedStrings.NotApplyClassActivation} 
                                           trigger={<span><Icon name = "check circle" disabled/></span>}/>
                                    <Popup content={LocalizedStrings.NotApplyAssemblySheetCreation}
                                           trigger={<span><Icon name = "file outline" disabled/></span>}/>
                                    <Popup content={LocalizedStrings.DeleteClass} 
                                           trigger={<Icon name = "delete" color= "red" link onClick={() => {window.LR_DeleteClasses({UUIDList:this.getSelectedClassNodes()})}}/>}/>
                                </Table.HeaderCell>
                            </Table.Row> 
                            : ""
                            }
                        </Table.Header>
                    </Table>

                    <div className="table-content" style={{width:"100%", maxHeight:IsElectronContext() ? "calc(100vh - 30em)" :"calc(100vh - 35em)", overflowY:"auto", overflowX: this.props.small ? undefined : "clip", marginBottom:"5em"}}>
                        {
                            (!this.state.showTree)
                            ?
                            <Table striped structured compact='very' size="small">
                                <Table.Body>
                                    {this.renderRows()}
                                </Table.Body>
                            </Table>
                            :
                            <Table>{this.renderClassTree()}</Table>
                        }
                    </div>

                    <Segment vertical textAlign="center" style={{position:"absolute", bottom: IsElectronContext() ? "1.5em" : "6em", width:"100%", border:"none"}}>
                        <Label as="a" color="green" onClick={this.onAddNewClass}>
                            <Icon name="plus"/>{LocalizedStrings.AddNewClass}
                        </Label>
                    </Segment>
                </div>  
               

                
            </>
        );
    }

    onHeaderClick = () => {
        let newVal = null
        switch (this.state.sorting) {
            case null:          newVal = "ascending";   break;
            case "ascending":   newVal = "descending";  break;
            default:            newVal = null;          break;
        }
        this.setState({
            sorting: newVal
        })
      }

    getSortedClasses = () => {
        let showData = this.state.classes

        if(this.state.sorting) 
        {
            let indexMap = []     
            showData.forEach((_, i) => indexMap.push(i))
            indexMap.sort((a,b) => showData[a][SORT_BY] === showData[b][SORT_BY]  ? 0 : (showData[a][SORT_BY]  < showData[b][SORT_BY]  ? -1 : 1))
            
            showData = indexMap.map((_, i) => {
                let calcIndex = this.state.sorting === 'ascending' ? i : indexMap.length - i - 1
                return showData[indexMap[calcIndex]]
            })
        }
        return showData
    }

    renderRows = () => {
        let showData = this.getSortedClasses()

        return showData.length > 0 && showData.map(clss => 
        {
            if(this.state.value !== "" && !clss.Name.toLowerCase().includes(this.state.value.toLowerCase()))
            {
                return null
            }
            return <ClassNode onSelectClassNode={this.onSelectClassNode} checked={this.state.selected[clss.UUID]} key={clss.UUID} small={this.props.small} clss={clss} activeUuid={this.state.activeClass}/>}
        )
    } 

    hasSelection = () =>
    {
        let hasSelection = false
        Object.keys(this.state.selected).every((key) =>
        {
            hasSelection = hasSelection || this.state.selected[key]
            return !hasSelection
        })
        return hasSelection
    }

    onSelectClassNode = (clUUID, value) =>
    {
        this.setState({selected: {...this.state.selected, [clUUID]: value}})
    }

    onAllClick = () =>
    {
        let selectedValue = this.hasSelection()
        let selected      = {}

        this.getSortedClasses().forEach((clss) =>
        {
            selected[clss.UUID] = !selectedValue
        })
        this.setState({selected})
    }

    getSelectedClassNodes = () =>
    {
        let classNodes = []
        this.getSortedClasses().forEach((clss) => 
        {
            if(this.state.selected[clss.UUID])
            {
                classNodes.push(clss.UUID)
            }       
        })
        
        return classNodes
    }

    getNodes = (name) =>
    {
        let classNodes = []
        this.getSortedClasses().forEach((clss) =>
        {
            if(this.state.selected[clss.UUID])
            {
                let classNode = {}
                classNode.UUID = clss.UUID
                classNode[name]= !clss[name]
                classNodes.push(classNode)
            }
        })

        return classNodes
    }

    onSearchChange = (value) => 
    {
      this.setState({value: value})
    }


    setUpCallbacks()
    {
        globalCallbacks.getNavigationClasses = async () =>
        {
            let ret = await window.LR_GetClasses();
            let classes = ret.Classes
            this.setState({classes});
        }

        globalCallbacks.updateActiveClass = async () =>
        {
            let res = await window.LR_GetActiveClass()
            if(res.Class === EMPTY_UUID)
            {
                res.Class = DEFAULT_CLASS_UUID
            }
            this.setState({activeClass: res.Class})
            
        }
    }

    takeDownCallbacks()
    {
        globalCallbacks.getNavigationClasses = undefined;
    }

    onAddNewClass = () =>
    {
        window.LR_AddNewClass({});
    }

    renderClassTree = () =>
    {
    //----------------------------------------------------------------------------------------
    // Generate data object of Class Tree where each tree node has a name, children array, parent, and expand properties

        let masterObject = new StructNode()
        let data = this.getSortedClasses()

        for(let groupClass of data){
            let name = groupClass.Name
            let levels = name.split("-")
            let currNode = masterObject
            for(let groupName of levels){
                let newNode = currNode.children.filter(e => e.name === groupName)[0]
                if(!newNode){
                    newNode = new StructNode(groupName)
                    newNode.rawData = groupClass
                    currNode.addChild(newNode)
                }
                currNode = newNode
            }
            currNode.rawData = groupClass
        }
        let classNameData = masterObject.children

        return(
            <>
                {classNameData.map((el, i) => {
                   if(this.state.value !== "" && !el.name.toLowerCase().includes(this.state.value.toLowerCase()))
                   {
                       return null
                   }
          
                    return(<div key={i} style={{marginLeft:"-2.5rem"}}>
                            <ClassTreeNode VWRouterStyle={this.props.VWRouterStyle} classTreeNode={el} activeUuid={this.state.activeClass} />
                          </div>)
                })}
            </>
        )
    }
}

export default ClassTable;


class ClassTreeNode extends React.Component 
{

    constructor(props)
    {
        super(props);

        this.state = this.props.classTreeNode.rawData

    }
    
   
    render()
    {
        return this.renderClassList(this.props.classTreeNode)
    }

    renderClassList = (classTreeNode) =>
    {
        let classNode = this.state

        let allVisible        = classTreeNode.checkIfAllVisible()
        let allOnlyVisible    = classTreeNode.checkIfAllOnlyVisible()
        let allOnlyVisibleUse = classTreeNode.checkIfAllOnlyVisibleUsed()
        return(
            <ul style={{listStyleType:"none",  marginTop:"0.5rem", padding:"0.9rem 0 0 -1rem"}}>
                <li style={{textDecoration:"none", width:"100%", height:"0.8rem", borderTop:"1px solid rgb(217 212 212 / 87%)", padding: this.props.VWRouterStyle ? "0.3rem 0 0.9rem 0" : "0.3rem 0 0.1rem 0", fontSize:"0.9rem", position:"relative"}}>
                    {classTreeNode.children.length > 0
                                                 ?
                                                 <Icon 
                                                     link 
                                                     name='dropdown' 
                                                     rotated={this.state.expand ? undefined : "counterclockwise"}   
                                                     onClick={() => {this.setState({expand:!this.state.expand}); this.forceUpdate()}}
                                                  /> 
                                                  : ""}
                    {classTreeNode.name}

                    <div style={{position:"absolute", right:0, top:0}}>

                        {classTreeNode.children.length  === 0
                                ?
                                <Popup content={LocalizedStrings.ActivateClass} 
                                        trigger={
                                                <Icon   name    = "check circle"
                                                        link
                                                        color   = {classNode.UUID === this.props.activeUuid ? "green" : "black"}
                                                        onClick = {() => {classNode.UUID === this.props.activeUuid ? window.LR_SetActiveClass({Class: EMPTY_UUID}) : window.LR_SetActiveClass({Class: classNode.UUID})}}/>
                                        }/>
                                : ""
                            }

                        <Popup content={LocalizedStrings.VisibilityClass}
                            trigger={
                                <Icon   name        = {allVisible ? "eye" : "eye slash"}
                                        link        = {!allOnlyVisibleUse}
                                        color       = {allOnlyVisibleUse? "grey" : "black"}
                                        disabled    = {allOnlyVisibleUse}
                                        onClick     = {() => {
                                                        for(let i of classTreeNode.getLeafs()){
                                                            this.updateData("Visible", !i.rawData.Visible, i.rawData.UUID);
                                                        };
                                                    }}
                                        
                                />
                            }/>
                         <Popup content={LocalizedStrings.OnlyVisibilityClass}
                            trigger={
                                <Icon   name    = {allOnlyVisible ? "eye" : "eye slash outline"}
                                        link
                                        color   = {allOnlyVisible ? "green" : allOnlyVisibleUse? "black" : "grey"}
                                        onClick = {() => {
                                                    for(let i of classTreeNode.getLeafs()){
                                                        this.updateData("OnlyVisible", !i.rawData.OnlyVisible, i.rawData.UUID);
                                                        this.updateData("OnlyVisibleUsed", !i.rawData.OnlyVisibleUsed, i.rawData.UUID) 
                                                    };
                                                
                                                }}
                                />
                            }/>
                        
                        

                    </div>

                        
                </li>
                {this.state.expand ? <>{classTreeNode.children.length  > 0 ? classTreeNode.children.map((child, i) => <ClassTreeNode key={i} VWRouterStyle={this.props.VWRouterStyle} classTreeNode={child} activeUuid={this.props.activeUuid} />) : ""}</> : ""}
            </ul>
        )
    }

    updateData = (name, value, UUID) =>
    {
        let request = 
        {
            UUID: UUID
        };

        if(name)    { request[name] = value; }
        else        { request.Name  = this.state.Name; }

        request.ModifierKey = false;
        window.LR_SetClass(request);
        this.setState({[name]: value})

        this.forceUpdate()
    }
}