import React, { Component } from "react";
import { globalCallbacks } from "../../util/callback";
import { BASE_UNIT_NUMBER } from "../../util/defines";
import LRModal from "../Basics/BasicModal";
import UnitInput from "../Basics/BasicUnitInput"
import LocalizedStrings from "../../localization/DWGImportScale";
import { Form } from "semantic-ui-react";
class DWGImportScale extends Component 
{
    constructor(props)
    {
        super(props)

        this.state = {
            open: false,
            scale: 1,
            center: false
        }
    }

    componentDidMount()
    {
        this.setUpCallbacks()
    }

    render()
    {
        return <LRModal open={this.state.open}
        title={LocalizedStrings.Header}
                        onOkClick={this.ok}
                        onCancelClick={()=>{this.setState({open: false})}}>
                            <Form>
                            <UnitInput label={LocalizedStrings.Scale} baseUnit={BASE_UNIT_NUMBER} value={this.state.scale} onStateUpdate={(_, value) => {this.setState({scale: value})}}/>
                            <Form.Checkbox label={LocalizedStrings.Center} checked={this.state.center} onChange={(e, {checked}) => { this.setState({center: checked})}}/>
                            </Form>
                        </LRModal>
    }

    ok = () =>
    {
        window.LR_ReadDWG({...this.state.Arg, Scale: this.state.scale, Center: this.state.center})
        this.setState({open: false})
    }

    setUpCallbacks()
    {
        globalCallbacks.openDwgScale = (arg) =>
        {

            this.setState({open: true, Arg: arg})
        }
    }
}

export default DWGImportScale