//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from "react";
import { Form, Button, Grid, Message } from "semantic-ui-react";
import LocalizedStrings from "../../localization/ResetPage";
import { globalCallbacks } from "../../util/callback";
import logo from "../../content/img/applogo.png";
class ResetPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
      password: "",
      repeatPassword: "",
    };
  }

  update = (e, { name, value }) => {
    this.setState({
      [name]: value,
    });
  };

  sendUpdatePassword = async () => {
    let url = new URL(window.location.href);
    let secretToken = url.searchParams.get("secret_token");
    
    let event = await globalCallbacks.sendUpdatePassword(
      this.state.password,
      secretToken
    );
    this.setState({
      message: event.message,
    });
  };

  render() {
    let isEmpty =
      this.state.password.length <= 0 && this.state.repeatPassword.length <= 0;
    let isValid = false;
    if (this.state.password.length > 0) {
      isValid = this.state.password === this.state.repeatPassword;
    }

    return (
      <div className="login-form">
        <div className="header">
          <img src={logo} alt="production assistant logo" />
        </div>
        <div>
          <img src={logo} alt="production assistant logo" />{" "}
          <Form>
            <Form.Input
              name="password"
              label={LocalizedStrings.enterPasswordText}
              placeholder={LocalizedStrings.password}
              value={this.state.password}
              onChange={this.update}
            />
            <Form.Input
              name="repeatPassword"
              label={LocalizedStrings.repeatPasswordText}
              placeholder={LocalizedStrings.repeatPassword}
              value={this.state.repeatPassword}
              onChange={this.update}
            />
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                primary
                type="submit"
                disabled={!isValid}
                onClick={this.sendUpdatePassword}
              >
                {LocalizedStrings.ChangePassword}
              </Button>
            </div>
          </Form>
          {this.state.message && (
            <Grid.Row>
              <Message style={{ marginTop: "16px" }}>
                {this.state.message}
              </Message>
            </Grid.Row>
          )}
          {!isEmpty && !isValid && (
            <Grid.Row>
              <Message negative style={{ marginTop: "16px" }}>
                {LocalizedStrings.errorPasswordNotAlike}
              </Message>
            </Grid.Row>
          )}
          <Grid.Row>
            <div style={{ marginTop: "32px", textAlign: "center" }}>
              <a href="/">{LocalizedStrings.loginPage}</a>
            </div>
          </Grid.Row>
        </div>
        <div>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0, 0, 100, 38.5896"
          >
            <path
              width="100%"
              height="100%"
              d="M 100 38.5896 
                L 0 38.5896 
                L 78.9959 24.6768 
                C 91.3502 22.3542 100 12.1998 100 0 
                L 100 0 
                z"
            />
          </svg>
        </div>
      </div>
    );
  }
}

export default ResetPage;
