//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2019 www.deersoft.de
//-----------------------------------------------------------------------------
import LocalizedStrings from 'localized-strings';
import LocalizeOption from './LocalizationOption';

import en from "./raw/en/GroupByComponent.json"
import de from "./raw/de/GroupByComponent.json"
import es from "./raw/es/GroupByComponent.json"
import fr from "./raw/fr/GroupByComponent.json"
import it from "./raw/it/GroupByComponent.json"
import ru from "./raw/ru/GroupByComponent.json"
import cz from "./raw/cs/GroupByComponent.json"

let Strings = new LocalizedStrings<Partial<typeof en>>(
  {
    en: en,
    de: de,
    es: es,
    fr: fr,
    ru: ru,
    it: it,
    cz: cz,
  },
  { ...LocalizeOption }
);

export default Strings;