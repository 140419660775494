
export const FRAME_COUNT = 30

export default class DateHelper extends Date{

    static fromAbsoluteFrameCount(frameCount: number){
        return new DateHelper(frameCount / FRAME_COUNT * 1000)
    }

    static AbsoluteFrameCountToSeconds(frameCount: number){
        return frameCount / FRAME_COUNT
    }

    static SecondsToFrameCount(seconds: number){
        return seconds * FRAME_COUNT
    }

    addRelativeFrameCount(frameCount: number){ // current Date + Time in Frames
        return new DateHelper(this.getTime() + (frameCount / FRAME_COUNT * 1000))
    }

    getAbsoluteFrameCount(){
        return this.getTime() / 1000 * FRAME_COUNT
    }

    getTimeDiffInFrames(dateH: DateHelper | Date){
        return Math.round((dateH.getTime() - this.getTime()) / 1000 * FRAME_COUNT)
    }
}
