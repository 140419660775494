//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import "../SignIn/signin.css";

import React, { Component } from "react";
import { Form, Button, Grid, Message } from "semantic-ui-react";
import LocalizedStrings from "../../localization/ResetPage";
import logo from "../../content/img/applogo.png";
import { Link } from "react-router-dom";
import { IsRunningAsBrowser } from "../../util/defines";
import NewNavBar from "../ProductionAssistLandingPage/NewNavBar";
import NewFooter from "../WebComponents/NewFooter";

import CONST from "../../webApp/CONST";

class NewForgotPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
      username: "",
      isValidUsername: false,
    };
  }

  update = (e, { name, value }) => {
    let isValid = this.state.isValidUsername;
    if (name === "username") {
      isValid = value.length < 1;
    }

    this.setState({
      [name]: value,
      isValidUsername: isValid,
    });
  };

  sendForgotPassword = async () => {
    let form = new FormData();
    form.append("username", this.state.username.toLowerCase());

    let response = await fetch(new URL(`${CONST.BASE_URL}/forgot`), {
      method: "POST",
      body: form,
    });

    let json = await response.json();
    this.setState({
      message: json.message,
      resOK: json.ok,
    });
  };

  render() {


    let logoImage = <img src={logo} alt="production assistant logo" />
    if (IsRunningAsBrowser())
    {
      logoImage = <Link to="/">{logoImage}</Link>
    }

    return (
      <div className="newlogin forgotPasswort">
        { this.props.webApp ? <NewNavBar /> : null}
     
        <div className="loginform">
          <img src={logo} alt="production assistant logo" />{" "}
          <h1>{LocalizedStrings.ResetHeader}</h1>
          <Form className="inputcontainer">
            <p>{LocalizedStrings.ResetText}</p>

            <Form.Input
              className="textin"
              name="username"
              placeholder={LocalizedStrings.username}
              value={this.state.username}
              onChange={this.update}
            />
             <div className="button-container">
                <Button
                type="submit"
                disabled={this.state.isValidUsername}
                onClick={this.sendForgotPassword}
                >
                {LocalizedStrings.submitButton}
                </Button>

                <a style={{color: "#1e6fff"}} href="/">{LocalizedStrings.loginPage}</a>
            </div>

            {this.state.message && (
            <Message
              style={{ marginTop: "16px" }}
              positive={this.state.resOK}
              negative={!this.state.resOK}
            >
              {this.state.message}
            </Message>
        )}
          </Form>
        </div>
      
        { this.props.webApp ? <NewFooter/> : null}
      </div>
    );
  }
}

export default NewForgotPage;
