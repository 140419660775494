//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, {Component} from 'react';
import gfm from 'remark-gfm'
const ReactMarkdown = React.lazy(()=>import("react-markdown"))

import { Text } from "../ExportPaperworksReport/MarkdowTableSupport";

class DrawingNote extends Component 
{
  render() 
  {

    return (
      <ReactMarkdown components={{p: Text}} remarkPlugins={[gfm]}>{this.props.text}</ReactMarkdown>
    )
  }
  
}

export default DrawingNote