
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Grid, Form, Label, Message, Button, Icon, Tab } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/PrintLabelComponent";
import LocalizedStringsObjectProps from "../../localization/LightRightObjectsFields";
import { globalCallbacks } from '../../util/callback';
import PageCanvas from './PageCanvas';
import LRModal from '../Basics/BasicModal';
import BasicSelect from '../Basics/BasicSelect';
import {SORT_LOW_HIGH, SORT_HIGH_LOW, EMPTY_UUID, getDPMM, LABEL_OBJECT_FILTER_RACK, LABEL_OBJECT_FILTER_CASE, LABEL_OBJECT_FILTER_OBJECT, BASE_UNIT_STRING, RESOURCE_TYPE_PrintLabel, BASE_UNIT_NUMBER, PRINT_LABEL_FIELD_TYPE, BASE_UNIT_LENGTH } from '../../util/defines';
import UnitInput from '../Basics/BasicUnitInput';
import SelectResourceModal from '../ToolSelect/SelectRessourceModal';

class AlignPageModal extends Component
{
  constructor(props)
  {
    super(props)
    
    this.firstObjLabel = undefined

    this.state = {
      xCount : 0,
      yCount : 0,

      activeTab: 0,

      Project: "",

      open: false,
      selectedLabel: undefined,      
      selectedWorksheet: undefined,

      fields: [],
      origins: [],
      originSelectActivated: false,
      selectedOrigin: null,
      PublicShareLinkOptions:[],
      PublicShareLink: "<None>",
      labels:[],
      worksheets:[],
      OnlySelected: false,
      TrussTapeModePrintSteps: false,
      TrussTapeMeasureFromMiddle: false,
      TrussTapeModeStepsWidth: 200,
      TrussTapeCurrentLength: 200,
      TrussTapeDesignLength: 200,
      resourceSelectorOpen: false,

      sortFromToOptions: [
        {key: "lowhigh", text: LocalizedStrings.Lowhigh, value: SORT_LOW_HIGH},
        {key: "highlow", text: LocalizedStrings.Highlow, value: SORT_HIGH_LOW},
      ],
      selectedSortBy: "<None>",

      selectedSortFromTo: SORT_LOW_HIGH,

      visibleFields: [],
      sortByOptions: [],
      doNotPrintKeys: [],

      filter: {
        prop: "<None>",
        from: 0,
        to: 0,
        subStr: "",
      },

    }
  }
  
  close = () => { this.setState({open: false})}
  open = () => { this.setState({open: true})}

  async componentDidMount()
  {
    this.setUpCallbacks()
    await this.getAvailableOrigins() 
  }

  getLabelInfos = () =>
  {
    if (this.props.labelCanvas)
    {
      let labelInfo = this.props.labelCanvas.current.getFieldInfos();
      this.setState({fields: labelInfo})
    }
  }

  async getAvailableOrigins(){
    const origins = await window.LR_GetAvailableOrigins({Async: true})
    this.setState({ origins })
  }

  
   render() {    
    let labelForSizeCalc = this.getLabelForMeasures(this.state.selectedLabel)
    console.log("LABEL render", labelForSizeCalc?.Name)
    let labelThere = labelForSizeCalc ? true : false 

    const panes = [
      {
        menuItem: LocalizedStrings.PageBasedLabels,
        render: () => (
          <Tab.Pane attached={false}>
                    <Grid>
            <Grid.Column width="7">
              <PageCanvas
                onClick={this.onClick}
                doNotPrintKeys={this.state.doNotPrintKeys}
                labelThere={labelThere}
                xCount={this.state.xCount}
                yCount={this.state.yCount}
                marginX={
                  labelThere ? labelForSizeCalc.MarginX : undefined
                }
                marginY={
                  labelThere ? labelForSizeCalc.MarginY : undefined
                }
                spacingX={
                  labelThere ? labelForSizeCalc.SpacingX : undefined
                }
                spacingY={
                  labelThere ? labelForSizeCalc.SpacingY : undefined
                }
                labelWidth={
                  labelThere ? labelForSizeCalc.SizeX : undefined
                }
                labelHeight={
                  labelThere ? labelForSizeCalc.SizeY : undefined
                }
                paperWidth={
                  labelThere ? labelForSizeCalc.PaperSizeX : undefined
                }
                paperHeight={
                  labelThere ? labelForSizeCalc.PaperSizeY : undefined
                }
                labelFields={this.state.visibleFields}
              />
              <Button.Group fluid>
                <Button style={{marginTop:"1em"}} disabled={labelForSizeCalc === undefined} onClick={this.onEditPrintLabel}>
                  <Icon name="edit"/> {LocalizedStrings.EditPrintLabel}
                  {this.state.resourceSelectorLoaderOpen ? <Icon name="spinner" loading /> :null}
                  </Button>
                <Button style={{marginTop:"1em"}} onClick={this.onCreateNewPrintLabel}><Icon name="edit"/> {LocalizedStrings.CreateNewPrintLabel}</Button>
              </Button.Group>
            </Grid.Column>
            <Grid.Column width="7" floated='right'>
                <Form widths="equal" style={{ marginTop: ''}}>
                  <Button floated="right" positive onClick={this.onDownloadNewLabel}><Icon name="download"/> {LocalizedStrings.DownloadOnline}</Button>
                  <BasicSelect
                    name="select label"
                    label={LocalizedStrings.SelectedLabel}
                    value={this.state.selectedLabel} // label
                    onStateUpdate={(name, value) => this.updateFields(value)}
                    options={this.state.labels} // {key, text, value=labelobj }
                  ></BasicSelect>
                  <BasicSelect
                    name="selectedWorksheet"
                    label={LocalizedStrings.SelectedWorksheet}
                    value={this.state.selectedWorksheet}
                    onStateUpdate={this.updateState}
                    search
                    placeholder={LocalizedStrings.Search}
                    options={this.state.worksheets}
                  />
                  {this.renderSort()}
                  {this.renderFilter()}
                  <Form.Checkbox
                    label={LocalizedStrings.OnlySelected}
                    checked={this.state.OnlySelected}
                    onChange={() => {
                      this.setState({ OnlySelected: !this.state.OnlySelected });
                    }}
                  />
                  <Form.Select
                    disabled={this.state.Project === ""}
                    onAddItem={this.createShareLink}
                    search
                    allowAdditions={true}
                    label={LocalizedStrings.PublicShareLink}
                    value={this.state.PublicShareLink}
                    fluid
                    compact
                    options={this.state.PublicShareLinkOptions}
                    onChange={(_, { value }) => {
                      this.setState({ PublicShareLink: value });
                    }}
                  />
                </Form>
              {this.state.Project === "" ? (
                <Message
                  icon="dont"
                  error
                  header={LocalizedStrings.NoOnlineProjectHeader}
                  content={LocalizedStrings.NoOnlineProjectText}
                />
              ) : null}
            </Grid.Column>
          </Grid>
          </Tab.Pane>
        ),
      },
      {
        menuItem: LocalizedStrings.TrussTapeMode,
        render: () => (
          <Tab.Pane attached={false}>
              <Form>
                <Button floated="right" positive onClick={this.onDownloadNewLabel}><Icon name="download"/> {LocalizedStrings.DownloadOnline}</Button>
                  <BasicSelect
                    name="select label"
                    label={LocalizedStrings.SelectedLabel}
                    value={this.state.selectedLabel}
                    onStateUpdate={(name, value) => this.updateFields(value)}
                    options={this.state.labels}
                  ></BasicSelect>
                  {this.renderFilter()}
                  <Form.Checkbox
                    label={LocalizedStrings.OnlySelected}
                    checked={this.state.OnlySelected}
                    onChange={() => {
                      this.setState({ OnlySelected: !this.state.OnlySelected });
                    }}
                  />
                  <Form.Group inline>
                    <Form.Checkbox
                      label={LocalizedStrings.markRoomCenter}
                      checked={this.state.originSelectActivated}
                      onChange={() => {
                        this.getAvailableOrigins();
                        this.setState({ originSelectActivated: !this.state.originSelectActivated });
                      }}
                    />
                    <Form.Dropdown 
                      selection 
                      disabled={!this.state.originSelectActivated}
                      options={this.state.origins.map((obj,i)=> ({ value:i, text:obj.text }))} 
                      value={this.state.selectedOriginIdx} 
                      text={ this.state.origins[this.state.selectedOriginIdx]?.text || "Select Origin" }
                      onChange={ (_,{ value }) => { this.setState({ selectedOriginIdx: value}) }}
                    />
                  </Form.Group>
                  <Form.Group fluid>
                      <Form.Checkbox
                        label={LocalizedStrings.TrussTapeModePrintSteps}
                        checked={this.state.TrussTapeModePrintSteps}
                        onChange={() => {
                          this.setState({ TrussTapeModePrintSteps: !this.state.TrussTapeModePrintSteps });
                        }}
                      />
                      <Form.Checkbox
                        label={LocalizedStrings.TrussTapeMeasureFromMiddle}
                        disabled={!this.state.TrussTapeModePrintSteps}
                        checked={this.state.TrussTapeMeasureFromMiddle}
                        onChange={() => {
                          this.setState({ TrussTapeMeasureFromMiddle: !this.state.TrussTapeMeasureFromMiddle });
                        }}
                      />
                  </Form.Group>

                    <UnitInput
                    baseUnit={BASE_UNIT_LENGTH}
                    disabled={!this.state.TrussTapeModePrintSteps}
                    label={LocalizedStrings.TrussTapeModeStepsWidth}
                    value={this.state.TrussTapeModeStepsWidth}
                    onStateUpdate={(e, value)=>this.setState({TrussTapeModeStepsWidth: value})}/>
                    <Form.Group fluid>
                    <UnitInput
                    baseUnit={BASE_UNIT_LENGTH}
                    label={LocalizedStrings.TrussTapeCurrentLength}
                    value={this.state.TrussTapeCurrentLength}
                    onStateUpdate={(e, value)=>this.setState({TrussTapeCurrentLength: value})}/>
                    <UnitInput
                    baseUnit={BASE_UNIT_LENGTH}
                    label={LocalizedStrings.TrussTapeDesignLength}
                    value={this.state.TrussTapeDesignLength}
                    onStateUpdate={(e, value)=>this.setState({TrussTapeDesignLength: value})}/>
                    <UnitInput
                    baseUnit={BASE_UNIT_NUMBER}
                    label={LocalizedStrings.TrussTapeCorrectionFactor}
                    value={this.state.TrussTapeCurrentLength / this.state.TrussTapeDesignLength}
                    readOnly/>
                    </Form.Group>
                  </Form>
              {this.state.Project === "" ? (
                <Message
                  icon="dont"
                  error
                  header={LocalizedStrings.NoOnlineProjectHeader}
                  content={LocalizedStrings.NoOnlineProjectText}
                />
              ) : null}
          </Tab.Pane>
        ),
      },
    ];

    return (
        <LRModal
                size="large"
                open={this.state.open}
                title={LocalizedStrings.ExportHeader}
                okDisabled={!this.state.selectedLabel || !this.state.selectedWorksheet}
                onCancelClick={this.close}
                onOkClick={this.onOk}
              >
          <Tab 
          menu={{ pointing: true }} 
          activeIndex={this.state.activeTab}
          onTabChange={(event, {activeIndex})=>{ this.setState({activeTab:activeIndex})}}
          panes={panes} />
 
          {this.state.resourceSelectorOpen ? <SelectResourceModal 
                      SelectRessourceCallback={this.closeRessourceSelector}
                      open={this.state.resourceSelectorOpen} 
                      close={this.closeRessourceSelectorLoader} 
                      resourceType={RESOURCE_TYPE_PrintLabel}
                      /> : null}
        </LRModal> 
    )
 
    
} 


  onCreateNewPrintLabel = async() =>
  {
    let label = await window.LR_AddNewPrintLabel()

    this.close()

    window.LR_OpenGeometryEditMode({UUID: label.UUID})
  }

  onEditPrintLabel = () =>
  {
    this.close()

    window.LR_OpenGeometryEditMode({UUID: this.state.selectedLabel.UUID})
  }

  onDownloadNewLabel = () =>
  {
    this.setState({resourceSelectorOpen: true})
  }

  closeRessourceSelector = () =>
  {
    this.setState({resourceSelectorOpen: false, resourceSelectorLoaderOpen: false})
    globalCallbacks.showPrintDialog();

  }

  closeRessourceSelectorLoader = () =>
  {
    this.setState({resourceSelectorLoaderOpen: true})

  }

  renderSort = () => {

    return (
      <>
        <BasicSelect
          name="selectedSortBy"
          multiple
          label={LocalizedStrings.SortByProperty}
          value={this.state.selectedSortBy}
          placeholder={LocalizedStrings.Search}
          options={this.state.sortByOptions}
          onStateUpdate={this.updateState}
        />
        <BasicSelect
          name="selectedSortFromTo"
          label={LocalizedStrings.SortFrom}
          value={this.state.selectedSortFromTo}
          disabled={this.state.selectedSortBy === "<None>"}
          options={this.state.sortFromToOptions}
          onStateUpdate={this.updateState}
        />
      </>
    );
  }

  renderFilter = () => {
    let filterOptions = [...this.state.sortByOptions]
    filterOptions.push({key : "<None>", text : "<None>", value : "<None>"})

    const selectedFilterField = filterOptions.find(opt => opt.value === this.state.filter.prop)

    return (
      <Form.Group inline>
        <BasicSelect
          name="prop"
          label={LocalizedStrings.FilterByProperty}
          value={this.state.filter.prop}
          placeholder={LocalizedStrings.Search}
          options={this.state.sortByOptions}
          onStateUpdate={this.updateFilterState}
        />
        {selectedFilterField.baseUnit === BASE_UNIT_STRING ? (
          <Form.Input
            label    = {LocalizedStrings.SubStr}
            name     = "subStr"
            onChange = {(e, { name, value }) => {
              this.updateFilterState(name, value);
            }}
          />
        ) : (
          <>
            <UnitInput
              baseUnit={BASE_UNIT_NUMBER}
              min={0}
              name="from"
              disabled={this.state.filter.prop === "<None>"}
              label={LocalizedStrings.FilterFrom}
              value={this.state.filter.from}
              onStateUpdate={this.updateFilterState}
            />
            <UnitInput
              baseUnit={BASE_UNIT_NUMBER}
              min={Math.max(0, this.state.filter.from)}
              name="to"
              disabled={this.state.filter.prop === "<None>"}
              label={LocalizedStrings.To}
              value={this.state.filter.to}
              onStateUpdate={this.updateFilterState}
            />
          </>
        )}
      </Form.Group>
    );
  }

  updateFilterState = (name, value) => {
    this.setState({ filter: { ...this.state.filter, [name]: value } })
  }

  createShareLink = async(e, data) =>
  {
    await window.LR_CreateShareLinkForProject({Name: data.value})
    globalCallbacks.showPrintDialog()
  }

  // canvas prop, accumulates keys for labels not to print
  onClick = (key) => {
    let keys = [...this.state.doNotPrintKeys]
    let index = keys.indexOf(key)

    if(index > -1)
    {
      keys.splice(index, 1)
    }
    else
    {
      keys.push(key)
    }

    this.setState({doNotPrintKeys: keys})
  }

  updateState = (name, value) => {
    this.setState({[name]: value})
  }

  getLabelForMeasures = (currentLabel) => {
    let labelForSizeCalc = null;

    if (currentLabel && currentLabel.UUID == EMPTY_UUID) {
      if (this.firstObjLabel) {
        labelForSizeCalc = this.firstObjLabel;
      }
    }

    if (!labelForSizeCalc) {
      labelForSizeCalc = currentLabel;
    }
    
    return labelForSizeCalc
  }

  updateFields = async (label) => {    

    let visibleFields = []
    let sortByOptions = []
    let xCount = 0
    let yCount = 0

    let labelForSizeCalc = this.getLabelForMeasures(label)
    console.log("LABEL updateFields", labelForSizeCalc?.Name)

    if (labelForSizeCalc) 
    {
      // idea : we place labels in the area that is page - spacing; for n labels we need n-1 margins, so we add a theoretical one to available space
      xCount = Math.floor((labelForSizeCalc.PaperSizeX - 2 * labelForSizeCalc.MarginX + labelForSizeCalc.SpacingX) / (labelForSizeCalc.SizeX + labelForSizeCalc.SpacingX)+0.1)
      yCount = Math.floor((labelForSizeCalc.PaperSizeY - 2 * labelForSizeCalc.MarginY + labelForSizeCalc.SpacingY) / (labelForSizeCalc.SizeY + labelForSizeCalc.SpacingY)+0.1)

      visibleFields = labelForSizeCalc.Fields ?? []
      for(let entry of visibleFields){
        if(entry.PrintLabelFieldType !== PRINT_LABEL_FIELD_TYPE.DynamicText){
          continue
        }
  
        let propertyName = entry.PropertyName

        if (!entry.ArrayName) { propertyName = LocalizedStringsObjectProps[entry.PropertyName]}
        else 
        {
          if (LocalizedStringsObjectProps[entry.ArrayName + "_" + entry.PropertyName])  { propertyName =  LocalizedStringsObjectProps[entry.ArrayName + "_" + entry.PropertyName] }
          else                                                                          { propertyName = LocalizedStringsObjectProps[entry.PropertyName] }
        }

        sortByOptions.push({
          key: entry.ArrayName + "_" + entry.PropertyName,
          text: propertyName,
          value: entry.PropertyName,
          baseUnit: entry.BaseUnit
        });
      }

      //visibleFields = Object.values(label.Fields).filter(entry => entry.Visible);

      /*visibleFields.forEach(entry => { if(entry.PropertyName !== "QRCodeField") { sortByOptions.push({  key  : entry.PropertyName, 
                                                                                                        text : LocalizedStringsObjectProps[entry.PropertyName], 
                                                                                                        value: entry.PropertyName }) } })*/
    }

    this.setState({selectedLabel: label, visibleFields, sortByOptions, xCount, yCount})
  }

  getObjectStr = (value) =>
  {
    switch(value)
    {
      case LABEL_OBJECT_FILTER_RACK : return LocalizedStrings.Rack;
      case LABEL_OBJECT_FILTER_CASE : return LocalizedStrings.Case;
      case LABEL_OBJECT_FILTER_OBJECT: return LocalizedStrings.Object;
      default: return ""
    }
  }

  getLabelFromFirstObject = async (printLabels) => {
    let firstObject =await window.LR_GetFirstObject();

    let objectTree = await  window.LR_GetObjectTree({
      FirstUUID: firstObject.UUID,
      CompleteTree: true,
      IncludeGeometries: true,
      Async: true
    })

    let objKey
    for (objKey in objectTree) {
      let current = objectTree[objKey]

      if (current.Resolved.Label3D != EMPTY_UUID) {

        let labelKey
        for (labelKey in printLabels) {
          if (printLabels[labelKey].UUID == current.Resolved.Label3D) {
            return printLabels[labelKey];
          }
        }
      }
    }

    return null
  }  

  setUpCallbacks()
  {
    globalCallbacks.showPrintDialog = async () => 
    { 
        let printLabels = await window.LR_GetPrintLabels()          

        let printLabelOptions = printLabels.PrintLabels.map(label => {
            return {key: label.UUID, 
                    text: <React.Fragment>
                            <Label>{this.getObjectStr(label.ObjectFilter)}</Label>
                            {label.Name}
                          </React.Fragment>,
                    value: label} 
          })
            
        this.firstObjLabel = await this.getLabelFromFirstObject(printLabels.PrintLabels)

        this.updateFields(printLabels.PrintLabels[0])

        this.setState({
          labels: [...printLabelOptions,
          {
            key: EMPTY_UUID,
            text: LocalizedStrings.UseFromObject,
            value: { UUID: EMPTY_UUID }
          }]
        })                            

        window.LR_GetLinkedProject().then(result => 
        {
          this.setState({
              Project: result.Project
          })

        })

        window.LR_GetWorksheets().then(worksheets => 
        
          {
          let worksheetOptions = worksheets.Worksheets.map(worksheet => {
            return {key: worksheet.UUID, text: worksheet.Name, value: worksheet}
          })
          let sceneOption = {key: 101, text: "Scene", value: {Name: "Scene", UUID: EMPTY_UUID}}
          this.setState({worksheets: [sceneOption, ...worksheetOptions], selectedWorksheet: {Name: "Scene", UUID: EMPTY_UUID}})
        })

        let shareLinks = await window.LR_GetShareLinksForProject()    
        let options = []
        this.SHARE_LINK_MAP = {}
        if(Array.isArray(shareLinks))
        {
          options = shareLinks.map((e)=>
          {
            this.SHARE_LINK_MAP[e.name] = e.token

            return {
            key: e.name,
            text: e.name,
            value:e.name
          }})
        }
    
        options.push(
          {
            text:"<None>",
            value: "",
            key:"<None>"
          }
        )
    
    
        this.setState({openExportPdf: true, PublicShareLinkOptions: options})

        this.setState({open: true})
    }
  }

  onClose =() =>
  {
    this.setState({
      open: false,
      selectedSortBy: "<None>",
      sortByOptions: [],
      filter: {
        prop: "<None>",
        from: 0,
        to: 0,
        subStr: ""
      }
    })
  }

  onOk =() =>
  {
      
    let local = {}
    Object.keys(LocalizedStringsObjectProps).forEach(key => 
    {
      if(!key.startsWith("_")) local[key] = LocalizedStringsObjectProps[key]
    }) 

    window.LR_PrintLabels({ LabelUUID:      this.state.selectedLabel.UUID, 
                            WorksheetUUID:  this.state.selectedWorksheet.UUID, 
                            LocalizedStrings:  local, 
                            OnlySelected: this.state.OnlySelected,
                            TrussTapeModePrintSteps: this.state.TrussTapeModePrintSteps,
                            TrussTapeModeStepsWidth: this.state.TrussTapeModeStepsWidth,
                            TrussTapeMeasureFromMiddle: this.state.TrussTapeMeasureFromMiddle,
                            TrussTapeCorrectionFactor: this.state.TrussTapeCurrentLength / this.state.TrussTapeDesignLength,
                            TrussTapeMode: this.state.activeTab == 1,
                            PublicShareLink:  this.SHARE_LINK_MAP[ this.state.PublicShareLink ], 
                            OriginParentId: this.state.origins[this.state.selectedOriginIdx]?.ParentUuid,
                            OriginGeoId: this.state.origins[this.state.selectedOriginIdx]?.GeometryUuid,
                            PageInfo:       { CountX: this.state.xCount, 
                                              CountY: this.state.yCount,
                                              DoNotPrintKeys: this.state.doNotPrintKeys, 
                                              
                                              SortBy: this.state.selectedSortBy,
                                              SortFromTo: this.state.selectedSortFromTo,
                                              
                                              FilterBy: this.state.filter.prop !== "<None>" ? this.state.filter.prop : "",
                                              FilterFrom: this.state.filter.from,
                                              FilterTo: this.state.filter.to,
                                              FilterSubStr: this.state.filter.subStr,
                                              
                                              ScreenDPMM: getDPMM()}, 
                            })
    this.onClose()
  }
    
}

export default AlignPageModal;
