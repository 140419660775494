
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Form, Icon } from 'semantic-ui-react';
import LocalizedStrings from "../../localization/ObjectPropertyComponent";
import { kWireType_Input, 
        kWireType_Output, 
        kWireType_Generator, 
        kWireType_Consumer, 
        kWireType_Fuse,
        kWireType_NetworkProvider, 
        kWireType_NetworkInput, 
        kWireType_HotpatchInput, 
        kWireType_HotpatchOutput, 
        kWireType_NetworkOutput, 
        kWireType_NetworkInOut,
        GetConnectorInfoByName } from '../../util/defines';

const optionsTypeElectrical = [
    {
        text: "Input",
        value: kWireType_Input
    },
    {
        text: "Output",
        value: kWireType_Output
    },
    {
        text: "Generator",
        value: kWireType_Generator
    },
    {
        text: "Consumer",
        value: kWireType_Consumer
    },
    {
        text: "Fuse",
        value: kWireType_Fuse
    },
    {
        text: "Data Source",
        value: kWireType_NetworkProvider
    },
    {
        text: "Data Input",
        value: kWireType_NetworkInput
    },
    {
        text: "Data Output",
        value: kWireType_NetworkOutput
    },
    {
        text: "Data Bidirectional",
        value: kWireType_NetworkInOut
    },
    {
        text: "Hotpatch Input",
        value: kWireType_HotpatchInput
    },
    {
        text: "Hotpatch Output",
        value: kWireType_HotpatchOutput
    },
]

class ElectricalWireField extends Component 
{
    constructor(props)
    {
      super(props);
  
      this.state = 
      {
        optionsConnectorSignal:     [],
        optionsConnectorElectrical: []
      }
      this.dataOption = []
      this.electicalOption = []
    }

    componentDidMount = async () => 
    {
        let data      = await window.LR_GetConnectors_Data();
        let electical = await window.LR_GetConnectors_Electric();

        this.dataOption      = data.Connectors
        this.electicalOption = electical.Connectors

        let dataOption = this.dataOption.map( entry => {
            return {
            text:  entry.LocalizedName,
            value: entry.ConnectorName
        }})

        let electricalOption = this.electicalOption.map( entry => {
            return {
            text:  entry.LocalizedName,
            value: entry.ConnectorName
        }})

        dataOption = dataOption.sort((a,b)=> {return a.text < b.text ? -1 : 1} )
        electricalOption = electricalOption.sort((a,b)=> {return a.text < b.text  ? -1 : 1} )

        this.setState({optionsConnectorSignal: dataOption, optionsConnectorElectrical: electricalOption})
    }

   render = () =>
    {
        let connectorOptions = this.getConnectorOptions()
        return( 
            <React.Fragment>
                <Form.Select options={optionsTypeElectrical}
                             fluid
                             selectOnBlur={false}
                             size      = "mini"
                             name      = "WireType"
                             label     = {LocalizedStrings.WireType}
                             value     = {this.props.wireType}
                             onChange = {this.props.onValueChanged}/>
                {connectorOptions ? <Form.Select options={connectorOptions}
                             fluid
                             selectOnBlur={false}
                             size      = "mini"
                             search
                             name      = "ConnectorType"
                             label     = {LocalizedStrings.WireConnector}
                             value     = {this.props.connectorType}
                             onChange = { this.props.onValueChanged}/> :null}
                {this.getInputOutputPatch()}
            </React.Fragment>
          );
    }
    getConnectorOptions = () =>
    {
        switch(this.props.wireType)
        {
            case kWireType_Input           :
            case kWireType_Output          :
            case kWireType_Generator       :
            case kWireType_HotpatchInput       :
            case kWireType_HotpatchOutput       :
            case kWireType_Consumer        :return this.state.optionsConnectorElectrical;
            
            case kWireType_NetworkProvider :
            case kWireType_NetworkInput    :
            case kWireType_NetworkOutput   :
            case kWireType_NetworkInOut    : return this.state.optionsConnectorSignal;
            case kWireType_Fuse            : 
            default                        : return null
        }
    }

    getInputOutputPatch = () =>
    {
        switch(this.props.wireType)
        {
            case kWireType_Input           :
            case kWireType_HotpatchInput   :
            case kWireType_HotpatchOutput  :
            case kWireType_Output          :
            case kWireType_NetworkInput    :
            case kWireType_NetworkOutput   :
            case kWireType_NetworkInOut    :
            case kWireType_Fuse            : return this.getFieldsForConnector(this.props.connectorType)
            default                        : return null
        }
        
    }

    getElectricalInputField = (connectorId, objectConnector) =>
    {
        let isSame = this.props.connections?.[connectorId]?.IsSame 
        let name = "Connector" + connectorId

        let label = objectConnector.Name

        let value = null
        if(this.props.connections[connectorId])
        {
            value = this.props.connections[connectorId].Connections
        }
        else{
            console.log("Did not found", connectorId, this.props.connections)
        }

        return( <Form>
                    <Form.Group widths="equal">
                        <Form.Select
                            name = {name}
                            multiple
                            fluid
                            select
                            search
                            selectOnBlur={false}
                            connectorId = {connectorId}
                            options = {this.props.options}
                            size = "mini"
                            labelPosition = 'right'
                            label     = {label}
                            icon = 'power cord' 
                            placeholder={isSame === false ? LocalizedStrings.Multiple : ""}
                            value = {value}
                            onBlur    = {this.props.updateData} 
                            onKeyDown = {this.props.onKeyDown}
                            onChange = {this.props.onValueChanged} >
                        </Form.Select>
                        {isSame === false ? <Icon name='delete' color='red' style={{marginTop: "2.5rem", cursor: "pointer"}} onClick={(e) => {
                            if (this.props.onValueChanged)
                            {
                                this.props.onValueChanged(e, {name: name, value: [], connectorId: connectorId})
                            }
                        }}/> : null}
                    </Form.Group>
                </Form>)
    }


    getFieldsForConnector = () =>
    {
        if(this.props.wireType === kWireType_Fuse)
        {
            return(<React.Fragment>
                {this.getElectricalInputField(0, {Name: "IN"})}
                {this.getElectricalInputField(1, {Name: "OUT"})}
           </React.Fragment>)
        }
        else
        {
            let connectorObject = GetConnectorInfoByName(this.props.connectorType);
            if(!connectorObject)            { return null}
            if(!connectorObject.SocketInfo) { console.error(this.props.connectorType, connectorObject); return null}
    
            return(<React.Fragment>
                         {connectorObject.SocketInfo.map((entry, i) => {return this.getElectricalInputField(i, entry);})}
                    </React.Fragment>)
        }

    }
}

export default ElectricalWireField;