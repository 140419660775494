// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../content/img/background-planet-blue.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".not-found {\n  height: 80vh;\n  margin-bottom: -5em;\n  display: grid;\n  justify-content: center;\n  align-items: center;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 25vw -60vw / 1300px\n      no-repeat,\n    linear-gradient(to right, #353c94, #5711f0);\n}\n\n.not-found .callout {\n  background-color: #45bbff;\n}\n", "",{"version":3,"sources":["webpack://./src/component/NotFound/not-found.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB;;+CAE6C;AAC/C;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".not-found {\n  height: 80vh;\n  margin-bottom: -5em;\n  display: grid;\n  justify-content: center;\n  align-items: center;\n  background: url(../../content/img/background-planet-blue.png) 25vw -60vw / 1300px\n      no-repeat,\n    linear-gradient(to right, #353c94, #5711f0);\n}\n\n.not-found .callout {\n  background-color: #45bbff;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
