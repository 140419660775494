//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, {Component} from 'react';
import LocalizedStrings from "../../localization/NavigationContainer";
import { Icon, Label, Search, Segment, Table, Form } from 'semantic-ui-react';
import DepartmentNode from './DepartmentNode';
import escapeRegExp from "lodash/escapeRegExp"
import filter from "lodash/filter"
import { IsElectronContext } from '../../util/defines';
import LRModal from '../Basics/BasicModal';
import ColorInputField from '../ColorPicker/ColorInputField';

import { globalCallbacks as mainCB } from '../../util/callback';
import { globalCallbacks as mockCB } from '../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB
const SORT_BY = "Name"

class DepartmentTable extends Component
{
    constructor(props)
    {
        super(props)

        this.state = 
        {
            DepartmentObjects: [],
            editableDepartment: undefined,
            
            // search
            isLoading: false,
            result : [],
            value : "",
            sorting: null,
            indexMap: [],
        }
    }

    componentDidMount()
    {
        this.setUpCallbacks()
        globalCallbacks.updateDepartmentObjects()
    }

    render()
    {
        let DepartmentObjects = this.state.DepartmentObjects;

        return (
        <div style={{width:"100%", height:"100%"}}>
             <Table style={{borderBottom: "none", margin:0}}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell colSpan='4' style={{zIndex: 0}} onClick={this.onHeaderClick}>
                            {<Icon link style={{floated: 'left', position: 'absolute',zIndex: 1}} name={this.state.sorting === null ? "sort" : this.state.sorting === "ascending" ? "sort alphabet up": "sort alphabet down"}/>}
                            <Search open={false}
                                    loading={this.state.isLoading}
                                    value = {this.state.value}
                                    onClick = {(e)=>{e.stopPropagation()}}
                                    onSearchChange = {(e,{value}) => this.onSearchChange(value, DepartmentObjects)}
                                    aligned="right" />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
             </Table>

             <div style={{width:"100%", maxHeight:IsElectronContext() ? "calc(100vh - 25em)" :"calc(100vh - 30em)", overflowY:"auto", marginBottom:"5em"}}>
                <Table striped structured compact='very' size="small"> 
                    <Table.Body>
                        {this.renderRows()}
                    </Table.Body>
                </Table>
            </div>

            <Segment vertical textAlign="center" style={{position:"absolute", bottom:IsElectronContext() ? "1.5em" : "6em", width:"100%", border:"none"}}>
                <Label as="a" color="green" onClick={this.onAddNewDepartment}>
                    <Icon name="plus"/>{LocalizedStrings.AddNewDepartment}
                </Label>
            </Segment>

            <LRModal    open={this.state.editDepartmentObject !== undefined}
                                title={this.state.editDepartmentObject ? this.state.editDepartmentObject.Name : ""}
                                onCancelClick={this.closeEditColorCode}
                                onOkClick={this.applyEditColorCode}>
                            <Form>
                                <Form.Group widths="equal">
                                    <ColorInputField 
                                        label           = {LocalizedStrings.Color}
                                        colorX          = {this.state.editDepartmentObject ? this.state.editDepartmentObject.DepartmentColor.X : 0}
                                        colorY          = {this.state.editDepartmentObject ? this.state.editDepartmentObject.DepartmentColor.Y : 0}
                                        colorL          = {this.state.editDepartmentObject ? this.state.editDepartmentObject.DepartmentColor.Z : 0}
                                        onColorChanged  = {(cie) => {this.setState({editDepartmentObject: {...this.state.editDepartmentObject, DepartmentColor: {X: cie.fx, Y: cie.fy, Z: cie.f_Y}}})}}/>
                                </Form.Group>
                            </Form>
                </LRModal>
        </div>
        )
    }

    renderRows = () => {
        let DepartmentObjects = this.state.DepartmentObjects;
        let showData = !this.state.value ? DepartmentObjects : this.state.isLoading ? DepartmentObjects : this.state.results

        if(this.state.sorting) {
            let indexMap = []
            showData.forEach((_, i) => indexMap.push(i))
            indexMap.sort((a,b) => showData[a][SORT_BY] === showData[b][SORT_BY]  ? 0 : (showData[a][SORT_BY]  < showData[b][SORT_BY]  ? -1 : 1))
            return this.state.indexMap.map((_, i) => {
                let calcIndex = this.state.sorting === 'ascending' ? i : this.state.indexMap.length - i - 1
                return showData[this.state.indexMap[calcIndex]]
            }).map(department => <DepartmentNode key={department.UUID} department={department} />)
        }

        return showData.map(department => <DepartmentNode key={department.UUID} department={department} />)
    }

    closeEditColorCode = () =>
    {
        this.setState({editDepartmentObject: undefined})
    }

    applyEditColorCode = () =>
    {
        window.LR_SetDepartment(this.state.editDepartmentObject);

        this.setState({editDepartmentObject: undefined})
    }

    onHeaderClick = () => {
        let newVal = null
        switch (this.state.sorting) {
            case null:          newVal = "ascending";   break;
            case "ascending":   newVal = "descending";  break;
            default:            newVal = null;          break;
        }
        this.setState({
            sorting: newVal
        })
    }

    onSearchChange = (value, resources) => 
    {
      this.setState({isLoading: true, value: value})

        if(value.length < 1)
        {
          this.onSearchReset()
        }
        else
        {
          const re = new RegExp(escapeRegExp(value), 'i')
          const isMatch = (result) => {let res=re.test(result.Name); return res;}
        
          this.setState({results: filter(resources, isMatch), isLoading: false})
        }
    }

    onSearchReset = () => {
        this.setState({isLoading: false,
                       results  : [], 
                       value    : "",})
    }

    onAddNewDepartment = (e) => 
    {
        window.LR_AddNewDepartment({})
    }

    setUpCallbacks()
    {
        globalCallbacks.updateDepartmentObjects = async () =>
        {
            let departments = await window.LR_GetDepartments()
           
            this.setState({DepartmentObjects: departments.Departments})
        }

        globalCallbacks.openColorDepartmentEdit = async (request) =>
        {
            window.LR_GetDepartment({UUID: request.UUID}).then(res =>
            {
                this.setState({editDepartmentObject: res})
            })

        }
    }
}

export default DepartmentTable;