//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import LocalizedStrings from "../../localization/DmxOverview";
import React, { Component } from 'react';
import { Popup } from 'semantic-ui-react';
import { SELECT_Single, ABSOLUTE_VIEW} from '../../util/defines';
import { globalCallbacks } from "../../util/callback";

let color0Dmx   = { R: 1, G: 102, B:1,}
let color255Dmx = { R: 0, G: 255, B:0,}

let color0DmxNoDmx   = { R: 128, G: 128, B:128,}
let color255DmxNoDmx = { R: 220, G: 220, B:220,}

let colorStart0DmxNoDmx   = { R: 0, G: 0, B:0,}
let colorStart255DmxNoDmx = { R: 0, G: 0, B:255,}

class DmxChannel extends Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            isDragging: false
        }
    }

    render()
    {
        let dmx = this.props.dmxValue ? this.props.dmxValue : 255
        let backgroudColor = "white";
        let text;
        let popupContent;
        let popupDisabled = this.state.isDragging;
        if( ! this.props.match)
        {
            //Grey
            backgroudColor = this.colorMix(color0DmxNoDmx, color255DmxNoDmx, dmx/255);
            popupDisabled = true;
        }
        else if(this.props.match.length === 1)
        {
            if(this.props.match[0].ErrorTwoUniverse)
            {
                backgroudColor ="#E74C3C"
            }
            else
            {
                backgroudColor =this.getColor(this.props.match[0].Starting, dmx);
            }
            
            text = this.getEntry(this.props.match[0], true);
            popupContent = this.getPopupContent(this.props.match[0], true);
        }
        else if(this.props.match.length > 1)
        {
            //Red
            backgroudColor ="#E74C3C"
            for(let i_m = 0; i_m < this.props.match.length; i_m++)
            {
                if(this.props.match[i_m].Starting)
                {
                    backgroudColor ="#e73cd9"
                    break;
                }

            }
            
            text = this.props.match.map((entry, i) => this.getEntry(entry, (i+1) === this.props.match.length));
            popupContent = this.props.match.map((entry, i) => this.getPopupContent(entry, (i+1) === this.props.match.length));
        }

        let draggable = !!this.props.match

        return (
            <Popup
                disabled={popupDisabled}
                trigger={
                    <div 
                        draggable={draggable}
                        style={{ cursor: draggable ? "grab" : undefined, fontSize: "7px", minHeight: "8em", width: "8em", borderStyle: "solid", borderWidth: ".3px", backgroundColor: backgroudColor, paddingLeft: "4px", paddingRight: "4px", wordBreak: "normal", wordWrap: "normal" }}
                        onDrop={this.onDrop}
                        onDrag={this.onDrag}
                    >
                        <b style={{ fontSize: "14px" }}>{this.props.viewType === ABSOLUTE_VIEW ? this.props.absoluteAddress : this.props.universe + "." + this.props.channel}</b>
                        <i style={{ textAlign: "left" }}> {dmx} </i>
                        <br />
                        {text}
                    </div>
                }
                content={popupContent}
            />);
    }

    static lastDragged

    onDrag = () =>
    {
        this.setState({isDragging: true})
        if(this.props.match)
        {
            DmxChannel.lastDragged = this.props.match[0]
        }
    }

    onDrop = (e) => 
    {
        this.setState({isDragging: false})

        let it = DmxChannel.lastDragged
        window.LR_SetObject({
            UUID: it.UUID,
            [`DmxAddress${it.Break}`]: this.props.absoluteAddress
        })
    }

    getColor(starting, dmx)
    {
        if(starting)
        {
            return this.colorMix(colorStart0DmxNoDmx, colorStart255DmxNoDmx, dmx/255);
        }
        else
        {
            return this.colorMix(color0Dmx, color255Dmx, dmx/255);
        }
    }

    getEntry(entry, last)
    {
        return(<span key={`${entry.UUID}_${entry.Break}`} onClick={ () => this.setSelected(entry.UUID)}>#{entry.FixtureId}{last ? "" : ", "}</span>);
    }

    getPopupContent = (entry, last) =>
    {
        let footprint = "";
        switch(entry.Break)
        {
            case 1:
            {
                footprint = "DmxFootPrint1: " + entry.DmxFootPrint1;
                break;
            }
            case 2:
            {
                footprint = "DmxFootPrint2: " + entry.DmxFootPrint2;
                break;
            }
            case 3:
            {
                footprint = "DmxFootPrint3: " + entry.DmxFootPrint3;
                break;
            }
            case 4:
            {
                footprint = "DmxFootPrint4: " + entry.DmxFootPrint4;
                break;
            }
            default: break;
        }

        return(
            <span key={`${entry.UUID}_${entry.Break}`}>
                {entry.Name} <br/>
                {footprint} <br/>
                {LocalizedStrings.FixtureID + ": " + entry.FixtureId} <br/>
                {last ? "" : <br/>}
            </span>
        );
    }

    setSelected = (uuid) =>
    {
  
      let request = 
      {
        Selected      : true,
        SelectionMode : SELECT_Single,
        SelectionGroupMode : globalCallbacks.selectionGroupMode,
        ShiftKey      : false,
        MetaKey       : false,
        AltKey        : false,
        SelectedList  : [uuid]
      }
  
      window.LR_Select(request);
    }

    colorMix = (colorA, colorB, percent) =>
    {
        let col  = {
            R: colorA.R	+ percent * (colorB.R		- colorA.R),
            G:colorA.G	+ percent * (colorB.G		- colorA.G),
            B:colorA.B	+ percent * (colorB.B		- colorA.B)
        }
        return "rgba(" +col.R + "," +col.G + "," +col.B + ", 0.7)"
    }
}

export default DmxChannel;