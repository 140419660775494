import LRFilterInput from '@component/Basics/FilterField';
import React, { Component, useEffect, useMemo, useState } from 'react';
import { Button, Form, Header, Icon, Modal, Table } from 'semantic-ui-react';
import LocalizedStrings from "../../localization/ResourceManager";
import { globalCallbacks } from '../../util/callback';
import { BASE_UNIT_POWER, EMPTY_UUID, IsRunningAsBrowser, IsVectorworksContext } from '../../util/defines';
import LRModal from '../Basics/BasicModal';
import UnitInput from '../Basics/BasicUnitInput';
import DynamicTable from '../Basics/DynamicTableView';
import ColorInputField from '../ColorPicker/ColorInputField';
import { cie2hex } from '../ColorPicker/utilities';

const DEFAULT_COLOR = { X: 0.3209, Y: 0.1542, Z: 28.48 };

function SocketNode({
    socketNodeData,
    onNameChange,
    onColorChange,
    onDelete,
    disabled
}) {

    return (
        <Table.Row>
            <Table.Cell>
                <Form.Input
                    value={socketNodeData.Name}
                    label={LocalizedStrings.Name}
                    onChange={(e, { value }) => onNameChange(value)}
                    disabled={disabled}
                />
            </Table.Cell>
            <Table.Cell>
                <ColorInputField
                    label={LocalizedStrings.Color}
                    labelPosition='right'
                    colorX={socketNodeData.Color.X}
                    colorY={socketNodeData.Color.Y}
                    colorL={socketNodeData.Color.Z}
                    onColorChanged={(cie) => onColorChange({ X: cie.fx, Y: cie.fy, Z: cie.f_Y })}
                    disabled={disabled}
                />
            </Table.Cell>
            <Table.Cell>
                {
                    disabled ? null : <Icon
                        name="delete"
                        color="red"
                        link
                        onClick={() => onDelete()}
                    />
                }
            </Table.Cell>
        </Table.Row>
    )
}

function SocketTable({
    onSave,
    data,
    isDefault
}) {
    return (
        <>
            <Table celled fixed>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{LocalizedStrings.Name}</Table.HeaderCell>
                        <Table.HeaderCell>{LocalizedStrings.Color}</Table.HeaderCell>
                        {isDefault ? null : <Table.HeaderCell>{LocalizedStrings.Delete}</Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {data.map((item, index) => (
                        <SocketNode
                            disabled={isDefault}
                            socketNodeData={item}
                            onNameChange={(value) => {
                                data[index].Name = value
                                onSave(data)
                            }}
                            onColorChange={(value) => {
                                data[index].Color = value
                                onSave(data)
                            }}
                            onDelete={() => {
                                data.splice(index, 1)
                                onSave(data)
                            }}
                            socketNode={item}
                            key={index}
                        />
                    ))}

                    {isDefault ? null :
                        <Table.Row>
                            <Table.Cell colSpan="3">
                                <Button
                                    positive
                                    onClick={() => {
                                        data.push({ Name: "", Color: DEFAULT_COLOR })
                                        onSave(data)
                                    }}
                                >
                                    {LocalizedStrings.AddSocket}
                                </Button></Table.Cell>
                        </Table.Row>
                    }
                </Table.Body>
            </Table>
        </>
    )
}

function CompatibleConnectorNode({
    availableConnectorsList,
    compatibleConnectorData: el,
    data,
    onChange,
    onDelete
}) {
    let list = el ? [{ key: el.ConnectorName, text: el.LocalizedName, value: el.ConnectorName }, ...availableConnectorsList] : availableConnectorsList
    return (
        <Table.Row>
            <Table.Cell style={{ overflow: "visible" }}>
                <Form.Dropdown
                    fluid
                    search
                    value={data.OtherConnectorName}
                    options={list}
                    onChange={(_, { value }) => onChange(value)}
                    placeholder={LocalizedStrings.SelectCompatibleConnector}
                />
            </Table.Cell>
            <Table.Cell>
                {
                    data.Default ? null : <Icon
                        name="delete"
                        color="red"
                        link
                        onClick={() => onDelete()}
                    />
                }
            </Table.Cell>
        </Table.Row>
    )
}

function CompatibleWithTable({
    onSave,
    data,
    availableConnectors
}) {

    let availableConnectorsList = useMemo(() => {
        return availableConnectors
            .filter(el => !data.some(d => d.OtherConnectorName === el.ConnectorName))
            .map(el => ({ key: el.ConnectorName, text: el.LocalizedName, value: el.ConnectorName }))
    }, [availableConnectors, data])

    return (
        <>
            <Table celled fixed>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={1}>{LocalizedStrings.CompatibleConnectorsWith}</Table.HeaderCell>
                        <Table.HeaderCell width={1}>{LocalizedStrings.Delete}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {data.map((item, index) => (
                        <CompatibleConnectorNode
                            availableConnectorsList={availableConnectorsList}
                            compatibleConnectorData={availableConnectors.find(i => i.ConnectorName === item.OtherConnectorName)}
                            data={item}
                            onChange={(value) => {
                                data[index].OtherConnectorName = value
                                onSave(data)
                            }}
                            onDelete={() => {
                                data.splice(index, 1)
                                onSave(data)
                            }}
                            socketNode={item}
                            key={index}
                        />
                    ))}

                    <Table.Row>
                        <Table.Cell colSpan={2}>
                            <Button
                                positive
                                onClick={() => {
                                    data.push({ OtherConnectorName: "", Default: false })
                                    onSave(data)
                                }}
                            >
                                {LocalizedStrings.AddCompatibleConnector}
                            </Button>
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </>
    )
}

function CreateConnectorModal({
    open,
    onClose,
    onSave,
    data,
    isDataConnector,
    checkNameAvailable,
    allConnectorList,
    cableSets
}) {

    let [modData, setModData] = useState(data ?? {
        Color: DEFAULT_COLOR,
        SocketInfo: [],
        ConnectorName: "",
        MaxPayload: 0,
        IsData: isDataConnector,
        LocalizedName: "",
        Default: false,
        CompatibleWith: []
    })

    let isDefault = modData.Default
    let nameIsUnique = data !== undefined || isDefault || checkNameAvailable(modData.ConnectorName)

    useEffect(() => {
        setModData(data ?? {
            Color: DEFAULT_COLOR,
            SocketInfo: [],
            ConnectorName: "",
            MaxPayload: 0,
            IsData: isDataConnector,
            LocalizedName: "",
            DefaultCableSetUUID: EMPTY_UUID
        })
    }, [data, open])

    let modifyData = (name, value) => setModData(i => ({ ...i, [name]: value }))

    let compatibleWithList = useMemo(() => {
        return allConnectorList.filter(el => el.ConnectorName !== modData.ConnectorName && el.IsData === isDataConnector)
    }, [data, allConnectorList, isDataConnector])

    let [onBeforeSaveData, setOnBeforeSaveData] = useState(undefined)

    let onSaveClick = () => {
        let newData = { ...modData }
        if (!newData.LocalizedName) {
            newData.LocalizedName = newData.ConnectorName
        }
        if (data && data.DefaultCableSetUUID !== newData.DefaultCableSetUUID) {
            setOnBeforeSaveData(newData)
        } else {
            onSave(newData)
            onClose()
        }
    }

    let onCloseByCallbackModal = (val) => {
        onSave(onBeforeSaveData, val); 
        onClose()
        setOnBeforeSaveData(undefined)
    }

    if (!open) {
        return null;
    }

    return (
        <LRModal
            title={LocalizedStrings.CreateConnectorHeader}
            open={open}
            onCancelClick={onClose}
            okDisabled={!modData.ConnectorName.length || !nameIsUnique}
            onOkClick={onSaveClick}
            scrolling={false}
        >
            <Modal
                open={onBeforeSaveData !== undefined}
                onClose={() => setOnBeforeSaveData(undefined)}
            >
                <Modal.Header>{LocalizedStrings.OverwriteExistingCableSetHeader}</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        {LocalizedStrings.OverwriteExistingCableSetText}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={() => onCloseByCallbackModal(false)}>
                        {LocalizedStrings.No}
                    </Button>
                    <Button positive onClick={() => onCloseByCallbackModal(true)}>
                        {LocalizedStrings.Yes}
                    </Button>
                </Modal.Actions>
            </Modal>
            <Form>
                <Form.Group widths="equal">
                    <Form.Input
                        value={modData.ConnectorName}
                        label={LocalizedStrings.Name}
                        onChange={(e, { value }) => modifyData("ConnectorName", value)}
                        disabled={data !== undefined || isDefault}
                        error={!nameIsUnique ? LocalizedStrings.NameMustBeUnique : undefined}
                    />
                    <Form.Input
                        value={modData.LocalizedName}
                        label={LocalizedStrings.LocalizedName}
                        onChange={(e, { value }) => modifyData("LocalizedName", value)}
                        placeholder={modData.ConnectorName}
                        disabled={isDefault}
                    />
                </Form.Group>
                <Form.Group widths="equal">
                    <ColorInputField
                        disabled={isDefault}
                        label={LocalizedStrings.Color}
                        labelPosition='right'
                        colorX={modData.Color.X}
                        colorY={modData.Color.Y}
                        colorL={modData.Color.Z}
                        onColorChanged={(cie) => modifyData("Color", { X: cie.fx, Y: cie.fy, Z: cie.f_Y })} />

                    {isDataConnector ? null : <UnitInput
                        disabled={isDefault}
                        name="MaxPayload"
                        value={modData.MaxPayload}
                        label={LocalizedStrings.MaxPayload}
                        baseUnit={BASE_UNIT_POWER}
                        min={0}
                        onStateUpdate={(name, value, e) => modifyData(name, value)}
                    />}
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Dropdown
                        fluid
                        search
                        selection
                        value={modData.DefaultCableSetUUID}
                        options={cableSets}
                        onChange={(_, { value }) => modifyData("DefaultCableSetUUID", value)}
                        label={LocalizedStrings.OverwriteDefaultCableSet}
                    />
                </Form.Group>
                <SocketTable
                    data={structuredClone(modData.SocketInfo)}
                    onSave={(data) => modifyData("SocketInfo", data)}
                    isDefault={isDefault}
                />
                <CompatibleWithTable
                    data={structuredClone(modData.CompatibleWith ?? [])}
                    onSave={(data) => modifyData("CompatibleWith", data)}
                    availableConnectors={compatibleWithList}
                />
            </Form>
        </LRModal>
    )
}

class ConnectorTemplates extends Component {
    constructor(props) {
        super(props);

        this.state = {
            connectorTemplates: [],
            dataConnectorTemplates: [],
            electricConnectorTemplates: [],
            createConnectorModalOpen: false,
            modalData: undefined,
            modalIsDataConnector: false,
            searchFilterText: ""
        };
    }

    componentDidMount = async () => {
        this.setUpCallback();
        globalCallbacks.updateConnectorTemplates()
    };

    saveModalData = (data, updateAllConnectors) => {
        let fun = this.state.modalData === undefined ? window.LR_AddConnectorsTemplate : window.LR_SetConnectorsTemplate;
        data.Name = data.ConnectorName;
        fun({connector: data, updateAllConnectors}).then(() => {
            if (globalCallbacks.updateConnectorTemplates && IsRunningAsBrowser) { globalCallbacks.updateConnectorTemplates() }
        })
    }

    openCreateModal = (data, isData) => this.setState({
        createConnectorModalOpen: true,
        modalData: data,
        modalIsDataConnector: isData ?? false
    });

    closeCreateModal = () => this.setState({
        createConnectorModalOpen: false
    });


    onDeleteConnectorTemplate = (entry) => {
        window.LR_DeleteConnectorsTemplate({ Name: entry.ConnectorName }).then(() => {
            if (globalCallbacks.updateConnectorTemplates && IsRunningAsBrowser) {
                globalCallbacks.updateConnectorTemplates();
            }
        })
    };

    render() {
        //----------------------------------------------------
        // define dynamic table view props

        const data_headerIdent = [
            { editable: false, unit: undefined, sortable: true, ident: "ConnectorName", label: LocalizedStrings.ConnectorName },
            { editable: false, unit: undefined, sortable: true, ident: "LocalizedName", label: LocalizedStrings.LocalizedName },
            { editable: false, unit: undefined, sortable: true, ident: "Color", label: LocalizedStrings.Color },
            { editable: false, unit: undefined, ident: "Edit", label: LocalizedStrings.EditConnector },
            { editable: false, unit: undefined, ident: "Delete", label: LocalizedStrings.DeleteConnector },
        ];

        const elec_headerIdent = [
            { editable: false, unit: undefined, sortable: true, ident: "ConnectorName", label: LocalizedStrings.ConnectorName },
            { editable: false, unit: undefined, sortable: true, ident: "LocalizedName", label: LocalizedStrings.LocalizedName },
            { editable: false, unit: undefined, sortable: true, ident: "Color", label: LocalizedStrings.Color },
            { editable: false, sortable: true, ident: "MaxPayload", label: LocalizedStrings.MaxPayload, unit: BASE_UNIT_POWER },
            { editable: false, unit: undefined, ident: "Edit", label: LocalizedStrings.EditConnector },
            { editable: false, unit: undefined, ident: "Delete", label: LocalizedStrings.DeleteConnector },
        ];

        const cellRender = (entry, rowIndex) => {
            return {
                ConnectorName: entry.ConnectorName,
                Edit: (<Icon
                    name="edit"
                    color="black"
                    link
                    textAlign="right"
                    onClick={() => this.openCreateModal(entry, entry.IsData)}
                />),

                Delete: (
                    <>
                        {entry.Default ?
                            LocalizedStrings.DeleteConnectorNodePossible :
                            (
                                <Icon
                                    name="delete"
                                    color="red"
                                    link
                                    textAlign="right"
                                    onClick={() => this.onDeleteConnectorTemplate(entry)}
                                />
                            )}
                    </>
                ),
                Color: (<div style={{ width: "4rem", height: "1rem", backgroundColor: cie2hex(entry.Color) }} />),
                MaxPayload: entry.MaxPayload / 1000,
                IsData: entry.IsData ? LocalizedStrings.IsDataConnection : LocalizedStrings.IsNotDataConnection,
                LocalizedName: entry.LocalizedName
            };
        };

        let filteredDataConnectorTemplates = [...this.state.dataConnectorTemplates];
        let filteredElectricConnectorTemplates = [...this.state.electricConnectorTemplates];      

        if(this.state.searchFilterText !== "") {
            filteredDataConnectorTemplates = filteredDataConnectorTemplates.filter(entry => (  
                                            (entry?.ConnectorName.toLowerCase().includes(this.state.searchFilterText.toLowerCase())) ||
                                            (entry?.LocalizedName.toLowerCase().includes(this.state.searchFilterText.toLowerCase())))); 

            filteredElectricConnectorTemplates = filteredElectricConnectorTemplates.filter(entry => (
                                                (entry?.ConnectorName.toLowerCase().includes(this.state.searchFilterText.toLowerCase())) || 
                                                (entry?.LocalizedName.toLowerCase().includes(this.state.searchFilterText.toLowerCase())))); 
        }

        const data_cellData = filteredDataConnectorTemplates.map(el => ({ ...el, editable: !el.Default }));
        const elec_cellData = filteredElectricConnectorTemplates.map(el => ({ ...el, editable: !el.Default }));

        return (
          <>
            <div style={{position: "fixed", top: IsVectorworksContext() ? "8.5rem" : "4.5rem", left: "53.5rem", zIndex: 1000, width: "30rem"}} >
                <LRFilterInput style={{maxWidth: "30%"}} noLabel value={this.state.searchFilterText} onChange={(value) => this.setState({searchFilterText: value})} />
            </div>
            <div style={{marginTop: "2rem", overflowY: "auto", maxHeight: "100%"}}>
                <Header as="h2">{LocalizedStrings.ElectricalConnectors}</Header>
                <DynamicTable
                    cellRender={cellRender}
                    headerIdent={elec_headerIdent}
                    cellData={elec_cellData}
                    footerRow={
                        <Table.HeaderCell colSpan="29">
                            <Button
                                floated="left"
                                icon
                                labelPosition="left"
                                primary
                                onClick={() => this.openCreateModal(undefined, false)}
                                size="small"
                            >
                                <Icon name="plug" />
                                {LocalizedStrings.CreateConnectorElectrical}
                            </Button>
                        </Table.HeaderCell>
                    }
                />
                <Header as="h2">{LocalizedStrings.DataConnectors}</Header>
                <DynamicTable
                    cellRender={cellRender}
                    headerIdent={data_headerIdent}
                    cellData={data_cellData}
                    footerRow={
                        <Table.HeaderCell colSpan="29">
                            <Button
                                floated="left"
                                icon
                                labelPosition="left"
                                primary
                                onClick={() => this.openCreateModal(undefined, true)}
                                size="small"
                            >
                                <Icon name="plug" />
                                {LocalizedStrings.CreateConnectorData}
                            </Button>
                        </Table.HeaderCell>
                    }
                />
                <CreateConnectorModal
                    data={this.state.modalData}
                    isDataConnector={this.state.modalIsDataConnector}
                    onClose={this.closeCreateModal}
                    onSave={this.saveModalData}
                    open={this.state.createConnectorModalOpen}
                    checkNameAvailable={name => !this.state.connectorTemplates.some((socket) => socket.ConnectorName === name)}
                    allConnectorList={this.state.connectorTemplates}
                    cableSets={this.state.cableSets}
                />
            </div>
          </>
        )
    }

    setUpCallback = () => {
        globalCallbacks.updateConnectorTemplates = async () => {
            this.setState({ isLoading: true });
            let connectorData = await window.LR_GetConnectors_Data();
            let electricConnectors = await window.LR_GetConnectors_Electric();

            let cableSets = await window.LR_GetSymbolDefs()
                .then(res => res.SymbolDefs.filter(i => i.UseAsCableInventory))
                .then(res => res.map(i => ({
                    key: i.UUID,
                    value: i.UUID,
                    text: i.Name
                })))

            cableSets.push({
                key: EMPTY_UUID,
                value: EMPTY_UUID,
                text: LocalizedStrings.UseDefaultCableSet
            })

            this.setState({
                connectorTemplates: [...connectorData.Connectors, ...electricConnectors.Connectors],
                dataConnectorTemplates: connectorData.Connectors,
                electricConnectorTemplates: electricConnectors.Connectors,
                cableSets,
                isLoading: false
            })
        }
    }

}

export default ConnectorTemplates