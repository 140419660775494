import React, { useEffect, useRef, useState } from "react";
import { Accordion, Button, Dimmer, Divider, Form, Header, Icon, Loader, Segment } from "semantic-ui-react";
import LocalizedStrings from "../../localization/UserOverview";
import { globalCallbacks as mainCB } from '../../util/callback';
import { globalWindowInterface } from "../../util/callbackTypes";
import { BASE_UNIT_DATE } from "../../util/defines";
import { globalCallbacks as mockCB } from '../../util/mock_callback';
import LRModal from "../Basics/BasicModal";
import UnitInput from "../Basics/BasicUnitInput";
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB

declare const window: globalWindowInterface;

const headerStyle = {
  cursor: 'pointer',
  display: "flex",
  flexDirection: "row",
  alignItems: "center"
};

interface ISettings {
  DueDate: any
  ConstructionDate: any
  EventDate: any
  DismantleDate: any
  ProjectPrettyName: string
  Company: string
  UserForStructural: string
  Impressum: string
  ProjectManager: string
  ProjectDescription: string
  LocationPrettyName: string
  Street: string
  Number: string
  PostalCode: string
  City: string
  Country: string
}

let lastChangedWasSecond = false
export default function ProjectInformation(this: unknown, {
  drawingSettings,
  description,
  noDeleteButton,
  onDataUpdate
}: {
  drawingSettings: ISettings,
  description: string,
  noDeleteButton?: boolean,
  onDataUpdate?: (name: string, value: any) => void
}) {
  const [activeIndex, setActiveIndex] = useState(lastChangedWasSecond ? 1 : 0)
  const [settingsState, setSettingsState] = useState(drawingSettings)
  const [descState, setDescState] = useState(description)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [currentlyDeleting, setCurrentlyDeleting] = useState(false)
  
  const onUpdate = async (name: string, value: string) => {
    lastChangedWasSecond = [
      "LocationPrettyName", "Street", "Number", "PostalCode", "City", "Country",
    ].includes(name)

    setSettingsState(i => ({
      ...i,
      [name]: value
    }))

    if (onDataUpdate) {
      onDataUpdate(name, value)
    }
  }

  const onBlur = () => {
    if (!onDataUpdate) {
      window.LR_SetProjectSettings({ DrawingSettings: settingsState, description: descState })
    }
  }

  const onDelete = async () => {
    setCurrentlyDeleting(true)
    let res = await globalCallbacks.deleteProject();
    setCurrentlyDeleting(false)
    if (res) {
      window.location.href = window.location.protocol + "//" + window.location.host;
    }
    setDeleteModalOpen(false)
  }

  return (
    <Accordion fluid styled>
      <Accordion.Title
        active={activeIndex === 0}
        index={0}
        onClick={() => setActiveIndex(0)}
        style={headerStyle}
      >
        <Icon name='dropdown' />
        <Header style={{ margin: 0 }} as="h2">{LocalizedStrings.GeneralInformation}</Header>
      </Accordion.Title>

      <Accordion.Content active={activeIndex === 0}>
        <Form>
          <Form.Input
            label={LocalizedStrings["ProjectPrettyName"]}
            value={settingsState["ProjectPrettyName"]}
            onChange={(e, { value }) => onUpdate("ProjectPrettyName", value)}
            onBlur={onBlur}
            onKeyDown={(e) => { if (e.keyCode === 13) { onBlur(); e.target.blur() } }}
          />
          <Form.Input
            label={LocalizedStrings["ProjectDescription"]}
            value={descState}
            onChange={(e, { value }) => {setDescState(value); onDataUpdate?.("ProjectDescription", value)}}
            onBlur={onBlur}
            onKeyDown={(e) => { if (e.keyCode === 13) { onBlur(); e.target.blur() } }}
          />
          {
            ["Company", "ProjectManager", "UserForStructural", "Impressum"].map(i => <Form.Input
              key={i}
              label={LocalizedStrings[i]}
              value={settingsState[i]}
              onChange={(e, { value }) => onUpdate(i, value)}
              onBlur={onBlur}
              onKeyDown={(e) => { if (e.keyCode === 13) { onBlur(); e.target.blur() } }}
            />)
          }
          <Form.Group widths="equal">
            <Form.Field>
              <label>{LocalizedStrings.DueDate}</label>
              <UnitInput
                baseUnit={BASE_UNIT_DATE}
                name="DueDate"
                value={settingsState.DueDate}
                onStateUpdate={onUpdate}
                onUpdateData={onBlur}
                isClearable
              />
            </Form.Field>
            <Form.Field>
              <label>{LocalizedStrings.ConstructionDate}</label>
              <UnitInput
                baseUnit={BASE_UNIT_DATE}
                name="ConstructionDate"
                value={settingsState.ConstructionDate}
                onStateUpdate={onUpdate}
                onUpdateData={onBlur}
                isClearable
              />
            </Form.Field>

          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>{LocalizedStrings.EventDate}</label>
              <UnitInput
                baseUnit={BASE_UNIT_DATE}
                name="EventDate"
                value={settingsState.EventDate}
                onStateUpdate={onUpdate}
                onUpdateData={onBlur}
                isClearable
              />
            </Form.Field>
            <Form.Field>
              <label>{LocalizedStrings.DismantleDate}</label>
              <UnitInput
                baseUnit={BASE_UNIT_DATE}
                name="DismantleDate"
                value={settingsState.DismantleDate}
                onStateUpdate={onUpdate}
                onUpdateData={onBlur}
                isClearable
              />
            </Form.Field>
          </Form.Group>
        </Form>
        {!noDeleteButton && (
          <Segment color="red">
            <Header as="h2">{LocalizedStrings.Delete}</Header>
            <Divider />
            <Button negative onClick={() => setDeleteModalOpen(true)}>
              <Icon name="delete" />
              {LocalizedStrings.deleteProject}
            </Button>
          </Segment>
        )}
        <LRModal
          size="mini"
          open={deleteModalOpen}
          title={LocalizedStrings.deleteProject}
          onOkClick={onDelete}
          onCancelClick={() => setDeleteModalOpen(false)}
        >
          <Dimmer active={currentlyDeleting}>
            <Loader />
          </Dimmer>
          {LocalizedStrings.areYouSure}
        </LRModal>
      </Accordion.Content>
      <Accordion.Title
        active={activeIndex === 1}
        index={1}
        onClick={() => setActiveIndex(1)}
        style={headerStyle}
      >
        <Icon name='dropdown' />
        <Header style={{ margin: 0 }} as="h2">{LocalizedStrings.LocationInformation}</Header>
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 1}>
        <Form>
          {
            [
              "LocationPrettyName", "Street", "Number", "PostalCode", "City", "Country",
            ].map(i => <Form.Input
              key={i}
              label={LocalizedStrings[i]}
              value={settingsState[i]}
              onChange={(e, { value }) => onUpdate(i, value)}
              onBlur={onBlur}
              onKeyDown={(e) => { if (e.keyCode === 13) { onBlur(); e.target.blur() } }}
            />)
          }
        </Form>
      </Accordion.Content>
    </Accordion>
  )
}