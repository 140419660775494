import React from "react";
import { Component } from "react";
import { Table, Loader, Message, Button, Icon, Form } from "semantic-ui-react";
import LocalizedStrings from "../../localization/ResourceManager"
import { BASE_UNIT_LENGTH, BASE_UNIT_PAGE_LENGTH, IsRunningAsBrowser } from "../../util/defines";
import LRModal from "../Basics/BasicModal";
import UnitInput from '../Basics/BasicUnitInput';
import { globalCallbacks } from '../../util/callback';
import LRTableHeaderCell from "../Basics/BasicTableView"
import { Pagination } from 'semantic-ui-react'

const templateInit = {
  Name: "New Paper Template",
  Width: 0,
  Height: 0,
  MarginX: 0,
  MarginY: 0,
  SpacingX: 0,
  SpacingY: 0,
  LabelWidth: 0,
  LabelHeight: 0
}

const tableFields = [{
  name: "Width"
}, {
  name: "Height",
}, {
  name: "MarginX"
}, {
  name: "MarginY"
}, {
  name: "SpacingX"
}, {
  name: "RoundEdge"
}, {
  name: "SpacingY"
}, {
  name: "LabelWidth"
}, {
  name: "LabelHeight"
}]

class PaperTemplateDisplay extends Component {
  constructor(props) {
    super(props)
    this.state = {
      paperTemplates: [],
      isLoading: false,
      page: 1,
      itemsPerPage: 75,
      open: false,

      editName: "",
      create: {
        ...templateInit
      }
    }
  }

  componentDidMount()
  {
    this.setUpCallbacks();
    if (globalCallbacks.updatePaperFormatTemplates) { globalCallbacks.updatePaperFormatTemplates(); }
  }

  render() {
    let startPos = this.state.itemsPerPage*(this.state.page - 1);
    
    return (
      <React.Fragment>
        <Table selectable striped celled>
          <Loader inverted content='Loading' />
          <Table.Header>
            <Table.Row>
              <LRTableHeaderCell>{LocalizedStrings.Name}</LRTableHeaderCell>
              <LRTableHeaderCell baseUnit={BASE_UNIT_PAGE_LENGTH}>{LocalizedStrings.Width}</LRTableHeaderCell>
              <LRTableHeaderCell baseUnit={BASE_UNIT_PAGE_LENGTH}>{LocalizedStrings.Height}</LRTableHeaderCell>
              <LRTableHeaderCell baseUnit={BASE_UNIT_PAGE_LENGTH}>{LocalizedStrings.MarginX}</LRTableHeaderCell>
              <LRTableHeaderCell baseUnit={BASE_UNIT_PAGE_LENGTH}>{LocalizedStrings.MarginY}</LRTableHeaderCell>
              <LRTableHeaderCell baseUnit={BASE_UNIT_PAGE_LENGTH}>{LocalizedStrings.SpacingX}</LRTableHeaderCell>
              <LRTableHeaderCell baseUnit={BASE_UNIT_PAGE_LENGTH}>{LocalizedStrings.SpacingY}</LRTableHeaderCell>
              <LRTableHeaderCell baseUnit={BASE_UNIT_PAGE_LENGTH}>{LocalizedStrings.LabelWidth}</LRTableHeaderCell>
              <LRTableHeaderCell baseUnit={BASE_UNIT_PAGE_LENGTH}>{LocalizedStrings.LabelHeight}</LRTableHeaderCell>
              <LRTableHeaderCell baseUnit={BASE_UNIT_PAGE_LENGTH}>{LocalizedStrings.RoundEdge}</LRTableHeaderCell>
            </Table.Row>
          </Table.Header>
          { this.state.paperTemplates.length !== 0 ? 
          <Table.Body>
            {this.state.paperTemplates.slice(startPos, startPos + this.state.itemsPerPage).map(entry => this.render_row(entry))}
          </Table.Body>
          : 
          this.state.isLoading 	? <Message style={{width: '100%'}}><Loader active content={LocalizedStrings.Loading}/></Message> 
                                : <Message style={{width: '100%'}} warning><Message.Header>{LocalizedStrings.NoPaperTemplatesHeader}</Message.Header><p>{LocalizedStrings.NoPaperTemplatesText}</p></Message>}
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='10'>
                <Button
                  floated="right"
                  icon
                  labelPosition='left'
                  primary
                  onClick={this.openEditModal}
                  size='small'><Icon name='paper'/>{LocalizedStrings.AddPaperTemplate}</Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <div style={{display: "flex", "justifyContent": "center"}}>
          <Pagination activePage={this.state.page} totalPages={Math.ceil(this.state.paperTemplates.length / this.state.itemsPerPage)} onPageChange={(ev, {activePage})=>{this.setState({page: activePage})}}/>
        </div>
        <LRModal 
          title={LocalizedStrings.EditPaperObjectsHeader}
          open={this.state.open}
          onCancelClick={this.onDiscardModal}
          onOkClick={this.onOkClick}>
          <Form>
            <Form.Input value={this.state.editName} onChange={(e, {value}) => {this.setState({editName: value})}}/>
            {this.group2(tableFields.map(arg => this.render_modal_cell(arg)))}
          </Form>
        </LRModal>		 
      </React.Fragment>
    );
  }

  onDeletePaperFormatTemplate = (entry) => {
    window.LR_DeletePaperFormatTemplate({Name: entry.Name}).then(() => {
      if (globalCallbacks.updatePaperFormatTemplates && IsRunningAsBrowser) { globalCallbacks.updatePaperFormatTemplates() }
    })
  }
  render_row(entry) {
    return <Table.Row key={entry.Name}>
      <Table.Cell>
        <Icon name="delete" color="red" link textAlign="right" onClick={() => this.onDeletePaperFormatTemplate(entry)}></Icon>
        {entry.Name}
      </Table.Cell>
      {tableFields.map(field => this.render_cell(field, entry))}
    </Table.Row>
  }

  render_cell(args, entry) {
    return <Table.Cell key={args.name}>
      <UnitInput
        min={0}
        baseUnit={BASE_UNIT_LENGTH}
        label={false}
        noStepper
        transparent
        name={args.name}
        value={this.state[entry.Name + args.name] ?? entry[args.name]}
        onStateUpdate = {(name, value) => {this.setState({[entry.Name + "edit" + name]: value})}}
        onUpdateData  = {(name, value) => {this.updatePaperFormat(name, value, entry)}}/>
    </Table.Cell>
  }

  group2(arr) {
    let groupArr = []
    let outArr = []
    arr.forEach(el => {
      groupArr.push(el)
      if(groupArr.length === 2) {
        outArr.push(<Form.Group widths={"equal"}>
          {groupArr}
        </Form.Group>)
        groupArr = []
      }
    })
    return outArr
  }

  render_modal_cell(args) {
    return <UnitInput
            min={0}
            name={args.name} 
            label={LocalizedStrings[args.name]} 
            baseUnit={BASE_UNIT_LENGTH} 
            value={this.state.create[args.name]} 
            onStateUpdate={(name, val) => {this.setState({create: {...this.state.create, [args.name]: val} })}}/>
  }

  editInput(_ , {name, value}) {
    this.setState({
      create: {
        ...this.state.create,
        [name]: value
      }
    })
  }

  onOkClick = () =>
  {
    window.LR_AddPaperFormatTemplate(this.toCoreFormat({...this.state.create, Name: this.state.editName})).then(() => {
      if (globalCallbacks.updatePaperFormatTemplates && IsRunningAsBrowser) { globalCallbacks.updatePaperFormatTemplates() }
    })
    this.setState({open: false})
  }

  onDiscardModal = () =>
  {
    this.setState({open: false})
  }

  openEditModal = () =>
  {
    this.setState({open: true, create: {...templateInit}, editName: templateInit.Name})
  }

  updatePaperFormat = (name, value, entry) => {
    entry[name] = this.state[entry.Name + "edit" + name]
    window.LR_SetPaperFormatTemplate(this.toCoreFormat(entry)).then(() => {
      if (globalCallbacks.updatePaperFormatTemplates && IsRunningAsBrowser) { globalCallbacks.updatePaperFormatTemplates() }
    })
  }

  toCoreFormat(obj) {
    return {
      Name: obj.Name,
      PaperSize:  {X: obj.Width,      Y: obj.Height,      Z:0},
      Margin:     {X: obj.MarginX,    Y: obj.MarginY,     Z:0},
      Spacing:    {X: obj.SpacingX,   Y: obj.SpacingY,    Z:0},
      Size:       {X: obj.LabelWidth, Y: obj.LabelHeight, Z:0},
    }
  }

  fromCoreFormat(obj) {
    return {
      Name: obj.Name,
      Width: obj.PaperSize.X,
      Height: obj.PaperSize.Y,
      MarginX: obj.Margin.X,
      MarginY: obj.Margin.Y,
      SpacingX: obj.Spacing.X,
      SpacingY: obj.Spacing.Y,
      LabelWidth: obj.Size.X,
      LabelHeight: obj.Size.Y
    }
  }

  setUpCallbacks()
  {
    globalCallbacks.updatePaperFormatTemplates = async () =>
    {
      this.setState({isLoading: true})
      let res = await window.LR_GetPaperFormatTemplateMap() 
      this.setState({paperTemplates: res.ResourceMap.paperFormatTemplate.map(this.fromCoreFormat), isLoading: false, page: 1})
    }
  }
}
export default PaperTemplateDisplay;
