//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Form, Radio } from 'semantic-ui-react';

import LocalizedStrings from "../../../localization/TableViewComponent";


class StructureFilter extends Component
{
    render()
    {
        return(
        <>
          <h5>{LocalizedStrings.Filter}</h5>
          <Form.Field>
            <Radio
              checked={this.props.structureGroupByObject}
              label={LocalizedStrings.GroupByObjects}
              name='radioGroup'
              onChange={(e, {checked}) => {if (this.props.onGroupByChanged) { this.props.onGroupByChanged(checked)}}}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              checked={!this.props.structureGroupByObject}
              label={LocalizedStrings.GroupByLines}
              name='radioGroup'
              onChange={(e, {checked}) => {if (this.props.onGroupByChanged) { this.props.onGroupByChanged(!checked)}}}
            />
          </Form.Field>
        </>
          )
    }
}


export default StructureFilter;