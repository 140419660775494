export const SET = 'SET'
export const FETCH = 'FETCH'
export const CLEAR = 'CLEAR'

export const PROJECTS         = 'projects'
export const REQUESTED_CALCULATIONS         = 'calculations'
export const REQUESTED_CALCULATIONS_ALL         = 'all_calculations'
export const MINI_PROJECTS    = 'mini_projects'
export const ACTIVE_USER      = 'active_user'
export const ACTIVE_ADMIN     = 'active_admin'
export const CHANGES          = 'changes'
export const ACTIVE_PROJECT   = 'active_project'
export const EDIT_MODE        = 'edit_mode'
export const COLLABORATORS    = 'collaborators'
export const SHAREDLICENCE    = 'sharedLicence_users'
export const SLACK_CHANNELS   = 'slack_channels'
export const ACTIVE_CHANGE    = 'active_change'
export const ACTIVE_CHECK_PROJECT    = 'active_project_change'
export const GLOBAL_SETTINGS  = 'global_settings'
export const MEMBERS          = "members"
export const USER_REVIEW_TEMPLATES = "user_review_templates"
export const PROJECT_REVIEW_TEMPLATE = "project_review_template"
export const REVIEW           = "review"
export const GROUPS           = "groups"
export const CUSTOMER_INFO    = "customer_info"
export const ADMINS           = "admins"  
export const CONTENT_ADMINS   = "content_admins"  
export const API_TOKENS       = "api_tokens"  
export const SHARE_LINKS      = "share_links"
export const RESET            = "reset"
export const CUSTOM_PROJECT_DATA = "custom_project_data"
