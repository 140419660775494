//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import "./forgot-page.css";

import React, { Component } from "react";
import { Form, Button, Grid, Message } from "semantic-ui-react";
import LocalizedStrings from "../../localization/ResetPage";
import logo from "../../content/img/applogo.png";
import { Link } from "react-router-dom";
import { IsRunningAsBrowser } from "../../util/defines";

import CONST from "../../webApp/CONST";

class ForgotPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
      username: "",
      isValidUsername: false,
    };
  }

  update = (e, { name, value }) => {
    let isValid = this.state.isValidUsername;
    if (name === "username") {
      isValid = value.length < 1;
    }

    this.setState({
      [name]: value,
      isValidUsername: isValid,
    });
  };

  sendForgotPassword = async () => {
    let form = new FormData();
    form.append("username", this.state.username.toLowerCase());

    let response = await fetch(new URL(`${CONST.BASE_URL}/forgot`), {
      method: "POST",
      body: form,
    });

    let json = await response.json();
    this.setState({
      message: json.message,
      resOK: json.ok,
    });
  };

  render() {


    let logoImage = <img src={logo} alt="production assistant logo" />
    if (IsRunningAsBrowser())
    {
      logoImage = <Link to="/">{logoImage}</Link>
    }

    return (
      <div className="login-form">
      
        <div className="header">
          {logoImage}
        </div>
        <div>
          <img src={logo} alt="production assistant logo" />{" "}
          <h1>{LocalizedStrings.ResetHeader}</h1>
          <p>{LocalizedStrings.ResetText}</p>
          <Form>
            <Form.Input
              name="username"
              label={LocalizedStrings.username}
              placeholder={LocalizedStrings.usernamePlaceHolder}
              value={this.state.username}
              onChange={this.update}
            />
            <Button
              primary
              type="submit"
              disabled={this.state.isValidUsername}
              onClick={this.sendForgotPassword}
            >
              {LocalizedStrings.submitButton}
            </Button>
          </Form>
        </div>
        {this.state.message && (
          <Grid.Row>
            <Message
              style={{ marginTop: "16px" }}
              positive={this.state.resOK}
              negative={!this.state.resOK}
            >
              {this.state.message}
            </Message>
          </Grid.Row>
        )}
        <Grid.Row>
          <div style={{ marginTop: "32px", textAlign: "center", position:"sticky" }}>
            <a href="/">{LocalizedStrings.loginPage}</a>
          </div>
        </Grid.Row>

        <div style={{height:"95%"}}>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0, 0, 100, 38.5896"
          >
            <path
              width="100%"
              height="100%"
              d="M 100 38.5896 
                L 0 38.5896 
                L 78.9959 24.6768 
                C 91.3502 22.3542 100 12.1998 100 0 
                L 100 0 
                z"
            />
          </svg>
        </div>
        
      </div>
    );
  }
}

export default ForgotPage;
