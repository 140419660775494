
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Form } from 'semantic-ui-react'

class FilterFormInput extends Component 
{

    render() 
    {
        let children = this.props.children;
        if(!children || children.length === 0) return null

        let hasChilds = false
        if(! Array.isArray(children)) { hasChilds = true }
        else { children.forEach(e=>{ if(e !== null) { hasChilds = true } }) }
        
        if(hasChilds)
        {
            return (
            <Form.Group widths="equal">
                {children}
            </Form.Group>
                );
        }
        return null
    }

}

export default FilterFormInput;

