import React from 'react';
import { Button, Icon,Form } from 'semantic-ui-react'
import SelectRessourceModal from '../ToolSelect/SelectRessourceModal';
import { RESOURCE_TYPE_SymbolDef } from '../../util/defines';
import LocalizedStrings from "../../localization/ResourceManager";


class SymbolMapSelector extends React.Component {
    constructor(props)
    {
        super(props)

        this.state={
            value: this.props.value ?? undefined,
            open: false,
            resourceType: RESOURCE_TYPE_SymbolDef
        }
    }

    componentDidUpdate(){
      if(this.state.value !== this.props.value){
        this.setState({value: this.props.value})
      }
    }

    render()
    {
      return <>
        <Form.Input  value={this.state.value} readOnly label={LocalizedStrings.ReplaceWith}/>
        <Button positive onClick={()=>this.setState({open:true})}><Icon name="edit"/> {LocalizedStrings.Edit}</Button>
        {
        this.state.open ? 
        <SelectRessourceModal 
        headerAdd={this.props.entry}
          SelectRessourceCallback={this.selectResourceSelector} 
          OnlySelect
          key={this.props.entry}
          disableLocalRessources={true}
          open={this.state.open} close={this.closeResourceSelector} 
          resourceType={this.state.resourceType} 
          handleChangeType={(type)=>{this.setState({resourceType: type})}}/> 
        : null}
      </>
    }


  selectResourceSelector = (ResourceType, UUID, RessourceIdent, UserName, item) =>
  {
    this.setState({value:item.Name })
    this.props.updateData({newTo: item.Name, newUUID: UUID, newIdent: RessourceIdent, User: UserName  });
    this.closeResourceSelector()
  }

  closeResourceSelector = () =>
  {
    this.setState({open: false})
  }
}

export default SymbolMapSelector;