//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component, } from 'react';
import { connect } from 'react-redux';
import { Button, Icon, Header, Accordion, List, Card } from 'semantic-ui-react';
import {TASK_STATE_NONE,
        TASK_STATE_PROGRESS,
        TASK_STATE_FINISHED,
        IsRunningAsBrowser,
        BASE_UNIT_TIME_CODE,
        SELECT_Single,} from '../../util/defines'
import LocalizedStrings from "../../localization/UserTaskPage";
import { globalCallbacks } from '../../util/callback';
//import { getElapsedTimeStr } from '../ChangeRequest/ChangeRequestList';
import { Link } from 'react-router-dom';
import { ACTIVE_PROJECT } from '../../redux/redux_defines';
import DrawingNote from '../DrawingNotes/Note';
import UserAvatar from '../WebComponents/UserAvatar';
import UnitInput from '../Basics/BasicUnitInput';

class TaskEntry extends Component 
{
  constructor(props)
  {
      super(props)

      this.state = {
        Notes: [],
        activeIndex: -1,
        closed: true
      }
    }


  render() 
  {
    let worksheet = this.props.wsObj.Worksheet
    let worksheetName = worksheet.Name


    let avatars = worksheet.DefaultAssingedUsers.map(user => {
      return <UserAvatar user={this.props.users[user]?.Name} key={user}/>
    });

    let linkName = false
    if(IsRunningAsBrowser())
    {
      if (this.props.active_project.data)
      {
        linkName = this.props.active_project.data.owner.username + "/" + this.props.active_project.data.name
      }
    }

    return (
      <Card fluid={!IsRunningAsBrowser()} id={"TASK" + worksheet.UUID}>
        <Card.Content>
          <Card.Header textAlign="center" onClick={()=>this.setState({closed: !this.state.closed})}>
            <Icon name={this.state.closed ? "angle right" : "angle down"} />
            <Header h="h4" as='a'>{worksheetName.toUpperCase()}</Header>
          </Card.Header>
        </Card.Content>
        {
          this.state.closed ? null : this.renderContent(avatars, linkName, worksheet)
        }

      </Card>
    )

  }


  renderContent(avatars, linkName, worksheet)
  {
    return(<>
            <Card.Content >
            {avatars}
            <br/>
            {this.getTime()}
        </Card.Content>
    <Card.Content>
        {linkName ? 
            <Button fluid color="blue" as={linkName?Link:undefined} to={linkName??`/${linkName}/worksheet/uuid/${worksheet.UUID}`}>
              <Icon name='file' />
              {LocalizedStrings.OpenWs}
              </Button> : 
              <Button.Group vertical fluid>
              <Button primary onClick={()=>{window.LR_ShowWorkSheet({UUID: worksheet.UUID})}}>
                 <Icon name='file' />
                {LocalizedStrings.OpenWs}
              </Button>
              <Button primary onClick={this.selectBaseObject}>
                 <Icon name='hand point left outline' />
                {LocalizedStrings.SelectBaseObject}
              </Button>
              </Button.Group>
              }
        <Accordion fluid>
        <Accordion.Title onClick={()=>{this.setState({open:!this.state.open})}} active={this.state.open}>
          <Icon name='dropdown'/>
          {LocalizedStrings.BuildDescription}
        </Accordion.Title>
        <Accordion.Content active={this.state.open}>
          <DrawingNote text={worksheet.BuildDescription} />
        </Accordion.Content>
        </Accordion>
        </Card.Content>
        <Card.Content>
            <Button fluid onClick={(e)=>{window.LR_ShowCreateDrawingNote({UUID: worksheet.LinkedObject}); e.stopPropagation(); e.preventDefault(); }}>{LocalizedStrings.AddNote}</Button>
          <Accordion>
            <Accordion.Title
                active={this.state.activeIndex === 0}
                index={0}
                onClick={this.handleClick}
                >
                  <Icon name='dropdown' />
                  {LocalizedStrings.ShowNote}
            </Accordion.Title>
            <Accordion.Content active={this.state.activeIndex === 0}>
              {this.renderNotes()}
            </Accordion.Content>
          </Accordion>
        </Card.Content>
        <Card.Content textAlign="center">
        <Header h="h4" as='a'>{LocalizedStrings.Progress}</Header>
          <Button.Group fluid vertical>
              {this.switchStateButton()}
          </Button.Group>
        </Card.Content></>)
  }

  selectBaseObject = async ()=>
  {
        let request = 
        {
            Selected      : true,
            SelectionMode : SELECT_Single,
            SelectionGroupMode : globalCallbacks.selectionGroupMode,
            ShiftKey      : false,
            MetaKey       : false,
            AltKey        : false,
            SelectedList  : [this.props.wsObj.Worksheet.LinkedObject],
        }

        await window.LR_Select(request);
        await window.LR_ZoomToSelection();
    
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })

    window.LR_GetNotesForObjects({Async: true, Worksheet:this.props.wsObj.Worksheet.UUID}).then((o)=>{ this.setState({Notes: o.Notes}) });

  }

  renderNotes()
  {
    return <List divided relaxed>
    {this.state.Notes.map(note => 
      {
        return<>
        <List.Item>
          <List.Icon>
            <UserAvatar user={note.User}/>
          </List.Icon>
        <List.Content>
          <DrawingNote text={note.Note} />
        </List.Content>

        </List.Item>
        </>
      })}
      </List>
  }

  // builds the strings with the calculated time information i.e. how much time is left until the due date
  getTime = () => {
    return <div>{LocalizedStrings.AllocatedTime}: <UnitInput readOnly value={this.props.wsObj.Worksheet.TimeDuration} baseUnit={BASE_UNIT_TIME_CODE}/></div>
  }

  switchStateButton = () => 
  {
    let task = this.props.wsObj.Task

    switch(task.state)
    {
      case TASK_STATE_NONE     :
        return( <Button positive
                        primary
                        onClick = {() => this.switchState(TASK_STATE_PROGRESS)}>
                             <Icon name    = 'caret down' />
                  {LocalizedStrings.MarkProgress}
                </Button>)

      case TASK_STATE_PROGRESS :
        return( <>
                    <Button negative
                            fluid
                            primary
                            onClick = {() => this.switchState(TASK_STATE_NONE)}>
                                 <Icon name    = 'caret up' />
                      {LocalizedStrings.MarkNext}
                    </Button>
                    <Button positive
                            primary
                            onClick = {() => this.switchState(TASK_STATE_FINISHED)}>
                      <Icon name    = 'caret down' />
                        {LocalizedStrings.MarkDone}
                    </Button>
                </>)
      
      case TASK_STATE_FINISHED :
        return (<Button negative
                        primary
                        onClick = {() => this.switchState(TASK_STATE_PROGRESS)}>
                  <Icon name    = 'caret up' />
                    {LocalizedStrings.RevokeTask}
                </Button>)

      default : return null;
    }
  }

  switchState = async(state) => 
  {
    let wsUUID = this.props.wsObj.Worksheet.UUID
    await window.LR_SwitchTaskState({"UUID": wsUUID, "State": state})

    globalCallbacks.updateTasks(wsUUID)
    
  }
}

//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      active_project: (state[ACTIVE_PROJECT]),

    };
}

export default connect(mapStateToProps)(TaskEntry)
