//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Form } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/AboutDialog";
import LRModal from '../Basics/BasicModal';
import { globalCallbacks } from "../../util/callback";
import { BASE_UNIT_LENGTH } from '../../util/defines';
import UnitInput from '../Basics/BasicUnitInput';


//-----------------------------------------------------------------------------
// The component for the TreeControl
class ScaleByRef extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
      Measured: 0.0
    }
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }


  close = () => 
  {
    this.setState({open : false});
  }

  onOkClicked = () => 
  {
    window.LR_ScaleByDistance({
      Measured: this.state.Measured,
      Desired: this.state.Desired
  })
    this.close()
  }
    
  render() 
  {
    let open = this.state.open;
    if(!open) {return <div/>}

    return (
      <LRModal  open={open} 
                onCancelClick={this.close} 
                closeOnDimmerClick={false} 
                size="mini"
                scrolling={false}
                title={LocalizedStrings.ScaleByRef}
                onOkClick={this.onOkClicked}>
                  <Form>
                    <UnitInput label={LocalizedStrings.Measured} baseUnit = {BASE_UNIT_LENGTH} value={this.state.Measured} onStateUpdate = {(name, value) => { this.setState({Measured:value}) }} />
                    <UnitInput label={LocalizedStrings.Desired} baseUnit = {BASE_UNIT_LENGTH} value={this.state.Desired} onStateUpdate = {(name, value) => { this.setState({Desired:value}) }} />
                  </Form>
      </LRModal>
    )
  }

  setUpCallbacks()
  {

    globalCallbacks.ShowScaleByDistance = (arg) => 
    { 
      this.setState(
        {
          open: true,
          Measured: arg.Measured
        }
      )
    }
    
  }
}

export default ScaleByRef