//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component, } from 'react';
import {  Breadcrumb, Button, Grid, Header, Icon, List } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import LocalizedStrings from "../../localization/UserOverview";
import { timeSince } from '../../util/time';
import MediaQuery from 'react-responsive'
import { getProjectName, RESPONSIVE_PHONE } from '../../util/defines';

class ProjectEntry extends Component 
{
    render() 
    {    
        
        return (
          <MediaQuery maxWidth={RESPONSIVE_PHONE}>
            {(isPhone) => {return isPhone ? this.renderMobile() : this.renderDesktop()}}
          </MediaQuery>
              

        );
    }

    renderMobile = () =>
    { 
      let {project} = this.props

      let projectName = getProjectName(project)

      return(
        <>
        <Header as="h5">
          <Breadcrumb.Section link as={Link} to={`${project.owner.username}/${project.projectname}`}>
            {`${project.owner.name}/${projectName}`}
          </Breadcrumb.Section>
          <Button floated="right" onClick={()=>{window.open("productionassist://openproject?user="+project.owner.username+"&project="+project.projectname+"&branch=master", '_blank');}} compact color='blue'><Icon name="folder open"/></Button>

        </Header>
        </>
      )
    }

    renderDesktop = () =>
    {
      let {project} = this.props

        let due = project.dueDate
        let dateString = ''
        if(due) 
        {
          let date = new Date(due)
          dateString = date.toLocaleDateString()
        }

        let projectName = getProjectName(project)
      return (
      <Grid style={{marginTop:"10px"}} columns={2} divided>
          <Grid.Column width={13}>
          <List>
            <List.Header>
              <Header>
                <Breadcrumb.Section link as={Link} to={`/${project.owner.username}/${project.projectname}`}>
                  {`${project.owner.name}/${projectName}`}
                  {due ? <div style={{float: 'right'}}>{dateString}</div> : null}
                </Breadcrumb.Section>
              </Header>
            </List.Header>
            <List.Item>
              {project.description}
            </List.Item>
            <List.Item>
              <Button onClick={()=>{window.open("productionassist://openproject?user="+project.owner.username+"&project="+project.projectname+"&branch=master", '_blank');}} compact color='blue'>{LocalizedStrings.openInApp}</Button>
            </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={3}>
            <List>
            <List.Item>
                {<><Icon name="edit"></Icon>{this.getChangedBy(project)}</>}
              </List.Item>
              <List.Item>
                {<><Icon name="calendar alternate outline"></Icon>{due ? dateString : LocalizedStrings.NoDueDate}</>}
              </List.Item>
              <List.Item>
                {<><Icon name="slack"></Icon>{project.slackChannel !== "" ? project.slackChannel : LocalizedStrings.NoSlackChannel}</>}
              </List.Item>
            </List>
          
          </Grid.Column>
        </Grid>)
    }

    getChangedBy = (project) =>
    {
      let userName = ""
      if(project.defaultBranch && project.defaultBranch.current && project.defaultBranch.current.modifiedBy)
      { 
        userName = project.defaultBranch.current.modifiedBy.username
      }
      let date = undefined
      if(project.defaultBranch && project.defaultBranch.current)
      {
        date     = project.defaultBranch.current.date
      }
      

      let dateObj = new Date(date)


      let changedBy = LocalizedStrings.ChangeByString

      changedBy = changedBy.replace("$1", userName)
      changedBy = changedBy.replace("$2", timeSince(dateObj))

      return changedBy;
    }
}

export default ProjectEntry
