import React, { Component } from 'react'
import { Button } from 'semantic-ui-react';
import "../component/NotFound/not-found.css";
import LocalizedStrings from "../localization/ErrorComponent";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error : false
        }
    }

    static getDerivedStateFromError(err) 
    {
        return { error : true }
    }

    componentDidCatch(err, errInfo)
    {
        console.error("Component Error", err, errInfo)
    }

    render() {
        if (this.state.error)
        {
            return this.renderErrorComponent()
        }
        return this.props.children
    }

    renderErrorComponent = () => {
        return (
          <>
            <main className="not-found">
                <div className="callout callout-very-big" onClick={()=>window.location.reload(false)}>{LocalizedStrings.CalloutText}</div>
                <Button positive onClick={()=>window.location.reload(false)}>{LocalizedStrings.ButtonText}</Button>
            </main>
          </>
        );
    }
}

export default ErrorBoundary