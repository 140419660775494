//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------

import { SELECT_Single } from "../../util/defines";
import { addNotification } from "../NotificationDisplay/NotificationDisplay";
import LocalizedStrings from "../../localization/ToolSelectComponent"

import { globalCallbacks } from "../../util/callback";

let TryGetUUID =(path) =>
{
    return "{" + path.substring(
        path.indexOf("%7B") + 3, 
        path.lastIndexOf("%7D")
    ) +"}";
}

let TryGetUserAndProject =(path) =>
{
    let obj ={}
    try
    {
        let splitted = path.split("/")
        obj.Owner  = splitted[1]
        obj.Project = splitted[2]
    }
    catch(e)
    {
        console.error(e)
        obj ={}
    }


    return obj

}

let CheckoutIfNeeded = async (cur, scanned) =>
{
    if(cur.Owner !== scanned.Owner && 
       cur.Project !== scanned.Project)
    {

        let prom = new Promise(res => 
        {
            window.LR_CheckoutProject(
            {
                Project:scanned.Project,
                User:scanned.Owner,
                Branch:"master",
            });

                globalCallbacks.addEventListener("read-file", ()=>
                {
                    res()
                })
        })

        return prom 
    }
    
}

export let HandleScanInWeb = async (data) =>
{
    console.log("HandleScanInApp", data)
    const url = new URL(data);

    if(url.hostname.includes("production-assist.com"))
    {
        let route =  url.pathname

        return route

    }

    return false;
}

export let HandleScanInApp = async (data) =>
{
    console.log("HandleScanInApp", data)
    const url = new URL(data);

    if(url.hostname.includes("production-assist.com"))
    {
        let route =  url.pathname


        let currentProject = await window.LR_GetLinkedProject()
        let scannedProject = TryGetUserAndProject(route)


        // Worksheet
        if(route.includes("/object/%7B") && route.endsWith("%7D"))
        {
            addNotification(LocalizedStrings.FailedScanObjectHeader, LocalizedStrings.FailedScanObjectText, true, false)
            await CheckoutIfNeeded(currentProject, scannedProject)

            let request = 
            {
            Selected      : true,
            SelectionMode : SELECT_Single,
            SelectionGroupMode : globalCallbacks.selectionGroupMode,
            ShiftKey      : false,
            MetaKey       : false,
            AltKey        : false,
            SelectedList  : [TryGetUUID(route)]
            }
            window.LR_Select(request);

            return true
        }


        // Object
        else if(route.includes("/worksheet/uuid/%7B") && route.endsWith("%7D"))
        {
            addNotification(LocalizedStrings.FailedScanWorksheetHeader, LocalizedStrings.FailedScanWorksheetText, true, false)
            await CheckoutIfNeeded(currentProject, scannedProject)

            window.LR_ShowWorkSheet({UUID: TryGetUUID(route)})

            return true
        }
    }

    addNotification(LocalizedStrings.FailedScanHeader, LocalizedStrings.FailedScanText, false, true)


    return false

}