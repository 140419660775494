//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2020 www.deersoft.de
//-----------------------------------------------------------------------------

import React from "react";
import LocalizedStrings from "../../localization/createUser";
import { Form, Button, Message, Icon } from "semantic-ui-react";
import logo from "../../content/img/applogo.png";
import { Navigate, Route } from "react-router-dom";
import { ValidateEmail } from "../../util/defines";
import { withRouter } from "../../webApp/WebRouter";

import fetch from "cross-fetch";

import CONST from "../../webApp/CONST";
import NewNavBar from "../ProductionAssistLandingPage/NewNavBar";
import NewFooter from "../WebComponents/NewFooter";
import "./../SignIn/signin.css"

class NewCreateUser extends React.Component {
  constructor(props) {
    super(props);
    this.notAllowed = new RegExp("[^A-Za-z0-9$\\-_.+]");
    this.state = {
      username: "",
      FirstName: "",
      LastName: "",
      companyName: "",
      password: "",
      repeatPassword: "",
      email: "",
      error: "",
      loggedIn: false,
      OK_AGB: false,
      OK_Newsletter: true,
      loading:false
    };
  }

  isEmptyString = (s) => {
    if (!s) {
      return true;
    }
    return s.length < 1;
  };

  isValidUsername = (s) => {
    return !this.notAllowed.test(s);
  };

  register = async () => 
  {

    if(!this.state.OK_AGB)
    {
      this.setState({ error: LocalizedStrings.ErrorAGB });
      return
    }

    // Check Input Date
    if (
      this.isEmptyString(this.state.username) ||
      this.isEmptyString(this.state.password) ||
      this.isEmptyString(this.state.FirstName) ||
      this.isEmptyString(this.state.LastName) ||
      this.isEmptyString(this.state.repeatPassword) ||
      this.isEmptyString(this.state.email)
    ) {
      this.setState({ error: LocalizedStrings.validateFields });
      return;
    }
    if (this.state.username.length < 3) {
      this.setState({ error: LocalizedStrings.usernameLength})
      return
    }
    if (!ValidateEmail(this.state.email)) {
      this.setState({ error: LocalizedStrings.validateEmail });
      return;
    }
    if (this.state.repeatPassword !== this.state.password) {
      this.setState({ error: LocalizedStrings.validatePassword });
      return;
    }
    if (!this.isValidUsername(this.state.username)) {
      let wrongChar = this.notAllowed.exec(this.state.username)[0];
      if (wrongChar === " ") {
        this.setState({ error: LocalizedStrings.ErrorWhiteSpace });
      } else {
        this.setState({
          error: LocalizedStrings.ErrorInvalidChar.replace("$1", wrongChar),
        });
      }
      return;
    }

    this.setState({ error: "" });

    this.setState({loading:true});

    // check if the user registers over a link that provides an invite token
    const urlObj = new URL(window.location.href)
    const invitetoken = urlObj.searchParams.get("invitetoken")

    let data = {
      name: this.state.username,
      password: this.state.password,
      email: this.state.email,
      template: this.props.match.params.template,
      FirstName: this.state.FirstName,
      LastName: this.state.LastName,
      companyName: this.state.companyName,
      newsletter: this.state.OK_Newsletter,
      trialPhase: true,
      tolt_referral: window.tolt_referral ? window.tolt_referral : "",
      invitetoken
    };

    let url = new URL(`${CONST.BASE_URL}/api/users`);
    let response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    });

    let json = await response.json();
    if (response.status !== 201) {
      console.error(json);
      this.setState({ error: json.message.Text});
    } 
    else 
    {
      // Tell 
      if(window.__ReactGA)
      {
        window.__ReactGA.event("conversion", 
        {
          'send_to': 'AW-10825383014/QC1dCN2Gl5QDEOb4-Kko',
          'value': 1.0,
          'currency': 'EUR',
        })
      }




      await window.LR_LoginToServerAsync({
        User: this.state.username.toLowerCase(),
        Password: this.state.password,
      });
      this.setState({ loggedIn: true });
      if (this.props.onLogin) {
        this.props.onLogin();
      }
    }
  };

  onKeyDown = (event) => {
    if (event.key === "Enter") {
      this.register();
    }
  };

  render() {

    if (this.state.loggedIn && this.props.webApp) {
        return (
          <Route path="/register">
            <Navigate to="/getstarted" />
          </Route>
        );
    } else {
      return (
        <div className="newlogin newregister">
          <NewNavBar />
          <div className="loginform">
            <img  src={logo} alt="production assistant logo" />
            <h1>{LocalizedStrings.Header}</h1>
            <Form className="inputcontainer" onKeyDown={this.onKeyDown}>
                <Form.Input
                  className="textin"
                  name={LocalizedStrings.username}
                  value={this.state.username}
                  placeholder={LocalizedStrings.insertUsername}
                  onChange={(e) => this.setState({ username: e.target.value, error: "", loading: false })}
                />
                <Form.Input
                  className="textin"
                  name={LocalizedStrings.FirstName}
                  value={this.state.FirstName}
                  placeholder={LocalizedStrings.FirstName}
                  onChange={(e) => this.setState({ FirstName: e.target.value, error: "", loading: false  })}
                />
                <Form.Input
                  className="textin"
                  name={LocalizedStrings.LastName}
                  value={this.state.LastName}
                  placeholder={LocalizedStrings.LastName}
                  onChange={(e) => this.setState({ LastName: e.target.value, error: "", loading: false  })}
                />
                <Form.Input
                  className="textin"
                  name={LocalizedStrings.CompanyName}
                  value={this.state.companyName}
                  placeholder={LocalizedStrings.CompanyName}
                  onChange={(e) => this.setState({ companyName: e.target.value, error: "", loading: false  })}
                />
                <Form.Input
                  className="textin"
                  name={LocalizedStrings.email}
                  value={this.state.email}
                  placeholder={LocalizedStrings.insertEmail}
                  onChange={(e) => this.setState({ email: e.target.value, error: "", loading: false  })}
                />
                <Form.Input
                  className="textin"
                  name={LocalizedStrings.password}
                  type="password"
                  value={this.state.password}
                  placeholder={LocalizedStrings.insertPassword}
                  onChange={(e) => this.setState({ password: e.target.value, error: "", loading: false  })}
                />
                <Form.Input
                  className="textin"
                  name={LocalizedStrings.password}
                  type="password"
                  value={this.state.repeatPassword}
                  placeholder={LocalizedStrings.repeatPassword}
                  onChange={(e) =>
                    this.setState({ repeatPassword: e.target.value, error: "", loading: false  })
                  }
                />
                <Form.Checkbox
                  radio
                  checked={this.state.OK_AGB}
                  label={LocalizedStrings.AcceptAGB}
                  onChange={(e, { checked }) => {
                    this.setState({ OK_AGB: checked });
                  }}
                ></Form.Checkbox>
                <Form.Checkbox
                  radio
                  checked={this.state.OK_Newsletter}
                  label={LocalizedStrings.AcceptNewsLetter}
                  onChange={(e, { checked }) => {
                    this.setState({ OK_Newsletter: checked });
                  }}
                ></Form.Checkbox>
                {this.state.error ? <Message negative> <Message.Header style={{textAlign:"center"}}>{LocalizedStrings.InvalidLogin}</Message.Header>{this.state.error}</Message>: null}
                <div className="button-container">
                  <Button disabled={this.state.loading} fluid size="big" onClick={this.register}>
                    {this.state.loading? <Icon name="spinner" loading={true}/> : LocalizedStrings.Register}
                  </Button>
                </div>
            </Form>
          </div>
          {this.props.match ? null : (
            <div style={{ margin: "12px" }}>
              <a href="?tab=login">{LocalizedStrings.login}</a>
            </div>
          )}
          <NewFooter />
        </div>
      );
    }
  }
}

export default withRouter(NewCreateUser);
