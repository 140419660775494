//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component, } from 'react';
import { connect } from 'react-redux';
import {  Loader, Divider, Grid, Menu } from 'semantic-ui-react';
import ProjectEntry from './ProjectEntry';
import { hasFetched, FetchProjects } from '../../redux/actions/fetch_actions'
import { PROJECTS } from "../../redux/redux_defines"
import BasicSelect from '../Basics/BasicSelect';
import LocalizedStrings from '../../localization/ProjectView';
import {SORT_LOW_HIGH, SORT_HIGH_LOW} from '../../util/defines';
import escapeRegExp from "lodash/escapeRegExp"
import filter from "lodash/filter"
import LRFilterInput from '../Basics/FilterField';
import MediaQuery from 'react-responsive';
import { RESPONSIVE_PHONE } from '../../util/defines';
import ProjectFolder from '../ProjectFolder/ProjectFolder';


class ProjectOverview extends Component 
{

  constructor(props)
  {
    super(props);

    this.state =
    {
      // sort
      sortByOptions : [
        {key: "Change", text: LocalizedStrings.Change, value: "lastChanged", selected: false}, // lastChanged, dateCreated are the keys in the project object
        {key: "Create", text: LocalizedStrings.Create, value: "dateCreated", selected: false},
      ],
      sortBy : undefined,

      sortFromToOptions: [
        {key: "lowhigh", text: LocalizedStrings.Lowhigh, value: SORT_LOW_HIGH, selected: false},
        {key: "highlow", text: LocalizedStrings.Highlow, value: SORT_HIGH_LOW, selected: false},
      ],
      sortFromTo: undefined, 

      // search
      isLoading: false,
      results  : [], 
      query    : "",
      activeSelection : window.localStorage.getItem("active_selection_project")
    }

    if(!this.state.activeSelection)
    {
      this.state.activeSelection = "ProjectList"
      window.localStorage.setItem("active_selection_project", this.state.activeSelection)
    }
  }

  render()
  {
    FetchProjects()
    let {projects} = this.props;

    if(hasFetched(projects))
    {
      return (
        <MediaQuery  minWidth={RESPONSIVE_PHONE}>
              {(isMoblie) => isMoblie ? this.renderContent(projects, false) : this.renderContent(projects, true) }
        </MediaQuery>
      )
    }
    return <Loader active/>
  
  }

  renderContent = (projects, isMobile) => {
    
    let sortByText = LocalizedStrings.SortBy 
    let sortFromToText = LocalizedStrings.SortFrom

    // filter by search
    let showData = this.onSearchChange(this.state.query, projects.data)
    
    // get sorting parameters (sortBy, sortFromTo) if there are any selected
    let sortBy = this.state.sortByOptions.find(e => e.selected)
    if (sortBy) 
    {       
      sortByText += " : " + sortBy.text
    } 
    
    let sortFromTo = this.state.sortFromToOptions.find(e => e.selected)
    if (sortFromTo) 
    { 
      sortFromToText += " : "
      sortFromToText += sortFromTo.value === SORT_LOW_HIGH ? LocalizedStrings.Lowhigh : LocalizedStrings.Highlow
    }
    
    // sort
    if (sortBy !== undefined && sortFromTo !== undefined) 
    { 
      showData.sort((a, b) => { return this.compare(a, b, sortBy.value, sortFromTo.value) }) 
    }

    return(
            <>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                      <Menu attached='top' tabular>
                          <Menu.Item
                            name='ProjectList'
                            active={this.state.activeSelection === 'ProjectList'}
                            onClick={this.handleItemClick}
                          />
                          <Menu.Item
                            name='ProjectFolder'
                            active={this.state.activeSelection === 'ProjectFolder'}
                            onClick={this.handleItemClick}
                          />   
                      </Menu>
                    </Grid.Column>
                </Grid.Row>
                {
                  (this.state.activeSelection === 'ProjectList') ? 
                  (<>
                      <Grid.Row >
                            <Grid.Column width={isMobile ? 5 : 3}>
                              <LRFilterInput value   = {this.state.query}
                                            noLabel
                                            onChange = {(query) => this.setState({query})}/>
                            </Grid.Column>
                            {!isMobile && <Grid.Column width={7} />}
                            <Grid.Column width={isMobile ? 5 : 3}>
                              <BasicSelect  name          = "sortByOptions"
                                            filter
                                            multiple      = {false}
                                            button
                                            text          = {sortByText}
                                            options       = {this.state.sortByOptions}
                                            onStateUpdate = {this.updateSelectState}/>
                            </Grid.Column>
                            <Grid.Column width={isMobile ? 5 : 3}>
                              <BasicSelect  name          = "sortFromToOptions"
                                            filter
                                            multiple      = {false}
                                            button
                                            text          = {sortFromToText}
                                            options       = {this.state.sortFromToOptions}
                                            onStateUpdate = {this.updateSelectState}/>  
                              </Grid.Column>
                      </Grid.Row>
                      <Grid.Row >
                        <Grid.Column>

                        {showData.map( (project, index) => 
                                        <React.Fragment key={project._id}>
                                          <ProjectEntry project={project}/> 
                                          {index+1 < showData.length ? <Divider/> : null} </React.Fragment>)}
                        </Grid.Column>
                      </Grid.Row>
                   </> ) :
                  <Grid.Row >
                    <ProjectFolder />
                  </Grid.Row>
                }
              </Grid>
            </>
    )
  }

  handleItemClick = (e, { name }) => 
  {
    this.setState({ activeSelection: name })
    window.localStorage.setItem("active_selection_project", name)
  }

  onSearchChange = (value, resources) => 
  {
    if(value.length < 1)
    {
      return resources
    }
    else
    {
      const re = new RegExp(escapeRegExp(value), 'i')
      const isMatch = (result) => {let res=re.test(result.name); return res;}
    
      return filter(resources, isMatch)
    }
  }

  updateSelectState = (name, value, e) => {
    this.setState({[name]: value})
  }

  /* 
    compares projects for sorting by varying properties, 
    only works with date/time for now
    stable sorting
  */ 
  compare = (a, b, sortBy, sortFromTo) => {
    // date vals in a,b should be ISO standard
    let isoA = a[sortBy] 
    let isoB = b[sortBy]
    
    let unixTSA = new Date(isoA).getTime()
    let unixTSB = new Date(isoB).getTime()

    // define the compare function based on the chosen order
    let comp = sortFromTo === SORT_LOW_HIGH ? function(a,b) { return a < b } : function(a,b) { return a > b }

    if (comp(unixTSA,  unixTSB))
    {
      return -1 // a first
    }
    else if (!comp(unixTSB,  unixTSA))
    { 
      return 1 // b first
    }

    return 0 // equal, leave at is
  }

}

//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      projects: (state[PROJECTS])
    };
}

export default connect(mapStateToProps)(ProjectOverview)
