//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, {Component} from 'react';
import {  Form, Grid,  Message, Tab, Image } from 'semantic-ui-react';
import LocalizedStrings from "../../localization/ExportView";
import LRModal from '../Basics/BasicModal';
import { BASE_UNIT_LENGTH, BASE_UNIT_NUMBER, EMPTY_UUID } from '../../util/defines';
import UnitInput from '../Basics/BasicUnitInput';

import { globalCallbacks } from "../../util/callback";


//-----------------------------------------------------------------------------
// The component for the TreeControl

class ExportViewModal extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,

      SelectedView: EMPTY_UUID,
      PrintLabels: [],
      selectedPrintLabel:EMPTY_UUID,
      activeTab:0,
      Width:1024,
      Height:1024,
      activeIndex:0,

      PublicShareLinkOptions:[],
      PublicShareLink: "",
      SavedViewContainer:{},
      PrintLabelContainer:{}
    }
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }

  show = async() => 
  {

    let ret5 = await window.LR_GetSavedViews();
    let ret4 = await window.LR_GetPrintLabels();

    let SavedViewContainer = {}
    
    let SavedViewOption = ret5.SavedViews.map(w=> 
      { 
        SavedViewContainer[w.UUID] = w
        return {key: w.UUID, value:w.UUID, text: w.Name}
      })
      
    let PrintLabelContainer = {}
    for(let lb of ret4.PrintLabels)
    {
      PrintLabelContainer[lb.UUID] = lb
    }

    SavedViewOption.push({key: EMPTY_UUID, value:EMPTY_UUID, text: "<Current View>"})



    let shareLinks = await window.LR_GetShareLinksForProject()    
    let options = []
    this.SHARE_LINK_MAP = {}

    if(Array.isArray(shareLinks))
    {
      options = shareLinks.map((e)=>
      {
        this.SHARE_LINK_MAP[e.name] = e.token
        return {
        key: e.name,
        text: e.name,
        value:e.name
      }})
    }

    options.push(
      {
        text:"<None>",
        value: "",
        key:"<None>"
      }
    )

    this.setState( { 
      open : true, 
      SavedViewOption,
      PrintLabels:ret4.PrintLabels,
      PublicShareLinkOptions: options,
      SavedViewContainer,
      PrintLabelContainer
    });
  }

  export = () => 
  {   
    if(this.state.activeTab === 0)
    {
      window.LR_WritePngToDisk({
        Base64: this.state.image.url,
      })
    } 
    else
    {
      window.LR_ExportView({
        SelectedView: this.state.SelectedView,
        SelectedPrintLabel: this.state.selectedPrintLabel,
        PublicShareLink: this.SHARE_LINK_MAP[ this.state.PublicShareLink ],
      })
    }

    this.close()
  }

  cancel = () => 
  {

    this.close()
  }

  close = () => 
  {

    this.setState({open : false});
  }
    
  render() 
  {
    const panes = 
    [
      { menuItem: LocalizedStrings.PNG, render: () => 
        <Tab.Pane >
          {this.renderExportPng()}
        </Tab.Pane> 
      },
      { menuItem: LocalizedStrings.PDF,  render: () => 
        <Tab.Pane >
          {this.renderExportPdf()}
        </Tab.Pane> 
      },

    ]

    return (
      <LRModal
        open={this.state.open}
        closeOnDimmerClick={false}
        closeOnEnter={false}
        title={LocalizedStrings.Header}
        size="fullscreen"
        onOkClick={this.export}
        dimmer=""
        onCancelClick={this.cancel}
        onDiscardClick={this.close}
      >
        <Tab menu={{ secondary: true, pointing: true }} 
        activeIndex={this.state.activeTab} onTabChange={(event, {activeIndex})=>{this.setActiveTab(activeIndex)}}
        panes={panes}/>

      </LRModal>
    );
  }

  setActiveTab = (tabIndex) =>
  {
    this.setState({activeTab:tabIndex})
  }

  renderExportPdf()
  {
    let height = 297
    let width  = 210
    let selectedLabel = this.state.PrintLabelContainer[this.state.selectedPrintLabel]
    if(selectedLabel)
    {
      height = selectedLabel.PaperSizeY
      width = selectedLabel.PaperSizeX
    }


    return <Grid className='ExportViewReport'>
    <Grid.Row>
      <Grid.Column width="13">
        <Image style={{height:"50em"}} src={"data:image/png;base64," + this.state.image?.url}></Image>
      </Grid.Column>
      <Grid.Column width="3">
        <Form>
          <Form.Select
            label={LocalizedStrings.PrintLabel}
            options={[
              { key: EMPTY_UUID, value: EMPTY_UUID, text: LocalizedStrings.NoBackground },
              ...this.state.PrintLabels.map((lb) => ({
                key: lb.UUID,
                value: lb.UUID,
                text: lb.Name,
              })),
            ]}
            value={this.state.selectedPrintLabel}
            onChange={(_, { value }) => {
              this.setState({ selectedPrintLabel: value });
            }}
          />       
            <Form.Select
            label={LocalizedStrings.SavedView}
            options={this.state.SavedViewOption}
            value={this.state.SelectedView}
            onChange={(_, { value }) => {
              this.setState({ SelectedView: value }, ()=>this.getRenderImage());
            }}
          />            
          <Form.Select
            onAddItem={this.createShareLink}
            search
            disabled={this.state.Project===""}
            allowAdditions={true}
            label={LocalizedStrings.PublicShareLink}
            value={this.state.PublicShareLink}
            fluid
            compact
            options={this.state.PublicShareLinkOptions}
            onChange={(_, { value }) => {
              this.setState({ PublicShareLink: value });
            }}
          />
          <UnitInput
                        fluid
                        label={LocalizedStrings.Height}
                        baseUnit      = {BASE_UNIT_LENGTH}
                        value         = {height}
                        readOnly/>
          <UnitInput
                        fluid
                        label={LocalizedStrings.Width}
                        baseUnit      = {BASE_UNIT_LENGTH}
                        value         = {width}
                        readOnly/>

        </Form>
        {this.state.Project === "" ? (
                    <Message
                      icon="dont"
                      error
                      header={LocalizedStrings.NoOnlineProjectHeader}
                      content={LocalizedStrings.NoOnlineProjectText}
                    />
                  ) : null}
      </Grid.Column>
    </Grid.Row>
  </Grid>
  


}

  renderExportPng()
  {
    return <Grid className='ExportViewReport'>
    <Grid.Row>
      <Grid.Column width="13">
        <Image style={{height:"50em"}} src={"data:image/png;base64," + this.state.image?.url}></Image>
      </Grid.Column>
      <Grid.Column width="3">
        <Form>  
            <Form.Select
            label={LocalizedStrings.SavedView}
            options={this.state.SavedViewOption}
            value={this.state.SelectedView}
            onChange={(_, { value }) => {
              this.setState({ SelectedView: value }, ()=>this.getRenderImage());
            }}
          />    
          <UnitInput
                        fluid
                        label={LocalizedStrings.Height}
                        baseUnit      = {BASE_UNIT_NUMBER}
                        value         = {this.state.Height}
                        onUpdateData = {(name, value) => { this.setState({Height:value}, ()=>this.getRenderImage()); }} />
          <UnitInput
                        fluid
                        label={LocalizedStrings.Width}
                        baseUnit      = {BASE_UNIT_NUMBER}
                        value         = {this.state.Width}
                        onUpdateData = {(name, value) => { this.setState({Width:value}, ()=>this.getRenderImage() )}} />
          </Form>
      </Grid.Column>
    </Grid.Row>
  </Grid>
  }

  getRenderImage = async () =>
  {
    if(globalCallbacks.GetPNGOfSavedView)
    {

      let view = this.state.SavedViewContainer[this.state.SelectedView]

      let req = {
        xSize: this.state.Width / 10, 
        ySize:this.state.Height / 10,
      }
      if(view)
      {
        req.view = view
      }
      
      let image = await globalCallbacks.GetPNGOfSavedView(req)
      this.setState({image: image})
    }
  }

  createShareLink = async(e, data) =>
  {
    await window.LR_CreateShareLinkForProject({Name: data.value})
    this.show()
  }



  setUpCallbacks()
  {

    globalCallbacks.ShowExportViewModal = () => 
    { 
      this.show();
      this.getRenderImage()
    }    
  }
}

export default ExportViewModal