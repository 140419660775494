//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Select } from 'semantic-ui-react';
import LocalizedStrings from "../../../localization/TableViewComponent";
import { BASE_UNIT, BASE_UNIT_LENGTH, BASE_UNIT_POWER, EMPTY_UUID, GetConnectorInfoByName_Electrical, kWireType_Consumer, kWireType_Input } from '../../../util/defines';
import UnitInput from '../../Basics/BasicUnitInput';
import DynamicTable, { TABLE_SELECTION_TYPE_Cell } from '../../Basics/DynamicTableView';


import { globalCallbacks as mainCB } from '../../../util/callback';
import { globalCallbacks as mockCB } from '../../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB

class ConnectionView extends Component
{
    constructor(prop)
    {
        super(prop);  
        
        this.state = 
        {
            colorCodes: [],
            departments:[]
        }

        this.cellHeader = [ 
                            {ident: "Type", label: LocalizedStrings.Electrical_Type},
                            {ident: "From", label: LocalizedStrings.Electrical_From},
                            {ident: "To", label: LocalizedStrings.Electrical_To},
                            {ident: "Department", label: LocalizedStrings.Electrical_Department},
                            {ident: "FromAssemblyGroup", label: LocalizedStrings.Electrical_FromAssemblyGroup},
                            {ident: "ToAssemblyGroup", label: LocalizedStrings.Electrical_ToAssemblyGroup},
                            {ident: "PathName", label: LocalizedStrings.Electrical_PathName},
                            {ident: "CableType", label: LocalizedStrings.Electrical_CableType},
                            {ident: "ColorCode", label: LocalizedStrings.Electrical_ColorCode},
                            {ident: "Fuse", label: LocalizedStrings.Electrical_Fuse},
                            {ident: "FuseAssemblyGroup", label: LocalizedStrings.Electrical_FuseAssemblyGroup},
                            {ident: "Generator", label: LocalizedStrings.Electrical_Generator},
                            {ident: "GeneratorAssemblyGroup", label: LocalizedStrings.Electrical_GeneratorAssemblyGroup},
                            {ident: "CableLength", label: LocalizedStrings.Electrical_Length},
                            {ident: "Consumption", label: LocalizedStrings.Electrical_Consumption},
                                ]
    }

    componentDidMount()
    {
        this.setUpCallbacks();

        globalCallbacks.UpdateConnectionList()
    }


    IsInput = (wiretype) =>
    {
        if(kWireType_Consumer === wiretype)
        {
            return true
        }

        if(kWireType_Input === wiretype)
        {
            return true
        }

        return false
    }

    render()
    {
        let mapConnections = {

        }   
        let connections = []

        let AllCableEntry = {}


        this.props.objects.forEach(e => 
        {
            

            e.Resolved.ElectricalConnections.forEach(con => 
            {

                for(let e of con.SelectedCables)
                {
                    if(!AllCableEntry[e.UUID])
                    {
                        AllCableEntry[e.UUID] = e.Name
                    }
                }
                con.Connections.forEach(i_con =>
                {   
                    connections.push({
                        Object: e.Resolved,
                        Connection: con,
                        InternalConnection: i_con,
                        SortProperties:{
                            To:  e.Resolved.ObjectId,
                            From: con.ConnectionName,
                            Type: this.IsInput(con.OwnWireType) ? "Input" : "Output",
                            FromAssemblyGroup:  !this.IsInput(con.OwnWireType) ? e.Resolved.AssemblyGroupName : i_con.AssemblyGroupName,
                            ToAssemblyGroup: this.IsInput(con.OwnWireType) ? e.Resolved.AssemblyGroupName : i_con.AssemblyGroupName,
                            Fuse: con.LinkedFuse,
                            FuseAssemblyGroup: con.LinkedFuseAssemblyGroup,
                            Generator: con.LinkedGenerator,
                            GeneratorAssemblyGroup:con.LinkedGeneratorAssemblyGroup,
                            CableType: GetConnectorInfoByName_Electrical(con.ConnectorType).ConnectorName
                        }
                    })

                    if(this.IsInput(con.OwnWireType))
                    {
                        mapConnections[i_con.ConnectionID] = true
                    }

                    
                })

            });
        });
        
        connections = connections.filter(entry =>
        {

            if(this.props.searchFilter !== "")
            {
                let filter = this.props.searchFilter.toLowerCase()
                let b1 = entry.SortProperties.From.toLowerCase().includes(filter)
                let b2 = entry.SortProperties.FromAssemblyGroup.toLowerCase().includes(filter)
                let b3 = entry.SortProperties.ToAssemblyGroup.toLowerCase().includes(filter)
                let b4 = entry.SortProperties.To.toLowerCase().includes(filter)
                if(!(b1 || b2 || b3 || b4))
                {
                    return false
                }
            }
            if(this.IsInput(entry.Connection.OwnWireType) || mapConnections[entry.InternalConnection.ConnectionID] === undefined)
            {
                return true
            }
            return false
        })

        let cellRender = (entry, rowIndex) =>
        {
            let CableEntry = {}
            for(let e of entry.Connection.SelectedCables)
            {
                if( ! CableEntry[e.UUID])
                {
                    CableEntry[e.UUID] = 1
                }
                else
                {
                    CableEntry[e.UUID]++;
                }
            }



            return {
                Type: <>{this.IsInput(entry.Connection.OwnWireType) ? "Input" : "Output"}</>,
                Department: <Select options={this.state.departments}
                                    multiple
                                    style={{zIndex: connections.length + 5 - rowIndex}}
                                    value = {entry.Object.LinkedDepartments.map(depObj => {
                                        return depObj.UUID
                                    })}
                                    onChange={(_,{value})=>window.LR_SetObject({UUID:entry.Object.UUID, LinkedDepartments: value})}/>,
                From: <>{entry.SortProperties.From}</>,
                To: <>{entry.SortProperties.To}</>,
                FromAssemblyGroup: <>{entry.SortProperties.FromAssemblyGroup}</>,
                ToAssemblyGroup: <>{entry.SortProperties.ToAssemblyGroup}</>,
                PathName: <>{entry.PathName}</>,
                CableType: <>{entry.SortProperties.CableType}</>,
                Fuse: <>{entry.SortProperties.Fuse}</>,
                FuseAssemblyGroup: <>{entry.SortProperties.FuseAssemblyGroup}</>,
                Generator: <>{entry.SortProperties.Generator}</>,
                GeneratorAssemblyGroup: <>{entry.SortProperties.GeneratorAssemblyGroup}</>,
                ColorCode: <Select     value={entry.Object.ColorCodeObject.UUID} 
                                            options={this.state.colorCodes}
                                            onChange={(e, {value})=>{window.LR_SetObject({UUID: entry.Object.UUID, ColorCode: value})}}/>,
                CableLength: <UnitInput readOnly baseUnit={BASE_UNIT_LENGTH} value={entry.Connection.AttachedCableLength}/>,
                Consumption: <UnitInput readOnly baseUnit={BASE_UNIT_POWER} value={entry.Connection.Consumption}/>,
                ...CableEntry
            }
        }

        let sortFunction = (objectA, objectB, propertyIdentifer) =>
        {
            return {First: objectA.SortProperties[propertyIdentifer], Second: objectB.SortProperties[propertyIdentifer]}
        }
        
        let cablesHeader = []
        for (const [key, value] of Object.entries(AllCableEntry)) 
        {
            cablesHeader.push({ident: key, label: value})
        }


        return <DynamicTable 
                headerIdent={[...this.cellHeader, ...cablesHeader]}
                cellRender={cellRender}
                tableName="Connection_List"
                pages={this.props.pages}
                worksheet={this.props.worksheet}
                headerData={this.props.headerData}
                cellData={connections}
                selectionType={TABLE_SELECTION_TYPE_Cell}
                sortFunction={sortFunction}
                selectionChanged={this.selectionChanged}
                showPages={this.props.showPages}
                printMargin={this.props.printMargin}
                printScale={this.props.worksheetScale}/>
    }

    selectionChanged = (selectedIdent, selectedObjects, arrayIndex) => 
    {
        window.LR_Select({
            SelectionMode: 0, 
            SelectedList: [
                ...selectedObjects.map(connection => connection.Object.UUID),
                ...selectedObjects.map(connection => connection.InternalConnection.Object),
            ], 
            Selected: true})

    }

    setUpCallbacks()
    {
        globalCallbacks.UpdateConnectionList = async () =>
        {
            let res = await window.LR_GetColorCodeObjects()
            let colorCodes = res.ColorCodeObjects.map(ccObject => {
                return {
                    key: ccObject.UUID,
                    value: ccObject.UUID,
                    text: <UnitInput
                        baseUnit={BASE_UNIT.COLOR}
                        value={ccObject}
                        readOnly
                        transparent
                        label={false}
                        style={{ minWidth: 150 }}
                    />
                }
            })

            this.setState({
                colorCodes: [
                    {
                        key: EMPTY_UUID,
                        value: EMPTY_UUID,
                        text: <UnitInput
                            baseUnit={BASE_UNIT.COLOR}
                            value={{
                                Name: "None"
                            }}
                            readOnly
                            transparent
                            label={false}
                            style={{ minWidth: 150 }}
                        />
                    },
                    ...colorCodes
                ]
            })

            res = await window.LR_GetDepartments()
                
            let departments = res.Departments.map(depObj => {
            return {
                key: depObj.UUID,
                value: depObj.UUID,
                text: depObj.Name
            }
            })
            this.setState({departments})
                

        }
    }
}

export default ConnectionView;