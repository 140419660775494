//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2019 www.deersoft.de
//-----------------------------------------------------------------------------
import LocalizedStrings from 'localized-strings';
import LocalizeOption from './LocalizationOption';
 
import en from "./raw/en/AdminResourceManager.json"
import de from "./raw/de/AdminResourceManager.json"
import es from "./raw/es/AdminResourceManager.json"
import fr from "./raw/fr/AdminResourceManager.json"
import it from "./raw/it/AdminResourceManager.json"
import ru from "./raw/ru/AdminResourceManager.json"
import cz from "./raw/cs/AdminResourceManager.json"


let Strings = new LocalizedStrings<Partial<typeof en>>(
{
  en: en,
  de: de,
  es: es,
  fr: fr,
  ru: ru,
  it: it,
  cz: cz,
},
  { ...LocalizeOption }
);

export default  Strings;