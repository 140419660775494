//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2022
//----------------------------------------------------------------------------------------------------------------


import React, { Component } from 'react';
import { Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { PROJECTS, ACTIVE_USER} from "./../../redux/redux_defines"
import { IsRunningAsBrowser } from '../../util/defines';
import { FetchProjects } from '../../redux/actions/fetch_actions';
import LocalizedStrings from "../../localization/TimeStrings";
const Folders = React.lazy(()=>import("./Folders"))

class ProjectFolder extends Component 
{
  constructor(props)
  {
      super(props);
      this.state ={
           projects: [],
           user:{},
           isApp: false
      };
  }


  componentDidMount = async () =>
  {

    if(!IsRunningAsBrowser())
    {
        let projects = await window.LR_GetProjectsAsync()
        let user = await window.LR_GetUserDataAsync()
        this.setState({projects: projects, user: user, isApp: true})
    }
  }


  render() 
  {
    let projects = this.getData().projects
    let activeUserFolders = this.getData().userFolders

    //----------------------------------------------------------------------------------------------------
    // Create Map with the project from the user, and set them to the parent folder by default
    let formatedFiles = {}
    let projectFiles = [...projects]

    
    projectFiles.forEach(project => 
    {
        formatedFiles[project.id] = { 
            id : project.id, 
            name : project.DrawingSettings.ProjectPrettyName ? project.DrawingSettings.ProjectPrettyName : project.name, 
            isDir:false,
            targetFile: `/${project.owner.username}/${project.projectname}`,
            checkoutName:project.name,
            checkoutUser:project.owner.username,
            checkoutBranch: project?.defaultBranch?.id,
            checkoutBranchName:project?.defaultBranch?.name,
            modDate: project.dateCreated,
            draggable:true, 
            droppable:false,
            ext:"",
            color:"#45BBFF",
            parentId :"projectFolder",
        }
    })


    //----------------------------------------------------------------------------------------------------
    // When this is a freh user, there are no folder, so we create a new foot folder and add them projects
    let projectsData;
    if(!activeUserFolders || activeUserFolders.length === 0 )
    {

                projectsData = {
                    rootFolderId: "projectFolder",
                    fileMap: {
                        projectFolder : {
                            id: "projectFolder",
                            name: LocalizedStrings.ProjectFolder,
                            isDir: true,
                            childrenIds: [...projectFiles.map(f => f.id)],
                            childrenCount : projectFiles.length
                        },...formatedFiles
                    }
                }
 
    }
    //----------------------------------------------------------------------------------------------------
    // When there is already folder, we need to add the project to the folder
    else 
    {
        projectsData = activeUserFolders
     
        //----------------------------------------------------------------------------------------------------
        // Generate a Map with All the Folders
        let formatedFolders = {}
        

        projectsData.forEach(folder => 
        {
            formatedFolders[folder.id] = {...folder}
        })

        projectsData.forEach(folder =>
            {    
                folder.childrenIds.filter(child =>
                {
                    if(formatedFolders[child])
                    {
                        formatedFolders[child].parentId = folder.id
                        return true
                    }
                    if(formatedFiles[child])
                    {
                        formatedFiles[child].parentId = folder.id
                        return true
                    }
                    return false
                })

                //folder.childrenCount = folder.childrenIds.length
    
            })

        
        //----------------------------------------------------------------------------------------------------
        // Find childrenIds and childrenCount in the top level
        
        let allFilesAndFolders = [ ...Object.values(formatedFiles),...projectsData]

        let topLevelChildren = allFilesAndFolders.filter(child => child.parentId === "projectFolder")

        let topLevelChildrenIds = topLevelChildren.map(child => child.id)

        let topLevelChildrenCount = topLevelChildren.length

        //----------------------------------------------------------------------------------------------------
        // Form file and folder like chonky format
        projectsData = {
            rootFolderId: "projectFolder",
            fileMap : {
                projectFolder : {
                    id: "projectFolder",
                    name: LocalizedStrings.ProjectFolder,
                    isDir: true,
                    childrenIds: topLevelChildrenIds,
                    childrenCount : topLevelChildrenCount
                },
                ...formatedFiles, 
                ...formatedFolders
            }
        }
    }  
        return (
            <>
                { projects && activeUserFolders ?  <Folders projectsData={projectsData} isApp={this.state.isApp} /> : <Loader inverted content={LocalizedStrings.Loading} /> }
            </>
        )
    }   

  getData = () =>
  {
      if (!IsRunningAsBrowser())    
      {
          return {
                    projects    : this.state.projects,
                    userFolders : this.state.user.folders
                 }
      }
      else
        {
            FetchProjects()
            return {
                        projects    : this.props.projects.data,
                        userFolders : this.props.activeUser.data.folders
                    }
        }
  }
  }

//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      activeUser: (state[ACTIVE_USER]),
      projects: (state[PROJECTS])
    };
}

export default connect(mapStateToProps)(ProjectFolder)