//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Icon, Button, Table, Form, Select, Message, Loader, Dimmer, Segment} from 'semantic-ui-react'
import LocalizedStrings from "../../localization/ResourceManager";
import LRModal from '../Basics/BasicModal';
import { globalCallbacks } from '../../util/callback';
import { BASE_UNIT_LENGTH, BASE_UNIT_NUMBER, BASE_UNIT_WEIGHT, IsRunningAsBrowser } from '../../util/defines';
import UnitInput from '../Basics/BasicUnitInput';
import LRTableHeaderCell from '../Basics/BasicTableView';


class CaseTemplateDisplay extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    {
      cases: [],
      caseSymbols:[],
      createOpen:false,
      editOpen: false,
      editCaseName: "",

      createName: "",
      createSizeX: 0,
      createSizeY: 0,
      createSizeZ: 0,
      createWeight: 0,
      isloading: false,

      onlineResources: [],
      editSelectedResource: undefined,
    };

  }

  componentDidMount()
  {
    this.setUpCallbacks();
    if (globalCallbacks.updateCaseTemplates) { globalCallbacks.updateCaseTemplates(); }

    window.LR_GetServerResourceAsync().then(serverResources => { console.log("Getting online resource: ", serverResources); this.setOptions(serverResources)});
    //window.LR_GetLocaleResources().then(localeResources => { this.setOptionsLocale(localeResources)})
  }
  
  setOptions = (serverResources) =>
  {
    let options = []
    serverResources.forEach(folder => 
      {
        folder.files.forEach(file => 
          {
            if(file.revisions && file.revisions[0])
            {
              file.revisions[0].symbols.forEach(symbol => 
                {
                  options.push({
                    text: symbol.Name,
                    value: symbol.UUID,
                    key: symbol.UUID,
                    ident: file.identifier,
                    uuid:symbol.UUID,
                    name: symbol.Name,
                    label: { children: symbol.Name }
                  })
                });
                file.revisions[0].fixtureTypes.forEach(symbol => 
                  {
                    options.push({
                      text: symbol.Name,
                      value: symbol.UUID,
                      key: symbol.UUID,
                      ident: file.identifier,
                      uuid:symbol.UUID,
                      name: symbol.Name,
                      label: { children: symbol.Name }
                    })
                  });
            }
            
          });
        
      });

      this.setState({onlineResources: options})
  }

  setOptionsLocale = (localeResources) => 
  {
    let options = []
    localeResources.forEach(file => {
      file.Symbols.forEach(symbol => {
        options.push({
            text: symbol.Name,
            value: symbol.UUID,
            key: symbol.UUID,
            ident: file.identifier,
            uuid:symbol.UUID,
            name: symbol.Name,
            label: { children: symbol.Name }
          })
      })
    })
    this.setState({onlineResources: options})
    console.log("LocaleREsources: ", localeResources)
  }

  render() 
  {
    return(
      <React.Fragment>
      <Table selectable striped celled>
        <Table.Header>
          <Table.Row>
            <LRTableHeaderCell>{LocalizedStrings.Name}</LRTableHeaderCell>
            <LRTableHeaderCell baseUnit={BASE_UNIT_LENGTH}>{LocalizedStrings.SizeX}</LRTableHeaderCell>
            <LRTableHeaderCell baseUnit={BASE_UNIT_LENGTH}>{LocalizedStrings.SizeY}</LRTableHeaderCell>
            <LRTableHeaderCell baseUnit={BASE_UNIT_LENGTH}>{LocalizedStrings.SizeZ}</LRTableHeaderCell>
            <LRTableHeaderCell baseUnit={BASE_UNIT_WEIGHT}>{LocalizedStrings.Weight}</LRTableHeaderCell>
            <LRTableHeaderCell>{LocalizedStrings.HasWheels}</LRTableHeaderCell>
            <LRTableHeaderCell>{LocalizedStrings.AllowTip}</LRTableHeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        {this.state.cases.length !== 0   ?
        <Table.Body>
          {this.state.cases.map( (entry, index) => {return this.render_Row(entry, index)})}
        </Table.Body> 
        :
        this.state.isLoading ?<Message style={{width:'100%'}}><Loader active content={LocalizedStrings.Loading}/></Message> :  
                              <Message style={{width:'100%'}} warning><Message.Header>{LocalizedStrings.NoCaseHeader}</Message.Header><p>{LocalizedStrings.NoCaseText}</p></Message>}
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan='9'>
              <Button
                floated='right'
                icon
                labelPosition='left'
                primary
                onClick={()=>{this.setState({createOpen:true})}}
                size='small'>
                <Icon name='box' />
                {LocalizedStrings.AddCase}
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>

      <LRModal  open={this.state.createOpen} 
                title={LocalizedStrings.CreateCaseObjectsHeader}
                onCancelClick={this.onCloseCreate}
                onOkClick={this.onCreateCaseTemplate}>
        <Form>
          <Form.Input label={LocalizedStrings.Name} value={this.state.createName} onChange={(e, {value}) => {this.setState({createName: value})}}/>
          <Form.Group widths='equal'>
              <UnitInput name="SizeX" label={LocalizedStrings.SizeX} baseUnit={BASE_UNIT_LENGTH} value={this.state.createSizeX} onStateUpdate={(name, val) => {this.setState({createSizeX: val})}}/>
              <UnitInput name="SizeY" label={LocalizedStrings.SizeY} baseUnit={BASE_UNIT_LENGTH} value={this.state.createSizeY} onStateUpdate={(name, val) => {this.setState({createSizeY: val})}}/>
              <UnitInput name="SizeZ" label={LocalizedStrings.SizeZ} baseUnit={BASE_UNIT_LENGTH} value={this.state.createSizeZ} onStateUpdate={(name, val) => {this.setState({createSizeZ: val})}}/>
          </Form.Group>
          <UnitInput name="Weight" label={LocalizedStrings.Weight} baseUnit={BASE_UNIT_WEIGHT} value={this.state.createWeight} onStateUpdate={(name, value) => {this.setState({createWeight: value})}}/>
        </Form>
      </LRModal>
      
      <LRModal  title={LocalizedStrings.EditCaseObjectsHeader}
                open={this.state.editOpen}
                scrolling={false}
                onCancelClick={this.onCloseEdit}
                onOkClick={this.onCloseEdit}>
        <Table selectable striped celled>
          <Table.Header>
            <Table.Row>
              <LRTableHeaderCell>{LocalizedStrings.CaseSymbol}</LRTableHeaderCell>
              <LRTableHeaderCell>{LocalizedStrings.CaseSymbolCount}</LRTableHeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
              {this.state.caseSymbols && this.state.caseSymbols.map( (entry, i) => {return this.render_Row_CaseSymbols(entry, i)})}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='3'>
                <Button
                floated='right'
                icon
                labelPosition='left'
                primary
                onClick={()=>{this.onAddCaseTemplateSymbol()}}
                size='small'><Icon name='box' />{LocalizedStrings.CaseSymbolAddEntry}</Button>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>   
      </LRModal>
      </React.Fragment>
    );
  }

  onAddCaseTemplateSymbol = () => {
    window.LR_AddCaseTemplateSymbol({CaseName: this.state.editCaseName}).then(() => {
      if (globalCallbacks.updateCaseTemplates && IsRunningAsBrowser()) { globalCallbacks.updateCaseTemplates(); }
    })
  }

  onCloseCreate  = () =>
  {
    this.setState({createOpen:false, disableTo: false})
  }

  onCreateCaseTemplate = () =>
  {
    window.LR_AddCaseTemplate({Name: this.state.createName, SizeX: this.state.createSizeX, SizeY: this.state.createSizeY, SizeZ: this.state.createSizeZ, Weight:this.state.createWeight }).then(() => {
      if (globalCallbacks.updateCaseTemplates && IsRunningAsBrowser()) { globalCallbacks.updateCaseTemplates(); }
    })
    this.setState({createOpen: false})
  }

  onCloseEdit = () =>
  {
    this.setState({editOpen: false})
  }

  onDeleteCaseTemplate = (entry) => {
    window.LR_DeleteCaseTemplate({Name: entry.Name}).then(() => {
      if (globalCallbacks.updateCaseTemplates && IsRunningAsBrowser()) { globalCallbacks.updateCaseTemplates(); }
    })
  }

  render_Row(entry, index) 
  {
    return(<Table.Row>
              <Table.Cell>
                <Icon name="delete" color="red" link textAlign="right" onClick={() => {this.onDeleteCaseTemplate(entry)}}></Icon>
                {entry.Name}
              </Table.Cell>
              <Table.Cell>
                <UnitInput name         ="SizeX"
                           baseUnit     ={BASE_UNIT_LENGTH} 
                           transparent 
                           label        ={false} 
                           value        ={entry.SizeX} 
                           onStateUpdate={(name, value, e) => {this.updateState(name, value, index)}}
                           onUpdateData ={() => this.updateData(entry)}
                           noStepper/>
              </Table.Cell>
              <Table.Cell>
                <UnitInput name         ="SizeY"
                           baseUnit     ={BASE_UNIT_LENGTH} 
                           transparent 
                           label        ={false} 
                           value        ={entry.SizeY} 
                           onStateUpdate={(name, value, e) => {this.updateState(name, value, index)}}
                           onUpdateData ={() => this.updateData(entry)}
                           noStepper/>
              </Table.Cell>
              <Table.Cell>
                <UnitInput name         ="SizeZ"
                           baseUnit     = {BASE_UNIT_LENGTH} 
                           transparent 
                           label        ={false} 
                           value        ={entry.SizeZ} 
                           onStateUpdate={(name, value, e) => {this.updateState(name, value, index)}}
                           onUpdateData ={() => this.updateData(entry)}
                           noStepper/>
              </Table.Cell>
              <Table.Cell>
                <UnitInput name         ="Weight"
                           baseUnit     ={BASE_UNIT_WEIGHT} 
                           transparent 
                           label        ={false} 
                           value        ={entry.Weight} 
                           onStateUpdate={(name, value, e) => {this.updateState(name, value, index)}}
                           onUpdateData ={() => this.updateData(entry)}
                           noStepper/>
              </Table.Cell>
              <Table.Cell>
                <Form.Checkbox name="HasWheel" label={LocalizedStrings.HasWheels} checked={entry.HasWheels} onChange={(_, {checked}) => {this.updateData({...entry, HasWheels: checked})}}/>
              </Table.Cell>
              <Table.Cell>
                <Form.Checkbox name="AllowTip" label={LocalizedStrings.AllowTip} checked={entry.AllowTip} onChange={(_, {checked}) => {this.updateData({...entry, AllowTip: checked})}}/>
              </Table.Cell>
              <Table.Cell><Button fluid size="tiny" onClick={()=> this.setState({editOpen:true, editCaseName: entry.Name, caseSymbols: entry.SymbolList})}>{LocalizedStrings.EditCaseObjects}</Button></Table.Cell>
            </Table.Row>)
  }

  updateState(name, value, index)
  {
    this.setState({cases: this.state.cases.map((entry, i) => {
      if(i===index)
      {
        return {
          ...entry,
          [name]:value
        }
      }
      return entry
    })})
  }

  updateData(entry) 
  {
    window.LR_UpdateCaseTemplate({"Name": entry.Name, Entry: entry}).then(() => {
      if (globalCallbacks.updateCaseTemplates && IsRunningAsBrowser()) { globalCallbacks.updateCaseTemplates(); }
    })
  }

  render_Row_CaseSymbols(entry, index) 
  {
    return(<React.Fragment>
          <Table.Row >
            <Table.Cell collapsing>
              {
                (this.state.onlineResources.length === 0) ? 

                <Segment style={{padding:"1.5rem 0"}}>
                  <Dimmer active inverted>
                    <Loader size='mini' active inline>{LocalizedStrings.Loading}</Loader>
                  </Dimmer>
               </Segment> :

                <Select fluid  options={this.state.onlineResources}
                        search
                        value={entry.SymbolUuid}
                        onChange={(e, {value}) => { this.onChangeSelect(index, "SymbolUuid", value, true) }}>
                </Select> 
              }
            
            </Table.Cell>
            <Table.Cell collapsing>
              <UnitInput 
                      fluid value   ={entry.Count}
                      baseUnit      ={BASE_UNIT_NUMBER}
                      onUpdateData  ={ () => { this.onChangeSelect(index, "Count", entry.Count, true) }}
                      onStateUpdate ={(name, value) => { this.onChangeSelect(index, "Count", value, false) }}
                      min           ={0}
                      noStepper
              />
            </Table.Cell>
          <Table.Cell collapsing>
            <Icon name="delete" color="red" link textAlign="right" onClick={() => {this.onDeleteCaseTemplateSymbol(entry)}}></Icon>
          </Table.Cell>
          </Table.Row>
          </React.Fragment>)
  }

  onDeleteCaseTemplateSymbol = (entry) => {
    window.LR_RemoveCaseTemplateSymbol({CaseName: this.state.editCaseName, SymbolUuid: entry.SymbolUuid}).then(() => {
      if (globalCallbacks.updateCaseTemplates && IsRunningAsBrowser()) { globalCallbacks.updateCaseTemplates(); }
    })
  }

  onChangeSelect = (index, field, value, sendToCore) =>
  {
    let newData = this.state.caseSymbols.map((entry, i) => 
    {
      return {...entry, [field]: i=== index ? value : entry[field]}
    })

    if(sendToCore) { 
      window.LR_SetCaseTemplateSymbols({CaseName: this.state.editCaseName, CaseSymbols: newData}).then(() => {
        if (globalCallbacks.updateCaseTemplates && IsRunningAsBrowser()) { globalCallbacks.updateCaseTemplates(); }
      })
      this.setState({caseSymbols: newData})
    }
    else           { this.setState({caseSymbols: newData}) }

    
  }

  setUpCallbacks()
  {
    globalCallbacks.updateCaseTemplates =  async () =>
    {
      let res = await window.LR_GetCaseTemplateMap()
      
      res.ResourceMap.caseTemplate.forEach(caseTemplate => {
        if (caseTemplate.Name === this.state.editCaseName)
        {
          this.setState({caseSymbols: caseTemplate.SymbolList});
        }
      })
      
      this.setState({cases: res.ResourceMap.caseTemplate})
    }
  }
}

export default CaseTemplateDisplay;

