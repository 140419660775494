//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------

import React, {Component} from 'react';
import LocalizedStrings from "../../localization/ExportStructuralCalculation";
import { Button, Icon, Label, Popup, Header } from 'semantic-ui-react';


class RequestStructuralCalculationButton extends Component 
{
    constructor(props)
    {
        super(props);
        this.state = 
        { 
            Loading: true,
            Price: 0,
            Currency: "eur"
        }
    }

    componentDidMount = async () =>
    {
        let res = await window.LR_GetCalculationPricing()

        this.setState({
            Loading: false,
            Price: res.Price,
            Currency: res.Currency,
        })
    }

    getPricing()
    {
        if(this.state.Loading)
        {
            return <Icon name='spinner' loading={true}/>
        }
        let cur = ""
        switch(this.state.Currency)
        {
        case "usd": cur = "$"; break;
        case "eur": cur = "€"; break;
        }
        
        return <>{this.state.Price / 100} {cur}</>
    }

    render()
    {
        return(
            <Popup
            trigger={
                <Button.Group fluid vertical>
                    <Button
                        negative 
                        onClick={()=>window.LR_ShowRequestCalculationCheck()}>
                        {LocalizedStrings.RequestCheckedCalculation}
                    </Button>
                    <Button 
                        positive
                        onClick={()=>window.LR_ShowRequestCalculationCheck()}>
                        <Label>
                            <Icon name="cart"/> 
                            {this.getPricing()}
                        </Label>
                    </Button>
                </Button.Group>
            }
            content={
                <>
                <Header>
                {LocalizedStrings.RequestCheckedCalculationPopupHeader}
                </Header>
                {LocalizedStrings.RequestCheckedCalculationPopupText}
                </>
            }
            
            />
        )

    }
}

export default RequestStructuralCalculationButton