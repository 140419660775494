
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Search, Table, Icon, Segment, Button, Loader, Select, Checkbox } from 'semantic-ui-react'
import WorksheetNode from "./WorksheetNode";
import LocalizedStrings from "../../localization/NavigationContainer";
import { EMPTY_UUID, IsElectronContext } from '../../util/defines';

import { globalCallbacks as mainCB } from '../../util/callback';
import { globalCallbacks as mockCB } from '../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB


const SORT_BY = "Name"

class WorksheetTable extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = 
        {
            Selected: {},
            AllSelected: false,
            worksheets  : [],
            indexMap    : [],
            FilteredTimeLine: EMPTY_UUID,

            // search
            isLoading: false,
            results  : [], 
            searchString    : "",
            sorting  : null,
            timeLineOptions: [],

            SelectedTimeLineChange: EMPTY_UUID,
            SelectedSelectedUser: [],
        };

    }

    componentDidMount = () => 
    {
        this.setUpCallbacks();
        globalCallbacks.getWorksheetsForNavigation();
    }

    componentWillUnmount = () => 
    {
        this.takeDownCallbacks();
    }

    render() 
    {
        return (
            <div style={{width:"100%", height:"100%"}}>
                <Table style={{borderBottom: "none", margin:0}}>
                    <Table.Header onClick={this.onHeaderClick}>
                        <Table.Row>
                            <Table.HeaderCell style={{zIndex: 0}} >
                                {<Icon link style={{floated: 'left', position: 'absolute',zIndex: 1}} name={this.state.sorting === null ? "sort" : this.state.sorting === "ascending" ? "sort alphabet up": "sort alphabet down"}/>}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                <Select 
                                    compact
                                    search
                                    options = {[{ value: EMPTY_UUID, text:LocalizedStrings.WorksheetTimePhaseFilter },...this.state.timeLineOptions]}
                                    value = {this.state.FilteredTimeLine}
                                    onChange = {(event, {value})=>{ this.setState({FilteredTimeLine:value })}}/>
                                    </Table.HeaderCell>
                                <Table.HeaderCell colSpan='3' >
                                <Search open    = {false}
                                        aligned = "right"
                                        value   = {this.state.searchString}
                                        onClick = {(e)=>{e.stopPropagation()}}
                                        onSearchChange = {(e, {value}) => this.onSearchChange(value)}/>
                            </Table.HeaderCell>
                        </Table.Row>
                        <Table.Row compact>
                            <Table.HeaderCell><Checkbox checked={this.hasSelection()} onChange={(e)=>{e.stopPropagation();this.onAllClick()}}/></Table.HeaderCell>
                            <Table.HeaderCell width={4}>{LocalizedStrings.WorksheetName}</Table.HeaderCell>
                            {this.hasSelection() ? this.renderMultiEditHeader() : this.renderTextHeader()}
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                </Table>

                <div style={{width:"100%", maxHeight:"calc(100vh - 34em)", overflowY:"auto", marginBottom:"9em"}}>
                    <Table striped structured compact='very' size="small">
                        <Table.Body>
                        <Loader active={this.state.worksheets.length === 0 && this.state.isLoading} />
                        {this.renderRows()}
                        </Table.Body>
                    </Table>
                </div>
 
                <Segment vertical textAlign="center" style={{position:"absolute", bottom: IsElectronContext() ? "1.5em" : "6em", width:"100%", border:"none"}}>
                    <Button.Group vertical>
                    <Button primary onClick={()=>window.LR_ShowCreateWorksheetDialog()}>
                        <Icon name="plus"/>{LocalizedStrings.AddNewWorksheet}
                    </Button>
                    <Button onClick={()=>window.LR_ShowCreateWorksheetDialog({AllAssemblyGroups: true})}>
                        <Icon corner name="plus"/>
                        {LocalizedStrings.AddNewWorksheetAll}
                    </Button>
                    </Button.Group>
                </Segment>
            </div>
        );
    }

    renderTextHeader()
    {
        return  <>
                    
                    <Table.HeaderCell width={5} textAlign="left">{LocalizedStrings.WorksheetTimePhase}</Table.HeaderCell>
                    <Table.HeaderCell width={3}>{LocalizedStrings.WorksheetUser}</Table.HeaderCell>
                </>
    }
    renderMultiEditHeader()
    {
        let OnBlur = () =>
        {
            let Worksheets = this.getSelectedWorksheets()
            let req ={Worksheets}

            if(this.state.SelectedTimeLineChangeChanged)
            {
                req.LinkedTimeLinePhaseChange = this.state.SelectedTimeLineChange
            }

            if(this.state.SelectedSelectedUserChanged)
            {
                req.DefaultAssingedUsers = this.state.SelectedSelectedUser
            }


            window.LR_SetWorksheet(req)

            this.setState({SelectedSelectedUser: [], SelectedTimeLineChange: EMPTY_UUID, SelectedTimeLineChangeChanged: false, SelectedSelectedUserChanged: false })
        };


            return <>
                <Table.HeaderCell>
                    <Select 
                        compact
                        search
                        options = {this.state.timeLineOptions}
                        value = {this.state.SelectedTimeLineChange}
                        onBlur={OnBlur}
                        onChange = {(event, {value})=>{ this.setState({SelectedTimeLineChange:value, SelectedTimeLineChangeChanged: true })}}/>
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <Select 
                        compact
                        search
                        multiple
                        options = {this.state.Users}
                        onBlur={OnBlur}
                        value = {this.state.SelectedSelectedUser}
                        onChange = {(event, {value})=>{ this.setState({SelectedSelectedUser:value, SelectedSelectedUserChanged: true })}}/>
                </Table.HeaderCell>
                <Table.HeaderCell>
                    <Button negative onClick={()=>{window.LR_DeleteWorksheet({UUIDS: this.getSelectedWorksheets()})}}>{LocalizedStrings.DeleteWorksheets}</Button>
                </Table.HeaderCell>
            </>
    }

    getSelectedWorksheets()
    {
        let Worksheets = []
        this.getDisplayedWorksheets().forEach((worksheet) =>
        {
            if(this.state.Selected[worksheet.UUID])
            {
                Worksheets.push(worksheet.UUID)
            }
        })
        return Worksheets
    }


    onAllClick = () =>
    {
        let SelectedValue = this.hasSelection()
        let Selected = {}

        this.getDisplayedWorksheets().forEach((worksheet) =>
        {
            Selected[worksheet.UUID] = !SelectedValue
        })
        this.setState({Selected})
    }

    hasSelection = () =>
    {
        let hasSelection = false
        Object.keys(this.state.Selected).every((key) =>
        {
            hasSelection = hasSelection || this.state.Selected[key]
            return !hasSelection
        })
        return hasSelection
    }

    onSelectWorksheet = (wsUUID, value) =>
    {
        this.setState({Selected: {...this.state.Selected, [wsUUID]: value}})
    }

    getDisplayedWorksheets()
    {
        let worksheets = this.state.worksheets

        if(this.state.sorting) 
        {
            worksheets =  this.state.indexMap.map((_, i) => {
                let calcIndex = this.state.sorting === 'ascending' ? i : this.state.indexMap.length - i - 1
                return this.state.worksheets[this.state.indexMap[calcIndex]]
            })
        }

        let retVal = []
        worksheets.forEach((worksheet) => 
        {
            if(this.state.FilteredTimeLine !== EMPTY_UUID && worksheet.LinkedTimeLinePhaseChange !== this.state.FilteredTimeLine)
            {
                return;
            }
            if(this.state.searchString !== "" && ! worksheet.Name.toLowerCase().includes(this.state.searchString.toLowerCase()))
            {
                return
            }
            retVal.push(worksheet) 
        })

        return retVal
    }

    renderRows = () => 
    {
        return this.getDisplayedWorksheets().map((worksheet) => 
        {
            return <WorksheetNode onSelectWorksheet={this.onSelectWorksheet} checked={this.state.Selected[worksheet.UUID]} DefaultAssingedUsers={worksheet.DefaultAssingedUsers} LinkedTimeLinePhaseChange={worksheet.LinkedTimeLinePhaseChange} key={worksheet.UUID} worksheet={worksheet} timeLineOptions={this.state.timeLineOptions} Users={this.state.Users}/>
        })
    }

    onHeaderClick = () => {
        let newVal = null
        switch (this.state.sorting) {
            case null:          newVal = "ascending";   break;
            case "ascending":   newVal = "descending";  break;
            default:            newVal = null;          break;
        }
        this.setState({
            sorting: newVal
        })
    }

    onSearchChange = (value, resources) => 
    {
      this.setState({searchString: value});
    }

    setUpCallbacks()
    {
        globalCallbacks.getWorksheetsForNavigation = async () =>
        {
            this.setState({isLoading: true});
            let workSheetArray = await window.LR_GetWorksheets();
            let indexMap = []
            let objects = workSheetArray.Worksheets
            objects.forEach((_, i) => indexMap.push(i))
            indexMap.sort((a,b) => objects[a][SORT_BY] === objects[b][SORT_BY]  ? 0 : (objects[a][SORT_BY]  < objects[b][SORT_BY]  ? -1 : 1))

            let stateArray = []
            let timeLinesArray = await window.LR_GetTimePhaseChanges();

            if(Array.isArray(timeLinesArray.Changes))
            {
                stateArray = timeLinesArray.Changes.map(timePhas => {
                return {
                    value: timePhas.UUID,
                    text: timePhas.Name,
                };
                });
            }

            let users   = await window.LR_GetUsers()

            let members = users.Users.map((user) => 
            {
                return {
                    text: <>{user.Name}</>,
                    key: user.UUID,
                    value: user.UUID,
                };
            });
            


            


            this.setState({worksheets: workSheetArray.Worksheets, indexMap: indexMap, timeLineOptions: stateArray, Users: members, isLoading: false});
        }
    }

    takeDownCallbacks()
    {
        globalCallbacks.getWorksheetsForNavigation = undefined;
    }

}

export default WorksheetTable;

