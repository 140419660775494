//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import LocalizedStrings from "../../localization/TableViewComponent";
import LRModal from '../Basics/BasicModal';
import { globalCallbacks } from "../../util/callback";


//-----------------------------------------------------------------------------
// The component for the TreeControl
class RenamePropertyDialog extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
      Property: {},
      LocalizedValue: ""
    }
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }

  show = async(property) => 
  {
    this.setState( { 
        open : true, 
        Property: property
      });
  }

  ok = () => 
  {
    window.LR_SetPropertyName({
      PropertyIdent: this.state.Property.Ident,
      LocalizedName: this.state.LocalizedValue
    })
    this.close()
  }

  close = () => 
  {
    this.setState({open : false});
  }
    
  render() 
  {
    let open = this.state.open;
    if(!open) {return <div/>}

    return (
      <LRModal  open={open} 
                onCancelClick={this.close} 
                closeOnDimmerClick={false} 
                size="mini"
                scrolling={false}
                title={LocalizedStrings.RenamePropertyName}
                onOkClick={this.ok}>
         <Form>
           <Form.Input label={this.state.Property.LocalizedName} value={this.state.LocalizedValue} onChange={(_,{value}) => {this.setState({LocalizedValue: value})}}/>
         </Form>
      </LRModal>
    )
  }

  setUpCallbacks()
  {

    globalCallbacks.ShowRenamePropertyDialog = (arg) => 
    { 
      this.show(arg);
    }
    
  }
}

export default RenamePropertyDialog