import React, {Component} from 'react'
import { Table, Icon, Button, Grid, Header, Input, Checkbox, List, Dimmer, Loader } from 'semantic-ui-react'
import { connect } from 'react-redux'
import LocalizedStrings from "../../localization/ChangeListChecks"
import LRTableHeaderCell from '../Basics/BasicTableView';
import LRModal from '../Basics/BasicModal';
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css"; //Example style, you can use another
import LRFilterInput from '../Basics/FilterField';
import { SingleNotification } from '../NotificationDisplay/NotificationDisplay';
import "./ChecksEditor.css"

const highlightWithLineNumbers = (input, language) =>
  highlight(input, language)
    .split("\n")
    .map((line, i) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
    .join("\n");

class ChangeRequestCheck extends Component 
{
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      editEntry: undefined,
      editName: undefined,
      log:"",

      checks: [], // user/project checks
      ownerChecks: [], // checks of the owner (project only)
      userChecks: [], // checks of the owner that have been activated (project only)

      project: "",

      commands: [],

      searchFilter:"",
      loading: false,
      lastRunSuccessful: undefined
    }

    this.references = {}
  }

  componentDidMount = async () => {

    this.setChecksState()


    let commands = await window.LR_GetAvailableCommands()
    this.setState({commands})
  }

  setChecksState = async () => {
    this.setState({loading: true})

    let checks = []
    let userChecks = []
    let ownerChecks = []
    let project = await window.LR_GetLinkedProject()

    // get checks
    if (project.Project !== "")
    {
      ownerChecks = await window.LR_GetProjectOwnerChecks()
      
      let checksObj = await window.LR_GetChecksFromProject()
      checks = checksObj.checks
      userChecks = checksObj.userChecks
    }
    else 
    {
      checks = await window.LR_GetChecksFromUser() 
    }

    // update refs
    this.references = {}
    Object.values(checks).forEach(check => {
      this.references[check._id] = React.createRef()
    })

    this.setState({ checks, userChecks, ownerChecks, project: project.Project, loading: false})
  }

  render() 
  {
    let code = this.state.editEntry ? this.state.editEntry.script : "";
    return <div>
      <Table selectable striped celled>
        <Dimmer active={this.state.loading}>
            <Loader active={this.state.loading}/>
        </Dimmer>
        <Table.Header>
          <Table.Row>
            <LRTableHeaderCell />
            <LRTableHeaderCell>{LocalizedStrings.Name}</LRTableHeaderCell>
            <LRTableHeaderCell>{LocalizedStrings.Edit}</LRTableHeaderCell>
          </Table.Row>
        </Table.Header>
        {this.state.checks.map(check => this.render_Row(check, false))}
        {this.state.ownerChecks.length > 0 && this.renderOwnerChecksTable()}
        <Table.Footer>
        <Table.HeaderCell />
          <Table.Row>
            <Table.HeaderCell colSpan='3'>
              <Button
              disabled={!this.state.project}
              floated='right'
              icon
              labelPosition='left'
              primary
              onClick = {this.addScriptToProject}
              size='small'><Icon name='box' />{LocalizedStrings.AddScriptToProject}</Button>
              <Button
              floated='right'
              icon
              labelPosition='left'
              primary
              onClick = {this.addScriptToUser}
              size='small'><Icon name='box' />{LocalizedStrings.AddScriptToUser}</Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>

      <LRModal  open={this.state.open} 
                size="fullscreen"
                title={LocalizedStrings.Code}
                onCancelClick={this.close}
                closeOnEscape={false}
                closeOnEnter={false}
                onOkClick={this.ok}
                additionalActions={
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center"}}>
                  <Button color="red" onClick={() => this.execute(this.state.editEntry)}><Icon name="play" />{LocalizedStrings.RunScript}</Button>
                  {this.state.lastRunSuccessful !== undefined ?
                    <div style={{ marginLeft: 5 }}>
                      <Icon name={this.state.lastRunSuccessful ? "check" : "times"} color={this.state.lastRunSuccessful ? "green" : "red"} />
                      {this.state.lastRunSuccessful ? LocalizedStrings.ScriptSuccess : LocalizedStrings.ScriptError}
                    </div>
                    : 
                    null
                    }
                </div>
                }
      >
                  <Grid>
                    <Grid.Row>
                    <Grid.Column width="12">
                      <Header>{LocalizedStrings.Editor}</Header>
                      <Editor
                        value={code}
                        onValueChange={(code) => this.setState({editEntry : { ...this.state.editEntry, script: code }})}
                        highlight={(code) => highlightWithLineNumbers(code, languages.js)}
                        className='ChecksEditor'
                        textareaId='codeArea'
                        padding={10}
                        style={{
                          fontFamily: '"Fira code", "Fira Mono", monospace',
                          fontSize: 12,
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column width="4">
                      <Header>{LocalizedStrings.Commands}</Header>
                      <div style={{marginTop: "1em", marginBottom:"1em"}}>
                      <LRFilterInput  value = {this.state.searchFilter} onChange={(value)=> {this.setState({searchFilter: value})}}/>
                      <List style={{overflowY:"scroll", height:"60em"}}>
                        {this.state.commands
                        .filter(e=> !this.state.searchFilter || e.toLowerCase().includes(this.state.searchFilter.toLowerCase()))
                        .map((c, i)=><List.Item key={i}>{c}</List.Item>
                        )}
                      </List>
                      </div>
                      <Button fluid positive onClick={()=>{window.LR_OpenLink({UseBaseUrl: true, URL: "/documentation/checks"})}}><Icon name="globe"/>{LocalizedStrings.OpenHelp}</Button>
                    </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Header>{LocalizedStrings.LogOutputs}</Header>
                        <Editor
                            value={this.state.log ? this.state.log : LocalizedStrings.NoLogYet}
                            highlight={(code) => highlight(code, languages.js)}
                            padding={10}
                            style={{
                              fontFamily: '"Fira code", "Fira Mono", monospace',
                              fontSize: 12,
                            }}
                          />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

      </LRModal>
    </div>
  }

  renderOwnerChecksTable = () => {
    return (<>  
              <Table.Row>
                <Table.Cell></Table.Cell>
                <Table.Cell>
                  <Header>Project Owner Checks</Header>
                </Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
              {this.state.ownerChecks.map(check => this.render_Row(check, true))}
            </>)
  }

  addScriptToProject = async () => {
    if (this.state.project !== "")
    {
      await window.LR_AddCheckToProject({ Name: "fancyCheckProject" })
      this.setChecksState()
    }
  }

  addScriptToUser = async () => {
    await window.LR_AddCheckToUser({ Name: "fancyCheckUser" })
    this.setChecksState()
  }

  execute = async (entry, showLog = false) =>
  {
    let res = await window.LR_JsEngineExecute({Script: entry.script})

    let log = ""
    if(res.Log)
    {
      res.Log.forEach((line, i) => {
        log += i;
        log += "\t"
        log += line;
        log += "\n"
        
      });
    }
    this.setState({log: log, lastRunSuccessful: res.OK})
    if(showLog){
      if(res.OK){
        (await SingleNotification.newNotification(LocalizedStrings.ScriptSuccessHeader, LocalizedStrings.ScriptSuccessText.replace("$1", entry.name), "positive")).show()
      }else{
        (await SingleNotification.newNotification(LocalizedStrings.ScriptErrorHeader, LocalizedStrings.ScriptErrorText.replace("$1", entry.name), "error")).show()
      }
    }
  }

  ok = async() => {
    let newScript = this.state.editEntry.script
    let checkId = this.state.editEntry._id
  
    await window.LR_EditScriptInCheck({Script: newScript, checkId: checkId})
    this.setChecksState()
    this.close()
  }

  open = (entry) =>
  {
    this.setState({open:true, editEntry: entry})
  }

  close = () =>
  {
    this.setState({open:false, editEntry: undefined, lastRunSuccessful: undefined})
  }
  

  remove = async(entry) => {
    this.setState({loading: true})
    if (this.state.project !== "")
    {
      await window.LR_RemoveCheckFromProject({ checkId: entry._id })
    }
    else 
    {
      await window.LR_RemoveCheckFromUser({ checkId: entry._id })
    }
    this.setChecksState()
  }

  render_Row(entry, isOwnerCheck) 
  {
    return(<React.Fragment key={entry._id}>
            <Table.Row key={entry._id}>
              <Table.Cell collapsing>
                {this.renderAction(entry, isOwnerCheck)}
              </Table.Cell>
              <Table.Cell>
                {this.state[entry._id + "editName"] ?
                  <Input value={this.state[entry._id + "editName"]} 
                         ref={this.references[entry._id]}
                         fluid 
                         onChange = {(e, {value}) => { this.changeCheckNameState(value, entry) }} />
                  : entry.name}
              </Table.Cell>
              <Table.Cell collapsing>
                {this.state[entry._id + "editName"] ?
                <>
                  <Button negative 
                          onClick={(e) => { this.cancelNameEdit(e, entry) }}>{LocalizedStrings.Abort}</Button> 
                  <Button positive 
                          onClick={() => { this.changeCheckNameServer(entry) }}>{LocalizedStrings.Confirm}</Button>
                </>
                :
                <>  
                  <Button color="red" onClick={()=>this.execute(entry, true)}><Icon name="play"/>{LocalizedStrings.RunScript}</Button>
                  <Button onClick={() => { this.open(entry) }}><Icon name='box' />{LocalizedStrings.EditContent}</Button>
                  <Button onClick={() => { this.editName(entry) }}><Icon name='edit' />{LocalizedStrings.EditName}</Button> 

                </>}
              </Table.Cell>
            </Table.Row>
          </React.Fragment>)
  }

  cancelNameEdit = (e, entry) => {
    e.stopPropagation()
    this.setState({[entry._id + "editName"]: undefined})
  }

  editName = (entry) => {
    this.setState({[entry._id + "editName"] : entry.name}, () => {
      if(this.references && this.references[entry._id])
      {
        this.references[entry._id].current.focus()
        this.references[entry._id].current.select()
      }
    })
  }

  renderAction = (entry, isOwnerCheck) => {
    let addable = true
    let found = this.state.userChecks.find(c => entry._id === c._id)
    if(isOwnerCheck && found)
    {
      addable = false
    }

    return (!isOwnerCheck ? <Icon name="remove" 
                                  onClick={() => {this.remove(entry)}}></Icon>
                          : <Checkbox checked={!addable} 
                                      onChange={(e, {checked}) => {this.toggleOwnerCheck(entry, checked)}}/>)
  }

  toggleOwnerCheck = async (entry, checked) => {
    this.setState({loading: true})
    await window.LR_ToggleProjectOwnerChecks({checkId: entry._id, checked})
    this.setChecksState()
  }

  changeCheckNameState = (value, entry) => {
    this.setState({[entry._id + "editName"]: value})
  }

  changeCheckNameServer = async(entry) => {
    this.setState({loading: true})
    let newName = this.state[entry._id + "editName"]
    await window.LR_EditCheckName({Name: newName, checkId: entry._id})

    await this.setChecksState()
    this.setState({[entry._id + "editName"]: undefined})
  }

  
}

const mapStateToProps = (state) => {
}


export default connect(mapStateToProps)(ChangeRequestCheck)