//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Grid, List, Header, Loader, Button, Icon, Message, Sticky, Divider, Segment} from 'semantic-ui-react';
import { Link } from "react-router-dom";
import Updates from './Updates'
import Calendar from '../Calendar/Calendar';
import { timeSince } from '../../util/time';
import LocalizedStrings from "../../localization/TimeStrings";
import { hasFetched, FetchCollaborators, FetchActiveUser, FetchMiniProjects} from './../../redux/actions/fetch_actions'
import { ACTIVE_USER, COLLABORATORS, MINI_PROJECTS } from "./../../redux/redux_defines"
import CreateNewProject from '../CreateNewProject/CreateNewProject';
import { globalCallbacks } from '../../util/callback';
import UserAvatar from '../WebComponents/UserAvatar'
import { lrServerConnection } from '../../redux/light_right_server_connection';
import LRModal from '../Basics/BasicModal';
import { getProjectName } from '../../util/defines';


const eSORT_BY = Object.freeze({
  "USER"    : (a, b) => { return a.modifiedBy.username.localeCompare(b.modifiedBy.username); },
  "PROJECT" : (a, b) => { return a.project.projectname.localeCompare(b.project.projectname); },
  "DATE"    : (a, b) => { return new Date(b.date) - new Date(a.date); }
})

class UserStartPage extends Component 
{
  constructor(props)
  {
      super(props);
      this.state =
      {
        sortFunc  : eSORT_BY.DATE,
        NewProjectOpen: false,
        CurrentlyCreatingNewProject: false,
        showCalendar: false,
        selectedFolder: "",
        selectedUserAcc: undefined
      };
      this.contextRef = createRef()
      this.createProjRef = createRef()
      this.updatedProjects = this.props
  }


  render () 
  {
    let { loggedInUser, projects } = this.props;
    if ( ! loggedInUser.data ) 
    { 
      return null; 
    }
    lrServerConnection.setUser(loggedInUser.data.username)

    window.document.title = `${"Production Assist"}`
    
    FetchMiniProjects();

    let renderPlaceHolder_projects = ! hasFetched(projects)

    let { sortFunc } = this.state;
    let sortedProject
    if( ! renderPlaceHolder_projects)
    {
      sortedProject= projects.data.sort((p1,p2) => new Date(p2.lastChanged)-new Date(p1.lastChanged))
    }

    return (
      <div>
        <Grid stackable>
          <Grid.Column width={6} >
            <div style={{height: '100%'}}ref={this.contextRef}>
            <Sticky offset={70} context={this.contextRef}>
            <Header>
              <UserAvatar/>
              <Link to={`/${loggedInUser.data.username}`}>{loggedInUser.data.name}</Link>
              <Button floated='right'  
                      onClick={() => this.setState({showCalendar: !this.state.showCalendar})}>
                      {this.state.showCalendar ? LocalizedStrings.ShowUpdatedProjects : LocalizedStrings.ShowCalendar}
              </Button>
            </Header>
            <Divider/>
            <Message>
              <Message.Header>
                {LocalizedStrings.ProjectHeader}
                <Button color='green' floated='right' size="mini" onClick={this.openNewProject}><Icon name="add"/>{LocalizedStrings.NewProject}</Button>
              </Message.Header>
              <Message.Content style={{marginTop: "15px"}}>
              <List divided relaxed>
                {renderPlaceHolder_projects ? 
                      [1,1,1,1].map((_, i) => this.renderProjectEntryPlaceholder(i)) 
                      : 
                      sortedProject.map((project, i) => { if(i > 15) { return null } else {return this.renderProjectEntry(project, i)}}) 
                }
              </List>
              </Message.Content>
            </Message>
            </Sticky>
            </div>
          </Grid.Column>
          <Grid.Column width={10}>
            {this.state.showCalendar ? <Calendar /> : <Updates sortFunc={sortFunc}/>}
          </Grid.Column>
        </Grid>
        {this.renderAddNewProject()}
      </div>

    );
  }

  

  renderProjectEntry(project)
  {
    let projectName = getProjectName(project)

      let lastChangedString = timeSince(new Date(project.lastChanged));
      return (
        <List.Item key={"project-" + project.name} >
          <List.Content>
            <List.Header> 
              <Link to={`/${project.owner.username}`}>{project.owner.name}</Link>
              /
              <Link to={`/${project.owner.username}/${project.projectname}`}>{projectName}</Link>
            </List.Header>
            <List.Description>{LocalizedStrings.formatString(LocalizedStrings.UpdatedBy, lastChangedString)}<Link to={"/" + project.owner.name}>{project.owner.name}</Link></List.Description>
          </List.Content>
        </List.Item>
      )
  }

  renderProjectEntryPlaceholder(i)
  {
    return <List.Item key={i}>
      <List.Content>
        <Segment basic>
          <Loader active/>
        </Segment>
      </List.Content>
    </List.Item>
  }

  openNewProject = () => this.setState({
    NewProjectOpen: true
  })

  closeNewProject = () => this.setState({
    NewProjectOpen: false
  })

  renderAddNewProject = () =>
  {
    FetchCollaborators()
    return (
      <LRModal  open={this.state.NewProjectOpen}
                title={LocalizedStrings.AddNewProject}
                onCancelClick={this.closeNewProject}
                okLoading={this.state.CurrentlyCreatingNewProject}
                onOkClick={this.onOk}
                okDisabled={this.state.projectNameExists || !this.state.selectedUserAcc}
                scrolling={false}>
        <CreateNewProject loggedInUser={this.props.loggedInUser.data.name} ref={this.createProjRef} selectedUserAcc={(user) => {this.setState({selectedUserAcc:user})}} doesProjectNameExist={(exists) => {this.setState({projectNameExists: exists})}}/>
      </LRModal>
    )
  }

  onOk = () => 
  {
    const data = this.createProjRef.current.getData()

    this.setState({CurrentlyCreatingNewProject: true})
    
    globalCallbacks.createProject(data).then(() => 
    {
      FetchMiniProjects(true)
      FetchActiveUser(true)
      this.setState({
        NewProjectOpen: false,
        CurrentlyCreatingNewProject: false,
        selectedFolder:data.selectedFolder,
      })
    })
  }
}

//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      loggedInUser: (state[ACTIVE_USER]),
      collaborators: state[COLLABORATORS].data,
      projects: (state[MINI_PROJECTS])
    };
}

export default connect(mapStateToProps)(UserStartPage)
