//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Form } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/CreateWorksheetModal";
import { globalCallbacks } from '../../util/callback';
import { COUNT_WORKSHEHET_VIEW_TYPE } from '../../util/defines';
import LRModal from '../Basics/BasicModal';


//-----------------------------------------------------------------------------
// The component for the TreeControl
class SelectResourceModal extends Component 
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            Name:"",
            worksheetNames               : [],
            linkedTimelinePhaseChange   : "",
            timelinePhaseChangesOptions: [],

            linkedPreset: "Default",
            linkedPresetOptions: [],

            createWorksheetOpen: false,

            fullPresets: [],
            AssingedUsers: []
        };
    }
    
    componentDidMount = () => 
    {
        this.setUpCallbacks();

    }

    render() 
    {
        const modalSize = "tiny";

        return (
            <LRModal    open={this.state.createWorksheetOpen}
                        size={modalSize}
                        title={LocalizedStrings.Header}
                        onCancelClick={this.close}
                        scrolling={false}
                        onOpen={() => { this.setState({worksheetNames: this.state.startworksheetNames})}}
                        onOkClick={() => this.confirm(this.state.worksheetNames, this.state.linkedTimelinePhaseChange, this.state.linkedPreset)}>
                <Form>
                    <Form.Input name        = "Name" 
                                fluid
                                label       = {LocalizedStrings.worksheetName} 
                                value       = {this.state.Name}
                                onChange    = {(e, {name, value}) => { this.setState({[name]: value}) }}/>

                    <Form.Field/>
                    <Form.Select    name        = "linkedPreset"
                                    fluid
                                    search
                                    selection
                                    value       = {this.state.linkedPreset}
                                    label       = {LocalizedStrings.linkedPreset}
                                    options     = {this.state.linkedPresetOptions}  
                                    onChange    = {this.onValueChanged}/>            
                    <Form.Select    name        = "linkedTimelinePhaseChange"
                                    fluid
                                    search
                                    selection
                                    value       = {this.state.linkedTimelinePhaseChange}
                                    label       = {LocalizedStrings.linkedTimelinePhaseChange}
                                    options     = {this.state.timelinePhaseChangesOptions}  
                                    onChange    = {this.onValueChanged}/>
                  <Form.Select    name        = "AssingedUsers"
                                    fluid
                                    search
                                    selection
                                    multiple
                                    value       = {this.state.AssingedUsers}
                                    label       = {LocalizedStrings.AssingedUser}
                                    options     = {this.state.members}  
                                    onChange    = {this.onValueChanged}/>
                </Form>
            </LRModal>
        )
    }

    onValueChanged = (event, {name, value}) =>
    {
        this.setState({[name]: value});
    }

    setUpCallbacks = () =>
    {
      globalCallbacks.GetUsersForWorksheets = async () => 
      {
        let users   = await window.LR_GetUsers()
        let members = users.Users.map((user) => 
        {
          return {
              text: <>{user.Name}</>,
              key: user.UUID,
              value: user.UUID,
          };
        });

        this.setState(members)
      }
      
        globalCallbacks.getTimelinePhasesToLink = async () => 
        {
            let ret = await window.LR_GetTimePhaseChanges();

            if(ret.Changes && Array.isArray(ret.Changes))
            {
              let timelinePhaseChanges = ret.Changes;
              timelinePhaseChanges.sort(function(a, b) {return a.Order - b.Order});
              timelinePhaseChanges.forEach((change, i) =>
              {
                  change.Order = i;
              });

              let timelinePhaseChangesOptions = timelinePhaseChanges.map(change =>
              {
                  return(
                  {
                      key   : change.UUID,
                      value : change.UUID,
                      text  : change.Name,
                  }
                  );
              });
              
              let defaultUuid = timelinePhaseChanges[0].UUID;
              this.setState({timelinePhaseChangesOptions, linkedTimelinePhaseChange: defaultUuid});
            }
            else
            {
              console.error("getTimelinePhasesToLink failed", ret)
            }

            
        }

        globalCallbacks.GetPresets = async () => {
          const propPresets = await window.LR_GetPropertyTemplateMap()

          const presetOptions = propPresets.ResourceMap.propertyTemplate.map(template => {return {
            key: template.Name,
            text: template.Name,
            value: template.Name
          }})

          this.setState({linkedPresetOptions: presetOptions, fullPresets: propPresets.ResourceMap.propertyTemplate})
        }

        // opens the modal, each entry in 'objArr' gets their own worksheet !
        globalCallbacks.createWorksheet = async (objArr) => {

          globalCallbacks.getTimelinePhasesToLink();
          globalCallbacks.GetPresets()
          globalCallbacks.GetUsersForWorksheets()

          if (objArr.length === 1)
          {
            this.setState({
              createWorksheetOpen: true,
              worksheetUuids : [objArr[0].UUID], 
              startworksheetNames: [objArr[0].Name],
            });
          }
          else
          {
            this.setState({
              createWorksheetOpen: true,
              worksheetUuids: objArr.map(obj => obj.UUID),
              startworksheetNames: objArr.map(obj => obj.Name)  ,
            });
          }
        };

        globalCallbacks.createWorksheetFromLayer = async (layerUuid) =>
        {
          globalCallbacks.getTimelinePhasesToLink();
          globalCallbacks.GetPresets()
          globalCallbacks.GetUsersForWorksheets()

          let layer = await window.LR_GetLayer({UUID: layerUuid})
          if (layer.UUID)
          {
            this.setState({createWorksheetOpen: true, worksheetUuids: undefined, worksheetLayerUuid: layerUuid, startworksheetNames: [layer.Name]})
          }
        }

        globalCallbacks.createWorksheetFromClass = async (classUuid) =>
        {
          globalCallbacks.getTimelinePhasesToLink();
          globalCallbacks.GetPresets()
          globalCallbacks.GetUsersForWorksheets()

          let clss = await window.LR_GetClass({UUID: classUuid})
          if (clss.UUID)
          {
            this.setState({createWorksheetOpen: true, worksheetUuids: undefined, worksheetLayerUuid: undefined, worksheetClassUuid: classUuid, startworksheetNames: [clss.Name]})
          }
        }
    }

  close = () =>
  {
    this.setState({createWorksheetOpen : false, linkedPreset: "Default",});
  }

  confirm = async(worksheetNames, linkedTimelinePhaseChangeUuid, linkedPreset) =>
  {
    this.close()

    // get the complete propertytemplate
    const propTemplate = this.state.fullPresets.find(preset => preset.Name === linkedPreset)

    let WorksheetState = {}
    for (let i = 0; i < COUNT_WORKSHEHET_VIEW_TYPE; i++) 
      {
        WorksheetState[i] = {
          ...propTemplate.WorksheetState,
          ActivePropertyPreset: linkedPreset
        };
      }
    
    // create the new worksheet(s)
    let request = {
      LinkedTimeLinePhaseChange: linkedTimelinePhaseChangeUuid,
      WorksheetState,
      DefaultAssingedUsers: this.state.AssingedUsers,
      AppendName: this.state.Name
    };

    if (worksheetNames)
    {
      if (this.state.worksheetUuids)
      {
        request.ObjectUUIDs = this.state.worksheetUuids
      }
      else if (this.state.worksheetLayerUuid)
      {
        request.LayerUUID = this.state.worksheetLayerUuid
      }
      else if (this.state.worksheetClassUuid)
      {
        request.ClassUUID = this.state.worksheetClassUuid
      }else{
        return
      }

      await window.LR_AddNewWorksheet(request)
    }

  }

  
}

export default SelectResourceModal