//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Segment, Form, Checkbox, Message } from 'semantic-ui-react'
// @ts-ignore
import LocalizedStrings from "../../localization/InstallVectorworks";
import LRModal from '../Basics/BasicModal';
import { globalCallbacks } from '../../util/callback';

declare const  window: any;

interface InstallPluginModalState {
  open: boolean,
  App_ShowInstallPlugins: boolean,
  Targets: any
}

//-----------------------------------------------------------------------------
// The component for the TreeControl
export class InstallPluginModal extends Component<{}, InstallPluginModalState> 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
      App_ShowInstallPlugins : true,
      Targets: []
    }
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }

  show = async(args) => 
  {
    let settings = await window.LR_GetGlobalSettings()
    this.setState( { 
        open : true, 
        Targets: args,
        App_ShowInstallPlugins: settings.GlobalSettings.App_ShowInstallPlugins
      });
  }

  ok = () =>
  {
    this.close( this.state.Targets.filter(e=>e.Selected))
  }

  close = (SelectedPackages = []) => 
  {
    window.LR_InstallLightRightPlugin({SelectedPackages: SelectedPackages})
    window.LR_SetGlobalSettings({App_ShowInstallPlugins: this.state.App_ShowInstallPlugins})
    this.setState({open : false});
  }
    
  render() 
  {
    let open = this.state.open;
    if(!open) {return null}

    return (
      <LRModal  open={open} 
                onCancelClick={this.close} 
                closeOnDimmerClick={false} 
                size="tiny"
                scrolling={false}
                title={LocalizedStrings.InstallPlugins}
                additionalActions={<Checkbox checked={this.state.App_ShowInstallPlugins} onChange={()=>{this.setState({App_ShowInstallPlugins: !this.state.App_ShowInstallPlugins})}} label={LocalizedStrings.HideNextStart}/>}
                onOkClick={this.ok}>
                  <Message>
                    <Message.Header>{LocalizedStrings.Header}</Message.Header>
                    {LocalizedStrings.Line1}
                  </Message>
            <Segment>
            <Form>

              {this.state.Targets.map(e=>{
                return<>
                  <Form.Field>
                    <Checkbox 
                    key={e.Name}
                    label={e.Name} 
                    value={e.Selected} 
                    disabled={!e.ApplicationInstalled}
                    onChange={()=>
                    {
                      this.setState({Targets: this.state.Targets.map(i=>
                        {
                          return {
                            ...i,
                            Selected: i.LocalPathToPA_Name === e.LocalPathToPA_Name ? !e.Selected : i.Selected
                          }
                        })})
                    }}/>
                    {
                      e.PluginInstalled ? <i style={{color: 'LightGreen', marginLeft: 10}}>{LocalizedStrings.Installed}</i> : null
                    }
                  </Form.Field>

                </>
              })}
              </Form>
          </Segment>
      </LRModal>
    )
  }

  setUpCallbacks()
  {

    globalCallbacks.ShowInstallPluginModal = (args) => 
    { 
      this.show(args);
    }
    
  }
}

export default InstallPluginModal