//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component, } from 'react';
import {  Segment, Header, Icon, Card } from 'semantic-ui-react';
import TaskEntry from './TaskEntry'
import LocalizedStrings from "../../localization/UserTaskPage";


class TaskEntryContainer extends Component 
{
  render() 
  {
    return (
      <div className="TaskEntry">
        <Card.Group>
          {this.props.worksheets?.map(wsObj => <TaskEntry users={this.props.users} key={wsObj.Worksheet.UUID} wsObj={wsObj} />)}
        </Card.Group>
        {this.props.worksheets.length === 0 ?
          <Segment placeholder>
            <Header icon>
              <Icon name='search' />
              {LocalizedStrings.NoTaskWithCriteria}
            </Header></Segment> : null}
      </div>
    );
  }
}


export default TaskEntryContainer
