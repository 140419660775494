//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Card, Image, Segment, Grid } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/AboutDialog";
import SW_LocalizedStrings from "../../localization/StructuralWizard";

import IMG_1 from '../../content/img_wizard/1.png'
import IMG_2 from '../../content/img_wizard/2.png'
import IMG_3 from '../../content/img_wizard/3.png'
import IMG_4 from '../../content/img_wizard/4.png'
import IMG_5 from '../../content/img_wizard/5.png'
import IMG_6 from '../../content/img_wizard/6.png'
import IMG_7 from '../../content/img_wizard/7.png'
import IMG_8 from '../../content/img_wizard/8.png'
import IMG_9 from '../../content/img_wizard/9.png'
import IMG_10 from '../../content/img_wizard/10.png'
import IMG_11 from '../../content/img_wizard/11.png'
import IMG_12 from '../../content/img_wizard/12.png'
import IMG_13 from '../../content/img_wizard/13.png'
import IMG_14 from '../../content/img_wizard/14.png'
import IMG_15 from '../../content/img_wizard/15.png'
import IMG_16 from '../../content/img_wizard/16.png'
import IMG_17 from '../../content/img_wizard/17.png'
import IMG_18 from '../../content/img_wizard/18.png'
import IMG_19 from '../../content/img_wizard/19.png'
import IMG_20 from '../../content/img_wizard/20.png'
import IMG_21 from '../../content/img_wizard/21.png'
import IMG_22 from '../../content/img_wizard/22.png'
import IMG_23 from '../../content/img_wizard/23.png'
import IMG_24 from '../../content/img_wizard/24.png'
import IMG_25 from '../../content/img_wizard/25.png'
import IMG_26 from '../../content/img_wizard/26.png'
import IMG_27 from '../../content/img_wizard/27.png'
import IMG_28 from '../../content/img_wizard/28.png'
import IMG_29 from '../../content/img_wizard/29.png'
import IMG_30 from '../../content/img_wizard/30.png'
import IMG_31 from '../../content/img_wizard/31.png'
import IMG_32 from '../../content/img_wizard/32.png'
import IMG_33 from '../../content/img_wizard/33.png'
import LRModal from '../Basics/BasicModal';
import { globalCallbacks } from "../../util/callback";
import RequestStructuralCheck from '@component/RequestStructuralCheck/RequestStructuralCheck';


//-----------------------------------------------------------------------------
// The component for the TreeControl
class StructuralWizard extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
      selectedWindLoad: 'Outdoor',
      selectedAreaLoad: 'ScaffoldingLoadclass1',
      selectedBallast: 'StructureConnected',
      selectedFloorLoad: 'Indoor5',
      selectedLocation: 'Germany2'
    }
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }

  show = async() => 
  {
    this.setState({open : true});
  }

  close = () => 
  {
    this.setState({open : false});
  }

  okClick = () => 
  {
    window.LR_RunStructuralWizard({
      SelectedWindLoad: this.state.selectedWindLoad,
      SelectedAreaLoad: this.state.selectedAreaLoad,
      SelectedBallast: this.state.selectedBallast,
      SelectedFloorLoad: this.state.selectedFloorLoad,
      SelectedLocation: this.state.selectedLocation,
    });
    this.setState({open : false});
  }
    
  render() 
  {
    let open = this.state.open;
    if(!open) {return <div/>}
    return (
      <LRModal  open={open} 
                onCancelClick={this.close}
                closeOnDimmerClick={false} 
                size="fullscreen"
                scrolling={false}
                title={LocalizedStrings.Header}
                onOkClick={this.okClick}>
          <Grid columns={2} style={{height: 'calc(100vh - 12rem)'}}>
          <Grid.Row>
          <Grid.Column width={10} style={{height: 'calc(100vh - 15rem)', overflowY: 'auto', paddingRight: '1rem'}}>
          <h1>{SW_LocalizedStrings.WindLoadsHeader}</h1>
          <p style={{ marginBottom: '1rem' }}>{SW_LocalizedStrings.WindLoadsInfoDesc}</p>
          <Segment placeholder>
            <Card.Group>
            {this.renderCard("windLoads", "Outdoor", SW_LocalizedStrings.Outdoor, IMG_1, SW_LocalizedStrings.Outdoor_Desc, SW_LocalizedStrings.Outdoor_AccordingTo)}
            {this.renderCard("windLoads", "EnUse", SW_LocalizedStrings.EnUse, IMG_2, SW_LocalizedStrings.EnUse_Desc, SW_LocalizedStrings.EnUse_AccordingTo)}
            {this.renderCard("windLoads", "EnNoUse", SW_LocalizedStrings.EnNoUse, IMG_3, SW_LocalizedStrings.EnNoUse_Desc, SW_LocalizedStrings.EnNoUse_AccordingTo)}
            {this.renderCard("windLoads", "Tradeshow", SW_LocalizedStrings.Tradeshow, IMG_4, SW_LocalizedStrings.Tradeshow_Desc, SW_LocalizedStrings.Tradeshow_AccordingTo)}
            {this.renderCard("windLoads", "Indoor", SW_LocalizedStrings.Indoor, IMG_5, SW_LocalizedStrings.Indoor_Desc, SW_LocalizedStrings.Indoor_AccordingTo)}
            </Card.Group>
            {
              this.state.selectedWindLoad === "Outdoor" ? <>

              <h1>{SW_LocalizedStrings.WindLoadsLocation}</h1>
                <Card.Group>
                {this.renderCard("windLoadLoaction", "Germany1", SW_LocalizedStrings.Germany1, IMG_23, SW_LocalizedStrings.Germany1_Desc)}
                {this.renderCard("windLoadLoaction", "Germany2", SW_LocalizedStrings.Germany2, IMG_24, SW_LocalizedStrings.Germany2_Desc)}
                {this.renderCard("windLoadLoaction", "Germany3", SW_LocalizedStrings.Germany3, IMG_25, SW_LocalizedStrings.Germany3_Desc)}
                {this.renderCard("windLoadLoaction", "Germany4", SW_LocalizedStrings.Germany4, IMG_26, SW_LocalizedStrings.Germany4_Desc)}
                {this.renderCard("windLoadLoaction", "Netherlands1", SW_LocalizedStrings.Netherlands1, IMG_31, SW_LocalizedStrings.Netherlands1_Desc)}
                {this.renderCard("windLoadLoaction", "Netherlands2", SW_LocalizedStrings.Netherlands2, IMG_32, SW_LocalizedStrings.Netherlands2_Desc)}
                {this.renderCard("windLoadLoaction", "Netherlands3", SW_LocalizedStrings.Netherlands3, IMG_33, SW_LocalizedStrings.Netherlands3_Desc)}
                {this.renderCard("windLoadLoaction", "France1", SW_LocalizedStrings.France1, IMG_30, SW_LocalizedStrings.France1_Desc)}
                {this.renderCard("windLoadLoaction", "France2", SW_LocalizedStrings.France2, IMG_27, SW_LocalizedStrings.France2_Desc)}
                {this.renderCard("windLoadLoaction", "France3", SW_LocalizedStrings.France3, IMG_29, SW_LocalizedStrings.France3_Desc)}
                {this.renderCard("windLoadLoaction", "France4", SW_LocalizedStrings.France4, IMG_28, SW_LocalizedStrings.France4_Desc)}
              </Card.Group>
              </> : null}
          </Segment>
          <h1>{SW_LocalizedStrings.AreaLoadsHeader}</h1>
          <p style={{ marginBottom: '1rem' }}>{SW_LocalizedStrings.AreaLoadsInfoDesc}</p>
          <Segment placeholder>
            <Card.Group>
            {this.renderCard("areaLoads", "ScaffoldingLoadclass1", SW_LocalizedStrings.ScaffoldingLoadclass1, IMG_6, SW_LocalizedStrings.ScaffoldingLoadclass1_Desc, SW_LocalizedStrings.ScaffoldingLoadclass1_AccordingTo)}
            {this.renderCard("areaLoads", "ScaffoldingLoadclass2", SW_LocalizedStrings.ScaffoldingLoadclass2, IMG_7, SW_LocalizedStrings.ScaffoldingLoadclass2_Desc, SW_LocalizedStrings.ScaffoldingLoadclass2_AccordingTo)}
            {this.renderCard("areaLoads", "ScaffoldingLoadclass3", SW_LocalizedStrings.ScaffoldingLoadclass3, IMG_8, SW_LocalizedStrings.ScaffoldingLoadclass3_Desc, SW_LocalizedStrings.ScaffoldingLoadclass3_AccordingTo)}
            {this.renderCard("areaLoads", "ScaffoldingLoadclass4", SW_LocalizedStrings.ScaffoldingLoadclass4, IMG_9, SW_LocalizedStrings.ScaffoldingLoadclass4_Desc, SW_LocalizedStrings.ScaffoldingLoadclass4_AccordingTo)}
            {this.renderCard("areaLoads", "ScaffoldingLoadclass5", SW_LocalizedStrings.ScaffoldingLoadclass5, IMG_10, SW_LocalizedStrings.ScaffoldingLoadclass5_Desc, SW_LocalizedStrings.ScaffoldingLoadclass5_AccordingTo)}
            {this.renderCard("areaLoads", "ScaffoldingLoadclass6", SW_LocalizedStrings.ScaffoldingLoadclass6, IMG_11, SW_LocalizedStrings.ScaffoldingLoadclass6_Desc, SW_LocalizedStrings.ScaffoldingLoadclass6_AccordingTo)}

            {this.renderCard("areaLoads", "EN13814_3_5", SW_LocalizedStrings.EN13814_3_5, IMG_12, SW_LocalizedStrings.EN13814_3_5_Desc, SW_LocalizedStrings.EN13814_3_5_AccordingTo)}
            {this.renderCard("areaLoads", "EN13814_5_0", SW_LocalizedStrings.EN13814_5_0, IMG_13, SW_LocalizedStrings.EN13814_5_0_Desc, SW_LocalizedStrings.EN13814_5_0_AccordingTo)}
            {this.renderCard("areaLoads", "EN13814_2_0", SW_LocalizedStrings.EN13814_2_0, IMG_14, SW_LocalizedStrings.EN13814_2_0_Desc, SW_LocalizedStrings.EN13814_2_0_AccordingTo)}
            {this.renderCard("areaLoads", "EN13814_1_5", SW_LocalizedStrings.EN13814_1_5, IMG_15, SW_LocalizedStrings.EN13814_1_5_Desc, SW_LocalizedStrings.EN13814_1_5_AccordingTo)}
            </Card.Group>
          </Segment>
          <h1>{SW_LocalizedStrings.BallastHeader}</h1>
          <p style={{ marginBottom: '1rem' }}>{SW_LocalizedStrings.BallastInfoDesc}</p>

          <Segment placeholder>
            <Card.Group>
            {this.renderCard("ballast", "StructureConnected", SW_LocalizedStrings.StructureConnected, IMG_16, SW_LocalizedStrings.StructureConnected_Desc, SW_LocalizedStrings.StructureConnected_AccordingTo)}
            {this.renderCard("ballast", "StructureFreeStanding", SW_LocalizedStrings.StructureFreeStanding, IMG_17, SW_LocalizedStrings.StructureFreeStanding_Desc, SW_LocalizedStrings.StructureFreeStanding_AccordingTo)}
            {this.renderCard("ballast", "StructureFreeFrictionImprovedStanding", SW_LocalizedStrings.StructureFreeFrictionImprovedStanding, IMG_22, SW_LocalizedStrings.StructureFreeFrictionImprovedStanding_Desc, SW_LocalizedStrings.StructureFreeFrictionImprovedStanding_AccordingTo)}
            </Card.Group>
          </Segment>
          <h1>{SW_LocalizedStrings.FloorLoadsHeader}</h1>
          <p style={{ marginBottom: '1rem' }}>{SW_LocalizedStrings.FloorLoadsInfoDesc}</p>
          
          <Segment placeholder>
            <Card.Group>
            {this.renderCard("floorLoads", "Outdoor13814_100", SW_LocalizedStrings.Outdoor13814_100, IMG_18, SW_LocalizedStrings.Outdoor13814_100_Desc, SW_LocalizedStrings.Outdoor13814_100_AccordingTo)}
            {this.renderCard("floorLoads", "Outdoor13814_150", SW_LocalizedStrings.Outdoor13814_150, IMG_19, SW_LocalizedStrings.Outdoor13814_150_Desc, SW_LocalizedStrings.Outdoor13814_150_AccordingTo)}
            {this.renderCard("floorLoads", "Outdoor13814_200", SW_LocalizedStrings.Outdoor13814_200, IMG_20, SW_LocalizedStrings.Outdoor13814_200_Desc, SW_LocalizedStrings.Outdoor13814_200_AccordingTo)}
            {this.renderCard("floorLoads", "Indoor5", SW_LocalizedStrings.Indoor5, IMG_21, SW_LocalizedStrings.Indoor5_Desc, SW_LocalizedStrings.Indoor5_AccordingTo)}
            </Card.Group>
          </Segment>
          </Grid.Column>
          <Grid.Column width={6} style={{height: 'calc(100vh - 15rem)', overflowY: 'auto'}}>
              <RequestStructuralCheck noModal={true}/>
            </Grid.Column>
          </Grid.Row>
          </Grid>
      </LRModal>
    )
  }

  renderCard(category, key, name, image, cardDescription, accordingTo)
  {
    let isSelected = false;
    const { selectedWindLoad, selectedAreaLoad, selectedBallast, selectedFloorLoad, selectedLocation } = this.state;

    switch(category) {
      case 'windLoads':
        isSelected = selectedWindLoad === key;
        break;
      case 'areaLoads':
        isSelected = selectedAreaLoad === key;
        break;
      case 'ballast':
        isSelected = selectedBallast === key;
        break;
      case 'floorLoads':
        isSelected = selectedFloorLoad === key;
        break;
      case 'windLoadLoaction':
        isSelected = selectedLocation === key;
        break;
    }

    const cardStyle = isSelected ? {
      boxShadow: '0 0 0 3px #2185d0 inset',  
      transform: 'translateY(-3px)',         
      transition: 'all 0.3s ease'
    } : {};

    return (
    <Card 
      onClick={() => this.handleCardSelect(category, key)}
      style={cardStyle}>
      <Image src={image} wrapped />
      <Card.Content>
        <Card.Header>{name}</Card.Header>
        <Card.Meta>
          <span className='date'>{accordingTo}</span>
        </Card.Meta>
        <Card.Description>
          {cardDescription}
        </Card.Description>
      </Card.Content>
    </Card>)
  }

  handleCardSelect = (category, cardKey) => {
    switch(category) {
      case 'windLoads':
        this.setState({ selectedWindLoad: cardKey });
        break;
      case 'areaLoads':
        this.setState({ selectedAreaLoad: cardKey });
        break;
      case 'ballast':
        this.setState({ selectedBallast: cardKey });
        break;
      case 'floorLoads':
        this.setState({ selectedFloorLoad: cardKey });
        break;
      case 'windLoadLoaction':
        this.setState({ selectedLocation: cardKey });
        break;
    }
  }

  setUpCallbacks()
  {

    globalCallbacks.ShowStructuralWizard = () => 
    { 
      this.show();
    }
    
  }
}

export default StructuralWizard