import React, { Component } from "react";
import { Table, Header, Icon, Checkbox } from "semantic-ui-react";
import { lrServerConnection } from "../../redux/light_right_server_connection";
import { BASE_UNIT_DATE } from "../../util/defines";
import UnitInput from "../Basics/BasicUnitInput";

import {  ACTIVE_ADMIN} from "../../redux/redux_defines"
import { connect } from 'react-redux'
import { FetchActiveAdmin} from '../../redux/actions/fetch_actions'
class PaymentHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentHistory: [],
      download: true,
    };
  }

  componentDidMount = async () => {
    let paymentHistory = await lrServerConnection.getPaymentHistory();
    this.setState({ paymentHistory });
  };

  componentDidUpdate = async (prevProps, prevState) => {

    if(prevProps.activeAdmin?.data !== this.props.activeAdmin?.data)
    {
      let paymentHistory = await lrServerConnection.getPaymentHistory();
      this.setState({ paymentHistory });
    }
  };

  render() {
    FetchActiveAdmin()
    return (
      <>
        <Header as="h3">Payment History</Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Amount Remaining</Table.HeaderCell>
              <Table.HeaderCell>Amount Due</Table.HeaderCell>
              <Table.HeaderCell>Amount Received</Table.HeaderCell>
              <Table.HeaderCell>Subscription Period</Table.HeaderCell>
              <Table.HeaderCell>
                {this.state.download ? "Download " : "View "}
                Invoice
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.paymentHistory.map((p) => this.renderRow(p))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="5" />
              <Table.HeaderCell>
                <Checkbox
                  label={"Download or open in new tab"}
                  toggle
                  checked={this.state.download}
                  onChange={(e, { checked }) => {
                    this.setState({ download: checked });
                  }}
                ></Checkbox>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </>
    );
  }

  renderRow = (payment) => {
    return (
      <Table.Row>
        <Table.Cell>
          <UnitInput
            value={new Date(payment.date * 1000).toISOString()}
            baseUnit={BASE_UNIT_DATE}
            dateOnly
            readOnly
          />
        </Table.Cell>
        <Table.Cell positive={payment.amount.remaining === 0}>
          {payment.amount.remaining / 100 + "€"}
        </Table.Cell>
        <Table.Cell>{payment.amount.due / 100 + "€"}</Table.Cell>
        <Table.Cell>{payment.amount.paid / 100 + "€"}</Table.Cell>
        <Table.Cell>
          <UnitInput
            value={new Date(payment.period.start * 1000).toISOString()}
            baseUnit={BASE_UNIT_DATE}
            dateOnly
            readOnly
          />
          {" To "}
          <UnitInput
            value={new Date(payment.period.end * 1000).toISOString()}
            baseUnit={BASE_UNIT_DATE}
            dateOnly
            readOnly
          />
        </Table.Cell>
        <Table.Cell>
          {this.state.download ? (
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={payment.download}
            >
              <Icon name="download" link />
            </a>
          ) : (
            <a rel="noopener noreferrer" target="_blank" href={payment.url}>
              <Icon name="share" link />
            </a>
          )}
        </Table.Cell>
      </Table.Row>
    );
  };
}


const mapStateToProps = (state) => ({
  activeAdmin   : state[ACTIVE_ADMIN]
})

export default connect(mapStateToProps)(PaymentHistory)