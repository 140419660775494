//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import LRModal from '../Basics/BasicModal';
import { BASE_UNIT_NUMBER } from '../../util/defines';
import { globalCallbacks } from '../../util/callback';
import { Table } from 'semantic-ui-react';
import LocalizedStrings from '../../localization/NumberingFixture';
import UnitInput from '../Basics/BasicUnitInput';

class NumberingFixtureModal extends Component
{
    constructor(props)
    {
        super(props)

        this.state = {
            open: false,
            fixtureTypes: [],
            parentUuid: undefined,
        }
    }

    componentDidMount()
    {
        this.setUpCallbacks();
    }

    render()
    {
        return <LRModal     open={this.state.open}
                            onOkClick={this.onOkClick}
                            onCancelClick={this.close}>
            <Table celled>
                <Table.Header>
                    <Table.HeaderCell>
                        {LocalizedStrings.Name}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        {LocalizedStrings.Count}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        {LocalizedStrings.StartId}
                    </Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                    {this.state.fixtureTypes.map((fixture, index) => {
                            return this.renderFixtureRow(fixture, index);
                        })}
                </Table.Body>
            </Table>
        </LRModal>
    }

    renderFixtureRow(fixture)
    {
        return  <Table.Row key={fixture.UUID}>
                    <Table.Cell>
                        {fixture.Name}
                    </Table.Cell>
                    <Table.Cell>
                        {fixture.Count}
                    </Table.Cell>
                    <Table.Cell>
                        <UnitInput  
                                transparent 
                                fluid 
                                value        ={fixture.StartingId}
                                baseUnit     ={BASE_UNIT_NUMBER} 
                                onStateUpdate={(name, value) => {fixture.StartingId = value; this.setState({fixtureTypes: this.state.fixtureTypes})}}/>
                    </Table.Cell>
                </Table.Row>
    }

    open = () =>
    {
        this.setState({open: true})
        this.loadCoreState()
    }

    close = () =>
    {
        this.setState({open: false})
    }

    onOkClick = () =>
    {
        // First set the new starting id for the fixtures
        window.LR_SetFixtureTypes({FixtureTypes: this.state.fixtureTypes.map(ftype => ({UUID: ftype.UUID, StartingId: ftype.StartingId}))}).then(_ => {
            window.LR_NumberAllFixtures({UUID: this.state.parentUuid});
            this.close()
        })
    }

    loadCoreState = async (args) =>
    {
        let parentUuid = args ? args.UUID : undefined
        let fixtureTypes = await window.LR_GetFixtureTypesFromObject({UUID: parentUuid});

        this.setState({fixtureTypes: fixtureTypes.FixtureTypes, parentUuid: parentUuid})
    }

    setUpCallbacks()
    {
        globalCallbacks.showFixtureNumbering = (args) =>
        {
            this.loadCoreState(args)
            this.open()
        }
    }
}

export default NumberingFixtureModal;