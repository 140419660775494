//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component, } from 'react';
import {  Grid, List, Container, Segment, Icon, IconGroup } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import LocalizedStrings from "../../localization/WebSiteHeader";
import { connect } from 'react-redux';
import { ACTIVE_USER } from '../../redux/redux_defines'
import "./newfooter.css"




class NewFooter extends Component 
{
  constructor(props) {
    super(props);
    this.state = {
      isAboutListVisible: false,
      isDownloadListVisible: false,
      isFaqListVisible: false
    };
  }

  toggleAboutList = () => {
    this.setState(prevState => ({
      isAboutListVisible: !prevState.isAboutListVisible
    }));
  }

  toggleDownloadList = () => {
    this.setState(prevState => ({
      isDownloadListVisible: !prevState.isDownloadListVisible
    }));
  }

  toggleFaqList = () => {
    this.setState(prevState => ({
      isFaqListVisible: !prevState.isFaqListVisible
    }));
  }

  render() 
  {    
    let isBeta = this.props.loggedInUser.data && this.props.loggedInUser.data.isBeta
    console.log("this.props.loggedInUser", isBeta)
    return (
    <>
    <div className='newfooter f-desktop'>
      <Segment    style={{ padding: '5em 0em'}}>
        <Container  textAlign='center'>
          <Grid stackable>
            <Grid.Column width={4} style={{textAlign:"left"}} >
                <List link>
                    <List.Item as={Link} to="/about">Production Assist</List.Item>
                    <List.Item as={Link} to="https://www.deersoft.de">© DeerSoft GmbH {new Date().getFullYear()}</List.Item>
                    <IconGroup style={{marginTop: "10px"}}>
                        <List.Item as={"a"} href="https://www.instagram.com/production_assist/" target="_blank" rel="noreferrer"><Icon style={{color: "white", marginRight:"10px"}}size='big' name="instagram"/></List.Item>
                        <List.Item as={"a"} href="https://www.facebook.com/groups/334512312106347/" target="_blank" rel="noreferrer"><Icon  style={{color: "white"}} size='big' name="facebook square"/></List.Item>
                    </IconGroup>
                </List>
            </Grid.Column>
            <Grid.Column width={4} style={{textAlign:"left"}} >
              <List link>
                <List.Item as={Link} to="/about">Production Assist</List.Item>
                <List.Item as={Link} to="/aboutus">{LocalizedStrings.AboutUs}</List.Item>
                <List.Item as={Link} to="/agb">{LocalizedStrings.AGB}</List.Item>
                <List.Item as={Link} to="/impressum">{LocalizedStrings.Impressum}</List.Item>
                <List.Item as={Link} to="/impressum#data">{LocalizedStrings.DataProtection}</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={4} style={{textAlign:"left"}} >
              <List link>
              <List.Item as={Link} to="/structuralquote">{LocalizedStrings.StructuralQuote}</List.Item>

              <List.Item as={Link} to="/download">{LocalizedStrings.Downloads}</List.Item>
                <List.Item as={Link} to="/admin">{LocalizedStrings.OnlineContent}</List.Item>
                <List.Item as={Link} to="/support">{LocalizedStrings.Support}</List.Item>
                <List.Item as={Link} to="/static">{LocalizedStrings.StaticCalculation}</List.Item>
                <List.Item as={Link} to="/collaboration">{LocalizedStrings.Collaboration}</List.Item>
                {isBeta ? <List.Item as={Link} to="/acceptance-test">{LocalizedStrings.AcceptanceTests}</List.Item> : null}
              </List>
            </Grid.Column>
            <Grid.Column width={4} style={{textAlign:"left"}}>
              <List link>
                <List.Item as={Link} to="/faq">{LocalizedStrings.FAQ}</List.Item>
                <List.Item as={Link} to="/video-tutorial">{LocalizedStrings.Tutorial}</List.Item>
                <List.Item as={Link} to="/documentation">{LocalizedStrings.Documentation}</List.Item>
                <List.Item as={Link} to="/knowhow">{LocalizedStrings.KnowHow}</List.Item>
              </List>
            </Grid.Column>
          </Grid>
        </Container>
      </Segment>
    </div>
     {this.mobileRender()}
    </>
    );
  }

  mobileRender = () =>
  {
    return(
      <div className='newfooter f-mobile'>
        <ul className="footer-list">
          <li className="footer-item">
            <div className='top-list'>
              <Link to="/about" className="footer-link">Production Assist</Link>  
              <PlusSymbol isVisible={this.state.isAboutListVisible} onClick={this.toggleAboutList} />
            </div>
           <div className={`hidden-list ${this.state.isAboutListVisible ? 'visible' : ''}`}>
              <Link to="/aboutus" className="footer-link">{LocalizedStrings.AboutUs}</Link>
              <Link to="/agb" className="footer-link">{LocalizedStrings.AGB}</Link>
              <Link to="/impressum" className="footer-link">{LocalizedStrings.Impressum}</Link>
              <Link to="/impressum#data" className="footer-link">{LocalizedStrings.DataProtection}</Link>
            </div>
          </li>
          <div className='mobile-divider'></div>
          <li className="footer-item">
            <div className='top-list'>
              <Link to="/download" className="footer-link">{LocalizedStrings.Downloads}</Link>
              <PlusSymbol isVisible={this.state.isDownloadListVisible} onClick={this.toggleDownloadList} />
            </div>
            <div className={`hidden-list ${this.state.isDownloadListVisible ? 'visible' : ''}`}>
              <Link to="/admin">{LocalizedStrings.OnlineContent}</Link>
              <Link to="/support">{LocalizedStrings.Support}</Link>
              <Link to="/static">{LocalizedStrings.StaticCalculation}</Link>
              <Link to="/collaboration">{LocalizedStrings.Collaboration}</Link>
            </div>
          </li>
          <div className='mobile-divider'></div>
          <li className="footer-item">
            <div className='top-list'>
              <Link to="/faq" className="footer-link">FAQ</Link>
              <PlusSymbol isVisible={this.state.isFaqListVisible} onClick={this.toggleFaqList} />
            </div>
            <div className={`hidden-list ${this.state.isFaqListVisible ? 'visible' : ''}`}>
              <Link as={Link} to="/video-tutorial">{LocalizedStrings.Tutorial}</Link>
              <Link as={Link} to="/documentation">{LocalizedStrings.Documentation}</Link>
              <Link as={Link} to="/knowhow">{LocalizedStrings.KnowHow}</Link>
            </div>
          </li>
          <div className='mobile-divider'></div>
          <li className="footer-item">Production Assist</li>
          <li className="footer-item">&copy; DeerSoft GmbH {new Date().getFullYear()}</li>
    
          <IconGroup style={{marginTop: "10px"}}>
            <List.Item as={"a"} href="https://www.instagram.com/production_assist/" target="_blank" rel="noreferrer"><Icon style={{color: "white", marginRight:"10px"}}size='big' name="instagram"/></List.Item>
            <List.Item as={"a"} href="https://www.facebook.com/groups/334512312106347/" target="_blank" rel="noreferrer"><Icon  style={{color: "white"}} size='big' name="facebook square"/></List.Item>
          </IconGroup>
        </ul>
    </div>
    )
  }
}

//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      loggedInUser: (state[ACTIVE_USER]),
    };
}

function PlusSymbol(props) {
  const { isVisible, onClick } = props;
  return <span className={`plus ${isVisible ? 'rotate' : ''}`} onClick={onClick}>+</span>
}

export default connect(mapStateToProps)(NewFooter)


