import React, { Component, } from 'react';
import escapeRegExp from "lodash/escapeRegExp"
import filter from "lodash/filter"
import {Dropdown, Checkbox, Form, Input, Container, Icon} from'semantic-ui-react';

class BasicSelect extends Component {
    constructor(props)
    {
        super(props)

        this.state={
            open     : false,

            // search
            isLoading       : false,
            results         : [],
            searchQuery     : "",
        }
    }

    render()
    {
        let showData = this.state.searchQuery.length === 0 ? this.props.options : this.state.isLoading ? this.props.options : this.state.results
        
        // dropdown selection with checkmarks
        if (this.props.filter)
        {
            return (
                <Dropdown   name            = {this.props.name ?? undefined}
                            text            = {this.props.text ?? undefined}
                            button
                            size            = {this.props.size ?? undefined}
                            fluid           = {this.props.fluid ?? true}
                            placeholder     = {this.props.placeholder ?? undefined}
                            search          = {this.props.search ?? false}
                            onSearchChange  = {(e, {searchQuery}) => {this.onSearchChange(searchQuery, this.props.options)}}
                            searchQuery     = {this.state.searchQuery}
                        >
                      <Dropdown.Menu>
                        {showData && showData.map(((entry, i) => {

                            let option = this.props.getOption ? this.props.getOption(entry) : entry.text
                           
                            if(entry.isHeader){
                                return <Dropdown.Header key = {`${entry}_${i}`} content={option}/>
                            }

                            return (<Dropdown.Item  
                                        key = {`${entry}_${i}`}
                                        onClick = {(e) => {this.checkboxOnChange(e, entry)}}
                                    >
                                        <div>
                                            {entry.selected && <Icon name="check"/>} 
                                            {option}
                                        </div>
                                    </Dropdown.Item>);
                        }))}
                      </Dropdown.Menu>       
                </Dropdown> 
            )
        }

        // similar to filter but is always open, they probably can be combined
        else if (this.props.alwaysOpen)
        {
            return( <React.Fragment>
                        <h5>{this.props.label}</h5>
                        <Input  fluid 
                                value       = {this.state.searchQuery} 
                                onChange    = {(e, {value}) => {this.onSearchChange(value, this.props.options)}}
                                icon        = 'search' 
                                placeholder = {this.props.placeholder}/>
                        <Container style={{overflow: 'auto', maxHeight: 220, marginTop:"1em" }}>
                            {showData.map((entry) => {  let option = this.props.getOption ? this.props.getOption(entry) : entry.text
                                                        
                                                        return  <Checkbox   style   = {{display: "block", marginBottom: "14px"}}
                                                                            key     = {entry.key}
                                                                            checked = {entry.selected} 
                                                                            onClick = {(e) => {this.checkboxOnChange(e, entry)}}
                                                                            label   = {option}>
                                                                </Checkbox>})}
                        </Container>
                        {this.props.children}
                    </React.Fragment>)
        }
        // for normal dropdown

        else if (this.props.normalDropdown)
        {
            return (<Dropdown  {...this.props}
                        options         = {showData}
                        search
                        onChange        = {this.formSelectOnChange}
                    />)
        }

        // normal select wrapper
        else 
        {
            return (<Form.Select    {...this.props}
                                    options         = {showData}
                                    fluid
                                    selection
                                    search
                                    onChange        = {this.formSelectOnChange}
> 
                    </Form.Select>)
        }
    }

    checkboxOnChange = (e, option) => 
    {
        let newOptions = this.props.options.map(entry => {
            if(entry.key === option.key)
            {
                entry["selected"] = !entry["selected"]
            }
            else if (this.props.multiple === false) 
            { 
                entry["selected"] = false
            }
            return entry
        })

        if (this.props.onStateUpdate)
        {
            this.props.onStateUpdate(this.props.name, newOptions, e)
        }

        e.stopPropagation()
        
        // right now we want to update immediately on all instances
        this.onBlur(e, newOptions)
    }

    formSelectOnChange = (e, temp) => 
    {
        if (this.props.onStateUpdate)
        {
            this.props.onStateUpdate(this.props.name, temp.value, e)                
        }
        if (this.props.onUpdateData)
        {
            this.props.onUpdateData(this.props.name, temp.value, e)
        }
    }
    
    // is used for filter aswell as select wrapper
    onBlur = (e, newOptions) => 
    {   
        let selected = newOptions.filter(entry => entry.selected)

        if (this.props.onUpdateData)
        {
            this.props.onUpdateData(this.props.name, selected, e)            
        }
    }

    onSearchChange = (searchQuery, resources) => 
    {
        this.setState({isLoading: true, searchQuery})

        if(this.props.onSearchChange)
        {
            this.props.onSearchChange(this.props.name, searchQuery)
        }

        if(searchQuery.length < 1)
        {
          this.onSearchReset()
        }
        else
        {
            const re = new RegExp(escapeRegExp(searchQuery), 'i')
            const isMatch = (result) => {
                let res=!re.test(this.props.getOption ? this.props.getOption(result) : result.key); 
                return res;
            }
            
            let results = filter(resources, isMatch)
            this.setState({results: results, isLoading: false})
        }
    }

    onSearchReset = () => {
      this.setState({isLoading: false,
                     results  : [], 
                     value    : "",})
    }

}

export default BasicSelect;