//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';

import LocalizedStrings from "../../../localization/TableViewComponent";
import DynamicTable, { TABLE_SELECTION_TYPE_Cell } from '../../Basics/DynamicTableView';
import { addNotification } from '../../NotificationDisplay/NotificationDisplay';


class InventoryList extends Component
{

  constructor(props)
  {
    super(props)

    this.state = {
      groupingModes: []
    }

    this.cellIdnetifier = []

    this.cellData = []
    this.tableRef = React.createRef()

    this.defaultCellIdents = [{ident: "Inv_Count", label: LocalizedStrings.Count}, 
                              {ident: "Inv_CountSpare;CountDefinition", label: LocalizedStrings.CountSpare, IfInArrayWithName: true}, 
                              {ident: "Inv_CountTotal", label: LocalizedStrings.CountTotal}, 
                              {ident: "Inv_BookedTotal;CountDefinition", label: LocalizedStrings.BookedTotal, IfInArrayWithName: true, printHidden: true}, 
                            ]
  }

  async updateSummery(){
    let summeryEntryList = this.props.inventoryData.FullDataList.map(entry => entry.Objects)
    let summery = await window.LR_GetInventorySummery({InventoryTable: summeryEntryList, SummeryProps: this.props.inventorySummeryProps, Async: true})
    this.setState({groupingModes: summery})
  }

  componentDidUpdate(prevProps)
  {
    if(prevProps.inventoryData != this.props.inventoryData || prevProps.inventorySummeryProps != this.props.inventorySummeryProps)
    {
      this.updateSummery()
    }
  }

  render()
  {

    let groupingModes = this.state.groupingModes

    this.cellIdnetifier = []
    this.props.properties.forEach(prop => {
      this.cellIdnetifier.push({ident: this.getPropertyIdentifier(prop), label:prop.LocalizedName, unit: prop.IsUnitBased ? prop.BaseUnit : undefined, sortable: true})
    })
    this.cellIdnetifier.push(...this.defaultCellIdents.map(obj => ({...obj, sortable: true})))
    groupingModes.forEach(mode => {
      this.cellIdnetifier.push({ident:"Grouping_" + mode.PropertyName, label: mode.PropertyName, extraColumns: mode.Fields.map(f => ({label: f.Value}))})
    })
    
    let cellRendering = (entry, rowIndex) => {
      let modes = {}
      let properties = {}
      if(!entry.isAccessory)
      {
        for(let mode of groupingModes) 
        {
          for(let field of mode.Fields)
          {
            if(field.Count[rowIndex])
            {
              modes["Grouping_" + mode.PropertyName + "_" + field.Value] = field.Count[rowIndex].toString()
            }
          }
        }
      }

      for(let prop of this.props.properties) 
      {
        // Arrays are not supported
        if (prop.ArrayName) { continue; }

        if (prop.CustomCellFunction)
        {
          properties[this.getPropertyIdentifier(prop)] = prop.CustomCellFunction(prop.PropertyName, entry.GroupValues)
        }
        else
        {
          properties[this.getPropertyIdentifier(prop)] = entry.GroupValues[prop.PropertyName]
        }
      }

      let count = entry.Inv_Count ?? entry.InventoryCount

      return {
        Inv_Count: count,
        ["Inv_CountSpare;CountDefinition"]: entry.Inv_CountSpare,
        Inv_CountTotal: entry.Inv_CountTotal,
        ["Inv_BookedTotal;CountDefinition"]: entry.Inv_BookedTotal,
        ...modes,
        ...properties,
        RowError: this.props.showInventoryError && Number(entry.Inv_CountTotal) > Number(entry.Inv_BookedTotal),
        RowWarning: this.props.showInventoryError && Number(entry.Inv_CountTotal) < Number(entry.Inv_BookedTotal)
      }
    }

    let acc = this.props.showAccessories ? this.props.inventoryData.AccessoriesData: []
    let data = [
      ...this.props.inventoryData.FullDataList,
      ...acc.map(i => (
        {
          ...(i.Accessory),
          AffectedList: i.AffectedList,
          GroupValues: {Name: i.Accessory.Name},
          ArrayIndex: i.ArrayIndex,
          isAccessory: true,
        }))
    ]

    this.cellData = data

    return (
      <>
        <div>
          <DynamicTable   headerIdent={this.cellIdnetifier}
                          cellRender={cellRendering}
                          headerData={this.props.headerData}
                          tableName="InventoryList"
                          selectionType={TABLE_SELECTION_TYPE_Cell}
                          selectionChanged={this.selectionChanged}
                          worksheet={this.props.worksheet}
                          cellData={this.cellData}
                          pages={this.props.pages}
                          showPages={this.props.showPages}
                          fitColumnWidthToContent={this.props.showPages}
                          printMargin={this.props.printMargin}
                          printScale={this.props.worksheetScale}
                          ref={this.tableRef}
                          selectedPreset={this.props.selectedPreset}/>
        </div>
      </>
      )
  }

  selectionChanged = (selectedIdent, selectedObjects, arrayIndex) => 
  {
    /**
     * When Inv_CountSpare is selected we wanna warn if we did not Group by Supplier or Part Number
     */
    if (selectedIdent.ident === "Inv_CountSpare;CountDefinition" || selectedIdent.ident === "Inv_BookedTotal;CountDefinition")
    {
      for (let selectedRow of selectedObjects)
      {
        if (selectedRow.DifferentSupplierPart)
        {
          addNotification(LocalizedStrings.Warning, LocalizedStrings.WarningGroupBySupplier, false, true)
          break;
        }
      }
    }

    let allSelectedObjectUUIDs = []
    let allSelectedObjects = []
    let arrIdx = 0
    let accessoryData = undefined;
    for(let entry of selectedObjects)
    {
      if(entry.isAccessory){
        accessoryData = {
          BaseName: entry.BaseName,
          PrefixName: entry.PrefixName,
        }
        allSelectedObjectUUIDs.push(...entry.AffectedList)
        arrIdx = entry.ArrayIndex;
        for(let i of entry.AffectedList)
        {
          allSelectedObjects.push({Resolved: {...entry, ...entry.GroupValues, UUID: i}})
        }
      }else{
        allSelectedObjectUUIDs.push(...entry.Objects)
        for(let i of entry.Objects)
        {
          allSelectedObjects.push({Resolved: {...entry, ...entry.GroupValues, UUID: i}})
        }
      }

    }

    //NOTE(Wilke): filter option "Hide unselected objects" is problematic. 
    // because the selelction is also controlled from the table not only from the view.
    // the selection update from the table should be blocked, if this filter is set. 
    console.log("show only selected ? ", this.props.showOnlySelected)
    if(!this.props.showOnlySelected){
      window.LR_Select({SelectionMode: 0, SelectedList: allSelectedObjectUUIDs, Selected: true})
    }
    if (this.props.onSelectionChanged)
    {
        this.props.onSelectionChanged(selectedIdent, allSelectedObjects, arrIdx, accessoryData)
    }
  }

  getPropertyIdentifier(prop)
  {
    if (prop.ArrayName)
    {
      return prop.PropertyName + "_" + prop.ArrayName
    }
    return prop.PropertyName
  }

  updateCellData = (data) =>
  {
      window.LR_SetCellData({WorksheetData: {InventoryList: data}, Worksheet: this.props.worksheet})
  }

  applySearchFilter = (objects) =>
  {
    if (!this.props.searchFilter) { return objects }
    return objects.filter(element => {
      for (let i = 0; i < this.cellIdnetifier.length; i++)
      {
        let currentIdent = this.cellIdnetifier[i].ident
        if (!element[currentIdent]) { continue; }

        let elementAsString = element[currentIdent].toString()
        if (elementAsString.toLowerCase().includes(this.props.searchFilter.toLowerCase()))
        {
          return true
        }
        
      }
      return false
    })
  }


  /**
   * Get called from outside
   */
  objectSelectionChanged = (selectedObjects) =>
  {
    if (!this.tableRef.current) { return; }

    let newRowsToSelect = []
    selectedObjects.forEach(obj => {
      let index = this.tableRef.current.getSortedIndexOfData(element => {
        if (!element.Objects)
        {
          return false;
        }
        let result = element.Objects.find(elem => elem.UUID === obj)
        return result
      })

      if (index !== -1)
      {
        newRowsToSelect.push(index)
      }
    })

    this.tableRef.current.setSelectedIndex(newRowsToSelect);
  }
}

export default InventoryList