
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React from 'react';
import { useMediaQuery } from 'react-responsive';
const TableView = React.lazy(()=>import('../TableViews/TableViewComponent'))

import { ErrorBoundary } from '@sentry/react';
import { RESPONSIVE_PHONE } from '../../util/defines';
import { useParams } from 'react-router-dom';

export default function Worksheet(props){
    let isMobile = useMediaQuery({maxWidth: RESPONSIVE_PHONE})
    let uuid = useParams().uuid
    return <ErrorBoundary><TableView {...props} UUID={uuid} electron mobile={isMobile}/></ErrorBoundary>
}

