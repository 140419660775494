//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Button, Form, Grid } from 'semantic-ui-react';

import LocalizedStrings from "../../../localization/TableViewComponent";
import LRModal from '../../Basics/BasicModal';

import { globalCallbacks as mainCB } from '../../../util/callback';
import { globalCallbacks as mockCB } from '../../../util/mock_callback';
import { MultiFieldSearch } from './FieldSearch';
import GroupByComponent from './GroupByComponent';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB                  


class ObjectPropertiesColumnsDisplay extends Component
{

  constructor(props)
  {
    super(props);

    this.state = 
    {
      search:"",
      PresetOptions: [],
      SelectedPreset: "",
      EditOpen: false,


      CreateOpen: false,
      CreateName: "",
      activePropertyFieldIndex: [0]
    }
  }

  componentDidMount()
  {
    this.setUpCallbacks()
  }

  componentDidUpdate(oldProps)
  {
    if (this.props.SelectedPreset !== oldProps.SelectedPreset)
    {
      globalCallbacks.updateObjectPropertiesTemplateMap()
    }
  }
  
  propertyPresetChangable = () =>
  {
    let propertyTemplate = this.state.currentTemplate?.WorksheetState
    if (!propertyTemplate) { return false; }
    if (this.state.currentTemplate.Const) { return false; }

    let compareProperties = (array1, array2) =>
    {
      if (array1.length !== array2.length) { return false; }
      for (let i = 0; i < array1.length; i++)
      {
        let prop = array1[i]
        if (array2.find(elem => (elem.ArrayName || undefined) === (prop.ArrayName || undefined) && elem.PropertyName === prop.PropertyName) === undefined)
        {
          return false;
        }
      }
      return true;
    }
    let comparePropertyFilter = (array1, array2) =>
    {
      if (array1.length !== array2.length) { return false; }
      for (let i = 0; i < array1.length; i++)
      {
        let prop = array1[i]
        if (array2.find(elem => (
          elem.PropertyName === prop.PropertyName && 
          elem.StringValue === prop.StringValue && 
          elem.NumberValue === prop.NumberValue && 
          elem.RangeMin === prop.RangeMin &&
          elem.RangeMax === prop.RangeMax &&
          elem.Mode == prop.Mode
          )) === undefined)        
        {
          return false;
        }
      }
      return true;
    }


    let sameProps = compareProperties(propertyTemplate.ExtraEnabledProperties, this.props.enabledProperties)
    if (!sameProps) { return true; }

    let samePropertyFilter = comparePropertyFilter(propertyTemplate.PropertyBasedFilter, this.props.propertyBasedFilter)
    if (!samePropertyFilter) { return true; }

    let sameGrouping = comparePropertyFilter(propertyTemplate.GroupByProperty, this.props.GroupByProperty)
    if (!sameGrouping) { return true; }

    return  propertyTemplate.ShowFilterConsumer !== this.props.ShowFilterConsumer ||
            propertyTemplate.ShowFilterDistributer !== this.props.ShowFilterDistributer ||
            propertyTemplate.ShowFilterPlugBox !== this.props.ShowFilterPlugBox ||
            propertyTemplate.ShowFilterAssemblyGroup !== this.props.ShowFilterAssemblyGroup ||
            propertyTemplate.ShowFilterGenerator !== this.props.ShowFilterGenerator ||
            propertyTemplate.ShowFilterFixture !== this.props.ShowFilterFixture ||
            propertyTemplate.ShowFilterStructures !== this.props.ShowFilterStructures ||
            propertyTemplate.ShowFilterSupport !== this.props.ShowFilterSupport ||
            propertyTemplate.ShowFilterGroundSupport !== this.props.ShowFilterGroundSupport ||
            propertyTemplate.ShowFilterHouseRiggingPoint !== this.props.ShowFilterHouseRiggingPoint ||
            propertyTemplate.ShowFilterAudio !== this.props.ShowFilterAudio ||
            propertyTemplate.ShowFilterMeshes !== this.props.ShowFilterMeshes ||
            propertyTemplate.ShowFilterDockPoints !== this.props.ShowFilterDockPoints ||
            propertyTemplate.ShowFilterVisible !== this.props.ShowFilterVisible ||
            propertyTemplate.ShowFilterOutputs !== this.props.ShowFilterOutputs ||
            propertyTemplate.ShowFilterInputs !== this.props.ShowFilterInputs ||
            propertyTemplate.ShowFilterSelected !== this.props.ShowFilterSelected
  }

  render()
  {
      return(
        <React.Fragment>
            <Grid stackable style={{maxHeight: 240}}>
              <Grid.Column width="5">
                <h5>{LocalizedStrings.Properties_Preset}</h5>
                <Form.Dropdown  disabled={this.props.isInEditMode}
                                selection 
                                compact
                                search={(options, search) => options.filter(opt => opt.key.toLowerCase().includes(search.toLowerCase()))}
                                options={this.props.PresetOptions} 
                                fluid 
                                value={this.props.SelectedPreset} 
                                onChange={this.onPropertyPresetChanged}/>
                <Button.Group style={{width: "100%", marginTop: "1rem"}}>
                  <Button disabled={!this.propertyPresetChangable() || this.props.isInEditMode} compact size="mini" color="blue" content={LocalizedStrings.UpdatePropertyPresets} onClick={this.updateClick}/>
                  <Button.Or/>
                  <Button disabled={this.props.isInEditMode} compact size="mini" positive content={LocalizedStrings.CreatePropertyPresets} onClick={this.createClick}/>
                </Button.Group>
              </Grid.Column>
              <Grid.Column width="6">
                <h5>{LocalizedStrings.Properties_Field}</h5>
                <MultiFieldSearch
                  propertyList={this.props.propertyList}
                  enabledProperties={this.props.enabledProperties}
                  toggleShowProp={this.props.toggleShowProperties}
                />
              </Grid.Column>
              <Grid.Column width="5">
                <h5>{LocalizedStrings.Group_Field}</h5>
                  <div style={{overflowY: "auto", overflowX: "hidden", height: 140, marginBottom: 10}}>
                    {
                      this.props.GroupByProperty.map((i, idx) => <GroupByComponent
                          key={i.PropertyName}
                          propertyList={this.props.propertyList}
                          groupingProp={i}
                          onChangeGroupByProperty={(property) => { this.props.onGroupByPropertyChanged(idx, property) }}
                          onDelete={()=>{this.props.onGroupByPropertyDeleted(idx)}}
                          onCopy={p => this.props.onGroupByPropertyChanged(this.props.GroupByProperty.length, p)}
                        />
                      )
                    }
                    <GroupByComponent
                      propertyList={this.props.propertyList}
                      onChangeGroupByProperty={(property) => { this.props.onGroupByPropertyChanged(this.props.GroupByProperty.length, property) }}
                    />
                  </div>
                  <Form.Checkbox label = {LocalizedStrings.Setting_UseGroupingIndent} checked={this.props.UseGroupingIndentation} onChange={(e, {checked}) => {this.props.changeUseGroupingIndentation(checked)}}/>
                  <Form.Checkbox      label   = {LocalizedStrings.Setting_ShowSummery} checked={this.props.ShowSummeryTable} onChange={(e, {checked}) => {this.props.showSummeryChange(checked)}}/>
              </Grid.Column>
            </Grid>
            <LRModal  open={this.state.CreateOpen}
                      title={LocalizedStrings.CreatePropertyPresetsHeader}
                      onOkClick={this.createFinished}
                      onCancelClick={() => {this.setState({CreateOpen: false})}}>
              <Form>
                <Form.Input value={this.state.CreateName} onChange={(e, {value}) => {this.setState({CreateName: value})}}/>
              </Form>
            </LRModal>
        </React.Fragment>)
    }

    getPropertyIdentifier(prop)
    {
      if (prop.ArrayName)
      {
        return prop.PropertyName + "_" + prop.ArrayName
      }
      return prop.PropertyName
    }

    getPropFromIdentifier(ident)
    {
      let strings = ident.split("_");
      if (strings.length > 1)
      {
        return {PropertyName: strings[0], ArrayName: strings[1]}
      } 
      else if (strings.length === 1)
      {
        return {PropertyName: strings[0], ArrayName: ""}
      }
      return undefined
    }

    createClick = () =>
    {
      this.setState({CreateOpen: true})
    }

    createFinished = async () =>
    {
      let req = {
        Name: this.state.CreateName, 
        WorksheetState: {
          ExtraEnabledProperties: this.props.enabledProperties,
          GroupByProperty: this.props.GroupByProperty,
          ShowFilterConsumer: this.props.ShowFilterConsumer,
          ShowFilterAssemblyGroup: this.props.ShowFilterAssemblyGroup,
          ShowFilterDistributer: this.props.ShowFilterDistributer,
          ShowFilterPlugBox: this.props.ShowFilterPlugBox,
          ShowFilterGenerator: this.props.ShowFilterGenerator,
          ShowFilterFixture: this.props.ShowFilterFixture,
          ShowFilterStructures: this.props.ShowFilterStructures,
          ShowFilterSupport: this.props.ShowFilterSupport,
          ShowFilterGroundSupport: this.props.ShowFilterGroundSupport,
          ShowFilterHouseRiggingPoint: this.props.ShowFilterHouseRiggingPoint,
          ShowFilterAudio: this.props.ShowFilterAudio,
          ShowFilterMeshes: this.props.ShowFilterMeshes,
          ShowFilterDockPoints: this.props.ShowFilterDockPoints,
          ShowFilterVisible: this.props.ShowFilterVisible,
          ShowFilterInputs: this.props.ShowFilterInputs,
          ShowFilterOutputs: this.props.ShowFilterOutputs,
          ShowFilterSelected: this.props.ShowFilterSelected,
          PropertyBasedFilter: this.props.propertyBasedFilter
        }
      }
      await window.LR_AddPropertyTemplate(req)

      this.setState({CreateOpen: false})
      this.onPropertyPresetChanged(undefined, {value: this.state.CreateName})
    }

    updateClick = () =>
    {
      let req = {
        Name: this.props.SelectedPreset, 
        WorksheetState: {
          ExtraEnabledProperties: this.props.enabledProperties, 
          GroupByProperty: this.props.GroupByProperty, 
          ShowFilterConsumer: this.props.ShowFilterConsumer,
          ShowFilterAssemblyGroup: this.props.ShowFilterAssemblyGroup,
          ShowFilterDistributer: this.props.ShowFilterDistributer,
          ShowFilterPlugBox: this.props.ShowFilterPlugBox,
          ShowFilterGenerator: this.props.ShowFilterGenerator,
          ShowFilterFixture: this.props.ShowFilterFixture,
          ShowFilterStructures: this.props.ShowFilterStructures,
          ShowFilterSupport: this.props.ShowFilterSupport,
          ShowFilterGroundSupport: this.props.ShowFilterGroundSupport,
          ShowFilterHouseRiggingPoint: this.props.ShowFilterHouseRiggingPoint,
          ShowFilterAudio: this.props.ShowFilterAudio,
          ShowFilterMeshes: this.props.ShowFilterMeshes,
          ShowFilterDockPoints: this.props.ShowFilterDockPoints,
          ShowFilterVisible: this.props.ShowFilterVisible,
          ShowFilterSelected: this.props.ShowFilterSelected,
          ShowFilterInputs: this.props.ShowFilterInputs,
          ShowFilterOutputs: this.props.ShowFilterOutputs,
          PropertyBasedFilter: this.props.propertyBasedFilter
        }
      }
      window.LR_SetPropertyTemplate(req)
    }

    onPropertyPresetChanged = (e, {value}) =>
    {
      if (this.props.onPropertyPresetChanged)
      {
        window.LR_GetPropertyTemplate({Name: value}).then(res => 
        {
          if (res.PropertyTemplate) {
            this.props.onPropertyPresetChanged(res.PropertyTemplate);
          }
        })
      }
    }

    setUpCallbacks()
    {
      globalCallbacks.updateObjectPropertiesTemplateMap = () =>
      {
        window.LR_GetPropertyTemplate({Name: this.props.SelectedPreset}).then(res => 
          {
            if (res.PropertyTemplate) {
              this.setState({currentTemplate: res.PropertyTemplate})
            }
          })
      }
      globalCallbacks.updateObjectPropertiesTemplateMap()
    }
}


export default ObjectPropertiesColumnsDisplay;