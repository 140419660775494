//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------

import React, { Component } from 'react';
import { Button, Card, Tab, Message, Segment, Table, Checkbox, Icon, Label } from 'semantic-ui-react';
import LocalizedStrings from "../../localization/PlaceToStructure";
import { RESOURCE_TYPE_FixtureType, RESOURCE_TYPE_SymbolDef, RESOURCE_TYPE_Mesh, EMPTY_UUID, BASE_UNIT_LENGTH, BASE_UNIT_NUMBER, TRUSS_INSERT_ALIGNMENT_TOPLEFT_LEFT, TRUSS_INSERT_ALIGNMENT_TOPLEFT_UP, TRUSS_INSERT_ALIGNMENT_TOPRIGHT_UP, TRUSS_INSERT_ALIGNMENT_TOPRIGHT_RIGHT, TRUSS_INSERT_ALIGNMENT_BOTTOMRIGHT_RIGHT, TRUSS_INSERT_ALIGNMENT_BOTTOMRIGHT_DOWN, TRUSS_INSERT_ALIGNMENT_BOTTOMLEFT_DOWN, TRUSS_INSERT_ALIGNMENT_BOTTOMLEFT_LEFT, TRUSS_INSERT_ALIGNMENT_CENTER, TRUSS_INSERT_ALIGNMENT_LAST, TRUSS_INSERT_ALIGNMENT_CENTER_UP, TRUSS_INSERT_ALIGNMENT_CENTER_DOWN, TRUSS_INSERT_ALIGNMENT_CENTER_RIGHT, TRUSS_INSERT_ALIGNMENT_CENTER_LEFT } from '../../util/defines';
import UnitInput from '../Basics/BasicUnitInput';
import LRTableHeaderCell from '../Basics/BasicTableView';
import SelectResourceModal from '../ToolSelect/SelectRessourceModal';
import LRModal from '../Basics/BasicModal';

import { globalCallbacks } from "../../util/callback";


const TAB_SELECTED_OBJECTS = 3

function HoverLabel(props)
{
    const labelStyle = {
            transform: "scale(1.1)",
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            cursor: "pointer"
    }

    let [hovered, setHovered] = React.useState(false);

    return (
        <Label  onMouseEnter = {()=> setHovered(true)} 
                onMouseLeave = {()=> setHovered(false)} 
                style={hovered ? labelStyle : undefined}
                {...props}>
            {props.children}
        </Label>
    )
}

class PlaceToStructureModal extends Component
{
    constructor(props) {
        super(props)
    
        this.panes = 
        [
            {
                menuItem: LocalizedStrings.FixtureTypes, render: () =>
                    <Tab.Pane key='1'>
                        {this.renderFixtures()}
                    </Tab.Pane>
            },
            {
                menuItem: LocalizedStrings.SymbolDefintions, render: () =>
                    <Tab.Pane key='2'>
                        {this.renderSymbols()}
                    </Tab.Pane>
            },
            {
                menuItem: LocalizedStrings.Mesh, render: () =>
                    <Tab.Pane key='3'>
                        {this.renderMeshes()}
                    </Tab.Pane>
            },
            {
                menuItem: LocalizedStrings.PlaceSelected, render: () => 
                    <Tab.Pane key="4">
                        {this.renderSelectedObjects()}
                    </Tab.Pane>
            },
        ]

        
        this.state = {
            open: false,
            count: 1,
            spacing: 0,
            resourceType: RESOURCE_TYPE_FixtureType,
            activeTab: 0,

            fixtureTypes: [],
            meshes: [],
            symbolDefs: [],
            ressourceDrawings: [],
            structures: [],

            structLength: 0,

            selectedIndex: 0,

            resourceSelectorOpen: false,

            selectedObjectCount: 0,
            selectedObjects: [],
        }
    }

    componentDidMount = () => 
    {
        window.LR_GetGlobalSettings().then(res => {
            this.setState({Unit: res.GlobalSettings.App_DisplayUnit_Length.Value})
        })
        this.setUpCallbacks();
    }

    show = async (args) => {

        let fixtureTypes      = await window.LR_GetFixtureTypes();
        let meshes            = await window.LR_GetMeshes({});
        let symbolDefs        = await window.LR_GetSymbolDefs();

        let newState = {
            open: true, 
            fixtureTypes: fixtureTypes.FixtureTypes, 
            meshes: meshes.Meshes, 
            symbolDefs: symbolDefs.SymbolDefs,
            ObjectList: [],
            ParentObject: undefined,
        }

        if (args.ObjectList) {
            newState.ObjectList = args.ObjectList;
        } else {
            newState.ParentObject = args.ParentObject;
        }

        this.setState(newState);
    }

    onOkClick = () => {

        //----------------------------------------------------------------------------------------------------
        let selectedObject = undefined;
        if (this.state.resourceType === RESOURCE_TYPE_FixtureType)
        {
            selectedObject = this.state.fixtureTypes[this.state.selectedIndex];
        } 
        else if (this.state.resourceType === RESOURCE_TYPE_Mesh)
        {
            selectedObject = this.state.meshes[this.state.selectedIndex];
        }
        else if (this.state.resourceType === RESOURCE_TYPE_SymbolDef)
        {
            selectedObject = this.state.symbolDefs[this.state.selectedIndex];
        }


        //----------------------------------------------------------------------------------------------------
        if (this.state.activeTab === TAB_SELECTED_OBJECTS)
        {
            let request = 
            {
                UseSelectedObjects: true,
                Structures: this.state.structures
            }

            window.LR_PlaceToStructure(request)
        }
        else if (selectedObject)
        {
            let request = 
            {
                Object:         selectedObject.UUID, 
                ResourceType:   this.state.resourceType,
                Structures: this.state.structures
            }

            request.ObjectList   = this.state.ObjectList;
            request.ParentObject = this.state.ParentObject;

            window.LR_PlaceToStructure(request);
        }
        this.close();
    }
    
    
    close = () => {
        this.setState({open: false, selectedIndex: 0})
    }
    
    render()
    {
        let {open} = this.state;
        if (!open) { return <div/>}

        return (
            <LRModal    open={open} 
                        onCancelClick={this.close}
                        onOkClick={this.onOkClick}
                        okDisabled={!this.state.structures}
                        title={LocalizedStrings.Header}
                        additionalHeader={<>
                            <Button disabled={this.state.activeTab === 3/*This is the selected objects tab. Dont want to import resources*/} compact floated='right' positive  onClick={()=>{this.setState({ resourceSelectorOpen : true });}}>
                                <Icon name='cloud download' />
                                {LocalizedStrings.Download}
                            </Button>
                            <Button disabled={this.state.activeTab === 3/*This is the selected objects tab. Dont want to import resources*/} compact floated='right' positive content={this.state.resourceType === RESOURCE_TYPE_SymbolDef ? LocalizedStrings.Create : LocalizedStrings.Import} onClick={this.newResource}/></>}>
               
                    <Tab panes={this.panes} activeIndex={this.state.activeTab} onTabChange={this.onTabChange}/>


                    { !this.state.structures ? <Message error>
                        <Message.Header>
                            {LocalizedStrings.ErrorNoStructureHeader}
                        </Message.Header>
                        {LocalizedStrings.ErrorNoStructureText}
                    </Message> : this.renderStructures()}
                {(this.getApplyCount() > 1 && this.state.activeTab === TAB_SELECTED_OBJECTS) ? 
                    <Message warning>
                        <Message.Header>
                            {LocalizedStrings.ToManyStructures}
                        </Message.Header>
                        {LocalizedStrings.DetailToManyStructures}
                    </Message> : null
                }
                {this.state.resourceSelectorOpen ? <SelectResourceModal 
                    open={this.state.resourceSelectorOpen} 
                    close={this.closeRessourceSelector} 
                    resourceType={this.state.resourceType}
                    disableNewButton
                    disableLocalRessources/> : null}
            </LRModal>
        );
    }

    onTabChange = (_, { activeIndex }) =>
    {
        let resourceType = RESOURCE_TYPE_FixtureType
        if (activeIndex === 0) { resourceType = RESOURCE_TYPE_FixtureType; }
        else if (activeIndex === 1) { resourceType = RESOURCE_TYPE_SymbolDef; }
        else if (activeIndex === 2) { resourceType = RESOURCE_TYPE_Mesh; }
        this.setState({activeTab: activeIndex, resourceType: resourceType})
    }

    closeRessourceSelector = () => 
    {
        globalCallbacks.PlaceStructureReloadResource()
        this.setState({ resourceSelectorOpen : false });

    }

    renderFixtures = () => 
    {
        return (
            <Card.Group itemsPerRow="4">
                        {this.state.fixtureTypes.length !== 0 ? this.state.fixtureTypes.map((fixture, index) => {
                            return (
                                <Card   link 
                                        key={fixture.UUID} 
                                        fluid 
                                        as="h2" 
                                        color={this.state.selectedIndex === index ? "red" : undefined}
                                        onClick={(e) => {this.setState({selectedIndex: index})}}>
                                    <Card.Content>
                                        <Card.Header></Card.Header>
                                        {fixture.Name}
                                        <Card.Meta></Card.Meta>
                                    </Card.Content>
                                </Card>)
                        }) : null}
            </Card.Group>);
    }

    renderMeshes = () => 
    {
        return (
        <Card.Group itemsPerRow="4">
                    {this.state.meshes.length !== 0 ? this.state.meshes.map((mesh, index) => {
                        return (
                            <Card   link 
                                    key={mesh.UUID} 
                                    fluid 
                                    as="h2" 
                                    color={this.state.selectedIndex === index ? "red" : undefined}
                                    onClick={(e) => {this.setState({selectedIndex: index})}}>
                                <Card.Content>
                                    <Card.Header></Card.Header>
                                    {mesh.Name}
                                    <Card.Meta></Card.Meta>
                                </Card.Content>
                            </Card>)
                    }) : null}
        </Card.Group>);

    }

    renderSymbols = () => 
    {
        return (
            <Card.Group itemsPerRow="4">
                        {this.state.symbolDefs.length !== 0 ? this.state.symbolDefs.map((symDef, index) => {
                            return (
                                <Card   link 
                                        key={symDef.UUID} 
                                        fluid 
                                        as="h2" 
                                        color={this.state.selectedIndex === index ? "red" : undefined}
                                        onClick={(e) => {this.setState({selectedIndex: index})}}>
                                    <Card.Content>
                                        <Card.Header></Card.Header>
                                        {symDef.Name}
                                        <Card.Meta></Card.Meta>
                                    </Card.Content>
                                </Card>)
                        }) : null}
            </Card.Group>);
    }

    renderSelectedObjects = () =>
    {
        return (
            <Card.Group itemsPerRow="4">
                {this.state.selectedObjects.map((obj => {
                    return (
                        <Card   key={obj.UUID}
                                fluid
                                as="h2">
                            <Card.Content>
                                <Card.Header></Card.Header>
                                {obj.NameIdentifier}
                                <Card.Meta></Card.Meta>
                            </Card.Content>
                        </Card>
                    )
                }))}
            </Card.Group>
        )
    }

    renderStructures = () => 
    {
        return (<Segment>
            <Table>
                <Table.Header>
                    <LRTableHeaderCell/>
                    <LRTableHeaderCell>
                        {LocalizedStrings.StructName}
                    </LRTableHeaderCell>
                    <LRTableHeaderCell baseUnit={BASE_UNIT_LENGTH}>
                        {LocalizedStrings.StructLength}
                    </LRTableHeaderCell>
                    <LRTableHeaderCell>
                        {LocalizedStrings.Alignment}
                    </LRTableHeaderCell>
                    <LRTableHeaderCell>
                        {LocalizedStrings.Alternate}
                    </LRTableHeaderCell>
                    <LRTableHeaderCell>
                        {LocalizedStrings.Count}
                    </LRTableHeaderCell>
                    <LRTableHeaderCell baseUnit={BASE_UNIT_LENGTH}>
                        {LocalizedStrings.Spacing}
                    </LRTableHeaderCell>
                    <LRTableHeaderCell baseUnit={BASE_UNIT_LENGTH}>
                        {LocalizedStrings.Offset}
                    </LRTableHeaderCell>
                </Table.Header>
                <Table.Body>
                    {this.state.structures.map((strct, index) => {
                        return (
                            <Table.Row key={index}>
                                <Table.Cell>
                                    <Checkbox name="Apply"
                                                checked={strct.Apply}
                                                onChange={(e, {checked}) => {this.onApplyChange(index, checked, e.altKey)}}/>
                                </Table.Cell>
                                <Table.Cell>
                                    {strct.Name}
                                </Table.Cell>
                                <Table.Cell>
                                    <UnitInput name          = "Length" 
                                               baseUnit      = {BASE_UNIT_LENGTH}
                                               value         = {strct.Length}
                                               transparent
                                               label         = {false}
                                               readOnly      = {true}/>
                                </Table.Cell>
                                <Table.Cell>
                                    <HoverLabel size="big" 
                                                onClick={() => {this.increaseAlignment(index)}}
                                                onContextMenu={() => {this.descreaseAlignment(index)}}>
                                        {this.getIconByAlignment(strct.Alignment)}
                                    </HoverLabel>
                                </Table.Cell>
                                <Table.Cell>
                                    <Checkbox   name="Alternate" 
                                                checked={strct.UseAlternateAlignment}
                                                onChange={(e, {checked}) => {this.setUseAlternate(index, checked)}}/>
                                    <HoverLabel size="big"
                                                onClick={() => {if (strct.UseAlternateAlignment) {this.increaseAlternateAlignment(index)}}}
                                                onContextMenu={() => {if (strct.UseAlternateAlignment) {this.descreaseAlternateAlignment(index)}}}>
                                        {this.getIconByAlignment(strct.UseAlternateAlignment ? strct.AlternateAlignment : strct.Alignment)}
                                    </HoverLabel>
                                </Table.Cell>
                                <Table.Cell>
                                    <UnitInput name          = "Count" 
                                               baseUnit      = {BASE_UNIT_NUMBER}
                                               value         = {this.state.activeTab === TAB_SELECTED_OBJECTS ? this.state.selectedObjectCount : strct.Count}
                                               transparent
                                               label         = {false}
                                               unitStr={LocalizedStrings.All}
                                               forceUnitString={true}
                                               labelClicked={()=>this.onAllCount(strct.Count)}
                                               readOnly      = {this.state.activeTab === TAB_SELECTED_OBJECTS}
                                               onStateUpdate = {(name, value) => {this.onCountChange(index, value)}}/>
                                </Table.Cell>
                                <Table.Cell>
                                    <UnitInput  name          = "Spacing"
                                                baseUnit      = {BASE_UNIT_LENGTH}
                                                value         = {this.state.activeTab === TAB_SELECTED_OBJECTS ? strct.Length / this.state.selectedObjectCount : strct.Spacing}
                                                transparent
                                                label         = {false}
                                                labelClicked={()=>this.onAllSpacing(strct.Spacing)}
                                                readOnly      = {this.state.activeTab === TAB_SELECTED_OBJECTS}
                                                onStateUpdate = {(name, value) => { this.onSpacingChange(index, value) }}>

                                    </UnitInput>
                                </Table.Cell>
                                <Table.Cell>
                                    <UnitInput  name          = "Offset"
                                                baseUnit      = {BASE_UNIT_LENGTH}
                                                value         = {strct.Offset}
                                                labelClicked={()=>this.onAllOffset(strct.Offset)}
                                                transparent
                                                label         = {false}
                                                onStateUpdate = {(name, value) => {this.onOffsetChange(index, value)}}/>
                                </Table.Cell>
                            </Table.Row>
                        )
                    })}
                </Table.Body>
            </Table>
        </Segment>)
    }

    setUseAlternate = (index, checked) =>
    {
        let structures = this.state.structures;
        structures[index].UseAlternateAlignment = checked;
        this.setState({structures: structures});
    }

    increaseAlternateAlignment = (index) =>
    {
        let strcts = this.state.structures;
        strcts[index].AlternateAlignment = (strcts[index].AlternateAlignment + 1) % 8;
        this.setState({structures: strcts});
    }

    descreaseAlternateAlignment = (index) =>
    {
        let strcts = this.state.structures;
        let newAlignment = strcts[index].AlternateAlignment - 1
        if (newAlignment < 0)
        {
            newAlignment = 7
        }
        strcts[index].AlternateAlignment = newAlignment
        this.setState({structures: strcts});
    }

    increaseAlignment = (index) =>
    {
        let strcts = this.state.structures;
        let newAlignment = strcts[index].Alignment + 1
        if (newAlignment > TRUSS_INSERT_ALIGNMENT_LAST)
        {
            newAlignment = 0;
        }
        strcts[index].Alignment = newAlignment;
        this.setState({structures: strcts});
    }

    descreaseAlignment = (index) =>
    {
        let strcts = this.state.structures;
        let newAlignment = strcts[index].Alignment - 1
        if (newAlignment < 0)
        {
            newAlignment = TRUSS_INSERT_ALIGNMENT_LAST;
        }
        strcts[index].Alignment = newAlignment;

        this.setState({structures: strcts});
    }

    getIconByAlignment = (alignment) =>
    {
        switch (alignment)
        {
        case TRUSS_INSERT_ALIGNMENT_TOPLEFT_LEFT:
            return <Icon rotated='counterclockwise' name='level up alternate'/>
        case TRUSS_INSERT_ALIGNMENT_TOPLEFT_UP:
            return <Icon flipped='horizontally' name='level up alternate'/>
        case TRUSS_INSERT_ALIGNMENT_TOPRIGHT_UP:
            return <Icon name='level up alternate'/>
        case TRUSS_INSERT_ALIGNMENT_TOPRIGHT_RIGHT:
            return <Icon rotated='counterclockwise' name='level down alternate'/>
        case TRUSS_INSERT_ALIGNMENT_BOTTOMRIGHT_RIGHT:
            return <Icon flipped='vertically' rotated='counterclockwise' name='level down alternate'/>
        case TRUSS_INSERT_ALIGNMENT_BOTTOMRIGHT_DOWN:
            return <Icon name='level down alternate'/>
        case TRUSS_INSERT_ALIGNMENT_BOTTOMLEFT_DOWN:
            return <Icon flipped='horizontally' name='level down alternate'/>
        case TRUSS_INSERT_ALIGNMENT_BOTTOMLEFT_LEFT:
            return <Icon flipped='vertically' rotated='counterclockwise' name='level up alternate'/>
        case TRUSS_INSERT_ALIGNMENT_CENTER:
            return <Icon  name='circle'/>
        case TRUSS_INSERT_ALIGNMENT_CENTER_UP:
            return <Icon  name='long arrow alternate up'/>
        case TRUSS_INSERT_ALIGNMENT_CENTER_DOWN:
            return <Icon  name='long arrow alternate down'/>
        case TRUSS_INSERT_ALIGNMENT_CENTER_RIGHT:
            return <Icon  name='long arrow alternate right'/>
        case TRUSS_INSERT_ALIGNMENT_CENTER_LEFT:
            return <Icon  name='long arrow alternate left'/>
        default:
            return null;
        }
    }

    onCountChange = (index, value) =>
    {
        let strcts = this.state.structures;

        let newCount = Math.floor(Number(value));
        let newSpacing = (strcts[index].Length) / (newCount - 1);
        
        
        strcts[index].Count = newCount;
        strcts[index].Spacing = newSpacing;
        

        this.setState({structures: strcts});
    }

    onSpacingChange = (index, value) => 
    {
        let strcts = this.state.structures

        let newSpacing = Number(value)
        let newCount = ((strcts[index].Length) / newSpacing) + 1;

        strcts[index].Count = newCount;
        strcts[index].Spacing = newSpacing;

        this.setState({structures: strcts});
    }

    onOffsetChange = (index, value) => 
    {
        let strcts = this.state.structures

        let newCount = Number(value)

        strcts[index].Offset = newCount;

        this.setState({structures: strcts});
    }

    onAllCount = (value) => 
    {
        let strcts = this.state.structures;

        strcts.forEach(e=>
        {
            let newCount = Math.floor(Number(value));
            let newSpacing = (e.Length) / newCount;
            e.Count = newCount;
            e.Spacing = newSpacing;
    
        })

    
        this.setState({structures: strcts});
    }

    onAllSpacing = (value) => 
    {
        let strcts = this.state.structures;

        strcts.forEach(e=>
        {
            let newSpacing = Number(value)
            let newCount = (e.Length) / value;
            e.Count = newCount;
            e.Spacing = newSpacing;
    
        })

    
        this.setState({structures: strcts});
    }

    onAllOffset = ( value) => 
    {
        let strcts = this.state.structures

        strcts.forEach(e=>
        {
            e.Offset = value;
    
        })

        this.setState({structures: strcts});
    }
    
    onApplyChange = (index, checked, all) =>
    {
        let strcts = this.state.structures.map((s,i) =>
        {
            if(all)
            {
                return{
                    ...s,
                    Apply: checked
                }
            }
            else if(index === i)
            {
                return{
                    ...s,
                    Apply: checked
                }
            }

            return{
                ...s,
            }

        })

        this.setState({structures: strcts})
    }

    newResource = () => 
    {
        if (this.state.resourceType === RESOURCE_TYPE_Mesh)
        {
            window.LR_ImportMesh({ });
        }
        else if (this.state.resourceType === RESOURCE_TYPE_FixtureType)
        {
            window.LR_ImportGdtf({ });
        }
        else if (this.state.resourceType === RESOURCE_TYPE_SymbolDef)
        {
            window.LR_AddNewSymbolDefinition();
        }
    }

    
    getDistance(pos1, pos2)
    {
        let xDist = pos1.X - pos2.X
        let yDist = pos1.Y - pos2.Y
        let zDist = pos1.Z - pos2.Z

        return Math.sqrt(xDist ** 2 + yDist ** 2 + zDist ** 2);
    }

    getApplyCount()
    {
        let result = 0
        this.state.structures.forEach(strct => {
            if (strct.Apply)
            {
                result++;
            }
        })
        return result
    }

    setUpCallbacks()
    {
        globalCallbacks.ShowPlaceToStructure = async (args) =>
        {
            //This needs to be done for all selected if ParentObject == undefined
            let structures = undefined

            if ( args.ParentObject)
            {
                structures = await window.LR_GetStructures({RootObject: args.ParentObject});
            }
            else if (args.UseSelected === true)
            {
                structures = await window.LR_GetStructures({UseSelected: true})
            }
            else
            {
                structures = await window.LR_GetStructures({});
            }

            structures.Structures.forEach(strct => {
                let strctLength = (strct.Length);
                strct.Count = 1;
                strct.Spacing = strctLength / strct.Count
                strct.Offset = 0
                strct.Apply = true
                strct.Alignment = TRUSS_INSERT_ALIGNMENT_BOTTOMRIGHT_DOWN
                strct.AlternateAlignment = strct.Alignment
                strct.UseAlternateAlignment = false
            })


            let selectedObjects = await window.LR_GetSelectedObjects()
            let selectedObjectCount = selectedObjects.length;

            this.setState({structures: structures.Structures, ParentObject: args.ParentObject ? args.ParentObject : EMPTY_UUID, selectedObjectCount, selectedObjects})
            this.show(args);
        }

        globalCallbacks.PlaceStructureReloadResource = async () => 
        {
            let fixtureTypes      = await window.LR_GetFixtureTypes();
            let meshes            = await window.LR_GetMeshes({});
            let symbolDefs        = await window.LR_GetSymbolDefs();

            this.setState({ fixtureTypes: fixtureTypes.FixtureTypes, 
                            meshes: meshes.Meshes, 
                            symbolDefs: symbolDefs.SymbolDefs});
        }
    }
}


export default PlaceToStructureModal;