//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Segment, Grid, Divider, Icon, Header, Button } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/ConnectToRemote";
import LRModal from '../Basics/BasicModal';
import { globalCallbacks } from "../../util/callback";


//-----------------------------------------------------------------------------
// The component for the TreeControl
class AskForLRNETMaster extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
    }
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }

  show = () => 
  {
    this.setState( { open : true});
  }

  close = () => 
  {
    this.setState({open : false});
  }
    
  render() 
  {
    let open = this.state.open;
    if(!open) {return <div/>}
    return (
      <LRModal  open={open}
                title={LocalizedStrings.HeaderLRnetSession}
                closeOnDimmerClick={false}
                noCancel
                noActions
                onCancelClick={this.close} 
                onOkClick={this.close}>
          <Segment placeholder>
          <Grid columns={2} stackable textAlign='center'>
            <Divider vertical>{LocalizedStrings.or}</Divider>

            <Grid.Row verticalAlign='middle'>
              <Grid.Column>
                <Header icon >
                  <Icon name='send' />
                </Header>
                <Button positive onClick={()=>{window.LR_LRNET_ConnectionAccept({SendDrawing: true});this.close()}}>{LocalizedStrings.Send}</Button>

              </Grid.Column>

              <Grid.Column>
                <Header icon >
                  <Icon name='mail' />
                </Header>
                <Button positive onClick={()=>{window.LR_LRNET_ConnectionAccept({SendDrawing: false});this.close()}}>{LocalizedStrings.Receive}</Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          </Segment>
      </LRModal>
    )
  }

  setUpCallbacks()
  {

    globalCallbacks.AskForLRNetMaster = () => 
    { 
      this.show();
    }
    
  }
}

export default AskForLRNETMaster