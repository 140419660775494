//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------

import React, { Component } from 'react';
import LRModal from '../Basics/BasicModal';
import { globalCallbacks } from "../../util/callback";
import LocalizedStrings from "../../localization/ExportSymbolsModal";
import { Form } from 'semantic-ui-react';
import { RESOURCE_TYPE_PrintLabel, RESOURCE_TYPE_SymbolDef } from '../../util/defines';

class ExportSymbolsModal extends Component {
    constructor (props)
    {
        super(props)

        this.state = {
            open: false,
            Arg: {},
            options: [],
            symbols: []
        }
    }

    componentDidMount ()
    {
        this.setUpCallbacks()
    }

    render() {
        return <LRModal open={this.state.open}
                        size="tiny"
                        title={LocalizedStrings.Header}
                        onOkClick={this.ok}
                        scrolling={false}
                        onCancelClick={this.close}>
                <Form>
                    <Form.Select    name="symbols" 
                                    label={LocalizedStrings.Symbols} 
                                    fluid 
                                    selection 
                                    multiple
                                    options={this.state.options}
                                    value={this.state.symbols}
                                    onChange={(e, {value}) => {this.setState({symbols: value})}}/>
                </Form>
        </LRModal>
    }

    close = () =>
    {
        this.setState({open: false})
    }

    ok = () =>
    {
        window.LR_ExportSymbols({...this.state.Arg, Symbols: this.state.symbols, })
        this.setState({open: false})
    }

    setUpCallbacks ()
    {
        globalCallbacks.showExportSymbolsModal = async (args) =>
        {
            let options = []
            if(args.ResourceType === RESOURCE_TYPE_SymbolDef)
            {
                let symbols = await window.LR_GetSymbolDefs()
                options = symbols.SymbolDefs.map(symbol => ({key: symbol.UUID, value: symbol.UUID, text: symbol.Name}))
            }
            else if(args.ResourceType === RESOURCE_TYPE_PrintLabel)
            {
                let symbols = await window.LR_GetPrintLabels()
                options = symbols.PrintLabels.map(symbol => ({key: symbol.UUID, value: symbol.UUID, text: symbol.Name}))
            }
            
            this.setState({open: true, Arg: args, options})
        }
    }
}

export default ExportSymbolsModal;