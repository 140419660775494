//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import { DEVELOPMENT_SERVER } from './CONST';

let PRODUCT_IDs_DEV = {
    COMMUNITY : "prod_Jh6FZ5wCcGwQYb",
    PRO_SEAT  : "prod_Jh6GnD2gfkYAPe",
    PRO_TEAM  : "prod_Jh6IuWSfrwLUot",
    BASIC     : "prod_Jh6IJXLHQQ3PBc",
    ARENA     : "prod_O9relH1DxZzlcM"
}

let PRICE_IDs_DEV = {
    COMMUNITY         : "price_1J3i2nBvVp14AnWlfdAnib82",
    MONTHLY : {
      [PRODUCT_IDs_DEV.PRO_SEAT] : "price_1J57xhBvVp14AnWl8wc6G8L8", // seat
      [PRODUCT_IDs_DEV.PRO_TEAM] : "price_1J57xNBvVp14AnWliucgtZrj", // team
      [PRODUCT_IDs_DEV.BASIC]    : "price_1J57xsBvVp14AnWl6m8Ab4qD",  // basic
      [PRODUCT_IDs_DEV.ARENA]    : "price_1NNXvTBvVp14AnWlVfBrsPwM"  // arena
    },
    YEARLY : {
      [PRODUCT_IDs_DEV.PRO_SEAT] : "price_1JCjVDBvVp14AnWlJJdRoKya", // seat
      [PRODUCT_IDs_DEV.PRO_TEAM] : "price_1JCjUlBvVp14AnWlA35d1BlT", // team
      [PRODUCT_IDs_DEV.BASIC]    : "price_1JCjUMBvVp14AnWlB23baXQ4",  // basic
      [PRODUCT_IDs_DEV.ARENA]    : "price_1NNXvTBvVp14AnWlbHuLeSHY"  // arena
    }, 
}
      

let PRODUCT_IDs_PROD = {
    COMMUNITY : "prod_Jh51gJQYeTWE9V",
    PRO_SEAT  : "prod_Izhebl8fJ6U5xD",
    PRO_TEAM  : "prod_IzhehZrbQvR9Jy",
    BASIC     : "prod_IzhdrVSLcdfUWl",
    ARENA     : "prod_O9rd2CEFoO0jxd"
}
    
let PRICE_IDs_PROD = {
    COMMUNITY         : "price_1J3gr6BvVp14AnWlaivwPPE8",
    MONTHLY : {
        [PRODUCT_IDs_PROD.PRO_SEAT] : "price_1JCjP4BvVp14AnWl90AlhunG", // seat
        [PRODUCT_IDs_PROD.PRO_TEAM] : "price_1JCjQDBvVp14AnWlzqNT2bNo", // team
        [PRODUCT_IDs_PROD.BASIC]    : "price_1JCjRsBvVp14AnWl1hjD4IIC",  // basic
        [PRODUCT_IDs_PROD.ARENA]    : "price_1NNXuHBvVp14AnWlcp3kR6T5"  // arena
    },
    YEARLY : {
        [PRODUCT_IDs_PROD.PRO_SEAT] : "price_1JCjRTBvVp14AnWl5dl61jhD", // seat
        [PRODUCT_IDs_PROD.PRO_TEAM] : "price_1JCjQZBvVp14AnWl3IlsTT31", // team
        [PRODUCT_IDs_PROD.BASIC]    : "price_1JCjSDBvVp14AnWlmTjtqDfi",  // basic
        [PRODUCT_IDs_PROD.ARENA]    : "price_1NNXuHBvVp14AnWlK8TERaLh"  // arena
    }, 
}

export let GetProductId = () =>
{
    return DEVELOPMENT_SERVER ? PRODUCT_IDs_DEV : PRODUCT_IDs_PROD
}

export let GetPriceIds = () =>
{
    return  DEVELOPMENT_SERVER ? PRICE_IDs_DEV : PRICE_IDs_PROD
}

export let GetProductNames = () =>
{
    const PRODUCT_IDs = DEVELOPMENT_SERVER ? PRODUCT_IDs_DEV : PRODUCT_IDs_PROD

    let PRODUCT_NAMES = {
    [PRODUCT_IDs.COMMUNITY] : "Community",
    [PRODUCT_IDs.PRO_SEAT]  : "Pro Seat",
    [PRODUCT_IDs.PRO_TEAM]  : "Pro Team",
    [PRODUCT_IDs.BASIC]     : "Basic" ,
    [PRODUCT_IDs.ARENA]     : "Arena" 
    } 

    return PRODUCT_NAMES
}

// returns productId for a given priceId
export let GetProductFromPrice = (priceId) => {
  if (priceId === GetPriceIds().COMMUNITY)
    return GetProductId().COMMUNITY

  const monthlyKey = Object.keys(GetPriceIds().MONTHLY).find(prodId => GetPriceIds().MONTHLY[prodId] === priceId)
  const yearlyKey = Object.keys(GetPriceIds().YEARLY).find(prodId => GetPriceIds().YEARLY[prodId] === priceId)

  return monthlyKey ?? yearlyKey
}

export let IsMonthly = (price) => {
  const priceIds = GetPriceIds()
  const monthlyOptions = [...Object.values(priceIds.MONTHLY), priceIds.COMMUNITY]
  const res = monthlyOptions.find(p => p === price)
  return !!res
}

export let IsUpgrade = (oldProductId, newPriceId) => {
  let upgrade = false

  const newPriceIsProTeam = (newPriceId === GetPriceIds().MONTHLY[GetProductId().PRO_TEAM] ||
                              newPriceId === GetPriceIds().YEARLY[GetProductId().PRO_TEAM])

  if ((oldProductId === GetProductId().COMMUNITY && newPriceId !== GetPriceIds().MONTHLY[GetProductId().COMMUNITY]) ||
      (oldProductId === GetProductId().BASIC && newPriceIsProTeam)) 
      upgrade = true

  return upgrade
}

export let GetStripeKey = () =>
{
    console.warn(DEVELOPMENT_SERVER ? "Using Stripe Test Enviroment" : "Using Stripe Production Enviroment" )
    return  DEVELOPMENT_SERVER ? "pk_test_51INi63BvVp14AnWlCM6ur2dSRd1eAmVFjGurC9rLukHlcE4jDzvYs5yKqTxLbUUJCsKZ9NYrPJ1Zg6wtPgrc3Ixt00Vaj8WMMM" : "pk_live_51INi63BvVp14AnWl8HleUwFoBxL4QcBrQPgZRoy7VZA6BNDQ8snLmBHuVsSFwJVQc3RgLdxjVEiGAskWuHoBlSzs00puJQ0eAy"
}