
//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
import React, { Component } from "react";
import { Label } from "semantic-ui-react";

//-----------------------------------------------------------------------------
class Renderer extends Component
{
  constructor(props)
  {
    super(props);

    this.state = {
      render3D: true
    }
  }

  componentDidMount()
  {
    if(this.props.node) { this.props.node.setEventListener("close", (p) => { this.props.onClose() }) }
  }

  render() 
  {
      return (
      <>
      <Label corner="right" onClick={()=>{this.setState({render3D: !this.state.render3D})}}>
        {this.state.render3D ? "3D" : "2D"}
      </Label>
      </>)
  }
}

export default Renderer