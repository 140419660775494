import React, { Component } from 'react'
import LocalizedStrings from "../../localization/ResourceManager";
import { Icon, Message, Form, Table, Button } from 'semantic-ui-react'
import LRModal from '../Basics/BasicModal';
import { BASE_UNIT_LENGTH, BASE_UNIT_FORCE, BASE_UNIT_FORCE_PER_DISTANCE, BASE_UNIT_AREA, BASE_UNIT_AREA_MOMENT_OF_INERTIA, BASE_UNIT_TORQUE, BASE_UNIT_SELECTABLE, IsRunningAsBrowser, BASE_UNIT_BOOLEAN, BASE_UNIT_NUMBER, BASE_UNIT_WEIGHT_PER_DISTANCE } from '../../util/defines';
import UnitInput from '../Basics/BasicUnitInput';
import { globalCallbacks } from '../../util/callback';
import DynamicTable from '../Basics/DynamicTableView';


let TrussDesignOptions = [
  {key: "1", value: "1", text: LocalizedStrings.DesignNone},
  {key: "2", value: "2", text: LocalizedStrings.Pipe},
  {key: "3", value: "3", text: LocalizedStrings.Square},
  {key: "4", value: "4", text: LocalizedStrings.Triangle},
  {key: "5", value: "5", text: LocalizedStrings.Ladder},
  {key: "6", value: "6", text: LocalizedStrings.RopeSection},
]

let BracingDesignOptions = [
  {key: "0", value: "0", text: LocalizedStrings.BracingNone},
  {key: "1", value: "1", text: LocalizedStrings.Alternating},
  {key: "2", value: "2", text: LocalizedStrings.Circular},
]

let CalculationCodeOptions = [
  {key: "0", value: "0", text: LocalizedStrings.Eurocode},
  {key: "1", value: "1", text: LocalizedStrings.DIN},
  {key: "2", value: "2", text: LocalizedStrings.ANSIE_1_2__2012},
]

class CrossSectionTemplateDisplay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      crossSectionTemplates: [],
      editModalOpen: false,
      createModalOpen: false,
      modalValues: {
      },
    };
  }

  componentDidMount = async () => {
    this.setUpCallbacks();
    let crossSectionTemplates = await window.LR_GetTrussCrossSection();
    
    this.setState({ crossSectionTemplates: crossSectionTemplates.ResourceMap.crossSection });
  };

  render() {
    //----------------------------------------------------
    // define dynamic table view props

    const headerIdent = [
        {editable: false, unit: undefined,                    sortable: true, ident: "CrossSectionName",         label: LocalizedStrings.CrossSectionName},
        {editable: false, unit: undefined,                    sortable: true, ident: "Brand",                    label: LocalizedStrings.Brand},
        {editable: false, unit: undefined,                    sortable: true, ident: "Origin",                   label: LocalizedStrings.Origin},
        {editable: false, unit: undefined,                    sortable: true, ident: "LinkedMaterial",           label: LocalizedStrings.LinkedMaterial},
        {editable: true,  unit: undefined,                    sortable: true, ident: "Design",                   label: LocalizedStrings.CrossSectionDesign},
        {editable: false, unit: undefined,                    sortable: true, ident: "Default",                  label: LocalizedStrings.Default},
        {editable: true,  unit: undefined,                    sortable: true, ident: "BracingDesign",            label: LocalizedStrings.CrossSectionBracingDesign},
        {editable: true,  unit: undefined,                    sortable: true, ident: "CalculationCode",            label: LocalizedStrings.UsedCode},
        {editable: true,  unit: BASE_UNIT_LENGTH,             sortable: true, ident: "Hight",                    label: LocalizedStrings.Height},
        {editable: true,  unit: BASE_UNIT_LENGTH,             sortable: true, ident: "Width",                    label: LocalizedStrings.Width},
        {editable: true,  unit: BASE_UNIT_AREA,               sortable: true, ident: "CrossDectionAreaX",        label: LocalizedStrings.CrossDectionAreaX},
        {editable: true,  unit: BASE_UNIT_LENGTH,             sortable: true, ident: "LengthConnector",        label: LocalizedStrings.LengthConnector},
        {editable: true,  unit: BASE_UNIT_NUMBER,             sortable: true, ident: "DeflectionLimit",        label: LocalizedStrings.DeflectionLimit},
        {editable: true,  unit: BASE_UNIT_AREA_MOMENT_OF_INERTIA, sortable: true, ident: "FtmXX",                    label: LocalizedStrings.FtmXX},
        {editable: true,  unit: BASE_UNIT_AREA_MOMENT_OF_INERTIA, sortable: true, ident: "FtmYY",                    label: LocalizedStrings.FtmYY},
        {editable: true,  unit: BASE_UNIT_AREA_MOMENT_OF_INERTIA, sortable: true, ident: "FtmZZ",                    label: LocalizedStrings.FtmZZ},
        {editable: true,  unit: BASE_UNIT_FORCE,              sortable: true, ident: "Nx",                       label: LocalizedStrings.Nx},
        {editable: true,  unit: BASE_UNIT_FORCE,              sortable: true, ident: "Vy",                       label: LocalizedStrings.Vy},
        {editable: true,  unit: BASE_UNIT_FORCE,              sortable: true, ident: "Vz",                       label: LocalizedStrings.Vz},
        {editable: true,  unit: BASE_UNIT_TORQUE,             sortable: true, ident: "Mxx",                      label: LocalizedStrings.Mxx},
        {editable: true,  unit: BASE_UNIT_TORQUE,             sortable: true, ident: "Mby",                      label: LocalizedStrings.Mby},
        {editable: true,  unit: BASE_UNIT_TORQUE,             sortable: true, ident: "Mbz",                      label: LocalizedStrings.Mbz},
        {editable: true,  unit: BASE_UNIT_FORCE_PER_DISTANCE, sortable: true, ident: "Qx",                       label: LocalizedStrings.Qx},
        {editable: true,  unit: BASE_UNIT_FORCE_PER_DISTANCE, sortable: true, ident: "Qy",                       label: LocalizedStrings.Qy},
        {editable: true,  unit: BASE_UNIT_FORCE_PER_DISTANCE, sortable: true, ident: "Qz",                       label: LocalizedStrings.Qz},
        {editable: true,  unit: BASE_UNIT_FORCE,              sortable: true, ident: "ForceChords",              label: LocalizedStrings.ForceChords},
        {editable: true,  unit: BASE_UNIT_FORCE,              sortable: true, ident: "ForceConnector",           label: LocalizedStrings.ForceConnector},
        {editable: true,  unit: BASE_UNIT_FORCE,              sortable: true, ident: "ForceBracingHorizontal",   label: LocalizedStrings.ForceBracingHorizontal},
        {editable: true,  unit: BASE_UNIT_FORCE,              sortable: true, ident: "ForceBracingVertical",     label: LocalizedStrings.ForceBracingVertical},
        {editable: true,  unit: BASE_UNIT_LENGTH,             sortable: true, ident: "ChordDiameter",            label: LocalizedStrings.ChordDiameter},
        {editable: true,  unit: BASE_UNIT_BOOLEAN,             sortable: true, ident: "AssignCableLoad",         label: LocalizedStrings.AssignCableLoad},
        {editable: true,  unit: BASE_UNIT_BOOLEAN,             sortable: true, ident: "DefineDeadLoadByMaterial",         label: LocalizedStrings.DefineDeadLoadByMaterial},
        {editable: true,  unit: BASE_UNIT_WEIGHT_PER_DISTANCE,             sortable: true, ident: "DeadLoadPerDistance",         label: LocalizedStrings.DeadLoadPerDistance},
    ]

    const cellRender = (entry, rowIndex) => {
    
      return {
        CrossSectionName: (
          <>
           <Icon
                name="copy"
                link
                textAlign="right"
                onClick={() => {
                  window.LR_DuplicateCrossSectionTemplate({ Name: entry.Name }).then(() => {
                    if (globalCallbacks.updateCrossSectionTemplates && IsRunningAsBrowser()) { globalCallbacks.updateCrossSectionTemplates() }
                  });
                }}
              />
            {!entry.Default && (<>
              <Icon
                name="delete"
                color="red"
                link
                textAlign="right"
                onClick={() => {
                  window.LR_DeleteCrossSectionTemplate({ Name: entry.Name }).then(() => {
                    if (globalCallbacks.updateCrossSectionTemplates && IsRunningAsBrowser()) { globalCallbacks.updateCrossSectionTemplates() }
                  });
                }}
              />
            </>)}
            {entry.Name}
          </>
        ),
        Brand                    : entry.Brand,
        Origin                   : entry.Origen,
        LinkedMaterial           : entry.LinkedMaterial,
        Design                   : this.renderSelectCell("Design", entry, TrussDesignOptions),
        Default                  : entry.Default ? "yes" : "no",
        BracingDesign            : this.renderSelectCell("BracingDesign", entry, BracingDesignOptions),
        CalculationCode          : this.renderSelectCell("CalculationCode", entry, CalculationCodeOptions),
        Hight                    : this.renderUnitCell("Hight", entry, BASE_UNIT_LENGTH),
        Width                    : this.renderUnitCell("Width", entry, BASE_UNIT_LENGTH),
        CrossDectionAreaX        : this.renderUnitCell("CrossDectionAreaX", entry, BASE_UNIT_AREA),
        LengthConnector          : this.renderUnitCell("LengthConnector", entry, BASE_UNIT_LENGTH),
        DeflectionLimit          : this.renderUnitCell("DeflectionLimit", entry, BASE_UNIT_NUMBER),
        FtmXX                    : this.renderUnitCell("FtmXX", entry, BASE_UNIT_AREA_MOMENT_OF_INERTIA),
        FtmYY                    : this.renderUnitCell("FtmYY", entry, BASE_UNIT_AREA_MOMENT_OF_INERTIA),
        FtmZZ                    : this.renderUnitCell("FtmZZ", entry, BASE_UNIT_AREA_MOMENT_OF_INERTIA),
        Nx                       : this.renderUnitCell("Nx", entry, BASE_UNIT_FORCE),
        Vy                       : this.renderUnitCell("Vy", entry, BASE_UNIT_FORCE),
        Vz                       : this.renderUnitCell("Vz", entry, BASE_UNIT_FORCE),
        Mxx                      : this.renderUnitCell("Mxx", entry, BASE_UNIT_TORQUE),
        Mby                      : this.renderUnitCell("Mby", entry, BASE_UNIT_TORQUE),
        Mbz                      : this.renderUnitCell("Mbz", entry, BASE_UNIT_TORQUE),
        Qx                       : this.renderUnitCell("Qx", entry, BASE_UNIT_FORCE_PER_DISTANCE),
        Qy                       : this.renderUnitCell("Qy", entry, BASE_UNIT_FORCE_PER_DISTANCE),
        Qz                       : this.renderUnitCell("Qz", entry, BASE_UNIT_FORCE_PER_DISTANCE),
        ForceChords              : this.renderUnitCell("ForceChords", entry, BASE_UNIT_FORCE),
        ForceConnector           : this.renderUnitCell("ForceConnector", entry, BASE_UNIT_FORCE),
        ForceBracingHorizontal   : this.renderUnitCell("ForceBracingHorizontal", entry, BASE_UNIT_FORCE),
        ForceBracingVertical     : this.renderUnitCell("ForceBracingVertical", entry, BASE_UNIT_FORCE),
        ChordDiameter            : this.renderUnitCell("ChordDiameter", entry, BASE_UNIT_LENGTH),
        AssignCableLoad          : this.renderUnitCell("AssignCableLoad", entry, BASE_UNIT_BOOLEAN),
        DefineDeadLoadByMaterial : this.renderUnitCell("DefineDeadLoadByMaterial", entry, BASE_UNIT_BOOLEAN),
        DeadLoadPerDistance : this.renderUnitCell("DeadLoadPerDistance", entry, BASE_UNIT_WEIGHT_PER_DISTANCE),
      };
    };

    const cellData = this.state.crossSectionTemplates.map(e => ({...e, editable: !e.Default}));

    const footer = (
        <Table.HeaderCell colSpan="29">
          <Button
            floated="left"
            icon
            labelPosition="left"
            primary
            onClick={this.openCreateModal}
            size="small"
          >
            <Icon name="" />
            {LocalizedStrings.CreateCrossSection}
          </Button>
        </Table.HeaderCell>
    );

    //----------------------------------------------------
    // render
    return (
      <>
        {cellData.length > 0 ? (
          <DynamicTable
            cellRender={cellRender}
            headerIdent={headerIdent}
            cellData={cellData}
            footerRow={footer}
          />
        ) : (
          <>
            <Message style={{ width: "100%" }} warning>
              <Message.Header>
                {LocalizedStrings.NoCrossSectionTemplatesHeader}
              </Message.Header>
              <p>{LocalizedStrings.NoCrossSectionTemplatesText}</p>
            </Message>
            <Button
              floated="left"
              icon
              labelPosition="left"
              primary
              onClick={this.openCreateModal}
              size="small">
              <Icon name="" />
              {LocalizedStrings.AddCrossSection}
            </Button>
          </>
        )}
        {this.renderEditModal()}
      </>
    );
  }

  renderUnitCell = (propName, entry, baseUnit) => {

    let value = entry[propName]
 
 return (
      <UnitInput
        baseUnit={baseUnit}
        label={false}
        min={0}
        noStepper
        readOnly={!entry.editable}
        cellChild
        transparent
        name={propName}
        value={value}
        onStateUpdate={(name, value) => {
          this[entry.Name + "edit" + name] = value
        }}
        onUpdateData={(name, value, e) => {

          if(baseUnit === BASE_UNIT_BOOLEAN)
          {
            this.updateCrossSectionUnit(name, entry, value);
          }
          else
          {
            this.updateCrossSectionUnit(name, entry, this[entry.Name + "edit" + name]);
          }

          
          this[entry.Name + "edit" + name] = undefined
        }}
      />
    );
  };

  renderSelectCell = (propName, entry, options) => {
    const currVal = String(entry[propName])
    return (
      <UnitInput
        readOnly={!entry.editable}
        baseUnit={BASE_UNIT_SELECTABLE}
        options={options}
        value={currVal}
        onlyShowOnEdit
        updateData={value => this.updateCrossSectionEnum(propName, value, entry)}
      />
    )
  }

  updateCrossSectionEnum = (name, value, entry) => {
    entry[name] = value;
    window.LR_SetCrossSectionTemplate(entry).then(() => {
      if (globalCallbacks.updateCrossSectionTemplates && IsRunningAsBrowser) { globalCallbacks.updateCrossSectionTemplates() }
    })
  }

  updateCrossSectionUnit = (name, entry, value) => {
    entry[name] = value
    window.LR_SetCrossSectionTemplate(entry).then(() => {
      if (globalCallbacks.updateCrossSectionTemplates && IsRunningAsBrowser) { globalCallbacks.updateCrossSectionTemplates() }
    })
  };

  //----------------------------------------
  // EDIT MODAL
  //----------------------------------------

  renderEditModal = () => {
    return (
      <LRModal
        title={LocalizedStrings.CreateCrossSectionHeader}
        open={this.state.editModalOpen || this.state.createModalOpen}
        onCancelClick={this.onDiscardModal}
        onOkClick={this.onOkClick}
      >
        <Form>
          <Form.Input
            value={this.state.modalValues.Name}
            label={LocalizedStrings.Name}
            onChange={(e, { value }) => {
              this.setModalValues("Name", value);
            }}
          />
          <Form.Input
            value={this.state.modalValues.Brand}
            label={LocalizedStrings.Brand}
            onChange={(e, { value }) => {
              this.setModalValues("Brand", value);
            }}
          />
          <Form.Input
            value={this.state.modalValues.Origen}
            label={LocalizedStrings.Origin}
            onChange={(e, { value }) => {
              this.setModalValues("Origen", value);
            }}
          />
          <Form.Input
            value={this.state.modalValues.LinkedMaterial}
            label={LocalizedStrings.LinkedMaterial}
            onChange={(e, { value }) => {
              this.setModalValues("LinkedMaterial", value);
            }}
          />
         
          <UnitInput
            placeholder={LocalizedStrings.DesignNone}
            value={this.state.modalValues.Design}
            label={LocalizedStrings.CrossSectionDesign}
            options={TrussDesignOptions}
            baseUnit={BASE_UNIT_SELECTABLE}
            updateData={(value) => {
              this.setModalValues("Design", value);
            }}
          />
             
          <UnitInput
            placeholder={LocalizedStrings.BracingNone}
            value={this.state.modalValues.BracingDesign}
            label={LocalizedStrings.CrossSectionBracingDesign}
            options={BracingDesignOptions}
            baseUnit={BASE_UNIT_SELECTABLE}
            updateData={(value) => {
              this.setModalValues("BracingDesign", value);
            }}
            /> 

        <UnitInput
            placeholder={LocalizedStrings.BracingNone}
            value={this.state.modalValues.CalculationCode}
            label={LocalizedStrings.UsedCode}
            options={CalculationCodeOptions}
            baseUnit={BASE_UNIT_SELECTABLE}
            updateData={(value) => {
              this.setModalValues("CalculationCode", value);
            }}
            /> 
             
          <Form.Checkbox
            name = "AssignCableLoad"
            value={this.state.modalValues.AssignCableLoad}
            label={LocalizedStrings.AssignCableLoad}
            onChange={(_, {checked}) => {
              this.setModalValues("AssignCableLoad", checked);
            }}
          /> 

        <Form.Checkbox
            name = "DefineDeadLoadByMaterial"
            value={this.state.modalValues.DefineDeadLoadByMaterial}
            label={LocalizedStrings.DefineDeadLoadByMaterial}
            onChange={(_, {checked}) => {
              this.setModalValues("DefineDeadLoadByMaterial", checked);
            }}
          /> 
       <Form.Group widths="equal">
          <UnitInput
            name = "Hight"
            value={this.state.modalValues.Hight}
            label={LocalizedStrings.Height}
            baseUnit={BASE_UNIT_LENGTH}
            min={0}
            onStateUpdate={(name, value, e) => {
              this.setModalValues(name, value);
            }}
          /> 
          <UnitInput
            name="Width"
            min={0}
            value={this.state.modalValues.Width}
            label={LocalizedStrings.Width}
            baseUnit={BASE_UNIT_LENGTH}
            onStateUpdate={(name, value, e) => {
              this.setModalValues(name, value);
            }}
          /> 
        </Form.Group>

          <UnitInput
            name="CrossDectionAreaX"
            min={0}
            value={this.state.modalValues.CrossDectionAreaX}
            label={LocalizedStrings.CrossDectionAreaX}
            baseUnit={BASE_UNIT_AREA}
            onStateUpdate={(name, value, e) => {
              this.setModalValues(name, value);
            }}
          /> 
          <UnitInput
              name="DeflectionLimit"
              min={0}
              value={this.state.modalValues.DeflectionLimit}
              label={LocalizedStrings.DeflectionLimit}
              baseUnit={BASE_UNIT_NUMBER}
              onStateUpdate={(name, value, e) => {
                this.setModalValues(name, value);
              }}
            /> 

          <Form.Group widths="equal">
            <UnitInput
              name="FtmXX"
              min={0}
              value={this.state.modalValues.FtmXX}
              label={LocalizedStrings.FtmXX}
              baseUnit={BASE_UNIT_AREA_MOMENT_OF_INERTIA}
              onStateUpdate={(name, value, e) => {
                this.setModalValues(name, value);
              }}
            /> 
            <UnitInput
              name="FtmYY"
              min={0}
              value={this.state.modalValues.FtmYY}
              label={LocalizedStrings.FtmYY}
              baseUnit={BASE_UNIT_AREA_MOMENT_OF_INERTIA}
              onStateUpdate={(name, value, e) => {
                this.setModalValues(name, value);
              }}
            />
            <UnitInput
              name="FtmZZ"
              min={0}
              value={this.state.modalValues.FtmZZ}
              label={LocalizedStrings.FtmZZ}
              baseUnit={BASE_UNIT_AREA_MOMENT_OF_INERTIA}
              onStateUpdate={(name, value, e) => {
                this.setModalValues(name, value);
              }}
            />
        </Form.Group>

        <Form.Group widths="equal">
          <UnitInput
            name="Nx"
            min={0}
            value={this.state.modalValues.Nx}
            label={LocalizedStrings.Nx}
            baseUnit={BASE_UNIT_FORCE}
            onStateUpdate={(name, value, e) => {
              this.setModalValues(name, value);
            }}
          />
          <UnitInput
            name="Vy"
            min={0}
            value={this.state.modalValues.Vy}
            label={LocalizedStrings.Vy}
            baseUnit={BASE_UNIT_FORCE}
            onStateUpdate={(name, value, e) => {
              this.setModalValues(name, value);
            }}
          />
          <UnitInput
            name="Vz"
            min={0}
            value={this.state.modalValues.Vz}
            label={LocalizedStrings.Vz}
            baseUnit={BASE_UNIT_FORCE}
            onStateUpdate={(name, value, e) => {
              this.setModalValues(name, value);
            }}
          />
       </Form.Group>

       <Form.Group widths="equal">
          <UnitInput
            name="Mxx"
            min={0}
            value={this.state.modalValues.Mxx}
            label={LocalizedStrings.Mxx}
            baseUnit={BASE_UNIT_TORQUE}
            onStateUpdate={(name, value, e) => {
              this.setModalValues(name, value);
            }}
          />
          <UnitInput
            name="Mby"
            min={0}
            value={this.state.modalValues.Mby}
            label={LocalizedStrings.Mby}
            baseUnit={BASE_UNIT_TORQUE}
            onStateUpdate={(name, value, e) => {
              this.setModalValues(name, value);
            }}
          />
          <UnitInput
            name="Mbz"
            min={0}
            value={this.state.modalValues.Mbz}
            label={LocalizedStrings.Mbz}
            baseUnit={BASE_UNIT_TORQUE}
            onStateUpdate={(name, value, e) => {
              this.setModalValues(name, value);
            }}
          />
      </Form.Group>

      <Form.Group widths="equal">
          <UnitInput
            name="Qx"
            min={0}
            value={this.state.modalValues.Qx}
            label={LocalizedStrings.Qx}
            baseUnit={BASE_UNIT_FORCE_PER_DISTANCE}
            onStateUpdate={(name, value, e) => {
              this.setModalValues(name, value);
            }}
          />
          <UnitInput
            name="Qy"
            min={0}
            value={this.state.modalValues.Qy}
            label={LocalizedStrings.Qy}
            baseUnit={BASE_UNIT_FORCE_PER_DISTANCE}
            onStateUpdate={(name, value, e) => {
              this.setModalValues(name, value);
            }}
          />
          <UnitInput
            name="Qz"
            min={0}
            value={this.state.modalValues.Qz}
            label={LocalizedStrings.Qz}
            baseUnit={BASE_UNIT_FORCE_PER_DISTANCE}
            onStateUpdate={(name, value, e) => {
              this.setModalValues(name, value);
            }}
          />
      </Form.Group>

      <Form.Group widths="equal">
          <UnitInput
            min={0}
            name="ForceChords"
            value={this.state.modalValues.ForceChords}
            label={LocalizedStrings.ForceChords}
            baseUnit={BASE_UNIT_FORCE}
            onStateUpdate={(name, value, e) => {
              this.setModalValues(name, value);
            }}
          />
          <UnitInput
            min={0}
            name="ForceConnector"
            value={this.state.modalValues.ForceConnector}
            label={LocalizedStrings.ForceConnector}
            baseUnit={BASE_UNIT_FORCE}
            onStateUpdate={(name, value, e) => {
              this.setModalValues(name, value);
            }}
          />
      </Form.Group>

      <Form.Group widths="equal">

          <UnitInput
            min={0}
            name="ForceBracingHorizontal"
            value={this.state.modalValues.ForceBracingHorizontal}
            label={LocalizedStrings.ForceBracingHorizontal}
            baseUnit={BASE_UNIT_FORCE}
            onStateUpdate={(name, value, e) => {
              this.setModalValues(name, value);
            }}
          />
          <UnitInput
            min={0}
            name="ForceBracingVertical"
            value={this.state.modalValues.ForceBracingVertical}
            label={LocalizedStrings.ForceBracingVertical}
            baseUnit={BASE_UNIT_FORCE}
            onStateUpdate={(name, value, e) => {
              this.setModalValues(name, value);
            }}
          />
      </Form.Group>
          <UnitInput
            min={0}
            name="ChordDiameter"
            value={this.state.modalValues.ChordDiameter}
            label={LocalizedStrings.ChordDiameter}
            baseUnit={BASE_UNIT_LENGTH}
            onStateUpdate={(name, value, e) => {
              this.setModalValues(name, value);
            }}
          />
          <UnitInput
            min={0}
            name="DeadLoadPerDistance"
            value={this.state.modalValues.DeadLoadPerDistance}
            label={LocalizedStrings.DeadLoadPerDistance}
            baseUnit={BASE_UNIT_WEIGHT_PER_DISTANCE}
            onStateUpdate={(name, value, e) => {
              this.setModalValues(name, value);
            }}
          />

        </Form>
      </LRModal>
    );
  };

  setModalValues = (key, value) => {
    this.setState({ modalValues: { ...this.state.modalValues, [key]: value } });
  };

  openEditModal = () => {
    this.setState({ editModalOpen: true });
  };

  openCreateModal = () => {
    this.setState({ createModalOpen: true, modalValues: {Design: "1", BracingDesign: "0"} });
  };

  onDiscardModal = () => {
    this.setState({
      editModalOpen: false,
      createModalOpen: false,
      modalValues: {},
    });
  };

  onOkClick = async () => {
    if (this.state.createModalOpen) {
      window.LR_AddCrossSectionTemplate(this.state.modalValues).then(() => {
        if (globalCallbacks.updateCrossSectionTemplates && IsRunningAsBrowser) { globalCallbacks.updateCrossSectionTemplates() }
      })
    } else if (this.state.modalValues) {
      window.LR_SetCrossSectionTemplate(this.state.modalValues).then(() => {
        if (globalCallbacks.updateCrossSectionTemplates && IsRunningAsBrowser) { globalCallbacks.updateCrossSectionTemplates() }
      })
    }

    this.setState({
      editModalOpen: false,
      createModalOpen: false,
      modalValues: {},
    });
  };

  setUpCallbacks() {
    globalCallbacks.updateCrossSectionTemplates = async () => {
      this.setState({ isLoading: true });
      let crossSectionTemplates = await window.LR_GetTrussCrossSection();
      this.setState({ crossSectionTemplates: crossSectionTemplates.ResourceMap.crossSection, isLoading: false });
    };
  }
}

export default CrossSectionTemplateDisplay