//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
const ReactMarkdown = React.lazy(()=>import("react-markdown"))

import ReactMde, { getDefaultToolbarCommands } from 'react-mde';
import gfm from 'remark-gfm';
import { Icon } from 'semantic-ui-react';
import LocalizedStrings from "../../localization/CreateNote";
import LRModal from '../Basics/BasicModal';

import { globalCallbacks } from "../../util/callback";
import { Text } from "../ExportPaperworksReport/MarkdowTableSupport";

const DEFAULT_CONTEXT = [
  {Name: LocalizedStrings.SAVED_VIEW, text: "%%SAVED_VIEW( name, label, width_mm, height_mm,)%%",},
  {Name: LocalizedStrings.WORKSHEET, text: "%%WORKSHEET( name )%%",},
]

//-----------------------------------------------------------------------------
// The component for the TreeControl
let customCommand = {}
let customCommandList = []
DEFAULT_CONTEXT.forEach(e => 
{
  customCommandList.push(e.Name)
  customCommand[e.Name] = {
    name: e.Name,
    icon: () => (
      <span role="img" aria-label="nice">
        {e.Name}
      </span>
    ),
    execute: opts => {
      opts.textApi.replaceSelection("\n" + e.text + "\n");
    }
  }
});

class CreateDrawingNote extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
      protocolText: "",
      changed: false,

      reportPresets: [],
      selectedPreset: "",

      createOpen: false,
      createName: "",

      PrintLabels: [],
      selectedPrintLabel:"",
      selectedPrintLabelFirstPage:""
    }
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }

  show = async(uuid) => 
  {
    let ret  = await window.LR_GetWorksheets()

    let Worksheets = {}

    ret.Worksheets.forEach(ws => 
      {
        Worksheets[ws.Name] = ws.UUID
      })

      globalCallbacks.__gWorksheets = Worksheets;

    this.setState( { 
        open : true, 
        Worksheets: Worksheets,
        UUIDObject: uuid
      });
  }

  export = () => 
  {
    window.LR_AddNewNote({Note: this.state.protocolText, LinkedObject: this.state.UUIDObject})
    this.close()
  }

  cancel = () => 
  {
    this.close()
  }

  close = () => 
  {
    this.setState({open : false});
  }
    
  render() 
  {
    let open = this.state.open;


    return (
      <LRModal  open={open} 
                closeOnDimmerClick={false} 
                closeOnEnter={false}
                title={LocalizedStrings.Header}
                size="fullscreen"
                onOkClick={this.export}
                changed={this.state.changed}
                preventDataLoss
                onCancelClick={this.cancel}
                onDiscardClick={this.close}
                >
                        <ReactMde
                          commands={customCommand}
                          toolbarCommands={ [...getDefaultToolbarCommands(),customCommandList]}
                          getIcon={(commandName) => <Icon name={commandName} />}
                          value={this.state.protocolText}
                          onChange={(data) => this.setState({protocolText: data, changed: true})}
                          selectedTab={this.state.selectedTab}
                          onTabChange={(data) => this.setState({selectedTab: data})}
                          generateMarkdownPreview={(markdown) => Promise.resolve(<ReactMarkdown components={{p: Text}} remarkPlugins={[gfm]}>{markdown}</ReactMarkdown>) }
                          loadSuggestions={this.loadSuggestions}
                          minEditorHeight={800}
                          childProps={{ writeButton: { tabIndex: -1 } }}
                        />
      </LRModal>
    )
  }


  setUpCallbacks()
  {

    globalCallbacks.ShowMakeNote = (arg) => 
    { 
      this.show(arg.UUID);
    }

    
  }
}

export default CreateDrawingNote