//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------

import React, { Component } from 'react';
import { IsDarkTheme } from './defines';
class CommandLine extends Component 
{
    constructor(props)
    {
      super(props);
      this.state = {
          log: [],
          entry: "",
          success: 0,
          offset: 0
      }
    }



    render()
    {
        let backgroundColor = IsDarkTheme() ? "#919191" : "";
        if(this.state.success > 0) { backgroundColor = "rgba(60,191,63,0.3)";}
        if(this.state.success < 0) { backgroundColor = "rgba(191,63,63,0.3)"; }

        return(
            <div style={{width: "100%"}} className="CommandLine">
                <input  value={this.state.entry}
                        onChange={this.onChange} 
                        style={{
                            width: "100%", 
                            backgroundColor:backgroundColor,
                            outlineWidth: 0
                        }} 
                        onSubmit={this.onClickEnter} 
                        onKeyDown={this.handleKeyDown} />
            </div>
        )
    }

    onChange = async (e) =>
    {
        this.setState({ entry: e.target.value});
    }

    handleKeyDown = async (e) =>
    {
        if (e.keyCode ===13) 
        {
            let result = await window.LR_CommandLine({ Command: this.state.entry, })
            this.setState({ log: [...this.state.log, this.state.entry], entry:"", success: result.OK ? 1 : -1})
            setTimeout(()=>{ this.setState({ success: 0})}, 500)
        }
        else if(e.keyCode ===38 /*Up*/) 
        {
            let offset = this.state.offset + 1
            if(offset < 1) { offset = 1}
            if(this.state.log.length <= offset) { offset = this.state.log.length}
            let entry = this.state.log[this.state.log.length - offset]

            console.log(entry)
            this.setState({  entry: entry, offset: offset })
        }
        else if(e.keyCode ===40 /*Down*/) 
        {
            let offset = this.state.offset - 1
            if(offset < 1) { offset = 1}
            if(this.state.log.length <= offset) { offset = this.state.log.length-1}


            let entry = this.state.log[this.state.log.length - offset]
            console.log(entry)
            this.setState({  entry: entry, offset: offset })
        }
    }
}

export default CommandLine;