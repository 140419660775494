//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Icon, Divider, Table } from 'semantic-ui-react';
import { CheckWorkspace } from '../../util/defines';
import FilterFormInput from '../ObjectProperties/FilteredFormGroup';

const onValueOrArray = (f, v) => Array.isArray(v) ? v.every(f) : f(v)

const isEmpty = (elem) => {
    if(!elem) return true
    if(elem.type === FilterFormInput) {
        return onValueOrArray(isEmpty, elem.props.children)
    }
}

class CollapsableDevider extends Component
{
    constructor(props)
    {
        super(props)

        this.state = {
            open: true
        }
    }

    render()
    {   
        let workspace = CheckWorkspace()

        if(this.props.children && onValueOrArray(isEmpty, this.props.children)) return null
        if(this.props.omit) {return this.props.children}

        if(workspace.ObjectInfo[this.props.name] === false)
        {
            return null
        }

        if(this.props.navigationTable)
        {
            return(
                <>
                    <Table.Row style={{padding:"0.5rem 0"}} onClick={() => {this.setState({open:!this.state.open})}}>
                        <Table.Cell colspan={7}>
                            <Icon link name={this.state.open ? "caret down" : "caret right"}/>
                            <span style={{ fontWeight : "bold"}}> {`${this.props.header} (${this.props.countErrors})`}</span>   
                        </Table.Cell>
                    </Table.Row>
                    {this.state.open ? this.props.children : null}
                </>
            )
        }

        return( <>
                    <Divider horizontal  onClick={() => {this.props.update()}}>
                        <Icon link name={this.props.open ? "caret down" : "caret right"}/>
                        <b>
                            <Icon name={this.props.icon} />
                           <span style={{ color : this.props.headerColor}}> {this.props.header}</span>
                        </b>
                    </Divider>
                    {this.props.open ? this.props.children : null}
                </>)
    }
}

export default CollapsableDevider;