//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from "react";
import * as THREE from "three";
import { WEBGL } from './js/WebGl'
import ReactResizeDetector from 'react-resize-detector';
import {InitScene, LoadObjectsReq, LoadMeshes, LoadTextures, FitCameraToSelectedObjects, LoadResourceContainer} from "./js/defaultfunctions";
import { lrServerConnection } from "../../redux/light_right_server_connection";
import ResourceLoadContainer from "./ResourceLoadContainer";
import { Dimmer, Loader } from "semantic-ui-react";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';


// Component for the WebGL Control
class DiffRenderer extends Component
{
  constructor(props)
  {
    super(props);

    this.renderOptions = {}
    this.renderOptions.ShowLights       = false;
    this.renderOptions.ShowBeams        = false;
    this.renderOptions.ShowLightHelpers = false;
    this.renderOptions.ShowLabels       = false;
    this.renderOptions.ShowMagnets      = true;
    this.renderOptions.ThreeJSObjects   = new Map();
    this.renderOptions.meshLoadContainer = new ResourceLoadContainer()
    this.renderOptions.SymbolLoadContainer = new ResourceLoadContainer()
    this.renderOptions.FixtureTypeLoadContainer = new ResourceLoadContainer()
    this.renderOptions.addGeometriesToMap = false;

    this.state = {
      isLoading: true
    }


    this.deletedMaterial      = new THREE.Color(0xff0000);
    this.lightDeletedMaterial = new THREE.Color(0xff6666);

    this.createdMaterial      = new THREE.Color(0x00ff00);
    this.lightCreatedMaterial = new THREE.Color(0x66ff66);

    this.changedMaterial      = new THREE.Color(0x0000ff);
    this.lightChangedMaterial = new THREE.Color(0x6666ff);

    this.textureLoadContainer = new ResourceLoadContainer()
  }
  // -----------------------------------------------------------------------------------------------------------------------
  // React Lifecycle Methods
  // -----------------------------------------------------------------------------------------------------------------------

  // Initialize Data
  componentDidMount = async () =>
  {
    if(WEBGL.isWebGLAvailable())
    {
      this.scene     = new THREE.Scene();
      this.renderOptions.Scene = this.scene;
      this.camera    = new THREE.PerspectiveCamera(50, this.mount.clientWidth / this.mount.clientHeight, 10, 30000000)
      this.camera.up.set(0,0,1)
      this.camera.matrixAutoUpdate = true

      if(WEBGL.isWebGL2Available())
      {
        let canvas          = document.createElement( 'canvas' ); 
        let context         = canvas.getContext( 'webgl2' ); 
        this.renderer       = new THREE.WebGLRenderer( { canvas: canvas, context: context } );
      }
      else
      {
        this.renderer       = new THREE.WebGLRenderer();
      }

      InitScene(this.scene, this.camera,this.renderer,  undefined, this.mount.clientWidth, this.mount.clientHeight);

      this.initControls()
      
      // Attach Renderer to DOM
      this.mount.appendChild(this.renderer.domElement);
      
      // Start Animation
      if (!this.frameId) 
      {
        this.frameId = requestAnimationFrame(this.animate);
      }
    }

    let oldFile
    if(this.props.file && lrServerConnection.setFile !== undefined) {
      oldFile = lrServerConnection.__FILE
      lrServerConnection.setFile(this.props.file, undefined)
    }

    await window.LR_SetActiveDrawing({ DrawingUUID: this.props.drawing1UUID })
    await this.LoadObjectsFromDrawing()

    if(this.props.prevFile)
      lrServerConnection.setFile(this.props.prevFile, undefined)

    await window.LR_SetActiveDrawing({ DrawingUUID: this.props.drawing2UUID })
    await this.LoadObjectsFromDrawing()
    lrServerConnection.setFile(oldFile, undefined)
    this.forceUpdate();
    FitCameraToSelectedObjects(this.scene, this.camera, this.orbitControls, true);

    this.setState({isLoading: false})
  }

  // Unmount
  componentWillUnmount() 
  {
    // Stop Animation
    if(WEBGL.isWebGLAvailable())
    {
      cancelAnimationFrame(this.frameId);
      if (this.renderer)
      {
        this.mount.removeChild(this.renderer.domElement);
      }
    }
  }

  componentDidUpdate(oldProps) 
  {
    this.updateView();
  }
  
  render() 
  {
    return(
      <div style={{top: 10, height: "100%"}}>
        <div  className="Renderer" 
              style={{ width: "100%", height: "100%", maxHeight: "100%" }} 
              ref={mount => { this.mount = mount; }}/>
        <ReactResizeDetector handleWidth handleHeight onResize={this.onResize}/>
        <Dimmer active={this.state.isLoading}>
          <Loader active={this.state.isLoading}></Loader>
        </Dimmer>
      </div>
    );
  }

  onResize = () => 
  {
    this.camera.aspect = this.mount.clientWidth / (this.props.height); // without the -3 there is a useless scrollbar
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(this.mount.clientWidth, this.props.height);  // without the -3 there is a useless scrollbar
  }

  //------------------------------------------------------------------------------------------------------------
  // Updates Scene
  // This will be called very often, 
  animate = () => 
  {
    this.frameId = window.requestAnimationFrame(this.animate);
    
    this.orbitControls.update();
    this.updateMeshInstances();
    this.renderer.render(this.scene, this.camera);
  };


  // Initialize Controls
  initControls = () =>
  {
    this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement, this.scene);
    this.orbitControls.rotateSpeed = 1;
    this.orbitControls.panSpeed = 1;
  }

  updateMeshInstances = () =>
  {
    // Update all the mesh positions
    let oldMeshObjectMap = {...this.meshObjectIndexMap}

    let usedIndexMap = {}
    this.meshObjectIndexMap = {}

    this.scene.traverse(obj => {
      let objectVisible = () => {
        let tempObject = obj
        while (tempObject.parent && !tempObject.LRLayer && !tempObject.LRClass)
        {
          tempObject = tempObject.parent
        }
        if (tempObject.LRLayer || tempObject.LRClass)
        {
          return tempObject.visible
        }
        return true
      }

      if (obj.MeshInstance)
      {
        let thisInstanceMesh = obj.MeshInstance.GetMesh()
        if (!thisInstanceMesh)
        {
          return;
        }
        let meshUuid = thisInstanceMesh.uuid
        
        
        if (!objectVisible())
        {
          if (usedIndexMap[meshUuid] === undefined)
          {
            usedIndexMap[meshUuid] = {Index: 0, MeshInstance: obj.MeshInstance}
          }
          return;
        }


        obj.updateMatrix()
        obj.updateMatrixWorld(true)


        if (this.meshObjectIndexMap[meshUuid] === undefined)
        {
          this.meshObjectIndexMap[meshUuid] = []
        }
        this.meshObjectIndexMap[meshUuid].push(obj);
        


        if (usedIndexMap[meshUuid] === undefined)
        {
          usedIndexMap[meshUuid] = {Index: 0, MeshInstance: obj.MeshInstance};
        }
        let index = Number(usedIndexMap[meshUuid].Index)
        usedIndexMap[meshUuid].Index++;

        thisInstanceMesh.setMatrixAt(index, obj.matrixWorld);

        if (obj.CurrentColor)
        {
          thisInstanceMesh.setColorAt(index, obj.CurrentColor)
        }
        else
        {
          thisInstanceMesh.setColorAt(index, new THREE.Color(1,1,1))
        }
        
        thisInstanceMesh.instanceColor.needsUpdate = true
        thisInstanceMesh.instanceMatrix.needsUpdate = true
      }
    })

    Object.entries(usedIndexMap).forEach(([key, value]) => {
      oldMeshObjectMap[key] = undefined
      if (value.MeshInstance.SetCount(value.Index))
      {
        let theMeshInstance = value.MeshInstance.GetMesh()
        for (let i = 0; i < this.meshObjectIndexMap[key].length; i++)
        {
          let obj = this.meshObjectIndexMap[key][i]

          theMeshInstance.setMatrixAt(i, obj.matrixWorld);
          if (obj.CurrentColor)
          {
            theMeshInstance.setColorAt(i, obj.CurrentColor)
          }
          else
          {
            theMeshInstance.setColorAt(i, new THREE.Color(1,1,1))
          }
        }

        theMeshInstance.instanceColor.needsUpdate = true
        theMeshInstance.instanceMatrix.needsUpdate = true
      }
    })

    // Remove any old mesh instances
    Object.entries(oldMeshObjectMap).forEach(([key, value]) => {
      if (Array.isArray(value) && value.length > 0)
      {
        if (value[0].MeshInstance)
        {
          value[0].MeshInstance.SetCount(0)
        }
      }
    })
  }

  //Load models
  LoadObjectsFromDrawing = async () =>
  {
    
    const textures = await window.LR_GetTextures();
    await LoadTextures(textures.Textures, this.textureLoadContainer);

    const meshes = await window.LR_GetMeshes();
    await LoadMeshes(meshes.Meshes, this.renderOptions.meshLoadContainer, this.textureLoadContainer, this.scene)

    const sym = await window.LR_GetSymbolDefs();
    await LoadResourceContainer(sym.SymbolDefs, this.renderOptions.meshLoadContainer)

    const ft = await window.LR_GetFixtureTypes();
    await LoadResourceContainer(ft.FixtureTypes, this.renderOptions.meshLoadContainer)
    
    let firstObject = await window.LR_GetFirstObject();
    let container   = this.scene;
    if(firstObject.UUID)
    {
      let objectTree = await window.LR_GetObjectTree({FirstUUID: firstObject.UUID, CompleteTree: false, IncludeGeometries: true, Async: true});      
      this.index = {value: 0};
      await LoadObjectsReq(objectTree, this.index, container, this.renderOptions);
    }

  }


  //-----------------------------------------------------------------
  // Geometry

  getRenderedObject = (uuid) =>
  {
    return this.renderOptions.ThreeJSObjects.get(uuid);
  }

  //Diff stuff
  updateObjectMaterial = (object, material) =>
  {

    let children = object.children;

		for ( let i = 0; i < children.length; i ++ ) {
      let child = children[i];

      if (child instanceof THREE.Mesh && child.material.color)
      {
        child.material = material;
        child.material.needsUpdate = true;
      }
      else if (child instanceof THREE.Group)
      {
        this.updateObjectMaterial(child, material);
      }
		}
  }

  updateView = () =>
  {
    if(!this.props.differences || !this.props.differences.Object)
    {
      console.error("Failed to load updateView");
      return ;
    }

    const traverse = (obj, cb) => {
      cb(obj)
      obj.children.forEach((child) => {
        if(!child.UUID) {
          traverse(child, cb)
        }
      })
    }

    if(this.props.differences.Object.Created)
    {
      for(const [uuid, objectChange] of Object.entries(this.props.differences.Object.Created))
      {
        let createdObject = this.getRenderedObject(uuid);
        if(createdObject === undefined) { break; }
        
        traverse(createdObject,obj => {          
          if(obj.MeshInstance) 
          {
            obj.CurrentColor = objectChange.Apply ? this.createdMaterial : this.lightCreatedMaterial
          }
        });

        /*let material = objectChange.Apply ? this.createdMaterial : this.lightCreatedMaterial;
        this.updateObjectMaterial(createdObject, material); */
        
      }
    }

    if(this.props.differences.Object.Changed)
    {
      for(const [uuid, objectChange] of Object.entries(this.props.differences.Object.Changed))
      {
        let changedObject = this.getRenderedObject(uuid);
        if(changedObject === undefined) { break; }
  
        let changeColor = false;
  
        for(const [, change] of Object.entries(objectChange.Changes))
        {
          if (change.Value === undefined) { continue; }
          changeColor = changeColor || change.Value.Apply;
          
        }
        traverse(changedObject,obj => {          
          if(obj.MeshInstance) 
          {
            obj.CurrentColor = changeColor ? this.changedMaterial : this.lightChangedMaterial
          }
        });

        //this.updateObjectMaterial(changedObject, material);
        
      }
    }
    
    if(this.props.differences.Object.Deleted)
    {
      for(const [uuid, objectChange] of Object.entries(this.props.differences.Object.Deleted))
      {
        let deletedObject = this.getRenderedObject(uuid);
        if(deletedObject === undefined) { break; }
        
        traverse(deletedObject,obj => {          
          if(obj.MeshInstance) 
          {
            obj.CurrentColor = objectChange.Apply ? this.deletedMaterial : this.lightDeletedMaterial;
          }
        });
        //this.updateObjectMaterial(deletedObject, material);
      }
    }
    
  }

}
// Export
export default DiffRenderer;