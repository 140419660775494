//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2020 www.deersoft.de
//-----------------------------------------------------------------------------

import "./not-found.css";

import React from "react";
import { connect } from "react-redux";
import { ACTIVE_USER } from "../../redux/redux_defines";
class NotFound extends React.Component {
  render() {
    const loggedIn = !!this.props.loggedInUser.data
    const notFoundText = loggedIn ? "Page not found" : "Page not found, please login"
    return (
      <main className="not-found">
        <div className="callout callout-big">{notFoundText}</div>
      </main>
    );
  }
}

//---------------------------------------------------------------------
// Redux Connection
const mapStatetoProps = (state) => {
  return {
    loggedInUser: state[ACTIVE_USER]
  }
}

export default connect(mapStatetoProps)(NotFound);
