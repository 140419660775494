import ResourceLoadContainer from "../Renderer/ResourceLoadContainer"
import {LoadTextures as defaultLoadTextures} from "../Renderer/js/defaultfunctions"

const nameLoadContainer = new ResourceLoadContainer()
const uuidLoadContainer = new ResourceLoadContainer()

export async function LoadTextures() {
    return window.LR_GetTextures().then(textures => defaultLoadTextures(textures.Textures, nameLoadContainer, uuidLoadContainer))
}

export function HasTexture(name) {
    return nameLoadContainer.has(name)
}

export async function GetTextureByUUID(uuid){
    return await new Promise((res) => {
        uuidLoadContainer.get(uuid, res)
    }) 
}

export function GetTexture(name) {
    return new Promise((res) => {
        nameLoadContainer.get(name, res)
    })
}

export function HasOrLoadingTexture(name) {
    return nameLoadContainer.hasOrLoading(name)
}