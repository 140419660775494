//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------

import React, { Component } from 'react';
import { Button, Card, Icon } from 'semantic-ui-react';
import LocalizedStrings from "../../localization/PlaceToStructure";
import { RESOURCE_TYPE_FixtureType, RESOURCE_TYPE_SymbolDef, RESOURCE_TYPE_Mesh } from '../../util/defines';
import SelectResourceModal from '../ToolSelect/SelectRessourceModal';
import LRModal from '../Basics/BasicModal';

import { globalCallbacks } from "../../util/callback";

class InsertDropComponent extends Component
{
    constructor(props) {
        super(props)
    

        
        this.state = {
            open: false,
            count: 1,
            spacing: 0,
            resourceType: RESOURCE_TYPE_FixtureType,
            activeTab: 0,

            fixtureTypes: [],
            meshes: [],
            symbolDefs: [],
            ressourceDrawings: [],
            structures: [],

            structLength: 0,

            selectedIndex: 0,

            resourceSelectorOpen: false,

            selectedObjectCount: 0,
            selectedObjects: [],
        }
    }

    componentDidMount = () => 
    {
        this.setUpCallbacks();
    }

    show = async () => {

        let symbolDefs        = await window.LR_GetSymbolDefs();

        let newState = {
            open: true, 
            symbolDefs: symbolDefs.SymbolDefs,
            resourceType: RESOURCE_TYPE_SymbolDef,
            ObjectList: [],
        }


        this.setState(newState);
    }

    onOkClick = async () => {

        await window.LR_SetDefaultSymbolDef({UUID: this.state.UUID})
        window.LR_InsertDropForSelectedObjects({UUID: this.state.UUID})
        this.close();
    }
    
    
    close = () => 
    {
        this.setState({open: false})
    }
    
    render()
    {
        let {open} = this.state;
        if (!open) { return <div/>}

        return (
            <LRModal    open={open} 
                        onCancelClick={this.close}
                        onOkClick={this.onOkClick}
                        okDisabled={!this.state.structures}
                        title={LocalizedStrings.Header}
                        additionalHeader={<>
                            <Button disabled={this.state.activeTab === 3/*This is the selected objects tab. Dont want to import resources*/} compact floated='right' positive content={this.state.resourceType === RESOURCE_TYPE_SymbolDef ? LocalizedStrings.Create : LocalizedStrings.Import} onClick={this.newResource}/>
                            this.shouldRenderButton() && <Button disabled={this.state.activeTab === 3/*This is the selected objects tab. Dont want to import resources*/} compact floated='right' positive  onClick={()=>{this.setState({ resourceSelectorOpen : true });}}>
                                <Icon name='cloud download' />
                                {LocalizedStrings.Download}
                            </Button></>}>
               
                    {this.renderSymbols()}
                {this.state.resourceSelectorOpen ? <SelectResourceModal 
                    open={this.state.resourceSelectorOpen} 
                    close={this.closeRessourceSelector} 
                    resourceType={this.state.resourceType}
                    disableNewButton
                    disableLocalRessources/> : undefined}
            </LRModal>
        );
    }

    shouldRenderButton = () => {
        return this.state.symbolDefs.length !== 0 ? true : false
    }

    closeRessourceSelector = () => 
    {
        globalCallbacks.PlaceStructureReloadResource()
        this.setState({ resourceSelectorOpen : false });

    }
    renderSymbols = () => 
    {
        return (
            <Card.Group itemsPerRow="4">
                        {this.state.symbolDefs.length !== 0 ? this.state.symbolDefs.map((symDef, index) => {
                            return (
                                <Card   link 
                                        key={symDef.UUID} 
                                        fluid 
                                        as="h2" 
                                        color={this.state.selectedIndex === index ? "red" : undefined}
                                        onClick={(e) => {this.setState({selectedIndex: index, UUID: symDef.UUID})}}>
                                    <Card.Content>
                                        <Card.Header></Card.Header>
                                        {symDef.Name}
                                        <Card.Meta></Card.Meta>
                                    </Card.Content>
                                </Card>)
                        }) : 
                        <Button disabled={this.state.activeTab === 3/*This is the selected objects tab. Dont want to import resources*/} compact floated='right' positive  onClick={()=>{this.setState({ resourceSelectorOpen : true });}}>
                            <Icon name='cloud download' />
                            {LocalizedStrings.Download}
                        </Button>}
            </Card.Group>);
    }

    newResource = () => 
    {
        if (this.state.resourceType === RESOURCE_TYPE_Mesh)
        {
            window.LR_ImportMesh({ });
        }
        else if (this.state.resourceType === RESOURCE_TYPE_FixtureType)
        {
            window.LR_ImportGdtf({ });
        }
        else if (this.state.resourceType === RESOURCE_TYPE_SymbolDef)
        {
            window.LR_AddNewSymbolDefinition();
        }
    }


    setUpCallbacks()
    {
        globalCallbacks.showInsertDropModal = async (args) =>
        {
            globalCallbacks.PlaceStructureReloadResource()
            this.show(args);
        }
    }
}


export default InsertDropComponent;