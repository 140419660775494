//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Segment, Header, Icon, Button, Form, Message } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/ShareProject";
import LRModal from '../Basics/BasicModal';
import { globalCallbacks } from "../../util/callback";
import { QRCodeCanvas } from 'qrcode.react';

//-----------------------------------------------------------------------------
// The component for the TreeControl
class ShareModal extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
      PublicShareLink: ""
    }
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }

  show = async() => 
  {
    let info = await window.LR_GetLinkedProject()
    let res2 = await window.LR_GetBaseURL()
    let shareLinks = await window.LR_GetShareLinksForProject()

    let options = []
    this.SHARE_LINK_MAP = {}

    if(Array.isArray(shareLinks))
    {
      options = shareLinks.map((e)=>
      {
        this.SHARE_LINK_MAP[e.name] = e.token

        return {
        key: e.name,
        text: e.name,
        value:e.name
      }})
    }

    options.push(
      {
        text:"<None>",
        value: "",
        key:"<None>"
      })


    this.setState( { 
        open : true, 
        Project: info.Project,
        Owner: info.Owner,
        URL: res2.URL,
        PublicShareLinkOptions: options
      });
  }

  close = () => 
  {
    this.setState({open : false});
  }
    
  render() 
  {
    let open = this.state.open;
    if(!open) {return <div/>}

    return (
      <LRModal  open={open} 
                onCancelClick={this.close} 
                closeOnDimmerClick={false} 
                size="mini"
                scrolling={false}
                title={LocalizedStrings.Header}
                noCancel
                onOkClick={this.close}>
                {this.state.Owner !== "" ? this.renderProject() : this.renderNoProject()}
         
      </LRModal>
    )
  }

  renderNoProject()
  {
    return  <Segment placeholder>
    <Header icon>
      <Icon name='times rectangle' color='red' />
      {LocalizedStrings.NoOnlineProject}
    </Header>
    <Button primary onClick={()=>{this.close(); window.LR_CommitToServer()}}> {LocalizedStrings.Commit}</Button>
  </Segment>
  }

  renderProject()
  {
    let href  = this.state.URL + "/" + this.state.Owner + "/" + this.state.Project

    if(this.state.PublicShareLink !== "")
    {
      href += "?sharetoken="
      href += this.SHARE_LINK_MAP[ this.state.PublicShareLink ]
    }

    
    return <>

      <QRCodeCanvas style={{height: "20em", width:"20em", margin:"0.4em"}}value={href} />
    <Form>
      <Form.Select
                  onAddItem={this.createShareLink}
                  search
                  allowAdditions={true}
                  label={LocalizedStrings.PublicShareLink}
                  value={this.state.PublicShareLink}
                  fluid
                  compact
                  options={this.state.PublicShareLinkOptions}
                  onChange={(_, { value }) => {
                    this.setState({ PublicShareLink: value });
                  }}
                />
      <Form.Input label={LocalizedStrings.ProjectLink} value={href} readOnly></Form.Input>
      <Button
      fluid
      onClick={()=>
        navigator.clipboard.writeText(href)
      }>
      {LocalizedStrings.Copy}
      </Button>
              </Form>
              <Message>
    <Message.Header>{LocalizedStrings.ShareHeader}</Message.Header>
    {LocalizedStrings.ShareText}
    </Message>
    </>
  }

  createShareLink = async(e, data) =>
  {
    await window.LR_CreateShareLinkForProject({Name: data.value})
    this.show()
  }

  setUpCallbacks()
  {

    globalCallbacks.ShowCreateShowLink = () => 
    { 
      this.show();
    }
    
  }
}

export default ShareModal