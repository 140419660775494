//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dimmer, Header, Icon, Loader, Segment } from 'semantic-ui-react';
import LocalizedStrings from '../../localization/TableViewComponent';
import { globalCallbacks } from '../../util/callback';
import { globalWindowInterface, lrObjectInterface, uuidStr } from '../../util/callbackTypes';
import { EMPTY_UUID } from '../../util/defines';
import ObjectPropertiesComponent from '../ObjectProperties/ObjectPropertiesComponent';

declare const window: globalWindowInterface

function MainRender({ uuid }: { uuid: uuidStr }) {
  if (uuid && uuid !== EMPTY_UUID) {
    return <ObjectPropertiesComponent UUID={uuid} />
  } else {
    return (
      <Segment placeholder textAlign="center">
        <Header icon>
          <Icon name="rain" />
          {LocalizedStrings.ObjectNotFound}
        </Header>
      </Segment>
    )
  }
}

export default function SingleObjectView(this: unknown) {
  let uuid = useParams().uuid
  let [isFetching, setIsFetching] = useState(true)
  let [currObject, setCurrObject] = useState<lrObjectInterface>(undefined)

  const updateObject = async () => {
    setCurrObject(await window.LR_GetObject({ UUID: uuid }))
  }

  useEffect(() => {
    updateObject().then(() => setIsFetching(false))
    globalCallbacks.drawingChangedObjectView = updateObject
  }, [uuid])

  return (
    <>
      <Dimmer active={isFetching}>
        <Loader />
      </Dimmer>
      <MainRender uuid={currObject?.UUID} />
    </>
  )
}