
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Menu, Icon, Tab, } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/LightingPaperwork";

import CueList from "./component/CueList";
import FocusList from "./component/FocusList";
import PresetList from "./component/PresetList";
import TrackingList from "./component/TrackingList";
const TAB_PAGE_CUELIST  = 0
const TAB_PAGE_TRACKING = 1
const TAB_PAGE_PRESET   = 2
const TAB_PAGE_FOCUS    = 3

class LightingPaperwork extends Component 
{
  constructor(props)
  {
    super(props);

    this.state = 
    {
      activeTab : TAB_PAGE_CUELIST,
    };

    this.panes = 
    [
        { tab: TAB_PAGE_CUELIST,   menuItem: <Menu.Item key={0}><Icon name='list ol' />{LocalizedStrings.CueList}</Menu.Item>, render: () => <Tab.Pane><CueList/></Tab.Pane> },
        { tab: TAB_PAGE_TRACKING,  menuItem: <Menu.Item key={1}><Icon name='lightbulb' />{LocalizedStrings.TrackingByUnit}</Menu.Item>,   render: () => <Tab.Pane><TrackingList/></Tab.Pane> },
        { tab: TAB_PAGE_PRESET,    menuItem: <Menu.Item key={2}><Icon name='edit' />{LocalizedStrings.PresetList}</Menu.Item>,   render: () => <Tab.Pane><PresetList/></Tab.Pane> },
        { tab: TAB_PAGE_FOCUS,     menuItem: <Menu.Item key={2}><Icon name='target' />{LocalizedStrings.PresetFocus}</Menu.Item>,   render: () => <Tab.Pane><FocusList/></Tab.Pane> },
    ]
  }

  render() 
  {
    window.document.title = "Lighting Paperwork"
    return (
            <Tab panes={this.panes} activeIndex={this.state.activeTab} onTabChange={(event, {activeIndex})=>{this.setActiveTab(activeIndex)}}/>
    );
  }


  setActiveTab = (tabIndex) =>
  {
    this.setState({activeTab:tabIndex})
  }


}

export default LightingPaperwork;

