//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import LocalizedStrings from "../../../localization/TableViewComponent";
import { BASE_UNIT_LENGTH } from '../../../util/defines';
import UnitInput from '../../Basics/BasicUnitInput';
import DynamicTable from '../../Basics/DynamicTableView';

import { globalCallbacks as mainCB } from '../../../util/callback';
import { globalCallbacks as mockCB } from '../../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB



class GelList extends Component
{
    constructor(prop)
    {
        super(prop);
        this.state = 
        {
            gelEntries: [],
            gelRolls: []
        }        

        this.objectHeaderIdents = [{ident: "Count", label: LocalizedStrings.Count},
                                    {ident: "GelName", label: LocalizedStrings.GelName},
                                    {ident: "GelManufacturer", label: LocalizedStrings.GelManufacturer},
                                    {ident: "GelSize", label: LocalizedStrings.GelSize, unit: BASE_UNIT_LENGTH},
                                    {ident: "GelId", label: LocalizedStrings.GelId}]

        this.rollsHeaderIdents = [{ident: "GelName", label: LocalizedStrings.GelName},
                                    {ident: "GelManufacturer", label: LocalizedStrings.GelManufacturer},
                                    {ident: "GelRollSize", label: LocalizedStrings.GelRollSize, unit: BASE_UNIT_LENGTH},
                                    {ident: "CountRolls", label: LocalizedStrings.CountRolls}]
    }

    componentDidMount()
    {
        this.setUpCallbacks()
        globalCallbacks.updateGelList()
    }

    render()
    {
        let objectCellRendering = (entry, rowIndex) =>
        {
            return {
                Count: entry.Count,
                GelName: entry.GelName,
                GelId: entry.GelID,
                GelManufacturer: entry.GelManufacturer,
                GelSize: <Form widths="equal">
                <Form.Group>                                
                    <UnitInput baseUnit={BASE_UNIT_LENGTH} noStepper transparent readOnly label={false} value={entry.FrameSizeX}/>
                    {"x"}
                    <UnitInput baseUnit={BASE_UNIT_LENGTH} noStepper transparent readOnly label={false} value={entry.FrameSizeY}/>
                </Form.Group>                            
            </Form>
            }
        }

        let rollsCellRendering = (entry, rowIndex) =>
        {
            return {
                GelName: entry.GelName,
                GelId: entry.GelID,
                GelManufacturer: entry.GelManufacturer,
                CountRolls: entry.CountRolls,
                GelRollSize: <Form widths="equal">
                                <Form.Group>                                
                                    <UnitInput baseUnit={BASE_UNIT_LENGTH} noStepper transparent readOnly label={false} value={entry.GelRollSizeX}/>
                                    {"x"}
                                    <UnitInput baseUnit={BASE_UNIT_LENGTH} noStepper transparent readOnly label={false} value={entry.GelRollSizeY}/>
                                </Form.Group>                            
                            </Form>
            }
        }

        return (
            <>
            <DynamicTable   headerIdent={this.objectHeaderIdents}
                            cellRender={objectCellRendering}
                            headerData={this.props.headerData}
                            tableName="GelListObject"
                            pages={this.props.pages}
                            showPages={this.props.showPages}
                            fitColumnWidthToContent={this.props.showPages}
                            worksheet={this.props.worksheet}
                            cellData={this.state.gelEntries}
                            printMargin={this.props.printMargin}
                            printScale={this.props.worksheetScale}/>
            <DynamicTable   headerIdent={this.rollsHeaderIdents}
                            cellRender={rollsCellRendering}
                            headerData={this.props.headerData}
                            tableName="GelListRolls"
                            pages={this.props.pages}
                            showPages={this.props.showPages}
                            fitColumnWidthToContent={this.props.showPages}
                            worksheet={this.props.worksheet}
                            cellData={this.state.gelRolls}
                            printMargin={this.props.printMargin}
                            printScale={this.props.worksheetScale}/>
            </>
        )
    }
   
    setUpCallbacks()
    {
        globalCallbacks.updateGelList = async () =>
        {
            let gelList = await window.LR_GetGelList()
            let gelRolls = await window.LR_GetGelRolls()
            
            this.setState({gelEntries: gelList.GelRows, gelRolls: gelRolls.GelRolls})
        }
    }
}

export default GelList;