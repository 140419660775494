
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Icon, Input, Table, Popup } from 'semantic-ui-react'
import LocalizedStrings from '../../localization/SceneTimeLineComponent';
import {ToTimeCode} from '../../util/time'
import UnitInput from '../Basics/BasicUnitInput';
import { BASE_UNIT_DATE, IsDarkTheme } from '../../util/defines';
import { cie2hex } from '../ColorPicker/utilities';
import WorksheetRow from './WorksheetRow';
import { FRAME_COUNT } from '../../util/DateHelper';
import { forceGlobalAnimationStop } from '../Renderer/Animation';



class TimePhase extends Component 
{

  constructor(props)
  {
    super(props);
    this.state = 
    {
      name  : undefined,
      coloredPlayButton: false,
    };
    this.timeoutObj = -1
  }
  render() 
  {
    let isDark = IsDarkTheme()
    let change = this.props.change 
    let icon = change.Expanded ? "caret down" : "caret right"
    if(!change.LinkedWorksheetArray.length){
      icon = null
    }
    return(
        <React.Fragment>
            <Table.Row  draggable       = {true}
                        onDragStart     = {(e) => {if (this.props.onDragStart) {this.props.onDragStart()}e.stopPropagation();}}
                        onDragEnter     = {(e) => {if (this.props.onDragEnter) {this.props.onDragEnter()}e.stopPropagation();}}
                        onDragEnd       = {(e) => {if (this.props.onDragEnd) {this.props.onDragEnd()} e.stopPropagation();}}
                        style           = {{"backgroundColor": cie2hex({fx: change.Color.X, fy: change.Color.Y, f_Y: change.Color.Z}) === "#ffffff" ? (isDark ? "#222" : "") : cie2hex({fx: change.Color.X, fy: change.Color.Y, f_Y: change.Color.Z})}}
                        >
                <Table.Cell collapsing >
                <Popup trigger={<Icon link name="play circle outline" color={this.state.coloredPlayButton ? "orange" : null} onClick = {() => this.playTimelineUntil()}/>}>
                  <p>{LocalizedStrings.PlayBackTimeLineStep}</p>
                  <p><b>{LocalizedStrings.Timecode} : </b>{ ToTimeCode(change.TimeCode)}</p>
                  <p><b>{LocalizedStrings.FadeIn}   : </b>{change.FadeIn}</p>
                  <p><b>{LocalizedStrings.FadeOut}  : </b>{change.FadeOut}</p>
                  <p><b>{LocalizedStrings.DelayIn}  : </b>{change.DelayIn}</p>
                  <p><b>{LocalizedStrings.DelayOut} : </b>{change.DelayOut}</p>
                </Popup>
                </Table.Cell>
                <Table.Cell collapsing>
                <Popup content={LocalizedStrings.BarsTimeLineStep}
                trigger={
                  <Icon name="bars"/>
                }/>
                </Table.Cell>
                <Table.Cell collapsing>
                <Popup content={LocalizedStrings.ExpandWorksheets}
                      trigger={
                        <Icon name={icon} onClick={()=>this.onCollapse()}/>
                }/>
                </Table.Cell>
                <Table.Cell>
                <Input  size      = "large"
                        name      = "Name" 
                        value     = {this.getInputEntry()}
                        transparent
                        onChange  = {(e, {value}) => this.onValueChanged(value)} 
                        onBlur    = {this.onBlur}
                        onKeyDown = {(e) => {if(e.keyCode === 13){this.onBlur(); e.target.blur()}}}
                        style     = {{width: "calc(100% - 40px)"}}
                    />    
                </Table.Cell>
                <Table.Cell collapsing textAlign="center">
                  <UnitInput label={false} readOnly timeOnly value={change.StartDate} baseUnit={BASE_UNIT_DATE}/>                  
                </Table.Cell>
                <Table.Cell collapsing textAlign="center">
                  <UnitInput label={false} readOnly timeOnly value={change.EndDate} baseUnit={BASE_UNIT_DATE}/>                  
                </Table.Cell>

                <Table.Cell textAlign="center" collapsing>
                  <Popup content={LocalizedStrings.TimeLineStepEdit}
                  trigger={                      
                      <Icon name='edit' onClick={() => this.openEditTimeLineStepChange()}/>      
                    }/>    
                </Table.Cell> 
                <Table.Cell textAlign="center" collapsing>
                    <Popup content={LocalizedStrings.TimeLineStepDelete} 
                    trigger={                      
                      <Icon name='delete' color='red' onClick={() => this.onDeleteTimePhaseChange()}/>            
                    }/>
                </Table.Cell>
            </Table.Row>
            {
              change.Expanded ? 
                change.LinkedWorksheetArray.map((worksheet, i)=>{
                  return <WorksheetRow key={i} worksheet={worksheet}/>
                }) : null
            }
        </React.Fragment>
    );
  }

  onCollapse() {
    window.LR_SetTimePhaseChange({ UUID : this.props.change.UUID, Expanded: !this.props.change.Expanded });
  }

  openEditTimeLineStepChange = () =>
  {
    if(this.props.openEditTimeLineStepChange){
      this.props.openEditTimeLineStepChange(this.props.change.UUID)
    }
  }

  onValueChanged = (value) =>
  {
    this.setState({name:value})
  }
  
  onBlur = () =>
  {
    window.LR_SetTimePhaseChange({UUID: this.props.change.UUID, Name: this.state.name});
    this.setState({name:undefined})
  }

  getInputEntry = () =>
  {
    return this.state.name ? this.state.name : this.props.change.Name
  }

  onDeleteTimePhaseChange = () =>
  {
    window.LR_DeleteTimePhaseChange({UUID: this.props.change.UUID});
  }

  playTimelineUntil = () =>
  {
    if(this.timeoutObj !== -1){
      clearTimeout(this.timeoutObj)
      this.timeoutObj = -1
      this.setState({coloredPlayButton: false})
      forceGlobalAnimationStop()
    }else{
      this.setState({coloredPlayButton: true})
      this.timeoutObj = setTimeout(() => {
        this.timeoutObj = -1
        this.setState({coloredPlayButton: false})
      }, this.props.change.FadeIn / FRAME_COUNT * 1000)
      window.LR_PlayTimelineUntil({ UUID  : this.props.change.UUID});
    }
  }
}

export default TimePhase;

