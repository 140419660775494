//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Form } from 'semantic-ui-react'
// @ts-ignore
import LocalizedStrings from "../../localization/RandomizeChainShorten";
import LRModal from '../Basics/BasicModal';
import UnitInput from '../Basics/BasicUnitInput';

import { globalCallbacks } from '../../util/callback';
import { BASE_UNIT_PAGE_LENGTH } from '../../util/defines';

declare const  window: any;


interface ChainShortenEffectModalState {
  open: Boolean
  Delta: number,
}

//-----------------------------------------------------------------------------
// The component for the TreeControl
class ChainShortenEffectModal extends Component<{}, ChainShortenEffectModalState> 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
      Delta: 30,
    }
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }

  show = async() => 
  {
    this.setState( { 
        open : true, 
      });
  }

  close = () => 
  {
    window.LR_CalculateChainShortenEffect({Delta: this.state.Delta})
    this.setState({open : false});
  }
    
  render() 
  {
    let open = this.state.open;
    if(!open) {return <div/>}

    return (
      <LRModal  open={open} 
                onCancelClick={this.close} 
                closeOnDimmerClick={false} 
                size="mini"
                scrolling={false}
                title={LocalizedStrings.HeaderEffect}
                customOKText={LocalizedStrings.Close}
                onOkClick={this.close}>
                  <Form>
                  <UnitInput name="Delta" label={LocalizedStrings.Delta} baseUnit={BASE_UNIT_PAGE_LENGTH} value={this.state.Delta} onStateUpdate={(name, val) => {this.setState({Delta: val})}}/>
                  </Form>
                  
      </LRModal>)
  }
                 
  setUpCallbacks()
  {
    globalCallbacks.ShowCalculateChainShortenEffect = async () => 
    {       
      this.show();
    } 
  }
}

export default ChainShortenEffectModal