//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Grid, Icon, Header, Button, Segment, Form, Message, Table, List, Tab } from 'semantic-ui-react'
// @ts-ignore
import LocalizedStrings from "../../localization/ConnectLoadCells";
import { BASE_UNIT_DATE, BASE_UNIT_NUMBER, BASE_UNIT_STRING } from '../../util/defines';
import LRModal from '../Basics/BasicModal';
import UnitInput from '../Basics/BasicUnitInput';
import { globalCallbacks } from '../../util/callback';

declare const  window: any;


interface LoadCell {

    Name: String,
    UUID: String,
    RefreshRate: Number,
    IP: String,
    Port: Number,
    Running: Boolean,
}
interface ConnectLoadCallModalState {
  open: Boolean
  IP: String,
  user: string,
  password: string,
  Port: Number,
  StorePassword: boolean,
  Connections: LoadCell[]
}

//-----------------------------------------------------------------------------
// The component for the TreeControl
class ConnectLoadCallModal extends Component<{}, ConnectLoadCallModalState> 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
      IP:"127.0.0.1",
      Port:80,
      Connections: [],
      user: "",
      password: "",
      StorePassword: false,
    }
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }

  show = async() => 
  {
    this.setState( { 
        open : true, 
      });
  }

  close = () => 
  {
    this.setState({open : false});
  }
    
  render() 
  {
    let open = this.state.open;
    if(!open) {return <div/>}

    const panes = [
      { menuItem: 'Broadweigh', render: () => this.renderBroadWeight() },
      { menuItem: 'Flexa IOT', render: () => this.renderFlexaIOT() },
      { menuItem: 'Show Distribution', render: () => this.renderShowDistribution() },
    ]

    return (
      <LRModal  open={open} 
                onCancelClick={this.close} 
                closeOnDimmerClick={false} 
                size="fullscreen"
                scrolling={false}
                title={LocalizedStrings.Header}
                customOKText={LocalizedStrings.Close}
                onOkClick={this.close}>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column width="12">
                        <Table>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>{LocalizedStrings.Number}</Table.HeaderCell>
                            <Table.HeaderCell>{LocalizedStrings.Name}</Table.HeaderCell>
                            <Table.HeaderCell>{LocalizedStrings.IP}</Table.HeaderCell>
                            <Table.HeaderCell>{LocalizedStrings.Port}</Table.HeaderCell>
                            <Table.HeaderCell>{LocalizedStrings.CountCells}</Table.HeaderCell>
                            <Table.HeaderCell>{LocalizedStrings.Cells}</Table.HeaderCell>
                            <Table.HeaderCell>{LocalizedStrings.LastSeen}</Table.HeaderCell>
                            <Table.HeaderCell>{LocalizedStrings.Actions}</Table.HeaderCell>
                          </Table.Row>
                          </Table.Header>
                          <Table.Body>
                          {this.state.Connections.map((e, i)=> {return this.renderRow(e,i+1)})}
                          </Table.Body>
                        </Table>
                      </Grid.Column>
                      <Grid.Column width="4">
                        <Tab panes={panes} />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  
      </LRModal>
    )
  }

  renderFlexaIOT()
  {
    return<>
        <Message>
          <Message.Header>{LocalizedStrings.ConnectionDetailHeaderFlexa}</Message.Header>
          {LocalizedStrings.ConnectionDetailTextFlexa}
        </Message>
        <Segment>
          <Header as="h3">{LocalizedStrings.Connect}</Header>
        <Form>
        <Form.Input 
                name="user"
                inverted
                value={this.state.user}
                fluid
                icon="user"
                iconPosition="left"
                label={LocalizedStrings.Email}
                onChange={(_, {value})=>this.setState({user: value})}
              />
              <Form.Input
                name="password"
                type="password"
                value={this.state.password}
                fluid
                icon="lock"
                iconPosition="left"
                label={LocalizedStrings.Password}
                onChange={(_, {value})=>this.setState({password: value})}
              />        
              <Form.Checkbox  label={LocalizedStrings.StoreLogin} checked={this.state.StorePassword} onChange={(_, {checked}) => {this.setState({StorePassword:checked})}}/>

              </Form>
        </Segment>
        <Button onClick={()=>{
          window.LR_AddNewLog100Connection({
            FlexaUserName: this.state.user,
            FlexaPassword: this.state.password,
            StorePassword: this.state.StorePassword,
            LoadCellType: 1
          })
        }} fluid positive>{LocalizedStrings.Connect}</Button>
    </>

    
  }

  renderBroadWeight()
  {
  return <>
    <Message>
    <Message.Header>{LocalizedStrings.ConnectionDetailHeader}</Message.Header>
    {LocalizedStrings.ConnectionDetailText}
  </Message>
  <Segment>
    <Header as="h3">{LocalizedStrings.Connect}</Header>
  <Form>

  <UnitInput baseUnit={BASE_UNIT_STRING}  onUpdateData  = {(name, value) => {this.setState({IP: value})}} value={this.state.IP} label={LocalizedStrings.IP}/>
  <UnitInput  baseUnit={BASE_UNIT_NUMBER}  onUpdateData  = {(name, value) => {this.setState({Port: value})}}  value={this.state.Port} label={LocalizedStrings.Port}/>
  </Form>
  </Segment>
  <Button onClick={()=>{
    window.LR_AddNewLog100Connection({
      IP: this.state.IP,
      Port: this.state.Port,
      LoadCellType: 0
    })
  }} fluid positive>{LocalizedStrings.Connect}</Button>
    </>
  }

  renderShowDistribution()
  {
  return <>
    <Message>
    <Message.Header>{LocalizedStrings.ConnectionDetailHeaderSD}</Message.Header>
    {LocalizedStrings.ConnectionDetailTextSD}
  </Message>
  <Segment>
    <Header as="h3">{LocalizedStrings.Connect}</Header>
  <Form>

  <UnitInput baseUnit={BASE_UNIT_STRING}  onUpdateData  = {(name, value) => {this.setState({IP: value})}} value={this.state.IP} label={LocalizedStrings.IP}/>
  <UnitInput  baseUnit={BASE_UNIT_NUMBER}  onUpdateData  = {(name, value) => {this.setState({Port: value})}}  value={this.state.Port} label={LocalizedStrings.Port}/>
  </Form>
  </Segment>
  <Button onClick={()=>{
    window.LR_AddNewLog100Connection({
      IP: this.state.IP,
      Port: this.state.Port,
      LoadCellType: 2
    })
  }} fluid positive>{LocalizedStrings.Connect}</Button>
    </>
  }

  renderRow(entry, number)
  {
    let lastseenDiff = Date.parse(entry.LastSeen) - Date.now()
    

    return(<Table.Row>
    <Table.Cell>#{number}</Table.Cell>
    <Table.Cell>{entry.Name}</Table.Cell>
    <Table.Cell>{entry.IP}</Table.Cell>
    <Table.Cell>{entry.Port}</Table.Cell>
    <Table.Cell>{entry.CountCells}</Table.Cell>
    <Table.Cell><List>{entry.CellNames.map(e=><List.Item onClick={()=>{navigator.clipboard.writeText(e)}}>{e}</List.Item>)}</List></Table.Cell>
    <Table.Cell><Icon name='circle' color={lastseenDiff < 1000 ? "green":"red"}/> <UnitInput readOnly baseUnit={BASE_UNIT_DATE} value={ entry.LastSeen} /> </Table.Cell>
    <Table.Cell>
      {
      !entry.Running ? 
      <Button positive onClick={()=>{window.LR_SetLog100Connections({UUID: entry.UUID, Running: !entry.Running})}}>{LocalizedStrings.StartConnection}</Button> :
      <Button primary onClick={()=>{window.LR_SetLog100Connections({UUID: entry.UUID, Running: !entry.Running})}}>{LocalizedStrings.StopConnection}</Button>
      }
      
      <Button negative onClick={()=>{window.LR_DeleteLog100Connections({UUID: entry.UUID})}}>{LocalizedStrings.ActionRemove}</Button>
    </Table.Cell>
    </Table.Row>)

  }

  setUpCallbacks()
  {
    globalCallbacks.RefreshLoadCellData = async () => 
    { 
      let connections = await window.LR_GetLog100Connections()

      this.setState({Connections: connections.Connections})
    }

    globalCallbacks.ShowConnectLoadCells = async () => 
    { 
      
      this.show();
      globalCallbacks.RefreshLoadCellData();
      


    }


    
  }
}

export default ConnectLoadCallModal