//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';

import LocalizedStrings from "../../../localization/TableViewComponent";
import { EMPTY_UUID, HOIST_REPORT_YSORTING } from '../../../util/defines';


import { globalCallbacks as mainCB } from '../../../util/callback';
import { globalCallbacks as mockCB } from '../../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB

class  SupportFilter extends Component
{
    constructor(prop){
      super(prop)
      this.state = {
        origins : [],
        loaded: false,
        originsFilter: EMPTY_UUID + EMPTY_UUID
      }
      this.onFilterChange(EMPTY_UUID, EMPTY_UUID)

      globalCallbacks.updateAvailableOrigins = () => this.updateOrigins()

    }

    render()
    {
        let add = undefined
        if(this.props.hoistFilter === HOIST_REPORT_YSORTING)
        {
          add = <Form.Dropdown label={LocalizedStrings.OriginPoints} selection options={this.state.origins} fluid value={this.state.originsFilter} onChange={(_ , {value}) => { this.onFilterChange(value.slice(0, value.length/2), value.slice(value.length/2)); }}/>
        }          
        return(
        <>
          <h5>{LocalizedStrings.Filter}</h5>
          <Form.Input icon='search' placeholder={LocalizedStrings.Search} value={this.props.value} onChange={this.props.searchFieldUpdate} />
          <Form.Dropdown label={LocalizedStrings.HoistSorting} selection options={this.props.hoistFilterOption} fluid value={this.props.hoistFilter} onChange={this.props.hoistFilterChange}></Form.Dropdown>
          {add}
        </>
          )
    }

  async updateOrigins(){
    let origins = await window.LR_GetAvailableOrigins({Async: true})
    origins = origins.map(v => {return {value: v.ParentUuid + v.GeometryUuid, text: v.text}})
    this.setState({ origins: [
      ...origins, 
      {
      value: EMPTY_UUID + EMPTY_UUID, 
      text: LocalizedStrings.GlobalCenter
    }] })
  }

  async onFilterChange(ParentUuid, GeometryUuid){
    this.setState({originsFilter: ParentUuid + GeometryUuid})
    window.LR_SetHoistOrigin({ParentUuid: ParentUuid, GeometryUuid: GeometryUuid})
  }

  componentDidMount() {
      this.updateOrigins()
  }

  componentDidUpdate(prevProps, prevState) {
      if (prevProps.sorting !== this.props.sorting) { this.updateOrigins() }
  }

}


export default SupportFilter;