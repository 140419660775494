//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import LocalizedStrings from "../../localization/AboutDialog";
import LRModal from '../Basics/BasicModal';
import CONST from '../../webApp/CONST';
import { Message } from 'semantic-ui-react'
import { IsRunningAsBrowser } from '../../util/defines';


//-----------------------------------------------------------------------------
// The component for the TreeControl
class VersionConflict extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
    }
  }

  componentDidMount = async () => 
  {
    if(IsRunningAsBrowser()) {return}

    let info = await window.LR_GetVersionInfo()
    this.setState( { 
        open : info.VersionNumber !== CONST.VERSION || CONST.BUILD !== info.BuildNumber, 
        BuildNumber: info.BuildNumber  !== CONST.VERSION,
        VersionNumber: info.VersionNumber,
      });
  }

  close = () => 
  {
    this.setState({open : false});
  }
    
  render() 
  {
    let open = this.state.open;
    if(!open) {return null}

    return (
      <LRModal  open={open} 
                onCancelClick={this.close} 
                closeOnDimmerClick={false} 
                size="mini"
                scrolling={false}
                title={LocalizedStrings.VersionConflict}
                noCancel
                onOkClick={this.close}>
                  <Message negative>
                    <Message.Header>{LocalizedStrings.VersionConflict}</Message.Header>
                    <Message.Content>
                      {LocalizedStrings.VersionConflictText1}
                      {this.state.VersionNumber}
                      {" "}
                      {this.state.BuildNumber}
                      <br/>
                      {LocalizedStrings.VersionConflictText2}
                      {CONST.VERSION}
                      {" "}
                      {CONST.BUILD}
                      <br/>
                      <br/>
                      {LocalizedStrings.VersionConflictText3}
                      </Message.Content>
                  </Message>
      </LRModal>
    )
  }

  setUpCallbacks()
  {
    
  }
}

export default VersionConflict