/*
 * Autodesk 3DS three.js file loader, based on lib3ds.
 *
 * Loads geometry with uv and materials basic properties with texture support.
 *
 * @author @tentone
 * @author @timknip
 * @class TDSLoader
 * @constructor
 */

import { lrServerConnection } from '../../../redux/light_right_server_connection';
import * as THREE from "three"

export default class TDSLoader {

	constructor(manager, token, textureMap)
	{
		this.manager = ( manager !== undefined ) ? manager : THREE.DefaultLoadingManager;
		this.debug = false;
	
		this.group = null;
		this.position = 0;
	
		this.materials = [];
		this.meshes = [];
		this.textureCache = textureMap;

		this.token = token;

		THREE.Cache.enabled = true;
	}

	/**
	 * Load 3ds file from url.
	 *
	 * @method load
	 * @param {[type]} url URL for the file.
	 * @param {Function} onLoad onLoad callback, receives group Object3D as argument.
	 * @param {Function} onProgress onProgress callback.
	 * @param {Function} onError onError callback.
	 */
	load( url, onLoad, onProgress, onError ) {

		var path = this.path !== undefined ? this.path : THREE.LoaderUtils.extractUrlBase( url );

		var loader = new THREE.FileLoader( this.manager );

		loader.setResponseType( 'arraybuffer' );

		if (this.token)
		{
			loader.setWithCredentials(true);
			loader.setRequestHeader({'Authorization': `Bearer ${this.token}`});
		}

		loader.load( url, function ( data ) {

			onLoad( this.parse( data, path ) );

		}, onProgress, onError );

	}

	loadMesh(uuid, onLoad) {
		lrServerConnection.coreCall("LR_GetMesh",{UUID: uuid})
		.then(meshObj => {
			try
			{
				onLoad(this.parse(meshObj.MeshBuffer))
			}
			catch(e)
			{
				console.error(e)
			}
			
		})
	}

	/**
	 * Parse arraybuffer data and load 3ds file.
	 *
	 * @method parse
	 * @param {ArrayBuffer} arraybuffer Arraybuffer data to be loaded.
	 * @param {String} path Path for external resources.
	 * @return {Object3D} Group loaded from 3ds file.
	 */
	parse ( arraybuffer, path ) {

		this.group = new THREE.Group();
		this.position = 0;
		this.materials = [];
		this.meshes = [];

		this.readFile( arraybuffer, path );

		for ( var o = 0; o < this.meshes.length; o ++ ) {

			this.group.add( this.meshes[ o ] );

		}

		return this.group;

	}

	/**
	 * Decode file content to read 3ds data.
	 *
	 * @method readFile
	 * @param {ArrayBuffer} arraybuffer Arraybuffer data to be loaded.
	 */
	readFile ( arraybuffer, path ) {

		var data = new DataView( arraybuffer );
		var chunk = this.readChunk( data );

		if ( chunk.id === MLIBMAGIC || chunk.id === CMAGIC || chunk.id === M3DMAGIC ) {

			var next = this.nextChunk( data, chunk );

			while ( next !== 0 ) {

				if ( next === M3D_VERSION ) {

					var version = this.readDWord( data );
					this.debugMessage( '3DS file version: ' + version );

				} else if ( next === MDATA ) {

					this.resetPosition( data );
					this.readMeshData( data, path );

				} else {

					this.debugMessage( 'Unknown main chunk: ' + next.toString( 16 ) );

				}

				next = this.nextChunk( data, chunk );

			}

		}

		this.debugMessage( 'Parsed ' + this.meshes.length + ' meshes' );

	}

	/**
	 * Read mesh data chunk.
	 *
	 * @method readMeshData
	 * @param {Dataview} data Dataview in use.
	 */
	readMeshData ( data, path ) {

		var chunk = this.readChunk( data );
		var next = this.nextChunk( data, chunk );

		while ( next !== 0 ) {

			if ( next === MESH_VERSION ) {

				var version = + this.readDWord( data );
				this.debugMessage( 'Mesh Version: ' + version );

			} else if ( next === MASTER_SCALE ) {

				var scale = this.readFloat( data );
				this.debugMessage( 'Master scale: ' + scale );
				this.group.scale.set( scale, scale, scale );

			} else if ( next === NAMED_OBJECT ) {

				this.debugMessage( 'Named Object' );
				this.resetPosition( data );
				this.readNamedObject( data );

			} else if ( next === MAT_ENTRY ) {

				this.debugMessage( 'Material' );
				this.resetPosition( data );
				this.readMaterialEntry( data, path );

			} else {

				this.debugMessage( 'Unknown MDATA chunk: ' + next.toString( 16 ) );

			}

			next = this.nextChunk( data, chunk );

		}

	}

	/**
	 * Read named object chunk.
	 *
	 * @method readNamedObject
	 * @param {Dataview} data Dataview in use.
	 */
	readNamedObject ( data ) {

		var chunk = this.readChunk( data );
		var name = this.readString( data, 64 );
		chunk.cur = this.position;

		var next = this.nextChunk( data, chunk );
		while ( next !== 0 ) {

			if ( next === N_TRI_OBJECT ) {

				this.resetPosition( data );
				var mesh = this.readMesh( data );
				mesh.name = name;
				this.meshes.push( mesh );

			} else {

				this.debugMessage( 'Unknown named object chunk: ' + next.toString( 16 ) );

			}

			next = this.nextChunk( data, chunk );

		}

		this.endChunk( chunk );

	}

	/**
	 * Read material data chunk and add it to the material list.
	 *
	 * @method readMaterialEntry
	 * @param {Dataview} data Dataview in use.
	 */
	readMaterialEntry ( data, path ) {

		var chunk = this.readChunk( data );
		var next = this.nextChunk( data, chunk );
		var material = new THREE.MeshLambertMaterial();

		while ( next !== 0 ) {

			if ( next === MAT_NAME ) {

				material.name = this.readString( data, 64 );
				this.debugMessage( '   Name: ' + material.name );

			} else if ( next === MAT_WIRE ) {

				this.debugMessage( '   Wireframe' );
				material.wireframe = true;

			} else if ( next === MAT_WIRE_SIZE ) {

				var value = this.readByte( data );
				material.wireframeLinewidth = value;
				this.debugMessage( '   Wireframe Thickness: ' + value );

			} else if ( next === MAT_TWO_SIDE ) {

				material.side = THREE.DoubleSide;
				this.debugMessage( '   DoubleSided' );

			} else if ( next === MAT_ADDITIVE ) {

				this.debugMessage( '   Additive Blending' );
				material.blending = THREE.AdditiveBlending;

			} else if ( next === MAT_DIFFUSE ) {

				this.debugMessage( '   Diffuse Color' );
				material.color = this.readColor( data );

			} else if ( next === MAT_SPECULAR ) {

				this.debugMessage( '   Specular Color' );
				material.specular = this.readColor( data );

			} else if ( next === MAT_AMBIENT ) {

				this.debugMessage( '   Ambient color' );
				material.color = this.readColor( data );

			} else if ( next === MAT_SHININESS ) {

				var shininess = this.readWord( data );
				material.shininess = shininess;
				this.debugMessage( '   Shininess : ' + shininess );

			} else if ( next === MAT_TEXMAP ) {

				this.debugMessage( '   ColorMap' );
				this.resetPosition( data );
				material.map = this.readMap( data, path );

			} else if ( next === MAT_BUMPMAP ) {

				this.debugMessage( '   BumpMap' );
				this.resetPosition( data );
				material.bumpMap = this.readMap( data, path );

			} else if ( next === MAT_OPACMAP ) {

				this.debugMessage( '   OpacityMap' );
				this.resetPosition( data );
				material.alphaMap = this.readMap( data, path );

			} else if ( next === MAT_SPECMAP ) {

				this.debugMessage( '   SpecularMap' );
				this.resetPosition( data );
				material.specularMap = this.readMap( data, path );

			} else {

				this.debugMessage( '   Unknown material chunk: ' + next.toString( 16 ) );

			}

			next = this.nextChunk( data, chunk );

		}

		this.endChunk( chunk );

		this.materials[ material.name ] = material;

	}

	/**
	 * Read mesh data chunk.
	 *
	 * @method readMesh
	 * @param {Dataview} data Dataview in use.
	 */
	readMesh ( data ) {

		var chunk = this.readChunk( data );
		var next = this.nextChunk( data, chunk );

		var geometry = new THREE.BufferGeometry();
		var uvs = [];

		var material = new THREE.MeshPhongMaterial();
		var mesh = new THREE.Mesh( geometry, material );
		mesh.name = 'mesh';

		while ( next !== 0 ) {

			if ( next === POINT_ARRAY ) {

				var points = this.readWord( data );

				this.debugMessage( '   Vertex: ' + points );

				//BufferGeometry

				var vertices = [];

				for ( var j = 0; j < points; j ++ )		{

					vertices.push( this.readFloat( data ) );
					vertices.push( this.readFloat( data ) );
					vertices.push( this.readFloat( data ) );

				}

				geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );

			} else if ( next === FACE_ARRAY ) {

				this.resetPosition( data );
				this.readFaceArray( data, mesh );

			} else if ( next === TEX_VERTS ) {

				var texels = this.readWord( data );

				this.debugMessage( '   UV: ' + texels );

				//BufferGeometry

				uvs = [];

				for ( var k = 0; k < texels; k ++ )		{

					uvs.push( this.readFloat( data ) );
					uvs.push( this.readFloat( data ) );

				}

				geometry.setAttribute( 'uv', new THREE.Float32BufferAttribute( uvs, 2 ) );


			} else if ( next === MESH_MATRIX ) {

				this.debugMessage( '   Tranformation Matrix (TODO)' );

				var values = [];
				for ( var i = 0; i < 12; i ++ ) {

					values[ i ] = this.readFloat( data );

				}

				var matrix = new THREE.Matrix4();

				//X Line
				matrix.elements[ 0 ] = values[ 0 ];
				matrix.elements[ 1 ] = values[ 6 ];
				matrix.elements[ 2 ] = values[ 3 ];
				matrix.elements[ 3 ] = values[ 9 ];

				//Y Line
				matrix.elements[ 4 ] = values[ 2 ];
				matrix.elements[ 5 ] = values[ 8 ];
				matrix.elements[ 6 ] = values[ 5 ];
				matrix.elements[ 7 ] = values[ 11 ];

				//Z Line
				matrix.elements[ 8 ] = values[ 1 ];
				matrix.elements[ 9 ] = values[ 7 ];
				matrix.elements[ 10 ] = values[ 4 ];
				matrix.elements[ 11 ] = values[ 10 ];

				//W Line
				matrix.elements[ 12 ] = 0;
				matrix.elements[ 13 ] = 0;
				matrix.elements[ 14 ] = 0;
				matrix.elements[ 15 ] = 1;

				matrix.transpose();

				var inverse = new THREE.Matrix4();
				inverse.getInverse( matrix, true );
				geometry.applyMatrix4( inverse );

				matrix.decompose( mesh.position, mesh.quaternion, mesh.scale );

			} else {

				this.debugMessage( '   Unknown mesh chunk: ' + next.toString( 16 ) );

			}

			next = this.nextChunk( data, chunk );

		}

		this.endChunk( chunk );

		geometry.computeVertexNormals();

		return mesh;

	}

	/**
	 * Read face array data chunk.
	 *
	 * @method readFaceArray
	 * @param {Dataview} data Dataview in use.
	 * @param {Mesh} mesh Mesh to be filled with the data read.
	 */
	readFaceArray ( data, mesh ) {

		var chunk = this.readChunk( data );
		var faces = this.readWord( data );

		this.debugMessage( '   Faces: ' + faces );

		var index = [];

		for ( var l = 0; l < faces; ++ l ) {

			index.push( this.readWord( data ), this.readWord( data ), this.readWord( data ) );

			this.readWord( data );

		}

		mesh.geometry.setIndex( index );

		//The rest of the FACE_ARRAY chunk is subchunks
		while ( this.position < chunk.end ) {

			var subChunk = this.readChunk( data );

			if ( subChunk.id === MSH_MAT_GROUP ) {

				this.debugMessage( '      Material Group' );

				this.resetPosition( data );

				var group = this.readMaterialGroup( data );

				var material = this.materials[ group.name ];

				if ( material !== undefined )	{

					mesh.material = material;

					if ( material.name === '' )		{

						material.name = mesh.name;

					}

				}

			} else {

				this.debugMessage( '      Unknown face array chunk: ' + chunk.toString( 16 ) );

			}

			this.endChunk( chunk );

		}

		this.endChunk( chunk );

	}

	/**
	 * Read texture map data chunk.
	 *
	 * @method readMap
	 * @param {Dataview} data Dataview in use.
	 * @return {Texture} Texture read from this data chunk.
	 */
	readMap ( data, path ) {

		var chunk = this.readChunk( data );
		var next = this.nextChunk( data, chunk );
		var texture = undefined;


		while ( next !== 0 ) {

			if ( next === MAT_MAPNAME ) {

				var name = this.readString( data, 128 );

				if(this.textureCache[name])
				{
					texture = this.textureCache[name].Texture; 
				}

				this.debugMessage( '      File: ' + path + name );

			} else if ( next === MAT_MAP_UOFFSET ) {

				texture.offset.x = this.readFloat( data );
				this.debugMessage( '      OffsetX: ' + texture.offset.x );

			} else if ( next === MAT_MAP_VOFFSET ) {

				texture.offset.y = this.readFloat( data );
				this.debugMessage( '      OffsetY: ' + texture.offset.y );

			} else if ( next === MAT_MAP_USCALE ) {

				texture.repeat.x = this.readFloat( data );
				this.debugMessage( '      RepeatX: ' + texture.repeat.x );

			} else if ( next === MAT_MAP_VSCALE ) {

				texture.repeat.y = this.readFloat( data );
				this.debugMessage( '      RepeatY: ' + texture.repeat.y );

			} else {

				this.debugMessage( '      Unknown map chunk: ' + next.toString( 16 ) );

			}

			next = this.nextChunk( data, chunk );

		}

		this.endChunk( chunk );

		return texture;

	}

	/**
	 * Read material group data chunk.
	 *
	 * @method readMaterialGroup
	 * @param {Dataview} data Dataview in use.
	 * @return {Object} Object with name and index of the object.
	 */
	readMaterialGroup ( data ) {

		this.readChunk( data );
		var name = this.readString( data, 64 );
		var numFaces = this.readWord( data );

		this.debugMessage( '         Name: ' + name );
		this.debugMessage( '         Faces: ' + numFaces );

		var index = [];
		for ( var m = 0; m < numFaces; ++ m ) {

			index.push( this.readWord( data ) );

		}

		return { name: name, index: index };

	}

	/**
	 * Read a color value.
	 *
	 * @method readColor
	 * @param {DataView} data Dataview.
	 * @return {Color} Color value read..
	 */
	readColor ( data ) {

		var chunk = this.readChunk( data );
		var color = new THREE.Color();

		if ( chunk.id === COLOR_24 || chunk.id === LIN_COLOR_24 ) {

			var rByte = this.readByte( data );
			var gByte = this.readByte( data );
			var bByte = this.readByte( data );

			color.setRGB( rByte / 255, gByte / 255, bByte / 255 );

			this.debugMessage( '      Color: ' + color.rByte + ', ' + color.gByte + ', ' + color.bByte );

		}	else if ( chunk.id === COLOR_F || chunk.id === LIN_COLOR_F ) {

			var r = this.readFloat( data );
			var g = this.readFloat( data );
			var b = this.readFloat( data );

			color.setRGB( r, g, b );

			this.debugMessage( '      Color: ' + color.r + ', ' + color.g + ', ' + color.b );

		}	else {

			this.debugMessage( '      Unknown color chunk: ' + chunk.toString( 16 ) );

		}

		this.endChunk( chunk );
		return color;

	}

	/**
	 * Read next chunk of data.
	 *
	 * @method readChunk
	 * @param {DataView} data Dataview.
	 * @return {Object} Chunk of data read.
	 */
	readChunk ( data ) {

		var chunk = {};

		chunk.cur = this.position;
		chunk.id = this.readWord( data );
		chunk.size = this.readDWord( data );
		chunk.end = chunk.cur + chunk.size;
		chunk.cur += 6;

		return chunk;

	}

	/**
	 * Set position to the end of the current chunk of data.
	 *
	 * @method endChunk
	 * @param {Object} chunk Data chunk.
	 */
	endChunk ( chunk ) {

		this.position = chunk.end;

	}

	/**
	 * Move to the next data chunk.
	 *
	 * @method nextChunk
	 * @param {DataView} data Dataview.
	 * @param {Object} chunk Data chunk.
	 */
	nextChunk ( data, chunk ) {

		if ( chunk.cur >= chunk.end ) {

			return 0;

		}

		this.position = chunk.cur;

		try {

			var next = this.readChunk( data );
			chunk.cur += next.size;
			return next.id;

		}	catch ( e ) {

			this.debugMessage( 'Unable to read chunk at ' + this.position );
			return 0;

		}

	}

	/**
	 * Reset dataview position.
	 *
	 * @method resetPosition
	 * @param {DataView} data Dataview.
	 */
	resetPosition () {

		this.position -= 6;

	}

	/**
	 * Read byte value.
	 *
	 * @method readByte
	 * @param {DataView} data Dataview to read data from.
	 * @return {Number} Data read from the dataview.
	 */
	readByte( data ) {

		var v = data.getUint8( this.position, true );
		this.position += 1;
		return v;

	}

	/**
	 * Read 32 bit float value.
	 *
	 * @method readFloat
	 * @param {DataView} data Dataview to read data from.
	 * @return {Number} Data read from the dataview.
	 */
	readFloat( data ) {

		try {

			var v = data.getFloat32( this.position, true );
			this.position += 4;
			return v;

		}	catch ( e ) {

			this.debugMessage( e + ' ' + this.position + ' ' + data.byteLength );

		}

	}

	/**
	 * Read 32 bit signed integer value.
	 *
	 * @method readInt
	 * @param {DataView} data Dataview to read data from.
	 * @return {Number} Data read from the dataview.
	 */
	readInt( data ) {

		var v = data.getInt32( this.position, true );
		this.position += 4;
		return v;

	}

	/**
	 * Read 16 bit signed integer value.
	 *
	 * @method readShort
	 * @param {DataView} data Dataview to read data from.
	 * @return {Number} Data read from the dataview.
	 */
	readShort( data ) {

		var v = data.getInt16( this.position, true );
		this.position += 2;
		return v;

	}

	/**
	 * Read 64 bit unsigned integer value.
	 *
	 * @method readDWord
	 * @param {DataView} data Dataview to read data from.
	 * @return {Number} Data read from the dataview.
	 */
	readDWord( data ) {

		var v = data.getUint32( this.position, true );
		this.position += 4;
		return v;

	}

	/**
	 * Read 32 bit unsigned integer value.
	 *
	 * @method readWord
	 * @param {DataView} data Dataview to read data from.
	 * @return {Number} Data read from the dataview.
	 */
	readWord( data ) {

		var v = data.getUint16( this.position, true );
		this.position += 2;
		return v;

	}

	/**
	 * Read string value.
	 *
	 * @method readString
	 * @param {DataView} data Dataview to read data from.
	 * @param {Number} maxLength Max size of the string to be read.
	 * @return {String} Data read from the dataview.
	 */
	readString( data, maxLength ) {

		var s = '';

		for ( var n = 0; n < maxLength; n ++ ) {

			var c = this.readByte( data );
			if ( ! c ) {

				break;

			}

			s += String.fromCharCode( c );

		}

		return s;

	}

	/**
	 * Set resource path used to determine the file path to attached resources.
	 *
	 * @method setPath
	 * @param {String} path Path to resources.
	 * @return Self for chaining.
	 */
	setPath( path ) {

		this.path = path;

		return this;

	}

	/**
	 * Print debug message to the console.
	 *
	 * Is controlled by a flag to show or hide debug messages.
	 *
	 * @method debugMessage
	 * @param {Object} message Debug message to print to the console.
	 */
	debugMessage( message ) {

		if ( this.debug ) {

			console.log( message );

		}

	}
}

var M3DMAGIC = 0x4D4D;
var MLIBMAGIC = 0x3DAA;
var CMAGIC = 0xC23D;
var M3D_VERSION = 0x0002;
var COLOR_F = 0x0010;
var COLOR_24 = 0x0011;
var LIN_COLOR_24 = 0x0012;
var LIN_COLOR_F = 0x0013;
var MDATA = 0x3D3D;
var MESH_VERSION = 0x3D3E;
var MASTER_SCALE = 0x0100;
var MAT_ENTRY = 0xAFFF;
var MAT_NAME = 0xA000;
var MAT_AMBIENT = 0xA010;
var MAT_DIFFUSE = 0xA020;
var MAT_SPECULAR = 0xA030;
var MAT_SHININESS = 0xA040;
var MAT_TWO_SIDE = 0xA081;
var MAT_ADDITIVE = 0xA083;
var MAT_WIRE = 0xA085;
var MAT_WIRE_SIZE = 0xA087;
var MAT_TEXMAP = 0xA200;
var MAT_OPACMAP = 0xA210;
var MAT_BUMPMAP = 0xA230;
var MAT_SPECMAP = 0xA204;
var MAT_MAPNAME = 0xA300;
var MAT_MAP_USCALE = 0xA354;
var MAT_MAP_VSCALE = 0xA356;
var MAT_MAP_UOFFSET = 0xA358;
var MAT_MAP_VOFFSET = 0xA35A;
var NAMED_OBJECT = 0x4000;
var N_TRI_OBJECT = 0x4100;
var POINT_ARRAY = 0x4110;
var FACE_ARRAY = 0x4120;
var MSH_MAT_GROUP = 0x4130;
var TEX_VERTS = 0x4140;
var MESH_MATRIX = 0x4160;
