//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Table, Icon, Input, Popup, Dropdown, Checkbox } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/NavigationContainer";
import { EMPTY_UUID } from '../../util/defines';

import { globalCallbacks as mainCB } from '../../util/callback';
import { globalCallbacks as mockCB } from '../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB
class LayerNode extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = this.props.layer;

        this.deleted = false;
    }

    componentDidUpdate(oldProps) 
    {
        const newProps = this.props;
        for(const [name, value] of Object.entries(newProps.layer))
        if(oldProps.layer[name] !== value) 
        {
            this.setState({[name]: value});
        }
    }

    render() 
    {
        return this.props.small ? this.renderActionsSmall() : this.renderActionsBig()
    }

    renderActionsBig = () =>
    {
        let layer = this.state;

        return (
            <Table.Row
                draggable={this.props.draggable}
                onDragStart={this.props.onDragStart}
                onDragEnter={this.props.onDragEnter}
                onDragEnd={this.props.onDragEnd}
                onDrop={this.props.onDrop}
            >
                <Table.Cell collapsing>
                    <Icon
                        name="bars"
                        style={{ cursor: "pointer" }}
                        color={this.props.draggable ? "black" : "grey"}
                    />
                </Table.Cell>
                <Table.Cell collapsing>
                    <Checkbox checked={this.props.checked} onChange={() => {this.props.onSelectLayerNode(layer.UUID, !this.props.checked)}}/>
                </Table.Cell>
                <Table.Cell key="1">
                    <Input  transparent
                            name      = "Name" 
                            value     = {layer.Name} 
                            onChange  = {this.onValueChanged} 
                            onBlur    = {() => this.updateData("Name", layer.Name)}
                            onKeyDown = {this.onKeyDown}
                            fluid/>
                </Table.Cell>
                <Table.Cell key="2" textAlign="center" collapsing>
                <Popup content={LocalizedStrings.Lock} 
                        trigger={
                            <Icon   name    = {layer.Locked ? "lock" : "lock open"}
                                    link
                                    onClick = {() => this.updateData("Locked", !layer.Locked)}
                                    />
                        }/>
                <Popup content={LocalizedStrings.Visibility}
                        trigger={
                                <Icon   name        = {layer.Visible || layer.UUID === this.props.activeUuid ? "eye" : "eye slash"}
                                        link        = {!layer.OnlyVisibleUsed}
                                        color       = {layer.UUID === this.props.activeUuid ? "blue" : layer.OnlyVisibleUsed ? "grey" : "black"}
                                        disabled    = {layer.OnlyVisibleUsed || layer.UUID === this.props.activeUuid}
                                        onClick     = {(event) => this.updateData("Visible", !layer.Visible, event)}
                                        />
                        }/>
                <Popup content={LocalizedStrings.OnlyVisibility}
                        trigger={
                                <Icon   name    = {layer.OnlyVisible ? "eye" : "eye slash outline"}
                                        link
                                        color   = {layer.OnlyVisible ? "green" : layer.OnlyVisibleUsed ? "black" : "grey"}
                                        onClick = {() => this.updateData("OnlyVisible", !layer.OnlyVisible)}
                                        />
                        }/>
            <Popup content={LocalizedStrings.Activate} 
                        trigger={
                            <Icon   name    = "check circle"
                                    link
                                    color   = {layer.UUID === this.props.activeUuid ? "green" : "black"}
                                    onClick = {() => {layer.UUID === this.props.activeUuid ? window.LR_SetActiveLayer({Layer: EMPTY_UUID}) : window.LR_SetActiveLayer({Layer: layer.UUID})}}/>
                        }/>
                <Popup content={LocalizedStrings.CreateWorksheet}
                        trigger={
                            <Icon   name    = "file outline"
                                    link
                                    onClick = {this.createWorksheet}
                                    />
                        }/>
            <Popup content={LocalizedStrings.DontUseForCalculation} 
                        trigger={
                            <Icon   name    = "pause circle"
                                    link
                                    color   = {layer.DontUseForCalculation ? "red" : "grey"}
                                    onClick = {() => this.updateData("DontUseForCalculation", !layer.DontUseForCalculation)}
                                    />
                        }/>
                <Popup content={LocalizedStrings.Delete}
                        trigger={
                            <Icon   name    = "delete"
                                    color   = "red"
                                    link
                                    onClick = {() => this.onDelete(layer.UUID)}
                                    />
                        }/>
            </Table.Cell>
        </Table.Row>)
    }

    renderActionsSmall = () =>
    {
        let layer = this.state;
        return  <Table.Row>
                    <Table.Cell key="1">
                        <Input  transparent
                                name      = "Name" 
                                value     = {layer.Name} 
                                onChange  = {this.onValueChanged} 
                                onBlur    = {() => this.updateData("Name", layer.Name)}
                                onKeyDown = {this.onKeyDown}
                                fluid/>
                    </Table.Cell>
                    <Table.Cell key="2" collapsing textAlign="center">
                        <Dropdown pointing="right" basic>
                            <Dropdown.Menu>
                                <Dropdown.Item  text = {LocalizedStrings.Lock_Header} 
                                                icon = {layer.Locked ? "lock" : "lock open"}
                                                onClick = {() =>{this.updateData("Locked", !layer.Locked)}}/>
                                <Dropdown.Item  text = {LocalizedStrings.Visibility_Header} 
                                                disabled = {layer.OnlyVisibleUsed}
                                                icon = {{ name: layer.Visible ? "eye" : "eye slash", 
                                                        color: layer.OnlyVisibleUsed ? "grey" : "black"}}
                                                onClick = {(event) => this.updateData("Visible", !layer.Visible, event)}/>
                                <Dropdown.Item  text = {LocalizedStrings.OnlyVisibility_Header}
                                                icon = {{ name: layer.OnlyVisible ? "eye" : "eye slash outline",
                                                        color: layer.OnlyVisible ? "green" : layer.OnlyVisibleUsed ? "black" : "grey"}}
                                                onClick = {() => {this.updateData("OnlyVisible", !layer.OnlyVisible)}}/>
                                <Dropdown.Item  text = {LocalizedStrings.Activate_Header}
                                                icon = {{ name: "check circle",
                                                        color: layer.UUID === this.props.activeUuid ? "green" : "black"}}
                                                onClick = {() => {layer.UUID === this.props.activeUuid ? window.LR_SetActiveLayer({Layer: EMPTY_UUID}) : window.LR_SetActiveLayer({Layer: layer.UUID})}}/>
                                <Dropdown.Item  text = {LocalizedStrings.OnlyVisibility_Header}
                                                icon = {{ name: "pause circl",
                                                        color: layer.DontUseForCalculation ? "red" : "grey"}}
                                                onClick = {() => {this.updateData("DontUseForCalculation", !layer.DontUseForCalculation)}}/>                
                                <Dropdown.Item  text = {LocalizedStrings.CreateWorksheet_Header}
                                                icon = "file outline"
                                                onClick = {this.createWorksheet}/>
                                <Dropdown.Item  text = {LocalizedStrings.Delete_Header} 
                                                icon = {{ name: "delete", 
                                                        color: "red"}}
                                                onClick = {() => {this.onDelete(layer.UUID)}}/>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Table.Cell>
                </Table.Row>
    }

    onValueChanged = (e, {name, value}) =>
    {
        this.setState({[name]:value});
    }

    createWorksheet = () =>
    {
        if (globalCallbacks.createWorksheetFromLayer)
        {
            globalCallbacks.createWorksheetFromLayer(this.state.UUID)
        }
    }

    updateData = (name, value, event) =>
    {
        let request = 
        {
            UUID: this.state.UUID
        };

        if(name)    { request[name] = value; }
        else        { request.Name  = this.state.Name; }

        if(event)
        {
            if(event.altKey)
            {
                request.ModifierKey = true;
                this.setState({Visible: true});
            }
            else
            {
                request.ModifierKey = false;
                this.setState({Visible: value});
            }
            
        }

        window.LR_SetLayer(request);
    }

    onKeyDown = (event) =>
    {
        if(event.keyCode === 13) { this.updateData(); event.target.blur() }
    }

    onDelete = (uuid) =>
    {
        let request = 
        {
            UUID: uuid,
        }

        //We use this test in case the UI didn't update fast enough (happened with big scenes)
        if(!this.deleted)
        {
            window.LR_DeleteLayer(request);
        }
        
        this.deleted = true;
    }

}

export default LayerNode;

