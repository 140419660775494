//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import {store} from '../store';
import {lrServerConnection} from '../light_right_server_connection'

import { REQUESTED_CALCULATIONS_ALL, REQUESTED_CALCULATIONS, PROJECT_REVIEW_TEMPLATE, CUSTOMER_INFO, REVIEW, ACTIVE_CHECK_PROJECT, ACTIVE_USER, SET, FETCH, PROJECTS, CHANGES, ACTIVE_PROJECT, CLEAR, EDIT_MODE, COLLABORATORS, ACTIVE_CHANGE, SLACK_CHANNELS, GLOBAL_SETTINGS, MEMBERS, USER_REVIEW_TEMPLATES, GROUPS, ADMINS, API_TOKENS, SHARE_LINKS, RESET, CUSTOM_PROJECT_DATA, ACTIVE_ADMIN, SHAREDLICENCE, MINI_PROJECTS, CONTENT_ADMINS} from "../redux_defines"

let cached = {
  user: null,
  project: null,
  refetched: []
}

let function_caller = (name) =>
{
  switch(name)
  {
    case PROJECTS               : return lrServerConnection.getProjects();
    case MINI_PROJECTS          : return lrServerConnection.getMiniProjects();
    case ACTIVE_USER            : return lrServerConnection.getLoggedInUser();
    case ACTIVE_ADMIN           : return lrServerConnection.getSelectedAdmin();
    case ACTIVE_PROJECT         : return lrServerConnection.getProject();
    case COLLABORATORS          : return lrServerConnection.getCollaborators();
    case SHAREDLICENCE          : return lrServerConnection.getSharedLicenceUsers()
    case MEMBERS                : return lrServerConnection.getProjectMembers();
    case ACTIVE_CHANGE          : return lrServerConnection.getFilesOfProject();
    case SLACK_CHANNELS         : return lrServerConnection.getSlackChannel();
    case USER_REVIEW_TEMPLATES  : return lrServerConnection.getUserReviewTemplates();
    case PROJECT_REVIEW_TEMPLATE: return lrServerConnection.getProjectReviewTemplate();
    case REVIEW                 : return lrServerConnection.getReviewByID();
    case ACTIVE_CHECK_PROJECT   : return window.LR_GetChecksFromProject();
    case CHANGES                : return lrServerConnection.getAllChanges();
    case GLOBAL_SETTINGS        : return window.LR_GetGlobalSettings();
    case GROUPS                 : return lrServerConnection.getGroups();
    case CUSTOMER_INFO          : return lrServerConnection.getCustomerInformation();
    case ADMINS                 : return lrServerConnection.getAdmins();
    case CONTENT_ADMINS         : return lrServerConnection.getContentAdmins();
    case API_TOKENS             : return lrServerConnection.getApiTokens();
    case SHARE_LINKS            : return lrServerConnection.getShareLinks();
    case CUSTOM_PROJECT_DATA    : return lrServerConnection.getCustomDataOnProject();
    case REQUESTED_CALCULATIONS    : return lrServerConnection.getStructuralCalculations();
    case REQUESTED_CALCULATIONS_ALL    : return lrServerConnection.getStructuralCalculationsAll();
    default: console.error("No caller for " + name); return {}
  }
}

let fetchAction = async (value, fetch_function_uncalled, force=false) => {
  if(cached.user !== lrServerConnection.__USERNAME || cached.project !== lrServerConnection.__PROJECT) {
    cached = {
      user: lrServerConnection.__USERNAME,
      project: lrServerConnection.__PROJECT,
      refetched: []
    }
  }
  if(!cached.refetched.includes(value)) {
    force = true
    cached.refetched.push(value)
  }

  if(!force && (store.getState()[value].isFetching || 
                store.getState()[value].hasFetched)) 
  {
    return
  }
  store.dispatch({
    type: FETCH,
    value,
  })

  let data = undefined
  try {  
    data = await function_caller(value);
  } catch (error) {
    console.log(error)
  }
  if(data === undefined) {
    store.dispatch({
      type: CLEAR,
      value
    })
    return
  }
  store.dispatch({
    type: SET,
    value,
    data 
  })
}

export const ResetRedux = () => store.dispatch({type: RESET})

export const hasFetched = (stateVal) => {
  return !stateVal.isFetching && stateVal.hasFetched
}

export const isFetching = (stateVal) => {
  return stateVal.isFetching
}

export const FetchProjects = async (force) => {
  fetchAction(PROJECTS, lrServerConnection.getProjects, force)
}

export const FetchMiniProjects = async (force) => {
  fetchAction(MINI_PROJECTS, lrServerConnection.getMiniProjects, force)
}

export const FetchActiveUser = async (force) => {
  fetchAction(ACTIVE_USER, lrServerConnection.getLoggedInUser, force)
}

export const FetchActiveAdmin = async (force) => {
  fetchAction(ACTIVE_ADMIN, lrServerConnection.getSelectedAdmin, force)
}

export const FetchProject = async (force) => {
  fetchAction(ACTIVE_PROJECT, lrServerConnection.getProject, force)
}

export const FetchCollaborators = async (force) => {
  fetchAction(COLLABORATORS, lrServerConnection.getCollaborators, force)
}

export const FetchStructuralCalculations = async (force) => {
  fetchAction(REQUESTED_CALCULATIONS, lrServerConnection.getStructuralCalculations, force)
}

export const FetchStructuralCalculationsAll = async (force) => {
  fetchAction(REQUESTED_CALCULATIONS_ALL, lrServerConnection.getStructuralCalculationsAll, force)
}

export const FetchAdmins = async (force) => {
  fetchAction(ADMINS, lrServerConnection.getAdmins, force)
}

export const FetchContentAdmins = async (force) => {
  fetchAction(CONTENT_ADMINS, lrServerConnection.getContentAdmins, force)
}

export const FetchSharedLicenceUsers = async (force) => {
  fetchAction(SHAREDLICENCE, lrServerConnection.getSharedLicenceUsers, force)
}

export const FetchApiTokens = async (force) => {
  fetchAction(API_TOKENS, lrServerConnection.getApiTokens, force)
}

export const FetchMembers = async (force) => {
  fetchAction(MEMBERS, lrServerConnection.getProjectMembers, force)
}

export const FetchSlackChannels = async (force) => {
  fetchAction(SLACK_CHANNELS, lrServerConnection.getSlackChannel, force)
}

export const FetchUserReviewTemplates = async (force) => {
  fetchAction(USER_REVIEW_TEMPLATES, lrServerConnection.getUserReviewTemplates, force)
}

export const FetchProjectReviewTemplate = async (force) => {
  fetchAction(PROJECT_REVIEW_TEMPLATE, lrServerConnection.getProjectReviewTemplate, force)
}

export const FetchReview = async (force) => {
  fetchAction(REVIEW, lrServerConnection.getReviewByID, force)
}

export const FetchGroups = async (force) => {
  fetchAction(GROUPS, lrServerConnection.getGroups, force)
}

export const FetchCustomerInformation = async (force) => {
  fetchAction(CUSTOMER_INFO, lrServerConnection.getCustomerInformation, force)
}

export const FetchShareLink = async (force) => {
  fetchAction(SHARE_LINKS, lrServerConnection.getShareLinks, force)
}

export const ReFetchProjects = async () => {
  let state = store.getState()

  FetchActiveUser(true);
  if(state[ACTIVE_PROJECT].hasFetched || state[ACTIVE_PROJECT].isFetching) FetchProject(true);
  if(state[PROJECTS].hasFetched || state[PROJECTS].isFetching) FetchProjects(true);
}

export const FetchActiveChange = async (force) => {
  fetchAction(ACTIVE_CHANGE, lrServerConnection.getFilesOfProject, force)
}

export const FetchActiveCheckForProject = async (force) => {
  fetchAction(ACTIVE_CHECK_PROJECT, window.LR_GetChecksFromProject, force)
}

export const FetchCustomProjectData = async (force) => {
  fetchAction(CUSTOM_PROJECT_DATA, lrServerConnection.getCustomDataOnProject, force)
}


export const SetEditMode = (bool) => ({
  type: SET,
  value: EDIT_MODE,
  data: bool
})

export const LogOut = async () =>
{
  lrServerConnection.logout();
  store.dispatch({
    type: SET,
    value: ACTIVE_USER,
    data: undefined
  })
}

export const FetchAllChanges = async (force) => {
  fetchAction(CHANGES, lrServerConnection.getAllChanges, force)
}

export const ClearActiveProject = async () => {
  store.dispatch({
    type: CLEAR,
    value: ACTIVE_CHANGE
  })
  store.dispatch({
    type: CLEAR,
    value: ACTIVE_PROJECT
  })
}


export const FetchGlobalSettings = async (force) => {
  fetchAction(GLOBAL_SETTINGS, undefined, force)
}