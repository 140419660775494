//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Form, Message } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/CustomURLDialog";
import LRModal from '../Basics/BasicModal';
import { globalCallbacks } from "../../util/callback";


//-----------------------------------------------------------------------------
// The component for the TreeControl
class CustomURLDialog extends Component {
  constructor(props) {
    super(props);
    this.state =
    {
      open: false,
      url: "",
      username: "",
      urlerror: false
    }
  }

  componentDidMount = () => {
    this.setUpCallbacks();
  }

  show = async () => {
    let base = await window.LR_GetBaseURL()
    this.setState({
      open: true,
      url: base.URL,
      username: base.User
    });
  }

  close = () => {
    this.setState({ open: false });
  }

  ok = () => {
    try {
      let url = new URL(this.state.url)

      window.LR_SetOnlineConfig({
        HTTP: url.host,
        HTTPS: url.origin,
        User: this.state.username,
      })
      this.close()
      return;

    }
    catch (e) {

      console.error(e)
    }

    this.setState({ urlerror: true })

  }

  render() {
    let open = this.state.open;
    if (!open) { return <div /> }

    return (
      <LRModal open={open}
        onCancelClick={this.close}
        size="mini"
        scrolling={false}
        title={LocalizedStrings.Header}
        onOkClick={this.ok}>
        <Message warning>
          <Message.Header>{LocalizedStrings.MessageHeader}</Message.Header>
          <Message.Header>{LocalizedStrings.MessageText}</Message.Header>
        </Message>
        <Form>
          <Form.Input placeholder={"https://www.production-assist.com"} error={this.state.urlerror} label={LocalizedStrings.URL} value={this.state.url} onChange={(_, { value }) => { this.setState({ url: value, urlerror: false }) }} />
          <Form.Input label={LocalizedStrings.ServerAdmin} value={this.state.username} onChange={(_, { value }) => { this.setState({ username: value }) }} />
        </Form>

      </LRModal>
    )
  }

  setUpCallbacks() {

    globalCallbacks.ShowCustomURLDialog = () => {
      this.show();
    }

  }
}

export default CustomURLDialog