
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Search, Table, Label, Icon, Segment, Form } from 'semantic-ui-react'
import RackNode from "./RackNode";
import LocalizedStrings from "../../localization/NavigationContainer";
import LRModal from '../Basics/BasicModal';
import escapeRegExp from "lodash/escapeRegExp"
import filter from "lodash/filter"
import { IsElectronContext } from '../../util/defines';

import { globalCallbacks as mainCB } from '../../util/callback';
import { globalCallbacks as mockCB } from '../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB


const SORT_BY = "Name"

class RackTable extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = 
        {
            racks : [],
            rackTemplates: [],
            createRackOpen: false,

            selectedRackTemplate: "",

            // search
            isLoading: false,
            results  : [], 
            value    : "",

            sorting: null,
            indexMap: [],
        };

    }

    componentDidMount = () => 
    {
        this.setUpCallbacks();
        globalCallbacks.updateRackObjects();
    }

    render() 
    {
        return (
            <div style={{width:"100%", height:"100%"}}>
                <Table style={{borderBottom: "none", margin:0}}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan='3' style={{zIndex:0}} onClick={this.onHeaderClick}>
                                {<Icon link style={{floated: 'left', position: 'absolute', zIndex:1}} name={this.state.sorting === null ? "sort" : this.state.sorting === "ascending" ? "sort alphabet up": "sort alphabet down"}/>}
                                <Search open    = {false}
                                        loading = {this.state.isLoading}
                                        value   = {this.state.value}
                                        onClick = {(e)=>{e.stopPropagation()}}
                                        onSearchChange = {(e, {value}) => this.onSearchChange(value, this.state.racks)}
                                        aligned = "right"/>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                </Table>

                <div style={{width:"100%", maxHeight:IsElectronContext() ? "calc(100vh - 25em)" :"calc(100vh - 30em)", overflowY:"auto", marginBottom:"5em"}}>
                    <Table striped structured compact='very' size="small">
                        <Table.Body>
                            {this.renderRows()}
                        </Table.Body>
                    </Table>
                </div>

                <Segment vertical textAlign="center" style={{position:"absolute", bottom:IsElectronContext() ? "1.5em" : "6em", width:"100%", border:"none"}}>
                    <Label as="a" color="green" onClick={ this.openCreateRack }>
                        <Icon name="plus"/>{LocalizedStrings.AddNewRack}
                    </Label>
                </Segment>
                
                <LRModal
                    open={this.state.createRackOpen}
                    title={LocalizedStrings.AddNewRack}
                    onOkClick={this.addNewRack}
                    onCancelClick={this.closeCreateRack}
                    scrolling={false}
                    >
                        <Form>
                            <Form.Select label={LocalizedStrings.Template} options={this.state.rackTemplates} value={this.state.selectedRackTemplate} onChange={(e, {value}) => {this.setState({selectedRackTemplate: value})}}/>
                        </Form>
                </LRModal>
            </div>

        );
    }

    onHeaderClick = () => {
        let newVal = null
        switch (this.state.sorting) {
            case null:          newVal = "ascending";   break;
            case "ascending":   newVal = "descending";  break;
            default:            newVal = null;          break;
        }
        this.setState({
            sorting: newVal
        })
    }

    renderRows = () => {
        let showData = !this.state.value ? this.state.racks : this.state.isLoading ? this.state.racks : this.state.results

        if(this.state.sorting) {
            return this.state.indexMap.map((_, i) => {
                let calcIndex = this.state.sorting === 'ascending' ? i : this.state.indexMap.length - i - 1
                return showData[this.state.indexMap[calcIndex]]
            }).map(rack => {return <RackNode small={this.props.small} key={rack.UUID} rack={rack}/>})
        }

        return showData.map(rack => {return <RackNode small={this.props.small} key={rack.UUID} rack={rack}/>})
    }

    onSearchChange = (value, resources) => 
    {
      this.setState({isLoading: true, value: value})

        if(value.length < 1)
        {
          this.onSearchReset()
        }
        else
        {
          const re = new RegExp(escapeRegExp(value), 'i')
          const isMatch = (result) => {let res=re.test(result.Name); return res;}
        
          this.setState({results: filter(resources, isMatch), isLoading: false})
        }
    }

    onSearchReset = () => {
      this.setState({isLoading: false,
                     results  : [], 
                     value    : "",})
    }

    openCreateRack = () =>
    {
        window.LR_GetRackTemplateMap().then(res => {
            let rackOptions = []

            rackOptions.push({
                text: "None",
                value:"",
                key: "None",
            })

            res.ResourceMap.rackTemplate.forEach(element => 
            {
                rackOptions.push({
                    text: element.Name,
                    value: element.Name,
                    key: element.Name,
                })
            });

            this.setState({createRackOpen: true, createRackName: "", rackTemplates: rackOptions, selectedRackTemplate: "None"})
        })
    }

    closeCreateRack = () =>
    {
        this.setState({createRackOpen: false})
    }

    addNewRack = async () =>
    {
        window.LR_AddNewRack({RackTemplate: this.state.selectedRackTemplate || "None"})
        this.setState({createRackOpen: false})
    }

    setUpCallbacks() 
    {
        globalCallbacks.updateRackObjects = async () =>
        {
            let racks = await window.LR_GetRacks()

            let indexMap = []
            let objects = racks.Racks
            objects.forEach((_, i) => indexMap.push(i))
            indexMap.sort((a,b) => objects[a][SORT_BY] === objects[b][SORT_BY]  ? 0 : (objects[a][SORT_BY]  < objects[b][SORT_BY]  ? -1 : 1))

            this.setState({racks: racks.Racks, indexMap})
        }
    }
}

export default RackTable;

