//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Header, Icon, Segment, Button } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/AboutDialog";
import LRModal from '../Basics/BasicModal';
import { globalCallbacks } from "../../util/callback";


//-----------------------------------------------------------------------------
// The component for the TreeControl
class LinkWithHostDialog extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
    }
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }

  show = async(args) => 
  {
    this.setState( { 
        open : !args.Valid, 
      });
  }

  close = () => 
  {
    window.LR_ReadVectorworksDrawing()
  }
    
  render() 
  {
    let open = this.state.open;
    if(!open) {return <div/>}

    return (
      <LRModal  open={open} 
                onCancelClick={this.close} 
                closeOnDimmerClick={false} 
                size="mini"
                scrolling={false}
                title={LocalizedStrings.FileNotLoaded}
                noCancel
                onOkClick={this.close}>
                  <Segment placeholder>
                  <Header icon>
                    <Icon name='info circle' />
                    {LocalizedStrings.FileNotLoadedText}
                  </Header>
                  <Button onClick={this.close} primary>{LocalizedStrings.Read}</Button>
                  </Segment>
      </LRModal>
    )
  }

  setUpCallbacks()
  {

    globalCallbacks.LinkWithHostDialog = (args) => 
    { 
      this.show(args);
    }
    
  }
}

export default LinkWithHostDialog