// ### types from the core 
export interface IBridlePart{
    CSA: number,
    Delta: number, 
    Length: number, 
    MaxLength: number,
    MinLength:number,
    Name: string, 
    UUID: string,
    IsShackle:boolean
}

export interface IBasketPart {
    [key:string]:any
}

export type TLRuuid = `{${string}-${string}-${string}-${string}-${string}}`;

export interface ILRVector3{
    X:number,Y:number,Z:number
}
export interface ISupportInfo {
    BridleSet:TLRuuid,  
    AllowNegativeZ: boolean 
    BasketHeight:number, 
    BasketWidth:number, 
    BeamForceX:number, 
    BeamForceY:number, 
    BeamForceZ:number, 
    Calculated: boolean, 
    Capacity:number 
    CapacityForceX:number 
    CapacityForceXX:number 
    CapacityForceY:number 
    CapacityForceYY:number 
    CapacityForceZ:number 
    CapacityForceZZ:number 
    ChainLength:number 
    ChainShorten:number 
    ChainShortenEffect:number 
    ChainShortenEffectTruss:number 
    ChainShortenEffectTrussWorkload:number 
    ChainShortenEffectWorkload:number 
    ChainShortenMaxReactionForce:number 
    CompensateChainShorten: boolean
    ConnectedOnTop: boolean
    ConnectedTop: string // UUID
    ConnectedWith: string // UUID
    ForceDropConnection: boolean
    FrictionCheck: boolean
    FrictionCheckName: string 
    FrictionCheckValue: number 
    GeometryPosition: {X: 0, Y: 0, Z: 0}
    GlobalForceX: number
    GlobalForceY: number
    GlobalForceZ: number
    HookForce: number
    LoadMeasurementCellId: string
    LoadMeasurementValue: number
    Name: string
    ObjectUUID: TLRuuid 
    OnValidSystem: boolean, 
    ReactionForce:number 
    ReactionForceX:number 
    ReactionForceXX:number 
    ReactionForceY:number 
    ReactionForceYY:number 
    ReactionForceZ:number 
    ReactionForceZZ:number 
    ResistanceX:number 
    ResistanceXX:number 
    ResistanceY:number 
    ResistanceYY:number 
    ResistanceZ:number 
    ResistanceZZ:number 
    ResourceType:number 
    RopeCrossSection: string 
    RopeOffset: ILRVector3
    SelectedBasketParts:IBasketPart[]
    SelectedBridleParts: IBridlePart[] 
    ShowResult: boolean 
    Speed: number
    SupportType:number
    UUID: TLRuuid 
    UpsideDown: boolean 
    Weight: number 
    WorkloadHigh: number 
    WorkloadOverload: number
    IsDownLeg:boolean
}
export type TBridleLeg = Pick<ISupportInfo, "BridleSet"| "SelectedBridleParts" | "IsDownLeg" | "RopeOffset" | "GeometryPosition">   

export enum TInventoryType {
    Default = 0,
    Cable = 1,
    BridlePart_Rope = 2,
    BridlePart_Shackle = 3,
    BridlePart_ChainShorten = 4,
}

export enum TDrawingObjectType{
    SceneObject = 0,
    Fixture = 1,
    Truss = 2,
    Dimension = 3,
}

export interface IDrawingObject{
    CableCrossSectionArea: number 
    Class: TLRuuid 
    ClassObject: Record<string,any>
    Class__CommitId: string 
    CreationId: string 
    Expanded: boolean 
    GlobalOffsetX: number 
    GlobalOffsetY: number 
    GlobalOffsetZ: number 
    GlobalRotationX: number 
    GlobalRotationY: number 
    GlobalRotationZ: number 
    GlobalScaleX: number 
    GlobalScaleY: number 
    GlobalScaleZ: number 
    HasBeam: boolean 
    InsulationMaterial: string 
    InventoryCount: number 
    InventoryType: number 
    Layer:TLRuuid 
    LayerObject: Record<string,any>
    Layer__CommitId: string 
    Length: number 
    LengthDelta: number 
    LengthMax: number 
    LengthMin: number
    LinkedSymbolDef: TLRuuid 
    "{00000000-0000-0000-0000-000000000000}"
    LinkedSymbolDefObject: Record<string,any>
    LinkedSymbolDef__CommitId: string
    MagnetInfo: 
    {
        ConnectedGeometry: TLRuuid, 
        ConnectedObject: TLRuuid, 
        IsMagnet: boolean,
        Type: string
    }
    MeshUuid: TLRuuid 
    MeshUuidObject: Record<string,any>
    MeshUuid__CommitId: string 
    Name: string 
    Note:string 
    ObjectType: TDrawingObjectType 
    Offset: Record<string,any>
    OffsetX: number
    OffsetY:number
    OffsetZ:number
    PreviewTexture: TLRuuid 
    PreviewTextureObject: Record<string,any>
    PreviewTexture__CommitId: string 
    Project: string 
    ResourceType: number 
    RotateX: boolean 
    RotateXMax: number 
    RotateXMin: number 
    RotateY: boolean 
    RotateYMax: number
    RotateYMin:number
    RotateZ:boolean
    RotateZMax:number
    RotateZMin:number
    Rotation:Record<string,any>
    RotationX:number
    RotationY:number
    RotationZ:number
    Scale: Record<string,any>
    ScaleX: number
    ScaleY: number 
    ScaleZ: number 
    Selected:boolean
    TextureUuid:TLRuuid
    TextureUuidObject:Record<string,any>
    TextureUuid__CommitId:string
    TranslateX:boolean
    TranslateXMax:number
    TranslateXMin:number
    TranslateY:boolean
    TranslateYMax:number
    TranslateYMin:number
    TranslateZ:boolean
    TranslateZMax:number
    TranslateZMin:number
    UUID:TLRuuid
    VWRef:boolean
    Visible:boolean
    VisibleInSceneTree:boolean
}

type TOptionType = "enum" | "";
export interface IDisplayUnit{
    Accuracy: number,
    Options: any[], 
    Type: TOptionType,
    Value: number
}

export enum TWireType {
    Input = 1,
    Output = 2,
    Generator = 3,
    Consumer = 4,
    Fuse = 5,
    NetworkProvider = 6,
    NetworkInput = 7,
    NetworkOutput = 8,
    NetworkInOut = 9,
    HotpatchInput = 10,
    HotpatchOutput = 11,
}
export interface IAppSettings{
    AlwaysApplyToActiv: boolean, 
    App_DisplayUnit_Area: IDisplayUnit,
    App_DisplayUnit_BroadWeightWeight: IDisplayUnit
    App_DisplayUnit_Force: IDisplayUnit
    App_DisplayUnit_ForcePerArea: IDisplayUnit 
    App_DisplayUnit_ForcePerDist: IDisplayUnit 
    App_DisplayUnit_Length: IDisplayUnit 
    App_DisplayUnit_Moment: IDisplayUnit 
    App_DisplayUnit_MomentPerDegree: IDisplayUnit 
    App_DisplayUnit_PageLength: IDisplayUnit
    App_DisplayUnit_Number_Accuracy: number 
    App_DisplayUnit_Power: IDisplayUnit 
    App_DisplayUnit_TimeCode: IDisplayUnit 
    App_DisplayUnit_Velocity: IDisplayUnit 
    App_DisplayUnit_Volume: IDisplayUnit 
    App_DisplayUnit_Weight: IDisplayUnit 
    App_DisplayUnit_WeightPerDistance: IDisplayUnit 
    App_DisplayUnit_ZeroOne: IDisplayUnit 
    App_GlobalTransform: boolean 
    App_ShowCalcFinishedDialog: boolean 
    App_ShowInstallPlugins: boolean
    App_UseLiveCalculation: boolean 
    AutoCADSaveFile: boolean 
    AutoCADTextStyle: string 
    AutogenerateHoistObjectId: boolean 
    CSVSeparator: {Options: [any,any,any], Type: TOptionType, Value: number}
    [key:string]:any
}
// ColorL1
// : 
// {Type: 'Color3D', X: 0.39022899998143373, Y: 0.3891570579329561, Z: 46.08010462745097}
// ColorL2
// : 
// {Type: 'Color3D', X: 0.31932241086120944, Y: 0.32573199565591593, Z: 12.518744000000002}
// ColorL3
// : 
// {Type: 'Color3D', X: 0.3107922149041179, Y: 0.3265070676907458, Z: 66.30432109803921}
// CreateTheCalculationResultsOnActiveClass
// : 
// true
// CreateTheCalculationResultsOnActiveLayer
// : 
// true
// CustomNameForResultsClass
// : 
// ""
// CustomNameForResultsLayer
// : 
// ""
// DateFormat
// : 
// "DD.MM.YYYY"
// DisplayMode
// : 
// {Options: Array(2), Type: 'enum', Value: 0}
// Dmx_PatchingMode
// : 
// {Options: Array(2), Type: 'enum', Value: 0}
// DontWarnAboutSupportsWithoutWeight
// : 
// false
// Electrical_OnlyPatchInAssemblyGroup
// : 
// false
// GridSize_10M
// : 
// 10000
// GridSize_M
// : 
// 1000
// InternalDateFormat
// : 
// "%d.%m.%Y"
// InternalTimeFormat
// : 
// "%H:%M"
// Language
// : 
// {Options: Array(7), Type: 'enum', Value: 0}
// LeaveFreePatch
// : 
// 0
// PatchFreeMode
// : 
// {Options: Array(2), Type: 'enum', Value: 0}
// PatchPercentageFree
// : 
// 20
// PatchToolOrder
// : 
// {Options: Array(2), Type: 'enum', Value: 0}
// PatchWattageFree
// : 
// 500
// Renderer_AlwaysRenderRenderView
// : 
// false
// Renderer_AutomaticclyResolvePatchingConflicts
// : 
// false
// Renderer_Beam_DisplayMode
// : 
// {Options: Array(3), Type: 'enum', Value: 0}
// Renderer_ColorStructures
// : 
// false
// Renderer_DisableSnapping
// : 
// false
// Renderer_RenderContinious
// : 
// false
// Renderer_ShowGroundSupports
// : 
// true
// Renderer_ShowIn3D
// : 
// true
// Renderer_ShowLabels3D
// : 
// true
// Renderer_ShowMagnets
// : 
// false
// Renderer_ShowOutline
// : 
// false
// Renderer_ShowPerspective
// : 
// true
// Renderer_ShowStructuralErrors
// : 
// false
// Renderer_ShowStructure
// : 
// true
// Renderer_ShowStructureInternal
// : 
// false
// Renderer_SolidFloor
// : 
// false
// Renderer_Workload_Coloring
// : 
// {Options: Array(2), Type: 'enum', Value: 0}
// Renderer_ZoomToCursor
// : 
// false
// Scale
// : 
// 1
// ShowLoadLocations_LineLoad
// : 
// false
// ShowLoadLocations_PointLoad
// : 
// false
// ShowTour
// : 
// false
// ShowTourOnStartUp
// : 
// true
// Theme
// : 
// {Options: Array(2), Type: 'enum', Value: 0}
// TimeFormat
// : 
// "HH:mm"
// VectorworksMode
// : 
// {Options: Array(3), Type: 'enum', Value: 0}
// Vectorworks_AlwaysGenerateEntriesForResults
// : 
// false
// Vectorworks_DeleteNonexistentConnections
// : 
// true
// Vectorworks_DisablePatchSupport
// : 
// false
// Vectorworks_ShowHookForceAsHoistResult
// : 
// false
// Vectorworks_ShowLoadAsWeight
// : 
// false
// }