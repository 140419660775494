import React, {Component} from 'react'
import { Header, Button, Message, Form } from 'semantic-ui-react'
import { ACTIVE_USER, ACTIVE_ADMIN} from "../../redux/redux_defines"
import { connect } from 'react-redux'
import { lrServerConnection } from '../../redux/light_right_server_connection';
import { globalCallbacks } from '../../util/callback'
import UserAvatar from '../WebComponents/UserAvatar'
import LocalizedStrings from '../../localization/Profile'
import { FetchActiveUser, FetchActiveAdmin} from '../../redux/actions/fetch_actions';

class Profile extends Component {
  constructor(props) {
    super(props)
    this.fileChooserRef = React.createRef();

    this.state = {
      src : undefined, 
      error: false,
      title:null, 
      phoneNumber:null,
      phoneNumberError: false
    }
  }

  componentDidUpdate = (prevProps, prevState) =>
  {
      if(prevProps.activeAdmin.data && this.props.activeAdmin.data && (prevProps.activeAdmin.data !== this.props.activeAdmin.data))
      {
        this.setState({title: null, phoneNumber:null})
      }
  }

  render() {
   
    FetchActiveUser()
    FetchActiveAdmin()
    
    let title       =  this.state.title       ?? ( this.props.activeAdmin.data?.title       ??  this.props.activeUser.data.title)
    let phoneNumber =  this.state.phoneNumber ?? ( (this.props.activeAdmin.data?.phoneNumber === null ? "" : this.props.activeAdmin.data?.phoneNumber) ??  this.props.activeUser.data.phoneNumber)
  

   
    return <div>
      <Header>{LocalizedStrings.ProfilePicture}</Header>
      <UserAvatar user={this.props.activeAdmin.data?.username ?? this.props.activeUser.data?.username } size="medium"/>
      <br/>
      {this.state.error ? <Message negative onDismiss={this.closeErrorMessage} size="large">
        <Message.Header>
        {LocalizedStrings.ErrorMessageHeader}
        </Message.Header>
        <Message.Content>
        {LocalizedStrings.ErrorMessageContent}
        </Message.Content>
      </Message> : null}
      <Button onClick={this.openFileChooser}>{LocalizedStrings.SetPicture}</Button>
      <input accept=".jpg, .jpeg, .png, .bmp, .tiff, .gif" type="file" ref={this.fileChooserRef} style={{display: "none"}} onChange={this.readFile}/>
      <br/>
      <br/>
      <Form style={{width:"60%"}}> 
          <Form.Input
            label={LocalizedStrings.Title}
            value={title}
            onChange={(e, { value }) => {
              this.setState({ title: value });
            }}
            onBlur={this.updateUserInfo}
            onKeyDown = {(e) => {if(e.keyCode === 13){this.updateUserInfo(); e.target.blur()}}}
          ></Form.Input>
          <Form.Input
            type="text"
            label={LocalizedStrings.PhoneNumber}
            value={phoneNumber}
            onChange={(e, { value }) => {
              this.setState({ phoneNumber: value, phoneNumberError : false });
            }}
            onBlur={this.updateUserInfo}
            onKeyDown = {(e) => {if(e.keyCode === 13){this.updateUserInfo(); e.target.blur()}}}

          ></Form.Input>
      </Form>
              {this.state.phoneNumberError === true ? <Message style={{width:"40vw"}} size="mini" negative onDismiss={this.closePhoneNumErrorMessage}><Message.Header>{LocalizedStrings.InvalidPhoneNumber}</Message.Header></Message> : ""}

    </div> 
  }

  isPhoneNumberValid = (phoneNumber) =>
  {
    //eslint-disable-next-line
    let phoneRegExp = /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g
    
      return phoneRegExp.test(phoneNumber)
  }

  closePhoneNumErrorMessage = () => 
  {
    this.setState({phoneNumberError:false})
  }

  updateUserInfo = async () =>
  {
    let userInfo = {
      title       : this.state.title       ?? ( this.props.activeAdmin.data?.title       ??  this.props.activeUser.data.title),
      phoneNumber : this.state.phoneNumber ?? ( this.props.activeAdmin.data?.phoneNumber ??  this.props.activeAdmin.data.phoneNumber )
    }
    let validPhoneNumber = this.isPhoneNumberValid(userInfo.phoneNumber)

    if(validPhoneNumber)
    {
      this.setState({phoneNumberError: false})
      await lrServerConnection.setUserInfo(userInfo)
      FetchActiveUser(true)
      FetchActiveAdmin(true)
    }else if((userInfo.title && !userInfo.phoneNumber) || (!userInfo.title && !userInfo.phoneNumber))
    {
      this.setState({phoneNumberError: false})
    }else
    {
      this.setState({phoneNumberError: true})
    }

  }

  readFile = (e) => {
    let files = e.target.files;
    let fileToUpload = files[0]
    
    globalCallbacks.setAvatar(fileToUpload)
      .then((res) => {
        if(res.status >= 400) {
          this.setState({error: true})
        } else {
          this.setState({error: false})
          setTimeout(() => {
            globalCallbacks.runCallbacks("refetch-user-avatar")
          },500);
        }
      })

  }

  openFileChooser = (e) => 
  {
    // the default input-field is ugly, so we hide it and call it through an other button
    let hiddenFileChooser = this.fileChooserRef.current;
    hiddenFileChooser.click();
  }

  closeErrorMessage = () => 
  {
    this.setState({error:false})
  }
}

//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      activeUser: state[ACTIVE_USER],
      activeAdmin: state[ACTIVE_ADMIN]
    };
}

export default connect(mapStateToProps)(Profile)

