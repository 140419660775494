//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2022
//----------------------------------------------------------------------------------------------------------------


import React, { Component, } from 'react';
import { Link } from "react-router-dom";
import { Card, Popup} from 'semantic-ui-react';
import LocalizedStrings from "../../localization/TimeStrings";

let CalendarPlugins = []

const FullCalendar = React.lazy(async ()=>{
  let c = await import('@fullcalendar/react')
  CalendarPlugins = (await Promise.all([
    import('@fullcalendar/daygrid'),
    import('@fullcalendar/timegrid'),
    import('@fullcalendar/list'),
    import('@fullcalendar/interaction')
  ])).map(i => i.default)

  return c
})

import { connect } from 'react-redux';
import { PROJECTS} from "./../../redux/redux_defines"
import { IsRunningAsBrowser } from '../../util/defines';
import { FetchProjects } from '../../redux/actions/fetch_actions';

class Calendar extends Component 
{
  constructor(props)
  {
      super(props);
      this.state ={
           projects: [] 
      };
  }


  componentDidMount = async () =>
  {
      
    if(!IsRunningAsBrowser())
    {
        let projects = await window.LR_GetProjectsAsync()
        this.setState({projects: projects})
    }
  }


  render() 
  {
    let constructionDates = this.getProjects().map(project => ({title: project.DrawingSettings.ProjectPrettyName ? project.DrawingSettings.ProjectPrettyName : project.name, date: project.DrawingSettings.ConstructionDate, eventType: LocalizedStrings.ConstructionDate, projectDescription:project.description, projectLink:`/${project.owner.username}/${project.projectname}`}))
    let dueDates          = this.getProjects().map(project => ({title: project.DrawingSettings.ProjectPrettyName ? project.DrawingSettings.ProjectPrettyName : project.name, date: project.DrawingSettings.DueDate,          eventType: LocalizedStrings.DueDate,          projectDescription:project.description, projectLink:`/${project.owner.username}/${project.projectname}`}))
    let eventDates        = this.getProjects().map(project => ({title: project.DrawingSettings.ProjectPrettyName ? project.DrawingSettings.ProjectPrettyName : project.name, date: project.DrawingSettings.EventDate,        eventType: LocalizedStrings.EventDate,        projectDescription:project.description, projectLink:`/${project.owner.username}/${project.projectname}`}))
    let dismantleDates    = this.getProjects().map(project => ({title: project.DrawingSettings.ProjectPrettyName ? project.DrawingSettings.ProjectPrettyName : project.name, date: project.DrawingSettings.DismantleDate,    eventType: LocalizedStrings.DismantleDate,    projectDescription:project.description, projectLink:`/${project.owner.username}/${project.projectname}`}))

    
    let allEventDates = [...constructionDates, ...dueDates, ...eventDates,...dismantleDates ]
    
    allEventDates = allEventDates.filter(i => i.date)
    
    return (
        <FullCalendar   plugins      ={CalendarPlugins} 
                        initialView  ='dayGridMonth'
                        editable     ={true}
                        selectable   ={true}
                        selectMirror ={true} 
                        events       = {allEventDates}   
                        eventContent ={this.renderEventContent}            
        />
    )
    }   

    renderEventContent = (eventInfo) => 
 {
    return (
    <>
        {IsRunningAsBrowser() ? 
            <Popup
            style={{color:"#000"}}
            content={eventInfo.event._def.extendedProps.projectDescription}
            on='hover'
            size='tiny'
            positionFixed
            trigger={  <Card color="grey" centered style={{display:"flex", flexDirection:"column"}}>
        
                            <Link to={eventInfo.event._def.extendedProps.projectLink}>
                              <Card.Header style={{color:"#000", fontWeight:"bold", margin: "0 0 0 0.5rem", overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>Project: {eventInfo.event.title}</Card.Header>
                            </Link> 
    
                          <Card.Description style={{color:"#000", margin: "0 0 0 0.5rem", overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>{eventInfo.event._def.extendedProps.eventType}</Card.Description>
                        </Card>
                    }
          />  :

          <Card color="grey" centered style={{display:"flex", flexDirection:"column"}}>
                        
                            <Card.Header onClick={()=>{window.LR_OpenLink({UseBaseUrl: true, URL: eventInfo.event._def.extendedProps.projectLink})}} style={{color:"#000", fontWeight:"bold", margin: "0 0 0 0.5rem", overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>
                                Project: {eventInfo.event.title}
                            </Card.Header>

                            <Card.Description style={{color:"#000", margin: "0 0 0 0.5rem", overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>{eventInfo.event._def.extendedProps.eventType}</Card.Description>
          </Card>
                
      

        }
    </>
    )
  }

  getProjects = () =>
  {
      if (!IsRunningAsBrowser())    
      {
          return this.state.projects
      }
      else
        {
            FetchProjects()
            return this.props.projects.data
        }
  }
  }


//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      projects: (state[PROJECTS])
    };
}

export default connect(mapStateToProps)(Calendar)

