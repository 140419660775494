//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React from 'react';
import RightClickMenu from './RightClickMenu';

const longPressTime = 300;

class LongPressHandler extends React.Component 
{
  constructor(props) {
    super(props);
    this.timer = undefined;
    this.open = false;
  }

  touchStart = e => {
    e.persist();
    this.oldTouchX = e.touches[0].clientX;
    this.oldTouchY = e.touches[0].clientY;
    this.timer = setTimeout(() => {
        this.open = true
        e.target.dispatchEvent(new TouchEvent("contextmenu", {...e}))
    }, longPressTime);
  };

  touchEnd = e => {
    clearTimeout(this.timer);
    this.open = false
  };

  touchMove = e => {
    if (this.open) {
      e.stopPropagation();
      e.preventDefault();
    }
    let touchX = e.changedTouches[0].clientX;
    let touchY = e.changedTouches[0].clientY;

    let xOff = touchX - this.oldTouchX;
    let yOff = touchY - this.oldTouchY;

    let dragLength = Math.sqrt(xOff * xOff + yOff * yOff);
    if (dragLength > 4) {
      clearTimeout(this.timer);
    }

    this.oldTouchX = touchX;
    this.oldTouchY = touchY;
  };

  render() {
    return (
      <div
        onTouchStartCapture={this.touchStart}
        onTouchEndCapture={this.touchEnd}
        onTouchMoveCapture={this.touchMove}
      >
        {this.props.children}
        <RightClickMenu/>
      </div>
    );
  }
}
export default LongPressHandler