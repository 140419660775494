import React, { useEffect } from "react"
import UnitInput from '../Basics/BasicUnitInput';
import { BASE_UNIT_TIME_CODE } from "../../util/defines";
import LocalizedStrings from "localized-strings";

const NAME_TIMEOFFSET = "TimeOffset"
export const TimeOffsetInput: React.FC = (props:{value:number, presetColor:string, visible:boolean | undefined }) => { 
    
    function updateValue(value) 
    {
        //@ts-ignore
        window.LR_CommandLine({ Command: "Set " + NAME_TIMEOFFSET + " '" + value + "'" }) 
    }
    
    return (
        <>
            {props.visible !== false && 
                    <UnitInput 
                        name ={NAME_TIMEOFFSET}
                        baseUnit = {BASE_UNIT_TIME_CODE}
                        value = {props.value}
                        presetColor = {props.presetColor}
                        onUpdateData = {(_,value)=>updateValue(value)}
                        label={LocalizedStrings[NAME_TIMEOFFSET]} 
                    />
            }
        </>
    )
}