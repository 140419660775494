//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React from "react";
import { Form, Icon } from "semantic-ui-react";

import LocalizedStrings from "../../localization/BasicComponents";

class LRFilterInput extends React.Component 
{
  render() 
  {
    return (
        <Form.Input 
        className={this.props.class || this.props.className }
        label={this.props.noLabel ? undefined : LocalizedStrings.Search}
        value={this.props.value}
        disabled={this.props.disabled}
        onChange={(e, {value})=> this.props.onChange(value)}
        icon={<Icon name={this.props.value === "" ? 'search' : "cancel" }  link onClick={()=>{this.props.onChange("")}}/>}
        onKeyDown={(e)=>{ if(e.keyCode === 27) { this.props.onChange("")} }}
        placeholder={LocalizedStrings.SearchDotDot}
        fluid/>
    );
  }

}

export default LRFilterInput;
