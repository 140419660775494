import "./BridleDrawing.css"
import React, { useState, useMemo } from 'react';
import { Stage, Layer, Rect, Text, Circle, Line , Image } from 'react-konva';
import { Button, Icon } from 'semantic-ui-react';
import { Vector2d } from 'konva/lib/types';
import { IBridleDrawingProps, ILayoutProps, ILegsProps, ILineWithLabelProps, IRegulatorProps } from './types';
import Konva from 'konva';
import { IDrawingObject, TBridleLeg, TInventoryType } from '@type/lr-types';
import { toVector3 } from "../../util/vectors";
import { Vector3 } from "three";
import { TPoint2D } from "@type/types";
// @ts-ignore
import __TraverseImage from "@image/traverse.png"

const NORMAL_DRAWING_HEIGHT = 800
const NORMAL_DRAWING_WIDTH = 480
const TOP_OFFSET = 29
const CROSSARM_SIZE = 60
const TOP_LIMIT = CROSSARM_SIZE/2 + TOP_OFFSET ; 
const LEG_LABLE_COLOR_PALETTE = ["black","green","red","yellow","green","red","yellow"]
const IMAGE_TRAVERSE = new window.Image()
IMAGE_TRAVERSE.src = __TraverseImage

const BridleDrawing: React.FC = React.memo((props:IBridleDrawingProps) => { 
    const[state, setState] = useState({ rotated:false })
    const{ inventory, overwrittenRopeId } = props
    const canvasSize: TPoint2D = (state.rotated) ? [NORMAL_DRAWING_HEIGHT, NORMAL_DRAWING_WIDTH ] : [NORMAL_DRAWING_WIDTH, NORMAL_DRAWING_HEIGHT]  
    const bottomOffset = 50 * canvasSize[1] / NORMAL_DRAWING_HEIGHT
    const bottomLimit = canvasSize[1] - bottomOffset;

    const ropes:IDrawingObject[] = useMemo(()=>{
        return Object.values(inventory).filter(obj => obj.InventoryType === TInventoryType.BridlePart_Rope)
    }, [inventory] )

    const { legs , downLeg } = useMemo(()=>{
        let downLeg 
        const legs = props.legs.reduce((newArr, currLeg)=>{ 
            if(currLeg.IsDownLeg)
                downLeg = currLeg
            else
                newArr.push(currLeg)         
            return newArr
        },[]) as TBridleLeg[]
        return { legs, downLeg }
    },[props.legs])

    // calculate cross arm positions
    const crossArmsPositions: TPoint2D[] = useMemo(()=>{
        const points:TPoint2D[] = []
        for (let idx = 0; idx < legs.length; idx++ ) {
            const x = (idx === 0 ? 0 : (canvasSize[0] - CROSSARM_SIZE*1.666666666666667 ) * ((idx) / (legs.length-1) ) ) + 20
            points.push([x, TOP_OFFSET])  
        }
        return points
    }, [props.legs, state.rotated] )

    const drawingData = { rotated: state.rotated, canvasSize, crossArmsPositions, bottomLimit, bottomOffset }

    return (
        <>
            <Layout
                drawingContext={drawingData}
                onRotate={()=>setState({ rotated: !state.rotated })}
                onReset={()=>props.onChange(0)}
            >
                { legs[0].SelectedBridleParts.length > 0 &&
                    <Legs
                        drawingContext={drawingData}
                        ropes={ropes}
                        legs={legs}
                        downLeg={downLeg}
                        overwrittenRopeId={overwrittenRopeId}
                        onChange={props.onChange}
                    />
                }
            </Layout>
        </>
    )
})

const Legs : React.FC<ILegsProps> = (props:ILegsProps)=>{
    const { onChange, downLeg, legs, ropes, overwrittenRopeId } = props
    const { canvasSize, crossArmsPositions, bottomLimit, bottomOffset } = props.drawingContext 
    const [ { stepIdx }, setState] = useState({ stepIdx: -1 })

    const maxScale = ( canvasSize[1] - TOP_LIMIT - bottomOffset)

    const { triangle, realHeight, realWidth } = useMemo(()=> {
        const leg1Vector = toVector3(legs[0].RopeOffset)
        const leg2Vector = toVector3(legs[legs.length-1].RopeOffset)
        const realCoordinates:Record<string,Vector3> = {  
            startPoint1: toVector3(legs[0].GeometryPosition).add(leg1Vector),
            startPoint2: toVector3(legs[legs.length-1].GeometryPosition).add(leg2Vector),
            riggingPoint: downLeg? toVector3(downLeg.GeometryPosition) : toVector3(legs[0].GeometryPosition)
        }

        const realWidth = realCoordinates.startPoint2.sub(realCoordinates.startPoint1).length() 
        const realHeight = legs[0].RopeOffset.Z + (downLeg ? downLeg.RopeOffset.Z : 0); 
        const triangle = {
            a: 0, 
            b: legs[0].RopeOffset.Z,
            c: leg1Vector.length()
        }
        triangle.a = Math.sqrt( triangle.c **2 - triangle.b ** 2)

        return { triangle, realHeight, realWidth }
    },[legs])

    const { ropeHeightList, selectedRopeIdx } = useMemo(()=>{

        const heightList = []
        let selectedRopeIdx = 0 
        for (let idx = 0; idx < ropes.length; idx++){  
            const height = Math.sqrt( ropes[idx].Length **2 - triangle.a ** 2)
            heightList.push( height )
            if(ropes[idx].UUID === overwrittenRopeId){
                selectedRopeIdx = idx
            }
            else if(ropes[idx].UUID === legs[0].SelectedBridleParts[0].UUID){
                selectedRopeIdx = idx
            }
        }

        if (stepIdx !== selectedRopeIdx)
            setState({ stepIdx: selectedRopeIdx })

        return { ropeHeightList: heightList, selectedRopeIdx }

    }, [ropes,legs])

    const { scaleSteps, startApexPos } = useMemo(()=>{
        const startApexPos:TPoint2D = [0,0]
        // define scale steps for leg1 based on the ropes given in inventory
        const scaleSteps = []
        for (let idx = 0; idx < ropes.length; idx++){  
            scaleSteps.push( (maxScale/realHeight) * ropeHeightList[idx] + TOP_LIMIT )  
        }
        
        const xOffset = crossArmsPositions[0][0]
        startApexPos[0] = ( (canvasSize[0] - xOffset*2 ) / realWidth) * triangle.a + crossArmsPositions[0][0]
        startApexPos[1] = scaleSteps[selectedRopeIdx]

        return { scaleSteps, startApexPos }
    }, [canvasSize] )

    const legLables = useMemo(()=>{
        if (stepIdx === -1) 
            return []
        const lables = []
        if(stepIdx !== selectedRopeIdx ){
            lables[0] = [ ropes[stepIdx].Length/1000 + "m"] 
            lables[1] = ["..."] 
        } else {
            for (const leg of legs){
                const lableParts = []
                for ( const part of leg.SelectedBridleParts) {
                    // if(obj.InventoryType === TInventoryType.BridlePart_Rope){
                    // }
                    if(part.IsShackle) {continue;}
                    lableParts.push( part.Length/1000 + "m")
                }
                lables.push(lableParts)
            }
        }

        return lables
    }, [legs, stepIdx])

    function onApexChange(v:number){

        for (let idx = 0; idx < scaleSteps.length; idx++) {
            const step = scaleSteps[idx]
            if(isNaN(step)){
                continue;
            }
            const diff = step - TOP_LIMIT - v*maxScale  
            if( Math.abs(diff) <= 20 ){
                if(scaleSteps[stepIdx] !== step){
                    setState({ stepIdx:idx })
                }
            }
        }
    }

    function applyChange(ropeIdx:number){
        const offset = legs[0].RopeOffset
        offset.Z = Math.sqrt( ropes[ropeIdx].Length **2 - triangle.a ** 2)
        onChange(ropes[ropeIdx].UUID)
    }

    return (
        <>
            {crossArmsPositions.map((point,idx)=>(
                <LineWithLabel 
                    key={idx}
                    points={[
                        point[0]+CROSSARM_SIZE/2, 
                        point[1]+CROSSARM_SIZE/2, 
                        startApexPos[0], 
                        scaleSteps[stepIdx]
                    ]}
                    labelTexts={legLables[idx] || [] } 
                />
            ))}
            { downLeg && ( 
                <LineWithLabel
                    points={[startApexPos[0], scaleSteps[stepIdx], startApexPos[0], canvasSize[1]-bottomOffset ]}
                    labelTexts={[]}
                    dash={[10, 5]}
                />
            )}
            <Regulator 
                bottom={bottomLimit}
                top={TOP_LIMIT}
                pos={[startApexPos[0], scaleSteps[stepIdx]]}
                onChange={onApexChange}
                onRelease={()=> applyChange(stepIdx)}
            />
            <Circle 
                x={ startApexPos[0] } 
                y={ TOP_LIMIT } 
                radius={ 3 } 
                fill="green"
            />
        </>
    )
}

const Layout:React.FC<ILayoutProps> = React.memo( (props:ILayoutProps) => {

    const { canvasSize, bottomOffset, rotated, crossArmsPositions } = props.drawingContext

    return (
        <>
            <div className={['bridle-drawing', (rotated)? 'rotated': 'normal'].join(" ")}>
                <Stage
                    width={canvasSize[0]}
                    height={canvasSize[1]}
                >
                    <Layer>
                        {props.children}
                        {
                            crossArmsPositions.map((point, idx)=>(
                                <React.Fragment key={idx}>
                                    <Circle 
                                        x={point[0] + CROSSARM_SIZE/2 } 
                                        y={point[1] + CROSSARM_SIZE/2 } 
                                        radius={ CROSSARM_SIZE*0.7 } 
                                        fill="green" 
                                    />
                                    <Rect
                                        x={point[0]}
                                        y={point[1]}
                                        width={CROSSARM_SIZE}
                                        height={CROSSARM_SIZE}
                                        fill="red"
                                        shadowBlur={10}
                                    />
                                </React.Fragment>
                            ))
                        }
                        <Image
                            x={-30}
                            y={ canvasSize[1]- bottomOffset } // bottomOffset 
                            image={IMAGE_TRAVERSE} 
                        />
                    </Layer>
                </Stage>
            </div>
            <div>
                <Button onClick={props.onRotate}><Icon name="sync" /> </Button>
                <Button onClick={props.onReset}><Icon name="undo" /> </Button>
            </div>
        </>
    )
})

//  ===============================================================
//  ========= SUB-COMPONENTS: ======================================
//  ================================================================ 

const wait = (t) => new Promise( (resolve,_)=> setTimeout(resolve,t))

async function dotGrow(dragPoint){
    dragPoint.to({
        scaleX: 2.5,
        scaleY: 2.5,
        duration: 0.3,
    });
    await wait(300)
}

async function dotShrink(dragPoint){
    dragPoint.to({
        scaleX: 1,
        scaleY: 1,
        duration: 0.1,
    });
    await wait(100)
}

const Regulator:React.FC<IRegulatorProps> = (props)=> {
    const { pos, bottom, top, onChange, onRelease } = props
    let dragPoint: Konva.Node;
    const animationControl = { run:true }

    function drag(position:Vector2d){
        const y = (position.y > bottom)? bottom : (position.y < top)? top :position.y 
        const value = (y - top) / (bottom-top)
        onChange(value)
        return { x: pos[0], y: pos[1] } as Vector2d
    }

    async function mouseOver(e){
        const container = e.target.getStage().container();
        container.style.cursor = "ns-resize";
        dotGrow(dragPoint)
    }

    function mouseLeave(e){
        const container = e.target.getStage().container();
        container.style.cursor = "default";
        dotShrink(dragPoint)
    }

    return (
        <Circle 
            ref={(node) => {
                dragPoint= node;
            }}
            x={pos[0]} 
            y={pos[1]} 
            radius={ 5 } 
            fill="red"
            draggable
            dragBoundFunc={(pos: Vector2d)=> drag(pos)}
            style={{ cursor:"pointer" }}
            onMouseOver={mouseOver}
            onMouseLeave={mouseLeave}
            onMouseDown={()=>{animationControl.run = false}}
            onDragEnd={()=>onRelease()}
        />
    )
}

const LineWithLabel:React.FC<ILineWithLabelProps> = (props)=> {
  
    const { points:[x1, y1, x2, y2], labelTexts } = props
    const lables = [...labelTexts]
    // Calculate angle of the line
    const { angle, rotate } = useMemo(()=>{
        let angle = Math.atan2(y2 - y1, x2 - x1) * (180 / Math.PI);
        const rotate = angle > 90 || angle < -90
        angle = rotate ? angle + 180 : angle
        return { angle, rotate }
    }, [y2, y1, x2, x1])

    let offsets = labelTexts.map(i => ( (8 * i.length) + 15))
    const completeLableLength = offsets.reduce((pre, curr) => (pre + curr), 0)

    if(rotate) {
        lables.reverse()
        offsets.reverse()
    }
    offsets = [0, ...offsets]

    // Calculate label position halfway between the points
    const labelX = (x1 + x2) / 2 ;
    const labelY = (y1 + y2) / 2 ;

    return (
        <React.Fragment>
            <Line
                points={props.points}
                stroke={'black'}
                tension={1}
                dash={props.dash}
            />
            {lables.map((text,idx)=>(
                <Text
                    key={idx}
                    x={labelX }
                    y={labelY}
                    text={text}
                    fontSize={16}
                    fill={LEG_LABLE_COLOR_PALETTE[rotate ? labelTexts.length -1 -idx : idx ]}
                    rotation={angle } 
                    offsetX={ (offsets[idx] + offsets.slice(0,idx).reduce((partialSum, a) => partialSum + a, 0) - completeLableLength/2 ) * (rotate ? -1 : 1) }
                    offsetY={rotate?-2:-5}
                />
            ))}
        </React.Fragment>
    );
}

export default BridleDrawing;