// DEV/TEST IDs

module.exports = {
    BASE_URL    : process.env.REACT_APP_DEVELOPMENT ? "http://localhost:4200" : process.env.PUBLIC_URL === "." ? process.env.REACT_APP_PUBLIC_URL : process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "https://development.production-assist.com",
    DEVELOPMENT_SERVER    : process.env.PUBLIC_URL === "https://development.production-assist.com" ? true : !process.env.REACT_APP_PUBLIC_URL,
    DEVELOPMENT : !process.env.NODE_ENV || process.env.NODE_ENV === 'development',
    VERSION : process.env.REACT_APP_VERSION  ? process.env.REACT_APP_VERSION : "999.0.0",
    BUILD : process.env.REACT_APP_BUILD  ? process.env.REACT_APP_BUILD : "LOCAL",
    HASH : process.env.REACT_APP_HASH  ? process.env.REACT_APP_HASH : "GIT",
    COMPILED_FOR_MA3 : process.env.REACT_APP_COMPILED_FOR_MA3  ? true : false,
    COMPILED_FOR_VW : process.env.REACT_APP_COMPILED_FOR_VW  ? true : false,
    BUILD_CHANNEL : process.env.REACT_APP_BUILD_CHANNEL,
}
