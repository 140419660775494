//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Form } from 'semantic-ui-react'
// @ts-ignore
import LocalizedStrings from "../../localization/RandomizeChainShorten";
import LRModal from '../Basics/BasicModal';
import UnitInput from '../Basics/BasicUnitInput';

import { globalCallbacks } from '../../util/callback';
import { BASE_UNIT_PAGE_LENGTH } from '../../util/defines';

declare const  window: any;


interface RandomizeChainShortenModalState {
  open: Boolean
  Min: number,
  Max: number,

}

//-----------------------------------------------------------------------------
// The component for the TreeControl
class RandomizeChainShortenModal extends Component<{}, RandomizeChainShortenModalState> 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
      Min: -30,
      Max: +30,

    }
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }

  show = async() => 
  {
    this.setState( { 
        open : true, 
      });
  }

  close = () => 
  {
    window.LR_RandomizeChainShorten({Min: this.state.Min, Max: this.state.Max})
    this.setState({open : false});
  }
    
  render() 
  {
    let open = this.state.open;
    if(!open) {return <div/>}

    return (
      <LRModal  open={open} 
                onCancelClick={this.close} 
                closeOnDimmerClick={false} 
                size="fullscreen"
                scrolling={false}
                title={LocalizedStrings.Header}
                customOKText={LocalizedStrings.Close}
                onOkClick={this.close}>
                  <Form>
                  <UnitInput name="Min" label={LocalizedStrings.Min} baseUnit={BASE_UNIT_PAGE_LENGTH} value={this.state.Min} onStateUpdate={(name, val) => {this.setState({Min: val})}}/>
                  <UnitInput name="Max" label={LocalizedStrings.Max} baseUnit={BASE_UNIT_PAGE_LENGTH} value={this.state.Max} onStateUpdate={(name, val) => {this.setState({Max: val})}}/>
                  </Form>
                  
      </LRModal>)
  }
                 
  setUpCallbacks()
  {
    globalCallbacks.ShowRandomizeChainShortenValues = async () => 
    {       
      this.show();
    } 
  }
}

export default RandomizeChainShortenModal