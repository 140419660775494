import React from "react";
import { Segment, Menu, Icon, Label } from "semantic-ui-react";

import LocalizedStrings from "../../localization/SceneTimeLineComponent";
import ResourceSchedule from "./ResourceSchedule";
import { globalCallbacks } from "../../util/callback";



const sideWidth = "10em";

const minStepDist = 100;

const scrollFactor = 5000

const labelStepsPer60 = [
  1,
  2,
  5,
  10,
  15,
  30
]

const labelSteps = [
  ...labelStepsPer60, 
  ...labelStepsPer60.map(step => step * 60), 
  ...labelStepsPer60.map(step => step * 60 * 60)
].map(s => s*30)



const noselect = {
  "-webkit-touch-callout": "none",
  "-webkit-user-select": "none",
  "-khtml-user-select": "none",
  "-moz-user-select": "none",
  "-ms-user-select": "none",
  "user-select": "none"
}
const styles = {
  main: {
    ...noselect,
    zIndex: 0,
    position: "relative",
    height: "1.5em",
    borderCollapse: "collapse"
  },
  sideBar: {
    ...noselect,
    zIndex: 2,
    textAlign: "left",
    position: "absolute",
    left: 0,
    width: sideWidth,
    borderTopStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#dddddd",
    borderRightStyle: "solid",
    height: "100%",
    paddingLeft: '0.5em',
    overflow: 'hidden',
  },
  content: {
    ...noselect,
    zIndex: 1,
    textAlign: "left",
    position: "absolute",
    left: sideWidth,
    right: 0,
    borderTopStyle: "solid",
    borderWidth: 1,
    overflow: "hidden",
    height: "100%"
  },
  entry: {
    ...noselect,
    position: 'absolute',
    overflow: 'hidden',
    height: '100%',
    textAlign: 'center',
    borderLeftStyle: 'solid',
    borderRightStyle: 'solid',
    borderWidth: 1,
    whiteSpace: 'nowrap'
  }
};

let tlIndex = 0;

class NewTimeLineRow extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      moving : false,
      locChange: this.props.change
    }
  }

  componentDidMount(){
    let div = document.getElementById(this.props.parentID)
    div.addEventListener("mousemove", (ev)=>this.onMouseMove(ev))
    div.addEventListener("mouseup", (ev)=>this.onMouseUp(ev))
  }

  render() {
    let { view, externUpdate, change } = this.props;

    if(externUpdate){
      this.setState({
        locChange: change
      })
    }




    change = this.state.locChange
    return (
      <div style={this.colorEven(styles.main)}>
        <div style={{...styles.sideBar, paddingLeft:"0.5em"}}>
          <b>{change.Name}</b>
        </div>
        <div style={styles.content}>
        {
          change.TimeCode ?
          <div
          onMouseDown={(ev)=>this.onMouseDown(ev)}
          key = {change.UUID} 
          style={{
            ...styles.entry,
            left  : this.toPercent(change.TimeStart, view.size, view.start),
            width : this.toPercent(change.TimeCode,  view.size, 0    ),
            backgroundColor: this.props.i % 2 === 0 ? 'rgba(0,0,0,0.1)' : 'rgba(0,0,0,0.19)'
          }}
          ><small>{this.getTimeString(change.TimeCode)}</small>
          </div> : null
        }
            
        </div>
      </div>
    );
  }

  getTimeString(time) {
    let tInS = time/30; 
    let seconds = tInS % 60;
    let minutes = ((tInS - seconds) / 60) % 60;
    let hours = ((tInS - seconds) / 60 - minutes) / 60;
    seconds = Math.floor(seconds)
    return (
      (tInS < 0 ? '-' : '') +
      Math.abs(hours).toString().padStart(2, "0") +
      ":" +
      Math.abs(minutes).toString().padStart(2, "0") +
      ":" +
      Math.abs(seconds).toString().padStart(2, "0")
    );
  }

  colorEven = (style) => {
    return {
      ...style,
      backgroundColor: this.props.i % 2 === 0 ? 'rgba(0,0,0,0.1)' : undefined
    }
  }

  toPercent(pos, size, start) {
    return (((pos - start) / size) * 100).toString() + "%";
  }

  onMouseDown(ev){
    this.setState({moving: true})
  }

  onMouseUp(ev){
    if(this.state.moving){
      this.setState({
        moving: false
      })
      let obj = this.state.locChange
      window.LR_SetTimePhaseChange({UUID: obj.UUID, TimeStart: obj.TimeStart})
    }
  }

  onMouseMove(ev){

    if(this.state.moving){
      let TimeStart = this.state.locChange.TimeStart + this.getTimePos(ev.movementX)/2
      TimeStart = Math.max(TimeStart, 0)
      TimeStart = Math.round(TimeStart)
      this.setState({
        locChange: {
          ...this.state.locChange,
          TimeStart,
          TimeEnd: TimeStart + this.state.locChange.TimeCode
        }
      })
    }
      
  }

  getTimePos = (clientX) => {
    const {view: { size }, parentID} = this.props
    let tlRect = document.getElementById(parentID).firstChild.lastChild.getBoundingClientRect()
    let rPos = clientX/tlRect.width
    return rPos * size
  }

}

const ACTIVE_VIEW_GANT = 0
const ACTIVE_VIEW_USER = 1

export default class NewTimeLine extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      view: {
        size: 54000,
        start: 0
      },
      move: {
        active: undefined,
        startPos: 0,
        currentPos: 0
      },
      touchStart: 0,
      touchZoom: 0,
      tStartSize: 0,
      timePhases: [],
      Users:[],
      activePhase: 0,
      externUpdate: false,
      activeView: ACTIVE_VIEW_USER
    };
    this.id = 'timeline-' + tlIndex++
    this.ref = React.createRef()
  }

  lOptions = {
    capture: true,
    passive: false
  }

  render(){
    const {  activePhase, timePhases } = this.state;
    if(timePhases.length === 0){
      return <div/>
    }

    if(activePhase > timePhases.length){
      this.setState({
        activePhase: 0
      })
    }
    let out = (

    <>
      <Menu pointing attached>
        <Menu.Item active={this.state.activeView === ACTIVE_VIEW_USER} onClick={()=>{this.setState({activeView: ACTIVE_VIEW_USER})}}>
          User Overview
        </Menu.Item>
        <Menu.Item active={this.state.activeView === ACTIVE_VIEW_GANT} onClick={()=>{this.setState({activeView: ACTIVE_VIEW_GANT})}}>
          Gant
        </Menu.Item>
      </Menu>
      <Menu pointing secondary attached>
        {timePhases.map((phase, index) => <Menu.Item key={phase.UUID} active={index === activePhase} onClick={()=>this.setState({activePhase: index, externUpdate: true})}>{phase.Name}</Menu.Item>)}
        <Menu.Item>
        {this.state.activeView === ACTIVE_VIEW_GANT ?
          <Label as="a" color="green" compact onClick={() => this.onAddNewTimePhase()}>
            <Icon name="plus"/>{LocalizedStrings.AddNewTimeLineEntry}
          </Label>
          :
          <>
            <Label as="a" color="green" compact onClick={() => this.onAddNewTimePhase()}>
              <Icon name="plus"/>{LocalizedStrings.AddNewTimeLineEntry}
            </Label>
            <Label as="a" color="green" onClick={() => this.onAddNewUser()}>
                <Icon name="plus"/>{LocalizedStrings.AddNewUser}
            </Label>
          </>
        }
        </Menu.Item>
      </Menu>
      {this.state.activeView === ACTIVE_VIEW_GANT ? this.renderTimeLine() : this.renderUsersSchedule()}
      </>

    );

    if(this.state.externUpdate){
      this.setState({externUpdate: false})
    }
    return out;
  }
  renderUser()
  {
    const {  view, Users } = this.state;
    return(
    <div 
    id        = { this.id }
    style     = { this.style }
  >
  <div style={styles.main}>
    <div style={styles.sideBar}>
      <small>
        {this.getTimeString(view.start)} - {this.getTimeString(view.start + view.size)}
      </small>
    </div>
    <div style={styles.content} ref={this.ref}>
      {this.getTimestamps()}
    </div>
      {Users.map((u, i)=><NewTimeLineRow key={i} User={u}/>)}
  </div>
  </div>)
  }

  renderUsersSchedule()
  {
    let { activePhase, timePhases } = this.state;

    let selectedTimePhases = timePhases.find(phase => phase.Order === activePhase)

    return (
            <ResourceSchedule timePhases={selectedTimePhases} />
    )
  }

  renderTimeLine()
  {
    const {  view, activePhase, timePhases } = this.state;

    return(
    <div 
        id        = { this.id }
        style     = { this.style }
      >
      <div style={styles.main}>
        <div style={styles.sideBar}>
          <small>
            {this.getTimeString(view.start)} - {this.getTimeString(view.start + view.size)}
          </small>
        </div>
        <div style={styles.content} ref={this.ref}>
          {this.getTimestamps()}
        </div>
      </div>
      {timePhases[activePhase].TimePhaseChangeObjects.map((el, i)=><NewTimeLineRow key={i} i={i} view={view} change={el} parentID={this.id} externUpdate={this.state.externUpdate}/>)}
      <Segment basic textAlign='center'>
        <Label as="a" color="green" onClick={() => this.onAddNewTimePhaseChange()}>
          <Icon name="plus"/>{LocalizedStrings.AddNewTimePhaseChange}
        </Label>
      </Segment>
      </div>
      )
  }

  onAddNewTimePhase(){
    window.LR_AddNewTimePhase();
  }

  onAddNewTimePhaseChange(){
    const { activePhase, timePhases } = this.state;
    window.LR_AddNewTimePhaseChange({ UUID : timePhases[activePhase].UUID });
  }

  onAddNewUser()
    {
        window.LR_AddNewUser();
    }

  getTimestamps = () => {
    const {
      view: { size, start }
    } = this.state;
    const end = start + size;
    const stepSize = this.getStepSize();
    let lines = [];
    let currentLinePos = start - (start % stepSize);

    while (currentLinePos < end) {
      lines.push(
        <div
          key = {currentLinePos}
          style={{
            height: "100%",
            borderLeftStyle: "solid",
            borderWidth: 1,
            position: "absolute",
            left: this.toPercent(currentLinePos, size, start)
          }}
        >
          <small>{this.getTimeString(currentLinePos)}</small>
        </div>
      );
      currentLinePos += stepSize;
    }
    return lines;
  };

  toPercent(pos, size, start) {
    return (((pos - start) / size) * 100).toString() + "%";
  }

  getStepSize = () => {
    const { view: { size } } = this.state;

    let maxSteps = 10
    if(this.ref.current) {
      maxSteps = Math.floor(this.ref.current.getBoundingClientRect().width/minStepDist)
    }
    else {
      this.forceUpdate()
      return 60
    }
    let i = 0
    while (i < labelSteps.length && size / labelSteps[i] > maxSteps) {
      i++
    }

    if(i >= labelSteps.length) {
      i = labelSteps.length - 1
    }
    
    return labelSteps[i]
  }

  getTimeString(time) {
    let tInS = time/30; 
    let seconds = tInS % 60;
    let minutes = ((tInS - seconds) / 60) % 60;
    let hours = ((tInS - seconds) / 60 - minutes) / 60;
    seconds = Math.floor(seconds)
    return (
      (tInS < 0 ? '-' : '') +
      Math.abs(hours).toString().padStart(2, "0") +
      ":" +
      Math.abs(minutes).toString().padStart(2, "0") +
      ":" +
      Math.abs(seconds).toString().padStart(2, "0")
    );
  }


  componentDidMount = async () => {
    if(this.state.activeView === ACTIVE_VIEW_GANT)
    {
      document.addEventListener('wheel', this.scroll, this.lOptions)
      let divEl = document.getElementById(this.id)
      divEl.addEventListener("touchmove", this.onTouchMove)
      divEl.addEventListener("touchstart", this.onTouchStart)
    }
      globalCallbacks.updateTimeline = async () =>
      {
        let timePhases = (await window.LR_GetTimePhases()).TimePhases
        let users = await window.LR_GetUsers()
  
        this.setState({
          timePhases,
          Users: users.Users,
          externUpdate: true
        })
      }
  
      await globalCallbacks.updateTimeline();
   
  }

  componentWillUnmount() {
    if(this.state.activeView === ACTIVE_VIEW_GANT)
    {
      document.removeEventListener('wheel', this.scroll, this.lOptions)
    }
  }

  scroll = e => {
    let tlRect = document.getElementById(this.id)?.getBoundingClientRect()
    const {view: { size, start }} = this.state

    if( e.clientX > tlRect?.left &&
        e.clientX < tlRect?.right && 
        e.clientY > tlRect?.top && 
        e.clientY < tlRect?.bottom) {
      e.preventDefault()
      e.stopPropagation()
      
      if(e.ctrlKey) {
        let currentPos = this.getTimePos(e.clientX)
        let relPos = (currentPos - start)/size
        let newSize = (1.001**e.deltaY) * size
        let newStart = currentPos - (relPos*newSize)

        this.setState({
          view: {
            ...this.state.view,
            start: newStart,
            size: newSize
          }
        })
      } else {
        let newStart = this.state.view.start + (e.deltaY*this.state.view.size)/scrollFactor
        this.setState({
          view: {
            ...this.state.view,
            start: newStart
          }
        })
      }
    }
  };

  getDistFromTouches = (t1, t2) => Math.sqrt((t1.clientX-t2.clientX)**2 + (t1.clientY-t2.clientY)**2)

  getPosFromTouches = (t1, t2) => (t1.clientX + t2.clientX)/2

  onTouchMove = e => {

    let {view: {size, start}, touchZoom, tStartSize, touchStart} = this.state
    //e.persist()
    if(e.touches.length === 2) {
      let dist = this.getDistFromTouches(e.touches[0], e.touches[1])
      let pos = this.getTimePos(this.getPosFromTouches(e.touches[0], e.touches[1]))
      
      let zoomFact = touchZoom/dist

      let newSize = tStartSize * zoomFact
      let relPos = (pos - start)/size

      let newStart = touchStart - (relPos*newSize)

      this.setState({
        view: {
          start: newStart,
          size: newSize
        }
      })
      e.stopPropagation()
      e.preventDefault()
    }
  }

  onTouchStart = e => {
    if(e.touches.length === 2) {
      this.setState({
        touchStart: this.getTimePos(this.getPosFromTouches(e.touches[0], e.touches[1])),
        touchZoom: this.getDistFromTouches(e.touches[0], e.touches[1]),
        tStartSize: this.state.view.size
      })
    }
  }


  onMouseUp = () => {
  }


  getTimePos = (clientX) => {
    const {view: { size, start }} = this.state

    let tlRect = document.getElementById(this.id).firstChild.lastChild.getBoundingClientRect()
    let rPos = (clientX - tlRect.x)/tlRect.width
    return rPos * size + start
  }

}
