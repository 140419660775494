//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Form, Message, Radio } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/SceneTimeLineComponent";
import LocTimeStrings from "../../localization/TimeStrings";
import LRModal from '../Basics/BasicModal';
import UnitInput from '../Basics/BasicUnitInput';
import DateHelper from '../../util/DateHelper';
import { BASE_UNIT_DATE, BASE_UNIT_TIME_CODE, BASE_UNIT_COLOR } from "../../util/defines";

export class TimeLineStepModal extends Component {
  constructor(props) {
    super(props)
    this.state =
    {
      loaded: false,
      oldOpen: false,
      obj: undefined,
      options: undefined,
      possibleChanges: undefined,
      relative: true,
    }
  }

  componentDidMount = async () =>
  {
    this.componentDidUpdate()

    let users   = await window.LR_GetUsers()

    let members = users.Users.map((user) => 
    {
        return {
            text: <>{user.Name}</>,
            key: user.UUID,
            value: user.UUID,
        };
    });

    this.setState({ Users: members});

  }

  componentDidUpdate() {
    if (this.state.oldOpen !== this.props.data.open) {
      this.setState({ oldOpen: this.props.data.open, error: false, relative: true })
      if(this.props.data.open){
        this.getData()
      }
    }
  }

  close() {
    this.setState({loaded: false})
    if (this.props.onClose)
      this.props.onClose()
  }

  onCloseButton() {
    this.close()
  }

  onOkButton() {
    let nData = this.state.obj

   
    if (nData.TimeCode === undefined) {
      return
    }
    if(this.state.StartError || this.state.TimeError){
      return
    }
    window.LR_SetTimePhaseChange(nData);

    this.close()
  }

  render() {
    
    let modal = this.props.data;    
    if (!modal.open || !this.state.loaded) { return <div /> }
    let { TurnOff, TurnOn, DependsOn } = this.state.obj;
    let options = this.state.options
    let possibleChanges = this.state.possibleChanges

    let turnOffOptions = [];
    let turnOnOptions = [];
    options.forEach(option => {
      let availableOff = true;
      for (let i = 0; i < TurnOn.length; i++) {
        if (TurnOn[i] === option.value) { availableOff = false; }
      }
      if (availableOff) { turnOffOptions.push(option); }

      let availableOn = true;
      for (let i = 0; i < TurnOff.length; i++) {
        if (TurnOff[i] === option.value) { availableOn = false; }
      }
      if (availableOn) { turnOnOptions.push(option); }

    });

    let dateStartCode = new DateHelper(this.state.obj.TimelineStartDate).addRelativeFrameCount(this.state.obj.TimeStart)
   
    let dateTimeCode = dateStartCode.addRelativeFrameCount(this.state.obj.TimeCode)

    dateStartCode = dateStartCode.toISOString()
    dateTimeCode = dateTimeCode.toISOString()
    
    let {TimeError, StartError} = this.doErrCheck()

    let err = []
    if(StartError){
      err.push(<Message negative>
                <Message.Header>{LocalizedStrings.Error}</Message.Header>
                {LocalizedStrings.StartErrorMsg}
              </Message>)
    }
    if(TimeError){
      err.push(<Message negative>
        <Message.Header>{LocalizedStrings.Error}</Message.Header>
        {LocalizedStrings.TimeErrorMsg}
      </Message>)
    }

    return (
      <LRModal size="large"
        open={modal.open}
        title={LocalizedStrings.PresetSelection}
        onCancelClick={() => this.onCloseButton()}
        onOkClick={() => this.onOkButton()}
        scrolling={false}
        >
        
        <Form>

          <Form.Input name="Name"
            label={LocalizedStrings.TimeLineEntryName}
            inline
            fluid
            onKeyDown= {(e) => {if(e.keyCode === 13){e.target.blur()}}}
            value={this.state.obj.Name}
            onChange={(e, { name, value }) => this.changeValue(name, value)} />
          
          <Form.Group widths="equal">
           <Form.Select  label="Users"
                          compact
                          search
                          multiple
                          options = {this.state.Users}
                          value = {this.state.obj.AssingedUsers}
                          onChange = {(event, {value})=>{ this.setState({...this.state, obj:{...this.state.obj, AssingedUsers:value}})}}/>

            <UnitInput name="Color" label="Color" fluid inline onStateUpdate={(name, value) => this.changeValue(name, value)} value={this.state.obj.Color} baseUnit={BASE_UNIT_COLOR}/>
          </Form.Group>

          <UnitInput name="Color" label="Color" fluid inline onStateUpdate={(name, value) => this.changeValue(name, value)} value={this.state.obj.Color} baseUnit={BASE_UNIT_COLOR}/>

          <Form.Group widths="equal">

            <Form.Dropdown
              label={LocalizedStrings.PresetsToTurnOff}
              placeholder={LocalizedStrings.Presets}
              fluid
              clearable
              multiple
              selection
              lazyLoad
              options={turnOffOptions}
              value={TurnOff}
              onChange={(e, { value }) => this.changeValue("TurnOff", value)}
              renderLabel={(label) => {
                return (
                  {
                    color: 'red',
                    content: label.text,
                    icon: 'chess queen'
                  });
              }} />

            <Form.Dropdown
              label={LocalizedStrings.PresetsToTurnOn}
              placeholder={LocalizedStrings.Presets}
              fluid
              clearable
              multiple
              selection
              lazyLoad
              options={turnOnOptions}
              value={TurnOn}
              onChange={(e, { value }) => this.changeValue("TurnOn", value)}
              renderLabel={(label) => {
                return (
                  {
                    color: 'green',
                    content: label.text,
                    icon: 'chess queen'
                  });
              }} />

          </Form.Group>
          <Form.Dropdown
            label={LocalizedStrings.DependsOn}
            placeholder={LocalizedStrings.DependsOn}
            fluid
            clearable
            multiple
            selection
            lazyLoad
            options={possibleChanges}
            value={DependsOn}
            onChange={(e, { value }) => this.changeDependsOn(value)}
          />
          <Form.Group widths="equal">
            <Form.Group grouped>
                <Form.Field
                style={{marginLeft:"0.5em", marginTop:"0em", marginRight:"0.5em"}}
                control={Radio}
                label={LocalizedStrings.Absolute}
                value='1'
                checked={!this.state.relative}
                onChange={()=>this.setState({relative: false})}
              />
              <Form.Field
                style={{marginLeft:"0.5em", marginTop:"0em", marginRight:"0.5em"}}
                control={Radio}
                label={LocalizedStrings.Relative}
                value='2'
                checked={this.state.relative}
                onChange={()=>this.setState({relative: true})}
              />
            </Form.Group>
          
          {
            this.state.relative ? 
            <>
              <UnitInput label={LocalizedStrings.DifferenceToParent} fluid onStateUpdate={(name, value) => this.setStartCode(value, true)} value={this.state.obj.TimeStart} baseUnit={BASE_UNIT_TIME_CODE}/>
              <UnitInput label={LocalizedStrings.Duration} fluid onStateUpdate={(name, value) => this.setTimeCode(value, true)} value={this.state.obj.TimeCode} baseUnit={BASE_UNIT_TIME_CODE}/>
            </> :
            <>
              <UnitInput label={LocalizedStrings.StartDate} fluid onStateUpdate={(name, value) => this.setStartCode(value, false)} value={dateStartCode} baseUnit={BASE_UNIT_DATE}/>
              <UnitInput label={LocalizedStrings.StopDate} fluid onStateUpdate={(name, value) => this.setTimeCode(value, false)} value={dateTimeCode} baseUnit={BASE_UNIT_DATE}/> 
            </>                
          }
          </Form.Group>
          {
            err
          }
          <Form.Group widths="equal">
            <UnitInput name="FadeIn"
              label={LocalizedStrings.FadeIn}
              baseUnit={BASE_UNIT_TIME_CODE}
              inline
              fluid
              labelPosition="right"
              value={this.state.obj.FadeIn}
              onStateUpdate={(name, value) => this.changeValue(name, value)}
              step="1">
            </UnitInput>

            <UnitInput name="FadeOut"
              label={LocalizedStrings.FadeOut}
              baseUnit={BASE_UNIT_TIME_CODE}
              inline
              fluid
              labelPosition="right"
              value={this.state.obj.FadeOut}
              onStateUpdate={(name, value) => this.changeValue(name, value)}
              step="1">
            </UnitInput>
          </Form.Group>
          <Form.Group widths="equal">
            <UnitInput name="DelayIn"
              label={LocalizedStrings.DelayIn}
              baseUnit={BASE_UNIT_TIME_CODE}
              inline
              fluid
              labelPosition="right"
              value={this.state.obj.DelayIn}
              onStateUpdate={(name, value) => this.changeValue(name, value)}
              step="1"
            >
            </UnitInput>
            <UnitInput name="DelayOut"
              label={LocalizedStrings.DelayOut}
              baseUnit={BASE_UNIT_TIME_CODE}
              inline
              fluid
              labelPosition="right"
              value={this.state.obj.DelayOut}
              onStateUpdate={(name, value) => this.changeValue(name, value)}
              step="1">
            </UnitInput>
          </Form.Group>

        </Form>
      </LRModal>
    )
  }

  async changeDependsOn(val){
    this.changeValue("DependsOn", val)
    let newDate = await window.LR_GetNeededTimeFromPhase(val.map(v=>{return {"UUID": v}}))
    this.changeValue("StartDate", newDate.TheoreticalStart)
  }

  doErrCheck(){

    return {
      TimeError: this.state.obj.TimeCode < 0,
      StartError: this.state.obj.TimeStart < 0
    }
  
  }

  setStartCode(val, relative){
 
    let tc = 0
   
    if(relative){
      tc = val
    }else{
      tc = new DateHelper(this.state.obj.TimelineStartDate).getTimeDiffInFrames(new DateHelper(val))
    }

    this.changeValue("TimeStart", tc)
  }

  setTimeCode(val, relative){
    let tc = 0

    if(relative){
      tc = val
    }else{

      tc = new DateHelper(this.state.obj.TimelineStartDate).addRelativeFrameCount(this.state.obj.TimeStart).getTimeDiffInFrames(new DateHelper(val))
    }
    this.changeValue("TimeCode", tc)

  }

  async getData() {

    function getDependencies(uuids, changeList) {
      function unique(array) {
        return array.filter((v, i, a) => a.indexOf(v) === i)
      }

      let dependencies = []
      changeList.forEach(change => {
        if (uuids.find(id => id === change.UUID) !== undefined) {
          dependencies.push(change.UUID)
          change.DependsOn.forEach(dep => {
            dependencies.push(dep)
          })
        }
      })
      dependencies = unique(dependencies)
      if (dependencies.length === uuids.length) return dependencies
      else return getDependencies(dependencies, changeList)
    }

    let data = await window.LR_GetTimeLineStepData({ UUID: this.props.data.uuid, Async: true });
    let Presets = data.Presets
    Presets.sort(function (a, b) { return a.Order - b.Order });

    let options = Presets.map(preset => {
      return ({ key: preset.UUID, value: preset.UUID, text: preset.Name });
    });

    let possibleChanges = data.AllChanges.filter(change => {
      let deps = getDependencies([change.UUID], data.AllChanges)
      return deps.find(id => id === data.Object.UUID) === undefined
    })
      .map(change => {
        return ({ key: change.UUID, value: change.UUID, text: change.Name });
      })

    this.setState({
      obj: {
        ...data.Object,
        TimeStart: new DateHelper(data.Object.TimelineStartDate).getTimeDiffInFrames(new DateHelper(data.Object.StartDate))
      },
      options,
      possibleChanges,
      loaded: true
    })
  }

  changeValue(name, value) {
    this.setState({ obj: {...this.state.obj, [name]:value} })
  }
}

class TimeLine extends Component{
  constructor(props) {
    super(props)
    this.state =
    {
      loaded: false,
      oldOpen: false,
      obj: undefined,
      sysOptions: undefined
    }
  }

  componentDidMount(){
    this.componentDidUpdate()
  }

  componentDidUpdate() {
    if (this.state.oldOpen !== this.props.data.open) {
      this.setState({ oldOpen: this.props.data.open })
      if(this.props.data.open){
        this.getData()
      }
    }
  }

  close() {
    this.setState({loaded: false})
    if (this.props.onClose)
      this.props.onClose()
  }

  onCloseButton() {
    this.close()
  }

  onOkButton() {
    let req = {
      UUID: this.props.data.uuid, 
      Name: this.state.obj.Name
    }
    if(this.state.obj.StartDateRef !== -1){
      req["StartDateRef"] = this.state.obj.StartDateRef
      req["StartDateOffset"] = this.state.obj.StartDateOffset
    }else{
      req["StartDateRef"] = -1
      req["StartDate"] = this.state.obj.StartDate
    }
    window.LR_SetTimePhase(req)
    this.close()
  }

  changeValue(name, value) {
    let obj = this.state.obj
    obj[name] = value
    this.setState({ obj })
  }

  render(){
    let modal = this.props.data
    if (!modal.open || !this.state.loaded) { return <div /> }
    let TimeOptions = [
      {
        value: -1,
        text: LocalizedStrings.OwnValue,
        currval: this.state.obj.StartDate
      },
      {
        value: 0,
        text: LocTimeStrings.DueDate,
        currval: this.state.sysOptions.DueDate
      },
      {
        value: 1,
        text: LocTimeStrings.EventDate,
        currval: this.state.sysOptions.ConstructionDate
      },
      {
        value: 2,
        text: LocTimeStrings.EventDate,
        currval: this.state.sysOptions.EventDate
      },
      {
        value: 3,
        text: LocTimeStrings.DismantleDate,
        currval: this.state.sysOptions.DismantleDate
      }
    ]

    let spValue = this.state.obj.StartDateRef !== -1 ? Number(this.state.obj.StartDateRef) : -1

    return(
    <LRModal size="large"
    open={modal.open}
    title={LocalizedStrings.PresetSelection}
    onCancelClick={() => this.onCloseButton()}
    onOkClick={() => this.onOkButton()}
    scrolling={false}
    >
    <Form>

      <Form.Input name="Name"
        label={LocalizedStrings.TimeLineEntryName}
        inline
        fluid
        onKeyDown= {(e) => {if(e.keyCode === 13){e.target.blur()}}}
        value={this.state.obj.Name}
        onChange={(e, { name, value }) => this.changeValue(name, value)} />
        <Form.Dropdown
            label={LocalizedStrings.TimeSource}
            fluid
            selection
            options={TimeOptions}
            value={spValue}
            onChange={(e, { value }) => this.updateRefType(value)}
          />
      <UnitInput label={LocalizedStrings.StartDate} readOnly={spValue !== -1} value={TimeOptions[spValue+1].currval} onStateUpdate = {(name, value) => this.updateManualRef(value)} baseUnit={BASE_UNIT_DATE}/>
      {
          spValue !== -1 && <UnitInput label={LocalizedStrings.Offset} value={this.state.obj.StartDateOffset} onStateUpdate = {(name, value) => this.updateOffset(value)} baseUnit={BASE_UNIT_TIME_CODE}/>
      }
    </Form>
  </LRModal>
  )
  }

  updateManualRef(val){
    this.setState({
      obj: {
        ...this.state.obj,
        StartDate: val
      }
    })
  }

  updateOffset(val){
    this.setState({
      obj: {
        ...this.state.obj,
        StartDateOffset: val
      }
    })
  }

  updateRefType(val){
    this.setState({
      obj: {
        ...this.state.obj,
        StartDateRef: val
      }
    })
  }



  async getData() {
    let [data, sysOptions] = await Promise.all([window.LR_GetTimePhases(), window.LR_GetProjectSettings()])
    for(let i of data.TimePhases){
      if(i.UUID === this.props.data.uuid){
        this.setState({
          obj: i,
          loaded: true,
          sysOptions: sysOptions["DrawingSettings"]
        })
        break;
      }
    }
  }
}

class PresetSelection extends Component{

  render(){
    if(this.props.data.type === "step"){
      return <TimeLineStepModal data={this.props.data} onClose={this.props.onClose}/>
    }else if(this.props.data.type === "timeline"){
      return <TimeLine data={this.props.data} onClose={this.props.onClose}/>
    }else{
      return <div/>
    }
  }
}

export default PresetSelection