
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Search, Table, Label, Icon, Segment } from 'semantic-ui-react'
import LoadGroupNode from "./LoadGroupNode";
import LocalizedStrings from "../../localization/NavigationContainer";
import escapeRegExp from "lodash/escapeRegExp"
import filter from "lodash/filter"
import { IsElectronContext } from '../../util/defines';

import { globalCallbacks as mainCB } from '../../util/callback';
import { globalCallbacks as mockCB } from '../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB


const SORT_BY = "Name"

class LoadGroupTable extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = 
        {
            LoadGroups : [],

            sorting: null,
            indexMap: [],
        };

    }

    componentDidMount = () => 
    {
        this.setUpCallbacks();
        globalCallbacks.uploadeLoadGroups();
    }

    onDragEnd = (e) => {
        window.LR_ChangeLoadGroupOrder({UUID: this.state.drag.source, Index: this.state.drag.target})
    }

    render() 
    {

        return (
            <div style={{width:"100%", height:"100%"}}>
                 <Table style={{borderBottom: "none", margin:0}}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan='5' style={{zIndex: 0}} onClick={this.onHeaderClick}>
                                {<Icon link style={{floated: 'left', position: 'absolute', zIndex: 1}} name={this.state.sorting === null ? "sort" : this.state.sorting === "ascending" ? "sort alphabet up": "sort alphabet down"}/>}
                                <Search open    = {false}
                                        loading = {this.state.isLoading}
                                        value   = {this.state.value}
                                        onClick = {(e)=>{e.stopPropagation()}}
                                        onSearchChange = {(e, {value}) => this.onSearchChange(value, this.state.LoadGroups)}
                                        aligned = "right"/>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                 </Table>

                 <div style={{width:"100%", maxHeight:IsElectronContext() ? "calc(100vh - 25em)" :"calc(100vh - 30em)", overflowY: "visible", marginBottom:"5em"}}>
                    <Table striped structured compact='very' size="small">
                        <Table.Body>
                            { this.renderRows()}
                        </Table.Body>
                    </Table>    
                </div>

                <Segment vertical textAlign="center" style={{position:"absolute", bottom:IsElectronContext() ? "1.5em" : "6em", width:"100%", border:"none"}}>
                    <Label as="a" color="green" onClick={ this.addNewLoadGroup }>
                        <Icon name="plus"/>{LocalizedStrings.AddNewLoadGroup}
                    </Label>
                </Segment> 
            </div>
        );
    }

    onHeaderClick = () => {
        let newVal = null
        switch (this.state.sorting) {
            case null:          newVal = "ascending";   break;
            case "ascending":   newVal = "descending";  break;
            default:            newVal = null;          break;
        }
        this.setState({
            sorting: newVal
        })
    }

    renderRows = () => {

        let showData = !this.state.value ? this.state.LoadGroups : this.state.isLoading ? this.state.LoadGroups : this.state.results

        if(this.state.sorting) {
            return this.state.indexMap.map((_, i) => {
                let calcIndex = this.state.sorting === 'ascending' ? i : this.state.indexMap.length - i - 1
                return this.state.LoadGroups[this.state.indexMap[calcIndex]]
            }).map(entry => {return <LoadGroupNode key={entry.UUID} entry={entry}/>})
        }

        return showData.map((entry, idx) => {return <LoadGroupNode 
            draggable={this.state.sorting === null}
            onDragStart={()=>this.setState({drag: {source: entry.UUID,              target: idx}})}
            onDragEnter={()=>this.setState({drag: {source: this.state.drag.source,  target: idx}})}
            onDragEnd={this.onDragEnd}
            key={entry.UUID} 
            entry={entry}/>
        })
    }

    onSearchChange = (value, resources) => 
    {
      this.setState({isLoading: true, value: value})

        if(value.length < 1)
        {
          this.onSearchReset()
        }
        else
        {
          const re = new RegExp(escapeRegExp(value), 'i')
          const isMatch = (result) => {let res=re.test(result.Name); return res;}
        
          this.setState({results: filter(resources, isMatch), isLoading: false})
        }
    }

    onSearchReset = () => {
      this.setState({isLoading: false,
                     results  : [], 
                     value    : "",})
    }


    addNewLoadGroup = () =>
    {
        window.LR_AddNewLoadGroup({})
    }

    setUpCallbacks() 
    {
        globalCallbacks.uploadeLoadGroups = () =>
        {
            window.LR_GetLoadGroups().then(res => {
                let indexMap = []
                let objects = res.LoadGroups
                objects.forEach((_, i) => indexMap.push(i))
                indexMap.sort((a,b) => objects[a][SORT_BY] === objects[b][SORT_BY]  ? 0 : (objects[a][SORT_BY]  < objects[b][SORT_BY]  ? -1 : 1))
                this.setState({LoadGroups: res.LoadGroups, indexMap})
            })
        }

    }
}

export default LoadGroupTable;

