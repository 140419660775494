import EventEmitter from "eventemitter3";

/**
 * noMerge: Overwrite existing Child with new One
 * addMerge: Overwrite data in existing child and combine old and new sub-children
 * modifyMerge: Overwrite data in existing child and keep only sub-children that are present in the new child (identical to noMerge, except, that children arent deleted, but merged)
 */
type mergeTypes = "noMerge" | "addMerge" | "modifyMerge";

type messageTypes = "thisObj" | "fromChild" | "fromParent"

//better definition
type booleanLike = any

export default abstract class {
    readonly ident: string
    private _label: string

    children: this[] = []
    parent: this = undefined
    isLeaf: boolean = true        //if isLeaf === true -> Does not have children
    isBeforeLeaf: boolean = false //if isBeforeLeaf === true -> Only has Leafs as children

    get label() {
        return this._label ?? this.ident
    }

    set label(l: string) {
        this._label = l
    }


    /**
     * @brief Event Handler
     * @description Allows to listen to events
     * @warning Do not delete events yourself, always use node.removeAllListeners()
     * @example this.eventHandler.on("thisObj", ()=>{...})
     */
    eventHandler = new EventEmitter<messageTypes>()

    constructor(ident: string) {
        this.ident = ident
        this.removeAllListeners()
    }

    /**
     * Overwrite this function with one, that merges toMerge into the current object
     * @param toMerge 
     */
    protected abstract mergeObjects(toMerge: this): void;

    /**
     * Merges the current Node with toMerge, overwriting the current nodes data with that of toMerge. If both nodes have children with identical ident, then these are merged recursively, other childeren (of both nodes) are concatenated together
     */
    private merge(toMerge: this, mergeMode: mergeTypes) {
        if (mergeMode === "noMerge") { return; }

        if (this.ident !== toMerge.ident) {
            console.error(`tried to merge two Nodes with different identifications: ${this.ident} -> ${toMerge.ident}`)
            return
        }

        this.eventHandler = toMerge.eventHandler
        this.mergeObjects(toMerge)

        let labelsA = this.children.map(e => e.ident)
        let labelsB = toMerge.children.map(e => e.ident)
        for (let i of labelsA.filter(x => labelsB.includes(x))) {
            this.getChildByIdent(i).merge(toMerge.getChildByIdent(i), mergeMode)
        }

        if (mergeMode === "modifyMerge") {
            for (let i of labelsA.filter(x => !labelsB.includes(x))) {
                this.removeChildByIdent(i, false)
            }
        }

        for (let i of labelsB.filter(x => !labelsA.includes(x))) {
            this.addChild(toMerge.getChildByIdent(i), mergeMode)
        }
    }

    /**
     * Adds a child node, optionally merging it with an existing child node with same ident
     * @param child 
     * @param merge merge or overwrite, if child with same ident exists
     * @returns added Child
     */
    addChild(child: this, merge: mergeTypes = "modifyMerge") {
        if (!child) { return undefined }

        this.isLeaf = false

        if (merge === "noMerge") {
            let idx = this.children.findIndex((n) => n.ident === child.ident)
            if (idx >= 0) {
                this.children[idx].parent = undefined
                this.children[idx] = child
                return child
            }
        }

        if (child.parent) {
            child.parent.removeChildByIdent(child.ident)
        }

        let item = this.children.find(e => e.ident === child.ident)
        if (item) {
            item.merge(child, merge)
            child = item
        } else {
            this.children.push(child)
        }

        child.parent = this
        if (this.parent) {
            this.parent.isBeforeLeaf = false
        }
        if (this.children.length === 1) {
            this.isBeforeLeaf = child.isLeaf
        } else {
            this.isBeforeLeaf = this.isBeforeLeaf && child.isLeaf
        }
        return child
    }

    addChildren(children: this[], merge: "noMerge" | "addMerge" | "modifyMerge" = "modifyMerge") {
        for (let i of children) {
            this.addChild(i, merge)
        }
    }

    /**
     * Remove child by Reference
     * @param child 
     */
    removeChild(child: this) {
        let idx = this.children.findIndex((n) => n === child)
        if (idx >= 0) {
            this.children.splice(idx, 1)
        }
        if (this.children.length === 0) {
            this.isLeaf = true
            if (this.parent) {
                this.parent.isBeforeLeaf = true
                for (let i of this.parent.children) {
                    if (!i.isLeaf) {
                        this.parent.isBeforeLeaf = false
                        break;
                    }
                }
            }
        }
    }

    /**
     * Remove Child by Ident
     * @param ident 
     * @param recursive 
     */
    removeChildByIdent(ident: string, recursive = false) {
        let idx = this.children.findIndex((n) => n.ident === ident)
        if (idx >= 0) {
            this.children.splice(idx, 1)
        } else if (recursive) {
            for (let i of this.children) {
                i.removeChildByIdent(ident, recursive)
            }
        }

        if (this.children.length === 0) {
            this.isLeaf = true
            if (this.parent) {
                this.parent.isBeforeLeaf = true
                for (let i of this.parent.children) {
                    if (!i.isLeaf) {
                        this.parent.isBeforeLeaf = false
                        break;
                    }
                }
            }
        }
    }

    /**
     * remove all children
     */
    clearChildren() {
        for (let i of this.children) {
            i.parent = undefined
        }
        this.children = []
        this.isLeaf = true
        if (this.parent && !this.parent.isBeforeLeaf) {
            this.parent.isBeforeLeaf = true
            for (let i of this.parent.children) {
                if (!i.isLeaf) {
                    this.parent.isBeforeLeaf = false
                    break;
                }
            }
        }
    }

    /**
     * removes itself and all its children out of the tree
     * DO NOT use this node anymore after this. This is meant for cleaning purposes only
     */
    removeSelf(){
        this.clearChildren()
        this.parent?.removeChild(this)
    }

    /**
     * Same as getLeafs but with a limit on the number of returned items
     * @param limit 
     * @param filterFun 
     * @returns 
     */
    *getLeafsWithLimit(limit: number, filterFun: (obj: this) => booleanLike = () => true) {
        let counter = 0
        for (let i of this.getLeafs(filterFun)) {
            if (counter > limit) {
                return
            }
            yield i
            counter++;
        }
    }

    /**
     * Same as getChildren but with a limit on the number of returned items
     * @param limit 
     * @param filterFun 
     * @returns 
     */
    *getChildrenWithLimit(limit: number, filterFun: (obj: this) => booleanLike = () => true) {
        let counter = 0
        for (let i of this.getChildren(filterFun)) {
            if (counter > limit) {
                return
            }
            yield i
            counter++;
        }
    }

    /**
     * Get all leafs that return true for filterFun(leaf)
     * @param filterFun Filters returned leafs by given function
     * @returns Generator returning selected leafs
     * @example 
     * for(let i of obj.getLeafs((leaf) => leaf.ident !== "test")) {
     *  ...
     * }
     */
    *getLeafs(filterFun: (obj: this) => booleanLike = () => true): Generator<this> {
        if (this.isLeaf) {
            if (!filterFun(this)) {
                return
            } else {
                yield this
            }
        }
        else {
            for (let i of this.children) {
                //see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/yield*?retiredLocale=de#delegating_to_another_generator
                yield* i.getLeafs(filterFun)
            }
        }
    }

    /**
     * Return all children (excluding this) recursively (dfs)
     * @param filterFun Filters returned nodes by given function
     */
    *getChildren(filterFun: (obj: this) => booleanLike = () => true): Generator<this> {
        for (let i of this.children) {
            if (filterFun(i)) {
                yield i
            }
            //see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/yield*?retiredLocale=de#delegating_to_another_generator
            yield* i.getChildren(filterFun)
        }
    }

    /**
     * iterates over all nodes in given node and its children (recursively); stops recursion if give node yields true to filterFun
     * @param filterFun Filters returned nodes by given function
     * @returns Generator returning selected nodes
     */
    *getTopNodes(filterFun: (obj: this) => booleanLike = () => true): Generator<this> {
        if (filterFun(this)) {
            yield this
        } else {
            for (let i of this.children) {
                yield* i.getTopNodes(filterFun)
            }
        }
    }

    /**
     * recursively get all parents of this node, that yield filterFun(parent) === true
     * @param filterFun function to filter parents by
     */
    *getParents(filterFun: (item: this) => booleanLike = () => true): Generator<this> {
        if (this.parent) {
          if (filterFun(this.parent)) {
            yield this.parent
          }
          yield* this.parent.getParents(filterFun)
        }
      }

    /**
     * Convenience function, which returns the first found node with given ident. Is by default not recursive and only searches the next level
     * @param ident 
     * @param recursive 
     * @returns 
     */
    getChildByIdent(ident: string, recursive = false): this | undefined {
        let t = this.children.find(e => e.ident === ident)
        if (t || !recursive) {
            return t
        } else {
            return this.getChildByFilter(i => i.ident === ident)
        }
    }

    /**
     * get first found child, that yields filterFun(child) === true
     * @param filterFun 
     * @returns 
     */
    getChildByFilter(filterFun: (item: this) => booleanLike = () => true): this | undefined{
        return this.getChildren(filterFun).next().value
    }

    /**
     * see getChildByFilter
     * @param filterFun 
     * @returns 
     */
    getLeafByFilter(filterFun: (item: this) => booleanLike = () => true): this | undefined{
        return this.getLeafs(filterFun).next().value
    }

    /**
     * see getChildByFilter
     * @param filterFun 
     * @returns 
     */
    getParentByFilter(filterFun: (item: this) => booleanLike = () => true): this | undefined{
        return this.getParents(filterFun).next().value
    }

    /**
     * Check if any of the nodes leafs return filterFun(leaf) === true
     * @param filterFun 
     * @returns 
     */
    checkIfLeafExists(filterFun: (item: this) => booleanLike) {
        return !this.getLeafs(filterFun).next().done
    }

    /**
     * Check if any of the nodes children return filterFun(child) === true
     * @param filterFun 
     * @returns 
     */
    checkIfChildExists(filterFun: (item: this) => booleanLike) {
        return !this.getChildren(filterFun).next().done
    }

    /**
     * Check if node has any parent (recursively) that yield filterFun(child) === true
     */
    checkIfParentExists(filterFun: (item: this) => booleanLike){
        return !this.getParents(filterFun).next().done
    }

    /**
     * Emit a Message into the tree 
     * @param msg 
     * @param args 
     */
    emit(settings?: { emitToParents?: boolean, emitToChildren?: boolean }, ...args: any[]) {
        if (settings.emitToParents === undefined) {
            settings.emitToParents = true
        }
        if (settings.emitToChildren === undefined) {
            settings.emitToChildren = true
        }

        this.eventHandler.emit("thisObj", ...args)
        if (settings.emitToParents && this.parent) {
            this.parent.eventHandler.emit("fromChild", this, ...args)
        }
        if (settings.emitToChildren) {

            for (let i of this.children) {
                i.eventHandler.emit("fromParent", this, ...args)
            }
        }
    }
    

    /**
     * Safely removes all listeners to the event handler of this node
     */
    removeAllListeners() {
        this.eventHandler.removeAllListeners("fromChild")
        this.eventHandler.removeAllListeners("fromParent")
        this.eventHandler.removeAllListeners("thisObj")

        this.eventHandler.on("fromChild", (obj: this, ...args) => {
            if (this.parent) {
                this.parent.eventHandler.emit("fromChild", obj, ...args)
            }
        })
        this.eventHandler.on("fromParent", (obj: this, ...args) => {
            for (let i of this.children) {
                i.eventHandler.emit("fromParent", obj, ...args)
            }
        })
    }
}