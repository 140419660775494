//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import LocalizedStrings from "../../localization/RequestStructuralCheck";
import { Grid, Table, Button, Icon, Segment, Header } from 'semantic-ui-react';
import { BASE_UNIT_BOOLEAN, BASE_UNIT_NUMBER } from '../../util/defines';
import UnitInput from '../Basics/BasicUnitInput'


//-----------------------------------------------------------------------------
// The component for the TreeControl
class RequestStructuralCheck_Quote extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      DisableButton: true,
      Loading: false,
      Pricing: {
        Price: 0.0,
        Currency: "eur",
        TimeNeeded: Date.now(),
        Username: "",
        ActiveId: "",
        Items: []
      },
      PricingArgs:{
        CountSupports: 2,
        HighWorkloadSupports: false,
        CountCrossSections: 1,
        HighWorkloadTrussCrossSections: false,
      }
    }
  }

  componentDidMount = () => 
  {
    this.updatePriceing()
  }

  updatePriceing = async() => 
  {
    this.setState({Loading: true})
    let res = await window.LR_GetCalculationPricing({PricingArgs:this.state.PricingArgs})

    this.setState( { 
        Pricing: {
          Price: res.Price,
          Username: res.Username,
          Currency: res.Currency,
          TimeNeeded: res.TimeNeeded,
          Items: res.Items
        },
        DisableButton: true,
        Loading: false
      });
  }
    
  render() 
  {

    return (
                <Grid divided='vertically'>
                  <Grid.Row columns={2}>
                    <Grid.Column width="11">
                    <Header>{LocalizedStrings.Parameters}</Header>
                      <Table>
                      <Table.Header>
                      <Table.Row>
                      <Table.HeaderCell width={"3"}>{LocalizedStrings.Entry}</Table.HeaderCell>
                      <Table.HeaderCell width={"5"}>{LocalizedStrings.Amount}</Table.HeaderCell>
                      <Table.HeaderCell width={"8"}>{LocalizedStrings.Description}</Table.HeaderCell>
                      </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {this.renderPricingOperation("CountSupports", BASE_UNIT_NUMBER)}
                        {this.renderPricingOperation("HighWorkloadSupports", BASE_UNIT_BOOLEAN)}
                        {this.renderPricingOperation("CountCrossSections", BASE_UNIT_NUMBER)}
                        {this.renderPricingOperation("HighWorkloadTrussCrossSections", BASE_UNIT_BOOLEAN)}
                      </Table.Body>
                      </Table>
                      <Button disabled={this.state.DisableButton} onClick={this.updatePriceing} positive fluid>{this.state.Loading ? <Icon loading name="spinner"/>: null }{LocalizedStrings.CalculatePrice}</Button>

                    </Grid.Column>
                    <Grid.Column width="5">
                      <Header>{LocalizedStrings.Quote}</Header>
                      <Segment placeholder>
                        <Header icon>
                          <Icon name="shopping cart" />
                          {this.getPricing()}
                        </Header>
                      </Segment>
                      

                    
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
    )
  }

  renderPricingOperation(entryString, baseunit, key)
  {
    return  <>
    <Table.Row>
      <Table.Cell>{LocalizedStrings[entryString]}</Table.Cell>
      <Table.Cell><UnitInput 
      baseUnit={baseunit}
      value         = {this.state.PricingArgs[entryString]}
      onStateUpdate = {(name, value) => { this.setState({DisableButton: false, PricingArgs: {...this.state.PricingArgs, [entryString]:value}}) }}
      onUpdateData = {(name, value) => { this.setState({DisableButton: false,PricingArgs: {...this.state.PricingArgs, [entryString]:value}}) }} />
    </Table.Cell>
    <Table.Cell><i>{LocalizedStrings["Text" + entryString]}</i></Table.Cell>

    </Table.Row>
    </>
  }

  getPricing()
  {
    if(this.state.Loading)
    {
      return <Icon size='huge' loading name="spinner"/>
    }
    let cur = ""
    switch(this.state.Pricing.Currency)
    {
      case "usd": cur = "$"; break;
      case "eur": cur = "€"; break;
    }
    
    return <>{this.state.Pricing.Price / 100} {cur}</>
  }

}

export default RequestStructuralCheck_Quote