
//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Header, Label, Icon, Segment, Input } from 'semantic-ui-react'
import LocalizedStrings from "../../../localization/TableViewComponent";

import { globalCallbacks as mainCB } from '../../../util/callback';
import { globalCallbacks as mockCB } from '../../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB


class RackList extends Component
{
    constructor(props)
    {
      super(props);
  
      this.state = {
        racks: [],
      };
    }

    componentDidMount = () => 
    {
        this.setUpCallbacks();
        globalCallbacks.updateRackList();
    }

    render()
    {
        return (
            <React.Fragment>
                {this.state.racks.length !== 0 ? 
                <div style={{display: 'flex', flexWrap: "wrap"}}>
                    {this.state.racks.map( entry => {return this.renderRack(entry)})}
                </div> 
                : <Segment placeholder><Header icon>{LocalizedStrings.EmptyRackList}</Header></Segment>}
                <Segment vertical textAlign="center ">
                    <Label color="green" onClick={this.addNewRack}>
                        <Icon name="plus"/>{LocalizedStrings.addNewRack}
                    </Label>
                </Segment>
            </React.Fragment> );
    }

    renderRack(rackObj)
    {
        let hes = []
        for(let i = 1; i <= rackObj.HECount; i++) 
        { 
            hes.push({HE: i})
            let hasObject = rackObj.RackUsage.find(obj => obj.AssingedHEinRack[rackObj.UUID] === this.displayToCore(i))
            if (hasObject) {
                i += hasObject.CountHE - 1
            }
        }

        let height = rackObj.HECount * 1.3 + 2 * 0.3 + rackObj.HECount * 0.4
        return(<div style={{ 
                    height: height + "em",
                    width: "10em",
                    backgroundColor: "gray",
                    padding: "0.3em",
                    margin: "0.5em",
                    borderRadius: "4px"
                }}>
                    <Input value    = {this.state["editRackName" + rackObj.UUID] ?? rackObj.Name} 
                           onChange = {(e, {value}) => this.setState({["editRackName" + rackObj.UUID]: value})}
                           onBlur   = {() => this.updateData("Name", this.state["editRackName" + rackObj.UUID], rackObj.UUID)}
                           transparent
                           style    = {{maxWidth: "100%"}}/>

                    {hes.map((entry, i) => {return this.renderHE(entry, rackObj)})}
                    
                </div>)
    }

    updateData = (name, value, UUID) => {
        let request = 
        {
            UUID: UUID,
            [name] : value
        };
        window.LR_SetRack(request);
    }

    renderHE(entry, rack)
    {   
        let isFree = () => {
            let free = true
            for (let i=entry.HE; i < (entry.HE + this.dragged.CountHE - 1); i++ )
            {
                for (let j=0; j < rack.RackUsage.length; j++)
                {
                    let obj = rack.RackUsage[j]
                    if (obj.UUID !== this.dragged.UUID) {
                        free = obj.AssingedHEinRack[rack.UUID] === i ? false:free
                    }
                }
            }   
            
            return free
        }

        let onDragOver = (e) => 
        {
            e.stopPropagation();
            e.preventDefault();
            let free = isFree()

            if (free) {
                document.getElementById(String(rack.UUID) + String(entry.HE)).style.backgroundColor = '#ccffcc' // light green
            }
            else
            {
                document.getElementById(String(rack.UUID) + String(entry.HE)).style.backgroundColor = '#ffff99' // light yellow
            }
        }

        let onDrop = (e) => 
        {
            let free = isFree()
            if (free) {
                window.LR_SetObject({UUID: this.dragged.UUID, PositionInRack: this.displayToCore(entry.HE), LinkedContainer: rack.UUID})
                this.dragged = null
            }
            document.getElementById(String(rack.UUID) + String(entry.HE)).style.backgroundColor = 'white'
        }

        let onDragLeave = (e) => 
        {
            document.getElementById(String(rack.UUID) + String(entry.HE)).style.backgroundColor = 'white'
        }

        let hasObject = rack.RackUsage.find(obj => obj.AssingedHEinRack[rack.UUID] === this.displayToCore(entry.HE))
        
        if (hasObject)
        {
            if (this.displayObject(hasObject))
            {
                return this.renderObject(hasObject, rack)
            }
            else
            {
                return null;
            }
        }
        else
        {   
            return (<>
                    <div    id = {String(rack.UUID) + String(entry.HE)}
                            key = {entry.HE}
                            onDragOver = {onDragOver}
                            onDrop = {onDrop}
                            onDragLeave = {onDragLeave}
                    
                        style={{  height: "1.3em", width: "100%", backgroundColor: "white", marginTop:"0.2em", marginBottom:"0.2em" }}>
                        {entry.HE}
                    </div>
                    </>)
        }

    }

    renderObject(rackObject, rack)
    {

        let onDragStart = (e) => 
        {
            this.dragged = rackObject
        } 

        let onDragOver = (e) => 
        { 
            if (this.dragged.UUID !== rackObject.UUID) 
            {
                document.getElementById(String(rack.UUID) + String(this.coreToDisplay(rackObject.AssingedHEinRack))).style.backgroundColor = '#ffff99' // light yellow
            }
        }

        let onDragLeave = (e) => 
        {
            document.getElementById(String(rack.UUID) + String(this.coreToDisplay(rackObject.AssingedHEinRack))).style.backgroundColor = 'lightgrey'
        }

        let onDragEnd = (e) => 
        {
            document.getElementById(String(rack.UUID) + String(this.coreToDisplay(rackObject.AssingedHEinRack))).style.backgroundColor = 'lightgrey'
        }

        let onDrop = () => {
            // swap positions
            const draggedHE = this.dragged.AssingedHEinRack[rack.UUID]
            const targetHE = rackObject.AssingedHEinRack[rack.UUID]
            window.LR_SetObject({UUID: this.dragged.UUID, PositionInRack: targetHE, LinkedContainer: rack.UUID})
            window.LR_SetObject({UUID: rackObject.UUID, PositionInRack: draggedHE, LinkedContainer: rack.UUID})
            this.dragged = null
        }

        let height = String(1.3 * rackObject.CountHE + 0.2 * (rackObject.CountHE - 1)) + "em"

        return (<>
                <div    draggable
                        id = {String(rack.UUID) + String(this.coreToDisplay(rackObject.AssingedHEinRack))}
                        key = {rackObject.UUID}
                        onDragStart     = {onDragStart}
                        onDragOver = {onDragOver}
                        onDragLeave = {onDragLeave}
                        onDragEnd = {onDragEnd}
                        onDrop = {onDrop}

                      style={{ height: height, width: "100%", backgroundColor: "lightgrey", marginTop:"0.2em", marginBottom:"0.2em", overflow: "hidden"}}>
                    
                    {this.coreToDisplay(rackObject.AssingedHEinRack[rack.UUID])}
                    {rackObject.NameIdentifier}
                    
                </div>
                </>)
    }

    displayObject(object)
    {
        return this.props.objects.find(key => key.UUID === object.UUID) !== undefined
    }

    addNewRack()
    {
        window.LR_AddNewRack({RackTemplate: "Rack"})
    }

    setUpCallbacks() 
    {   
        globalCallbacks.updateRackList = async () =>
        {
            let racks = await window.LR_GetRacks()
            this.setState({racks: racks.Racks})
        }

        
    }

    
    coreToDisplay(val) { return val + 1 }

    displayToCore(val) { return val - 1 }

   
}

export default RackList;