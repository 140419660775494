import React, { Component } from 'react';
import { TableCell, Table } from "semantic-ui-react";
import LocalizedStrings from "../../../localization/TableViewComponent";
import LRModal from '../../Basics/BasicModal';

class SummaryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [
        { console: 'Grand MA2 Lite', parameters: '4096', quantity: 0, total: 0 },
        { console: 'Grand MA2 Fullsize', parameters: '8192', quantity: 1, total: 8192 },
        { console: 'Grand MA3 Lite', parameters: '4096', quantity: 1, total: 4096 },
        { console: 'Grand MA3 Fullsize', parameters: '8192', quantity: 1, total: 8192 },
        { console: 'Grand MA2 onPC CMD', parameters: '2048', quantity: 0, total: 0 },
        { console: 'Grand MA2 onPC Fader', parameters: '2048', quantity: 0, total: 0 }
      ],
      tableData1: [
        { console: 'Grand MA2 NPU',parameters: '4096', quantity: 1, total: 4096 },
        { console: 'Grand MA3 PU M',parameters: '4096', quantity: 1, total: 4096 },
        { console: 'Grand MA3 PU L',parameters: '4096', quantity: 1, total: 4096 },
        { console: 'Grand MA3 PU XL',parameters: '4096', quantity: 1, total: 4096 }
      ]
    };
  }

  handleQuantityChange = (index, value) => {
    const updatedTableData = [...this.state.tableData];
    updatedTableData[index].quantity = parseInt(value);
    updatedTableData[index].total = parseInt(value) * parseInt(updatedTableData[index].parameters);
    this.setState({ tableData: updatedTableData });
  };

  handleQuantityChange1 = (index, value) => {
    const updatedTableData1 = [...this.state.tableData1];
    updatedTableData1[index].quantity = parseInt(value);
    updatedTableData1[index].total = parseInt(value) * parseInt(updatedTableData1[index].parameters);
    this.setState({ tableData1: updatedTableData1 });
  };
  
  getTotal1 = () => {
    let total = 0;
    this.state.tableData1.forEach(item => {
      total += item.total;
    });
    return total;  
  };

  showLowestNumber = () =>
  {
    const paraArr = this.state.tableData?.map(data => Number(data.parameters));
    let lowestNumber = Math.min(...paraArr);
    let MA2CMD       = this.state.tableData[4].quantity;
    let MA2FaderQuan = this.state.tableData[5].quantity;

    if(MA2CMD === 0 && MA2FaderQuan === 0 )
    {
      lowestNumber = 4096
    } 

    return lowestNumber
  }

  render() {
    const currentDmx = this.props.DmXParam;
    const paraArr = this.state.tableData?.map(data => Number(data.parameters));
    const maxPara = Math.max(...paraArr);
    const sumTotalHighest = maxPara + this.getTotal1();
    const sumTotalLowest = this.showLowestNumber() + this.getTotal1();
    
    const message = (sumTotalHighest >= currentDmx)
      ? <><i className="icon check green" style={{ color: "green" }}></i>{LocalizedStrings.ShowCheckGreaterCorrect}</>
      : <><i className="icon close red" style={{ color: "red" }}></i>{LocalizedStrings.ShowCheckGreaterWrong}</>;
    
    const message1 = (sumTotalLowest >= currentDmx)
      ? <><i className="icon check green" style={{ color: "green" }}></i>{LocalizedStrings.ShowCheckLowerCorrect}</>
      : <><i className="icon close red" style={{ color: "red" }}></i>{LocalizedStrings.ShowCheckLowerWrong} </>;
    

    return (
      <LRModal
        open       = {this.props.open}
        title      = {LocalizedStrings.DmxTable}
        size       = "fullscreen"
        scrolling  = {false}
        onOkClick  = {this.props.onClose}   
        noCancel  
        DmXParam   = {this.props.DmXParam}
      >
    <h2>{LocalizedStrings.ConsoleInfo}</h2>
    <div style={{ width: '100%' }} >
      <Table celled style={{ width: '100%', borderCollapse: 'collapse', margin: "0"}} definition >
        <Table.Header>
          <Table.Row >
            <Table.HeaderCell active style={{ fontWeight: '900', backgroundColor: 'rgba(0, 0, 0, .03)', color: "black"}}>{LocalizedStrings.ConsoleInformation}</Table.HeaderCell>
            <Table.HeaderCell width={2} >{LocalizedStrings.ParametersInfo}</Table.HeaderCell>
            <Table.HeaderCell width={2}  >{LocalizedStrings.QuantityInfo}
            </Table.HeaderCell>
            <Table.HeaderCell width={2} >{LocalizedStrings.TotalInfo}</Table.HeaderCell>
          </Table.Row >
        </Table.Header>
        <Table.Body>
          {this.state.tableData?.map((item, index) => (
            <Table.Row key={index}>
              <TableCell style={{ fontWeight: '900' }}>{item.console}</TableCell>
              <TableCell>{item.parameters}</TableCell>
              <TableCell >
                <div className='ui input'>
                <input
                  type="number"
                  value={item.quantity}
                  onChange={(e) => this.handleQuantityChange(index, e.target.value)}
                  style={{ width: '50%' }}
                />
                </div>
              </TableCell>
              <td>{item.total}</td>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    
      <div style={{ textAlign: 'right', marginTop: '10px' }}>
        <strong style={{textAlign: 'center'}}>
          <p>Total Highest Parameter: {maxPara}</p>
          <p>Total Lowest Parameter: {this.showLowestNumber()}</p>
          </strong>
      </div>
      <hr />
       <h2>{LocalizedStrings.ParameterInfo}</h2>
      <Table celled style={{ width: '100%', borderCollapse: 'collapse', margin: "0" }} definition>
        <Table.Header>
          <Table.Row >
            <Table.HeaderCell style={{ fontWeight: '900', backgroundColor: 'rgba(0, 0, 0, .03)', color: "black"}} >{LocalizedStrings.ConsoleInformation}</Table.HeaderCell>
            <Table.HeaderCell width={2} >{LocalizedStrings.ParametersInfo}</Table.HeaderCell>
            <Table.HeaderCell width={2} >{LocalizedStrings.QuantityInfo}</Table.HeaderCell>
            <Table.HeaderCell width={2} >{LocalizedStrings.TotalInfo}</Table.HeaderCell>
          </Table.Row >
        </Table.Header>
        <Table.Body>
          {this.state.tableData1?.map((item, index) => (
            <Table.Row key={index}>
              <TableCell style={{ fontWeight: '900' }}>{item.console}</TableCell>
              <TableCell>{item.parameters}</TableCell>
              <TableCell>
                <div className='ui input'>
                <input
                  type="number"
                  value={item.quantity}
                  onChange={(e) => this.handleQuantityChange1(index, e.target.value)}
                  style={{ width: '60%' }}
                />
                </div>
              </TableCell>
              <TableCell>{item.total}</TableCell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
     
      <div style={{ textAlign: 'center', marginTop: '10px' }}>
        <strong>Total: {this.getTotal1()}</strong>
      </div> 
    </div>
    <hr />
    <h2>{LocalizedStrings.summarisedOverview}</h2>
    <Table celled style={{ width: '100%', borderCollapse: 'collapse', margin: "0" }} definition>
    <Table.Header>
       <Table.Row >
       <Table.HeaderCell></Table.HeaderCell>
       <Table.HeaderCell>{LocalizedStrings.calculatedMaximum}</Table.HeaderCell>
       <Table.HeaderCell>{LocalizedStrings.currentDMX}</Table.HeaderCell>
       <Table.HeaderCell>{LocalizedStrings.check}</Table.HeaderCell>
       </Table.Row >
    </Table.Header>
    <Table.Body>
        <Table.Row >
        <Table.Cell>{LocalizedStrings.sumTotalHighest}</Table.Cell>
        <Table.Cell>{sumTotalHighest}</Table.Cell>
        <Table.Cell>{this.props.DmXParam}</Table.Cell>
        <Table.Cell>
          <div>{message}</div> 
          </Table.Cell>
        </Table.Row >
        <Table.Row >
        <Table.Cell>{LocalizedStrings.sumTotalLowest}</Table.Cell>
        <Table.Cell>{sumTotalLowest}</Table.Cell>
        <Table.Cell>{this.props.DmXParam}</Table.Cell>
        <Table.Cell><div>{message1}</div> </Table.Cell>
        </Table.Row >
    </Table.Body>
    </Table>
    </LRModal>
  );
}; 
}

export default SummaryTable

