//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';

class PageCanvas extends Component
{
   componentDidMount()
    {
    }

    render()
    {

        return <div id="label-container" 
                 style={{   
                            position: "relative",
                            borderStyle:"solid", 
                            borderWidth: ".3px",
                            width: "calc(" + (this.props.paperWidth / this.props.paperHeight) + " * 50vh)",
                            height:"50vh",}}>
                                    {this.createLabelEntries()}
            </div>
    }

  createLabelEntries()
  {
    if (!this.props.labelThere) { return null }
    
    //Check for divide by 0
    //if (this.props.labelWidth === 0 || this.props.labelHeight === 0) {return undefined; }

    // everything from here needs to be done relational to the paper (%)
    let relSpacingX = (((this.props.spacingX / this.props.paperWidth))) * 100
    let relSpacingY = (((this.props.spacingY / this.props.paperHeight))) * 100

    let relMarginX = (((this.props.marginX / this.props.paperWidth))) * 100
    let relMarginY = (((this.props.marginY / this.props.paperHeight))) * 100
    
    let relLabelWidth = ((this.props.labelWidth / this.props.paperWidth)) * 100
    let relLabelHeight= ((this.props.labelHeight / this.props.paperHeight)) * 100

    let result = []
    for (let y = 0; y < this.props.yCount; y++)
    {
        for (let x = 0; x < this.props.xCount; x++)
        {
            let xPos = ((x * (relLabelWidth + relSpacingX)) + (relMarginX))
            let yPos = ((y * (relLabelHeight + relSpacingY)) + (relMarginY))      
            let labelKey = `${x};${y}`

            if(this.props.doNotPrintKeys.find(entry => entry === labelKey))
            {
                result.push(<Segment    name  = {`label ${x};${y}`}
                                        key   = {labelKey}
                                        style = {{  position:"absolute",
                                                    borderStyle:"solid", 
                                                    margin: "0px",
                                                    padding: "0px",
                                                    display: "block",
                                                    left: xPos + "%", 
                                                    top: yPos + "%", 
                                                    width:(relLabelWidth) + "%", 
                                                    height:(relLabelHeight) + "%"   }}
                                        onClick = {() => { this.props.onClick(labelKey) }}>
                            </Segment>)
            }
            else
            {
                result.push(<div    name  = {`label ${x};${y}`}
                                    key   = {labelKey}
                                    style = {{  position:"absolute", 
                                                borderStyle:"solid", 
                                                left: xPos + "%", 
                                                top: yPos + "%", 
                                                width:(relLabelWidth) + "%", 
                                                height:(relLabelHeight) + "%"   }}
                                    onClick = {() => { this.props.onClick(labelKey) }}>
                                        <div style={{position:"relative", left:0, top:0, width:"100%", height:"100%"}}>
                                            {this.props.labelFields.map((field, index) => {
                                                let relFieldWidth = field.FieldLength  / this.props.labelWidth 
                                                let relFieldHeight = (field.FontSize * 1.333) /  this.props.labelHeight // 1.33 scales pt to px

                                                if (field.PropertyName === "QRCodeField") { relFieldHeight = relFieldWidth }
                                                return <div key   = {`${x};${y};${index}`}
                                                            name  = {`field ${x};${y};${index}`}
                                                            style = {{  position: "absolute",
                                                                        backgroundColor: "blue",
                                                                        left  : ((field.OffsetX / 100) / this.props.labelWidth  * 100) + "%",
                                                                        top   : ((field.OffsetY / 100) / this.props.labelHeight * 100) + "%",
                                                                        width : (relFieldWidth  * 100) + "%",
                                                                        height: (relFieldHeight * 100) + "%"    }}/>
                                            })}
                                        </div>
                                    </div>)
            }
        }
    }
    return result
  }
}

//<LabelEntry field={[]} presetFields={this.props.labelFields}/>

export default PageCanvas;