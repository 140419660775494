//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import ReactMde, { getDefaultToolbarCommands } from 'react-mde';
import gfm from 'remark-gfm';
import { Button, Dropdown, Form, Grid, Icon, List, Message, Popup } from 'semantic-ui-react';
import LocalizedStrings from "../../localization/ExportPaperworksReport";
import LocalizedStrings_MarkDownToolTips from "../../localization/MarkdownToolTips";
import LRModal from '../Basics/BasicModal';
const ReactMarkdown = React.lazy(()=>import("react-markdown"))

import { CompareObjects, EMPTY_UUID } from '../../util/defines';
import LRFilterInput from '../Basics/FilterField';
import { Text } from "../ExportPaperworksReport/MarkdowTableSupport";
import "./ExportPaperworksReport.css";


import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from '../../redux/store';
import { globalCallbacks } from "../../util/callback";
import CustomPDFFormatPicker from '../TableViews/CustomPDFFormatPicker';


const DEFAULT_CONTEXT = [
  {Name: "SAVED_VIEW", text: "%%SAVED_VIEW( name, label, width_mm, height_mm, )%%", options: "SavedViewOption", value: "SelectedSavedViewOption"},
  {Name: "WORKSHEET", text: "%%WORKSHEET( name )%%", options: "WorksheetOptions", value: "SelectedWorksheetOption"},
  {Name: "TEMPLATE_WORKSHEETS", text: "%%TEMPLATE_WORKSHEETS( name )%%", options: "TemplateOptions", value: "SelectedWorksheetOption"},
  {Name: "TOC", text: "%%TOC( level )%%",},
  {Name: "LINEBREAK", text: "%%LINEBREAK%%",},
  {Name: "QR_CODE_PROJECT", text: "%%QR_CODE_PROJECT%%",},
  {Name: "QR_CODE_WORKSHEET", text: "%%QR_CODE_WORKSHEET( name )%%", options: "WorksheetOptions", value: "SelectedWorksheetOption"},
  {Name: "QR_CODE_OBJECT", text: "%%QR_CODE_OBJECT( name )%%"},
  {Name: "SHEETBREAK", text: "%%SHEETBREAK%%",},
]

//-----------------------------------------------------------------------------
// The component for the TreeControl


class ExportPaperworkModal extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
      protocolText: "",
      changed: false,
      searchFilter:"",

      WorksheetsList: [],
      reportPresets: [],
      selectedPreset: "",

      createOpen: false,
      createName: "",

      PrintLabels: [],
      selectedPrintLabel:EMPTY_UUID,
      selectedPrintLabelFirstPage:EMPTY_UUID,

      WorksheetOptions:[],
      SelectedWorksheetOption:"",
      SelectedSavedViewOption:"",

      TemplateOptions:[],
      TemplateOption:"",

      PublicShareLinkOptions:[],
      PublicShareLink: "",
      marginSettings: undefined,
      PDFFormatsSettings: undefined
    }
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }

  show = async() => 
  {

    window.LR_GetLinkedProject().then(result => 
    {
      this.setState({
          Project: result.Project
      })

    })

    let ret  = await window.LR_GetWorksheets()
    this.fetchReportPresets()

    const ret2         = await window.LR_GetPrintLabels();

    let ret3 = await window.LR_GetPaperworkReportMarkdown()
    let ret4 = await window.LR_GetPropertyTemplateMap()
    let ret5 = await window.LR_GetSavedViews();

    let TemplateOptions = ret4.ResourceMap.propertyTemplate.map(w=> { return {key: w.Key, value:w.Name, text: w.Name}})
    TemplateOptions.push({key: "", value:"", text: "<Default>"})

    let Worksheets = {}

    ret.Worksheets.forEach(ws => 
    {
      Worksheets[ws.Name] = ws.UUID
    })

    globalCallbacks.__gWorksheets = Worksheets;


    let WorksheetOptions = ret.Worksheets.map(w=> { return {key: w.UUID, value:w.Name, text: w.Name}})

    WorksheetOptions.push({key: EMPTY_UUID, value:"", text: "<Scene>"})

    let SavedViewOption = ret5.SavedViews.map(w=> { return {key: w.UUID, value:w.Name, text: w.Name}})

    SavedViewOption.push({key: EMPTY_UUID, value:"", text: "<Current View>"})

    this.setState( { 
        open : true, 
        Worksheets: Worksheets,
        WorksheetsList: ret.Worksheets,
        PrintLabels:ret2.PrintLabels,
        WorksheetOptions,
        TemplateOptions,
        protocolText: ret3.Report,
        selectedPrintLabelFirstPage: ret3.Front,
        selectedPrintLabel: ret3.Back,
        SavedViewOption
      });

    let shareLinks = await window.LR_GetShareLinksForProject()    
    let options = []

    this.SHARE_LINK_MAP = {}
    if(Array.isArray(shareLinks))
    {
      options = shareLinks.map((e)=>
      {
        this.SHARE_LINK_MAP[e.name] = e.token
        return {
        key: e.name,
        text: e.name,
        value:e.name
      }})
    }

    options.push(
      {
        text:"<None>",
        value: "",
        key:"<None>"
      }
    )
    this.setState({ PublicShareLinkOptions: options})
  }

  export = () => 
  {
    window.LR_ExportPaperworkReport({
      ProtocolDef:this.state.protocolText, 
      SelectedPrintLabelBackground: this.state.selectedPrintLabel, 
      SelectedPrintLabelFrontPage: this.state.selectedPrintLabelFirstPage, 
      PublicShareLink: this.SHARE_LINK_MAP[ this.state.PublicShareLink ],
      PublicShareLinkName: this.state.PublicShareLink,
      PDFFormats: this.state.PDFFormatsSettings
    })
    this.close()
  }

  cancel = () => 
  {
    window.LR_SetPaperworkReportMarkdown(
      {
        Report:this.state.protocolText,
        Front:this.state.selectedPrintLabelFirstPage,
        Back:this.state.selectedPrintLabel,
      })
    this.close()
  }

  close = () => 
  {
    this.setState({open : false});
  }
    
  render() 
  {
    let open = this.state.open;

    let wasChanged = false
    let theSelectedPreset = this.state.reportPresets.find(pr => pr.Name === this.state.selectedPreset)
    if (theSelectedPreset)
    {
      wasChanged =  
      this.state.protocolText !== theSelectedPreset.Markdown || 
      this.state.selectedPrintLabel !== theSelectedPreset.BackgroundTemplate || 
      this.state.selectedPrintLabelFirstPage !== theSelectedPreset.FirstPageBackgroundTemplate || 
      this.state.PublicShareToken !== theSelectedPreset.PublicShareToken ||
      !CompareObjects(this.state.PDFFormatsSettings, theSelectedPreset.PDFFormats) ||
      !CompareObjects(this.state.marginSettings, theSelectedPreset.Margins)
    }

    let customCommand = {}
    let customCommandList = []


    DEFAULT_CONTEXT.forEach(e => 
    {
      customCommandList.push(e.Name)
      customCommand[e.Name] = {
        name: e.Name,
        icon: () => (
          <Popup content={ <ReactMarkdown>{LocalizedStrings_MarkDownToolTips[e.Name]}</ReactMarkdown> }
          trigger={
          <span>
             <Icon name="add"/>
            {e.options ? <Dropdown className='dropDown' onChange={(event, {value})=>{this.setState({[e.value]: value})}} value={this.state[e.value]} options={this.state[e.options]}></Dropdown> : null}
            <b>{LocalizedStrings[e.Name]}</b>
          </span>}/>
        ),
        execute: opts => {
          let text = e.text
          if(e.value) { text = text.replace("name", this.state[e.value]) }
          opts.textApi.replaceSelection("\n" + text + "\n");
        }
      }
    });


    return (
      <LRModal
        open={open}
        closeOnDimmerClick={false}
        closeOnEnter={false}
        title={LocalizedStrings.Header}
        size="fullscreen"
        onOkClick={this.export}
        changed={this.state.changed}
        preventDataLoss
        dimmer=""
        onCancelClick={this.cancel}
        onDiscardClick={this.close}
      >
        <Grid className='ExportPaperworksReport'>
          <Grid.Row>
            <Grid.Column width="13">
              <ReactMde
                commands={customCommand}
                toolbarCommands={[
                  ...getDefaultToolbarCommands(),
                  customCommandList,
                ]}
                getIcon={(commandName) => <Icon name={commandName} />}
                value={this.state.protocolText}
                onChange={(data) =>
                  this.setState({ protocolText: data, changed: true })
                }
                selectedTab={this.state.selectedTab}
                onTabChange={(data) => this.setState({ selectedTab: data })}
                generateMarkdownPreview={(markdown) =>
                  Promise.resolve(
                    <ReactMarkdown
                      components={{ p: Text }}
                      remarkPlugins={[gfm]}
                    >{markdown}</ReactMarkdown>
                  )
                }
                loadSuggestions={this.loadSuggestions}
                minEditorHeight={800}
                childProps={{ writeButton: { tabIndex: -1 }, textArea: {
                  onKeyDown: (e)=>{
                    // dont jump out of text field on tab
                    if (e.key == 'Tab') {
                      e.preventDefault();
                      let t = e.target
                      let start = t.selectionStart;
                      let end = t.selectionEnd;

                      let np = this.state.protocolText
                      np = np.substring(0, start) + "\t" + np.substring(end);

                      this.setState({protocolText: np}, ()=>{
                        t.selectionStart = t.selectionEnd = start + 1
                      })
                    }
                  }
                } }}
              />
            </Grid.Column>
            <Grid.Column width="3">
              <Form>
                <Form.Select
                  label={LocalizedStrings.SavedCalculationReports}
                  options={this.state.reportPresets.map((pres) => ({
                    key: pres.Name,
                    value: pres.Name,
                    text: pres.Name,
                  }))}
                  value={this.state.selectedPreset}
                  onChange={(_, { value }) => {
                    this.changeSelectedPreset(value);
                  }}
                />
                <Button.Group fluid style={{marginBottom: 15}}>
                  <Button disabled={!wasChanged} size="mini" color="blue" content={LocalizedStrings.UpdatePreset} onClick={this.updateClick}/>
                  <Button size="mini" positive content={LocalizedStrings.CreatePreset} onClick={this.createClick}/>
                  <Button size="mini" negative content={LocalizedStrings.DeletePreset} onClick={this.deleteClick} />
                </Button.Group>
                <Form.Select
                  label={LocalizedStrings.PrintLabel}
                  options={[
                    { key: EMPTY_UUID, value: EMPTY_UUID, text: LocalizedStrings.NoBackground },
                    ...this.state.PrintLabels.map((lb) => ({
                      key: lb.UUID,
                      value: lb.UUID,
                      text: lb.Name,
                    })),
                  ]}
                  value={this.state.selectedPrintLabel}
                  onChange={(_, { value }) => {
                    this.setState({ selectedPrintLabel: value });
                  }}
                />
                <Form.Select
                  label={LocalizedStrings.PrintLabelFirstPage}
                  options={[
                    { key: EMPTY_UUID, value: EMPTY_UUID, text: LocalizedStrings.NoBackground },
                    ...this.state.PrintLabels.map((lb) => ({
                      key: lb.UUID,
                      value: lb.UUID,
                      text: lb.Name,
                    })),
                  ]}
                  value={this.state.selectedPrintLabelFirstPage}
                  onChange={(_, { value }) => {
                    this.setState({ selectedPrintLabelFirstPage: value });
                  }}
                />
                <CustomPDFFormatPicker
                  PDFFormat={this.state.PDFFormatsSettings}
                  onChangePdfFormat={i => this.setState({PDFFormatsSettings: i})}
                />
                <Form.Select
                  onAddItem={this.createShareLink}
                  search
                  disabled={this.state.Project===""}
                  allowAdditions={true}
                  label={LocalizedStrings.PublicShareLink}
                  value={this.state.PublicShareLink}
                  fluid
                  compact
                  options={this.state.PublicShareLinkOptions}
                  onChange={(_, { value }) => {
                    this.setState({ PublicShareLink: value });
                  }}
                />
              </Form>
              {this.state.Project === "" ? (
                          <Message
                            icon="dont"
                            error
                            header={LocalizedStrings.NoOnlineProjectHeader}
                            content={LocalizedStrings.NoOnlineProjectText}
                          />
                        ) : null}
              <div style={{height:"40em", overflow:"auto", marginTop: "1em", marginBottom:"1em"}}>
                <b>{LocalizedStrings.Worksheets}</b>
            <LRFilterInput  value = {this.state.searchFilter} onChange={(value)=> {this.setState({searchFilter: value})}}/>
            <List>
            {this.state.WorksheetsList.filter(e=>{ 
              
              if(this.state.searchFilter !== "")
              {
                if( ! e.Name.toLowerCase().includes(this.state.searchFilter.toLowerCase()) )
                {
                  return false
                }
              }
              return true


            }).map((c, i)=><List.Item key={i} onClick={()=>{this.setState({protocolText: this.state.protocolText + `\n\n%%WORKSHEET(${c.Name})%%`})}}><Icon name="plus"/>{c.Name}</List.Item>)}
            </List>
            </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <LRModal
          open={this.state.createOpen}
          title={LocalizedStrings.CreatePreset}
          onCancelClick={this.closeCreateModal}
          onOkClick={this.createNewPreset}
        >
          <Form>
            <Form.Input
              value={this.state.createName}
              label={LocalizedStrings.PresetName}
              onChange={(e, { value }) => {
                this.setState({ createName: value });
              }}
            />
          </Form>
        </LRModal>
      </LRModal>
    );
  }

  createShareLink = async(e, data) =>
  {
    await window.LR_CreateShareLinkForProject({Name: data.value})
    this.show()
  }

  updateClick = () => {
    window.LR_SetCalculationReportTemplate({ 
      Name: this.state.selectedPreset, 
      Markdown: this.state.protocolText, 
      BackgroundTemplate: this.state.selectedPrintLabel, 
      FirstPageBackgroundTemplate: this.state.selectedPrintLabelFirstPage, 
      PublicShareToken: this.SHARE_LINK_MAP[this.state.PublicShareLink],
      PDFFormats: this.state.PDFFormatsSettings
    })
  }

  deleteClick = () =>
  {
    window.LR_DeleteCalculationReportTemplate({Name: this.state.selectedPreset})
  }

  createClick = () =>
  {
    this.setState({createOpen: true, createName: "Unknown Preset"})
  }


  closeCreateModal = () =>
  {
    this.setState({createOpen: false})
  }

  createNewPreset = () => {
    window.LR_AddCalculationReportTemplate({ 
      Name: this.state.createName, 
      Report: this.state.protocolText, 
      BackgroundTemplateUUID: this.state.selectedPrintLabel, 
      FirstPageBackgroundTemplateUUID: this.state.selectedPrintLabelFirstPage, 
      PublicShareToken: this.SHARE_LINK_MAP[this.state.PublicShareLink],
      PDFFormats: this.state.PDFFormatsSettings
    })
    this.setState({ selectedPreset: this.state.createName })
    this.closeCreateModal();
  }

  fetchReportPresets = async () =>
  {
    let presets = await window.LR_GetPaperworkReportTemplateMap()

    this.setState({reportPresets: presets.PaperworkReports.paperworkReport})
  }

  changeSelectedPreset = (preset) =>
  {
    let thePreset = this.state.reportPresets.find(pr => pr.Name === preset)
    if (thePreset) {
      this.setState({ 
        selectedPreset: preset, 
        protocolText: thePreset.Markdown, 
        selectedPrintLabel: thePreset.BackgroundTemplate, 
        selectedPrintLabelFirstPage: thePreset.FirstPageBackgroundTemplate, 
        PublicShareToken: thePreset.PublicShareToken,
        PDFFormatsSettings: thePreset.PDFFormats
      })
    }
  }

  setUpCallbacks()
  {
    globalCallbacks.GetWorksheetAsTableForExport = async (args) => {
      return new Promise(res => {
        let t = document.createElement('div')
        const root = createRoot(t);
        const obs = new MutationObserver(()=>{
          res({xml: new XMLSerializer().serializeToString(t)})
          obs.disconnect()
          setTimeout(()=>{
            root.unmount()
            t.remove()
          })
        })
        obs.observe(t, {
          childList: "true",
          subtree: true
        })
        let RenderTableOutside = React.lazy(()=>import("./WorksheetTableExport.js"))
        root.render(<Provider store={store}><RenderTableOutside args={args}/></Provider>);
      })
    }

    globalCallbacks.ShowExportPaperworkPdf = () => 
    { 
      this.show();
    }

    globalCallbacks.GetPaperworkReportPresets = () =>
    {
      this.fetchReportPresets()
    }

    window.LR_GetProjectSettings().then((res) => {
      if (res) {
          this.setState({PDFFormatsSettings: res.PdfFormat})
      }
  })
  }
}

export default ExportPaperworkModal