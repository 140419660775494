//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Loader, Dimmer } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/ProgressBar";
import { IsUsingWebassembly } from '../../webApp/ipcRenderer';

import { globalCallbacks } from "../../util/callback";

//-----------------------------------------------------------------------------
// The component for the TreeControl
class ProgressBar extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
      message: ""
    }
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }
    
  render() 
  {
    let message = this.state.message
    if(!message)
    {
      message ="Processing"
    }

    return (
      <Dimmer active={this.state.open}>
        <Loader active={this.state.open} size="massive">
            {LocalizedStrings[message]}
        </Loader>
      </Dimmer>
    )
  }

  setUpCallbacks()
  {


    globalCallbacks.ShowProgressBar = (message) => 
    { 
      if(IsUsingWebassembly && window.WEB_CORE.isFallbackLoaded){
        return
      }
      
      this.setState({open: true, message:message})
    }

    globalCallbacks.HideProgressBar = () => 
    { 
        this.setState({open: false, message:""})
    }
    
  }
}

export default ProgressBar