import React, { ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react"

interface IProps {
    onIdentifyDragElements?:()=>any[]
    dragElements?:any[]
    children: ReactNode[],
    dropZoneId:string,
    onDrop:(data:any)=>void
    onSetData:(e)=>any 
    onValidate?:(data)=>boolean
}

let dragData:any = null
let dragTarget =null
let dragTargtValid = false

export const DragAndDropWrapper:React.FC<IProps> = (props:IProps)=>{
    const { dragElements, onIdentifyDragElements, onSetData,onDrop,onValidate, dropZoneId } = props

    const valid = dragElements || onIdentifyDragElements;

    if(!valid){
        console.error("either dragElements or onIdentifyElements must be defined")
    }

    useEffect(
        () => {
            const elements = onIdentifyDragElements()
            elements?.forEach((element)=>{
                element.draggable = true
                element.setAttribute("dropZoneId",dropZoneId)
            })
        }
    ); 
      
    function handleDragStart(e){
        const data = onSetData(e);
        e.dataTransfer.setData('text/plain',data );
        dragData = data
    }

    function validateZone(e){
        e.preventDefault()

        if(dragTarget != e.target){
            dragTarget = e.target
            dragTargtValid = 
                props.dropZoneId == e.target.getAttribute('dropZoneId') 
                && (!onValidate || onValidate(dragData))
        }
        
        if(!dragTargtValid) e.dataTransfer.dropEffect = 'none';
    }
    
    function handleDrop(e){
        const data = dragData
        validateZone(e)
        if(dragTargtValid) {onDrop(data)}
        else {e.preventDefault(); }
    }

    return (
        <>
            {React.Children.map(
                props.children,
                (child) => React.cloneElement(
                    child as React.DetailedReactHTMLElement<any, HTMLElement> ,
                    { 
                        onDragStart:handleDragStart,
                        onDragOver:validateZone,
                        onDragEnter:validateZone,
                        onDrop:handleDrop,
                        dropZoneId
                    })
            )}   
        </>
    )
}
