//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Table, Segment, Header, Grid, Label, Button} from 'semantic-ui-react';
import { connect } from 'react-redux';
import SummaryTablePdf from './SummaryTable';
import LocalizedStrings from "../../../localization/TableViewComponent";
import { BASE_UNIT_FORCE, BASE_UNIT_POWER, BASE_UNIT_WEIGHT } from '../../../util/defines';
import { getUnit, getUnitName, getUnitValueFromCore } from '../../Basics/BasicUnitInput';
import { GLOBAL_SETTINGS } from '../../../redux/redux_defines';


import { globalCallbacks as mainCB } from '../../../util/callback';
import { globalCallbacks as mockCB } from '../../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB

class SummeryList extends Component
{
    constructor(prop)
    {
        super(prop);
        this.state = 
        {
            containers: [],
            expandedContainers: [],
            summeryList: {},
            showTable: false,
        }
        
    }

    componentDidMount()
    {
        this.setupCallbacks();

        if (globalCallbacks.refreshSummeryList) {globalCallbacks.refreshSummeryList()}
    }

    componentDidUpdate(prevProps, prevState)
    {
        if (prevProps.worksheet !== this.props.worksheet)
        {
            if (globalCallbacks.refreshSummeryList) {globalCallbacks.refreshSummeryList()}
        }
    }

    render()
    {
        let electical_rows  = [{ident: "Overview_KeyTotalPower", baseUnit: BASE_UNIT_POWER}, 
                               {ident: "Overview_KeyTotalConsumer", baseUnit: BASE_UNIT_POWER}]
        let show_rows      =  [{ident: "Overview_PresetCount"}, 
                                {ident: "Overview_CueCount"}]
        let dmx_rows        = [{ident: "Overview_DMXParams"}, 
                               {ident: "Overview_DMXUniveres"},
                               {ident: "Overview_DMXFixtures"}]
        let support_rows    = [{ident: "Overview_Weight", baseUnit: BASE_UNIT_WEIGHT}, 
                               {ident: "Overview_ReactionForce", baseUnit: BASE_UNIT_FORCE},
                               {ident: "Overview_SupportCount"}]
        let truck_rows      = [{ident: "Overview_Trucks"}, 
                               {ident: "Overview_Storage"},
                               {ident: "Overview_Used"}]
        return (
                <Grid>
                    {this.props.worksheet ? <Grid.Row>
                        <Grid.Column width="8">
                            <h1>{LocalizedStrings.SummeryScene}</h1>
                        </Grid.Column>
                    </Grid.Row> : null}
                    <Grid.Row>
                        <Grid.Column width="8">
                            {this.renderOverview("Overview_Inventory", truck_rows)}
                        </Grid.Column>
                        <Grid.Column width="8">
                            {this.renderOverview("Overview_Show", show_rows)}
                        </Grid.Column>
                    </Grid.Row>
                    {this.props.worksheet ? <Grid.Row>
                        <Grid.Column width="8">
                            <h1>{LocalizedStrings.SummeryWorksheet}</h1>
                        </Grid.Column>
                    </Grid.Row> : null}
                    <Grid.Row>
                        <Grid.Column width="8">
                            {this.renderOverview("Overview_DMX", dmx_rows)}
                        </Grid.Column>
                        <Grid.Column width="8">
                            {this.renderOverview("Overview_Supports", support_rows)}
                        </Grid.Column>
                    </Grid.Row>
                        <Grid.Column width="8">
                            {this.renderOverview("Overview_Electrical", electical_rows)}
                        </Grid.Column>
                    <Grid.Row>
                    </Grid.Row>

                    <Grid.Row><SummaryTablePdf/></Grid.Row>
                </Grid>
        );
    }

    renderOverview(header, rows)
    {
    const {showTable} = this.state;
        return (
            <React.Fragment key={header}>
                <Segment>
                    <Header as='h3'>
                    {LocalizedStrings[header]}
            {header === "Overview_DMX" &&  
                            <Button
                                onClick={() => this.setState({ showTable: !showTable })}
                                content={showTable ? "Hide Table" : "Show Table"}
                                style={{ float: "right" }}
                            />

            }
                    </Header>
                    <Table celled style={{margin: "0"}} definition>
                    <Table.Body>
                        {rows.map(row => { 
                            let unit = getUnit(row.baseUnit, this.props.globalSettings)
                            let unitName = getUnitName(unit, row.baseUnit)
                            return <Table.Row key={row.ident}>
                                                    <Table.Cell style={{position: 'relative'}}>
                                                        {LocalizedStrings[row.ident]}
                                                        {unitName ? <Label style={{position: 'absolute', right:'1rem'}} size="tiny" content={unitName} /> : null}
                                                    </Table.Cell>
                                                    <Table.Cell>{row.baseUnit ? getUnitValueFromCore(this.state.summeryList[row.ident], unit, row.baseUnit) : this.state.summeryList[row.ident]}</Table.Cell>
                                                   </Table.Row>})}
                    </Table.Body>
                    </Table>

                    <SummaryTablePdf
                        open     = {this.state.showTable}
                        onClose  = {() => this.setState({showTable: false})}
                        DmXParam = {this.state.summeryList.Overview_DMXParams}
                    >
                    </SummaryTablePdf>


                </Segment>
            
        </React.Fragment> 
        );
    }

    
   setupCallbacks()
   {
       globalCallbacks.refreshSummeryList = () => 
       {
            window.LR_GetSummeryList({Worksheet: this.props.worksheet}).then(res => {
                this.setState({summeryList: res.Summery})
            })
       }
   }
}

//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      globalSettings: state[GLOBAL_SETTINGS].data.GlobalSettings,
    };
}

let componentExport;
if(!process.env.JEST_WORKER_ID) { componentExport = connect(mapStateToProps)(SummeryList) }
else                                         { componentExport = SummeryList }


export default componentExport 