//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component, } from 'react';
import { connect } from 'react-redux';
import { PROJECTS } from '../../redux/redux_defines'
import { Table, Input, Grid, Header, Button, Form, Icon } from 'semantic-ui-react';
import LRModal from '../Basics/BasicModal';
import QrCodeReader from '../QrCodeScanner/QrCodeScanner';


class Inventory extends Component 
{
  constructor(props)
    {
        super(props);
        this.state = {
          open:false,
          qropen: false
        }
    }
  render()
  {
    return(<>
      
    <Grid>
    <Grid.Row>
      <Grid.Column>
      <Input fluid/>
      </Grid.Column>
    </Grid.Row>
      <Grid.Row>
      <Grid.Column width="10">
      <Header>Maintenance Pool</Header>
      <Button positive onClick={()=>{this.setState({open:true})}}>Add...</Button>
      <Table celled striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Group</Table.HeaderCell>
          <Table.HeaderCell>Supplier</Table.HeaderCell>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Linked Symbol</Table.HeaderCell>
          <Table.HeaderCell>Serial</Table.HeaderCell>
          <Table.HeaderCell>Next Maintenance</Table.HeaderCell>
          <Table.HeaderCell>Operating Hours</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
      </Table.Body>
    </Table>
    </Grid.Column>
    <Grid.Column  width="6">
      <Header>Maintenance History</Header>
      {this.renderTableMaintenance()}
      <Button positive>Start new maintenance...</Button>
      <Header>Sheduled Maintenance</Header>
      {this.renderTableMaintenance()}
      <Button positive>Shedule new maintenance...</Button>
    </Grid.Column>
    </Grid.Row>
    </Grid>
    <LRModal
    title="Add new Inventory Object"
    open={this.state.open}
    onCancelClick={()=>{this.setState({open:false})}}
    onClose>
      <Button onClick={()=>this.setState({qropen: true})}><Icon name="qrcode"/>Scan QR Code</Button>
      <Form>
        <Form.Input label="Name"></Form.Input>
        <Form.Input label="Serial"></Form.Input>
      </Form>
    </LRModal>
    <QrCodeReader 
      open={this.state.qropen}
      noCancelButton
      onCancelClick={()=>{this.setState({qropen:false})}}
      onOkClick={()=>{this.setState({qropen:false})}}
      title={"Import QR Code"}
      size="mini"
      noCancel={true}
    />
    </>)

  }

  renderTableMaintenance = () =>
  {
    return(<>
    <Table celled striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>User</Table.HeaderCell>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell>View</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {this.renderTableMaintenanceRow()}
        {this.renderTableMaintenanceRow()}
        {this.renderTableMaintenanceRow()}
        {this.renderTableMaintenanceRow()}
        {this.renderTableMaintenanceRow()}
        {this.renderTableMaintenanceRow()}
        {this.renderTableMaintenanceRow()}
      </Table.Body>
    </Table>
    </>)
  }

  renderTableMaintenanceRow = () =>
  {
    return(<>
    <Table.Row>
      <Table.Cell>
          Hoist Check 
        </Table.Cell>
        <Table.Cell>
          Malte Jäger
        </Table.Cell>
        <Table.Cell>
          12.15.2020
        </Table.Cell>
        <Table.Cell>
          <Button fluid compact>Open</Button>
        </Table.Cell>
      </Table.Row>
    </>)
  }

  renderRow = () =>
  {
    return (
      <>
      <Table.Row>
        <Table.Cell>
          Hoists 
        </Table.Cell>
        <Table.Cell>
          BigRig 
        </Table.Cell>
        <Table.Cell>
          C1 500kg
        </Table.Cell>
        <Table.Cell>
          Symbol Name
        </Table.Cell>
        <Table.Cell>
          3453453135153347864325
        </Table.Cell>
        <Table.Cell>
          15.12.2021
        </Table.Cell>
        <Table.Cell>
          500
        </Table.Cell>
      </Table.Row>
      </>
    )
  }

}

//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      projects: (state[PROJECTS])
    };
}

export default connect(mapStateToProps)(Inventory)
