import LocalizedStrings from 'localized-strings';

import en from "./raw/en/createUser.json"
import de from "./raw/de/createUser.json"
import es from "./raw/es/createUser.json"
import fr from "./raw/fr/createUser.json"
import it from "./raw/it/createUser.json"
import ru from "./raw/ru/createUser.json"
import cz from "./raw/cs/createUser.json"
 


let Strings = new LocalizedStrings(
{
  en: en,
  de: de,
  es: es,
fr: fr,
ru: ru,
it: it,
cz: cz,

});
    
export default  Strings;