//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
const registerdCallbacks  = []

export let ipcRenderer = 
{
    runCallBack: function(command, event, argument) 
    {
        registerdCallbacks.forEach( value => 
        {
            if(value.command === command) { value.func(event, argument) } 
        })
    },

    on: function(command, func) 
    {   
        if(registerdCallbacks.find(rc => rc.command === command && rc.func === func) === undefined) {
            registerdCallbacks.push({command: command, func: func})
        }
    },
    
    sendSync: async function(command, argument1, argument2) 
    {
        return {}
    }
}
window.ipcRenderer = ipcRenderer