//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Button, Icon, Table } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/EditShortCutModal";
import LRModal from '../Basics/BasicModal';
import { GetPossibleMenuBarCommands } from '../MenuBar/MenuBar';
import { globalCallbacks } from "../../util/callback";
import { formatKey } from "./ShortCut";


let ACTIONS =
[
  ...GetPossibleMenuBarCommands(),
  {
    cmd:"LR_V_CancelActiveTool",
    label:"Cancel Active Tool"
  },
  {
    cmd:"LR_V_CompleteActiveTool",
    label:"Complete Active Tool"
  },
  {
    cmd:"LR_V_TransformTool",
    label:"Move Objects"
  },
  {
    cmd:"LR_V_RotateTool",
    label:"Rotate Objects"
  },
  {
    cmd:"LR_V_SelectToolSingle",
    label:"Select Single Object"
  },
  {
    cmd:"LR_V_SelectToolSameNameO",
    label:"Select Object Same Name In Scene"
  },
  {
    cmd:"LR_V_SelectToolSameNameI",
    label:"Select Object Same Name In Group"
  },
  {
    cmd:"LR_V_SelectBox",
    label:"Rectangle Object Selection"
  },
  {
    cmd:"LR_V_Camera",
    label:"Show QR Code Reader"
  }
].filter(e=>e.label).sort((a,b)=>
{
  return (a.label < b.label ? -1 : 1 )
})

//-----------------------------------------------------------------------------
// The component for the TreeControl
class EditShortCutModal extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
      ShortCuts: {},
      StoreKeyFor: undefined,
      Code:undefined,
      Shift:undefined,
      Alt:undefined,
      CommandOrControl:undefined,
    }
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }

  show = async () => 
  {
    let sc = await window.LR_GetShortCutsSettings({})
    this.setState( { open : true, ShortCuts: sc});

  }

  close = () => 
  {
    window.location.reload()
    this.setState({open : false});
  }
    
  render() 
  {
    let open = this.state.open;
    if(!open) {return <div/>}
    return (
      <LRModal  open={open}
                title={LocalizedStrings.HeaderEditShortCuts}   
                noCancel     
                customOKText={LocalizedStrings.Close}
                onOkClick={this.close}
                additionalActions={<Button onClick={this.resetToDefault}>{LocalizedStrings.ResetToDefault}</Button>}
          >
          <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{LocalizedStrings.CellHeaderCommand}</Table.HeaderCell>
              <Table.HeaderCell> {LocalizedStrings.CellHeaderKey}</Table.HeaderCell>
              <Table.HeaderCell> {LocalizedStrings.Change}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
          {ACTIONS.map((e,i)=>{return this.renderShortCut(e, i)})}
          </Table.Body>
          </Table>
                    
      </LRModal>
    )
  }

  renderShortCut = (e, i) =>
  {

    if(!e.cmd)
    {
      return null
    }
    let key;

    let ident = e.cmd
    if(e.extra)
    {
      ident += e.extra
    }

    if(this.state.ShortCuts[ident])
    {
      key = this.state.ShortCuts[ident].Key
    }
    let active = this.state.StoreKeyFor === i


    return(<React.Fragment key={i}>
            <Table.Row>
            <Table.Cell>
                {e.label}
              </Table.Cell>
              <Table.Cell>
                {active ? <Icon color='green' name='keyboard' loading/> : undefined}
                {active ? this.state.Code ? <b>{this.returnKeyCode()}</b> :<i>{LocalizedStrings.PressTheKeys}</i> : key}
                {active ? <Icon color='green' name='keyboard' loading/> : undefined}
              </Table.Cell>
              <Table.Cell>
                <Button.Group>
                <Button onClick={()=>{this.startChangeShortCut(i, e)}} positive={active}>{active ? LocalizedStrings.Store : LocalizedStrings.Change}</Button>
                {active ? <Button onClick={()=>{this.startChangeShortCut(undefined, e)}} negative={active}>{LocalizedStrings.Cancel}</Button> : null}
                {!active ? <Button disabled={!key} onClick={()=>{this.remove(e)}} negative={true}>{LocalizedStrings.Remove}</Button> : null}
                </Button.Group>
              </Table.Cell>
            </Table.Row>
            </React.Fragment>)
  }

  returnKeyCode()
  {
    let text = ""
    if(this.state.CommandOrControl) text += "CommandOrControl+"
    if(this.state.Shift) text += "Shift+"
    if(this.state.Alt) text += "Alt+"
    if(this.state.Code) text += this.state.Code

    return text
  }

  remove = async(e)=>
  {
    await window.LR_SetShortCut({ShortCut: {
      Key:"",
      Command:e.cmd,
      Extra:e.extra,
    }})
    this.show();
  }

  startChangeShortCut = async(i, e) =>
  {
    if(i === this.state.StoreKeyFor)
    {
      window.removeEventListener("onKeyDown", this.onKeyDown)
      globalCallbacks.RECORDING_KEY_PRESS = false
      
      if(globalCallbacks.DisableElectronToSendShortCuts)
      
      globalCallbacks.DisableElectronToSendShortCuts(true)

      await window.LR_SetShortCut({ShortCut: {
        Key:this.returnKeyCode(),
        Command:e.cmd,
        Extra:e.extra,
      }})

      this.setState({
        Code: undefined,
        Shift: undefined,
        Alt: undefined,
        CommandOrControl: undefined,
        StoreKeyFor: undefined
      })

      this.show();
    }
    else
    {
      this.setState({StoreKeyFor: i})

      window.addEventListener("keydown", this.onKeyDown)
      globalCallbacks.RECORDING_KEY_PRESS = true

      if(globalCallbacks.DisableElectronToSendShortCuts)
      globalCallbacks.DisableElectronToSendShortCuts(false)
    }
    
  }

  onKeyDown = (e) =>
  {
    this.setState({
      Code: formatKey(e),
      Shift: e.shiftKey,
      Alt: e.altKey,
      CommandOrControl: navigator.platform === "MacIntel" ? e.metaKey : e.ctrlKey,
    })

  }

  resetToDefault = async () =>
  {
    if(window.confirm(LocalizedStrings.AreYouSure))
    {
      await window.LR_ResetShortCutSettings();
      globalCallbacks.ShowShortCutModal();
    }
  }


  setUpCallbacks()
  {

    globalCallbacks.ShowShortCutModal = () => 
    { 
      this.show();
    }
    
  }
}

export default EditShortCutModal