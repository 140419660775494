
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Button, Divider, Segment, Icon, TextArea, Grid, List, Form } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/RequestStructuralCheck";
import { IsElectronContext } from '../../util/defines';
import gfm from 'remark-gfm'
import ReactMde from 'react-mde';
import { Text} from "../ExportPaperworksReport/MarkdowTableSupport";

const ReactMarkdown = React.lazy(()=>import("react-markdown"))

class StructuralChecks extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = 
        {
            StructuralReportText: "",
            Password: "",
            changed: true,
            showOpenTask: true,
            showAddTask: true,
            showDoneTask: true,
            openTasks: [
                {
                    text: "Check if all fixtures have weight",
                    note: ""
                },
                {
                    text: "Check if all trusses have weight",
                    note: ""
                },
                {
                    text: "Check if all hoists have weight",
                    note: ""
                },
                {
                    text: "Check if all other loads have a proper load",
                    note: ""
                },
                {
                    text: "Check if all the loads are attached",
                    note: ""
                },
                {
                    text: "Check if all trusses have cross sections",
                    note: ""
                },
                {
                    text: "Check that the support workload <=  100%",
                    note: ""
                },
                {
                    text: "Check that the cross section workload 100%",
                    note: ""
                },
                {
                    text: "Check if the corners are properly loaded",
                    note: ""
                },
                {
                    text: "Check if all trusses are connected and there are no gaps",
                    note: ""
                },
                {
                    text: "Check deflection figure",
                    note: ""
                },
                {
                    text: "Check deflection is an a reasonable Amount",
                    note: ""
                },
                {
                    text: "Check for Rope Failure",
                    note: ""
                },
                {
                    text: "Check how sensitive the structure is to Chain Shorten Effects",
                    note: ""
                },
                {
                    text: "Check if there is a dynamic factor applied or needs to be.",
                    note: ""
                },
                {
                    text: "Check the CF Factor from Wind loads.",
                    note: ""
                },
                {
                    text: "Check of the structures tips from any wind directions.",
                    note: ""
                },
                {
                    text: "Check if the structure is overloaded from any wind direction.",
                    note: ""
                },
            ],
            doneTasks: []
        };

    }

    componentDidMount = () => 
    {
        this.fetchSingingJob()
    }

    fetchSingingJob = async () =>
    {
        let singingJob = await window.LR_GetSigningJob()
        let markdown = await window.LR_GetCalculationReportMarkdown()

        if(singingJob && Array.isArray(singingJob.checks)  )
        {
            this.setState({doneTasks: singingJob.checks})
        }

        this.setState({StructuralReportText: markdown.Report})
    }

    render() 
    {

        return (
            <div style={{width:"100%", height:"100%"}}>
                <Button positive fluid onClick={this.fetchSingingJob}>{LocalizedStrings.Reload}</Button>
                <div style={{width:"100%", maxHeight: IsElectronContext() ? "calc(100vh - 20em)" :"calc(100vh - 25em)", overflowY:"auto", overflowX: this.props.small ? undefined : "clip", marginBottom:"5em"}}>
                <h2 onClick={()=>this.setState({showMarkDownEditor: !this.state.showMarkDownEditor})}><Icon name={this.state.showMarkDownEditor ? "angle down" : "angle right"}/> {LocalizedStrings.ReportText}</h2>
                { 
                    this.state.showMarkDownEditor ? 
                    <>
                <Button
                positive
                fluid
                onClick={()=>    window.LR_SetCalculationReportMarkdown(
                    {
                      Report:this.state.StructuralReportText,
                    })
                }
                
                >

                    {LocalizedStrings.SaveReport}
                </Button>
                <ReactMde
                    getIcon={(commandName) => <Icon name={commandName} />}
                    value={this.state.StructuralReportText}
                    onChange={(data) => this.setState({StructuralReportText: data, changed: true})}
                    selectedTab={this.state.selectedTabSimple}
                    onTabChange={(data) => this.setState({selectedTabSimple: data})}
                    generateMarkdownPreview={(markdown) => Promise.resolve(<ReactMarkdown components={{p: Text}} remarkPlugins={[gfm]}>{markdown}</ReactMarkdown>) }
                    minEditorHeight={300}
                    childProps={{ writeButton: { tabIndex: -1 }, textArea: {
                        onKeyDown: (e)=>{
                        // dont jump out of text field on tab
                        if (e.key == 'Tab') {
                            e.preventDefault();
                            let t = e.target
                            let start = t.selectionStart;
                            let end = t.selectionEnd;

                            let np = this.state.StructuralReportText
                            np = np.substring(0, start) + "\t" + np.substring(end);

                            this.setState({StructuralReportText: np}, ()=>{
                            t.selectionStart = t.selectionEnd = start + 1
                            })
                        }
                        }
                    } }}
                />
                <Form>
                    <Form.Input
                    type="password"
                    label={LocalizedStrings.Password}
                    value={this.state.Password}
                    onChange={(_, {name, value})=>this.setState({Password:value})}/>
                </Form> 
                <Button
                primary
                fluid
                onClick={ async ()=> 
                {
                    let res = await window.LR_UploadProvedStructuralReport({Password: this.state.Password})
                    if(res.ErrorMessage)
                    {
                        window.alert(res.ErrorMessage)
                    }
                }}
                >
                {LocalizedStrings.ExportStructuralReport}

                </Button>
                </> : null}
                <Divider horizontal/>
                <h2 onClick={()=>this.setState({showOpenTask: !this.state.showOpenTask})}><Icon name={this.state.showOpenTask ? "angle down" : "angle right"}/> {LocalizedStrings.OpenChecks}</h2>
                {
                    this.state.showOpenTask ? 
                    <>
                        {this.state.openTasks.map(e=>this.renderOpenTask(e))}
                    </> : null
                }
                <Divider horizontal/>
                <h2 onClick={()=>this.setState({showAddTask: !this.state.showAddTask})}><Icon name={this.state.showAddTask ? "angle down" : "angle right"}/> {LocalizedStrings.AddCustomCheck}</h2>

                {
                    this.state.showAddTask ? 
                    <>
                    <Segment>
                        <TextArea value={this.state.NewCheck} placeholder={LocalizedStrings.AddNote}  onChange={(_ ,{value})=>{this.setState({NewCheck: value})}}/>

                        <Button 
                            positive 
                            onClick={()=>this.setState({NewCheck: "", openTasks: [...this.state.openTasks, {text: this.state.NewCheck, note: ""}]})}
                            fluid>
                            {LocalizedStrings.AddCheck}
                        </Button>
                    </Segment>
                    </> : null
                }

                <Divider horizontal/>
                <h2 onClick={()=>this.setState({showDoneTask: !this.state.showDoneTask})}><Icon name={this.state.showDoneTask ? "angle down" : "angle right"}/> {LocalizedStrings.DoneChanges}</h2>
                { 
                    this.state.showDoneTask ? 
                    <List>
                        {this.state.doneTasks.map(e=>this.renderTask(e))}
                    </List> : null
                }
                



                </div>

             </div>

        );
    }

    renderOpenTask(e)
    {
        let UpdateText = (_, {value}) =>
        {
            this.setState({openTasks: this.state.openTasks.map(t=> 
            {
                if(t.text === e.text)
                {
                    return {...t, note: value}
                }
                return t
            })})
        }

        let ButtonClick = (testOK, addToMadeTests)=>
        {
            let newEntry = {
                text: e.text,
                note: e.note,
                ok: testOK,
            }

            let doneTasks =  [...this.state.doneTasks ]
            if(addToMadeTests)
            {
                doneTasks.push(newEntry)
                window.LR_UpdateSigningJob(
                {
                    Text: e.text,
                    Note: e.note,
                    OK: testOK,
                })
            }

            this.setState({
                openTasks: this.state.openTasks.filter(t=> 
                {
                    if(t.text === e.text)
                    {
                        return false
                    }
                    return true
                }),
                doneTasks: doneTasks
            })

        }

        return(
        <Segment  key={e.text}>
            <Grid divided >
                <Grid.Row>
                    <Grid.Column width={8}>
                        <h3>{e.text}</h3>
                        <TextArea value={e.note} placeholder={LocalizedStrings.AddNote} onChange={UpdateText}/>
                        <Divider horizontal/>
                    </Grid.Column>
                    <Grid.Column width={8}>
                    <Button.Group vertical>
                        <Button onClick={()=> ButtonClick(true, true)}><Icon color="green" name="thumbs up"/>{LocalizedStrings.Check_OK}</Button>
                        <Button onClick={()=> ButtonClick(false, true)}><Icon color="red" name="thumbs down"/>{LocalizedStrings.Check_NOT_OK}</Button>
                        <Button onClick={()=> ButtonClick(undefined, false)}><Icon color="grey" name="circle"/>{LocalizedStrings.Check_IGNORE}</Button>
                    </Button.Group>
                    </Grid.Column>

                </Grid.Row>
            </Grid>
        </Segment>
        )
        
    }


    renderTask(e,)
    {
        return<>
        <List.Item  key={e.text}>
            {e.ok ? <List.Icon verticalAlign='middle' color="green" name="thumbs up"/> : <List.Icon verticalAlign='middle' color="red" name="thumbs down"/>}
            <List.Content>
            <List.Header>{e.text}</List.Header>
            <List.Description>{e.note}</List.Description>
            </List.Content>
        </List.Item>
        </>
    }

    
}

export default StructuralChecks;

