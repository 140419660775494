//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from "react"
import { Table, Input, Button, Feed, Header, Icon, Segment, Menu, Loader, Container, GridColumn, Grid, Divider, List, Popup, Label } from "semantic-ui-react"
const ReactMarkdown = React.lazy(()=>import("react-markdown"))

import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import LocalizedStrings from "../../localization/ChangeRequest"
import CommitOverview from "../../component/CommitOverview/CommitOverview";
import gfm from 'remark-gfm'

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FetchActiveCheckForProject, hasFetched, FetchProject, FetchActiveChange, FetchCollaborators, FetchReview } from "../../redux/actions/fetch_actions";
import { ACTIVE_CHECK_PROJECT, ACTIVE_PROJECT, ACTIVE_USER, ACTIVE_CHANGE, COLLABORATORS, REVIEW } from "../../redux/redux_defines"




import { lrServerConnection } from "../../redux/light_right_server_connection";
import UserAvatar from "../WebComponents/UserAvatar";
import DiffRenderer from "../Renderer/DiffRenderer";
import { withRouter } from "../../webApp/WebRouter";

class ChangeRequest extends Component
{
    constructor(props)
    {
        super(props)

        this.state = 
        {
            open: false,
            mergable: null,
            review: null,
            preview: false,
            diff: undefined,
            loadingDiff: false,
            stateCheckFinished: false,
            newComment: "",
            assignedEdit: null
        }

        this.prTitleRef = React.createRef()
    }

    componentDidUpdate(prevProps, prevState) 
    { 
      if (this.prTitleRef.current && prevState.editPullName === undefined) 
      { 
        this.prTitleRef.current.focus() 
        this.prTitleRef.current.select()
      }
    }

    FetchChecks = () =>
    {
      if(hasFetched(this.props.activeChange) && !this.state.stateCheckFinished)
      {
        let checks = this.props.activeChange.data[this.props.activeChange.data.length - 1]?.checks ?? []
        for(let [i, id] of checks.entries())
        {
          if(this["statecheck_"+ id]) { return }
          this["statecheck_"+ id] = true
          lrServerConnection.getCheckRunByID(id).then(runCheck =>
          {
            this.setState({["statecheck"+ runCheck.action]: runCheck})

            if(i === checks.length-1)
            {
              this.setState({stateCheckFinished: true})
            }
          })
        }

        if(checks.length === 0)
        {
          this.setState({stateCheckFinished: true})
        }
      }
    }

    render()
    {
        FetchProject()
        let ChangeNumber = this.props.match.params.changenumber
        if(ChangeNumber != lrServerConnection.__CHANGELIST) { 
          lrServerConnection.setFile(undefined, undefined,ChangeNumber) 
          FetchActiveChange(true)
        }
        FetchCollaborators()
        FetchActiveCheckForProject()
        let isLoading = !hasFetched(this.props.activeProject)
        let branches = !isLoading ?  this.props.activeProject.data.branches : undefined
        let branch = branches ? branches.find(b => String(b.number) === this.props.match.params.changenumber) : undefined
        if (branch && branch.review) {
          lrServerConnection.setReview(branch.review)
          FetchReview()
        }


        this.FetchChecks()
        
        // If it has not fetched mergable, fetch it
        if(this.state.mergable === null && branch) 
        {
          // Fetch it
          lrServerConnection.getMergable("master", branch._id)
          .then(res => { this.setState({ mergable: res}) })

          // Set Loading indicator
          this.setState({ mergable: "loading"})
        }
        
        
        if( ! branch)
        {
          branch ={
            createdBy:"",
            files:[],
            _id: "master"
          }
        }

        let activeComment = this.props.match.params.changeview === "comment" || this.props.comments
        let activeCommits = this.props.match.params.changeview === "commits"
        let activeChanges = this.props.match.params.changeview === "diff"

        return (
        <Container>
            {!this.state.editPullName && 
              <Header as="h1">
                <Header.Content style={{width: "100%"}}>
                  {`${branch.description} #${this.props.match.params.changenumber}`}
                  {this.state.editPullName === undefined && <Button onClick={() => { this.setState({editPullName: branch.description}); }} floated="right">{LocalizedStrings.Edit}</Button>}
                </Header.Content>
              </Header>}
            {this.state.editPullName && 
                  <div style = {{width: "100%"}}>
                    <Grid style = {{width: "100%"}}>
                      <GridColumn width={13} style={{ padding: "17px", 
                                                      paddingRight: "0px", 
                                                      paddingLeft: "0px"  }}>
                        <Input  style     = {{ display: "block !important", width: "100%"}}
                                ref       = {this.prTitleRef}
                                size      = "mini"
                                fluid
                                value     = {this.state.editPullName} 
                                onChange  = {(e, {value}) => {this.setState({editPullName: value})}} 
                                onKeyDown = {(e) => { if (e.keyCode === 13) { this.editPullRequestName(branch); 
                                                                              this.setState({editPullName: undefined}) } }}/>
                      </GridColumn>
                      <GridColumn width={3} 
                                  style={{ paddingRight: "0px", 
                                           paddingLeft: "0px" }}>
                        <Button style   = {{  display: "inline-block", 
                                              width: "45%",  
                                              marginLeft: "5px" }} 
                                onClick = {() => {  this.editPullRequestName(branch); 
                                                    this.setState({editPullName: undefined})  }} 
                                floated = "right"
                                color   = "green">{LocalizedStrings.Confirm} 
                        </Button>
                        <Button style   = {{ display: "inline-block", 
                                             width: "45%", 
                                             marginLeft: "10px" }} 
                                onClick = {() => {  this.setState({editPullName: undefined})  }}
                                floated = "right" 
                                color   = "red">{LocalizedStrings.Abort} 
                        </Button>
                      </GridColumn>
                    </Grid>
                  </div>}
            <Header as="h4">
                {`${branch.createdBy.username} wants to merge ${branch.files.length} into master`}
            </Header>
            <Menu secondary>
                <Menu.Item active={activeComment} as={Link} to={`/${this.props.username}/${this.props.projectname}/changelists/${this.props.match.params.changenumber}/comment`}>{LocalizedStrings.Comments}</Menu.Item>
                <Menu.Item active={activeCommits} as={Link} to={`/${this.props.username}/${this.props.projectname}/changelists/${this.props.match.params.changenumber}/commits`}>{LocalizedStrings.Commits}</Menu.Item>
                <Menu.Item active={activeChanges} as={Link} to={`/${this.props.username}/${this.props.projectname}/changelists/${this.props.match.params.changenumber}/diff`}>{LocalizedStrings.Differences}</Menu.Item>
            </Menu>
            {isLoading ? 
            <Loader /> :
            <>
            {activeComment ? this.renderComments(branch ? branch.comments : [], branch) : null} 
            {activeCommits ? <CommitOverview ChangeNumber={branch.number} match={this.props.match}/> : null}
            {activeChanges ? <>
            <Header>{LocalizedStrings.THREEDView}</Header>
            <Segment placeholder>
              
              {this.state.preview ? this.state.loadingDiff ? <Loader active/> : <DiffRenderer height={600} file={branch.current} prevFile={branch.base} differences={this.state.diff}/> : <Button primary onClick={this.openPreviewModal(branch)}><Icon name="sync"/>{LocalizedStrings.RenderDifferences}</Button>} 
            </Segment>
              <CommitOverview merge commitOverview base={this.props.activeProject.data.defaultBranch._id} target={branch._id}/>
            </> : null}
            </>
          }
          
        </Container>)
    }   

    toggleChangeListClose = async(branch) => {
      await lrServerConnection.toggleChangeListClose({branch: branch._id})
      FetchProject(true)
    }

    renderMergeSegment = (branch) => 
    {
      return(
        <Segment clearing>
          <Header>
            {LocalizedStrings.MergeChanges}
          </Header>
          {this.renderReviewUserTable(branch)}
          {this.renderCheckList()}
          <Divider/>
          {branch.closed ? null : this.renderMergeButton(branch)}
        </Segment>
      )
    }
    
    openPreviewModal = (branch) => async () =>
    {
      this.setState({preview: true})
      if(this.state.diff === undefined)
      {
        this.setState({loadingDiff: true})
        let diff = await lrServerConnection.getMergeCompare(this.props.activeProject.data.defaultBranch._id, branch._id)
        this.setState({diff: diff, loadingDiff: false})
      }
    }

    closePreviewModal = () =>
    {
      this.setState({
        preview: false
      })
    }

    renderMergeButton = (branch) => 
    {
        if(this.state.mergable === null || this.state.mergable === "loading") 
        {
            return <Button loading disabled>{LocalizedStrings.Loading}</Button>
        }
        else if(this.state.mergable && this.checkIfStatusOK()) 
        {
            return <Button positive onClick={()=>{ lrServerConnection.merge("master", branch._id)}}>
              <Icon name="birthday cake"/>
              {LocalizedStrings.Merge}
              </Button>
        }
        return <>
        <h5>{LocalizedStrings.MergingIsBlocked}</h5>
          <Button disabled negative>
            <Icon name="birthday cake"/>
            {LocalizedStrings.Merge}
          </Button>
          </>
    }

    checkIfStatusOK =() =>
    {
      let checksOK = true
      if(hasFetched(this.props.checks))
      {
        let projectChecks = this.props.checks.data?.checks;
        let userChecks    = this.props.checks.data?.userChecks;
  
        if(!projectChecks)  { projectChecks = []}
        if(!userChecks)     { userChecks = []}

        userChecks.forEach(check =>    { if(!this.state["statecheck"+ check._id] || ! this.state["statecheck"+ check._id].ok) { checksOK = false; } })
        projectChecks.forEach(check => { if(!this.state["statecheck"+ check._id] || ! this.state["statecheck"+ check._id].ok) { checksOK = false; } })
      }

      let reviewOK = true
      if(hasFetched(this.props.review))
      {
        let review = this.props.review.data
        if(!review) { review = {}}
        if(!review.AnyChecked) { review.AnyChecked = []}
        if(!review.AudioChecked) { review.AudioChecked = []}
        if(!review.VideoChecked) { review.VideoChecked = []}
        if(!review.LightChecked) { review.LightChecked = []}
        if(!review.RiggingChecked) { review.RiggingChecked = []}

        review.AnyChecked.forEach(review => { if(review.checked === false){ reviewOK = false}})
        review.AudioChecked.forEach(review => { if(review.checked === false){ reviewOK = false}})
        review.VideoChecked.forEach(review => { if(review.checked === false){ reviewOK = false}})
        review.LightChecked.forEach(review => { if(review.checked === false){ reviewOK = false}})
        review.RiggingChecked.forEach(review => { if(review.checked === false){ reviewOK = false}})
      }


      return checksOK && reviewOK
    }

    renderComments = (comments, branch) =>
    {
        if(!comments) { comments = []}
        let changes = []
       
        if(hasFetched(this.props.activeChange) && hasFetched(this.props.activeProject)) {
            let branches = this.props.activeProject.data.branches
            let branch = branches ? branches.find(b => String(b.number) === this.props.match.params.changenumber) : undefined
            changes = this.props.activeChange.data.filter(chng =>  branch.files.findIndex(id => id === chng._id) !== -1)
        }
        let completeFeed = [...comments , ...changes]
        completeFeed = completeFeed.sort((a,b) => new Date(a.date) - new Date(b.date))
        this.getAllProjectMembers()
        return(<Grid>
          <Grid.Row >
            <Grid.Column width="12">
                {(this.state.editDescription === undefined && 
                    <Segment clearing>
                        <ReactMarkdown remarkPlugins={[gfm]} children={branch.fullDescription !== "" ? branch.fullDescription : LocalizedStrings.noDescription}/>
                        <Button onClick={() => this.setState({editDescription: branch.fullDescription})} style={{float: "right",}} size="tiny" color="green">Edit</Button>
                    </Segment>)
                || (this.state.editDescription !== undefined &&
                    <Segment clearing>
                        <ReactMde
                            getIcon={(commandName) => <Icon name={commandName} />}
                            value={this.state.editDescription}
                            onChange={(data) => this.setState({editDescription: data})}
                            selectedTab={this.state.selectedTab}
                            onTabChange={(data) => this.setState({selectedTab: data})}
                            generateMarkdownPreview={(markdown) => Promise.resolve(<ReactMarkdown remarkPlugins={[gfm]} children={markdown} />) }
                            loadSuggestions={this.loadSuggestions}
                            childProps={{ writeButton: { tabIndex: -1 } }}
                        />  
                            <Button onClick={() => {this.editDescription(branch); this.setState({editDescription: undefined})}} style={{float: "right", marginTop: "10px"}} color="green">Confirm</Button>
                            <Button onClick={() => {this.setState({editDescription: undefined})}} style={{float: "left", marginTop: "10px"}} color="red">Abort</Button>
                    </Segment>
                )}
                <Feed>
                 {completeFeed.map(comment => this.feedEntry(comment, branch))}
                </Feed>
                {this.renderMergeSegment(branch)}
                <Segment clearing>
                    <ReactMde
                        getIcon={(commandName) => <Icon name={commandName} />}
                        value={this.state.newComment}
                        onChange={(data) => this.setState({newComment: data})}
                        selectedTab={this.state.selectedTabNewComment}
                        onTabChange={(data) => this.setState({selectedTabNewComment: data})}
                        generateMarkdownPreview={(markdown) => Promise.resolve(<ReactMarkdown remarkPlugins={[gfm]} children={markdown} />) }
                        loadSuggestions={this.loadSuggestions}
                        childProps={{ writeButton: { tabIndex: -1 } }}
                    />
                    <Button disabled={!this.state.newComment || this.state.newComment.length===0} onClick={() => {this.postComment(branch); this.setState({newComment: undefined})}} style={{float: "right", marginTop: "10px"}} color="green">Comment</Button>
                    {this.renderCloseOpenButton(branch)}
                </Segment>
                </Grid.Column>
                <Grid.Column width="4">
                  <Header>{LocalizedStrings.Assigned}<div style={{float: "right"}}>{this.renderAssignedUserEdit()}</div></Header>
                  {this.renderAssignedUser()}
                  {this.renderReviewTable()}
                  <Divider/>
                  <Header>{LocalizedStrings.Checkout}</Header>
                  <Button style={{marginBottom: "7px"}} fluid onClick={()=>{window.open("productionassist://openproject?user="+this.props.username+"&project="+this.props.projectname+"&branch=" + branch._id, '_blank');}} color='blue'>{LocalizedStrings.CheckoutInApp}</Button>
                  <Button fluid onClick={()=>{window.LR_ShowShareDialog()}} color='blue'>{LocalizedStrings.Share}</Button>
                </Grid.Column>
                </Grid.Row>
        </Grid>)
    }

    renderCloseOpenButton(branch)
    {
      if(branch.closed)
      {
        return <Button positive style={{float: "right", marginTop: "10px"}} onClick = {() => this.toggleChangeListClose(branch)}>{LocalizedStrings.Reopen}</Button>
      }
      return <Button style={{float: "right", marginTop: "10px"}} onClick = {() => this.toggleChangeListClose(branch)}><Icon name="cancel"/>{LocalizedStrings.Close}</Button>
      
    }

    renderAssignedUserEdit = () =>
    {
      if(this.state.assignedEdit === null) {
        return <Label onClick={this.onAssignEdit}>{LocalizedStrings.Edit}</Label>
      } else {
        return <>
          <Label color="green" onClick={this.onAssignApply}>{LocalizedStrings.Apply}</Label>
          <Label color="red" onClick={this.onAssignCancel}>{LocalizedStrings.Cancel}</Label>
        </>
      }
    }

    onAssignEdit = () => this.setState({assignedEdit: this.getAssignedUsers()})
    onAssignCancel = () => this.setState({assignedEdit: null})
    onAssignAdd = (user) => () => this.setState({assignedEdit: [...this.state.assignedEdit, user]})
    onAssignDelete = (user) => () => this.setState({assignedEdit: this.state.assignedEdit.filter(u => u.username !== user.username)})
    onAssignApply = () => {
      let isLoading = !hasFetched(this.props.activeProject)
      let branches = !isLoading ?  this.props.activeProject.data.branches : undefined
      let branch = branches ? branches.find(b => String(b.number) === this.props.match.params.changenumber) : undefined
      if(branch) {
        lrServerConnection.setAssignedUsers({
          branch: branch._id,
          assigned: this.state.assignedEdit.map(ass => ass.username)
        })
        .then(() => FetchProject(true))
      }
      this.setState({assignedEdit: null})
    }

    getAllProjectMembers = () =>
    {
      let members = []
      
      if(this.props.activeProject.hasFetched) {
        this.props.activeProject.data.members.forEach(user => {
          if(!members.find(member => member.username === user?.user?.username)) {
            members.push({username: user?.user?.username, name: user?.user?.name})
          }
        })
      }

      if(this.props.activeProject.hasFetched) {
        this.props.activeProject.data.groups.forEach(group => {
          group.group.members.forEach(user => {
            if(!members.find(member => member.username === user.username)) {
              members.push({username: user.username, name: user.name})
            }
          })
        })
      }      

      return members
    }

    getAssignedUsers = () =>
    {
      let assigned = []
      
      let isLoading = !hasFetched(this.props.activeProject)
      let branches = !isLoading ?  this.props.activeProject.data.branches : undefined
      let branch = branches ? branches.find(b => String(b.number) === this.props.match.params.changenumber) : undefined
      if(branch) {
        assigned = branch.assigned
      }

      return assigned
    }

    renderAssignedUser =() =>
    {
      let assigned = this.state.assignedEdit === null ? this.getAssignedUsers() : this.state.assignedEdit
      
      
      if(this.state.assignedEdit !== null) {
        let notAssigned = this.getAllProjectMembers().filter(user => !assigned.some(ass => ass.username === user.username))
        return <>
          <List>
            {assigned.map( user => <List.Item key={user.username}><UserAvatar user={user.username}/>{user.name}<div style={{float: 'right'}}><Icon name="delete" color="red" onClick={this.onAssignDelete(user)}/></div></List.Item>)}
          </List>
          <Header>{LocalizedStrings.AddAssignment}</Header>
          <List>
            {notAssigned.map( user => <List.Item key={user.username} onClick={this.onAssignAdd(user)}><UserAvatar user={user.username}/>{user.name}</List.Item>)}
          </List>
        </>
      }

      return (<List>
              {assigned.map( user => <List.Item key={user.username}><UserAvatar user={user.username}/>{user.name}</List.Item>)}
             </List>)
    }

    renderReviewTable = () =>
    {
      let review = hasFetched(this.props.review) ? this.props.review.data : undefined
      
      if(!review)
      {
        return null
      }

      return (
      <>
        <Divider />
        <Header>{LocalizedStrings.Review}</Header>
        <Table compact celled striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{LocalizedStrings.Craft}</Table.HeaderCell>
              <Table.HeaderCell>{LocalizedStrings.Status}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.renderReviewTableRow(LocalizedStrings.Any, "box", review.AnyChecked)}
            {this.renderReviewTableRow(LocalizedStrings.Audio, "file audio", review.AudioChecked)}
            {this.renderReviewTableRow(LocalizedStrings.Video, "video", review.VideoChecked)}
            {this.renderReviewTableRow(LocalizedStrings.Light, "lightbulb", review.LightChecked)}
            {this.renderReviewTableRow(LocalizedStrings.Rigging, "won", review.RiggingChecked)}
          </Table.Body>
        </Table>
      </>
      )
    }

    renderReviewTableRow(name, icon, array)
    {
      if( array?.length === 0)
      {
        return null
      }
      return(
        <Table.Row>
        <Table.Cell><Icon name={icon}/>{name}</Table.Cell>
        <Table.Cell><Icon name="circle" color={this.getStatusColor(array)}/></Table.Cell>
      </Table.Row>
      )
    }

    // takes arr where [{checked: bool, user: user}, ...]
    getStatusColor = (arr) => {
      let someTrue = arr?.some(status => status.checked === true)
      let someFalse = arr?.some(status => status.checked === false)
      let color = ""


      if (someTrue && !someFalse) { color = "green" }
      else if (someTrue) { color = "yellow" }
      else { color = "red" }

      return color
    }

    renderReviewUserTable = (branch) =>
    {
      let review = hasFetched(this.props.review) ? this.props.review.data : undefined
      let reviewUsersUnique = []

      if (review)
      {
        let reviewStatuses = review.AnyChecked
        reviewStatuses = reviewStatuses?.concat(review.AudioChecked)
        reviewStatuses = reviewStatuses?.concat(review.VideoChecked)
        reviewStatuses = reviewStatuses?.concat(review.LightChecked)
        reviewStatuses = reviewStatuses?.concat(review.RiggingChecked)

        reviewStatuses = reviewStatuses ?? [] 

        reviewStatuses.forEach(reviewStatus => {
          if (!reviewUsersUnique.some(unique => unique._id === reviewStatus.user._id))
          {
              reviewUsersUnique.push(reviewStatus.user)
          }
        })
      }

      if(!review)
      {
        return null
      }

      return(
        <>
          <Divider/>
          <Header as="h3">{LocalizedStrings.Review}</Header>
          <Table compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{LocalizedStrings.Craft}</Table.HeaderCell>
              <Table.HeaderCell>{LocalizedStrings.Status}</Table.HeaderCell>
              {reviewUsersUnique.map(user => { return <Table.HeaderCell key ={user.username}>{user.name}</Table.HeaderCell>})}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.renderReviewRow("box","Any", (review ? review.AnyChecked : []), reviewUsersUnique )}
            {this.renderReviewRow("file audio","Audio", (review ? review.AudioChecked : []), reviewUsersUnique )}
            {this.renderReviewRow("video","Video", (review ? review.VideoChecked : []), reviewUsersUnique )}
            {this.renderReviewRow("lightbulb","Light", (review ? review.LightChecked : []), reviewUsersUnique )}
            {this.renderReviewRow("won","Rigging", (review ? review.RiggingChecked : []), reviewUsersUnique )}
          </Table.Body>
          {! branch.closed ? 
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={reviewUsersUnique.length + 2}>
                <Button.Group floated="right">
                  <Button onClick={() => { this.changeReviewStatus(true, branch.review) }} positive><Icon name="thumbs up"/>{LocalizedStrings.ApproveChange}</Button>
                  <Button.Or />
                  <Button onClick={() => { this.changeReviewStatus(false, branch.review) }} negative><Icon name="thumbs down"/>{LocalizedStrings.RequestChanges}</Button>
                </Button.Group>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer> : null}
          </Table>
        </>)
    }

    changeReviewStatus = async(approved, reviewInstanceId) => {
      await lrServerConnection.toggleReviewInstance({reviewInstanceId, approved})
      FetchReview(true)
    }


    renderReviewRow = (icon, name, reviewStatuses, reviewUsersUnique) =>
    {
      // Check if there is actually a review needed
      if(reviewStatuses && reviewStatuses.length == 0)
      {
        return null
      }
      return  <Table.Row key={name}>
                <Table.Cell><Icon name={icon}/>{LocalizedStrings[name]}</Table.Cell>
                <Table.Cell><Icon name="circle" color={this.getStatusColor(reviewStatuses)}/></Table.Cell>
                {reviewUsersUnique.map(user => { return this.renderReviewCellChecked(user, reviewStatuses) })}
              </Table.Row>
    }

    renderReviewCellChecked = (user, reviewStatuses) => {
      let status = reviewStatuses.find(status => status.user._id === user._id)
      let iconName = status && status.checked ? "checkmark" : "x"
      
      return <Table.Cell key ={user.username}>{status && <Icon name={iconName}/>}</Table.Cell>
    }

    renderCheckList =()=>
    {
      if( ! hasFetched(this.props.checks))
      {
        return null
      }

      let projectChecks = this.props.checks.data?.checks;
      let userChecks    = this.props.checks.data?.userChecks;

      if(!projectChecks)  { projectChecks = []}
      if(!userChecks)     { userChecks = []}

      let count = projectChecks.length + userChecks.length
      if(count === 0) { return null }
      return (
        <>
          <Divider />
          <Header as="h3">{LocalizedStrings.Checks}</Header>
          <Table compact>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{LocalizedStrings.Check}</Table.HeaderCell>
                <Table.HeaderCell>{LocalizedStrings.Status}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {projectChecks.map((check) => this.renderCheckListRow(check))}
              {userChecks.map((check) => this.renderCheckListRow(check))}
            </Table.Body>
          </Table>


        </>)
    }

    renderCheckListRow = (check) =>
    {
      return  <Table.Row key={check.id}>
              <Table.Cell>{check.name}</Table.Cell>
              <Table.Cell>{this.renderCheckState(check)}</Table.Cell>
            </Table.Row>
    }

    renderCheckState = (check) =>
    {
      let icon = <Icon name="circle" color="red"/>
      let log = ""
      
      if(this.state["statecheck"+ check._id] === undefined && this.state.stateCheckFinished)
      {
        icon = <Icon name="circle" color="grey"/>
        log = LocalizedStrings.NoCheckRun
      }
      else if(this.state["statecheck"+ check._id] === undefined)
      {
        icon = <Icon name="spinner" color="yellow" loading/>
        log = LocalizedStrings.LoadingCheck
      }
      else if(this.state["statecheck"+ check._id].ok)
      {
        icon = <Icon name="circle" color="green"/>
        log = this.state["statecheck"+ check._id].log
      }
      else if(this.state["statecheck"+ check._id])
      {
        log = this.state["statecheck"+ check._id].log
      }

      return (
        <>
        <Popup trigger={icon}>
        {log}
        </Popup>
        </>
      )
      
    }

    feedEntry = (comment, branch) =>
    {
        let elapsedM = (Date.now() - new Date(comment.date).getTime()) / 1000 / 60
        let elapsedH = elapsedM / 60
        let elapsedD = elapsedH / 24

        if(comment.text !== undefined)
        return(
            <Feed.Event key={comment.id}>
            <Feed.Label>
                <Icon name="comment"/>
            </Feed.Label>
            <Feed.Content>
                <Feed.User>{comment.user.username + " commented " + (elapsedD > 1 ? Math.round(elapsedD) + " days ago" : elapsedH > 1 ? Math.round(elapsedH) + " hours ago" : Math.round(elapsedM) + " minutes ago")}</Feed.User>
                <Feed.Summary>
                    {!(this.state.edit === comment.id) ?
                            <ReactMarkdown remarkPlugins={[gfm]}children={" " + comment.text}/> 
                        : <>
                            <ReactMde
                                getIcon={(commandName) => <Icon name={commandName} />}
                                value={this.state.editComment}
                                onChange={(data) => this.setState({editComment: data})}
                                selectedTab={this.state.selectedTabNewComment}
                                onTabChange={(data) => this.setState({selectedTabNewComment: data})}
                                generateMarkdownPreview={(markdown) => Promise.resolve(<ReactMarkdown remarkPlugins={[gfm]} children={markdown} />) }
                                loadSuggestions={this.loadSuggestions}
                                childProps={{ writeButton: { tabIndex: -1 } }}
                            />
                            <Button onClick={() => {this.editComment(branch, comment); this.setState({edit: undefined, editComment: undefined})}} style={{float: "right", marginTop: "10px"}} color="green">Confirm</Button>
                            <Button onClick={() => {this.setState({edit: undefined, editComment: undefined})}} style={{float: "right", marginTop: "10px"}} color="red">Abort</Button>
                            <Button onClick={() => {this.deleteComment(branch, comment); this.setState({edit: undefined, editComment: undefined})}} style={{float: "left", marginTop: "10px"}} color="red">Delete</Button>
                        </>}
                        { comment.user.id === (hasFetched(this.props.activeUser) ? this.props.activeUser.data.id : false) && !(this.state.edit === comment.id) && <Button onClick={() => this.setState({editComment: comment.text,edit: comment.id})} style={{float: "right",}} size="tiny" color="green">Edit</Button>}
                </Feed.Summary>
            </Feed.Content>
            </Feed.Event>
        )
        else
        {
            return <Feed.Event key={comment.id}>
                <Feed.Label>
                    <Icon name="add"/>
                </Feed.Label>
                <Feed.Content>
                    <Feed.User>{comment.modifiedBy.username + " commited " + (elapsedD > 1 ? Math.round(elapsedD) + " days ago" : elapsedH > 1 ? Math.round(elapsedH) + " hours ago" : Math.round(elapsedM) + " minutes ago")}</Feed.User>
                    <Feed.Summary>
                            <Header style={{marginTop: 0}}>
                              {comment.message}
                            </Header> 
                            ( {comment.count} changes)
                    </Feed.Summary>
                </Feed.Content>
            </Feed.Event>
        }
    }


    loadSuggestions =(text) =>
    {      
        return new Promise((accept, reject) => {
          setTimeout(() => 
          {
            let suggestions = this.props.collaborators.data.map(user => {
              return {
                preview: user.name,
                value: `@${user.username}`
              }});

              suggestions.filter((i) => i.preview.toLowerCase().includes(text.toLowerCase()));
              accept(suggestions);
          }, 250);
        });
      }

      postComment = async (branch) =>
      {
        await lrServerConnection.commentOnBranch({"comment": this.state.newComment, "branch": branch.id})
        FetchProject(true)
      }
      
      editComment = async (branch, comment) =>
      {
        await lrServerConnection.editCommentOnBranch({"comment": this.state.editComment, "commentId": comment.id, "branch": branch.id})
        FetchProject(true)
      }

      deleteComment = async (branch, comment) =>
      {
        await lrServerConnection.deleteCommentOnBranch({"commentId": comment.id, "branch": branch.id})
        FetchProject(true)
      }

      editDescription = async (branch) =>
      {
        await lrServerConnection.editFullDescriptionOnBranch({"fullDescription": this.state.editDescription, "branch": branch.id})
        FetchProject(true)
      }

      editPullRequestName = async (branch) =>
      {
        await lrServerConnection.editPullRequestName({"description": this.state.editPullName, "branch": branch.id})
        FetchProject(true)
      }
}

//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      activeProject: state[ACTIVE_PROJECT],
      activeUser: state[ACTIVE_USER],
      activeChange: state[ACTIVE_CHANGE],
      collaborators: state[COLLABORATORS],
      checks: state[ACTIVE_CHECK_PROJECT],
      review: state[REVIEW],
    };
}

export default connect(mapStateToProps)(withRouter(ChangeRequest));