//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component, createRef } from 'react';
import { Form, Modal, Segment, Header } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/ColorPicker"

import { cie2RGB, XYZ2RGB, isInside, RGB2cie, map2spec, colorCapRgb, cie2hex} from "./utilities"
import { ManufacturerField, GelsField } from './GelSelector';
import LRModal from '../Basics/BasicModal';

let size = 400;
let sizeRed = 10;
let small = Math.round(size/sizeRed);
let mousedown = false;
const maxX = 0.75;
const maxY = 0.85;
const pl = [
  [0.17,0.01],
  [0.73,0.26],
  [0.40,0.60],
  [0.3 ,0.69],
  [0.15,0.81],
  [0.10,0.83],
  [0.08,0.83],
  [0.06,0.83],
  [0.04,0.82],
  [0.03,0.80],
  [0.02,0.78],
  [0.01,0.75],
  [0.00,0.65],
  [0.01,0.50],
  [0.03,0.35],
  [0.04,0.30],
  [0.09,0.13],
  [0.10,0.10],
  [0.13,0.05]]


  const black = {fx: 0, fy: 0, f_Y: 0}      
  const white = {fx: 0.31273, fy: 0.32902, f_Y: 100}  

  const grey = {fx: 0.31273, fy: 0.32902, f_Y: 50}        
  const brown = {fx: 0.5400, fy: 0.4095, f_Y: 12.96}        
  const green = {fx: 0.3020, fy: 0.5566, f_Y: 23.41}        
  const quickSelectColors = [black, white,grey,
    {f_Y: 21.26, fx: 0.6400744994567747, fy: 0.3299705106316933},
    {f_Y: 36.56822236728927, fx: 0.5436079351733278, fy: 0.4065678948406938},
    {f_Y: 92.78, fx: 0.41932146163480916, fy: 0.5052551326036051},
    {f_Y: 76.07051464665226, fx: 0.33526454453595517, fy: 0.5719988797482294},
    {f_Y: 71.52, fx: 0.3, fy: 0.6},
    {f_Y: 73.06537703428171, fx: 0.27335291465311157, fy: 0.5040610423829083},
    {f_Y: 78.74, fx: 0.2246576486305945, fy: 0.3287408149632598},
    {f_Y: 22.52822236728927, fx: 0.1762563144748333, fy: 0.15447884650664245},
    {f_Y: 7.22, fx: 0.15001662234042554, fy: 0.060006648936170214},
    {f_Y: 11.770514646652265, fx: 0.20040953115031204, fy: 0.0877671745794656},
    {f_Y: 28.48, fx: 0.32092016238159676, fy: 0.15415426251691475},
    {f_Y: 22.80537703428172, fx: 0.5001300413422978, fy: 0.25287768527445104},
    brown,
    green,
  ]

class ColorPicker extends Component
{
  constructor(props)
  {
    super(props);

    this.abcolor = createRef()
    this.brightness = createRef()

    this.state = {
      fx:       props.colorX,
      fy:       props.colorY,
      f_Y:      props.colorL,
    }



  }
  componentDidUpdate(prevProps, prevState) {
    if(prevProps !== this.props)
    {
      this.setState({
        fx:       this.props.colorX,
        fy:       this.props.colorY,
        f_Y:      this.props.colorL
      })
    }
    if(prevState !== this.state){ this.updateCanvas() }

  }
  componentDidMount() 
  {
    this.updateCanvas();
    this.abcolor.current.addEventListener('mousedown',(e)=>
    {
      let rect = this.abcolor.current.getBoundingClientRect();
      if(isInside(pl,[((e.clientX - rect.left)/size)*maxX,(1-(e.clientY - rect.top )/size)*maxY])){
        this.setState({
          ...this.state,
          fx:       ((e.clientX - rect.left)/size)*maxX,
          fy:       (1-(e.clientY - rect.top )/size)*maxY,
        })
        this.updateCanvas();
      }else{
        let c = map2spec({
          fx: ((e.clientX - rect.left)/size)*maxX,
          fy: (1-(e.clientY - rect.top )/size)*maxY
        },pl)
        this.setState({
          ...this.state,
          fx:       c.fx,
          fy:       c.fy,
        })
        this.updateCanvas();
      }
      mousedown = true;
    })
    this.brightness.current.addEventListener('mousedown',(e)=>
    {
      let rect = this.brightness.current.getBoundingClientRect();
      this.setState({
        ...this.state,
        f_Y: ((e.clientX - rect.left)*100)/size
      })
      mousedown = true;
      this.updateCanvas();
    })
    this.abcolor.current.addEventListener('mousemove',(e)=>
    {
      if(mousedown){
        let rect = this.abcolor.current.getBoundingClientRect();
        if(isInside(pl,[((e.clientX - rect.left)/size)*maxX,(1-(e.clientY - rect.top )/size)*maxY]))
        {
          this.setState({
            ...this.state,
            fx:       ((e.clientX - rect.left)/size)*maxX,
            fy:       (1-(e.clientY - rect.top )/size)*maxY,
          })
          this.updateCanvas();
        }
        else
        {
          let c = map2spec({
            fx: ((e.clientX - rect.left)/size)*maxX,
            fy: (1-(e.clientY - rect.top )/size)*maxY
          },pl)
          this.setState({
            ...this.state,
            fx:       c.fx,
            fy:       c.fy,
          })
          this.updateCanvas();
        }
      }
    })
    this.brightness.current.addEventListener('mousemove',(e)=>
    {
      if(mousedown){
        let rect = this.brightness.current.getBoundingClientRect();
        this.setState({
          ...this.state,
          f_Y: ((e.clientX - rect.left)*100)/size
        })
        this.updateCanvas();
      }
    })
    this.abcolor.current.addEventListener('mouseup',(e)=>
    {
      mousedown = false;
      this.props.onChange(this.state)
    })
    this.brightness.current.addEventListener('mouseup',(e)=>
    {
      mousedown = false;
      this.props.onChange(this.state)
    })
  }
  updateCanvas() {
    const ctx = this.abcolor.current.getContext('2d');
    ctx.clearRect(0,0,size,size);
    let imgData=ctx.createImageData(size,size);
    for (let i = 0;i<size; i++) {
      for (let j=0;j<size; j++)
      {
        let cie = {fy: (1-i/size)*maxY, fx: (j/size+0.001)*maxX, f_Y: this.state.f_Y}
        //let cie = {fy: 1-i/size, fx: j/size+0.001, f_Y: (1-(1-i/size)-(j/size+0.001))*100}
        let normFac = 0.9/Math.max(cie.fx,cie.fy,1-cie.fx-cie.fy);
        let rgb = XYZ2RGB([(cie.fx)*normFac,(cie.fy)*normFac,(1-cie.fx-cie.fy)*normFac]);
        if(!isInside(pl,[cie.fx,cie.fy])) 
        {
          // If you don't match RGB
          imgData.data[i*size*4+4*j]= 0;
          imgData.data[i*size*4+4*j+1]= 0;
          imgData.data[i*size*4+4*j+2]= 0;
          imgData.data[i*size*4+4*j+3]= 0;
        } 
        else 
        {
          // If you match RGB
          imgData.data[i*size*4+4*j]= rgb[0]//*normFac;
          imgData.data[i*size*4+4*j+1]= rgb[1]//*normFac;
          imgData.data[i*size*4+4*j+2]= rgb[2]//*normFac;
          imgData.data[i*size*4+4*j+3]=255;
        }
      }
    }
    ctx.putImageData(imgData,0,0);
    ctx.beginPath();
    ctx.arc(size*this.state.fx/maxX,size*(1*maxY-this.state.fy)/maxY,size/100,0,2*Math.PI);
    ctx.stroke();

    let r = RGB2cie([255,0,0])
    let g = RGB2cie([0,255,0])
    let b = RGB2cie([0,0,255])

    ctx.font = "15px Verdana"

    ctx.beginPath();
    ctx.arc(size*r.fx/maxX, size*(1*maxY-r.fy)/maxY, size/200,0,2*Math.PI)
    ctx.fillStyle="black"
    ctx.fill()
    ctx.fillText("R", size*r.fx/maxX + size/150, size*(1*maxY-r.fy)/maxY)

    ctx.beginPath();
    ctx.arc(size*g.fx/maxX, size*(1*maxY-g.fy)/maxY, size/200,0,2*Math.PI)
    ctx.fillStyle="black"
    ctx.fill()
    ctx.fillText("G", size*g.fx/maxX + size/150, size*(1*maxY-g.fy)/maxY)

    ctx.beginPath();
    ctx.arc(size*b.fx/maxX, size*(1*maxY-b.fy)/maxY, size/200,0,2*Math.PI)
    ctx.fillStyle="black"
    ctx.fill()
    ctx.fillText("B", size*b.fx/maxX + size/150, size*(1*maxY-b.fy)/maxY)

    const ctx2 = this.brightness.current.getContext('2d');
    ctx2.clearRect(0,0,size,small);
    let imgData2=ctx2.createImageData(size,small);
    for (let i2 = 0; i2<size; i2++) {
      let cie2 = {fy: this.state.fy, fx: this.state.fx, f_Y: (i2/size)*100}
      let rgb2 = cie2RGB(cie2);
      for (let j2 = 0; j2<small; j2++){
        imgData2.data[j2*size*4+4*i2]=  rgb2[0];
        imgData2.data[j2*size*4+4*i2+1]= rgb2[1];
        imgData2.data[j2*size*4+4*i2+2]= rgb2[2];
        imgData2.data[j2*size*4+4*i2+3]=255;
      }
    }
    ctx2.putImageData(imgData2,0,0)
    ctx2.beginPath();
    ctx2.rect(size*(this.state.f_Y/100)-size/200,-1,size/100,small+2);
    ctx2.stroke();
    
  }

  render(){
    return(
      <div>
        <canvas ref={this.abcolor} width= {size} height={size}></canvas>
        <br/>
        <canvas ref={this.brightness} width= {size} height={small}></canvas>
      </div>
    );
  }
}


//-----------------------------------------------------------------------------
// The component for the TreeControl
class EditColorModalControl extends Component 
{
    constructor(props)
    {
        super(props);



        this.state = { 
          open : false,
          fx : this.props.colorX,
          fy : this.props.colorY,
          f_Y: this.props.colorL ,
          readProps: false,
          selectedManufacturer: 0,
          selectedGel: 0,

        }
    }

    componentDidUpdate() 
    {
      // This forces to read the props before open
      if(this.state.readProps === false && this.state.open)
      {
        this.setState({
          readProps: true,
          fx : this.props.colorX,
          fy : this.props.colorY,
          f_Y: this.props.colorL ,
        })
      }
      
    }

    colorChange = (cie) => {
      this.setState({
        ...cie,
        open: this.state.open,
        selectedGelName:"",
        selectedManufacturerName: "",
      })
    }

    colorChangeX = (e) => {
      let cie = {
        fx:   Number(e.target.value),
        fy:   this.state.fy,
        f_Y:  this.state.f_Y
      }
      this.colorCapCie(cie)
      this.setState(
        {
          ...this.state,
          ...cie,
          selectedGelName:"",
        selectedManufacturerName: "",
        }
      )
    }

    colorChangeY = (e) => {
      let cie = {
        fx:   this.state.fx,
        fy:   Number(e.target.value),
        f_Y:  this.state.f_Y
      }
      this.colorCapCie(cie)
      this.setState(
        {
          ...this.state,
          ...cie,
          selectedGelName:"",
        selectedManufacturerName: "",
        }
      )
    }

    colorChangeL = (e) => {
      let cie = {
        fx:   this.state.fx,
        fy:   this.state.fy,
        f_Y:  Number(e.target.value)
      }
      this.colorCapCie(cie)
      this.setState(
        {
          ...this.state,
          ...cie,
          selectedGelName:"",
        selectedManufacturerName: "",
        }
      )
    }

    colorCapCie = cie => 
    {
      if      (cie.fx < 0)  { cie.fx = 0; }
      else if (cie.fx > 1)  { cie.fx = 1; }

      if      (cie.fy < 0)  { cie.fy = 0; }
      else if (cie.fy > 1)  { cie.fy = 1; }
      
      if      (cie.f_Y < 0)  { cie.f_Y = 0; }
      else if (cie.f_Y > 100){cie.f_Y = 100;}
    }

    colorChangeR = (e) => {
      let cie = { fx : this.state.fx, fy : this.state.fy, f_Y : this.state.f_Y};
      let rgb = cie2RGB(cie);
      rgb[0] = parseInt(e.target.value === "" ? "0": e.target.value,10)
      colorCapRgb(rgb);
      cie = RGB2cie(rgb);
      this.setState({
        ...this.state,
        f_Y: cie.f_Y,
        fy: cie.fy,
        fx: cie.fx,
        selectedGelName:"",
        selectedManufacturerName: "",
      })
    }

    colorChangeG = (e) => {
      let cie = { fx : this.state.fx, fy : this.state.fy, f_Y : this.state.f_Y};
      let rgb = cie2RGB(cie);
      rgb[1] = parseInt(e.target.value === "" ? "0": e.target.value,10)
      colorCapRgb(rgb);
      cie = RGB2cie(rgb);
      this.setState({
        ...this.state,
        f_Y: cie.f_Y,
        fy: cie.fy,
        fx: cie.fx,selectedGelName:"",
        selectedManufacturerName: "",
      })
    }

    colorChangeB = (e) => {
      let cie = { fx : this.state.fx, fy : this.state.fy, f_Y : this.state.f_Y};
      let rgb = cie2RGB(cie);
      rgb[2] = parseInt(e.target.value === "" ? "0": e.target.value,10)
      colorCapRgb(rgb);
      cie = RGB2cie(rgb);
      this.setState({
        ...this.state,
        f_Y: cie.f_Y,
        fy: cie.fy,
        fx: cie.fx,
        selectedGelName:"",
        selectedManufacturerName: "",
      })
    }

    onOkButton = (e) => 
    {
        this.props.onColorChanged({
          fx: this.state.fx,
          fy: this.state.fy,
          f_Y:this.state.f_Y,
          gelManufacturer: this.state.selectedManufacturerName,
          gelName: this.state.selectedGelName,
        });
        this.close(e)
    }

    close = (e) => 
    {
      this.setState({
        ...this.state,
        open : false,
        readProps: false});
      if (this.props.onClose) {
        this.props.onClose(e);
      }
    }

    precise = (x) =>{
      return Number.parseFloat(x).toPrecision(4);
    }

    colorChangeFromCie = (cie) => {
      this.setState({
        fx: cie.fx,
        fy: cie.fy,
        f_Y: cie.f_Y,
      })
    }
    
  render() 
  {
    let open = this.state.open;
    if (this.props.open)
    {
      open = this.props.open;
    }

    let cie  = { fx : this.state.fx, fy : this.state.fy, f_Y : this.state.f_Y};
    let rgb  = cie2RGB( cie );

    let header = this.props.title && this.props.title.length > 0 ? LocalizedStrings.pickColor + " for " + this.props.title : LocalizedStrings.pickColor;
 



    return (
      <React.Fragment>
        <LRModal
          open={open}
          title={header}
          onCancelClick={this.close}
          onOkClick={this.onOkButton}
          contentStyle={{ display: "flex" }}
        >
          <div>
            <Segment>
              <ColorPicker
                colorX={cie.fx}
                colorY={cie.fy}
                colorL={cie.f_Y}
                onChange={(c) => {
                  this.colorChange(c);
                }}
              />
            </Segment>
            <Segment>
              <Header as={"h3"}>{"Quickselect"}</Header>
              {quickSelectColors.map((cieCol, i) => {
                const hexCol = cie2hex(cieCol);
                return (
                  <span key={i}
                    style={{
                      display: "inline-block",
                      width: "5mm",
                      height: "5mm",
                      background: hexCol,
                      border: "1px solid rgba(200, 200, 200, 200)",
                      margin: "1mm",
                    }}
                    onClick={() => {
                      this.colorChangeFromCie(cieCol);
                    }}
                  ></span>
                );
              })}
            </Segment>
          </div>
          <div style={{ width: "2em" }}></div>
          <Modal.Description>
            <Form>
              <Form.Input
                label={LocalizedStrings.ColX}
                placeholder="0"
                name="col_x"
                value={this.precise(cie.fx)}
                onChange={(e) => {
                  this.colorChangeX(e);
                }}
              />
              <Form.Input
                label={LocalizedStrings.ColY}
                placeholder="0"
                name="col_y"
                value={this.precise(cie.fy)}
                onChange={(e) => {
                  this.colorChangeY(e);
                }}
              />
              <Form.Input
                label={LocalizedStrings.ColL}
                placeholder="0"
                name="col_L"
                value={this.precise(cie.f_Y)}
                onChange={(e) => {
                  this.colorChangeL(e);
                }}
              />
              <Form.Input
                label={LocalizedStrings.ColR}
                placeholder="0"
                value={Math.round(rgb[0])}
                onChange={(e) => {
                  this.colorChangeR(e);
                }}
              />
              <Form.Input
                label={LocalizedStrings.ColG}
                placeholder="0"
                value={Math.round(rgb[1])}
                onChange={(e) => {
                  this.colorChangeG(e);
                }}
              />
              <Form.Input
                label={LocalizedStrings.ColB}
                placeholder="0"
                value={Math.round(rgb[2])}
                onChange={(e) => {
                  this.colorChangeB(e);
                }}
              />
              <Form.Group>
                <ManufacturerField
                  Manufacturer={this.state.selectedManufacturerName}
                  onManufacturerChange={(arg) => {
                    this.setState({
                      selectedGelName: arg.selectedGelName,
                      selectedManufacturerName: arg.selectedManufacturerName,
                    });
                  }}
                />
                <GelsField
                  Manufacturer={this.state.selectedManufacturerName}
                  SelectedGel={this.state.selectedGelName}
                  onGelChange={(arg) => {
                    this.setState({
                      f_Y: arg.f_Y,
                      fy: arg.fy,
                      fx: arg.fx,
                      selectedGelName: arg.selectedGelName,
                      selectedManufacturerName: arg.selectedManufacturerName,
                    });
                  }}
                />
              </Form.Group>
            </Form>
          </Modal.Description>
        </LRModal>
      </React.Fragment>
    );
  }
}

export default EditColorModalControl