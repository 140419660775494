//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Form } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/ConnectToRemote";
import LRModal from '../Basics/BasicModal';
import { globalCallbacks } from "../../util/callback";


//-----------------------------------------------------------------------------
// The component for the TreeControl
class ConnectToRemote extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
      ip: "",
      port: "",
    }
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }

  show = () => 
  {
    this.setState( { open : true});
  }

  onOkButton = () => 
  {
    let request = 
    {
      IP: this.state.ip,
      Port: this.state.port
    }
    window.LR_ConnectToRemote(request)
    this.close()
  }

  close = () => 
  {
    this.setState({open : false});
  }
    
  render() 
  {
    let open = this.state.open;
    if(!open) {return <div/>}
    return (
      <LRModal  open={open}
                title={LocalizedStrings.Header}
                closeOnDimmerClick={false}
                onCancelClick={this.close}
                onOkClick={this.onOkButton}>
          <Form>
            <Form.Input label={LocalizedStrings.IP} name="ip" value={this.state.ip}  onChange={(e, {value})=>{this.setState({ip: value})}} />
            <Form.Input label={LocalizedStrings.Port} name="port" value={this.state.port}  onChange={(e, {value})=>{this.setState({port: value})}} />
          </Form>
      </LRModal>
    )
  }

  setUpCallbacks()
  {

    globalCallbacks.ShowConnectToRemmote = () => 
    { 
      this.show();
    }
    
  }
}

export default ConnectToRemote