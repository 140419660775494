
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import {  Table, Message } from 'semantic-ui-react'
import DrawingErrorNode from './DrawingErrorNode';
import LocalizedStrings from "../../localization/NavigationContainer";

import { globalCallbacks as mainCB } from '../../util/callback';
import { globalCallbacks as mockCB } from '../../util/mock_callback';
import CollapsableDevider from '../Basics/CollapsableDevider';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB

class DrawingErrorTable extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = 
        {
        };

    }

    componentDidMount = async () => 
    {
        this.setUpCallbacks();

        let errors = await window.LR_GetDrawingErrors()
        this.setState({...errors})
    }

    render() 
    {
        let hasNoError = Object.entries(this.state).every(errArray => errArray[1].length === 0)
        return (
            <>
                {   hasNoError 
                    ? 
                    <Message positive>{LocalizedStrings.NoError}</Message> 
                    : 
                    <div style={{width:"100%", maxHeight:"calc(100vh - 20em)", overflowY:"auto"}}>
                        <Table striped structured compact='very' size="small">
                            <Table.Header>
                                <Table.Row>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {Object.entries(this.state).map((entry, i) => { return this.renderRows(entry[0], i)})}
                            </Table.Body>
                        </Table>
                    </div> 
                }  
            </>
        );
    }

    renderRows = (name, j) => 
    {
        let errorHeader = name.split("_").join(" ")
        if(name === "OUT_USED_TWO_TIMES")
        {
            errorHeader = "OUTPUT USED TWO TIMES"
        }

        let countErrors = this.state[name]?.length

        return(
            <CollapsableDevider countErrors={countErrors} header={errorHeader} navigationTable>
                {this.state[name]?.map((entry, i) => {return <DrawingErrorNode key={i} entry={entry} i={i} j={j}/>})}
            </CollapsableDevider>
        )
    }

    setUpCallbacks() 
    {
        globalCallbacks.UpdateErrorDisplayNavigation = (arg) =>
        {

            this.setState({[arg.Key]: arg.Payload})
        }

    }
}

export default DrawingErrorTable;

