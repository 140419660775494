//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------

import React, { Component } from "react";
import { Form, Button, Message, List } from "semantic-ui-react";
import { Link } from "react-router-dom";
import logo from "../../content/img/applogo_white.png";
import LocalizedStrings from "../../localization/SignIn";
import ForgotPage from "../ResetPage/ForgotPage";
import CreateUser from "../CreateUser/CreateUser";
import { globalCallbacks } from "../../util/callback";
import NewNavBar from "../ProductionAssistLandingPage/NewNavBar";
import "./signin.css"
import NewFooter from "../WebComponents/NewFooter";

//---------------------------------------------------------------------
// LightRightRouter
class NewSignIn extends Component 
{
  constructor(props) {
    super(props);

    this.state = {
      failed: false,
      userNameCheckFailed: false,
      emptyUserName: false,
      emptyPassword: false,
      user: "",
      password: "",
      text: "",
      loggedIn: false,
      inProgress: false,
      StorePassword: false,
    };

    globalCallbacks.logoutFromServer = () => this.onLogOut();


    globalCallbacks.validateLogin = async () => 
    {
      if(!this.state.loggedIn)
      {
        let res  = await window.LR_ValidateToken();
        let res2 = await window.LR_GetBaseURL()
    
        this.setState({URL: res2.URL, loggedIn: res.Valid && res.User !== "", KeyTarEnabled: res2.KeyTarEnabled})

        if(!res.Valid)
        {
          let ret = await window.LR_TryToGetUserAccountAsync()

          if(ret.length > 0)
          {
            this.setState({user: ret[0].Key,password: ret[0].Password })
          } 
        }
    }
    }
    globalCallbacks.validateLogin()
  }

  render() {
    if (this.state.loggedIn) {
      return this.props.children ? this.props.children : null;
    }
    let url = new URL(window.location.href);
    let tab = url.searchParams.get("tab");

    if (tab === "create") return <CreateUser onLogin={this.afterRegister} />;
    if (tab === "forgot") return <ForgotPage />;

    let reroute = url.searchParams.get("reroute");

    let text = this.state.URL ==="https://www.production-assist.com" ? LocalizedStrings.LoginToYourAccount : LocalizedStrings.LoginToYourDevelopmentAccount
    return (
      <div className="newlogin signin">
        { this.props.webApp ? <NewNavBar /> : null}
        <div className="loginform">
          <img src={logo} alt="production assistant logo" />
          <h1>{text}</h1>
          <Form className="inputcontainer">
              <Form.Input 
                className="textin"
                data-login="TRUE"
                name="user"
                value={this.state.user}
                placeholder="Username"
                onChange={this.onChange}
              />
              <Form.Input
                className="textin"
                data-login="TRUE"
                name="password"
                type="password"
                value={this.state.password}
                placeholder="Password"
                onChange={this.onChange}
              />
              {
                this.state.KeyTarEnabled ? 
                                <Form.Checkbox
                                label={LocalizedStrings.StorePassword}
                                checked={this.state.StorePassword}
                                onChange={(e, { checked }) => {
                                  this.setState({ StorePassword: checked });
                                }}/> : null
              }

              <div className="button-container">
                <Button
                  as={reroute && this.props.webApp ? Link : undefined}
                  to={reroute && this.props.webApp ? reroute : undefined}
                  loading={this.state.inProgress}
                  onClick={this.onLogin}
                  type="submit"
                > {LocalizedStrings.Login}
                </Button>
              </div>
         

          {this.props.webApp ? (
            <React.Fragment>
              <List>
                <List.Item as={Link} to={`/register`}>
                  <span>{LocalizedStrings.NewToPA}</span> <br className="b-mobile" />
                  {LocalizedStrings.CreateAccount}
                </List.Item>
                <List.Item as={Link} to={`/forgot`}>
                  {LocalizedStrings.ForgotPassword}
                </List.Item>
              </List>
            </React.Fragment>
          ) : (this.props.iosApp ? (
            <>
              <div>
                <a href={this.state.URL + "/register"} target="_blank" rel="noopener noreferrer">{LocalizedStrings.RegisterUser}</a>
              </div>
              <div>
                <a href={this.state.URL + "/forgot"} target="_blank" rel="noopener noreferrer">{LocalizedStrings.ForgotPassword}</a>
              </div>
            </>
          ) : (
            <React.Fragment>
              <div style={{ margin: "0 12px" }}>
                <span style={{color:"#20256b"}}>{LocalizedStrings.NewToPA}</span> <br className="b-mobile" />
                <span
                  style={{
                    cursor: "pointer",
                    color: "#1e6fff"
                  }}
                  onClick={() => {
                    const linkTo = this.state.URL + "/register";
                    window.LR_OpenLink({URL: linkTo});
                  }}
                >
                  {LocalizedStrings.CreateAccount}
                </span>      
              </div>
              <div style={{ margin: "0 12px" }}>
                <span
                  style={{
                    cursor: "pointer",
                    color: "#1e6fff"
                  }}
                  onClick={() => {
                    const linkTo = this.state.URL + "/forgot";
                    window.LR_OpenLink({URL: linkTo});
                  }}
                >
                  {LocalizedStrings.ForgotPassword}
                </span>
              </div>
                { window.ElectronWebsocketMode ? <Button onClick={()=>window.LR_E_SetAppMode({ElectronWebsocketMode:false})}>{LocalizedStrings.BackToAppMode}</Button> : null}
            </React.Fragment>
          ))}
          {this.state.failed ? (
            <Message negative>
              <Message.Header>{LocalizedStrings.InvalidLogin}</Message.Header>
              {this.state.text}
            </Message>
          ) : null}
          {this.state.userNameCheckFailed ? (
            <Message negative>
              <Message.Header>
                {LocalizedStrings.UserNameNotKnown}
              </Message.Header>
              {LocalizedStrings.UserNameNotKnownTest}
            </Message>
          ) : null}
          {this.state.emptyUserName ? (
            <Message negative>
              <Message.Header>
                {LocalizedStrings.PleaseProvideUserName}
              </Message.Header>
              {LocalizedStrings.PleaseProvideUserTest}
            </Message>
          ) : null}
          {this.state.emptyPassword ? (
            <Message negative>
              {LocalizedStrings.PleaseProvidePassword}
            </Message>
          ) : null}
           </Form>
        </div>
        { this.props.webApp ? <NewFooter/> : null}
      </div>
    );
  }

  componentDidMount = () => {};

  onChange = (e, { name, value }) => {
    this.setState({ [name]: value, failed: false, userNameCheckFailed: false, emptyUserName:false, emptyPassword:false });
  };

  afterRegister = async () => {
    this.setState({ loggedIn: true });
  };

  onLogin = async () => {

    if(this.state.user === "")
    {
      this.setState({ emptyUserName: true });
      return
    }
    if(this.state.password === "")
    {
      this.setState({ emptyPassword: true });
      return
    }

    this.setState({ inProgress: true });

    let check = await window.LR_ValidateUserNameAsync({
      User: this.state.user.toLowerCase(),
    });

    if (check.OK) 
    {
      let request = {
        User: this.state.user.toLowerCase(),
        Password: this.state.password,
        StorePassword: this.state.StorePassword
      };

      let loginResult = await window.LR_LoginToServerAsync(request);

      if (loginResult?.token) {
        this.setState({
          failed: false,
          text: "",
          loggedIn: true,
          user: "",
          password: "",
          inProgress: false,
        });
      } else {
        this.setState({
          failed: true,
          text: "",
          loggedIn: false,
          password: "",
          inProgress: false,
        });
      }
    } 
    else 
    {
      this.setState({ userNameCheckFailed: true, inProgress: false });
    }
  };

  onLogOut = () => {
    this.setState({ loggedIn: false });
  };
}

export default NewSignIn;