//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------

import React, { Component } from 'react';
import { Form, Input, Tab } from 'semantic-ui-react';
import LocalizedStrings from "../../localization/ArrayModifier";
import { ARRAY_MODIFY_Circle, ARRAY_MODIFY_Line, ARRAY_MODIFY_Rect, BASE_UNIT_LENGTH, BASE_UNIT_NUMBER } from '../../util/defines';
import LRModal from '../Basics/BasicModal';
import UnitInput from '../Basics/BasicUnitInput';


import { globalCallbacks } from "../../util/callback";

class ArrayModifierModal extends Component
{
    constructor(props) {
        super(props)
    
        this.state = {
             open: false,
             type: LocalizedStrings.TypeLine,

             OffsetX : 0,
             OffsetY : 0,
             OffsetZ : 0,
        }

        this.child = React.createRef();

        this.panes = 
        [
            {
                menuItem: LocalizedStrings.TypeLine, render: () =>
                <Tab.Pane key='1'>
                    <ModifyLineComponent ref = {this.child} create = {this.state.create}/>
                </Tab.Pane>
            },
            {
                menuItem: LocalizedStrings.TypeRect, render: () =>
                <Tab.Pane key='2'>
                    <ModifyRectComponent ref = {this.child} create = {this.state.create}/>
                </Tab.Pane>
            },
            {
                menuItem: LocalizedStrings.TypeCircle, render: () =>
                <Tab.Pane key='3'>
                    <ModifyCircleComponent ref = {this.child} create = {this.state.create}/>
                </Tab.Pane>
            }
        ]
    }

    componentDidMount = () => 
    {
        this.setUpCallbacks();
    }

    show = (args) => {
        this.setState({open: true, create: args.Create});
    }

    onOkClick = () => {
        this.child.current.OnOkClick();

        this.close();
    }
    
    
    close = () => {
        this.setState({open: false})
    }
    
    render()
    {
        let {open} = this.state;
        if (!open) { return <div/>}

        return (
            <LRModal    open={open}
                        title={LocalizedStrings.Header}
                        onCancelClick={this.close}
                        onOkClick={this.onOkClick}>
            <Tab menu={{ secondary: true, pointing: true }} panes={this.panes}/>
            </LRModal>
        )
    }

    setUpCallbacks()
    {
        globalCallbacks.ShowArrayModifierDialog = (args) =>
        {
            this.show(args);
        }
    }
    
    
}


class ModifyLineComponent extends Component
{
    constructor(props)
    {
        super(props)
        
        this.state = 
        {
            Count: 1,

            OffsetX: 0,
            OffsetY: 0,
            OffsetZ: 0,
        }

    }

    OnOkClick = async () => {

        let args = 
        {
            Type: ARRAY_MODIFY_Line,
            Props: 
            {
                Count: this.state.Count,
                OffsetX: Number(this.state.OffsetX) ,
                OffsetY: Number(this.state.OffsetY),
                OffsetZ: Number(this.state.OffsetZ),
            }
        }
        if (this.props.create) {
            window.LR_AddObjectArray(args);
        } else {
            window.LR_SetObjectArray(args);
        }
    }
    
    render() {
        return (
                <Form>
                    {this.props.create ? <UnitInput name="Count" label={LocalizedStrings.Count} baseUnit={BASE_UNIT_NUMBER} value={this.state.Count} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>: null}
                    <Form.Group widths="equal">
                        <UnitInput name="OffsetX" label={LocalizedStrings.OffsetX} baseUnit={BASE_UNIT_LENGTH} value={this.state.OffsetX} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>
                        <UnitInput name="OffsetY" label={LocalizedStrings.OffsetY} baseUnit={BASE_UNIT_LENGTH} value={this.state.OffsetY} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>
                        <UnitInput name="OffsetZ" label={LocalizedStrings.OffsetZ} baseUnit={BASE_UNIT_LENGTH} value={this.state.OffsetZ} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>
                    </Form.Group>
                </Form>

        );
    }

}

class ModifyRectComponent extends Component
{
    constructor(props)
    {
        super(props);

        this.state = 
        {
            CountX  : 1,
            CountY  : 1, 
            CountZ  : 1,

            OffsetX : 0,
            OffsetY : 0,
            OffsetZ : 0,
        }
    }

    OnOkClick = async () =>
    {
        let args = 
        {

            Type: ARRAY_MODIFY_Rect,
            Props:
            {
                CountX: this.state.CountX,
                CountY: this.state.CountY,
                CountZ: this.state.CountZ,

                OffsetX: this.state.OffsetX,
                OffsetY: this.state.OffsetY,
                OffsetZ: this.state.OffsetZ,
            }
        }
        if (this.props.create) {
            window.LR_AddObjectArray(args);
        } else {
            window.LR_SetObjectArray(args);
        }
    }

    FloatEdit = (props) =>
    {
        return (
        <div>
            <h3 style={{textAlign: "center"}}>
                {props.Name}
            </h3>
            <Input style={{textAlign: "center", display: 'flex', alignItems: 'center', justifyContent: 'center'}} value={this.state[props.Name]} type='number' onChange = {(e, {value}) => {this.setState({[props.Name]: parseFloat(value)})}}>
            </Input> 
        </div>);
    }

    render()
    {
        return (
                <Form>
                <Form.Group widths="equal">
                    <UnitInput name="CountX" label={LocalizedStrings.CountX} baseUnit={BASE_UNIT_NUMBER} value={this.state.CountX} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>
                    <UnitInput name="CountY" label={LocalizedStrings.CountY} baseUnit={BASE_UNIT_NUMBER} value={this.state.CountY} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>
                    <UnitInput name="CountZ" label={LocalizedStrings.CountZ} baseUnit={BASE_UNIT_NUMBER} value={this.state.CountZ} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>
                </Form.Group>
                <Form.Group widths="equal">
                    <UnitInput name="OffsetX" label={LocalizedStrings.OffsetX} baseUnit={BASE_UNIT_LENGTH} value={this.state.OffsetX} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>
                    <UnitInput name="OffsetY" label={LocalizedStrings.OffsetY} baseUnit={BASE_UNIT_LENGTH} value={this.state.OffsetY} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>
                    <UnitInput name="OffsetZ" label={LocalizedStrings.OffsetZ} baseUnit={BASE_UNIT_LENGTH} value={this.state.OffsetZ} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>
                </Form.Group>
                </Form>
        );
    }
}

class ModifyCircleComponent extends Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            Count: 1,

            Radius: 1
        }
    }

    OnOkClick = async() =>
    {
        let args = 
        {
            Type: ARRAY_MODIFY_Circle,
            Props: 
            {
                Count: this.state.Count,

                Radius: this.state.Radius,
            }
        }
        if (this.props.create) {
            window.LR_AddObjectArray(args);
        } else {
            window.LR_SetObjectArray(args);
        }
    }

    FloatEdit = (props) =>
    {
        return (
        <div>
            <h3 style={{textAlign: "center"}}>
                {props.Name}
            </h3>
            <Input style={{textAlign: "center", display: 'flex', alignItems: 'center', justifyContent: 'center'}} value={this.state[props.Name]} type='number' onChange = {(e, {value}) => {this.setState({[props.Name]: parseFloat(value)})}}>
            </Input> 
        </div>);
    }

    render()
    {
        return (
            <Form>
                <Form.Group widths="equal">
                    <UnitInput name="Count" label={LocalizedStrings.Count} baseUnit={BASE_UNIT_NUMBER} value={this.state.Count} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>
                    <UnitInput name="Radius" label={LocalizedStrings.Radius} baseUnit={BASE_UNIT_LENGTH} value={this.state.Radius} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>
                </Form.Group>
            </Form>
        );
    }
}

export default ArrayModifierModal;