import React, { CSSProperties, useMemo, useState } from "react";
import { Accordion, Container, Form, Icon, Input } from "semantic-ui-react";
import LocalizedStrings from "../../../localization/TableViewComponent";
import { uuidStr } from "../../../util/callbackTypes";
import { CUSTOM_CELL_FUNCTION_TYPE } from "../../../util/defines";
export interface PropertyListItemInterface{
    LocalizedName: string;
    PropertyList: string;
    PropertyName: string;
    IfInArrayWithName: boolean;
    IsUnitBased: boolean;
    BaseUnit: number;
    ArrayName: string;
    LinkedPreset: uuidStr;
    CustomCellFunction: (a: string, b: PropertyListItemInterface) => void
    CustomCellFunctionType: CUSTOM_CELL_FUNCTION_TYPE
}


function RenderItem(this: unknown, props: {
    item: PropertyListItemInterface,
    style?: CSSProperties,
    selected: boolean,
    isRadio?: boolean
    showIsObjectProperty?: boolean
    disabled?: boolean
    toggleShowProp: (item: PropertyListItemInterface, checked: boolean, e: React.FormEvent<HTMLInputElement>) => void
}) {
    let Type = props.isRadio ? Form.Radio : Form.Checkbox;
    let label = <>
            {
            props.showIsObjectProperty && props.item.CustomCellFunctionType === CUSTOM_CELL_FUNCTION_TYPE.OBJECT_FIELD ? <div data-tooltip={LocalizedStrings.IsObjectProperty} data-position="top left"><Icon name="grid layout" style={{marginRight:8}}/></div> : undefined
        }
        {props.item.LocalizedName}
    </>

    return <div style={{ ...props.style, display: "flex", flexDirection: "row", alignItems: "center"}}>
        <Type
            checked={props.selected}
            style={{marginRight: 8}}
            onChange={(e, { checked }) => { props.toggleShowProp(props.item, checked, e) }} 
            disabled={props.disabled}
        />
        {label}
    </div>
}

function showPropertyListName(name: string) {

    if (name === "") { name = LocalizedStrings.GeneralInformation }
    if (name === 'User') { name = LocalizedStrings.Show_ShowUserAndTime }
    if (name === 'Electrical') { name = LocalizedStrings.Electricity }
    if (name === 'Offset') { name = LocalizedStrings.Position }
    if (name === 'Rotation') { name = LocalizedStrings.RotationOrScale }
    if (name === 'Color') { name = LocalizedStrings.LightColor }
    if (name === 'Support') { name = LocalizedStrings.Show_Support }
    if (name === 'Truck') { name = LocalizedStrings.Truck }
    if (name === 'Data') { name = LocalizedStrings.Show_Data }
    if (name === 'GlobalInformation') { name = LocalizedStrings.Show_GlobalInformation }

    return name
}


export function MultiFieldSearch(this: unknown, props: {
    propertyList: PropertyListItemInterface[],
    enabledProperties: PropertyListItemInterface[]
    toggleShowProp: (item: PropertyListItemInterface | PropertyListItemInterface[], checked: boolean, e: React.FormEvent<HTMLInputElement>) => void,
    style?: CSSProperties,
    showIsObjectProperty?: boolean
    disabled?: boolean
}) {
    const calcListNames = (propList: PropertyListItemInterface[]) => {
        return Array.from(new Set(propList.map(i => i.PropertyList)))
    }

    let [searchText, setSearchText] = useState("")
    let [activePropertyFieldIndex, setActivePropertyFieldIndex] = useState(new Set())
    let propertyListNames = useMemo(() => calcListNames(props.propertyList), [props.propertyList])

    const handleSelectedPropertyFieldClick = (e, titleProps) => {
        const { index } = titleProps

        let n = new Set(activePropertyFieldIndex)

        if (!n.delete(index)) {
            n.add(index)
        }

        setActivePropertyFieldIndex(n)
    }

    const propertyIsSelectedSet = useMemo(() => {
        let ret = new Set<PropertyListItemInterface>()
        const propertyIsSelected = (item: PropertyListItemInterface) => {
            if (!props.enabledProperties) { return false; }
            return props.enabledProperties.find(elem => (elem.ArrayName || undefined) === (item.ArrayName || undefined) && elem.PropertyName === item.PropertyName) !== undefined
        }
        for (let i of props.propertyList) {
            if (propertyIsSelected(i)) {
                ret.add(i)
            }
        }
        return ret
    }, [props.enabledProperties, props.enabledProperties.length])


    const checkIfChecked = (name: string) => props.propertyList.some(i => i.PropertyList === name && propertyIsSelectedSet.has(i))
    const checkIfUnchecked = (name: string) => props.propertyList.some(i => i.PropertyList === name && !propertyIsSelectedSet.has(i))
    const checkIfIndeterminate = (name: string) => checkIfChecked(name) && checkIfUnchecked(name)
    const doCheckOnList = (name: string, checked: boolean, ev: any) => {
        props.toggleShowProp(props.propertyList.filter(i => i.PropertyList === name), checked, ev)
    }

    let style = { overflow: 'auto', maxHeight: 130, marginTop: "1em", ...(props.style ?? {}) }

    return (
        <>
            <Input fluid value={searchText} onChange={(e, { value }) => setSearchText(value)} icon='search' placeholder={LocalizedStrings.Search} />

            <Container style={style}>
                {searchText
                    ?
                    props.propertyList.filter(item => item.LocalizedName.toUpperCase().includes(searchText.toUpperCase())).map((item) =>
                        <RenderItem
                            showIsObjectProperty={props.showIsObjectProperty}
                            key={item.ArrayName + "-" + item.PropertyName}
                            item={item}
                            selected={propertyIsSelectedSet.has(item)}
                            toggleShowProp={props.toggleShowProp}
                            disabled={props.disabled}
                        />)
                    :
                    propertyListNames.map((name, index) => {
                        return (
                            <Accordion key={index}>
                                <Accordion.Title style={{ display: "flex", flexDirection: "row", alignItems: "start", paddingBottom: 0 }} active={activePropertyFieldIndex.has(index)} index={index} onClick={(e, titleProps) => handleSelectedPropertyFieldClick(e, titleProps)}>
                                    <Icon name='dropdown' />
                                    <Form.Checkbox
                                        style={{ marginRight: "5px" }}
                                        checked={checkIfChecked(name)}
                                        indeterminate={checkIfIndeterminate(name)}
                                        onChange={(e, { checked }) => {doCheckOnList(name, checked, e); e.stopPropagation()}}
                                        disabled={props.disabled}
                                    />
                                    <div style={{color: props.disabled ? "#8b8b8b" : undefined}}>
                                        {showPropertyListName(name)}
                                    </div>
                                </Accordion.Title>
                                <Accordion.Content active={activePropertyFieldIndex.has(index)} style={{ marginBottom: "10px", paddingTop: 0 }}>
                                    {
                                        props.propertyList.map((item) => {
                                            if (item.PropertyList === name) {
                                                return <RenderItem
                                                    showIsObjectProperty={props.showIsObjectProperty}
                                                    style={{ marginLeft: "21px" }}
                                                    key={item.ArrayName + "-" + item.PropertyName}
                                                    item={item}
                                                    selected={propertyIsSelectedSet.has(item)}
                                                    toggleShowProp={props.toggleShowProp}
                                                    disabled={props.disabled}
                                                />
                                            }
                                            else { return null }
                                        })
                                    }
                                </Accordion.Content >
                            </Accordion>)
                    })
                }
            </Container>
        </>
    )

}

export function SingleFieldSearch(this: unknown, props: {
    propertyList?: PropertyListItemInterface[],
    chosenItem: PropertyListItemInterface | undefined,
    onItemChange: (item: PropertyListItemInterface) => void,
    showIsObjectProperty?: boolean,
    style?: CSSProperties,
    disabled?: boolean,
    className?: string
}) {
    let [searchText, setSearchText] = useState("")
    let [activePropertyFieldIndex, setActivePropertyFieldIndex] = useState(new Set())
    let propertyListNames = useMemo(() => Array.from(new Set(props.propertyList.map(i => i.PropertyList))), [props.propertyList])

    const handleSelectedPropertyFieldClick = (e, titleProps) => {
        const { index } = titleProps

        let n = new Set(activePropertyFieldIndex)

        if (!n.delete(index)) {
            n.add(index)
        }

        setActivePropertyFieldIndex(n)
    }

    return (
        <div className={props.className}>
            <Input fluid value={searchText} onChange={(e, { value }) => setSearchText(value)} icon='search' placeholder={LocalizedStrings.Search} disabled={props.disabled}/>
            <Container style={{ overflow: 'auto', marginTop: "1em", ...(props.style ?? {}) }}>
                {searchText
                    ?
                    props.propertyList.filter(item => item.LocalizedName.toUpperCase()
                    .includes(searchText.toUpperCase())).map((item) =>
                        <RenderItem
                            showIsObjectProperty={props.showIsObjectProperty}
                            key={item.ArrayName + "-" + item.PropertyName}
                            item={item}
                            selected={props.chosenItem === item}
                            toggleShowProp={props.onItemChange.bind(undefined, item)}
                            isRadio
                            disabled={props.disabled}
                        />)
                    :
                    propertyListNames.map((name, index) => 
                        <Accordion key={index}>
                            <Accordion.Title style={{ display: "flex", flexDirection: "row", alignItems: "start", paddingBottom: 0 }} active={activePropertyFieldIndex.has(index)} index={index} onClick={(e, titleProps) => handleSelectedPropertyFieldClick(e, titleProps)}>
                                <Icon name='dropdown' />
                                <div style={{color: props.disabled ? "#8b8b8b" : undefined}}>
                                    {showPropertyListName(name)}
                                </div>
                            </Accordion.Title>
                            <Accordion.Content active={activePropertyFieldIndex.has(index)} style={{ marginBottom: "5px", marginTop: "5px", paddingTop: 0 }}>
                                {
                                    props.propertyList.filter(i => i.PropertyList === name).map((item) => (
                                        <RenderItem
                                            showIsObjectProperty={props.showIsObjectProperty}
                                            style={{ marginLeft: "21px" }}
                                            key={item.ArrayName + "-" + item.PropertyName}
                                            item={item}
                                            selected={props.chosenItem === item}
                                            toggleShowProp={props.onItemChange.bind(undefined, item)}
                                            isRadio
                                            disabled={props.disabled}
                                        />
                                    ))
                                }
                            </Accordion.Content >
                        </Accordion>
                    )
                }
            </Container>
        </div>
    )
}