import * as THREE from "three";

import {BEAM_DISPLAY_MODE_BEAUTIFUL,
		BEAM_DISPLAY_MODE_FAST,
		BEAM_DISPLAY_MODE_OFF} from "../../../util/defines"

export const SPOT_LIGHT_RADIUS = 1000
export const SPOT_LIGHT_HEIGHT = 10000

export class VolumetricSpotLight extends THREE.Object3D{

	constructor(meshInstance, color, intensity, distance, angle, penumbra, decay)
	{
		super();
		this.spotLight = new THREE.SpotLight(color, intensity, distance, angle, penumbra, decay);
		this.initSpotLight();
		this.linkTarget();
		this.linkVolumetricMaterial();

		this.BeamMesh = meshInstance;
		
		this.OriginalColor = new THREE.Color().copy(color);
		this.CurrentColor = new THREE.Color().copy(color);

		// this.linkLense();
		this.spotLightHelper = new THREE.SpotLightHelper(this.spotLight);
		this.spotLightHelper.matrixAutoUpdate = true

		this.setColor(color);
		this.setIntensity(intensity);
		this.setDistance(distance);
		this.setAngle(angle);
		this.setPenumbra(penumbra);
		this.setDecay(decay);

		this.add(this.spotLight);
		this.add(this.spotLightHelper)

		this.ignoreRaycast = true
		this.ignoreSelectionMaterial = true
	}

	initSpotLight = () =>
	{
		this.spotLight.castShadow = true;
		this.spotLight.shadow.mapSize.width = 1024;
		this.spotLight.shadow.mapSize.height = 1024;
		this.spotLight.shadow.camera.near = 10;
		this.spotLight.shadow.camera.far = 200;
	}

	linkTarget = () =>
	{
		let target = new THREE.Object3D();
		target.position.set(0, 0, -1);
		this.spotLight.add(target);
		this.spotLight.target = target;
	}

	linkVolumetricMaterial = () =>
	{
		// add spot light
		let geometry = new THREE.CylinderGeometry(0, Math.tan(this.spotLight.angle) * this.spotLight.distance, this.spotLight.distance, 32*2, 20, true);
		geometry.applyMatrix4( new THREE.Matrix4().makeTranslation(0, -geometry.parameters.height/2, 0) );
		geometry.applyMatrix4( new THREE.Matrix4().makeRotationX(Math.PI / 2) );
		
		let VSmaterial = new VolumetricSpotLightMaterial();
		this.spotLightBeam = new THREE.Mesh(geometry, VSmaterial);
		//this.add(this.spotLightBeam)
		VSmaterial.uniforms.attenuation.value  = this.spotLight.distance;

		// Disable raycasting on the spotLightBeam
		this.spotLightBeam.raycast = function() {};
	}

	linkLense = () =>
	{
		let geometry 	= new THREE.CylinderGeometry(200, 200, 20, 32);
		let material 	= new THREE.MeshLambertMaterial({
			color: 0xaaaaaa,
			// emissive: 0xffffff,
		});
		this.lense 		= new THREE.Mesh(geometry, material);
		this.lense.rotation.x = Math.PI/2.0;
		this.lense.position.z = -2000;
		
		// this.lense.castShadow 	= true;
		// this.lense.receiveShadow = false;

		this.add(this.lense);
	}

	setGobo = (texture) =>
	{
		// this.lense.material.map = texture;
		// this.lense.material.needsUpdate = true;
		// this.lense.needsUpdate = true;

		//this.spotLightBeam.material.uniforms.goboTexture.value = texture;
		//this.spotLightBeam.material.uniforms.useGobo.value = !(texture === null);
	}

	setColor = (color) => 
	{
		this.spotLight.color.set(color);
		this.CurrentColor.copy(color);
		this.spotLightBeam.material.uniforms.lightColor.value.set(color);
	}

	lerpColors = (from, to, alpha) => 
	{
		this.spotLight.color.lerpColors(from, to, alpha);
		this.CurrentColor.lerpColors(from, to, alpha);
		this.spotLightBeam.material.uniforms.lightColor.value.lerpColors(from, to, alpha);
	}

	compColors = (toComp) =>{
		if(toComp instanceof VolumetricSpotLight)
		{
			return this.spotLight.color.equals(toComp.spotLight.color) && this.CurrentColor.equals(toComp.CurrentColor) && this.spotLightBeam.material.uniforms.lightColor.value.equals(toComp.spotLightBeam.material.uniforms.lightColor.value)
		}
		else
		{
			return this.spotLight.color.equals(toComp) && this.CurrentColor.equals(toComp) && this.spotLightBeam.material.uniforms.lightColor.value.equals(toComp)
		}
	}

	setIntensity = (intensity) =>
	{
		if (intensity < 0) {intensity = 0;}
		this.spotLight.intensity = intensity;
		this.spotLightBeam.material.uniforms.attenuation.value = intensity * this.spotLight.distance;
	}

	setDistance = (distance) =>
	{
		this.spotLight.distance = distance;
		this.spotLightBeam.geometry.height = distance;
	}

	setAngle = (angle) =>
	{
		if (angle === 0) {angle = 0.1;}
		this.spotLight.angle = angle;
		
		let halfAngle = angle / 2.0;
		let radius = Math.atan(halfAngle) * SPOT_LIGHT_HEIGHT;
		
		this.scale.set(radius / SPOT_LIGHT_RADIUS, radius / SPOT_LIGHT_RADIUS, this.scale.z);

		//this.spotLightBeam.geometry.bottomRadius = Math.tan(angle) * this.spotLight.distance;
	}

	setPenumbra = (penumbra) =>
	{
		this.spotLight.penumbra = penumbra;
	}

	setDecay = (decay) =>
	{
		this.spotLight.decay = decay;
	}

	setLight = (enabled) =>
	{
		if(enabled) { this.add(this.spotLight); }
		else 		{ this.remove(this.spotLight); }
	}

	setBeam = (beamMode) =>
	{
		
		
		if (beamMode === BEAM_DISPLAY_MODE_BEAUTIFUL)
		{
			this.add(this.spotLightBeam);

			this.MeshInstance = undefined;
			this.BeamMesh.DecreaseCount()
		}
		else if (beamMode === BEAM_DISPLAY_MODE_FAST)
		{
			this.remove(this.spotLightBeam);

			this.MeshInstance = this.BeamMesh
			this.BeamMesh.IncreaseCount()
		}
		else if (beamMode === BEAM_DISPLAY_MODE_OFF)
		{
			this.remove(this.spotLightBeam);

			this.MeshInstance = undefined;
			this.BeamMesh.DecreaseCount()
		}
	}

	setHelper = (enabled) =>
	{
		this.spotLightHelper.cone.material.visible = enabled;
	}

	updateBeamPosition = () =>
	{
		this.spotLightBeam.material.uniforms.spotPosition.value.setFromMatrixPosition(this.spotLightBeam.matrixWorld);
	}

}

let VolumetricSpotLightMaterial	= function(){
	/**
	 * from http://stemkoski.blogspot.fr/2013/07/shaders-in-threejs-glow-and-halo.html
	 * @return {[type]} [description]
	 */
	let vertexShader	= [
		'varying vec3 vNormal;',
		'varying vec3 vWorldPosition;',
		
		'void main(){',
			'// compute intensity',
			'vNormal		= normalize( normalMatrix * normal );',

			'vec4 worldPosition	= modelMatrix * vec4( position, 1.0 );',
			'vWorldPosition		= worldPosition.xyz;',

			'// set gl_Position',
			'gl_Position	= projectionMatrix * modelViewMatrix * vec4( position, 1.0 );',
		'}',
	].join('\n')
	let fragmentShader	= [
		'varying vec3		vNormal;',
		'varying vec3		vWorldPosition;',

		'uniform vec3		lightColor;',

		'uniform vec3		spotPosition;',

		'uniform float		attenuation;',
		'uniform float		anglePower;',

		'uniform sampler2D	goboTexture;',
		'uniform bool		useGobo;',

		'uniform mat4		modelMatrix;',


		'void main(){',
			'float intensity;',

			//////////////////////////////////////////////////////////
			// distance attenuation					//
			//////////////////////////////////////////////////////////
			'intensity	= distance(vWorldPosition, spotPosition)/attenuation;',
			'intensity	= 1.0 - clamp(intensity, 0.0, 1.0);',

			//////////////////////////////////////////////////////////
			// intensity on angle					//
			//////////////////////////////////////////////////////////
			'vec3 normal	= vec3(vNormal.x, vNormal.y, abs(vNormal.z));',
			'float angleIntensity	= pow( dot(normal, vec3(0.0, 0.0, 1.0)), anglePower );',
			'intensity	= intensity * angleIntensity;',

			//////////////////////////////////////////////////////////
			// Compute Gobos					//
			//////////////////////////////////////////////////////////
			'float goboIntensity = 1.0;',
			'if(useGobo)',
			'{',
				'// compute direction in world space',
				'vec3 worldDirection	= normalize(vWorldPosition - cameraPosition);',
				'// convert to object space',
				'vec3 localDirection	= normalize(inverse(modelMatrix) * vec4(worldDirection, 0.0)).xyz;',

				'// same for the position',
				'vec3 localPosition		= (inverse(modelMatrix) * vec4(vWorldPosition, 1.0)).xyz;',
				'float diameter			= 2.0 * length(localPosition.xy);',

				'// linear equation ux + vy + h = 0',
				'float u		= - localDirection.x;',
				'float v		= localDirection.y;',
				'// h is resized to fit in [0; 1]',
				'float h		= -u * (localPosition.x / diameter + 0.5) - v * (localPosition.y / diameter + 0.5) ;',

				'// go through the texture',
				'vec4 goboColor;',
				'float sampleCount = 32.0;',
				'if(v != 0.0 && abs(-u / v) <= 1.0)',
				'{',
					
					'for(int i = 0; float(i) < sampleCount; i++)',
					'{',
						'float i_float = float(i);',
						'float x = i_float / sampleCount;',
						'float y = -u / v * x - h / v;',
						'if(x >= 0.0 && x <= 1.0 && y >= 0.0 && y <= 1.0)',
						'{',
							'goboColor += texture(goboTexture, vec2(x, y));',
						'}',
					'}',
				'}',
				'if(u != 0.0 && abs(-v / u) < 1.0)',
				'{',
					'for(int i = 0; float(i) < sampleCount; i++)',
					'{',
						'float i_float = float(i);',
						'float y = i_float / sampleCount;',
						'float x = -v / u * y - h / u;',
						'if(x >= 0.0 && x <= 1.0 && y >= 0.0 && y <= 1.0)',
						'{',
							'goboColor += texture(goboTexture, vec2(x, y));',
						'}',
						
					'}',
				'}',

				'goboIntensity = (goboColor.x + goboColor.y + goboColor.z) / 3.0 / sampleCount;',
			'}',
			

			//////////////////////////////////////////////////////////
			// final color						//
			//////////////////////////////////////////////////////////

			// set the final color
			'gl_FragColor	= vec4(lightColor, intensity * goboIntensity);',
		'}',
	].join('\n')

	// create custom material from the shader code above
	//   that is within specially labeled script tags
	let material	= new THREE.ShaderMaterial({
		uniforms: { 
			attenuation	: {
				type	: "f",
				value	: 1.0
			},
			anglePower	: {
				type	: "f",
				value	: 1.2
			},
			spotPosition		: {
				type	: "v3",
				value	: new THREE.Vector3(0, 0, 0)
			},
			lightColor	: {
				type	: "c",
				value	: new THREE.Color('white')
			},
			goboTexture	: {
				value	: new THREE.Texture()
			},
			useGobo	: {
				value	: false
			}
		},
		vertexShader	: vertexShader,
		fragmentShader	: fragmentShader,
		// side		: THREE.DoubleSide,
		// blending	: THREE.AdditiveBlending,
		transparent	: true,
		depthWrite	: false,
	});
	return material
}