//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, {Component} from 'react';
import { cie2hex } from '../ColorPicker/utilities';
import { getDPMM } from '../../util/defines';


class LabelField extends Component
{
    constructor(props)
    {
        super(props)

        this.state = {
            PosX: 0.50,
            PosY: 0.50,

            SizeX: props.field.SizeX,
            SizeY: props.field.FontSize,

            ParentWidth: 0,
            ParentHeight: 0,
            ParentOffsetX: 0,
            ParentOffsetY: 0,

            FieldName: props.fieldName,
            Selected: false,
            FontSize: 12,
        };

        this.fieldGrabbed = false;
        this.grabbOffsetX = 0;
        this.grabbOffsetY = 0;
        this.mouseMoveDistance = 0;

        this.myRef = React.createRef()

        window.addEventListener("mouseup", this.onMouseUp)
        window.addEventListener("mousemove", this.onMouseMove)
        window.addEventListener("print-size-change", this.onResize)
    }

    // takes field width print size in mm, gives editor size in px
    calcWidth = (sizeX) => {
        return sizeX * this.props.scale * getDPMM()
    }

    // takes font print size in pt, gives editor size in px
    calcHeightfromFont = (fontSize) => {
        return fontSize * 1.3333 * this.props.scale + 5
    }

    componentDidMount()
    {
        let parentBoundingRect = this.myRef.current.parentElement.getBoundingClientRect()
        this.setState({ ParentWidth: parentBoundingRect.width, ParentHeight: parentBoundingRect.height,
                        ParentOffsetX: parentBoundingRect.x, ParentOffsetY: parentBoundingRect.y})

        if (this.props.field)
        {
            let offsetX = this.myRef.current.parentElement.offsetLeft
            let offsetY = this.myRef.current.parentElement.offsetTop
            this.setState({PosX: this.props.field.OffsetX / 100, PosY: this.props.field.OffsetY / 100, Selected: this.props.field.Selected, FontSize: this.props.field.FontSize, offsetX, offsetY})
        }

        if (this.props.fieldName === "QRCodeField")
        {
            this.setState({SizeY: this.state.SizeX})
        }
    }


    componentDidUpdate(beforeProps)
    {
        this.updateParentBounding();
    }

    render()
    {
        let backgroundColor = this.state.Selected ? "blue" : cie2hex({fx: this.props.field.BackgroundColor.X, fy: this.props.field.BackgroundColor.Y, f_Y: this.props.field.BackgroundColor.Z})
        let foregroundColor = this.state.Selected ? "black" : cie2hex({fx: this.props.field.ForegroundColor.X, fy: this.props.field.ForegroundColor.Y, f_Y: this.props.field.ForegroundColor.Z})
        

        let fontWeight = this.props.field.Fat ? "bold" : undefined;
        let fontStyle = this.props.field.Cursive ? "italic" : undefined;
        let textDecorationLine = this.props.field.Underlined ? "underline" : undefined;

        let posX = this.state.PosX
        let posY = this.state.PosY

        let SizeX = this.calcWidth(this.state.SizeX)
        let SizeY = this.calcHeightfromFont(this.state.SizeY)

        return <div     
                        ref         = {this.myRef} 
                        style       = {{    position            : "absolute", 
                                            left                : posX + this.props.offsetX, 
                                            top                 : posY + this.props.offsetY, 
                                            width               : SizeX + "px", 
                                            height              : SizeY + "px",
                                            fontWeight          : fontWeight,
                                            fontStyle           : fontStyle,
                                            textDecorationLine  : textDecorationLine,
                                            backgroundColor     : backgroundColor,
                                            color               : foregroundColor, 
                                            borderStyle         : "solid", 
                                            borderWidth         : ".1px",
                                            userSelect          : "none",
                                            fontSize            : `${this.state.FontSize * this.props.scale}pt`}}
                        onMouseDown = {(e) => { if(this.props.editable) { this.onMouseDown(e) } }}>
            {this.props.children}
        </div>
    }

    onMouseDown = (e) =>
    {
        this.fieldGrabbed = true;
        this.mouseMoveDistance = 0
        e.persist();
        let targetRect = e.target.getBoundingClientRect()
        let clientX = e.clientX
        let clientY = e.clientY

        this.grabbOffsetX = clientX - targetRect.x
        this.grabbOffsetY = clientY - targetRect.y
    }

    onMouseMove = (e) =>
    {
        if (this.fieldGrabbed)
        {
            this.mouseMoveDistance += Math.sqrt(e.movementX ** 2 + e.movementY ** 2);

            let clientX = e.clientX
            let clientY = e.clientY

            let newAbsPosX = clientX - this.state.ParentOffsetX - this.grabbOffsetX
            let newAbsPosY = clientY - this.state.ParentOffsetY - this.grabbOffsetY
            
            let relativePosX = this.getRelativePosition(newAbsPosX, this.state.ParentWidth)
            let relativePosY = this.getRelativePosition(newAbsPosY, this.state.ParentHeight)

            this.setState({PosX: relativePosX, PosY: relativePosY})
        }
    }

    onMouseUp = (e) =>
    {
        // Need to update the label
        if (this.fieldGrabbed && this.props.onChange)
        {

            //Click
            if (this.mouseMoveDistance < 5)
            {
                if (this.props.labelUuid && !this.props.field.NonSelectable)
                {
                    window.LR_SelectPrintLabelField({LabelUUID: this.props.labelUuid, LabelField: this.state.FieldName, Select: !this.state.Selected})
                }
            }

            let changeObject = {}
            changeObject.PosX = this.state.PosX
            changeObject.PosY = this.state.PosY
            this.props.onChange({[this.state.FieldName]: changeObject});
        }
        this.fieldGrabbed = false;
    }

    onResize = (e) =>
    {
        this.updateParentBounding();
    }

    updateField(labelObject)
    {
        if (labelObject && labelObject.Fields[this.state.FieldName])
        {
            let SizeX = labelObject.Fields[this.state.FieldName].SizeX
            
            let SizeY = 0
            if(this.state.FieldName === "QRCodeField")
            {
                SizeY = SizeX
            }
            else
            {
                SizeY = labelObject.Fields[this.state.FieldName].FontSize
            }
            
            this.setState({ PosX: labelObject.Fields[this.state.FieldName].PosX, 
                            PosY: labelObject.Fields[this.state.FieldName].PosY, 
                            Selected: labelObject.Fields[this.state.FieldName].Selected, 
                            FontSize: labelObject.Fields[this.state.FieldName].FontSize,
                            SizeX, 
                            SizeY, })
        }
    }

    updateParentBounding()
    {
        let parentBoundingRect = this.myRef.current.parentElement.getBoundingClientRect()
        if (this.state.ParentWidth !== parentBoundingRect.width || this.state.ParentHeight !== parentBoundingRect.height ||
            this.state.ParentOffsetX !== parentBoundingRect.x || this.state.ParentOffsetY !== parentBoundingRect.y)
        {
            this.setState({ ParentWidth: parentBoundingRect.width, ParentHeight: parentBoundingRect.height,
                            ParentOffsetX: parentBoundingRect.x, ParentOffsetY: parentBoundingRect.y})
        }
    }

    getRelativePosition(pos, parentSize)
    {
        return (pos / parentSize);
    }
}

export default LabelField;