//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component, createRef } from 'react';
import { Menu, Form } from 'semantic-ui-react';
import LocalizedStrings from "../../localization/DmxOverview";
import { globalCallbacks } from '../../util/callback';
import DmxChannel from './DmxChannel';
import { ABSOLUTE_VIEW, UNIVERSE_VIEW } from'../../util/defines';
import "./DmxOverview.css"

class DmxOverview extends Component
{
    constructor(props)
    {
        super(props);
     
        this.scrollRefs = []
        this.state = 
        {
            universes       : 5,
            tree            : {},
            activeUniverse  : 1,
            Data            :undefined,
            hideEmptyUniverses : true,
            dmxViewType     : Number(window.localStorage.getItem("dmxViewType"))
        }
        if(this.state.dmxViewType === undefined)
        {
            this.state.dmxViewType = UNIVERSE_VIEW 
            window.localStorage.setItem("dmxViewType", this.state.dmxViewType)
        }

        this.setUpCallbacks();
        globalCallbacks.updateDmxTree();
    }

    render()
    {
        let menu        = [];
        let channels    = {};
        for (let index = 1; index <= this.state.universes; index++) 
        {
            this.scrollRefs[index] = this.scrollRefs[index] ?? createRef()
            channels[index] = [];  
            
            let anyMatch = false
            if(this.state.hideEmptyUniverses){
                for (let channel = 1; channel < 513; channel++) 
                {
                    let absoluteAddress = (index-1) * 512 + channel;
                    let match = this.state.tree[absoluteAddress];
                    if(match){
                        anyMatch = true;
                        break;
                    }
                }
            }else{
                anyMatch = true
            }
            
            if(anyMatch){
                menu.push(<Menu.Item key={index} active={index === this.state.activeUniverse} onClick={() => this.setActiveUniverse(index)}>Universe {index}</Menu.Item>);
                for (let channel = 1; channel < 513; channel++) 
                {
                    let absoluteAddress = (index-1) * 512 + channel;
                    let match = this.state.tree[absoluteAddress];

                    let dmxValue = undefined
                    if(this.state.Data)
                    {
                        dmxValue = this.state.Data[channel-1]
                    }

                    channels[index].push(
                        <DmxChannel key             = {absoluteAddress} 
                                    universe        = {index}
                                    channel         = {channel}
                                    absoluteAddress = {absoluteAddress}
                                    match           = {match}
                                    dmxValue        = {dmxValue}
                                    onDrag          = {this.onDrag}
                                    onDrop          = {this.onDrop}
                                    />)
                }
            }

        }
        


        return(
        <div className='menuContainer'>
                <div className='styleDiv'>
                    {this.props.WebApp ? this.renderWebApp(menu): this.renderElectronApp(menu)}
                </div>
                    
                <div className="styleDMXDiv">
                {
                    Object.entries(channels).map(entry =>
                    {
                        return(
                            <div key={entry[0]} ref={this.scrollRefs[entry[0]]} id={"universe"+entry[0]} style={{display: 'flex', flexWrap: "wrap"}}>
                                {entry[1]}
                            </div>
                        );
                    })
                }

                </div>

                
        </div>);
    }

    renderWebApp = (menu) =>
    {
        return(
            <React.Fragment>
                <h5>{LocalizedStrings.Display}</h5>
                {this.renderRadioControl()}
                <h5>{LocalizedStrings.Universes}</h5>
                {this.renderUniverseControl(menu)}
            </React.Fragment>
        )
    }

    renderElectronApp = (menu) =>
    {
        return (
            <div className='leftMenubar'>
                {this.renderUniverseControl(menu)}
                {this.renderRadioControl()}
            </div>
        )
    }

    renderUniverseControl = (menu) =>
    {
        return( <Menu fluid secondary vertical >
                {menu}
                </Menu>)
    }

    renderRadioControl = () =>
    {
        let styleFooter = !this.props.WebApp ?  { } : 
                                                {verticalAlign: "bottom" } 
        return(<Form style={styleFooter} >
            <Form.Checkbox toggle  
                checked={this.state.hideEmptyUniverses}
                onChange={()=>this.setState({hideEmptyUniverses: !this.state.hideEmptyUniverses})}
                label={LocalizedStrings.HideEmptyUniverse}
            /> 
            <Form.Radio label   = {LocalizedStrings.AbsoluteAddress}
                value           = {ABSOLUTE_VIEW}
                defaultChecked
                checked         = {this.state.dmxViewType === ABSOLUTE_VIEW}
                onChange        = {this.changeView}
                />

            <Form.Radio label   = {LocalizedStrings.UniverseAddress}
                value           = {UNIVERSE_VIEW}
                checked         = {this.state.dmxViewType === UNIVERSE_VIEW}
                onChange        = {this.changeView}
               />
            
          
        </Form>
        
        
        )
    }

    setActiveUniverse = (index) =>
    {
        this.scrollRefs[index]?.current?.scrollIntoView()
        this.setState({activeUniverse : index});
    }

    changeView = (e, {value}) =>
    {
        this.setState({dmxViewType: value});
        window.localStorage.setItem("dmxViewType", value)
    }

    setUpCallbacks()
    {
        globalCallbacks.updateDmxTree = async () => 
        {
            window.LR_GetDmxTree().then(tree => {
                if(tree)
                {
                    let universes = Math.ceil(tree.max / 512);
                    if(isNaN(universes)) { universes = 1; }
                    this.setState({tree:tree, universes: universes});
                }
                else
                {
                    this.setState({tree:[],universes: 1});
                }
            });
        }

        window.LR_GetDataForDMXUniverse({Async: true, Universe:0}).then(i => this.setState({Data: i}))
    }
}

export default DmxOverview;