import { createStore, applyMiddleware, compose } from 'redux'
import { createLogger } from 'redux-logger'

import thunkMiddleware from 'redux-thunk'
import MainReducer from './reducers/main_reducers'
import * as Sentry from '@sentry/react';
import { RewriteFrames } from "@sentry/integrations";

const loggerMiddleware = createLogger()

import CONST from "../webApp/CONST"



if(process.env.NODE_ENV === 'production' && !process.env.JEST_WORKER_ID)
{
    console.log("Starting Sentry")
    //console.log  = () => { }
    console.warn = () => { }

    const rewriteFramesIntegration = new RewriteFrames({
    iteratee: frame => {
        if (frame.filename) 
        {
            let filename = frame.filename.split("static").pop();
            frame.filename = "~/static" + filename
        }
        return frame;
    }
    });
    console.log("Start Sentry")
    Sentry.init({
        dsn: "https://7ee2a3c714d043d99976daf7ef4d6869@sentry.io/2529975",
        release: CONST.VERSION + "-" + CONST.BUILD,
        integrations: [rewriteFramesIntegration],
        tracesSampleRate: 1.0,
    });
}

window.__LR__SENTRY = Sentry

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options
  });


export const store = createStore(
    MainReducer,
    compose(
    applyMiddleware(
      thunkMiddleware, // lets us dispatch() functions
      loggerMiddleware), // neat middleware that logs actions
      sentryReduxEnhancer
    )
  )
  