//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Button, Form, Header, Icon, Message, List } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/AddNewUserToProject";
import { EMPTY_UUID, ValidateEmail } from '../../util/defines';
import LRModal from '../Basics/BasicModal';
import LRFilterInput from '../Basics/FilterField';
import UserAvatar from '../WebComponents/UserAvatar';
import { globalCallbacks } from "../../util/callback";


//-----------------------------------------------------------------------------
// The component for the TreeControl
class AddNewUserToProject extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
      Groups: [],
      Collaborators: [],
      UnlinkedUsers: [],
      searchFilter: "",
      noProjectError: false
    }
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }

  componentDidUpdate = (prevProps, prevState) =>
  {
    if(!prevState.linkedProject && this.state.linkedProject)
    {
      this.setState({ noProjectError: false})
    }
  }

  show = () => 
  {
    this.setState( { open : true});
  }

  onOkButton = () => 
  {
    this.setState({open : false});
  }

  close = () => 
  {
    this.setState({open : false});
  }
    
  render() 
  {
    let open = this.state.open;
    if(!open) {return <div/>}
    return (
      <LRModal  open={open}
                title={LocalizedStrings.Header}
                closeOnDimmerClick={false}
                onCancelClick={this.close}
                noCancel
                onOkClick={this.onOkButton}>
                <>
                <Form>

                    <LRFilterInput value = {this.state.searchFilter} onChange={(value)=> {this.setState({searchFilter: value})}}/>
                    <Form.Select 
                        style={{marginTop:"1em"}}
                        fluid
                        search
                        selection
                        value={this.state.UserToReplace}
                        onChange={(e, {value} ) => this.setState({UserToReplace: value})}
                        options={[...this.state.UnlinkedUsers, {key: EMPTY_UUID, text:LocalizedStrings.NoUser, value: EMPTY_UUID}]}
                        label={LocalizedStrings.UserToReplace}
                        />
                    
                        </Form>
                    <Header>{LocalizedStrings.Users}</Header>
                    <List divided>
                        {this.state.Collaborators.map(m=>
                        {return this.filterBySearch(
                            <List.Item key={m.username}>
                                <UserAvatar user={m.username}/>{m.username}
                                <Button disabled={this.state["adding-"+m.username]} floated='right' positive onClick={()=>{this.handleOKClick(m.username)}}>
                                  {this.state["adding-"+m.username] ? <Icon name="undo" loading/>: null}
                                  {LocalizedStrings.Add}
                                </Button>
                            </List.Item>, m.username)})}
                    </List>
                    <Header>{LocalizedStrings.Groups}</Header>
                    <List divided>
                        {this.state.Groups.map(m=>
                        {return this.filterBySearch(
                            <List.Item key={m.name}>
                                {m.name}
                                <Button floated='right' secondary>{LocalizedStrings.Add}</Button>
                            </List.Item>, m.name)})}
                    </List>
                    {this.renderInvite()}
                </>
      </LRModal>
    )
  }

  renderInvite = () => {
    let mailNotValid = !ValidateEmail(this.state.inviteMail)

    if(!this.state.inviteMail)
    {
      mailNotValid = false
    }
    return (
      <>
        <Header>{"Invite to PA"}</Header>
        <Form>
          <Form.Group>
            <Form.Input width={12} fluid placeholder="E-Mail" onChange={(e, {value}) => { this.setState({inviteMail: value}) }}/>
            <Form.Button width={4} disabled={mailNotValid} fluid positive onClick={this.sendInvite}>Send</Form.Button>
          </Form.Group>
        </Form>
        { mailNotValid && <Message error>{LocalizedStrings.InvalidEmailErrorMessage}</Message>}
        { this.state.noProjectError && <Message error>{LocalizedStrings.NoProjectErrorMessage}</Message>}
      </>
    )
  }

  sendInvite = () => {
    if(this.state.linkedProject)
    {
      window.LR_SendInvite({toInviteMail: this.state.inviteMail})
    }else
    {
      this.setState({noProjectError: true})
    }
  }

  handleOKClick= async(user)=>
  {
    this.setState({["adding-"+user]: true})
    await window.LR_AddProjectMember({User: user, UserToReplace: this.state.UserToReplace})
    this.setState({["adding-"+user]: false})
    globalCallbacks.ShowAddNewUserToProjectRefetch();
  }

  filterBySearch = (component, tag) =>
  {
    if(this.state.searchFilter !== "" && ! tag.toLowerCase().includes(this.state.searchFilter.toLowerCase()) )
    {
      return null
    }
    return component
  }

  setUpCallbacks()
  {

    globalCallbacks.ShowAddNewUserToProjectRefetch = async () => 
    {
      let ret   = await window.LR_GetProjectMembers()
      let ret2  = await window.LR_GetUsers()

      
     
      let members = []
      if(ret.members)
      {
        members = ret.members.filter(m => m.user)
      }

      let arg = await window.LR_GetCollaboratorsAsync() 
      
      if(arg){
        let notinProject = arg.filter(e =>
        {
          for (const user of members) { if(user.user.username === e.username)          { return false ; } }
          for (const user of ret2.Users) { if(user.LinkedPAUser === e.username)   { return false; } }
  
          return true
        })
        this.setState({ Collaborators: notinProject})
      }


      let UnlinkedUsers = ret2.Users.filter(e =>
      {
        for (const user of members) { if(e.LinkedPAUser === user.user.username)   { return false; } }
        if(e.LinkedPAUser !== "")   { return false; } 
        return true
      }).map((user) => {
        return {
          text: <>{user.Name}</>,
          key: user.UUID,
          value: user.UUID,
        };
      });
      
        let Groups =  await window.LR_GetGroupsFromUser()  

        let linkedProject = await window.LR_GetLinkedProject()

      this.setState({ Groups, UnlinkedUsers, linkedProject: linkedProject.Project })
    
    }

    globalCallbacks.ShowAddNewUserToProject = async () => 
    { 
      globalCallbacks.ShowAddNewUserToProjectRefetch()
      this.show();
    }
    
  }
}

export default AddNewUserToProject