import React, { useEffect, useState } from "react";
import { Button, Form } from "semantic-ui-react";
import LocalizedStrings from "../../localization/TableViewComponent";
import LRModal from "../Basics/BasicModal";
import PrintSettings, { IPdfFormat, PrintSettingsPresetSelector } from "../GlobalSettings/PrintSettings";
import { globalWindowInterface } from "../../util/callbackTypes";
import { globalCallbacks } from "../../util/callback";

declare const window: globalWindowInterface;

export default function CustomPDFFormatPicker({
    PDFFormat,
    onChangePdfFormat
}: {
    PDFFormat: IPdfFormat,
    onChangePdfFormat: (format: IPdfFormat) => void,
}) {
    const [paperFormatOpen, setPaperFormatOpen] = useState(false)

    let [printFormatTemplate, setPrintFormatTemplate] = useState([])
    let [selectedPrintFormatTemplate, setSelectedPrintFormatTemplate] = useState("Default")


    let loadPrintFormats = () => {
        window.LR_GetPrintFormatTemplateMap().then(res => res.ResourceMap.printFormats).then(setPrintFormatTemplate)
    }

    let updateCustomFormat = (i: IPdfFormat, preset?: string)=>{
        onChangePdfFormat(i)
        if(preset)
        {
            setSelectedPrintFormatTemplate(preset)
        }
    }

    let selectNewPreset = (Name: string)=>{
        onChangePdfFormat(printFormatTemplate.find(i => i.Name === Name).format)
        setSelectedPrintFormatTemplate(Name)
    }

    let [defaultFormatName, setDefaultFormatName] = useState("Default")

    useEffect(() => {
        globalCallbacks.updateSettingsViewPresets = loadPrintFormats
        loadPrintFormats()

        globalCallbacks.updatePrintSettingsPresetSelectorDefault2 = () => {
            window.LR_GetSetSelectedPrintFormatTemplate().then(res => setDefaultFormatName(res.PrintFormatTemplateName))
        }

        window.LR_GetSetSelectedPrintFormatTemplate().then(res => { 
            setSelectedPrintFormatTemplate(res.PrintFormatTemplateName)
            if(PDFFormat === undefined)
            {
                onChangePdfFormat(res.PrintFormatTemplate)
            } 
        }) 

        return () => {
            globalCallbacks.updateSettingsViewPresets = undefined
            globalCallbacks.updatePrintSettingsPresetSelectorDefault2 = undefined
        }
    }, [])

    return <>
        <h5 style={{marginTop: 14, marginBottom: 4}}>{LocalizedStrings.PaperFormatPreset}</h5>
        <Form.Group style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 14, marginLeft: 0}} >
            <PrintSettingsPresetSelector
                withoutButtons
                selectedPreset={selectedPrintFormatTemplate}
                defaultPreset={defaultFormatName}
                presets={printFormatTemplate}
                onChangeSelected={selectNewPreset}
                style={{width: 320}}
            />
            <Button style={{marginLeft: 10}} onClick={() => setPaperFormatOpen(true)}>{LocalizedStrings.OpenFormatSettings}</Button>
        </Form.Group>
        <LRModal
            open={paperFormatOpen}
            title={LocalizedStrings.PDFFormatSettings}
            onOkClick={() => setPaperFormatOpen(false)}
            style={{width: 1200}}
            noCancel
        >
            {
                PDFFormat ?
                <PrintSettings
                    format={PDFFormat}
                    onFormatChange={(format, name, val) => onChangePdfFormat({ ...PDFFormat, [format]: { ...PDFFormat[format], [name]: val } })}
                    onAllFormatChange={updateCustomFormat}
                />
                :
                null
            }

        </LRModal>
    </>
}