import { Header, Icon, Table } from "semantic-ui-react";
import React, { useState } from "react";
import LocalizedStrings from "../../localization/DiffView";

interface changedInterface {
  Changed: {
    [key: string]: {
      Changes: {
        Geometries: changedInterface,
        [key: string]: any
      }
    }
  },
  Created: any,
  Deleted: any
}

interface diffViewInterface {
  [key: string]: changedInterface
}

function SingleRow(this: unknown, { createCount, changeCount, deleteCount, name, indent, children }: {
  createCount: number,
  changeCount: number,
  deleteCount: number,
  name: string,
  indent?: number,
  children?: JSX.Element[]
}) {
  let [expanded, setExpanded] = useState(true)

  return <>
    <Table.Row active={!!children?.length}>
      <Table.Cell>
        <div style={{ marginLeft: `${20 * (indent ?? 0)}px` }}>
          {
            children?.length ?
              <Icon link name={expanded ? "angle down" : "angle right"} onClick={() => setExpanded(!expanded)} />
              :
              <Icon name="long arrow alternate right" />
          }
          {name}
        </div>
      </Table.Cell>
      <Table.Cell>{createCount}</Table.Cell>
      <Table.Cell>{changeCount}</Table.Cell>
      <Table.Cell>{deleteCount}</Table.Cell>
      <Table.Cell><b>{createCount + changeCount + deleteCount}</b></Table.Cell>
    </Table.Row>
    {expanded ? children : null}
  </>
}

export default function (this: unknown, props: { diff: diffViewInterface }) {

  let diff = props.diff
  let [expanded, setExpanded] = useState(true)

  if (!diff) {
    return null
  }

  let list = []
  for (let [key, value] of Object.entries(diff)) {
    if (value && value.Changed && value.Created && value.Deleted) {
      let changeCount = Object.keys(value.Changed).length
      let createCount = Object.keys(value.Created).length
      let deleteCount = Object.keys(value.Deleted).length

      let sum = changeCount + createCount + deleteCount

      if (!sum) {
        continue
      }

      let types: { [key: string]: { cr: number, ch: number, de: number } } = {}
      let geoCr = 0, geoCh = 0, geoDe = 0
      for (let val of Object.values(value.Changed)) {
        for (let [k, v] of Object.entries(val.Changes)) {
          if (k === "Geometries") {
            geoCr += Object.keys(v.Created).length
            geoCh += Object.keys(v.Changed).length
            geoDe += Object.keys(v.Deleted).length
          } else if (k !== "ObjectId" && k !== "ObjectName") {
            let t = types[k]
            if (t) {
              t.ch += 1
            } else {
              types[k] = { cr: 0, ch: 1, de: 0 }
            }
          }
        }
      }
      if (geoCr + geoCh + geoDe) {
        types["Geometries"] = { cr: geoCr, ch: geoCh, de: geoDe }
      }

      list.push(
        <SingleRow name={key} changeCount={changeCount} createCount={createCount} deleteCount={deleteCount}>
          {
            Object.entries(types).map(([k, v]) => <SingleRow indent={1} name={k} changeCount={v.ch} createCount={v.cr} deleteCount={v.de} />)
          }
        </SingleRow>
      )
    }
  }

  if (!list.length) {
    return null
  }

  return (
    <>
      <Header>
        <Icon link name={expanded ? "angle down" : "angle right"} onClick={() => setExpanded(!expanded)} />
        {(LocalizedStrings as any).ChangeSummaryHeader}
      </Header>
      {expanded ?
        <Table compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{(LocalizedStrings as any).ChangeName}</Table.HeaderCell>
              <Table.HeaderCell>{(LocalizedStrings as any).ChangeAdded}</Table.HeaderCell>
              <Table.HeaderCell>{(LocalizedStrings as any).ChangeModified}</Table.HeaderCell>
              <Table.HeaderCell>{(LocalizedStrings as any).ChangeRemoved}</Table.HeaderCell>
              <Table.HeaderCell>{(LocalizedStrings as any).ChangeSum}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {list}
        </Table> :
        null
      }
    </>
  )
}