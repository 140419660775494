//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component, createRef } from 'react';
import { Table, Grid, Menu, Dropdown, Sticky, Input } from "semantic-ui-react";
import LocalizedStrings from "../../../localization/LightingPaperwork";
import {escapeRegExp} from"../../../util/defines";
import { globalCallbacks } from "../../../util/callback";


class CueList extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = 
        {
            timePhases              : [{TimePhaseChangeObjects: []}],
            activeTimePhaseIndex    : 0,
            activeTimePhaseChange   : "",

            searchText              : "",
            searchOptions           : [],
            searchResults           : [[]],
        };

        this.contextRef = createRef();
    }

    componentDidMount = () => 
    {
        this.setUpCallbacks();

        if(globalCallbacks.getTimePhasesCueList)
        {
            globalCallbacks.getTimePhasesCueList();
        }
    }

    componentWillUnmount = () => 
    {
        this.takeDownCallbacks();
    }

    render() 
    {
        return (
           <Grid columns={2}>
                <Grid.Column width={2}>
                    <div style={{height: '100%'}} ref={this.contextRef}>
                    <Sticky context={this.contextRef}>
                        
                        <Menu fluid secondary vertical >
                            <Dropdown
                                placeholder         = {LocalizedStrings.ChooseTimeLine}
                                fluid
                                selection
                                lazyLoad
                                options             = {this.state.timePhaseOptions}
                                value               = {this.state.activeTimePhase}
                                onChange            = {this.onChangeActiveTimePhase}/>
                            <Input
                                icon        = "search"
                                fluid
                                placeholder = "Search..."
                                value       = {this.state.searchText}
                                onChange    = {this.onChangeSearch}/>
                            {
                                this.state.searchResults.map(timePhaseChange =>
                                {
                                    return(
                                        <Menu.Item  key         = {timePhaseChange.UUID}
                                                    active      = {timePhaseChange.UUID === this.state.activeTimePhaseChange}
                                                    onClick     = {() => this.onCueClicked(timePhaseChange)}>
                                                        
                                            {timePhaseChange.title}
                                        </Menu.Item>
                                    );
                                })
                            }
                        </Menu>
                    </Sticky>
                    </div>
                </Grid.Column>
                <Grid.Column width={14}>
                {this.state.timePhases[this.state.activeTimePhaseIndex].TimePhaseChangeObjects.map(change =>
                    { return this.renderCue(change); })
                }
                </Grid.Column>
            </Grid>
        );
    }

    renderCue = (change) =>
    {
        return(<div id={change.UUID} key={change.UUID} style={{marginTop: "0.5em", marginLeft: "0.5em"}}>
            <Table  fixed size="small" compact striped name={change.UUID} >
            <Table.Header>
                <Table.HeaderCell>{change.Name}</Table.HeaderCell>
                <Table.HeaderCell>{LocalizedStrings.PresetName}</Table.HeaderCell>
                <Table.HeaderCell>{LocalizedStrings.FadeHeader  + " (" +this.formatInOutValues(change.FadeIn, change.FadeOut)   + ")"}</Table.HeaderCell>
                <Table.HeaderCell>{LocalizedStrings.DelayHeader + " (" + this.formatInOutValues(change.DelayIn, change.DelayOut)+ ")"}</Table.HeaderCell>
                <Table.HeaderCell>{LocalizedStrings.Effect}</Table.HeaderCell>
            </Table.Header>
            {change.activePresets.map(preset =>
                { return this.renderPresetEntry(preset); })
            }
            </Table></div>
        )
    }

    renderPresetEntry = (preset) =>
    {
        return(<React.Fragment key={preset.UUID}>
             <Table.Row>
                <Table.Cell></Table.Cell>
                <Table.Cell>{preset.Name}</Table.Cell>
                <Table.Cell>{this.formatInOutValues(preset.FadeIn, preset.FadeOut)}</Table.Cell>
                <Table.Cell>{this.formatInOutValues(preset.DelayIn, preset.DelayOut)}</Table.Cell>
                <Table.Cell></Table.Cell>
            </Table.Row>
        </React.Fragment>)
    }

    formatInOutValues = (valueIn, valueOut) =>
    {
        //These values are in frames but we're printing them in seconds
        return valueIn/30 + "s / " + valueOut/30 + "s";

    }

    //------------------------------------------------------------------------------------------
    //Search related functions

    onChangeSearch = (_, {value}) =>
    {
        let searchResults = this.state.searchOptions[this.state.activeTimePhaseIndex];

        if(value)
        {
            const re = new RegExp(escapeRegExp(value), 'i')
            const isMatch = (result) => re.test(result.title);

            searchResults = this.state.searchOptions[this.state.activeTimePhaseIndex].filter(isMatch);

            //Avoid crash
            if(searchResults.length === 0) { searchResults.push([]); }
        }

        this.setState({ searchText : value, searchResults });
    }

    //------------------------------------------------------------------------------------------

    onChangeActiveTimePhase = (e, {value, options}) =>
    {
        let activeTimePhaseIndex = options.findIndex(x => x.value === value);

        let searchResults = this.state.searchOptions[activeTimePhaseIndex];

        this.setState({activeTimePhase: value, activeTimePhaseIndex, searchResults});
    }

    onCueClicked = (change) =>
    {
        let jumpHere = change.UUID;
        window.location.href = window.location.origin + window.location.search + "#" + jumpHere;
        this.setState({activeTimePhaseChange : change.UUID});
    }

    computeActivePresets = async (timePhases) =>
    {
        //------------------------------------------------------------------------------------------
        // Get the presets objects we need to display, and add them to each timePhaseChange.
        let presetObject = await window.LR_GetPresets();
        let presets = presetObject.Presets;
        presets.sort(function(a, b) {return a.Order - b.Order});

        //------------------------------------------------------------------------------------------
        //Find the active presets for every timePhaseChange
        let activePresets = [];
        timePhases.forEach(phase =>
        {
            phase.TimePhaseChangeObjects.forEach(change =>
            {
                change.TurnOn.forEach(presetOn =>
                {
                    //If the preset is not already active, we add it to the list
                    if(!activePresets.find(preset => preset === presetOn))
                    {    
                        activePresets.push(presetOn);
                    }
                })

                change.TurnOff.forEach(presetOff =>
                {
                    //If the preset is active, we remove it from the list
                    let presetIndex = activePresets.findIndex(preset => preset === presetOff);
                    if(presetIndex > -1)
                    {    
                        activePresets.splice(presetIndex, 1);
                    }
                })

                //Add the preset objects
                change.activePresets = [];
                
                activePresets.forEach(preset =>
                {
                    change.activePresets.push(presets.find(p => p.UUID === preset));
                })

                change.activePresets.sort(function(a, b) {return a.Order - b.Order});
            })
        });
 
    }

    setUpCallbacks()
    {
        globalCallbacks.getTimePhasesCueList = async () =>
        {
            let ret = await window.LR_GetTimePhases();

            let searchOptions = [];

            let timePhases = ret.TimePhases;
            let timePhaseOptions    = [];
            timePhases.sort(function(a, b) {return a.Order - b.Order});
            timePhases.forEach((timePhase, i) =>
            {
                //Also sort the TimePhaseChanges
                timePhase.TimePhaseChangeObjects.sort(function(a, b) {return a.Order - b.Order});

                timePhaseOptions.push(
                {
                    key   : timePhase.UUID,
                    value : timePhase.UUID,
                    text  : timePhase.Name,

                });

                //Prepare data for the SearchBar
                let timePhaseChangeOptions = timePhase.TimePhaseChangeObjects.map(change =>
                {
                    return({
                        UUID    : change.UUID,
                        title   : change.Name,
                    })
                })

                searchOptions.push(timePhaseChangeOptions);

            });

            let searchResults = [...searchOptions[0]];
            

            await this.computeActivePresets(timePhases);

            this.setState({timePhases, timePhaseOptions, searchOptions, searchResults});
        }
    }

    takeDownCallbacks()
    {
        globalCallbacks.getTimePhasesCueList = undefined;
    }


}

export default CueList;

