// Function to trigger CSV download
export function downloadCSVFile(csv_data, filename = 'table.csv') {
    // Convert csv_data to a properly formatted CSV string
    const csvRows = csv_data.map(row => row.join(',')).join('\n');
    
    // Create CSV content with UTF-8 encoding
    const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(csvRows)}`;
    
    // Create an invisible link element
    const link = document.createElement("a");
    link.setAttribute("href", csvContent);
    link.setAttribute("download", filename);
    
    // Append the link to the body (required for Firefox)
    document.body.appendChild(link);
  
    // Trigger the download by simulating a click on the link
    link.click();
  
    // Clean up: remove the link from the DOM
    document.body.removeChild(link);
}