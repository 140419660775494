//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2021
//----------------------------------------------------------------------------------------------------------------

import React, { Component } from "react";
const Steps = React.lazy(()=>import("intro.js-react").then(i => ({default: i.Steps})))

import "intro.js/introjs.css";
import LocalizedStrings from "../localization/Tour"
import { connect } from 'react-redux';
const ReactMarkdown = React.lazy(()=>import("react-markdown"))

import gfm from 'remark-gfm'
import Youtube from "react-youtube";

import { TOOL_MODE_Move } from "../util/defines";
import {  Checkbox, Grid, Label } from "semantic-ui-react";
import LRModal from "../component/Basics/BasicModal";
import { IsUsingWebassembly } from "../webApp/ipcRenderer";
import { globalCallbacks } from "../util/callback";
import { GLOBAL_SETTINGS } from "../redux/redux_defines";

class StartingTour extends Component 
{
  constructor(props) {
    super(props);

    this.state = {
      stepsEnabled: true,
      initialStep: 0,
      modal: true,
      showOnNextStart :true,
      steps: [
        {
          element: ".Tool",
          intro: LocalizedStrings.Tool
        },
        {
          element: ".Tool_Insert",
          intro: LocalizedStrings.Tool_Insert
        },
        {
          element: ".Tool_Select",
          intro: LocalizedStrings.Tool_Select
        },
        {
          element: ".Tool_Select_mode",
          intro: LocalizedStrings.Tool_SelectMode
        },
        {
          element: ".Tool_Move",
          intro: LocalizedStrings.Tool_Move
        },
        {
          element: ".Tool_Move_LocalGlobal",
          intro: LocalizedStrings.Tool_Move_LocalGlobal,

        },
        {
          element: ".SceneTreeHierarchy",
          intro: LocalizedStrings.SceneTreeHierarchy
        },
        {
          element: ".Renderer",
          intro: LocalizedStrings.Renderer
        },
        {
          element: ".Renderer_2D",
          intro: LocalizedStrings.Renderer_2D
        },
        {
          element: ".ObjectProperties",
          intro: LocalizedStrings.ObjectProperties
        },
        {
          element: ".OIPsearch",
          intro: LocalizedStrings.ObjectPropertiesSearch
        },
        {
          element: ".NavigationContainer",
          intro: LocalizedStrings.NavigationContainer
        },
        {
          element: ".CommandLine",
          intro: LocalizedStrings.CommandLine
        },
        {
          element: ".flexlayout__tabset_tabbar_outer",
          intro: LocalizedStrings.TabBar
        },
        
      ]
    };
  }

  render() {
    const {
      steps,
      initialStep,
    } = this.state;

      if(!this.props.globalSettings) return null  

      let tourText = LocalizedStrings.Welcome.replace("$1", IsUsingWebassembly ? `[${LocalizedStrings.IntroVideo}](https://www.youtube.com/watch?v=${LocalizedStrings.StartYoutubeVideo})` : "")

      return (
        <>
        <LRModal
          onOkClick={()=>{this.setState({modal: false})}}
          onCancelClick={this.onExit} 
          open={this.props.globalSettings.ShowTour && this.state.modal}
          customOKText={LocalizedStrings.ShowFirstSteps}
          title={LocalizedStrings.WelcomeHeader}
          closeOnDimmerClick={false}
          size="large"
          additionalActions={<Checkbox checked={this.state.showOnNextStart} onChange={()=>{this.setState({showOnNextStart: !this.state.showOnNextStart})}} label={LocalizedStrings.HideNextStart}/>}
        >
          <Grid divided columns={IsUsingWebassembly ? 1 : 2} stackable>
            <Grid.Column><ReactMarkdown components={{ a: Link }} remarkPlugins={[gfm]}>{tourText}</ReactMarkdown></Grid.Column>
            <Grid.Column>{
              IsUsingWebassembly ? null : <Youtube videoId={LocalizedStrings.StartYoutubeVideo}/>
              }</Grid.Column>
          </Grid>
          
          </LRModal>
          <Steps
            enabled={this.props.globalSettings.ShowTour && !this.state.modal}
            steps={steps}
            initialStep={initialStep}
            onBeforeChange={this.onBeforeChange}
            onExit={this.onExit}
            ref={steps => (this.steps = steps)}
          />
        </>
      );

    
  }

  onBeforeChange = nextStepIndex => {
    if (nextStepIndex === 4) 
    {
      if(globalCallbacks.UpdateTransformTool){
        globalCallbacks.UpdateTransformTool(TOOL_MODE_Move, true);
      }
    }
    else if (nextStepIndex === 5) 
    {
      this.steps.updateStepElement(nextStepIndex);
    }
  }

  onExit = () => 
  {
    window.LR_SetGlobalSettings({ShowTour: false, ShowTourOnStartUp: this.state.showOnNextStart})
  };


}

function Link(props) {

  return (
    <Label color="green" size="big" as="a" onClick={()=>{window.LR_OpenLink({URL: props.href})}}>{props.children}</Label>
  );
}

const mapStateToProps = (state) => 
{
    return {
      globalSettings: state[GLOBAL_SETTINGS].data.GlobalSettings,
    };
}

export default connect(mapStateToProps)(StartingTour) 
