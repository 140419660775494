//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import LRModal from '../Basics/BasicModal';
import LocalizedStrings from "../../localization/InAppFeedback";
import { globalCallbacks } from "../../util/callback";



//-----------------------------------------------------------------------------
// The component for the TreeControl
class InAppFeedback extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
    }
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();


  }

  show = async(arg) => 
  {
    this.setState( { 
        open : true, 
        id: arg.ID

      });

      window.EhAPI = {}; 
      window.EhAPI.after_load = function(){
        window.EhAPI.set_account('mlhp6g686ajrbf7ln4oqvtb2q8', 'production-assist');
        window.EhAPI.execute('rules');};(function(d,s,f) {
        var sc=document.createElement(s);sc.type='text/javascript';
        sc.async=true;sc.src=f;var m=document.getElementsByTagName(s)[0];
        m.parentNode.insertBefore(sc,m);   
        })(document, 'script', '//d2p078bqz5urf7.cloudfront.net/jsapi/ehform.js');
  }

  close = () => 
  {
    this.setState({open : false});
  }
    
  render() 
  {
    return (
      <>
      {this.state.open ? 
      <>
      <LRModal 
      open={true}
      title={LocalizedStrings.HeaderOnlineFeedback}
      onOkClick={this.close}
      noCancel
      onCancelClick={this.close} >
          <div className="engage-hub-form-embed" id={`eh_form_${this.state.id}`} data-id={`${this.state.id}`}></div>
      </LRModal>
      </> : null}
      </>
    )
  }

  setUpCallbacks()
  {

    globalCallbacks.ShowInAppFeedback = (args) => 
    { 
      this.show(args);
    }
    
  }
}

export default InAppFeedback 
