//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { List, Header, Pagination } from 'semantic-ui-react';
import Notification from './Notification';
import LocalizedStrings from "../../localization/TimeStrings";
import { lrServerConnection } from '../../redux/light_right_server_connection';

class Updates extends Component{

  constructor(props){
    super(props)
    this.state = {
      page: 1,
      data: undefined,
      totalNumber: 1,
      isFetching: true
    }
    this.getData(1)
  }

  getData(page){
    this.setState({page, isFetching: true})
    lrServerConnection.getChangesPaginated(page).then((d)=>{
      this.setState({data: d.docs, totalNumber: d.totalPages, isFetching: false})
    })
  }

  componentDidMount(){
  }

  render(){
    if (this.state.isFetching) {
      return <React.Fragment>
        <Header>{LocalizedStrings.UpdateHeader}</Header>
        <List divided relaxed>
          <Notification key={1} loading />
        </List>
      </React.Fragment>
    }

    if (this.state.data && Array.isArray(this.state.data) && this.state.data.length > 0) 
    {
      const updates = this.state.data
  
      let days = [[]]
  
      let curDate = new Date(updates[0].date).toDateString()
  
      for (let i = 0; i < updates.length; i++) {
        let newDate = new Date(updates[i].date).toDateString()
        if (curDate !== newDate) {
          days.push([])
          curDate = newDate
        }
        days[days.length - 1].push(updates[i])
      }
  
      return (
        <>
          <Header>{LocalizedStrings.UpdateHeader}</Header>
          {
            days.map(day => {
              let dateStr = new Date(day[0].date).toDateString();
              return <React.Fragment key={"list-" + dateStr}>
                <Header>{LocalizedStrings.formatString(LocalizedStrings.UpdatesFrom, dateStr)}</Header>
                <List divided relaxed>
                  {
                    day.map(entry => <Notification key={"notification-" + entry.date} file={entry} />)
                  }
                </List>
              </React.Fragment>
            })
          }
          <Pagination activePage={this.state.page} totalPages={this.state.totalNumber} secondary onPageChange={(e, data) => this.getData(data.activePage)} />
        </>
      );
    }

    return <></>
  }

}

export default Updates

