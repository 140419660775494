//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component, } from 'react';
const QrReader = React.lazy(()=> import('@blackbox-vision/react-qr-reader').then(i => ({default: i.QrReader})))

import { ViewFinder } from './ViewFinder';
import {ViewError} from "./ViewError";
import {ViewResult} from "./ViewResult";
import LocalizedStrings from "../../localization/QrCodeSite";
import { Message } from 'semantic-ui-react';
import { Navigate } from 'react-router-dom';

class QrCodeReaderSite extends Component 
{
  constructor(props)
  {
      super(props);
      this.state = {
        qrcode:true,
        searchOpen:false,
        redirect:"",
        result:0
      }
  }
  render() 
  {
    console.log("this.state.redirect", this.state.redirect)
    return (
      <div style={{
        width: '100%',
        margin: 'auto',
      }}>
        {this.state.redirect ? <Navigate to={this.state.redirect}/> : ""}
      <QrReader 
        constraints={{
          aspectRatio: 1,
          facingMode: 'environment',
        }}
        ViewFinder={this.handleView()}
        videoStyle={{ height: 'auto' }}
        containerStyle={{ width: '100%', }}
        onResult={this.handleScan}/>
         <Message warning={this.state.result===2}>
          <Message.Header>{this.handleMessage()}</Message.Header>
        </Message>
      </div>
    );
  }

  handleView = () => {
    switch(this.state.result){
       case 1 : 
       return ViewResult ;
   
       case 2 : 
       return ViewError ;
   
       default:
          return ViewFinder;
    }
   }

 handleMessage = () => {
  switch(this.state.result){
     case 1 : 
     return  LocalizedStrings.Message_OK;
 
     case 2 : 
     return LocalizedStrings.Message_Error;
 
     default:
        return LocalizedStrings.Message_NotFound;
  }
 }

  handleScan = async (result, error) =>
  {
  
    if(result){
      this.setState({result:1})
    }

    let lib = await import('@zxing/library')

    if(error instanceof lib.ChecksumException){
      this.setState({result:2, errorText: "Warning"})
    }

    if (error instanceof lib.NotFoundException)
    {
      this.setState({result:0})
      
    }

    console.log("handleScan",result)
    if(result && this.props.onScan)
    {
      let path = await this.props.onScan(result.text)
      if(path)
      {
        setTimeout(()=>
        {
          this.setState({redirect: path})   
        }, 500)
      }
    }
  }

}

export default QrCodeReaderSite;

