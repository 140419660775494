//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component, } from 'react';
import { Progress, Header, Divider, Message } from 'semantic-ui-react';
import TaskEntryContainer from './TaskEntryContainer'
import LocalizedStrings from "../../localization/UserTaskPage";
import {TASK_STATE_NONE,
        TASK_STATE_PROGRESS,
        TASK_STATE_FINISHED,} from '../../util/defines'
import { globalCallbacks } from '../../util/callback';


class TaskOverview extends Component 
{
  constructor(props) {
    super(props)
    this.state = {
      worksheets: [],
      tasks:[],
      linkedProject: ""
    }
  }

  componentDidMount = async() => 
  {
    let linkedProject = await window.LR_GetLinkedProject()
    this.setState({linkedProject: linkedProject.Project})

    this.setUpCallbacks()

    globalCallbacks.updateTasks()
  }

  

  render() 
  {
    let worksheets = this.state.worksheets
    if(this.props.activeUser) 
    {
      worksheets = worksheets.filter(ws =>  ws.AssignedUsers.some(user => user.Name === this.props.activeUser) ||         // In Objects inside the worksheet
                                            ws.DefaultAssingedUsers.some(user => user === this.props.activeUser) ||  // In the worksheet
                                            false)
    }


    let inprogress = []
    let finished = []
    let next = []
    worksheets.forEach(ws => {

      let task = undefined
      if (this.state.tasks) { task = this.state.tasks.find(tsk => tsk.worksheet === ws.UUID)}

      if(task === undefined)
      {
        task = {
          state: TASK_STATE_NONE, 
          Worksheet: {...ws}
        }
      }

      switch(task.state)
      {
        case TASK_STATE_NONE : 
          next.push({Worksheet: ws, Task: task}); 
          break;
        
        case TASK_STATE_PROGRESS : 
          inprogress.push({Worksheet: ws, Task: task}); 
          break;
        
        case TASK_STATE_FINISHED : 
          finished.push({Worksheet: ws, Task: task}); 
          break;
        
        default                    : 
          break;
      }
    })

    return (
      <>
        {this.state.linkedProject 
          ? 
          <div className="UserTask">
            <Progress value={finished.length} total={worksheets.length} indicating progress='ratio'/>
            <Header as='h1'>{LocalizedStrings.Upcoming}</Header>
            <Divider />
              <TaskEntryContainer users={this.props.users} worksheets={next}/>
              <Header as='h1'>{LocalizedStrings.Current}</Header>
              <Divider />
              <TaskEntryContainer users={this.props.users} worksheets={inprogress}/>
              <Header as='h1'>{LocalizedStrings.DoneTask}</Header>
              <Divider />
              <TaskEntryContainer users={this.props.users} worksheets={finished}/>
          </div>
          :
          <Message positive>{LocalizedStrings.NoLinkedProjectMessage}</Message> 
        }
      </>
    );
  }

  setUpCallbacks() 
  {
    globalCallbacks.updateTasks = async(scrool) => {
      let resWs = await window.LR_GetWorksheets()
      let resTasks = await window.LR_GetProjectTasks()
      this.setState({ tasks: resTasks.Tasks, worksheets: resWs.Worksheets }, ()=>
      {
        if(scrool)
        {
          document.getElementById("TASK" + scrool).scrollIntoView();
        }
      })
    }
  }
}

export default TaskOverview
