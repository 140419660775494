//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component, } from 'react';
import { connect } from 'react-redux';
import { Grid, Menu, Loader, Dropdown, Container } from 'semantic-ui-react';
import { hasFetched, FetchProject } from '../../redux/actions/fetch_actions'
import { ACTIVE_PROJECT } from "../../redux/redux_defines"
import TaskOverview from './TaskOverview'
import LocalizedStrings from "../../localization/UserTaskPage";
import UserAvatar from '../WebComponents/UserAvatar';
import MediaQuery from 'react-responsive';
import { IsRunningAsBrowser, RESPONSIVE_PHONE } from '../../util/defines';
import { globalCallbacks } from '../../util/callback';

class UserTaskOverview extends Component 
{
  constructor(props) {
    super(props);
    this.state = {
      activeUser: undefined,
      Users: []
    }
  } 

  componentDidMount = async () =>
  {
    this.setUpCallBacks()
    globalCallbacks.RefetchUsersForTasks()
  }


  setUpCallBacks()
  {
    globalCallbacks.RefetchUsersForTasks = async ()  =>
    {
      let users   = await window.LR_GetUsers()
      this.setState({Users: users.Users, });
    }
  }
  
  setActiveUser = (user) => {
    this.setState({
      activeUser: user.UUID
    })
  }
  
  render() 
  {
    if(IsRunningAsBrowser()) FetchProject();

    return (
          <MediaQuery  minWidth={RESPONSIVE_PHONE}>

            {(isMobile) => {
              
             return(
              <div style={{width:"100%", maxHeight: !isMobile ? "calc(100vh - 7em)" : "calc(100vh - 20em)", overflowX:"hidden", overflowY:"auto", marginBottom:"1rem"}}>
              <div className="UserTaskOverview">
                {isMobile && IsRunningAsBrowser() ? this.renderBig() : this.renderMobile()}
              </div>
              </div>
             ) 
            } }


          </MediaQuery>
    );
  }

  renderBig()
  {
    let users = this.GetUsers()

    return (
      <Grid>
            <Grid.Column width={4} >
              <Menu secondary vertical>
                {!hasFetched(this.props.project) ? <Loader active/>
                                                : [ <UserEntry setActive={this.setActiveUser}
                                                                activeUser={this.state.activeUser} 
                                                                key={"ALL"}
                                                                user={{}}/>,
                                                    ...users.map(user => <UserEntry setActive={this.setActiveUser} 
                                                                                    key={user.Name}
                                                                                    activeUser={this.state.activeUser} 
                                                                                    user={user}/>)]}
              </Menu>
            </Grid.Column>
            <Grid.Column width={12}>
              <TaskOverview users={this.GetUserMap()} activeUser={this.state.activeUser}/>
            </Grid.Column>
          </Grid>
    )
  }

  renderMobile()
  {
    let users = this.GetUsers()
    let options = this.usersToOptions(users)
    return (
      <Container>
        <Dropdown fluid
                  button
                  onChange={(e, {value})=>{this.setActiveUser(value)}}
                  placeholder = {options[0].text}
                  options = {options}
                  style = {{marginBottom: "5px"}}/>
        <TaskOverview users={this.GetUserMap()} activeUser={this.state.activeUser}/>
      </Container>
    )
  }

  GetUserMap= () =>
  { 
    let userMap = {}
    for(let user of this.GetUsers())
    {
      userMap[user.UUID] = user
    }
    return userMap

  }

  GetUsers= () =>
  {
    if(IsRunningAsBrowser())
    {
      return  hasFetched(this.props.project) && this.props.project.data.members ? this.props.project.data.members.map(e=>e.user) : []
    }

    return this.state.Users;
  }

  usersToOptions = (users) => {
    let allUsers = {text   : <UserEntry setActive={this.setActiveUser}
                                        activeUser={this.state.activeUser} user={{}}/>,
                    key    : "allUsers",
                    value  : {}}

    // first entry is all users, then every user and parse components as text for better looks etc
    return [allUsers,
            ...users.map(user => {return {  text: <UserEntry  setActive={this.setActiveUser} 
                                                              activeUser={this.state.activeUser} 
                                                              user={user}/> ,
                                            key: user.UUID, 
                                            value: user}})]
  }
}


class UserEntry extends Component 
{
  render() 
  {
    let user = this.props.user

    return (<Menu.Item active={this.props.activeUser === user.UUID} 
                       onClick={()=>{this.props.setActive(user)}}>
              <UserAvatar user={user}/>
              {(user && user.Name) ? user.Name : LocalizedStrings.AllUsers}
            </Menu.Item>);
  }
}



//---------------------------------------------------------------------
// Redux Connection



const mapStateToProps = (state) => 
{
    return {
      project:  (state[ACTIVE_PROJECT])
    };
}

export default connect(mapStateToProps)(UserTaskOverview)
