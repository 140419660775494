//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------

import {lrServerConnection} from '../redux/light_right_server_connection' 
import Cookies  from "js-cookie";
import { LogOut, FetchActiveUser, ReFetchProjects, FetchShareLink, FetchProject } from '../redux/actions/fetch_actions';
import {v4 as uuidv4} from 'uuid';
import { OnLightRightCoreCallback } from '../util/callback';
import CONST from "../webApp/CONST"
import { IsRunningAsBrowser, MergeObjects } from '../util/defines';
import LocalizedStrings from "../localization/WebEditMode";
import { globalCallbacks } from "../util/callback";

let url = window.location.pathname
export let IsUsingWebassembly = (Cookies.get("access_token") || sessionStorage.getItem("last_sharetoken")) && !url.includes("/Plans") && !url.endsWith("/settings/account/billing") && window.SharedArrayBuffer
export class WEB_CORE_CLASS{
    constructor(){
        this.CallbackID = 0; 
        this.eventEmitter = {}
        this.initResolver = undefined
        this.isLoaded = new Promise(res => this.initResolver = res)
        this.onOpenFileCB = undefined //see in rxlightrightapp/src/webApp/uploadModal.tsx
        this.wasmLoaderData = undefined
        this.isFallbackLoaded = false
    }

    loadWasm(wasmLoaderData){
        this.EMCore = new Worker(new URL('./webassembly_main_worker.ts', import.meta.url))
        this.EMCore.onmessage = this.onMsgFromWorker.bind(this)
        this.wasmLoaderData = wasmLoaderData
        this.EMCore.postMessage({cmd: "load", data: wasmLoaderData})
    }

    async fallbackLoad(){
        this.isFallbackLoaded = true
        globalCallbacks.HideProgressBar?.() // this is buggy when blocking the main thread, so disable it
        this.EMCore.terminate() // dont need that anymore
        this.EMCore = new (await import("./webassembly_main_worker")).SafariPolyfill(this.onMsgFromWorker.bind(this))
        this.EMCore.postMessage({cmd: "load", data: this.wasmLoaderData})
    }

    onMsgFromWorker({data: workerData}){
        let {cmd, data} = workerData
        switch(cmd){
            case "StateIndicator":
                if(data?.message === "NestingError"){   // Safari also does not support Error serialization, so use a normal property here
                    console.error("Browser does not support nested workers, reverting to main thread")
                    this.fallbackLoad()
                }else if(data instanceof Error){
                    if(data instanceof RangeError){
                        alert(LocalizedStrings.WASMOutOfMemoryError)
                    }else{
                        throw data
                    }
                }else if(data === "Loaded"){
                    delete this.wasmLoaderData
                    this.initResolver()
                }
                break;
            case "SyncReturn":
                this.SyncCoreCB(data)
                break;
            case "AsyncReturn":
                this.AsyncCoreCB(data)
                break;
            case "OpenFile":
                this.openFile(data)
                break;
            case "SaveFile":
                this.saveFile(data)
                break;
            case "OpenLink":
                this.openLink(data)
                break;
            case "Alert":
                alert(data)
                break;
            default:
                console.warn(`Got unknown command from worker thread ${cmd}`)
                break;
        }
    }


    async SyncCoreCB(data) {
        let cb = this.eventEmitter[data.CallbackID]
        if(cb){
            cb(data.Argument)
            delete this.eventEmitter[data.CallbackID]
        }
    }

    async AsyncCoreCB(data) {
        this.SyncCoreCB(data)
        ipcRenderer.runCallBack("OnLightRightCoreCallback", undefined, data)
    }

    async coreCall(command, arg = {}){
        await this.isLoaded
        arg.CallbackID = this.CallbackID++;
        let p = new Promise(cb => this.eventEmitter[arg.CallbackID] = cb)
        
        this.EMCore.postMessage({cmd: "SyncCall", data: {command, arg}})

        let ret = await p
        return ret
    }


    async AwaitAsyncEvent(command, arg = {}){
        await this.isLoaded
        arg.CallbackID = this.CallbackID++;
        let p = new Promise(cb => this.eventEmitter[arg.CallbackID] = cb)
        
        this.EMCore.postMessage({cmd: "AsyncCall", data: {command, arg}})

        let ret = await p
        return ret
    }


    //Called from Core
    async openFile({type, command, payload}){
        
        let mime = await import("mime-types")
        type += "," + type.split(",").map(i => mime.lookup(i)).filter(i => i /*filter out failed lookups*/).join(",")
        if(!this.onOpenFileCB){
            return
        }

        let file = await this.onOpenFileCB(type)
        let reader = new FileReader()
        reader.readAsArrayBuffer(file)
        try{
            await new Promise((res, rej) => {
                reader.onload = ()=>{
                    
                    this.EMCore.postMessage({
                        cmd: "writeFile", 
                        data: {
                            callData: {
                                command,
                                arg: {...payload, Path: file.name}
                            },
                            fileData: {
                                name: file.name, 
                                view: new DataView(reader.result)
                            }
                        }})

                    res()
                }
                reader.onerror = rej
                reader.onabort = rej
            })  
        }catch(e){
            console.warn("unnable to read file", e)
        }
    }

    //Called from Core
    saveFile({path, blob}){
        try{
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.setAttribute(
                'download',
                path.slice(1),
            );
        
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            URL.revokeObjectURL(link.href)
        }catch{
            console.error(`unable to open file ${path}`)
        }
    }

    //Called from Core
    openLink(link){
        //Only open weblinks
        if(link.startsWith("http")){
            window.open(link)
        }
    }
}


if(IsRunningAsBrowser()){ //Preload WASM File only
    window.WEB_CORE = new WEB_CORE_CLASS()
    if(IsUsingWebassembly){
        let Sharetoken = sessionStorage.getItem("last_sharetoken")
        if(Sharetoken){
            Sharetoken = JSON.parse(Sharetoken)["token"] ?? window.getQuery("sharetoken")
        }
        let GlobalSettings = localStorage.getItem("GlobalSettings")
        if(GlobalSettings){
            try{
                GlobalSettings = JSON.parse(GlobalSettings)
            }catch(e){
                console.error("unnable to deserialize settings, discarding")
                localStorage.removeItem("GlobalSettings")
            }
        }

        window.WEB_CORE.loadWasm({BASE_URL: CONST.BASE_URL, GlobalSettings, Sharetoken})
    }
}


const registerdCallbacks  = []

lrServerConnection.setServerURL(CONST.BASE_URL, (m) => 
{
    OnLightRightCoreCallback({Command: 'project-changed',Argument:{Project: `${m.Owner}/${m.Project}`, By: JSON.parse(m.Payload).by}})
    ReFetchProjects()
});


export let ipcRenderer = 
{
    runCallBack: function(command, event, argument) 
    {
        console.log("runCallBack");
        registerdCallbacks.forEach( value => 
        {
            if(value.command === command) { value.func(event, argument) } 
        })
    },
    on: function(command, func) 
    {   
        if(registerdCallbacks.find(rc => rc.command === command && rc.func === func) === undefined) {
            registerdCallbacks.push({command: command, func: func})
        }
    },

    sendAsync: async function(command, ...args){
        if(!IsUsingWebassembly)
        {
            args[0].Async = false
            return await this.sendSync(command, ...args)
        }
        else
        {
            switch(command)
            {
                case 'LR_LoginToServerAsync':
                {
                    args[0].Async = false
                    return await this.sendSync(command, ...args)
                }
                case 'LR_ValidateUserNameAsync':
                {
                    args[0].Async = false
                    return await this.sendSync(command, ...args)
                }
                case 'LR_GetProjectsAsync':
                {
                    return lrServerConnection.getProjects( args[0])
                }
                case 'LR_GetCalculationPricing':
                {
                    console.log("LR_GetCalculationPricing", args)
                    return lrServerConnection.getPricingQuote( args[0])
                }
                case 'LR_GetUserAvatarAsync':
                    {
                        return lrServerConnection.getAvatar(args[0].User !== "" ? args[0].User : undefined)
                    }
                default:
                {  
                    return await window.WEB_CORE.AwaitAsyncEvent(command, args[0])
                }
            }
        }
        
    },

    sendSync: async function(command, ...args) 
    {
        if(!IsUsingWebassembly && !window.location.pathname.includes("/Plans") && !window.location.pathname.endsWith("/settings/account/billing")){
            //try to reload page if switched back from a non-WASM area
            if(localStorage.getItem("WASM_reload_tried") !== "true"){
                localStorage.setItem("WASM_reload_tried", "true")
                window.location.reload()
            }
        }else{
            localStorage.setItem("WASM_reload_tried", "false")
        }
        
        let [argument1, argument2] = args
        switch(command)
        {
            case 'LR_ValidateToken':
            {
                let Token = Cookies.get("access_token")
                let User = localStorage.getItem("username")
                let Licence = localStorage.getItem("licence")
                if(!Token || !User || !Licence) {return {Valid: false}}
                
                let t = await lrServerConnection.getToken(Token)
                if(t && IsUsingWebassembly){
                    await window.WEB_CORE.coreCall("LR_LoginStoredData", {Token, User, Licence})
                }

                if(t){
                    lrServerConnection.setToken(Token)
                    FetchActiveUser()
                    lrServerConnection.setLoggedIn(User)
                }
                return {Valid: t}
            }
            case 'LR_LoginToServerAsync':
            {
                let t = await lrServerConnection.login(argument1.User, argument1.Password)
                if(t?.token){
                    let domain = CONST.DEVELOPMENT ? undefined : (new URL(CONST.BASE_URL).hostname)
                    let secure = !CONST.DEVELOPMENT 
                    Cookies.set("access_token", t.token, { domain, secure })
                    localStorage.setItem("username", t.username)
                    localStorage.setItem("licence", t.Licence)
                    lrServerConnection.setToken(t.token)
                    FetchActiveUser()
                    window.location.reload()
                }
                return { token: t?.token }
            }
            case 'LR_ValidateUserNameAsync':
            {
                let res = await lrServerConnection.validateUserName(argument1.User)
                return { OK: res }
            }
            case 'LR_LogOutFromServer':
            {
                lrServerConnection.setToken(null)
                let domain = CONST.DEVELOPMENT ? undefined : new URL(CONST.BASE_URL).hostname 
                let secure = !CONST.DEVELOPMENT 
                Cookies.remove("access_token", { domain, secure})
                localStorage.removeItem("username")
                localStorage.removeItem("licence")
                LogOut()
                ipcRenderer.runCallBack('OnLightRightCoreCallback', undefined, {Command:'Log-Out-From-App', Argument: {}});
                return
            }
            case 'LR_RunOpenSettings':
            {
                ipcRenderer.runCallBack('OnLightRightCoreCallback', undefined, {Command:'open-settings', Argument: {}});
                return
            }
            case 'LR_GetCalculationPricing':
            {
                return lrServerConnection.getPricingQuote( args[0])
            }

            case 'LR_SetGlobalSettings':
            {
                let before = localStorage.getItem("GlobalSettings")
                if (before)
                {
                    before = JSON.parse(before)                
                }
                else
                {
                    before = await window.LR_GetDefaultGlobalSettings()
                }

                let out = {...before.GlobalSettings, ...argument1}


                localStorage.setItem("GlobalSettings", JSON.stringify({GlobalSettings: out}))
                if(IsUsingWebassembly){
                    await window.WEB_CORE.coreCall(command, out)
                }
                return
            }
            case 'LR_GetProjectSettings':
            {
                // Don't call this via
                return undefined
            }
            case 'LR_GetGlobalSettings':
            {
                if(IsUsingWebassembly)
                {
                    return await window.WEB_CORE.coreCall(command, argument1)
                }
                else
                {
                    let defaultSettings = await window.LR_GetDefaultGlobalSettings()
                    let cookie = localStorage.getItem("GlobalSettings")
                    
                    if (cookie)
                    {
                        cookie = JSON.parse(cookie)
                        return MergeObjects([defaultSettings, cookie])
                    }
                    else
                    {
                        return defaultSettings
                    }
                }
                
            }
            case 'LR_GetCollaboratorsAsync':
            {
                return lrServerConnection.getCollaborators(argument1)   
            }
            case 'LR_GetSymbolMapTemplates':
                return lrServerConnection.getSymbolMapTemplates(argument1).then(arr => ({templates: arr, selected: ""}))
            case 'LR_GetOrganizationsAsync':
            {
                return lrServerConnection.getUserOrgs(argument1)
            }
            case 'LR_GetProjectsAsync':
            {
                return lrServerConnection.getProjects(argument1)
            }
            case 'LR_CreateBranchAsync':
            {
                return lrServerConnection.createBranch(argument1)   
            }
            case 'LR_GetUserAvatarAsync':
            {
                return lrServerConnection.getAvatar(argument1.User !== "" ? argument1.User : undefined)
            }
            case 'LR_GetLoggedInUser':
            {
                return new Promise(res => res({User: lrServerConnection.__LOGGEDINUSER}))
            }
            case `LR_ShowCreateDrawingNote`:
            {
                return ipcRenderer.runCallBack('OnLightRightCoreCallback', undefined, {Command:'show-create-note', Argument: argument1});
            }
            case `LR_ShowShareDialog`:
            {
                return ipcRenderer.runCallBack('OnLightRightCoreCallback', undefined, {Command:'show-share-modal', Argument: argument1});
            }
            case `LR_GetShareLinksForProject`:
            {
                return lrServerConnection.getShareLinks(argument1)  
            }
            case `LR_AddNewNote`:
            {
                
                if(IsUsingWebassembly){
                    return await window.WEB_CORE.coreCall(command, argument1)
                }else{
                    const note = {
                        LinkedObject: argument1.LinkedObject,
                        Note: argument1.Note,
                        User: lrServerConnection.__LOGGEDINUSER,
                        CreateDate: Date.now(),
                        Visibility: 0,
                        UUID: uuidv4()
                    }
                    return lrServerConnection.createNewNote(note)
                }
            }
            case 'get-project' : 
            {
                return lrServerConnection
                       .setProject(argument1)
                       .getProject()
            }
            case 'remove-user-from-project' : 
            {
                return lrServerConnection.removeUser(argument1)
            }
            case 'get-all-users-from-server' : 
            {
                return lrServerConnection.getAllUsers()
            }
            case 'add-user-to-project' : 
            {
                return lrServerConnection.addUser(argument1)
            }
            case 'reset-password' :
            {
                return lrServerConnection.resetPassword(argument1, argument2)
            }
            case 'delete-project' :
            {
                return lrServerConnection.removeProject()   
            }
            case 'change-password' :
            {
                return lrServerConnection.changePassword(argument1, argument2)   
            }
            case 'open-editmode':
            {
                return lrServerConnection.openEditMode()
            }
            case 'close-editmode':
            {
                return lrServerConnection.closeEditMode()
            }
            case 'web-commit':
            {
                return lrServerConnection.commit()
            }
            case 'add-collaborator':
            {
                return lrServerConnection.addCollaborator(argument1)
            }
            case 'remove-collaborator':
            {
                return lrServerConnection.removeCollaborator(argument1)
            }
            case 'LR_SetProjectSettings':
            {
                let ret = await lrServerConnection.setProjectSettings(argument1)
                FetchProject(true);
                return ret
            }                     
            case 'set-slack-channel':
            {
                return lrServerConnection.setSlackChannel(argument1)
            }
            case 'create-project':
            {
                return lrServerConnection.createNewProject(argument1)
            }
            case 'set-avatar':
            {
                return lrServerConnection.setAvatar(argument1)
            }
            case 'LR_GetProjectTasks':
            {
                return lrServerConnection.getProjectTasks()
            }
            case 'LR_GetWorksheetTasks':
            {
                return lrServerConnection.getWorksheetTask(argument1)
            }
            case 'LR_SwitchTaskState':
            {
                return lrServerConnection.switchTaskState(argument1)
            }
            case 'LR_AddCheckToProject':
            {
                return lrServerConnection.addCheckToProject(argument1)
            }
            case 'LR_AddCheckToUser':
            {
                return lrServerConnection.addCheckToUser(argument1)
            }
            case 'LR_ToggleProjectOwnerChecks':
            {
                return lrServerConnection.toggleProjectOwnerChecks(argument1)
            }
            case 'LR_EditScriptInCheck':
            {
                return lrServerConnection.editScriptInCheck(argument1)
            }
            case 'LR_EditCheckName':
            {
                return lrServerConnection.editCheckName(argument1)
            }
            case 'LR_RemoveCheckFromProject':
            {
                return lrServerConnection.removeCheckFromProject(argument1)
            }
            case 'LR_RemoveCheckFromUser':
            {
                return lrServerConnection.removeCheckFromUser(argument1)
            }
            case 'LR_RemoveCheck':
            {
                return lrServerConnection.removeCheck(argument1)
            }
            case 'LR_GetChecksFromProject':
            {
                return lrServerConnection.getChecksFromProject(argument1)
            }
            case 'LR_GetChecksFromUser':
            {
                return lrServerConnection.getChecksFromUser(argument1)
            }
            case 'LR_GetReviewsFromUser':
            {
                return lrServerConnection.getUserReviewTemplates(argument1)
            }
            case 'LR_GetGroupsFromUser':
            {
                return lrServerConnection.getGroups(argument1)
            }
            case 'LR_GetCheckByID':
            {
                return lrServerConnection.getCheckByID(argument1)
            }
            case 'LR_GetProjectOwnerChecks':
            {
                return lrServerConnection.getProjectOwnerChecks(argument1)
            }
            case 'LR_NewSymbolMapTemplate':
            {
                return lrServerConnection.createSymbolMapTemplate(argument1)
            }
            case 'LR_GetBaseURL':
            {
                return {
                    URL: CONST.BASE_URL
                }
            }
            case 'LR_GetFoldersAsync':
            {
                let res = await lrServerConnection.getUserInfo(argument1)
                if(Array.isArray(res.folders) )
                {
                    return res.folders
                }
                return []
            }
            
            case 'LR_CreateShareLinkForProject':
            {
                let res = await lrServerConnection.createShareLink(argument1.Name)
                FetchShareLink(true)
                return res
            }
            
            case 'LR_GetLinkedProject':
                {
                    return {
                        Project: lrServerConnection.__PROJECT,
                        Owner: lrServerConnection.__USERNAME,
                        Branch: lrServerConnection.__BRANCH,
                    }
                }
            case 'LR_FindUserSubset':
            {
                return lrServerConnection.findUserSubset(argument1)
            }
            case 'LR_GetNotesForObjects': 
            {
                return lrServerConnection.getNotesForObjects(argument1)
            }
            default:
            {
                // This here is a little special. When you are in the browser, sometimes there is no valid active project
                // So we pipe it not thru the wasm core, but the actual websockets. In this case it automattic is got by the 
                // webserver directly handeling it right
                if(command === "LR_GetSymbolMap" && args[0].UserBased)
                {
                    return await lrServerConnection.coreCall(command, argument1)
                }

                if(IsUsingWebassembly)
                {
                    return await window.WEB_CORE.coreCall(command, args[0])
                }
                else
                {
                    return await lrServerConnection.coreCall(command, argument1)
                }
            }
        }
    }
}