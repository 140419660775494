import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './new-nav.css';
import LocalizedStrings from "../../localization/ProductionAssistLandingPage";
import IMG_LOGO from "../../content/img/applogo_white.png";
import { Icon } from 'semantic-ui-react';


class NewNavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isMenuOpen: false
        };
      }
    
      toggleMenu = () => {
        this.setState(prevState => ({
          isMenuOpen: !prevState.isMenuOpen
        }));
      };
    
      render() {
        const { isMenuOpen } = this.state;
        return (
          <div className="new-nav">
            <div className={`hamburger-menu ${isMenuOpen ? 'open' : ''}`} onClick={this.toggleMenu}>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div className="logo">
                <Link to="/">
                  <img src={IMG_LOGO} alt="Logo" />
                </Link>
            </div>
            <div className={`menu-items ${isMenuOpen ? 'open' : ''}`}>
              <div className="dropdown">
                <p className="drop">{LocalizedStrings.KnowledgeCenter} <Icon name="arrow right" size='small' float="left" /></p>
                <div className="dropdown-content">
                  <Link to="/documentation">{LocalizedStrings.StartPage_LinkDokumentation}</Link>
                  <Link to="/video-tutorial">{LocalizedStrings.Header_Tutorial}</Link>
                  <Link to="/knowhow">{LocalizedStrings.Header_KnowHow}</Link>
                  <Link to="/faq">{LocalizedStrings.Header_FAQ}</Link>
                </div>
              </div>
              <Link className='pricepage' to="/pricing">{LocalizedStrings.Pricing}</Link>
              <Link to="/download">{LocalizedStrings.Header_Download}</Link>
              <Link to="https://meetings.engagebay.com/MoritzStaffel">{LocalizedStrings.DemoRequest}</Link>
              <Link to="/aboutus">{LocalizedStrings.Header_AboutUs}</Link>
              <Link className='mobile' to="/register">{LocalizedStrings.Header_Register}</Link>
            </div>
            <div className="auth-buttons">
              <Link to="/login"><button>{LocalizedStrings.Header_Login}</button></Link>
              <Link to="/register"><button>{LocalizedStrings.Header_Register}</button></Link>
            </div>
          </div>
        );
      }
}

export default NewNavBar;
