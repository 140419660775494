import React from "react";
import DateHelper from '../../util/DateHelper';
import { cie2hex } from '../ColorPicker/utilities';
import { Input } from 'semantic-ui-react'

let CalendarPlugins = []

const FullCalendar = React.lazy(async ()=>{

    let c = await import('@fullcalendar/react')
    CalendarPlugins = (await Promise.all([
    import('@fullcalendar/daygrid'),
    import('@fullcalendar/interaction'),
    import('@fullcalendar/resource-timegrid')
  ])).map(i => i.default)

  return c

})

import LocalizedStrings from "../../localization/SceneTimeLineComponent";
import PresetSelection from '../SceneTimeLine/PresetSelection'
import { EMPTY_UUID } from "../../util/defines";
import { formatDateToTimeOnly } from "../Basics/BasicUnitInput";
import { connect } from "react-redux";
import { GLOBAL_SETTINGS } from "../../redux/redux_defines";
import { globalCallbacks } from "../../util/callback";


class ResourceSchedule extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      users: [],
      events:[],
    };

  
  }

  componentDidMount = async () => 
  {
    globalCallbacks.getResourceScheduleUsers = async () =>
    { 
      let users     = await window.LR_GetUsers()
  
  
      if(users)
      {
          this.setState({users: users.Users})
      }
   }

   globalCallbacks.getWorksheetForResourceSchedule = async () =>
   {
    let workSheetArray = await window.LR_GetWorksheets();
    if(workSheetArray)
    {
        this.setState({worksheet: workSheetArray.Worksheets})
    }
   }

    await globalCallbacks.getResourceScheduleUsers()
    await globalCallbacks.getWorksheetForResourceSchedule()

    let globalSettings = await window.LR_GetGlobalSettings()

   if(globalSettings)
    {
        this.setState({timeSettings: globalSettings.GlobalSettings.TimeFormat})
    }

    window.resizeBy(-1, -1);
  }


  render() {

    let events        = this.getData().events;
    let usersResource = this.getData().usersResource;
    let initialDate   = this.props.timePhases.TimePhaseChangeObjects[0]?.StartDate ? this.props.timePhases.TimePhaseChangeObjects[0]?.StartDate : ""

    return (
            <FullCalendar
              height                = "100vh"
              contentHeight         = "auto"
              handleWindowResize    = {true}
              id                    = "calendar"
              initialView           = 'resourceTimeGridDay'
              initialDate           = {initialDate}
              aspectRatio           = {1.5}
              timeZone              = "local"
              editable              = {true}
              allDaySlot            = {false}
              plugins               = {CalendarPlugins}
              resources             = {usersResource}
              events                = {events}
              schedulerLicenseKey   = '0672423720-fcs-1659451798' 
              eventResize           = {(event) => this.handleResizeEvent(event)}
              eventDrop             = {(event) => this.handleMoveChange(event)}
              dateClick             = {(event) => {this.handleAddStepClick(event); document.activeElement.blur()}}
              eventContent          = {(event) => this.handleEventRender(event)}
              eventClick            = {(event) => this.handleOpenWorksheetClick(event)}
              resourceLabelContent  = {(event) => this.handleResourceRender(event)}
          
              slotLabelFormat       = {(e)=>formatDateToTimeOnly(new Date(e.date.marker), this.props.globalSettings.TimeFormat, this.props.globalSettings.Language.Value)}
            />
    );
  }

  handleEventRender(event)
  {
   
    if(event.event._def.extendedProps.worksheet)
    {
        return {html:` ${event.event.title}   <i class='file icon' aria-hidden='true'/>`}
    }else
    {
        return (
            <div>
                <SchedudeEventNode  timeText={event.timeText}  
                                    id={`${event.event._def.resourceIds}${event.event._def.extendedProps.stepUUID}`} 
                                    eventTitle={event.event.title} 
                                    event={event.event._def.extendedProps.step}
                 />
          </div>
        )
    }
  }

  handleResourceRender(event)
  {
    return  <SchedudeResourceNode  event = {event}/>
      
  }

  handleOpenWorksheetClick(event)
  {
    if(event.event._def.extendedProps.worksheet)
    { 
        window.LR_ShowWorkSheet({UUID: event.event._def.extendedProps.worksheet.UUID})
    }
  }

  handleResizeEvent(e)
  {
    let isWorkSheetEvent  = e.event._def.extendedProps.isFromWorkSheet;

    if(isWorkSheetEvent)
    {
        let worksheet = e.event._def.extendedProps.worksheet
 
        worksheet.TimeDuration += new DateHelper(e.endDelta.milliseconds).getAbsoluteFrameCount()

        window.LR_SetWorksheet(worksheet)
    }else
    {
        let newStep;
        let changedStep       = this.props.timePhases.TimePhaseChangeObjects.find(step => step.Name === e.event._def.title);
        let newStartDate      = new Date(new Date(changedStep.StartDate).getTime() + e.startDelta.milliseconds).toISOString();
        let newEndDate        = new Date(new Date(changedStep.EndDate).getTime() + e.endDelta.milliseconds).toISOString();
        let timelineStartDate = changedStep.TimelineStartDate;
        let newStartCode      = new DateHelper(timelineStartDate).getTimeDiffInFrames(new DateHelper(newStartDate))
        let newTimeCode       = new DateHelper(timelineStartDate).addRelativeFrameCount(newStartCode).getTimeDiffInFrames(new DateHelper(newEndDate))
    
    
        newStep = {
            ...changedStep,
            StartDate    : e.event._instance.range.start.toISOString(),
            EndDate      : e.event._instance.range.end.toISOString(),
            TimeStart    : newStartCode,
            TimeCode     : newTimeCode,
        }
    
        window.LR_SetTimePhaseChange(newStep);
    }

    globalCallbacks.getResourceScheduleUsers()
 
 
  }

  handleMoveChange(e)
  {
    
    let isWorkSheetEvent  = e.event._def.extendedProps.isFromWorkSheet;

    let stepAssignedUsers    = e.event._def.extendedProps.stepAssignedUsers;
    let newstepAssignedUsers = [...stepAssignedUsers];
    let newAssignedUser      = e.event._def.resourceIds[0];
    let oldAssignedUser      = e.oldEvent._def.resourceIds[0];
    let oldAssignedUserIndex = stepAssignedUsers.indexOf(oldAssignedUser);

    if(e.jsEvent.altKey)
    {
        newstepAssignedUsers.push(newAssignedUser)
    }else{
        if(oldAssignedUserIndex !== -1)
        {
            newstepAssignedUsers.splice(oldAssignedUserIndex, 1, newAssignedUser)
        }
    }

    if(isWorkSheetEvent)
    {
        let worksheet = e.event._def.extendedProps.worksheet; 
        worksheet.OffsetFromStart += new DateHelper(e.delta.milliseconds).getAbsoluteFrameCount()
        worksheet.DefaultAssingedUsers = newstepAssignedUsers

        window.LR_SetWorksheet(worksheet)
    }else
    {
        let newStep;
        let changedStep       = this.props.timePhases.TimePhaseChangeObjects.find(step => step.Name === e.event._def.title);
        let newStartDate      = new Date(new Date(changedStep.StartDate).getTime() + e.delta.milliseconds).toISOString();
        let newEndDate        = new Date(new Date(changedStep.EndDate).getTime() + e.delta.milliseconds).toISOString();
        let timelineStartDate = changedStep.TimelineStartDate;
        let newStartCode      = new DateHelper(timelineStartDate).getTimeDiffInFrames(new DateHelper(newStartDate))
        let newTimeCode       = new DateHelper(timelineStartDate).addRelativeFrameCount(newStartCode).getTimeDiffInFrames(new DateHelper(newEndDate))
    
        newStep = {
            ...changedStep,
            StartDate    : e.event._instance.range.start.toISOString(),
            EndDate      : e.event._instance.range.end.toISOString(),
            TimeStart    : newStartCode,
            TimeCode     : newTimeCode,
            AssingedUsers: newstepAssignedUsers
        }
    
        window.LR_SetTimePhaseChange(newStep);
    }
    
  }

  handleAddStepClick = async (e) =>
  {
      if(e.jsEvent.detail === 2)
      {
          let newTimeStart = new DateHelper(this.props.timePhases.StartDate)
          
          await window.LR_AddNewTimePhaseChange({ 
              UUID : this.props.timePhases.UUID,
              AssingedUsers: [e.resource._resource.id],
              TimeStart: newTimeStart.getTimeDiffInFrames(new DateHelper(e.date)),
              TimeCode: DateHelper.SecondsToFrameCount(60*60)
         });  
      }
  }


  getData = () =>
  {
    let usersResource = [] ;
    let steps = [];
    let worksheetSteps = [];
    let stepsUUID = [];
  
    //----------------------------------------------------------------------------------------------------
    // Get data from timeline

    for(let step of this.props.timePhases.TimePhaseChangeObjects){
        stepsUUID.push(step.UUID)

        let assingedUsers = step.AssingedUsers;

    //----------------------------------------------------------------------------------------------------
    // When there is no user, add step for general user
        if(step.AssingedUsers.length === 0 && this.state.users.length === 0)
        {
            steps.push({
                title: step.Name,
                start: step.StartDate,
                end: step.EndDate,
                resourceId: EMPTY_UUID, 
                overlap:true,
                isFromWorkSheet:false,
                stepUUID:step.UUID,
                stepAssignedUsers:assingedUsers,
                eventStartEditable:true,
                editable:false,
                step    : step,
                color: cie2hex({fx: step.Color.X, fy: step.Color.Y, f_Y: step.Color.Z}) === "#ffffff" ? "" : cie2hex({fx: step.Color.X, fy: step.Color.Y, f_Y: step.Color.Z})
            })
        }

        if(assingedUsers.length > 0)
        {
            for(let user of assingedUsers){
                steps.push({
                    title: step.Name,
                    start: step.StartDate,
                    end: step.EndDate,
                    resourceId: user, 
                    overlap:true,
                    isFromWorkSheet:false,
                    stepUUID:step.UUID,
                    stepAssignedUsers:assingedUsers,
                    eventStartEditable:true,
                    editable:true,
                    step    : step,
                    color: cie2hex({fx: step.Color.X, fy: step.Color.Y, f_Y: step.Color.Z}) === "#ffffff" ? "" : cie2hex({fx: step.Color.X, fy: step.Color.Y, f_Y: step.Color.Z})
                })
            }
        }else
        {
            if(this.state.users)
            {
                for(let user of this.state.users){
                    steps.push({
                        title: step.Name,
                        start: step.StartDate,
                        end: step.EndDate,
                        resourceId: user.UUID, 
                        overlap:true,
                        editable:true,
                        isFromWorkSheet:false,
                        stepUUID:step.UUID,
                        stepAssignedUsers:assingedUsers,
                        eventStartEditable:true,
                        step    : step,
                        color: cie2hex({fx: step.Color.X, fy: step.Color.Y, f_Y: step.Color.Z}) === "#ffffff" ? "" : cie2hex({fx: step.Color.X, fy: step.Color.Y, f_Y: step.Color.Z})
                        
                    })
                }
            }
        }
    }

    if(this.state.users)
    {
    //----------------------------------------------------------------------------------------------------
    // When there is no user, add general user
    
        if(this.state.users.length === 0)
        {
            usersResource.push({id: EMPTY_UUID, title: LocalizedStrings.GeneralUser})
        }else
        {
            usersResource = this.state.users.map(user =>{ return {id: user.UUID, title: user.Name}; })
        }
        
    }

   

    //----------------------------------------------------------------------------------------------------
    // Get data from worksheet

    if(this.state.worksheet)
    {
        this.state.worksheet.forEach(task => {
      
            let parentTaskUUID              = task.LinkedTimeLinePhaseChange;
            let isParentTaskExist           = stepsUUID.findIndex(uuid => uuid === parentTaskUUID);
 

            if(isParentTaskExist !== -1)
            {
                let worksheetAssingedUsers = task.DefaultAssingedUsers;
                
    //----------------------------------------------------------------------------------------------------
    // When there is no user, add worksheet step for general user
    
                if(this.state.users.length === 0 && task.AssignedUsers[0].Name === EMPTY_UUID)
                {
                    worksheetSteps.push({
                        title                : task.Name,
                        start                : new DateHelper(task.LinkedTimeLinePhaseChangeObject.StartDate).addRelativeFrameCount(task.OffsetFromStart).toISOString(),
                        end                  : new DateHelper(task.LinkedTimeLinePhaseChangeObject.StartDate).addRelativeFrameCount(task.OffsetFromStart).addRelativeFrameCount(task.TimeDuration).toISOString(),
                        resourceId           : EMPTY_UUID,
                        stepAssignedUsers    : worksheetAssingedUsers,
                        overlap              : true,
                        color                : "red",
                        isFromWorkSheet      : true,                         
                        worksheet            : task,
                        startEditable        : true,
                        eventDurationEditable: true
                    }) 
                }

                if(worksheetAssingedUsers.length > 0 && this.state.users.length > 0)
                {
                    task.DefaultAssingedUsers.forEach(assingedUser => {
                        worksheetSteps.push({
                            title                : task.Name,
                            start                : new DateHelper(task.LinkedTimeLinePhaseChangeObject.StartDate).addRelativeFrameCount(task.OffsetFromStart).toISOString(),
                            end                  : new DateHelper(task.LinkedTimeLinePhaseChangeObject.StartDate).addRelativeFrameCount(task.OffsetFromStart).addRelativeFrameCount(task.TimeDuration).toISOString(),
                            resourceId           : assingedUser,
                            stepAssignedUsers    : worksheetAssingedUsers,
                            overlap              : true,
                            color                : "red",
                            isFromWorkSheet      : true,                         
                            worksheet            : task,
                            startEditable        : true,
                            eventDurationEditable: true
                        }) 
                    })
                }else
                {
                    if(this.state.users)
                    {
                     
                        this.state.users.forEach((user) => {
                            worksheetSteps.push({
                                title                : task.Name,
                                start                : new DateHelper(task.LinkedTimeLinePhaseChangeObject.StartDate).addRelativeFrameCount(task.OffsetFromStart).toISOString(),
                                end                  : new DateHelper(task.LinkedTimeLinePhaseChangeObject.StartDate).addRelativeFrameCount(task.OffsetFromStart).addRelativeFrameCount(task.TimeDuration).toISOString(),
                                resourceId           : user.UUID,
                                stepAssignedUsers    : worksheetAssingedUsers,
                                overlap              : true,
                                color                : "red",
                                isFromWorkSheet      : true,                   
                                worksheet            : task,
                                startEditable        : true,
                                eventDurationEditable: true
                            })
                        })
                    }
                }

                
            }

        })
    }

    steps = [...steps, ...worksheetSteps]
 
  

  return {
      events: steps,
      usersResource:usersResource
  }
  
  }
}

const mapStateToProps = (state) => 
{
    return {
      globalSettings: state[GLOBAL_SETTINGS].data.GlobalSettings,
    };
}

export default connect(mapStateToProps)(ResourceSchedule)

class SchedudeEventNode extends React.Component 
{
    constructor(props)
    {
        super(props);

        this.state = {
            eventTitle:null,

            editStepModal:
            {
                open: false,
                uuid: props.event.UUID,
                type: "step"
            }
        }
    }

  

    render()
    {
        return (
          <div  
          onMouseLeave= {() => {if(this.state.eventTitle && this.state.eventTitle !== this.props.eventitle){this.handleBlur();}}}
          onDoubleClick = {() => {  this.openEditStepModal(); }}       
          >
            <PresetSelection data={this.state.editStepModal} onClose={()=>this.setState({editStepModal: {...this.state.editStepModal, open: false}})}/>
            <p>{this.props.timeText}</p>
            <Input  id={this.props.id}
                    type          = "text"
                    style         = {{marginLeft: "5.5rem", marginTop: "-2rem"}}
                    inverted
                    transparent
                    name          = "eventTitle" 
                    value         = {this.state.eventTitle ?? this.props.eventTitle} 
                    onChange      = {this.handleChange} 
                    onClick       = {(e) => {document.getElementById(this.props.id).focus()}}
                    fluid
                    onKeyDown     = {(e) => {e.persist();if(e.keyCode === 32){this.setState({eventTitle: e.target.value + " "})};}}
                    onDoubleClick = {(event) => { document.activeElement.select(); this.openEditStepModal(); 
                        event.preventDefault()
                        event.stopPropagation();
                        event.stopImmediatePropagation();
                        }}          
            />
        </div>
        )
    }

    handleChange = (e) =>
    {
        this.setState({eventTitle: e.target.value}); 
    }

    handleBlur = () =>
    {
        let newStep = this.props.event;
        newStep.Name = this.state.eventTitle ? this.state.eventTitle  : this.props.eventTitle;
        window.LR_SetTimePhaseChange(newStep);
    }

    openEditStepModal = () =>
    {
        let editStepModal =
        {
            ...this.state.editStepModal,
            open: true
        }
        this.setState({ editStepModal });
    }
}

class SchedudeResourceNode extends React.Component 
{
    constructor(props)
    {
        super(props);

        this.state = {
            resourceTitle: null
        }
    }
    render()
    {
        let userId = this.props.event.resource._resource.id;
        
        return (
            
                userId === EMPTY_UUID 
                ?  
                    <p>{this.props.event.resource._resource.title}</p>
                :
                    <input  
                        value    = {this.state.resourceTitle ?? this.props.event.resource._resource.title}
                        style    = {{border:"none", outline:"none"}}  
                        onChange = {(e) => this.setState({resourceTitle : e.target.value})}
                        onBlur   = {() => this.updateName()}
                    />
            
        )
    }

    updateName = () =>
    {
       let request  = { UUID : this.props.event.resource._resource.id}
       request.Name = this.state.resourceTitle ?? this.props.event.resource._resource.title

       window.LR_SetUser(request);
    }
}
