
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Table, Icon, Input, Popup, Form, Header, Grid, Button, Segment } from 'semantic-ui-react'
import LRModal from '../Basics/BasicModal';
import LocalizedStrings from "../../localization/NavigationContainer";
import UnitInput from '../Basics/BasicUnitInput';
import BasicSelect from '../Basics/BasicSelect';
import { BASE_UNIT_ANGLE, BASE_UNIT_BOOLEAN, BASE_UNIT_FORCE_PER_AREA, BASE_UNIT_LENGTH, BASE_UNIT_NUMBER, BASE_UNIT_VELOCITY, BASE_UNIT_WEIGHT_PER_DISTANCE } from '../../util/defines';
import IMG_Windzone_France from "../../content/img/windloadzones_fr.png"
import IMG_Windzone_Germany from "../../content/img/windloadzones_de.png"
import IMG_Windzone_England from "../../content/img/windloadzones_uk.png"
import IMG_Windzone_Netherlands from "../../content/img/windloadzones_nl.png"
import IMG_Windzone_UnitedStates from "../../content/img/windloadzones_us.png"


class CaseNode extends Component 
{
    constructor(props)
    {
        super(props);
        this.state = {
            ...this.props.entry,
            openModal: false,
            LoadGroups: [],
            LayersCableLoad: [],
            Layers: [],
            avChecks: [],
            HeaderLoadGroupFactors: true
        };
    }

    async getCheckData(){
        let checks = []
        let userChecks = []
        let ownerChecks = []
        let project = await window.LR_GetLinkedProject()

        // get checks
        if (project.Project !== "")
        {
          ownerChecks = await window.LR_GetProjectOwnerChecks()
          
          let checksObj = await window.LR_GetChecksFromProject()
          checks = checksObj.checks
          userChecks = checksObj.userChecks
        }
        else 
        {
          checks = await window.LR_GetChecksFromUser() 
        }

        let avChecks = []

        if(checks.length){
            avChecks.push({
                key: "Header0",
                text: "Project Checks",
                isHeader: true
            })

            for(let i of checks){
                avChecks.push({
                    key: i.id,
                    value: i.id,
                    text: i.name
                })
            }
        }

        if(userChecks.length){
            avChecks.push({
                key: "Header1",
                text: "User Checks",
                isHeader: true
            })

            for(let i of userChecks){
                avChecks.push({
                    key: i.id,
                    value: i.id,
                    text: i.name
                })
            }
        }


        // deduplicate, so only owner Checks that dont belong to the current user are shown
        ownerChecks = ownerChecks.filter(i => !userChecks.some(j => j.id === i.id))

        if(ownerChecks.length){
            avChecks.push({
                key: "Header2",
                text: "Project Owner Checks",
                isHeader: true
            })

            for(let i of ownerChecks){
                avChecks.push({
                    key: i.id,
                    value: i.id,
                    text: i.name
                })
            }
        }

        for(let i of this.state.ChecksToRunIDs){
            let t = avChecks.find(j => j.key === i)
            if(t){
                t.selected = true
            }
        }

        this.setState({avChecks})
    }

    componentDidUpdate(oldProps, prevState) 
    {
        const newProps = this.props;
        for(const [name, value] of Object.entries(newProps.entry))
        if(oldProps.entry[name] !== value) 
        {
            this.setState({[name]: value});
        }
        
        if(this.state.openModal && prevState.openModal !== this.state.openModal){
            this.getCheckData()   
        }
    }

    render() 
    {
        let entry = this.state;

        if(this.state.IsSettings && !this.props.settings)
        {
            return null
        }

        return (
            <>
            {this.props.settings ? <>
            <Segment>
            <Button compact primary onClick={this.openEditModal} fluid>{LocalizedStrings.LoadCombinationSettings}</Button>
            <Form>
            <UnitInput  name="WindAngle" 
                label={LocalizedStrings.WindAngle}
                compact
                baseUnit={BASE_UNIT_ANGLE} 
                value={this.state.WindAngle} 
                onStateUpdate  = {(name, val) => {this.updateData("WindAngle", val)}} 
                onUpdateData={(name, val) => {this.updateData("WindAngle", val)}}
                />
                <UnitInput  
                    label={LocalizedStrings.WindSpeed}
                    compact
                    baseUnit      = {BASE_UNIT_VELOCITY}
                    value         = {this.getVelocityFromWindLoad(this.state.WindPressure)}
                    onStateUpdate = {(name, value) => { this.updateData("WindPressure", this.getPressureFromWindSpeed(value)) }}
                    onUpdateData  = {(name, value) => { this.updateData("WindPressure", this.getPressureFromWindSpeed(value))  }}/>
                <UnitInput  
                    label={LocalizedStrings.WindPressure}
                    baseUnit      = {BASE_UNIT_FORCE_PER_AREA}
                    value         = {this.state.WindPressure}
                    onStateUpdate = {(name, value) => { this.updateData("WindPressure", value) }}
                    onUpdateData  = {(name, value) => { this.updateData("WindPressure", value)  }}/>

            </Form>
            </Segment>
            </> :
            <Table.Row
            draggable={this.props.draggable}
            onDragStart={e => this.props.onDragStart?.(e)}
            onDragEnter={e => this.props.onDragEnter?.(e)}
            onDragEnd=  {e => this.props.onDragEnd?.(e)}
            >
                <Table.Cell collapsing>
                    <Icon name="bars" style={{cursor: "pointer"}} color={this.props.draggable ? "black" : "grey"}/>
                </Table.Cell>
                <Table.Cell>
                    <Input  transparent
                            name      = "Name" 
                            value     = {entry.Name} 
                            onChange  = {this.onValueChanged} 
                            onBlur    = {() => this.updateData("Name", entry.Name)}
                            onKeyDown = {this.onKeyDown}
                            fluid/>

                </Table.Cell>
                <Table.Cell collapsing>
                
                </Table.Cell>
                <Table.Cell textAlign="center" collapsing>
                    <Popup content={LocalizedStrings.EditLoadComb}
                    trigger={
                    <Icon   name    = "edit" 
                            link
                            onClick = {this.openEditModal}/>
                        }/>
                    <Popup content={LocalizedStrings.ActiveLoadCombSupport}
                    trigger={
                    <Icon name={this.props.activeSupport ? "circle" : "circle outline"} 
                          color={this.props.activeSupport ? "blue" : undefined}
                          onClick = {() => window.LR_SetActiveLoadCombinationSupport({UUID:entry.UUID})}/>
                    }/>
                    <Popup content={LocalizedStrings.ActiveLoadComb}
                    trigger={
                    <Icon name={this.props.active ? "circle" : "circle outline"} 
                          color={this.props.active ? "green" : undefined}
                          onClick = {() => window.LR_SetActiveLoadCombination({UUID:entry.UUID})}/>
                    }/>
                    <Popup content={LocalizedStrings.DeleteLoadComb}
                    trigger={
                    <Icon   name    = "delete"
                            color   = "red"
                            onClick = {() => this.onDelete(entry.UUID)}
                            />
                    }/>
                    <Popup content={LocalizedStrings.DuplicatesLoadCom}
                    trigger={
                    <Icon   name    = "copy"
                            onClick = {() => this.onDuplicate(entry.UUID)}
                            />
                    }/>
                </Table.Cell>
            </Table.Row>}
            {this.state.openModal ? this.renderModal() : null}
            </>
        );
    }

    onValueChanged = (e, {name, value}) =>
    {
        this.setState({[name]:value});
    }

    updateData = (name, value, event) =>
    {
        let request = 
        {
            UUID: this.state.UUID
        };

        if(name)    { request[name] = value; }
        else        { request.Name  = this.state.Name; }

        
        window.LR_SetLoadCombination(request);
    }

    onKeyDown = (event) =>
    {
        if(event.keyCode === 13) { this.updateData(); event.target.blur()}
    }

    onDelete = (uuid) =>
    {
        let request = 
        {
            UUID: uuid,
        }

        window.LR_DeleteLoadCombination(request);
    }

    onDuplicate = async(uuid) =>
    {
        let lg = await window.LR_GetLoadCombination({UUID: uuid})
        window.LR_AddNewLoadCombination(lg);
    }

    closeEditModal = (e) => 
    {
        this.setState({openModal: false});
    }

    openEditModal = async() =>
    {
        this.setState(
        {
            openModal   : true,
        });

        let lgs = await window.LR_GetLoadGroups();
        let ly = await window.LR_GetLayers();

        let LoadGroups = lgs.LoadGroups.map( e =>
        {
            let factor = this.props.entry.LoadGroupFactors[e.UUID] 

            return { 
                ...e,
                LoadFactor: factor? factor : 1
            }
        })

        let Layers = ly.Layers.map( e =>
        {
            let factor = this.props.entry.LoadGroupFactorsLayers[e.UUID] 

            return { 
                ...e,
                LoadFactor: factor? factor : 1
            }
        })

        let LayersCableLoad = ly.Layers.map( e =>
            {
                let disable = this.props.entry.IgnoreCableLoadsFlatFare[e.UUID] 
    
                return { 
                    ...e,
                    LoadFactor: disable
                }
            })
        
            let DeflectionLimitLayer = ly.Layers.map( e =>
                {
                    let use = this.props.entry.CheckMaxDeflectionLimitLayer[e.UUID] 
                    let value = this.props.entry.MaxDeflectionLimitLayer[e.UUID] 
        
                    return { 
                        ...e,
                        Use: use,
                        Value: value,
                    }
                })


        this.setState(
        {
            LoadGroups,
            Layers,
            LayersCableLoad,
            DeflectionLimitLayer
        });

    }

    changeLoadCombination = async() =>
    {
        let req = 
        {
            UUID: this.props.entry.UUID,
            LoadGroupFactors:
            {

            },
            LoadGroupFactorsLayers:
            {

            },
            IgnoreCableLoadsFlatFare:
            {

            },
            CheckMaxDeflectionLimitLayer:
            {

            },
            MaxDeflectionLimitLayer:
            {

            },
            LowWorkload: this.state.LowWorkload,
            LoadFactor: this.state.LoadFactor,
            CableLoadTruss: this.state.CableLoadTruss,
            CableLoadPipe: this.state.CableLoadPipe,
            OverloadWorkload: this.state.OverloadWorkload,
            SecondOrder: this.state.SecondOrder,
            UseDetailedStructure: this.state.UseDetailedStructure,
            CompensateDrops: this.state.CompensateDrops,
            UseLayerFactorsFromSupport: this.state.UseLayerFactorsFromSupport,
            PutInteractionEverywhere: this.state.PutInteractionEverywhere,
            CheckMaxDeflectionLimit: this.state.CheckMaxDeflectionLimit,
            MaxDeflectionLimit: this.state.MaxDeflectionLimit,
            Country: this.state.Country,
            WindLoadZone: this.state.WindLoadZone,
            WindPressureShort: this.state.WindPressureShort,
            WindPressure: this.state.WindPressure,
            HeightOfConstruction: this.state.HeightOfConstruction,
            WindAngle: this.state.WindAngle,
            OverwriteCableLoadFromSettings: this.state.OverwriteCableLoadFromSettings,
            ChecksToRunIDs: this.state.avChecks.filter(i => i.selected).map(i => i.key)
        }

        this.state.LoadGroups.forEach(element => 
        {
            req.LoadGroupFactors[element.UUID] = element.LoadFactor
        });
        this.state.Layers.forEach(element => 
        {
            req.LoadGroupFactorsLayers[element.UUID] = element.LoadFactor
        });

        this.state.LayersCableLoad.forEach(element => 
        {
            req.IgnoreCableLoadsFlatFare[element.UUID] = element.LoadFactor
        });

        this.state.DeflectionLimitLayer.forEach(element => 
        {
            req.CheckMaxDeflectionLimitLayer[element.UUID]  = element.Use
            req.MaxDeflectionLimitLayer[element.UUID]       = element.Value
        });

        window.LR_SetLoadCombination(req)

        this.closeEditModal()
    }

    renderModal = () =>
    {
        return (
            <LRModal    open={this.state.openModal}
                        title={LocalizedStrings.EditLoadCombination}
                        onOkClick={this.changeLoadCombination}
                        onCancelClick={this.closeEditModal}>
                    <Form>
                        {this.props.settings ? null : <UnitInput  name="LowWorkload" label={LocalizedStrings.LoadGroupLowWorkload} baseUnit={BASE_UNIT_NUMBER} value={this.state.LowWorkload} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>}
                        {this.props.settings ? null :  <UnitInput  name="OverloadWorkload" label={LocalizedStrings.LoadGroupOverloadWorkload} baseUnit={BASE_UNIT_NUMBER} value={this.state.OverloadWorkload} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>}
                        {this.props.settings ? null : <Form.Checkbox name      = {"OverwriteCableLoadFromSettings"} 
                        fluid ="true"
                        size      = "mini"
                        label     = {<label>{LocalizedStrings.OverwriteCableLoadFromSettings}</label>} 
                        checked   = {this.state.OverwriteCableLoadFromSettings}
                        onChange  = {(e,{checked})=> this.setState({OverwriteCableLoadFromSettings: checked})} 
                        />}
                        <UnitInput disabled={!this.state.OverwriteCableLoadFromSettings && !this.props.settings} name="CableLoadTruss" label={LocalizedStrings.CableLoadTruss} baseUnit={BASE_UNIT_WEIGHT_PER_DISTANCE} value={this.state.CableLoadTruss} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>
                        <UnitInput disabled={!this.state.OverwriteCableLoadFromSettings && !this.props.settings} name="CableLoadPipe" label={LocalizedStrings.CableLoadPipe} baseUnit={BASE_UNIT_WEIGHT_PER_DISTANCE} value={this.state.CableLoadPipe} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>
                        {this.props.settings ? null : <UnitInput  name="LoadFactor" label={LocalizedStrings.LoadFactor} baseUnit={BASE_UNIT_NUMBER} value={this.state.LoadFactor} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>}
                        {!this.props.settings ? null : <Form.Checkbox name      = {"ShowResult"} 
                        fluid ="true"
                        
                        size      = "mini"
                        label     = {<label>{LocalizedStrings.SecondOrder}</label>} 
                        checked   = {this.state.SecondOrder}
                        onChange  = {(e,{checked})=> this.setState({SecondOrder: checked})} 
                        />}
                        {!this.props.settings ? null : <Form.Checkbox name      = {"UseDetailedStructure"} 
                        fluid ="true"
                        size      = "mini"
                        label     = {<label>{LocalizedStrings.UseDetailedStructure}</label>} 
                        checked   = {this.state.UseDetailedStructure}
                        onChange  = {(e,{checked})=> this.setState({UseDetailedStructure: checked})} 
                        />}
                        {!this.props.settings ? null : <Form.Checkbox name      = {"CompensateDrops"} 
                        fluid ="true"
                        size      = "mini"
                        label     = {<label>{LocalizedStrings.CompensateDrops}</label>} 
                        checked   = {this.state.CompensateDrops}
                        onChange  = {(e,{checked})=> this.setState({CompensateDrops: checked})} 
                        />}
                        {!this.props.settings ? null : <Form.Checkbox name      = {"UseLayerFactorsFromSupport"} 
                        fluid ="true"
                        size      = "mini"
                        label     = {<label>{LocalizedStrings.UseLayerFactorsFromSupport}</label>} 
                        checked   = {this.state.UseLayerFactorsFromSupport}
                        onChange  = {(e,{checked})=> this.setState({UseLayerFactorsFromSupport: checked})} 
                        />}
                        {!this.props.settings ? null : <Form.Checkbox name      = {"PutInteractionEverywhere"} 
                        fluid ="true"
                        size      = "mini"
                        label     = {<label>{LocalizedStrings.PutInteractionEverywhere}</label>} 
                        checked   = {this.state.PutInteractionEverywhere}
                        onChange  = {(e,{checked})=> this.setState({PutInteractionEverywhere: checked})} 
                        />}
                        {!this.props.settings ? null : <Form.Checkbox name      = {"CheckMaxDeflectionLimit"} 
                        fluid ="true"
                        size      = "mini"
                        label     = {<label>{LocalizedStrings.CheckMaxDeflectionLimit}</label>} 
                        checked   = {this.state.CheckMaxDeflectionLimit}
                        onChange  = {(e,{checked})=> this.setState({CheckMaxDeflectionLimit: checked})} 
                        />}
                        {!this.props.settings ? null : <UnitInput disabled={!this.state.CheckMaxDeflectionLimit} name="MaxDeflectionLimit" label={LocalizedStrings.MaxDeflectionLimit} baseUnit={BASE_UNIT_NUMBER} value={this.state.MaxDeflectionLimit} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>}

                    </Form>
                    {this.props.settings ? null : <>
                    <Header onClick={()=>this.setState({HeaderLoadGroupFactors: !this.state.HeaderLoadGroupFactors})}><Icon name={this.state.HeaderLoadGroupFactors ? "angle down" : "angle right"}/> {LocalizedStrings.HeaderLoadGroupFactors}</Header>
                    {this.state.HeaderLoadGroupFactors ? <Table>
                        
                        <Table.Header>
                            <Table.Row>
                            <Table.HeaderCell>{LocalizedStrings.LoadGroupName}</Table.HeaderCell>
                            <Table.HeaderCell>{LocalizedStrings.LoadGroupFactor}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.LoadGroups.map(e=>this.renderModalRow(e, "LoadGroups"))}
                        </Table.Body>
                    </Table> : null}
                    </>}
                    {this.props.settings ? null : <>
                    <Header onClick={()=>this.setState({HeaderLayerFactors: !this.state.HeaderLayerFactors})}><Icon name={this.state.HeaderLayerFactors ? "angle down" : "angle right"} />{LocalizedStrings.HeaderLayerFactors}</Header>
                    {this.state.HeaderLayerFactors ? <Table>
                        <Table.Header>
                            <Table.Row>
                            <Table.HeaderCell>{LocalizedStrings.LayerNameFactor}</Table.HeaderCell>
                            <Table.HeaderCell>{LocalizedStrings.LayerNameFactorFactor}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.Layers.map(e=>this.renderModalRow(e, "Layers"))}
                        </Table.Body>
                    </Table> : null}
                    </>}
                    {!this.props.settings ? null : <>
                    <Header onClick={()=>this.setState({DeflectionLimitForLayer: !this.state.DeflectionLimitForLayer})}><Icon name={this.state.DeflectionLimitForLayer ? "angle down" : "angle right"}/> {LocalizedStrings.DeflectionLimitForLayer}</Header>
                    {this.state.DeflectionLimitForLayer ? <Table>
                        
                        <Table.Header>
                            <Table.Row>
                            <Table.HeaderCell>{LocalizedStrings.LayerNameFactor}</Table.HeaderCell>
                            <Table.HeaderCell>{LocalizedStrings.LayerUseDeflectionLimit}</Table.HeaderCell>
                            <Table.HeaderCell>{LocalizedStrings.LayerMaxDeflectionLimit}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.DeflectionLimitLayer.map(e=>this.renderArray(e))}
                        </Table.Body>
                    </Table> : null}
                    </>}
                    {!this.props.settings ? null : <>     
                    <Header onClick={()=>this.setState({HeaderLayerDisableCableLoad: !this.state.HeaderLayerDisableCableLoad})}><Icon name={this.state.HeaderLayerDisableCableLoad ? "angle down" : "angle right"}/> {LocalizedStrings.HeaderLayerDisableCableLoad}</Header>
                    {this.state.HeaderLayerDisableCableLoad ? <Table>
                        
                        <Table.Header>
                            <Table.Row>
                            <Table.HeaderCell>{LocalizedStrings.LayerNameFactor}</Table.HeaderCell>
                            <Table.HeaderCell>{LocalizedStrings.LayerNameDisableCableLoad}</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {this.state.LayersCableLoad.map(e=>this.renderModalRow(e, "LayersCableLoad", BASE_UNIT_BOOLEAN))}
                        </Table.Body>
                    </Table> : null}
                    </>}
                    {!this.props.settings ? null : <>
                    <Header onClick={()=>this.setState({Windload: !this.state.Windload})}><Icon name={this.state.Windload ? "angle down" : "angle right"}/> {LocalizedStrings.Windload}</Header>
                    {this.state.Windload ? <Grid columns="2">
                        <Grid.Row>
                            <Grid.Column>
                                {this.renderDropDownWindload()}
                            </Grid.Column>
                            <Grid.Column>
                                <Form>
                                    <UnitInput  
                                                label={LocalizedStrings.HeightOfConstruction}
                                                fluid
                                                baseUnit      = {BASE_UNIT_LENGTH}
                                                value         = {this.state.HeightOfConstruction}
                                                onStateUpdate = {(name, value) => { this.setState({HeightOfConstruction: value}) }}
                                                onUpdateData  = {(name, value) => { this.setState({HeightOfConstruction: value})  }}/>
                                                <UnitInput  
                                                label={LocalizedStrings.WindAngle}
                                                fluid
                                                baseUnit      = {BASE_UNIT_ANGLE}
                                                value         = {this.state.WindAngle}
                                                onStateUpdate = {(name, value) => { this.setState({WindAngle: value}) }}
                                                onUpdateData  = {(name, value) => { this.setState({WindAngle: value})  }}/>
                                    <UnitInput  
                                                label={LocalizedStrings.WindPressure}
                                                fluid
                                                baseUnit      = {BASE_UNIT_FORCE_PER_AREA}
                                                value         = {this.state.WindPressure}
                                                onStateUpdate = {(name, value) => { this.setState({WindPressure: value}) }}
                                                onUpdateData  = {(name, value) => { this.setState({WindPressure: value})  }}/>
                                    {/*<UnitInput  
                                                label={LocalizedStrings.WindPressureShort}
                                                fluid
                                                baseUnit      = {BASE_UNIT_FORCE_PER_AREA}
                                                value         = {this.state.WindPressureShort}
                                                onStateUpdate = {(name, value) => { this.setState({WindPressureShort: value}) }}
                                                onUpdateData  = {(name, value) => { this.setState({WindPressureShort: value})  }}/>*/}
                                    <UnitInput  
                                                label={LocalizedStrings.WindSpeed}
                                                fluid
                                                baseUnit      = {BASE_UNIT_VELOCITY}
                                                value         = {this.getVelocityFromWindLoad(this.state.WindPressure)}
                                                onStateUpdate = {(name, value) => { this.setState({WindPressure: this.getPressureFromWindSpeed(value)}) }}
                                                onUpdateData  = {(name, value) => { this.setState({WindPressure: this.getPressureFromWindSpeed(value)})  }}/>
                                    {/*<UnitInput  
                                                label={LocalizedStrings.WindSpeedShort}
                                                fluid
                                                baseUnit      = {BASE_UNIT_VELOCITY}
                                                value         = {this.getVelocityFromWindLoad(this.state.WindPressureShort)}
                                                onStateUpdate = {(name, value) => { this.setState({WindPressureShort: this.getPressureFromWindSpeed(value)}) }}
                                                onUpdateData  = {(name, value) => { this.setState({WindPressureShort: this.getPressureFromWindSpeed(value)})  }}/>*/}
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid> : null}
                    </>}
                    {!this.props.settings ? null : <div style={{marginTop: 50, marginBottom: 400}}>
                        <BasicSelect
                            filter
                            multiple={true}
                            text={LocalizedStrings.CheckScriptsToRun}
                            value={this.state.Country}
                            options={this.state.avChecks}
                            onStateUpdate={(_, value)=>this.setState({avChecks: value})}
                        />
                    </div>}
            </LRModal>
        )
    }

    getVelocityFromWindLoad(value)
    {
        return Math.sqrt(2 * value * Math.pow(10,3) / 1.25) * Math.pow(10,6)
    }

    getPressureFromWindSpeed(value)
    {
        return 0.5 * 1.25 * Math.pow(value, 2) / Math.pow(10,15)
    }

    renderModalRow = (lg, ArrayName, unit = BASE_UNIT_NUMBER) =>
    {

        return(<Table.Row key={lg.UUID}>
            <Table.Cell>{lg.Name}</Table.Cell>
            <Table.Cell>
            <UnitInput  
                            fluid
                            baseUnit      = {unit}
                            value         = {lg.LoadFactor}
                            onStateUpdate = {(name, value) => { this.updateLoadGroup(lg.UUID,ArrayName, value) }}
                            onUpdateData  = {(name, value) => {this.updateLoadGroup(lg.UUID, ArrayName, value)  }}/>
            </Table.Cell>
        </Table.Row>)
    } 
    renderArray = (lg) =>
    {

        return(<Table.Row key={lg.UUID}>
            <Table.Cell>{lg.Name}</Table.Cell>
            <Table.Cell>
            <UnitInput  
                            fluid
                            baseUnit      = {BASE_UNIT_BOOLEAN}
                            value         = {lg.Use}
                            onStateUpdate = {(name, value) => { this.updateArray(lg.UUID,"DeflectionLimitLayer", "Use", value) }}
                            onUpdateData  = {(name, value) => {this.updateArray(lg.UUID, "DeflectionLimitLayer", "Use", value)  }}/>
            </Table.Cell>
            <Table.Cell>
            <UnitInput  
                            fluid
                            baseUnit      = {BASE_UNIT_NUMBER}
                            value         = {lg.Value}
                            onStateUpdate = {(name, value) => { this.updateArray(lg.UUID,"DeflectionLimitLayer", "Value", value) }}
                            onUpdateData  = {(name, value) => {this.updateArray(lg.UUID, "DeflectionLimitLayer", "Value", value)  }}/>
            </Table.Cell>
        </Table.Row>)
    } 

    renderDropDownWindload()
    {
        let update = (name, value, e) => 
        {
            let WindPressure      = 5
            let WindPressureShort = 2
            switch(this.state.Country)
            {
                case "de":
                    switch(value)
                    {
                        case 1: WindPressure = 0.32 * 1/Math.pow(10,6); WindPressureShort = 2; break;
                        case 2: WindPressure = 0.39 * 1/Math.pow(10,6); WindPressureShort = 2; break;
                        case 3: WindPressure = 0.47 * 1/Math.pow(10,6); WindPressureShort = 2; break;
                        case 4: WindPressure = 0.56 * 1/Math.pow(10,6); WindPressureShort = 2; break;
                    }
                    break;
                case "fr":
                    switch(value)
                    {
                        case 1: WindPressure = 0.30 * 1/Math.pow(10,6); WindPressureShort = 2; break;
                        case 2: WindPressure = 0.35 * 1/Math.pow(10,6); WindPressureShort = 2; break;
                        case 3: WindPressure = 0.41 * 1/Math.pow(10,6); WindPressureShort = 2; break;
                        case 4: WindPressure = 0.48 * 1/Math.pow(10,6); WindPressureShort = 2; break;
                    }
                    break;
                case "nl":
                    switch(value)
                    {
                        case 1: WindPressure = 0.54 * 1/Math.pow(10,6); WindPressureShort = 2; break;
                        case 2: WindPressure = 0.46 * 1/Math.pow(10,6); WindPressureShort = 2; break;
                        case 3: WindPressure = 0.36 * 1/Math.pow(10,6); WindPressureShort = 2; break;
                    }
                    break;
                case "us":
                    switch(value)
                    {
                        case 1: WindPressure = 0.54 * 1/Math.pow(10,6); WindPressureShort = 2; break;
                        case 2: WindPressure = 0.46 * 1/Math.pow(10,6); WindPressureShort = 2; break;
                        case 3: WindPressure = 0.38 * 1/Math.pow(10,6); WindPressureShort = 2; break;
                    }
                    break;
                case "uk":
                    switch(value)
                    {
                        case 1: WindPressure = 0.54 * 1/Math.pow(10,6); WindPressureShort = 2; break;
                        case 2: WindPressure = 0.46 * 1/Math.pow(10,6); WindPressureShort = 2; break;
                        case 3: WindPressure = 0.36 * 1/Math.pow(10,6); WindPressureShort = 2; break;
                        case 4: WindPressure = 0.36 * 1/Math.pow(10,6); WindPressureShort = 2; break;
                        case 5: WindPressure = 0.36 * 1/Math.pow(10,6); WindPressureShort = 2; break;
                    }
                    break;
                default: break;
            }

            this.setState({WindPressure, WindPressureShort, WindLoadZone: value})
        }



        return <>
        <BasicSelect
          label={LocalizedStrings.Country}
          value={this.state.Country}
          options={[
            {key:0, value: "de", text: LocalizedStrings.Germany},
            {key:1, value: "fr", text: LocalizedStrings.France},
            {key:2, value: "uk", text: LocalizedStrings.England},
            {key:3, value: "nl", text: LocalizedStrings.Netherlands},
            {key:4, value: "us", text: LocalizedStrings.UnitedStates},
            {key:5, value: "__", text: LocalizedStrings.Custom},
        ]}
          onStateUpdate={(name, value, e)=>{this.setState({Country: value})}}
        />
        {this.state.Country !== "__" ?
        <BasicSelect
          label={LocalizedStrings.WindLoadZone}
          value={this.state.WindLoadZone}
          options={this.getWindLoadZonesOptions()}
          onStateUpdate={update}
        /> : null}
        {this.state.Country === "fr" ? <img src={IMG_Windzone_France} alt="vrGif" /> : null}
        {this.state.Country === "de" ? <img src={IMG_Windzone_Germany} alt="vrGif" /> : null}
        {this.state.Country === "uk" ? <img src={IMG_Windzone_England} alt="vrGif" /> : null}
        {this.state.Country === "nl" ? <img src={IMG_Windzone_Netherlands} alt="vrGif" /> : null}
        {this.state.Country === "us" ? <img src={IMG_Windzone_UnitedStates} alt="vrGif" /> : null}
        

        </>
    }

    getWindLoadZonesOptions = () =>
    {
        let options = [
            {
                key: 1,
                value:1,
                text: "1"
            },
            {
                key: 2,
                value:2,
                text: "2"
            },
            {
                key: 3,
                value:3,
                text: "3"
            },
            {
                key:4,
                value:4,
                text: "4"
            },
        ]
        let options_UK = [
            {
                key: 1,
                value:1,
                text: "1"
            },
            {
                key: 2,
                value:2,
                text: "2"
            },
            {
                key: 3,
                value:3,
                text: "3"
            },
            {
                key:4,
                value:4,
                text: "4"
            },
            {
                key:5,
                value:5,
                text: "5"
            },
        ]
        let options_NL = [
            {
                key: 1,
                value:1,
                text: "1"
            },
            {
                key: 2,
                value:2,
                text: "2"
            },
            {
                key: 3,
                value:3,
                text: "3"
            }
        ]
        switch(this.state.Country)
            {
                case "de": return options;
                case "fr": return options;
                case "uk": return options_UK;
                case "nl": return options_NL;
                case "us": return options_NL;

                default: return options;
            }
    }

    updateLoadGroup = (uuid, ArrayName, value) =>
    {
        let array = this.state[ArrayName].map(e=>
        {
            if(e.UUID === uuid)
            {
                return {
                    ...e,
                    LoadFactor: value
                }
            }
            return e
        })

        this.setState({[ArrayName]:array})
    }

    updateArray = (uuid, ArrayName, name, value) =>
    {
        let array = this.state[ArrayName].map(e=>
        {
            if(e.UUID === uuid)
            {
                return {
                    ...e,
                [name]: value
                }
            }
            return e
        })

        this.setState({[ArrayName]:array})
    }
}

export default CaseNode;

