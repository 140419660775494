
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component, createRef } from 'react';
import { Table, Grid, Menu, Dropdown, Sticky, Message, Icon, Button, Input } from "semantic-ui-react";
import LocalizedStrings from "../../../localization/LightingPaperwork";
const Renderer = React.lazy(()=> import('../../Renderer/RendererWrapper'))
import {radToDeg, escapeRegExp} from'../../../util/defines';

import { globalCallbacks } from "../../../util/callback";

class FocusList extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = 
        {
            fixtures            : [],
            activeFixture       : 0,
            highlightedFixture  : "",

            rendererHeight      : 0,

            searchText          : "",
            searchOptions       : [],
            searchResults       : [],
        };

        this.contextRef = createRef();
    }

    componentDidMount = () => 
    {
        this.setUpCallbacks();

        if(globalCallbacks.getFixturesByAlignment)
        {
            globalCallbacks.getFixturesByAlignment();
        }

        this.updateRendererHeight();
        window.addEventListener("resize", this.updateRendererHeight);
    }

    componentWillUnmount = () => 
    {
        this.takeDownCallbacks();

        window.removeEventListener("resize", this.updateRendererHeight);
    }

    render() 
    {
        return (
            <Grid>
                <Grid.Column width={2}>
                    <div style={{height: '100%'}} ref={this.contextRef}>
                    <Sticky context={this.contextRef}>
                        <Menu fluid secondary vertical >
                            <Input
                                icon        = "search"
                                fluid
                                placeholder = "Search..."
                                value       = {this.state.searchText}
                                onChange    = {this.onChangeSearch}/>
                            {
                                this.state.searchResults.map(fixture =>
                                {
                                    return(
                                        <Menu.Item  key         = {fixture.UUID}
                                                    active      = {fixture.UUID === this.state.activeFixture}
                                                    onClick     = {() => this.onFixtureClicked(fixture)}>
                                                        
                                            {fixture.title}
                                        </Menu.Item>
                                    );
                                })
                            }
                        </Menu>
                    </Sticky>
                    </div>
                </Grid.Column >
                <Grid.Column width={9}>
                    {
                        this.state.fixtures.map((fixture) =>
                        {
                            return(this.renderFixture(fixture));
                        })
                    }
                    {
                        this.state.fixtures.length > 0 ? null : <Message warning><Icon name='warning'/>{LocalizedStrings.NoFixture}</Message>
                    }
                </Grid.Column >
                <Grid.Column width={5}>
                    <div style={{width:"100%", height:this.state.rendererHeight}}>
                        <Button fluid onClick={this.resetHighlight}>{LocalizedStrings.Reset}</Button>
                        <Renderer projectname={this.props.projectname} username={this.props.username} rootUuid={0} excludeFirstObject={false} hideEditMode={true}/>
                    </div>
                </Grid.Column >
                
            </Grid>

        );
    }

    renderFixture = (fixture) =>
    {
        return(<div id={fixture.UUID} key={fixture.UUID} style={{marginTop: "0.5em", marginLeft: "0.5em"}}>
            <Table fixed size="small" compact striped>
            <Table.Header>
                <Table.HeaderCell>{fixture.FixtureId + " " + fixture.Name}</Table.HeaderCell>
                <Table.HeaderCell>{LocalizedStrings.Alignment}</Table.HeaderCell>
                <Table.HeaderCell>{LocalizedStrings.UsedInCues}</Table.HeaderCell>
                <Table.HeaderCell>{LocalizedStrings.Highlight}</Table.HeaderCell>
            </Table.Header>
            {
                fixture.Alignments.map((alignment, alignmentIndex) =>
                {
                    return(this.renderAlignment(fixture, alignment, alignmentIndex));
                })
            }
            {
                fixture.Alignments.length > 0 ? null : <Message warning><Icon name='warning'/>{LocalizedStrings.NoAlignement}</Message>
            }
            </Table></div>
        )
    }

    renderAlignment = (fixture, alignment, alignmentIndex) =>
    {
        let cueList = "";
        alignment.TimePhaseChangeList.forEach((cue, i) =>
        {
            if(i === 0) { cueList += cue.Name; }
            else        { cueList += ", " + cue.Name; }
        });

        let dropdownColor = fixture.UUID === this.state.highlightedFixture ? "green" : "black";

        return(<Table.Row>
            <Table.Cell>{}</Table.Cell>
            <Table.Cell>{"Pan: " + radToDeg(alignment.Pan) + "° / Tilt: " + radToDeg(alignment.Tilt) + "°"}</Table.Cell>
            <Table.Cell>{cueList}</Table.Cell>
                <Dropdown
                        placeholder         = {LocalizedStrings.ChooseCue}
                        fluid
                        style={{color: dropdownColor}}
                        selection
                        lazyLoad
                        options             = {alignment.highlightOptions}
                        value               = {this.state.alignmentHighlights[fixture.UUID][this.state[alignmentIndex]]}
                        onChange            = {(e, {value}) => this.onChangeHighlightedCue(fixture, alignmentIndex, value)}/>
        </Table.Row>)
    }

    onChangeSearch = (_, {value}) =>
    {
        let searchResults = this.state.searchOptions;

        if(value)
        {
            const re = new RegExp(escapeRegExp(value), 'i')
            const isMatch = (result) => re.test(result.title);

            searchResults = this.state.searchOptions.filter(isMatch);

            //Avoid crash
            if(searchResults.length === 0) { searchResults.push([]); }
        }

        this.setState({ searchText : value, searchResults });
    }

    onChangeHighlightedCue = (fixture, alignmentIndex, value) =>
    {
        let alignmentHighlights = this.state.alignmentHighlights;
        alignmentHighlights[fixture.UUID][this.state[alignmentIndex]] = value;


        let request =
        {
            UUID            : value,
            HighlightUUID   : fixture.UUID
        }
        window.LR_PlayTimelineUntil(request);

        
        this.setState({highlightedFixture: fixture.UUID, alignmentHighlights});
    }

    resetHighlight = () =>
    {
        window.LR_ResetHighlight();

        this.setState({highlightedFixture: ""});
    }

    onFixtureClicked = (fixture) =>
    {
        let jumpHere = fixture.UUID;
        window.location.href = window.location.origin + window.location.search + "#" + jumpHere;
        this.setState({activeFixture : fixture});
    }

    updateRendererHeight = () =>
    {
        // 100 is an arbitrary value to see the whole renderer and not have a useless scrollbar.
        this.setState({rendererHeight: document.body.clientHeight - 100});
    }

    setUpCallbacks()
    {
        globalCallbacks.getFixturesByAlignment = async () =>
        {
            let fixtures = await window.LR_GetFixturesByAlignment();

            let alignmentHighlights = {};

            let searchOptions = [];

            fixtures.forEach(fixture =>
            {
                alignmentHighlights[fixture.UUID] = [];

                fixture.Alignments.forEach(alignment =>
                {
                    let highlightOptions = alignment.TimePhaseChangeList.map(cue =>
                    {
                        return(
                        {
                            key     : cue.UUID,
                            value   : cue.UUID,
                            text    : cue.Name,
                        })
                    });

                    alignment.highlightOptions  = highlightOptions;

                    alignmentHighlights[fixture.UUID].push("");
                });

                searchOptions.push({
                    UUID    : fixture.UUID,
                    title   : fixture.FixtureId + " " + fixture.Name,
                })

            });

            let searchResults = [...searchOptions];

            this.setState({fixtures, alignmentHighlights, highlightedAlignmentIndex:-1, searchOptions, searchResults});
        }
    }

    takeDownCallbacks()
    {
        globalCallbacks.getFixturesByAlignment = undefined;
    }

}

export default FocusList;

