//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Table, Icon, Label, Progress, Divider, Grid, Segment, Form, Button } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/Hotpatch";
import { kWireType, kWireType_Consumer, kWireType_Generator, kWireType_HotpatchInput, kWireType_HotpatchOutput, kWireType_Input, kWireType_Output } from '../../util/defines';
import LRModal from '../Basics/BasicModal';
import { globalCallbacks } from '../../util/callback';
import { globalWindowInterface } from '../../util/callbackTypes';

declare const  window: globalWindowInterface;

interface HotpatchModalState {
  open: Boolean,
  data: any
  patch: any
  AllInputs: any
  Objects: any

}


//-----------------------------------------------------------------------------
// The component for the TreeControl
class HotpatchModal extends Component<{}, HotpatchModalState> 
{
  lastDragged: any

  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
      data: [],
      patch: {},
      Objects: {},
      AllInputs: []
    }
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }

  show = async() => 
  {
    this.setState( { 
        open : true, 
      });
  }

  close = () => 
  {
    this.setState({open : false});
  }
  apply = () => 
  {
    let add = []
    let remove = []
    for (const [key, value] of Object.entries(this.state.patch)) 
    {
      const splitted = key.split("_");

      let object_1_uuid   = splitted[0]
      let object_2_uuid   = splitted[0]
      let geometry_1_uuid = splitted[1]
      let geometry_2_uuid = value

      if(geometry_2_uuid)
      {
        add.push(
        {
          From: {Object: object_1_uuid, Wire: geometry_1_uuid},
          To: {Object: object_2_uuid, Wire: geometry_2_uuid}
        })
      }
      else
      {
        remove.push(
          {
            Object: object_1_uuid,
            Wire: geometry_1_uuid,
          })
      }


    }

    window.LR_AddAndRemoveElectricalObjectConnection({Add:add, Remove: remove})

  }
    
  render() 
  {
    let open = this.state.open;
    if(!open) {return <div/>}

    return (
      <LRModal open={open}
        onCancelClick={this.close}
        closeOnDimmerClick={false}
        size="fullscreen"
        scrolling={false}
        title={LocalizedStrings.Header}
        noCancel
        onOkClick={this.close}>
        <Segment>
          <Grid>
            <Grid.Row>
              <Grid.Column width="15">{this.state.AllInputs.map(val => this.renderOtherConnections(val))}</Grid.Column>
              <Grid.Column  fluid width="1" ><Button onClick={()=> window.LR_HotPatchSelectedObjects({Hotpatch:true, Selected:true})} negative>{LocalizedStrings.Reset}</Button></Grid.Column>
            </Grid.Row>
          </Grid>
          
        </Segment>
        <Table celled structured striped textAlign="center" compact>
          <Table.Header>
            {this.renderHeader()}
          </Table.Header>
          <Table.Body>
            {this.state.data.map(lr_object => this.renderConnectionOption_Object(lr_object))}
          </Table.Body>
        </Table>
      </LRModal>
    )
  }

  renderOtherConnections(val)
  {
    if(val.WireType == kWireType.Input || val.WireType == kWireType.Consumer)
    {
      return <Label color={val.IsUsed ? "red" : "green"} draggable onDragStart={() => this.lastDragged = val}>
        {val.IsUsed ? <Icon name='delete' onClick={()=>{ window.LR_RemoveElectricalObjectConnection({Object: val.Object, Wire : val.Wire}) }}/> : null}
        {(val.ObjectId ? (val.ObjectId + ".") : "") + val.WireName}
        </Label>
     
    }
    return null
    
  }

  renderConnectionOption_Object(lr_object)
  {
    return (
      <>
      {lr_object.Geometries.map(geo => this.renderConnectionOption(geo, lr_object))}
      </>
    )
  }

  GetConnectionFromGeometry(uuid, lr_object)
  {
    for(let con of lr_object.HotpatchConnections)
    {
      if(uuid === con.OwnWire)
      {
        return con
      }
    }

    return {}
  }

  renderConnectionOption(lr_geo, lr_object)
  {
    if(lr_geo.WireType !== kWireType_HotpatchOutput)
    {
      return null
    }

    let con = this.GetConnectionFromGeometry(lr_geo.UUID, lr_object)

    return(
      <>
      <Table.Row>
      <Table.Cell colSpan="2">
        <b>{lr_geo.Name}{"  "}</b>
        {this.renderProgressBar(con.CurrentL1, con.MaxL1)}
      </Table.Cell>
      {this.renderCellOption(lr_geo)}
      </Table.Row>
      </>
    )
  }

  renderCellOption(lr_geo)
  {
    return(
      <>
      {
      this.state.data.map(lr_object => 
      <>
      {lr_object.Geometries.map(lr_GeoOption=>this.renderCellOption_Connection_option(lr_geo, lr_object, lr_GeoOption))}
      </>)
      }
      </>
    )
  }

  renderCellOption_Connection_option(lr_geo, lr_object, lr_GeoOption)
  {
    if(lr_GeoOption.WireType !== kWireType_HotpatchInput)
    {
      return null
    }
    return this.renderOptionField(lr_geo, lr_object, lr_GeoOption)
          
  }

  renderOptionField(lr_geo, lr_object, lr_GeoOption)
  {
    let optionName = lr_object.UUID + "_" + lr_GeoOption.UUID

    let Selected = this.state.patch[optionName] === lr_geo.UUID;
    let HandleClick = () =>
    {
      this.setState({patch: {...this.state.patch, [optionName]: (Selected ? undefined : lr_geo.UUID)}}, this.apply)
    }


    return <Table.Cell onClick={HandleClick} style={{backgroundColor: Selected ? "green" : "grey"}}>
      {Selected ? <Icon name='checkmark'/> : null}
    </Table.Cell>
  }

  renderProgressBar(val, max)
  {
    return<>
    {Number(val).toFixed(2)}A / {Number(max).toFixed(0)}A 
    <Progress percent={(val / max) * 100}
    style={{margin:"0"}}
          error = {max < val}
          warning = {max * 0.8< val }
          success = {max * 0.8> val}/>
    </>
  }

  renderHeader()
  {
    return(
      <>
      
      <Table.Row>
      <Table.HeaderCell/>
      <Table.HeaderCell/>
      {this.state.data.map(lr_object => this.renderSourceObjectHeader(lr_object))}
      </Table.Row>
      <Table.Row>
      <Table.HeaderCell rowSpan="3">
      {this.state.data.map(lr_object => this.renderInputSummeryObject(lr_object))}


      </Table.HeaderCell>
      </Table.Row>
      <Table.Row>
      <Table.HeaderCell collapsing>{LocalizedStrings.Channel}</Table.HeaderCell>
      {this.state.data.map(lr_object => this.renderObjectHeaderChannels(lr_object))}
      </Table.Row>
      <Table.Row>
      <Table.HeaderCell collapsing>{LocalizedStrings.ChannelPayload}</Table.HeaderCell>
      {this.state.data.map(lr_object => this.renderObjectHeaderChannelsOption(lr_object))}
      </Table.Row>
      </>
    )
  }

  renderInputSummeryObject(lr_object)
  {
    return (
      <>
      {lr_object.ElectricalConnections.map(con => 
        {
          if(con.OwnWireType !== kWireType_Input)
          {
            return null
          }
          return this.renderInputSummery(con)
        })}
      </>
    )
  }

  renderInputSummery(con)
  {
    return(<>
    <Divider horizontal>{con.ConnectorName}</Divider>
          L1:{" "}
          {this.renderProgressBar(con.CurrentL1,  con.MaxL1)}
          L2:{" "}
          {this.renderProgressBar(con.CurrentL2,  con.MaxL2)}
          L3:{" "}
          {this.renderProgressBar(con.CurrentL3,  con.MaxL3)}
          N:{" "}
          {this.renderProgressBar(con.CurrentN,  5)}
    </>)
  }

  renderSourceObjectHeader(lr_object)
  {
    let AllWireInputs = []
    let funcGenerateOption = (options, result) => 
      {
        options.forEach((val) => 
        {
          if (result[val.ConnectorType] === undefined) {  result[val.ConnectorType] = [] }
          
          let objId      = (val.ObjectId ? (val.ObjectId + ".") : "") + val.WireName
          let searchText = (val.ObjectId ? objId : val.ObjectName).toLowerCase();
  
          
          result[val.ConnectorType].push(
          {
            text: (val.IsUsed ? "✅" : "□") + " " + objId + "  " + val.ObjectName, 
            value: val.Object + val.Wire,
            key: val.Object + val.Wire,
            Wire: val.Wire,
            Object: val.Object,
            SearchFilter: searchText
          });
        });
      }

      funcGenerateOption(this.state.AllInputs, AllWireInputs)

      const onDropConnection = (connection, e) => {
        if(!connection || !e){
          return
        }

        window.LR_AddElectricalObjectConnection({
          From: { Object: lr_object.UUID, Wire: e.OwnWire },
          To: { Object: connection.Object, Wire: connection.Wire }
        });
      }

      const updateObjectConnections = (obj, e) =>
      {
        let existingConnections = e.Connections

        if (obj.value.length === 0)
        {    
          window.LR_RemoveElectricalObjectConnection({Object: lr_object.UUID, Wire: e.OwnWire});
        }
        else if (obj.value.length < existingConnections.length)
        {
          let diff = existingConnections.filter(x => !obj.value.includes(x.Object + x.Wire))
    
          //Delete connection
          if (diff.length === 1)
          {
            window.LR_RemoveElectricalObjectConnection({
              Object: lr_object.UUID,
              Wire: e.OwnWire,
              LinkedObject: diff[0].Object,
              LinkedWire: diff[0].Wire
            });
          }
        }
        else
        {
          let theOption = obj.options.find(op => op.key === obj.value[obj.value.length-1])
    
          window.LR_AddElectricalObjectConnection({
            From: { Object: lr_object.UUID, Wire: e.OwnWire },
            To: { Object: theOption.Object, Wire: theOption.Wire }
          });
        }
      }

    return<>
    {lr_object.ElectricalConnections.filter(e => e.OwnWireType === kWireType_Output || e.OwnWireType === kWireType_Generator).map(e=>
        <Table.HeaderCell colSpan="6">
          <div onDrop={() => onDropConnection(this.lastDragged, e)}>
            <Form>
              <Form.Select
                label={e.ConnectorName}
                options={AllWireInputs[e.ConnectorType]}
                selection
                multiple
                selectOnBlur={false}
                value={e.Connections.map(con => con.Object + con.Wire)}
                onChange={(_, data) => { updateObjectConnections(data, e) }}
              />
            </Form>
          </div>
        </Table.HeaderCell>
      )}
    </>
  }


  renderObjectHeaderChannelsOption(lr_object)
  {
    return<>
    {lr_object.Geometries.map(Geometry => this.renderFixtureOption(lr_object, Geometry))}
    </>
  }

  renderObjectHeaderChannels(lr_object)
  {
    return<>
    {lr_object.Geometries.map(Geometry => this.renderHeaderPin(lr_object, Geometry))}
    </>
  }

  renderHeaderPin(lr_object, Geometry)
  {
    if(Geometry.WireType !== kWireType_HotpatchInput)
    {
      return null
    }
    let optionName = lr_object.UUID + "_" + Geometry.UUID
    let Selected = this.state.patch[optionName] !== undefined;
    
    return(
        <Table.HeaderCell style={{backgroundColor: Selected ? undefined : "red"}}>{Geometry.Name}</Table.HeaderCell>
    )
  }

  renderFixtureOption(lr_object, Geometry)
  {
    if(Geometry.WireType !== kWireType_HotpatchInput)
    {
      return null
    }

    let connection = this.GetConnectionFromGeometry(Geometry.UUID, lr_object)
    
    return(
      <Table.HeaderCell>

        <b>{Number(connection.CurrentL1).toFixed(2)}A</b><br/>
        {connection.ConnectionsChannels.P1.map((e,i)=><><Label><b>{e}</b>{" " + this.state.Objects[connection.ConnectionsObjects.P1[i]]?.Name}</Label><br/></>)}
        
      </Table.HeaderCell>
    )
  }


  GetGeometryForConnection(uuid, Geometries)
  {
    for(let geo of Geometries)
    {
      if(uuid === geo.UUID)
      {
        return geo
      }
    }

    return {}
  }



  setUpCallbacks()
  {
    globalCallbacks.UpdateHotpatchDialog = async (force = false) => 
    { 
      if( ! (force || this.state.open))
      {
        return
      }
      let objects    = await window.LR_GetSelectedObjects({IncludeGeometries: true})
      let properties = await window.LR_GetObjectProperties({Async: true});

      let patch = {}

      //---------------------------------------------------------------
      // Prepare 
      for(let object of objects)
      {
        for(let con of object.HotpatchConnections)
        {
          if(con.OwnWireType === kWireType_HotpatchOutput)
          {
            for(let c of con.Connections)
            {
              let optionName = object.UUID + "_" + c.Wire
              patch[optionName] = con.OwnWire
            }
          }

        }
      }

      this.setState({data: objects, patch: patch, AllInputs: properties.AllInputs})

      let object     = await window.LR_GetObjectTree({
        Async: true,
        IncludeProperties: [
          "Name",
          "AssemblyGroupName"
        ]
      });

      let Objects = {}
      for(let o of object)
      {
        Objects[o.UUID] = o
      }

      this.setState({Objects})


    }
    globalCallbacks.ShowHotpatchDialog = async () => 
    { 
      globalCallbacks.UpdateHotpatchDialog(true)
      this.show();
    } 
  }
}

export default HotpatchModal