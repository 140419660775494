//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React from 'react';
import { Divider, Header, Icon, Segment } from 'semantic-ui-react';
import LocalizedStrings from "../../localization/ExportStructuralCalculation";
const TableView = React.lazy(()=>import('../TableViews/TableViewComponent'))

import { globalCallbacks } from "../../util/callback";
import { QRCodeCanvas } from 'qrcode.react';

export let gWorksheets = {}


function ReplaceInPDF(header, text, i)
{
  return <>
            <Header key={i}>{header}</Header>
            <Segment placeholder>
              <Header icon>
                <Icon name='pdf file outline' />
                {text}
              </Header>
            </Segment>
          </>
}

export function Text(props) 
{
  return <p>{props.children.map((e,i) => 
    {
      if(e === "%%SHEETBREAK%%")
      {
        return <Divider key={i}/>
      }
      else if(e.startsWith && e.startsWith("%%TOC("))
      {
        return ReplaceInPDF(LocalizedStrings.TOC, LocalizedStrings.TOC_Text,i)
      }
      else if(e.startsWith && e.startsWith("%%QR_CODE_"))
      {
        return  <QRCodeCanvas key={i} value={"https://www.production-assist.com"}/>
      }
      else if(e.startsWith && e.startsWith("%%WORKSHEET("))
      {
        e = e.replace("%%WORKSHEET(", "")
        e = e.replace(")%%", "")
        return <TableView key={i} UUID={globalCallbacks.__gWorksheets[e]} markdownRenderer electron mobile={false}/>
      }
      if(e === "%%FEM_APPENDIX%%")  { return ReplaceInPDF(LocalizedStrings.FEM_APPENDIX, LocalizedStrings.FEM_APPENDIX_Text,i) }
      if(e === "%%HOIST_RESULTS%%") { return ReplaceInPDF(LocalizedStrings.HOIST_RESULTS, LocalizedStrings.HOIST_RESULTS_Text,i) }
      if(e === "%%DROP_RESULTS%%")  { return ReplaceInPDF(LocalizedStrings.DROP_RESULTS, LocalizedStrings.DROP_RESULTS_Text,i) }
      if(e === "%%BRIDLE_RESULTS%%"){ return ReplaceInPDF(LocalizedStrings.BRIDLE_RESULTS, LocalizedStrings.BRIDLE_RESULTS_Text,i) }
      if(e === "%%TRUSS_RESULTS%%") { return ReplaceInPDF(LocalizedStrings.TRUSS_RESULTS, LocalizedStrings.TRUSS_RESULTS_Text,i) }
      if(e === "%%LOAD_OVERVIEW%%") { return ReplaceInPDF(LocalizedStrings.LOAD_OVERVIEW, LocalizedStrings.LOAD_OVERVIEW_Text,i) }
      if(typeof e === "string")      
      {  
        if(e  === "\n")
        {
          return <br key={i}/>
        }
        if(e.includes("\n"))
        {
          let text = String(e).split("\n")
        return  text.filter(t1=>t1!=="").map(t=><>{t}<br/></>)
        }
        
        return e
        
      }
      
      return e
    })
  }</p>


}