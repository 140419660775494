//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Form } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/AddNewWindloadDialog";
import LRModal from '../Basics/BasicModal';
import { BASE_UNIT_ANGLE, BASE_UNIT_FORCE_PER_DISTANCE, BASE_UNIT_LENGTH } from '../../util/defines';
import UnitInput from '../Basics/BasicUnitInput';

import { globalCallbacks } from "../../util/callback";


//-----------------------------------------------------------------------------
// The component for the TreeControl
class AddNewWindLoadDialog extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
      LoadBottom: 0.000125,
      LoadHeigth: 4000,
      ForceAngle: 0,
      LoadTop: 0.000063
    }
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }

  show = async() => 
  {
    this.setState( { 
        open : true, 
      });
  }

  close = () => 
  {
    this.setState({open : false});
  }

  add = () => 
  {
    window.LR_AddNewWindLoad({
      LoadBottom: this.state.LoadBottom,
      LoadHeigth: this.state.LoadHeigth,
      ForceAngle: this.state.ForceAngle,
      LoadTop: this.state.LoadTop
    });
    this.close()
  }
    
  render() 
  {
    let open = this.state.open;
    if(!open) {return <div/>}

    return (
      <LRModal  open={open} 
                onCancelClick={this.close} 
                closeOnDimmerClick={false} 
                size="small"
                scrolling={false}
                title={LocalizedStrings.Header}
                onOkClick={this.add}>
        <Form>
          <UnitInput
                      fluid
                      label={LocalizedStrings.LoadBottom}
                      baseUnit      = {BASE_UNIT_FORCE_PER_DISTANCE}
                      value         = {this.state.LoadBottom}
                      onStateUpdate = {(name, value) => { this.setState({LoadBottom:value}) }} />
          <UnitInput
                      fluid
                      label={LocalizedStrings.ForceAngle}
                      baseUnit      = {BASE_UNIT_ANGLE}
                      value         = {this.state.ForceAngle}
                      onStateUpdate = {(name, value) => { this.setState({ForceAngle:value}) }} />
          <Form.Group widths="equal">
            <UnitInput
                        fluid
                        label={LocalizedStrings.LoadHeigth}
                        baseUnit      = {BASE_UNIT_LENGTH}
                        value         = {this.state.LoadHeigth}
                        onStateUpdate = {(name, value) => { this.setState({LoadHeigth:value}) }} />
            <UnitInput
                        fluid
                        label={LocalizedStrings.LoadTop}
                        baseUnit      = {BASE_UNIT_FORCE_PER_DISTANCE}
                        value         = {this.state.LoadTop}
                        onStateUpdate = {(name, value) => { this.setState({LoadTop:value}) }} />

          </Form.Group>

          
        </Form>
           
      </LRModal>
    )
  }

  setUpCallbacks()
  {

    globalCallbacks.ShowAddWindLoadDialog = () => 
    { 
      this.show();
    }
    
  }
}

export default AddNewWindLoadDialog