
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Icon, Button, Table, Form, Message, Loader} from 'semantic-ui-react'
import LocalizedStrings from "../../localization/ResourceManager";
import LRModal from '../Basics/BasicModal';
import { BASE_UNIT_LENGTH, BASE_UNIT_WEIGHT, IsRunningAsBrowser } from '../../util/defines';
import { globalCallbacks } from '../../util/callback';
import UnitInput from '../Basics/BasicUnitInput';
import LRTableHeaderCell from '../Basics/BasicTableView';



class TruckTemplateDisplay extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    {
      trucks: [],
      isLoading: false,

      open: false,

      editName: "",
      createSizeX: 0,
      createSizeY: 0,
      createSizeZ: 0,
      editAllowablePayload: 0,
    };

  }

  componentDidMount()
  {
    this.setUpCallbacks();
    if (globalCallbacks.updateTruckTemplates) { globalCallbacks.updateTruckTemplates(); }
  }

  render() 
  {
    return(
      <React.Fragment>
      <Table selectable striped celled>
        <Loader inverted content='Loading' />
        <Table.Header>
          <Table.Row>
            <LRTableHeaderCell>{LocalizedStrings.Name}</LRTableHeaderCell>
            <LRTableHeaderCell baseUnit={BASE_UNIT_LENGTH}>{LocalizedStrings.SizeX}</LRTableHeaderCell>
            <LRTableHeaderCell baseUnit={BASE_UNIT_LENGTH}>{LocalizedStrings.SizeY}</LRTableHeaderCell>
            <LRTableHeaderCell baseUnit={BASE_UNIT_LENGTH}>{LocalizedStrings.SizeZ}</LRTableHeaderCell>
            <LRTableHeaderCell baseUnit={BASE_UNIT_WEIGHT}>{LocalizedStrings.AllowablePayload}</LRTableHeaderCell>
          </Table.Row>
        </Table.Header>
        {this.state.trucks.length !== 0   ?
        <Table.Body>
          {this.state.trucks.map( entry => {return this.render_Row(entry)})}
        </Table.Body> 
        :
        this.state.isLoading ?<Message style={{width:'100%'}}><Loader active content={LocalizedStrings.Loading}/></Message> :  
                              <Message style={{width:'100%'}} warning><Message.Header>{LocalizedStrings.NoTrucksHeader}</Message.Header><p>{LocalizedStrings.NoTrucksText}</p></Message>}
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan='5'>
              <Button
              floated='right'
              icon
              labelPosition='left'
              primary
              onClick={this.openEditModal}
              size='small'><Icon name='truck' />{LocalizedStrings.AddTruck}</Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>     
      <LRModal  title={LocalizedStrings.EditTruckObjectsHeader}
                open={this.state.open}
                onCancelClick={this.onDiscardModal}
                onOkClick={this.onOkClick}>
          <Form>
            <Form.Input value={this.state.editName}    onChange={(e, {value}) => {this.setState({editName: value})}}/>
            <Form.Group widths="equal">
              <UnitInput name="SizeX" label={LocalizedStrings.SizeX} baseUnit={BASE_UNIT_LENGTH} value={this.state.createSizeX} onStateUpdate={(name, val) => {this.setState({createSizeX: val})}}/>
              <UnitInput name="SizeY" label={LocalizedStrings.SizeY} baseUnit={BASE_UNIT_LENGTH} value={this.state.createSizeY} onStateUpdate={(name, val) => {this.setState({createSizeY: val})}}/>
              <UnitInput name="SizeZ" label={LocalizedStrings.SizeZ} baseUnit={BASE_UNIT_LENGTH} value={this.state.createSizeZ} onStateUpdate={(name, val) => {this.setState({createSizeZ: val})}}/>
            </Form.Group>
            <UnitInput name="editAllowablePayload" label={LocalizedStrings.AllowablePayload} baseUnit={BASE_UNIT_WEIGHT} value={this.state.editAllowablePayload} onStateUpdate={(name, value) => {this.setState({[name]: Number(value)})}}/>
          </Form>
      </LRModal>
      </React.Fragment>
    );
  }

  onOkClick = () =>
  {
    window.LR_AddTruckTemplate({Name: this.state.editName, SizeX: this.state.createSizeX, SizeY: this.state.createSizeY, SizeZ: this.state.createSizeZ, AllowablePayload: this.state.editAllowablePayload}).then(() => {
      if (globalCallbacks.updateTruckTemplates && IsRunningAsBrowser()) { globalCallbacks.updateTruckTemplates() }
    })

    this.setState({open: false})
  }

  onDiscardModal = () =>
  {
    this.setState({open: false})
  }

  openEditModal = () =>
  {
    this.setState({open: true, editName: "New Truck Template", createSizeX: 0, createSizeY: 0, createSizeZ: 0})
  }

  onDeleteTruckTemplate = (entry) => {
    window.LR_DeleteTruckTemplate({Name: entry.Name}).then(() => {
      if (globalCallbacks.updateTruckTemplates && IsRunningAsBrowser()) { globalCallbacks.updateTruckTemplates() }
    })
  }

  render_Row(entry) 
  {
    return(<React.Fragment>
          <Table.Row>
            <Table.Cell>
              <Icon name="delete" color="red" link textAlign="right" onClick={() => this.onDeleteTruckTemplate(entry)}></Icon>
              {entry.Name}
            </Table.Cell>
            <Table.Cell>
              <UnitInput  baseUnit      = {BASE_UNIT_LENGTH} 
                          label         = {false}   
                          noStepper 
                          transparent 
                          name          = "SizeX"
                          value         = {this.state[entry.Name + "editSizeX"] ?? entry.SizeX}
                          onStateUpdate = {(name, value) => {this.setState({[entry.Name + "edit" + name]: value})}}
                          onUpdateData  = {(name, value) => {this.updateTruck(name, value, entry)}}/>
            </Table.Cell>
            <Table.Cell>
              <UnitInput  baseUnit      = {BASE_UNIT_LENGTH} 
                          label         = {false}   
                          noStepper 
                          transparent 
                          name          = "SizeY"
                          value         = {this.state[entry.Name + "editSizeY"] ?? entry.SizeY}
                          onStateUpdate = {(name, value) => {this.setState({[entry.Name + "edit" + name]: value})}}
                          onUpdateData  = {(name, value) => {this.updateTruck(name, value, entry)}}/>
            </Table.Cell>
            <Table.Cell>
              <UnitInput  baseUnit      = {BASE_UNIT_LENGTH} 
                          label         = {false}   
                          noStepper 
                          transparent 
                          name          = "SizeZ"
                          value         = {this.state[entry.Name + "editSizeZ"] ?? entry.SizeZ}
                          onStateUpdate = {(name, value) => {this.setState({[entry.Name + "edit" + name]: value})}}
                          onUpdateData  = {(name, value) => {this.updateTruck(name, value, entry)}}/>
            </Table.Cell>
            <Table.Cell>
              <UnitInput  baseUnit      = {BASE_UNIT_WEIGHT} 
                          label         = {false}   
                          noStepper 
                          transparent 
                          name          = "AllowablePayload"
                          value         = {this.state[entry.Name + "editAllowablePayload"] ?? entry.AllowablePayload}
                          onStateUpdate = {(name, value) => {this.setState({[entry.Name + "edit" + name]: value})}}
                          onUpdateData  = {(name, value) => {this.updateTruck(name, value, entry)}}/>
            </Table.Cell>
          </Table.Row>
          </React.Fragment>)
  }

  updateTruck = (name, value, entry) => {
    entry[name] = this.state[entry.Name + "edit" + name]
    window.LR_SetTruckTemplate(entry).then(() => {
      if (globalCallbacks.updateTruckTemplates && IsRunningAsBrowser()) { globalCallbacks.updateTruckTemplates() }
    })
  }

  setUpCallbacks()
  {
    globalCallbacks.updateTruckTemplates = async () =>
    {
      this.setState({isLoading: true})
      let res = await window.LR_GetTruckTemplateMap() 
      this.setState({trucks: res.ResourceMap.truckTemplate, isLoading: false})
    }
  }
}

export default TruckTemplateDisplay;

