import React, { Component, useEffect, useState } from "react";
import { globalCallbacks } from "../../util/callback";
import { BASE_UNIT_DATE } from "../../util/defines";
import LRModal from "../Basics/BasicModal";
import UnitInput from "../Basics/BasicUnitInput"
import LocalizedStrings from "../../localization/RequestFile";
import { Button, Checkbox, Dimmer, Divider, Dropdown, Grid, Header, Icon, Input, List, Loader, Message, Segment, Table } from "semantic-ui-react";
import { globalWindowInterface, uuidStr } from "../../util/callbackTypes";

declare const window: globalWindowInterface

interface IMVRFile {
    FileID: uuidStr
    FromStation: uuidStr
    Description: string
    Received: string // Date
    FilePath: string
    FileSize: number
}

interface IDrawingSettings {
    MVRxchangeRunning: boolean,
    MVRxchangeServiceName: string,
    MVRxchangeConnected: boolean,
    ExternMVRxchangeFiles: { [key: uuidStr]: IMVRFile }
    InternMVRxchangeFiles: { [key: uuidStr]: IMVRFile }
}

function SelectNewMVRXchangeService() {
    const [connectionLoading, setConnectionLoading] = useState(false)
    const [availableLoading, setAvailableLoading] = useState(false)
    const [availableLoading_Interfaces, setAvailableLoading_Interfaces] = useState(false)
    const [typeValue, setTypeValue] = useState("")
    const [services, setServices] = useState<{ name: string, count: number }[]>([])
    const [mvrInterfaces, setInterfaces] = useState<{ adapterNam: string, friendlyNam: string, ip4: string, ip6: string }[]>([])

    const checkMVRName = (name: string) => {
        setTypeValue(name.split('').filter(char => /[a-zA-Z0-9\-\_]/.test(char)).join(''))
    }
    const updateDataMVR_Services = async () => {
        setAvailableLoading(true)

        let out: typeof services = []
        for (let i of await window.LR_GetMVRxchangeServices()) {
            let f = out.find(j => j.name === i.ServiceName)
            if (f) {
                f.count++
            } else {
                out.push({ name: i.ServiceName, count: 1 })
            }
        }
        setServices(out)

        setAvailableLoading(false)
    }

    const updateDataMvrInterfaces = async () => {
        setAvailableLoading_Interfaces(true)

        let out: typeof mvrInterfaces = []

        out.push({ adapterNam: "", friendlyNam : LocalizedStrings.AllInterfaces, ip4 : "", ip6 : ""})

        for (let i of await window.LR_GetMVRxchangeInterfaces()) {
            {
                if (i.ip4.length > 0) // Only consider Ip4 Interfaces
                {
                    out.push({ adapterNam: i.adapterNam, friendlyNam: i.friendlyNam, ip4 : i.ip4, ip6 : i.ip6 })
                }                
            }
        }        

        setInterfaces(out)

        setAvailableLoading_Interfaces(false)
    }

    const selectService = (name: string) => {
        window.LR_SetProjectSettings({
            MVRxchangeServiceName: name,
            MVRxchangeRunning: true
        })
        setConnectionLoading(true)
    }

    const selectInterface = (name: string) => {
        window.LR_MVRxchnageSelectInterface({interfNam : name})
    }

    useEffect(() => {
        updateDataMVR_Services()
        updateDataMvrInterfaces()
    }, [])

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center'}}>
                <Dropdown                     
                    placeholder={LocalizedStrings.ChooseInterface}
                    onChange={(e, {value}) => 
                        {
                            selectInterface(value.toString())
                        }}
                    fluid 
                    selection 
                    options={mvrInterfaces.map((intfc, index) => ({
                        key: index.toString(),
                        text: `${intfc.friendlyNam} ${intfc.ip4 ? ` (${intfc.ip4})` : ''}`,
                        value: intfc.adapterNam
                    }))}
                    style={{ position: 'relative', zIndex: 10 }}
                />
                <Button icon="redo" style={{ marginLeft: 5 }} size="small" disabled={availableLoading_Interfaces} loading={availableLoading_Interfaces} onClick={() => updateDataMvrInterfaces()} />
            </div>

            <Segment>
                <Dimmer active={connectionLoading}>
                    <Loader active={connectionLoading} />
                </Dimmer>

                <Grid columns={2} relaxed='very'>
                    <Grid.Column>
                        <Header as='h3' dividing>
                            {LocalizedStrings.CreateNewXchangeGroup}
                        </Header>
                        <div style={{ width: "100%", height: "80%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                            <Input action={
                                <Button icon="linkify" onClick={() => selectService(typeValue)} disabled={!typeValue.length} />
                            }
                                placeholder={LocalizedStrings.NewXChangeGroupNamePlaceholder}
                                onChange={(a, { value }) => checkMVRName(value)}
                                value={typeValue}
                            />
                            <div style={{ marginTop: 3, marginLeft: 2 }}>
                                {LocalizedStrings.CharactersAllowed} {[
                                    "a-z",
                                    "A-Z",
                                    "0-9",
                                    "-",
                                    "_"
                                ].map(i => <kbd>{i} </kbd>)}
                            </div>
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <Header as='h3' dividing style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                            {LocalizedStrings.SelectAvailableServices}
                            <Button icon="redo" style={{ marginRight: 5 }} size="small" disabled={availableLoading} loading={availableLoading} onClick={() => updateDataMVR_Services()} />
                        </Header>
                        <List divided relaxed>
                            {
                                services.map(val =>
                                    <List.Item style={{ display: "flex", flexDirection: "row", justifyContent: "left" }}>
                                        <Button icon="linkify" onClick={() => selectService(val.name)} />
                                        <List.Content style={{ marginLeft: 10 }}>
                                            <List.Header>{val.name}</List.Header>
                                            <List.Description>{LocalizedStrings.NumberFoundDevices} {val.count}</List.Description>
                                        </List.Content>
                                    </List.Item>
                                )
                            }
                        </List>
                    </Grid.Column>
                </Grid>

                <Divider vertical>{LocalizedStrings.Or}</Divider>
            </Segment>
        </div>
    )
}

function ManageMVRServiceConnection({ settings }: { settings: IDrawingSettings }) {
    const [newFileName, setNewFileName] = useState("")
    const [unlinkLoading, setUnlinkLoading] = useState(false)

    const requestFile = (UUID: uuidStr) => {
        window.LR_GetMVRxchangeFile({ UUID })
    }

    const publishNew = (Description: string) => {
        window.LR_CreateNewMVRxchangeRevision({ Description })
        setNewFileName("")
    }

    const unlinkMVRxchange = () => {
        window.LR_SetProjectSettings({
            MVRxchangeServiceName: "",
            MVRxchangeRunning: false
        })
        setUnlinkLoading(true)
    }

    const externEntries = Object.entries(settings.ExternMVRxchangeFiles)
    const internEntries = Object.entries(settings.InternMVRxchangeFiles)

    return <Segment.Group>
        <Segment style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <Header style={{ margin: 0 }}>
                {LocalizedStrings.ConnectedTo} {settings.MVRxchangeServiceName}
            </Header>
            <Button icon labelPosition='left' negative onClick={unlinkMVRxchange} loading={unlinkLoading} disabled={unlinkLoading}>
                <Icon name='unlinkify' />
                {LocalizedStrings.Disconnect}
            </Button>
        </Segment>
        <Segment>
            <Header>
                {LocalizedStrings.ExternAvailableFiles}
            </Header>
            <List divided relaxed>
                {
                    externEntries.map(([_, val]) =>
                        <List.Item style={{ display: "flex", flexDirection: "row", justifyContent: "left" }}>
                            <Button icon="download" onClick={() => requestFile(val.FileID)} />
                            <List.Content style={{ marginLeft: 10 }}>
                                <List.Header>{val.Description}</List.Header>
                                <List.Description>Station ID: {val.FromStation}</List.Description>
                                <List.Description>File ID: {val.FileID}</List.Description>
                                <List.Description>
                                    <UnitInput
                                        baseUnit={BASE_UNIT_DATE}
                                        value={val.Received}
                                        readOnly
                                    />
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    )
                }
            </List>
            {externEntries.length ? null : <Message
                icon="inbox"
                header={LocalizedStrings.NoFilesAvailableHeader}
                content={LocalizedStrings.NoFilesAvailableText}
            />}
        </Segment>
        <Segment>
            <Header>
                {LocalizedStrings.PublishedFiles}
            </Header>
            <List divided relaxed>
                {
                    internEntries.map(([_, val]) =>
                        <List.Item style={{ display: "flex", flexDirection: "row", justifyContent: "left" }}>
                            <List.Content style={{ marginLeft: 10 }}>
                                <List.Header>{val.Description}</List.Header>
                                <List.Description>File ID: {val.FileID}</List.Description>
                                <List.Description>
                                    <UnitInput
                                        baseUnit={BASE_UNIT_DATE}
                                        value={val.Received}
                                        readOnly
                                    />
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    )
                }
            </List>
            {internEntries.length ? null : <Message
                icon="inbox"
                header={LocalizedStrings.NoFilesAvailableHeader}
                content={LocalizedStrings.NoFilesPublishedText}
            />}
            <Input action={
                <Button onClick={() => publishNew(newFileName)}>{LocalizedStrings.Publish}</Button>
            }
                placeholder={LocalizedStrings.NewRevisionNamePlaceholder}
                onChange={(a, { value }) => setNewFileName(value)}
                value={newFileName}
                fluid
            />
        </Segment>
    </Segment.Group>
}

export default function MVRXchangeSettingsModal(this: unknown, { }) {
    const [open, setOpen] = useState(false)
    const [drawingSettings, setDrawingSettings] = useState<IDrawingSettings>()

    useEffect(() => {
        globalCallbacks.openMVRxchangeSettingsModal = async () => {
            setOpen(true)
        }

        globalCallbacks.updateMVRXchangeModal = async () => {
            window.LR_GetDrawingSettings().then(settings => {
                setDrawingSettings(settings)
            })
        }
        globalCallbacks.updateMVRXchangeModal()
    }, [open])

    if (!drawingSettings) {
        return null
    }

    return (
        <LRModal open={open}
            title={LocalizedStrings.Header}
            onOkClick={() => setOpen(false)}
            noCancel
        >
            {
                drawingSettings.MVRxchangeConnected ?
                    <ManageMVRServiceConnection settings={drawingSettings} />
                    :
                    <SelectNewMVRXchangeService />
            }
        </LRModal>
    )
}
