//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2019 www.deersoft.de
//-----------------------------------------------------------------------------
import LocalizedStrings from 'localized-strings';
import LocalizeOption from './LocalizationOption';

import en from "./raw/en/ConnectLoadCells.json"
import de from "./raw/de/ConnectLoadCells.json"
import es from "./raw/es/ConnectLoadCells.json"
import fr from "./raw/fr/ConnectLoadCells.json"
import it from "./raw/it/ConnectLoadCells.json"
import ru from "./raw/ru/ConnectLoadCells.json"
import cz from "./raw/cs/ConnectLoadCells.json"
 

let Strings = new LocalizedStrings<Partial<typeof en>>(
  {
    en: en,
    de: de,
    es: es,
    fr: fr,
    ru: ru,
    it: it,
    cz: cz,
  },
  { ...LocalizeOption }
);
  
  export default  Strings;