//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React from "react"
import LRModal from "../Basics/BasicModal";
import { globalCallbacks } from "../../util/callback";
import { Input } from "semantic-ui-react";
import LocalizedStrings from "../../localization/AskForGroupName";

class AskForGroupNameModal extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state = {
            open: false,
            name: ""
        }
    }

    componentDidMount()
    {
        this.setUpCallbacks()
    }

    render()
    {
        return  <LRModal    open={this.state.open}
                            onOkClick={this.onConfirm}
                            onCancelClick={this.close}
                            title={LocalizedStrings.Header}>
                    <Input fluid value={this.state.name} label="Group Name" onChange={(e, {value}) => {this.setState({name: value})}} autoFocus/>
                </LRModal>
    }

    onConfirm = () =>
    {
        window.LR_GroupSelected({Name: this.state.name})
        this.close()
    }

    open = () =>
    {
        this.setState({open: true, name: "Group"})
    }

    close = () =>
    {
        this.setState({open: false})
    }

    setUpCallbacks()
    {
        globalCallbacks.showAskForGroupName = () =>
        {
            this.open()
        }
    }
}

export default AskForGroupNameModal;