import React, { Component } from "react";
import { Grid, Icon, Divider, Button, Form, Header,Card, List, Loader } from 'semantic-ui-react';
import LocalizedStrings from "../../localization/SelectProjectView";
import { timeSince } from "../../util/time";
import LRModal from "../Basics/BasicModal";
import BasicSelect from "../Basics/BasicSelect";
import CreateNewProject from "../CreateNewProject/CreateNewProject";
import ProjectFolder from "../ProjectFolder/ProjectFolder";

import { globalCallbacks } from "../../util/callback";

class SelectProjectView extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
      projects: [],
      selectedProject: "",
      selectedBranch: "",
      selectedBranchName: "",
      loadingProject: false,
      createtingNewProject: false,
      finalCall: "",
      branches: {},
      projectData:[],
      errorMessage:false,
      selectedUserAcc: undefined
    }
    this.child = React.createRef();

    
  }

  componentDidMount = () =>
  {
    this.setUpCallbacks();
  }

  render() 
  {
    let isCommit = this.state.finalCall ==="LR_CommitToServer"
    return (
      <LRModal  open={this.state.open}
                title={<>{isCommit ? LocalizedStrings.HeaderCommit : LocalizedStrings.HeaderOpen}      <Button floated="right" positive onClick={this.getProjects}><Icon loading={this.state.loadingProject} name="redo"/> {LocalizedStrings.Reload}</Button></>}

                dimmer= ""
                onCancelClick={this.close}
                customOKText={isCommit ? LocalizedStrings.CreateNewProject : LocalizedStrings.HeaderOpen}
                onOkClick={isCommit ? this.onCreateNewProject : this.onUseExistingProject}
                size="fullscreen"
                scrolling={false}
                okDisabled={this.state.projectNameExists || (!isCommit && this.state.selectedProject==="") || (!isCommit &&this.state.selectedBranch==="") || (this.state.errorMessage && !this.state.selectedUserAcc)}
                >
          {isCommit ? this.renderCommit() : this.renderUpdate()}
      </LRModal>
    )
  }

  renderCommit()
  {
    return( <>

        <CreateNewProject errorMessage={this.state.errorMessage} ref={this.child} selectedUserAcc={(user) => {this.setState({selectedUserAcc:user})}} doesProjectNameExist={(exists) => {this.setState({projectNameExists: exists})}}/>
  </>)
  }

  onChangeProject = (name, value, e)=>
  { 
    let branches = this.state.branches[value]
    this.setState({selectedProject: value, selectedBranch: branches[branches.length - 1].value, selectedBranchName: branches[branches.length - 1].text})
  }

  

  renderUpdate()
  {
    return( 
      <>
      <Grid columns="2">
      <Grid.Column>
          <Header as="h1">{LocalizedStrings.Project}</Header>
          <Divider/>
          <Form>
          <Header as="h3">{LocalizedStrings.SelectProject}</Header>
          <Form.Group>
            <BasicSelect normalDropdown placeholder={LocalizedStrings.Project} search fluid selection options={this.state.projects} value={this.state.selectedProject} onStateUpdate={this.onChangeProject} />
            <Button icon onClick={this.getProjects} loading={this.state.loadingProject} attached="right"><Icon name='refresh'/></Button>
          </Form.Group>
            <Header disabled={!this.state.branches[this.state.selectedProject]} as="h3">{LocalizedStrings.Branch}</Header>
          <Form.Group>
            <BasicSelect normalDropdown disabled={!this.state.branches[this.state.selectedProject]} placeholder={LocalizedStrings.PlaceHolderBranch} search fluid selection options={this.state.branches[this.state.selectedProject]} value={this.state.selectedBranch} onStateUpdate={(name, value, e)=>{ let text = this.state.branches[this.state.selectedProject].find(b => b.value === value).text; this.setState({selectedBranch: value, selectedBranchName: text})}} />
          </Form.Group>
          </Form>
              {this.showFolders()}
        </Grid.Column>
        <Grid.Column>
        <Header as="h1">{LocalizedStrings.RecentProjects}</Header>
        <Divider/>
        <Card.Group>
          {this.state.loadingProject && this.state.projectData.length === 0 ? <Loader active /> : null}
          {this.state.projectData.map(project =>
              <Card key={project.owner.username+"/"+project.name} onClick={()=>{this.finalCallExistingProject(project.owner.username, project.name, project.defaultBranch.id, project.defaultBranch.name)}}>
              <Card.Content>
                <Card.Header>{project.owner.username}/{project.name}</Card.Header>
                <Card.Meta>{timeSince(Date.parse(project.lastChanged))}</Card.Meta>
                <Card.Description>
                  <List link>
                  <List.Item as="a" onClick={(event)=>{event.stopPropagation(); this.finalCallExistingProject(project.owner.username, project.name, project.defaultBranch.id, project.defaultBranch.name)}}>
                    <List.Icon name='folder open' />
                    <List.Content>{"master"}</List.Content>
                  </List.Item>
                  {project.branches.map(b=>{return b.closed ? null :
                    <List.Item as="a" onClick={(event)=>{event.stopPropagation();this.finalCallExistingProject(project.owner.username, project.name, b.id, b.name)}} key={b.id}>
                      <List.Icon name='folder open' />
                      <List.Content>{b.name}</List.Content>
                    </List.Item>})}
                  </List>
                </Card.Description>
              </Card.Content>
            </Card>
            )}
        
        </Card.Group>
        </Grid.Column>
        
      </Grid>
     
       
      </>)
  }

  getProjects = async () =>
  {
    this.setState({loadingProject:true})

    // Get Projects
    let projects = await window.LR_GetProjectsAsync() 

    if (!projects || !Array.isArray(projects)) { this.setState({loadingProject:false}); return; }

    let sortedProjects = projects.sort((a, b) => 
    {
      if (a.owner.username === b.owner.username)  { if (a.projectname < b.projectname)  { return -1; } else { return 1; } }
      return a.owner.username < b.owner.username ? -1 : 1;
    })

    let branches = {}
    
    let projectArray = sortedProjects.map(entry => 
    {
      let key = entry.projectname + "_" + entry.owner.username

      // Fill branches
      branches[key] = entry.branches.filter(branch => !branch.closed).map(branch => 
      {
        return { 
          text:   branch.name,
          key:    branch.id,
          value:  branch.id,
        }
      });

      if (entry.defaultBranch) 
      {
        branches[key].push({ 
          text:   entry.defaultBranch.name,
          key:    entry.defaultBranch.id,
          value:  entry.defaultBranch.id,
          label: { children: LocalizedStrings.DefaultBranch },
        })
      }

      

      // Fill projects
      return { 
        text:   entry.name,
        key:    entry.projectname,
        user:   entry.owner.username,
        value:  key,
        label: { children: entry.owner.username },
      }
    })


    //------------------------------------------------------------------
    // FIlter and sort project
    let sorted = projects.sort((a,b)=> Date.parse(b.lastChanged)-Date.parse(a.lastChanged))

    this.setState({loadingProject: false, projects: projectArray, branches: branches, projectData: sorted.slice(0,9)})

  }

  showFolders = () => 
  {
      return (
            <div style={{height:"40em", width:"50vw", overflow:"hidden", marginLeft:"-1rem"}}>
                <ProjectFolder />
            </div>
      )
  }

  onCreateNewProject = async () =>
  {
    this.setState({createtingNewProject:true})
    let data = this.child.current.getData()

    if(!data.Name){
      this.setState({errorMessage:true})
    }


    let ret = await window.LR_CreateNewProjectAsync(data)
    
    if(ret.Project.ProjectName !== "")
    {
      this.setState({createtingNewProject:false, open: false})

      window[this.state.finalCall]({Project: ret.Project.ProjectName, User:ret.Project.UserName, Branch: ret.Project.BranchName, Checkout: true})
    }
  }

  close =  () =>
  {
    this.setState({selectedProject:false, selectedBranch:false, selectedBranchName: "", open: false})
  }

  onUseExistingProject = async () =>
  {
    // Find Project
    let match = undefined
    this.state.projects.forEach(project => 
    {
      if((project.key + "_" + project.user) === this.state.selectedProject) { match = project }
    })

    if(match)
    {
      this.finalCallExistingProject(match.user, match.key, this.state.selectedBranch, this.state.selectedBranchName)
    }
   
  }

  finalCallExistingProject = async (owner, project, branch, branchName) =>
  {
    this.close()

    if(this.state.finalCall !=="LR_CheckoutProject" && this.state.finalCall !=="LR_ImportLRWX")
    {
      await window.LR_SetLinkedProject({Project: project, Owner:owner, Branch: branch, BranchName: branchName})
    }

    await window[this.state.finalCall]({Project: project, User:owner, Branch: branch, BranchName: branchName, Checkout: true})
  }

  setUpCallbacks()
  {
    globalCallbacks.openSelectActiveProject = async (arg) => 
    {
      this.setState({open:true, finalCall: arg.Call, errorMessage: false})
      this.getProjects();
    }

    globalCallbacks.closeProjectCheckoutModal = async (arg) => 
    {
      this.close();
    }
  } 
}


export default SelectProjectView;