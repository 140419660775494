//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';
import LocalizedStrings from "../../../localization/SupportView";
import { BASE_UNIT_LENGTH, BASE_UNIT_FORCE, BASE_UNIT_WEIGHT, BASE_UNIT_STRING, EMPTY_UUID, HOIST_REPORT_YSORTING } from '../../../util/defines';
import UnitInput from '../../Basics/BasicUnitInput';
import DynamicTable from '../../Basics/DynamicTableView';



import { globalCallbacks as mainCB } from '../../../util/callback';
import { globalCallbacks as mockCB } from '../../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB


const cellIdentifier = [
    { ident: "PosInList", label: LocalizedStrings.PosInList, sortable: true },
    { ident: "ObjectId", label: LocalizedStrings.ObjectID, sortable: true },
    { ident: "Name", label: LocalizedStrings.Name, sortable: true },
    { ident: "Weight", label: LocalizedStrings.Weight, unit: BASE_UNIT_WEIGHT, sortable: true },
    { ident: "HookForce", label: LocalizedStrings.HoistForce, unit: BASE_UNIT_FORCE, sortable: true },
    { ident: "ReactionForce", label: LocalizedStrings.RoofForce, unit: BASE_UNIT_FORCE, sortable: true },
    { ident: "OffsetX", label: LocalizedStrings.XPos, unit: BASE_UNIT_LENGTH, sortable: true },
    { ident: "OffsetY", label: LocalizedStrings.YPos, unit: BASE_UNIT_LENGTH, sortable: true },
    { ident: "Distance", label: LocalizedStrings.Distance, unit: BASE_UNIT_LENGTH, sortable: true },
    { ident: "Manufacturer", label: LocalizedStrings.Manufacturer, sortable: true },
    { ident: "AssemblyGroupName", label: LocalizedStrings.AssemblyGroup, sortable: true },
    { ident: "HangingType", label: LocalizedStrings.HangingType, sortable: true },
    { ident: "UpsideDown", label: LocalizedStrings.UpsideDown, sortable: true }]

class SupportList extends Component {
    constructor(prop) {
        super(prop);
        this.state =
        {
            supports: [],
            currView: this.props.sorting
        }

        this.setUpCallbacks()

    }

    componentDidMount() {
        if (globalCallbacks.updateSupportList) { globalCallbacks.updateSupportList(); }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.sorting !== this.props.sorting) { globalCallbacks.updateSupportList() }
    }


    renderYList(data) {
        let AnzLeft = 0
        let AnzRight = 0
        for (let i of data) {
            if (i.Left.length > AnzLeft)
            AnzLeft = i.Left.length
            if (i.Right.length > AnzRight)
            AnzRight = i.Right.length
        }

        let rows = []
        let key = 0

        for (let i of data) {
            let thisRow = []


            for (let j = 0; j < AnzLeft; j++)
                if (j < AnzLeft - i.Left.length) {
                    thisRow.push(<Table.Cell key={key++}></Table.Cell>)
                }
                else {
                    thisRow.push(<Table.Cell key={key++}>
                        <UnitInput baseUnit={BASE_UNIT_LENGTH} value={i.Left[j - AnzLeft + i.Left.length].HoistOriginOffsetDistance} label={false} transparent readOnly />
                        <br />
                        {i.Left[j - AnzLeft + i.Left.length].Name}
                        <br />
                        {i.Left[j - AnzLeft + i.Left.length].ObjectID}
                    </Table.Cell>)
                }

            thisRow.push(<Table.Cell active key={key++}><UnitInput baseUnit={BASE_UNIT_LENGTH} value={i.Distance} label={false} transparent readOnly /></Table.Cell>)


            for (let j = 0; j < AnzRight; j++)
                if (j >= i.Right.length)
                    thisRow.push(<Table.Cell key={key++}></Table.Cell>)
                else
                    thisRow.push(<Table.Cell key={key++}>
                        <UnitInput baseUnit={BASE_UNIT_LENGTH} value={i.Right[j].HoistOriginOffsetDistance} label={false} transparent readOnly />
                        <br />
                        {i.Right[j].Name}
                        <br />
                        {i.Right[j].ObjectID}
                    </Table.Cell>)

            rows.push(
                <Table.Row key={key++}>
                    {thisRow}
                </Table.Row>
            )
        }
        return (
            <div style = {{width: `${(AnzLeft+AnzRight+1)*40}mm`, overflow: "auto"}}>
            <Table striped celled textAlign='center'>
                <Table.Body>
                    {rows}
                </Table.Body>
            </Table>
            </div>
        )
    }

    render() {


        if (this.state.currView === HOIST_REPORT_YSORTING) 
        {
            return this.renderYList(this.state.supports)
        }


        let supports = this.applySearchFilter(this.state.supports)

        let renderCell = (object, index) => {
            return {
                PosInList: index + 1,
                ObjectId: object.ObjectId,
                Name: object.Name,
                Weight: <UnitInput baseUnit={BASE_UNIT_WEIGHT} value={object.Weight} label={false} transparent readOnly />,
                HookForce: <UnitInput baseUnit={BASE_UNIT_FORCE} value={object.DefiningSupportInfo.ReactionForce} label={false} transparent readOnly />,
                ReactionForce: <UnitInput baseUnit={BASE_UNIT_FORCE} value={object.DefiningSupportInfo.ReactionForce} label={false} transparent readOnly />,
                OffsetX: <UnitInput baseUnit={BASE_UNIT_LENGTH} value={object.OffsetX} label={false} transparent readOnly />,
                OffsetY: <UnitInput baseUnit={BASE_UNIT_LENGTH} value={object.OffsetY} label={false} transparent readOnly />,
                Distance: <UnitInput baseUnit={BASE_UNIT_LENGTH} value={object.Distance} label={false} transparent readOnly />,
                Manufacturer: <UnitInput baseUnit={BASE_UNIT_STRING} value={object.Manufacturer} label={false} transparent readOnly />,
                AssemblyGroupName: object.AssemblyGroupName,
                HangingType: object.DefiningSupportInfo.ConnectedTop === EMPTY_UUID ? LocalizedStrings.HangingPoint : LocalizedStrings.Drop,
                UpsideDown: object.DefiningSupportInfo.UpsideDown ? LocalizedStrings.True : LocalizedStrings.False,
            }
        }

        return <DynamicTable headerIdent={cellIdentifier}
            cellRender={renderCell}
            cellData={supports}
            pages={this.props.pages}
            headerData={this.props.headerData}
            sortFunction={this.sortFunction}
            tableName="SupportList"
            worksheet={this.props.worksheet}
            showPages={this.props.showPages}
            fitColumnWidthToContent={this.props.showPages}
            printMargin={this.props.printMargin}
            printScale={this.props.worksheetScale} />
    }


    applySearchFilter = (objects) => {
        if (!this.props.searchFilter) { return objects }
        return objects.filter(element => {
            for (let i = 0; i < cellIdentifier.length; i++) {
                let currentIdent = cellIdentifier[i].ident
                if (!element[currentIdent]) { continue; }
                let elementAsString = element[currentIdent].toString()
                if (elementAsString.toLowerCase().includes(this.props.searchFilter.toLowerCase())) {
                    return true
                }

            }
            return false
        })
    }

    setUpCallbacks() {
        globalCallbacks.updateSupportList = async () => {
            let list = await window.LR_GetSupportList({ Type: this.props.sorting, origin: this.props.origin ? this.props.origin : null })
            this.setState({ supports: list.Supports, currView: this.props.sorting })
        }
    }

    sortFunction = (objectA, objectB, propertyIdentifer) => {
        if (propertyIdentifer === "HangingType") {
            return {
                First: objectA.DefiningSupportInfo.ConnectedTop === EMPTY_UUID ? 0 : 1,
                Second: objectB.DefiningSupportInfo.ConnectedTop === EMPTY_UUID ? 0 : 1
            }
        }

        if (propertyIdentifer === "UpsideDown") {

            return {
                First: objectA.DefiningSupportInfo.UpsideDown ? 0 : 1,
                Second: objectB.DefiningSupportInfo.UpsideDown ? 0 : 1
            }


        }
        else {
            return { First: objectA[propertyIdentifer], Second: objectB[propertyIdentifer] }
        }
    }

}

export default SupportList;