//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Table, Icon, Input, Popup, List, Select, Dropdown, Checkbox } from 'semantic-ui-react'
import { ToTimeCode } from '../../util/time';
import { Link } from 'react-router-dom';
import {  IsRunningAsBrowser } from '../../util/defines';

import { addNotification } from '../NotificationDisplay/NotificationDisplay';
import LocalizedStringsNotification from "../../localization/Notification";
import LocalizedStrings from "../../localization/NavigationContainer";

class WorksheetNode extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = {
           ...this.props.worksheet
        }
    }
    render() 
    {
        
        let linkName = false
        if(IsRunningAsBrowser())
        {
        if (this.props.active_project.data)
        {
            linkName = this.props.active_project.data.owner.username + "/" + this.props.active_project.data.name
        }
        }


        return (
            <Table.Row>
                <Table.Cell collapsing width={1}>
                    <Checkbox checked={this.props.checked} onChange={()=>{this.props.onSelectWorksheet(this.props.worksheet.UUID, !this.props.checked)}}/>
                </Table.Cell>
                <Table.Cell  width={4}>
                    <Popup trigger={
                                        <Input  transparent
                                                    name      = "Name" 
                                                    value     = {this.state.Name} 
                                                    onChange  = {this.onValueChanged} 
                                                    onBlur    = {() => this.updateData("Name", this.state.Name)}
                                                    onKeyDown = {this.onKeyDown}
                                                    fluid/>
                                    }
                            position="left center">
                        <p><b>{LocalizedStrings.LinkedTimePhase} : {this.state.LinkedTimeLinePhaseChangeObject.Name}</b></p>
                        <p><b>{LocalizedStrings.TotalTime}   : {ToTimeCode(this.state.TimeDuration)}</b></p>
                        <p><b>{LocalizedStrings.AssignedUsers}  : {this.state.DefaultAssingedUsers.length} 
                            {this.renderDefaultAssingedUserList()}</b></p>
                    </Popup>
                        </Table.Cell>
                    <Table.Cell collapsing width={5} textAlign="left">
                        
                    <Select 
                            compact
                            search
                            options = {this.props.timeLineOptions}
                            value = {this.props.LinkedTimeLinePhaseChange}
                            onChange = {(event, {value})=>{window.LR_SetWorksheet({UUID: this.state.UUID, LinkedTimeLinePhaseChange: value }); this.setState({LinkedTimeLinePhaseChange:value })}}/>
                    </Table.Cell>
                    <Table.Cell collapsing width={3}>
                    <Dropdown  
                            compact
                            style={{marginTop:"1em"}}
                            fluid
                            multiple
                            search
                            selection
                            value={this.props.DefaultAssingedUsers}
                            onChange = {(event, {value})=>{window.LR_SetWorksheet({UUID: this.state.UUID, DefaultAssingedUsers: value }); this.setState({DefaultAssingedUsers:value })}}
                            options={this.props.Users}
                />
                    </Table.Cell>
                <Table.Cell textAlign="center" collapsing width={3}>
                        <Popup content={LocalizedStrings.OpenWorksheet}
                        trigger={ linkName ?                       
                            <Icon name='file' as={linkName?Link:undefined} to={linkName??`/${linkName}/worksheet/uuid/${this.state.UUID}`}/> :
                            <Icon name='file' onClick={()=>{window.LR_ShowWorkSheet({UUID: this.state.UUID})}}/>     
                                        }
                                        />
                    <Popup content={LocalizedStrings.OpenTasksWorksheet}
                    trigger=
                    {
                    <Icon link name="world" color="blue" onClick={this.openTask}/>
                    }/>
                    <Popup content={LocalizedStrings.DeleteWorksheet}
                    trigger=
                    {
                        <Icon link name="delete"color="red"  onClick= {()=>this.onDelete(this.state.UUID)}/>
                    }/>
                </Table.Cell>
            </Table.Row>
        );
    }

    openTask = async () =>
    {
        let result = await window.LR_GetLinkedProject()
        if(result.Owner)
        {
            window.LR_OpenLink({UseBaseUrl: true, URL: "/" + result.Owner + "/" + result.Project + "/tasks"}) 
        }
        else
        {
            addNotification(LocalizedStringsNotification.NoOnlineProjectSelectedHeader, LocalizedStringsNotification.NoOnlineProjectSelectedText)
        }
        
    }

    renderUserList =() =>
    {
        return(
            <List>
                {this.state.AssignedUsers.map((user, i) => <List.Item key={i}>{user.Name}:{ToTimeCode(user.TheoreticalTimeDuration)}</List.Item>)}
            </List>
        )
    }
    renderDefaultAssingedUserList =() =>
    {
        return(
            <List>
                {this.state.DefaultAssingedUsers.map((user, i) => <List.Item key={i}>{user}</List.Item>)}
            </List>
        )
    }

    onValueChanged = (e, {name, value}) =>
    {
        this.setState({[name]:value});
    }

    updateData = (name, value, event) =>
    {
        let request = 
        {
            UUID: this.state.UUID
        };

        if(name)    { request[name] = value; }
        else        { request.Name  = this.state.Name; }

        
        window.LR_SetWorksheet(request);
    }

    onDelete = (uuid) =>
    {
        window.LR_DeleteWorksheet({UUID: uuid})
    }

    onKeyDown = (event) =>
    {
        if(event.keyCode === 13) { this.updateData(); event.target.blur() }
    }
}

export default WorksheetNode;

