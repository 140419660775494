//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Link } from "react-router-dom";
import { Breadcrumb, Divider, Dropdown, Header, Icon, Loader, Menu } from 'semantic-ui-react';
import LocalizedStrings from "../../localization/ResourceManager";
import { FetchProjects, hasFetched } from '../../redux/actions/fetch_actions';
import { lrServerConnection } from '../../redux/light_right_server_connection';
import { PROJECTS } from "../../redux/redux_defines";
import { RESPONSIVE_PHONE } from '../../util/defines';
import CallBackWrapper from '../../webApp/CallBackWrapper';
import { withRouter } from '../../webApp/WebRouter';
import NotFound from '../NotFound/NotFound';
import UserAvatar from '../WebComponents/UserAvatar';

class UserOverview extends Component 
{
  render()
  {
    let {match} = this.props;
    lrServerConnection.setProject("")
    lrServerConnection.setUser(match.params.username)
    FetchProjects()

    let {projects} = this.props;

    if( ! hasFetched(projects))
    {
      return <Loader/>
    }

    if(projects.data.length === 0)
    {
      return <NotFound/>
    }

    let name = match.params.username

  // Get name (insensitive case) of user who has his projects and his collab projects by checking if username from url matches with username of one of his project  
    for (let project of this.props.projects.data)
    {
      if(match.params.username === project.owner.username)
      {
        name = project.owner.name;
        break;
      }
    }

    window.document.title = `${match.params.username}`
    return (
      <MediaQuery minWidth={RESPONSIVE_PHONE}>
      {desktop =>
        <>
        <Breadcrumb>
        <Header>
          <UserAvatar user={match.params.username}/><Breadcrumb.Section link>{name}</Breadcrumb.Section>
          {!desktop ?  this.renderMenuMobile(): null}
        </Header>
        </Breadcrumb>
        <Divider/>
        {desktop ?  this.renderMenu(): null}
        <CallBackWrapper>
          {React.Children.map(this.props.children, (child => React.cloneElement(child, { match: this.props.match,WebApp:true  })))}
        </CallBackWrapper>
        </>
      }
      </MediaQuery>
    );
  }

  renderMenu =() =>
  {
    let activeProjects = this.props.activeMenu === "projects";
    let activeSymbolMap = this.props.activeMenu === "symbolmap";;
    let activeFieldMap = this.props.activeMenu === "fieldmap";;
    let activeTruckTemplate = this.props.activeMenu === "trucktemplate";;
    let activeCaseTemplate = this.props.activeMenu === "casetemplate";;
    let activeRackTemplate = this.props.activeMenu === "racktemplate";;
    //let activeInventory = this.props.activeMenu === "inventory";;
    //let activeMentaince = this.props.activeMenu === "mentenaince";;
    //let activeSheduledMentainance = this.props.activeMenu === "sheduledmentainance";;
    let activeChecks = this.props.activeMenu === "checks";;
    let activeCSS = this.props.activeMenu === "crosssection";;
    let activeMat = this.props.activeMenu === "material";;
    let activePaper = this.props.activeMenu === "papertemplates";;
    let signingjob = this.props.activeMenu === "signingjob";;

    return(<Menu>
            <Menu.Item key={1} as={Link} to={`/${this.props.match.params.username}`}                     active={activeProjects}><Icon name='file' />{LocalizedStrings.Projects}</Menu.Item>
            <Menu.Item key={2} as={Link} to={`/${this.props.match.params.username}/symbolmap`}           active={activeSymbolMap}><Icon name='map signs' />{LocalizedStrings.SymbolMap}</Menu.Item>
            <Menu.Item key={3} as={Link} to={`/${this.props.match.params.username}/fieldmap`}            active={activeFieldMap}><Icon name='map' />{LocalizedStrings.VWFieldMap}</Menu.Item>
            <Menu.Item key={4} as={Link} to={`/${this.props.match.params.username}/trucktemplate`}       active={activeTruckTemplate}><Icon name='truck' />{LocalizedStrings.TruckRessources}</Menu.Item>
            <Menu.Item key={5} as={Link} to={`/${this.props.match.params.username}/casetemplate`}        active={activeCaseTemplate}><Icon name='box' />{LocalizedStrings.CaseRessources}</Menu.Item>
            <Menu.Item key={6} as={Link} to={`/${this.props.match.params.username}/racktemplate`}        active={activeRackTemplate}><Icon name='th' />{LocalizedStrings.RackRessources}</Menu.Item>
            <Menu.Item key={9} as={Link} to={`/${this.props.match.params.username}/checks`}              active={activeChecks}><Icon name='file code' />{LocalizedStrings.ChangeListChecks}</Menu.Item>
            <Menu.Item key={9} as={Link} to={`/${this.props.match.params.username}/crosssection`}        active={activeCSS}><Icon name='won' />{LocalizedStrings.CrossSectionResources}</Menu.Item>
            <Menu.Item key={9} as={Link} to={`/${this.props.match.params.username}/material`}            active={activeMat}><Icon name='tag' />{LocalizedStrings.MaterialResources}</Menu.Item>
            <Menu.Item key={9} as={Link} to={`/${this.props.match.params.username}/papertemplates`}      active={activePaper}><Icon name='file outline' />{LocalizedStrings.PaperTemplates}</Menu.Item>
            <Menu.Item key={10} as={Link} to={`/${this.props.match.params.username}/signingjobs`}      active={signingjob}><Icon name='flag checkered' />{LocalizedStrings.CalculationChecks}</Menu.Item>
            {/*<Menu.Item key={7} as={Link} to={`/${this.props.match.params.username}/inventory`}           active={activeInventory}><Icon name='flag checkered' />{LocalizedStrings.InventoryTab}</Menu.Item>*/}
            {/*<Menu.Item key={8} as={Link} to={`/${this.props.match.params.username}/mentenaince`}         active={activeMentaince}><Icon name='flag checkered' />{LocalizedStrings.MentenainceTab}</Menu.Item>*/}
            {/*<Menu.Item key={9} as={Link} to={`/${this.props.match.params.username}/sheduledmentainance`} active={activeSheduledMentainance}><Icon name='time' />{LocalizedStrings.SheduledMentenainceTab}</Menu.Item>*/}
          </Menu>)
  }

  renderMenuMobile =() =>
  {
    let activeProjects = this.props.activeMenu === "projects";
    let activeSymbolMap = this.props.activeMenu === "symbolmap";;
    let activeFieldMap = this.props.activeMenu === "fieldmap";;
    let activeTruckTemplate = this.props.activeMenu === "trucktemplate";;
    let activeCaseTemplate = this.props.activeMenu === "casetemplate";;
    let activeRackTemplate = this.props.activeMenu === "racktemplate";;
    //let activeInventory = this.props.activeMenu === "inventory";;
    //let activeMentaince = this.props.activeMenu === "mentenaince";;
    //let activeSheduledMentainance = this.props.activeMenu === "sheduledmentainance";;
    let activeChecks = this.props.activeMenu === "checks";;

    return(<Dropdown inline item text={""} compact>
      <Dropdown.Menu>
            <Dropdown.Item key={1} as={Link} to={`/${this.props.match.params.username}`}                     active={activeProjects}><Icon name='file' />{LocalizedStrings.Projects}</Dropdown.Item>
            <Dropdown.Item key={2} as={Link} to={`/${this.props.match.params.username}/symbolmap`}           active={activeSymbolMap}><Icon name='map signs' />{LocalizedStrings.SymbolMap}</Dropdown.Item>
            <Dropdown.Item key={3} as={Link} to={`/${this.props.match.params.username}/fieldmap`}            active={activeFieldMap}><Icon name='map' />{LocalizedStrings.VWFieldMap}</Dropdown.Item>
            <Dropdown.Item key={4} as={Link} to={`/${this.props.match.params.username}/trucktemplate`}       active={activeTruckTemplate}><Icon name='truck' />{LocalizedStrings.TruckRessources}</Dropdown.Item>
            <Dropdown.Item key={5} as={Link} to={`/${this.props.match.params.username}/casetemplate`}        active={activeCaseTemplate}><Icon name='box' />{LocalizedStrings.CaseRessources}</Dropdown.Item>
            <Dropdown.Item key={6} as={Link} to={`/${this.props.match.params.username}/racktemplate`}        active={activeRackTemplate}><Icon name='th' />{LocalizedStrings.RackRessources}</Dropdown.Item>
            <Dropdown.Item key={9} as={Link} to={`/${this.props.match.params.username}/checks`}              active={activeChecks}><Icon name='file code' />{LocalizedStrings.ChangeListChecks}</Dropdown.Item>
            {/*<Dropdown.Item key={7} as={Link} to={`/${this.props.match.params.username}/inventory`}           active={activeInventory}><Icon name='flag checkered' />{LocalizedStrings.InventoryTab}</Dropdown.Item>*/}
            {/*<Dropdown.Item key={8} as={Link} to={`/${this.props.match.params.username}/mentenaince`}         active={activeMentaince}><Icon name='flag checkered' />{LocalizedStrings.MentenainceTab}</Dropdown.Item>*/}
            {/*<Dropdown.Item key={9} as={Link} to={`/${this.props.match.params.username}/sheduledmentainance`} active={activeSheduledMentainance}><Icon name='time' />{LocalizedStrings.SheduledMentenainceTab}</Dropdown.Item>*/}
            </Dropdown.Menu>
          </Dropdown>)
  }
}

//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      projects: (state[PROJECTS])
    };
}

export default connect(mapStateToProps)(withRouter(UserOverview))
