//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2024
//----------------------------------------------------------------------------------------------------------------

import React, { Component } from 'react';
import { Container, ButtonGroup, Dimmer, Loader, Grid, List, Checkbox } from 'semantic-ui-react';
import "./video-react.css"; 
import "./new-tutorial.css"
import { getLocale } from "../../util/defines";
import LocalizedStrings from "../../localization/ProductionAssistLandingPage";
import {unified} from 'unified';
import remarkParse from 'remark-parse';
import YouTube from "react-youtube";
import BasicSelect from '../Basics/BasicSelect';
import CONST from "../../webApp/CONST";
const NewNavBar = React.lazy(() => import("../ProductionAssistLandingPage/NewNavBar"));
import NewFooter from '../WebComponents/NewFooter';
import { Link } from 'react-router-dom';
class NewTutorialVideos extends Component {

  constructor() {
    super();
    this.state = {
      loading: false,
      lang: getLocale(),
      ast: null,
      toc: [],
      selectedTopic: null,
      showVideoModal: false,
    };
  }
  componentDidMount = async () => {

    this.reloadMarkdown()
  }

  handleSelectedTopicChange = (index) => {
    this.setState((prevState) => ({
      selectedTopic: prevState.selectedTopic === index ? null : index,
    }));
  };

  reloadMarkdown = async () => {
    const url = new URL(`${CONST.BASE_URL}/api/markdown/${this.state.lang}/pa_tutorial.md`);
    this.setState({ loading: true });

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Failed to load markdown content');
      }

      const text = await response.text();
      const processor = unified().use(remarkParse);
      const ast = processor.parse(text);
      const toc = this.generateSection(ast);
      this.setState({ ast, toc, loading: false, selectedTopic: toc.length > 0 ? 0 : null });
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  };

  generateSection = (ast) => {
    const toc = [];
    let currentSection = null;

    const renderTocItem = (node, depth) => {
      if (node.type === 'heading') {
        if (currentSection) {
          toc.push(currentSection);
        }
        const itemText = node.children[0].value;
        currentSection = { text: itemText, content: [], videoUrl: null };
      } else if (node.type === 'image' && currentSection) {
        currentSection.videoUrl = node.url;
      } else if (currentSection) {
        currentSection.content.push(node);
      }

      if (node.children) {
        node.children.forEach((child) => renderTocItem(child, depth + 1));
      }
    };

    if (ast) {
      ast.children.forEach((child) => renderTocItem(child, 1));
      if (currentSection) {
        toc.push(currentSection);
      }
    }

    return toc.filter((item) => item.videoUrl);
  };

  renderContent = (content) => {
    return content.map((node, index) => {
      if (node.type === 'text') {
        return <p key={index}>{node.value}</p>;
      } else {
        return null;
      }
    });
  };

  closeVideoModal = () => this.setState({ showVideoModal: false });

  render() {
    const {  loading, toc, selectedTopic, showVideoModal } = this.state;
    const languageOptions = [
      { key: 'English', flag: "uk", text: "English", value: 'en' },
      { key: 'German', flag: "de", text: "Deutsch", value: 'de' },
      { key: 'Spanish', flag: "es", text: "Español", value: 'es' },
      { key: 'French', flag: "fr", text: "Français", value: 'fr' },
    ];

    if (loading) {
      return (
        <div className='newtutorial'>
          <header>
            <NewNavBar/>
            <div className="main-heading">
              <h1>{LocalizedStrings.Tutorials}</h1>
              <h3>{LocalizedStrings.WeAreOffering}</h3>
              <Link to="https://meetings.engagebay.com/MoritzStaffel"><button className="btn grey-btn">{LocalizedStrings.TalkToUs}</button></Link>
            </div>
          </header>
          <div style={{ position: 'relative' }}>
            <Container>
              <Dimmer active>
                <Loader size='large'>Loading...</Loader>
              </Dimmer>
            </Container>
          </div>
          <NewFooter />
        </div>
      );
    }

    // Divide the toc into three columns
    const columns = [[], [], []];
    toc.forEach((item, index) => {
      columns[index % 3].push({ ...item, index });
    });

   return (
    <div className='newtutorial'>
      <header>
        <NewNavBar/>
        <div className="main-heading">
          <h1>{LocalizedStrings.Tutorials}</h1>
          <h3>{LocalizedStrings.WeAreOffering}</h3>
          <Link to="https://meetings.engagebay.com/MoritzStaffel"><button className="btn grey-btn">{LocalizedStrings.TalkToUs}</button></Link>
        </div>
      </header>
      <div>
        <div>
          <Dimmer active={this.state.loading}><Loader /> </Dimmer>
          <div className='content'>
            <div className='topic'>
              <ButtonGroup floated="right" className='language'>
                <BasicSelect
                  normalDropdown
                  button
                  className='icon'
                  labeled
                  icon='world'
                  options={languageOptions}
                  onStateUpdate={(name, value, e) => { this.setState({ lang: value }, () => this.reloadMarkdown()) }}
                  value={this.state.lang}
                />
              </ButtonGroup>
              <h2>{LocalizedStrings.TutorialsFor2DAnd3D}</h2>
              <Grid stackable columns={3}>
                {columns.map((column, columnIndex) => (
                  <Grid.Column key={columnIndex}>
                    <List>
                        {column.map((item) => (
                          <div key={item.index} className='el' >
                            <List.Item onClick={()=> this.setState({showVideoModal: true})} key={item.index} style={{paddingTop: "1rem", cursor: "pointer"}}>
                              <Checkbox
                                radio
                                label={item.text}
                                checked={selectedTopic === item.index}
                                onChange={() => this.handleSelectedTopicChange(item.index)}
                              />
                            </List.Item>
                            <div className='mobile underline'></div>
                          </div>
                        ))}
                        </List>
                  </Grid.Column>
                ))}
              </Grid>
            </div>

            {selectedTopic !== null && toc[selectedTopic] && (
                <div className='detail desktop'>
                  <div className='text-content'>
                    {this.renderContent(toc[selectedTopic].content)}
                  </div>
                  <div className='youtube'>
                    <YouTube videoId={new URLSearchParams(new URL(toc[selectedTopic].videoUrl).search).get('v')} />
                  </div>
                </div>
              )}

            {showVideoModal && selectedTopic !== null && toc[selectedTopic] && (
              <div className='video-modal mobile'>
                <div className='video-modal-content'>
                  <button className='close-button' onClick={this.closeVideoModal}>×</button>
                  <div className='text-content'>
                    {this.renderContent(toc[selectedTopic].content)}
                  </div>
                  <div className='youtube'>
                    <YouTube videoId={new URLSearchParams(new URL(toc[selectedTopic].videoUrl).search).get('v')} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <NewFooter />
    </div>
   );
  }
}

export default NewTutorialVideos