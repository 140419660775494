//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from "react";
import { Icon, Table } from "semantic-ui-react";
import { getDPMM, IsDarkTheme } from "../../util/defines";
import LRTableHeaderCell from "./BasicTableView";
import { cie2hex } from "../ColorPicker/utilities";

// props description
// (required) props.headerIdent : array of string identifiers for the headers, [{ident: String, label: String, unit?: BASE_UNIT_ , sortable?: boolean, editable?: boolean }] where the default for sortable is the existance of 'sortFunction'
// (required) props.cellRender  : object to define how each cell should be rendered,  (rowEntry, rowIndex) => ({[ident]: element: JSXElement})
// (required) props.cellData    : an array of objects thats will be looped and passed in the cellRender function, an entry can control whether it's 'editable'
// props.extraCellRender        : object to define how each extra cell should be rendered, (parentEntry, extraEntry, extraIndex) => ({[ident]: JSXElement})
// props.collapsExtraData       : boolean wether the extra data should be collapsable
// props.sortFunction           : a function to get the cell data, in order to sort them, (objectA, objectB, cellIdentifier) => ({First: <ValueA>, Second: <ValueB>})
// props.selectionChanged       : a function when the selection finished changing, ([entries]) => ()
// props.headerData             : map of cell widths and indices, will be aplied at mount?, {[TableName]: {[PropertyName]: {Index: Integer, Width: Float}}}
// props.tableName              : the table name used for the cellData
// props.footerRow              : the footer for the table
// props.worksheet              : a worksheet uuid
// props.onSort                 : a callback function that's executed at the end of sorting the table, (sortedProperty, sortedDirection) => { <do something> } 
// props.sortProperties         : an array of objects defining the sorting ([{ident: <property name>, dir: <sorting direction ("descending" or "ascending")>}])
// props.showPages              : wether to show the pages, boolean
// props.printMargin            : the margin of the page, {Bottom: Float, Left: Float, Right: Float, Top: Float} all in mm
// props.fitColumnWidthToContent: if true, the table will shrink the column widths to the max width of it's content

// when the CellData contains an array called ExtraData, this will be rendered with the extraCellRender function

let theme = IsDarkTheme()
let headerStyle = {
    position: "sticky",
    top: "0",
    zIndex: 2,
    backgroundColor: theme ? "#303030" : "#F9FAFB",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
}

function PdfFormatToStyle(pdfFormat){
    if(!pdfFormat){
        return {}
    }
    return {
        fontWeight: pdfFormat.Bold ? "bold" : "normal",
        fontSize: pdfFormat.FontSize,
        fontStyle: pdfFormat.Italic ? "italic" : "normal",
        marginLeft: pdfFormat.SpaceBefore,
        marginRight: pdfFormat.SpaceAfter,
        textDecoration: pdfFormat.Underlined ? "underline" : "none",
        color: cie2hex(pdfFormat.Color)
    }
}

export let TABLE_SELECTION_TYPE_None = "None"
export let TABLE_SELECTION_TYPE_Cell = "Cell"
export let TABLE_SELECTION_TYPE_Row  = "Row"

const defaultWidth = 50

var tableKey = 1

class DynamicTable extends Component
{
    static defaultProps = {
        selectionType: TABLE_SELECTION_TYPE_None
    }

    constructor(props)
    {
        super(props)
        this.state = {
            indexMap: {},
            widthMap: {},
            expandedRows: [],
            selectedRows: [],
            selectedExtraRows: [],
            selectedSingleLine: {RowIndex: -1, ExtraIndex: 0},
            sortProperties: this.props.sortedProperties ? this.props.sortedProperties : []
        }

        this.cellIdentifier = []
        this.isSelecting = false
        this.selectedArrayIndex = 0
        this.countPages = 1

        this.selectingIdent = ""
        this.selectedCellIdent = undefined


        // Wether selecting or delselecting
 
        this.selectionMode = false
        this.selectionRef = React.createRef()

        this.initialColumnFit = this.props.fitColumnWidthToContent
        this.cellDataRendered = false

    }

    componentDidMount()
    {
    
        this.updateTableSize()

        window.addEventListener("mouseup", (e) => {


            if (this.isBoxSelecting)
            {
                if (this.props.selectionChanged)
                {
                    let selectedEntries = []
        
                    this.state.selectedRows.forEach(index => {
                        selectedEntries.push(this.cellData[index])
                    })
                    this.state.selectedExtraRows.forEach((val, index) => {
                        if (!val) { return; }
                        if (!this.state.selectedRows.includes(index))
                        {
                            let data = this.cellData[index]
                            if (data)
                            {
                                selectedEntries.push(data)
                            }
                        }
                    })
    
                    this.props.selectionChanged(this.selectedCellIdent, selectedEntries, this.selectedArrayIndex)
             
                }
            }
            this.isSelecting = false

            this.isBoxSelecting = false
            if (this.selectionRef && this.selectionRef.current)
            {
                this.selectionRef.current.style.opacity = 0
            }            
        })

        window.addEventListener("mousemove", (e) => {
            if (this.props.selectionType !== TABLE_SELECTION_TYPE_Cell) 
            {
                if (this.selectionRef && this.selectionRef.current)
                {
                    this.selectionRef.current.style.opacity = 0
                }
            }
            else if (this.isBoxSelecting)
            {
                let flexLayoutBoxPosition = this.getTabSetParent(this.selectionRef.current);
                let newWidth = (e.clientX - this.startBoxSelection.x)
                let newHeight = (e.clientY - this.startBoxSelection.y)

                if (newWidth < 0)
                {
                    newWidth = -newWidth

                    if(flexLayoutBoxPosition.left)
                    {
                        this.selectionRef.current.style.left = e.clientX - flexLayoutBoxPosition.left + "px";
                    }else
                    {
                        this.selectionRef.current.style.left = e.clientX + "px";
                    }
                }
                if (newHeight < 0)
                {
                    newHeight = -newHeight
                    
                    if(flexLayoutBoxPosition.top)
                    {
                        this.selectionRef.current.style.top = e.clientY - flexLayoutBoxPosition.top + "px";
                    }else
                    {
                        this.selectionRef.current.style.top = e.clientY + "px";
                    }
                }

                this.selectionRef.current.style.width = newWidth + "px"
                this.selectionRef.current.style.height = newHeight + "px"
            }
        })

        window.addEventListener("keydown", (e) => {
        
            let selectedCommandLineText;

            if(window.getSelection)
            {
                selectedCommandLineText = window.getSelection().toString()
            }
          
            if(e.code.startsWith("Arrow") && !selectedCommandLineText)
            { 
                return;
            }

            if(e.code === "ArrowLeft" || e.code === "ArrowRight" ){
                this.selectSingleCellByArrowKeyLeftOrRight(e.code)
            }

            if(e.code === "ArrowUp"){
            
                this.selectSingleCellByArrowKeyUp()
            }
  
            if(e.code === "ArrowDown"){
               
                this.selectSingleCellByArrowKeyDown() 
            }

            if (e.code === "Escape")
            {
                this.isSelecting = false
                
                this.setState({selectedRows: [], selectedExtraRows: []})
            }

            if (e.code === "Tab")
            {
                e.preventDefault();
                this.handleTabKeyPress()
            }
        })
    }

    componentDidUpdate = (prevProps, prevState) => {

        if(prevProps.headerData !== this.props.headerData)
        {
          this.updateTableSize()
        }
        // only fit columns on update if table has been rendered with cell data,
        // otherwise there would be no body yet (controlled by cellDataRendered)
        // and only do that once (controlled by initialColumnFit)
        if (this.initialColumnFit && this.cellDataRendered) { 
            this.initialColumnFit = false
            this.fitColumnWidthToContent(false)
        } 

        if(prevProps.headerIdent.length !== this.props.headerIdent.length)
        {
            let columns = document.querySelectorAll("th")

            if(columns && columns.length > 3)
            {
                let lastColumn = columns[columns.length-1]
                lastColumn.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})  
            }
        }
        // when worksheet preset changes, we update table column width
        if(prevProps.selectedPreset !== this.props.selectedPreset)
        {
            this.fitColumnWidthToContent(true, true)
        }
    }

    updateTableSize()
    {
        if (this.props.tableName && this.props.headerData)
        {
            let tableCellData = this.props.headerData[this.props.tableName]
            if (tableCellData)
            {
                let newIndexMap = {}
                let newWidthMap = {}
                Object.entries(tableCellData).forEach(([name, value]) => {
                    if (value.Index >= 0)
                    {
                        newIndexMap[name] = value.Index
                    }
                    newWidthMap[name] = value.Width
                })   
                this.setState({indexMap: newIndexMap, widthMap: newWidthMap})
            }
        }
    }

    sortCellIdentifier()
    {
        let result = []
        this.cellIdentifier.forEach((ident, index) => {
            if (this.state.indexMap[ident.ident] !== undefined) { 
                result[this.state.indexMap[ident.ident]] = {...ident}; 
            }
            else { result.push({...ident}); }
        })
        let removeEmptyResult = []
        for (let i = 0; i < result.length; i++)
        {
            if (result[i] !== undefined)
            {
                removeEmptyResult.push(result[i])
            }
        }
        result = removeEmptyResult
        this.cellIdentifier = result;
    }

    onSortTable = (propertyIdentifier, secondary = false) =>
    {
        let oldProps = this.state.sortProperties
        if(!secondary)
        {
            oldProps = this.state.sortProperties.slice(0,1)
            if(oldProps[0] && oldProps[0].ident !== propertyIdentifier)
            {
                oldProps = []
            }
        }
        let foundProp = oldProps.find(prop => prop.ident === propertyIdentifier)
        if(foundProp) 
        {
            if(foundProp.dir === "ascending")
            {
                this.setState({
                    sortProperties: oldProps.map(prop => prop.ident === propertyIdentifier ? {ident: prop.ident, dir: 'descending'} : prop)
                }, this.onSortCallback)
            }
            else
            {
                this.setState({
                    sortProperties: oldProps.filter(prop => prop.ident !== propertyIdentifier)
                }, this.onSortCallback)
            }
        } 
        else
        {
            this.setState({
                sortProperties: [...oldProps, {ident: propertyIdentifier, dir: 'ascending'}]
            }, this.onSortCallback)
        }
    }


    onSortCallback = () => {
      // execute callback function
      if (this.props.onSort) {
        this.props.onSort(
          this.state.sortProperties
        );
      }
    }

    sortFunc = (identifier, dir) => (a, b) => 
        {
            let propA = undefined
            let propB = undefined
            if (this.props.sortFunction)
            {
                let sortValues = this.props.sortFunction(a, b, identifier)
                if (sortValues)
                {
                    propA = sortValues.First
                    propB = sortValues.Second
                }
            }
            else
            {
                propA = a[identifier];
                propB = b[identifier];
            }
            
        
            if (propA === undefined) { propA = 0; }
            if (propB === undefined) { propB = 0; }

            
            let c = 0
            if(typeof propB == "number" && typeof propA == "number")
            {
                c = propA - propB
            }
            else
            {
                c = String(propA).localeCompare(String(propB), undefined, {numeric: true, sensitivity: 'base'})
            }

            

            if (c > 0) 
            {
                return dir === "ascending" ? 1 : -1;
            } else if (c < 0) 
            {
                return dir === "ascending" ? -1 : 1;
            }
            return 0;
        }

    sortBy(dataArray, sortProperties)
    {
        if (!Array.isArray(sortProperties) || sortProperties.length === 0) { return dataArray.map((o, index) => ({...o, originalIndex: index})); }
        let sortedData = dataArray.map((o, index) => ({...o, originalIndex: index}))


        if(Array.isArray(sortProperties)) {
            [...sortProperties].reverse().forEach(secondary => {
                sortedData = sortedData.sort(this.sortFunc(secondary.ident, secondary.dir))
            })
        }

        return sortedData
    }

    /**
     * @param {{RowIndex: Number, ExtraIndex: Number}} selection 
     */
    selectSingleCompleteRow = (selection) =>
    {
        if (this.props.selectionType !== TABLE_SELECTION_TYPE_Row) { return; }

        let newSelection = {...selection}
        if (this.state.selectedSingleLine.RowIndex === selection.RowIndex && this.state.selectedSingleLine.ExtraIndex === selection.ExtraIndex)
        {
            newSelection = {RowIndex: -1, ExtraIndex: 0}
        }

        this.setState({selectedSingleLine: newSelection})

        if (this.props.singleRowSelectionChange)
        {
            this.props.singleRowSelectionChange(newSelection)
        }
    }

    render()
    {
        if (!this.props.cellRender) { return <div>No Cell renderer set for dynamic table view</div>}
        if (!this.props.cellData) { return <div>No cell data for the table</div>}

        if (this.props.cellData.length !== 0) {
            this.cellDataRendered = true
        }

        this.cellIdentifier = this.props.headerIdent;
        this.sortCellIdentifier()
        

        this.cellData = this.sortBy(this.props.cellData, this.state.sortProperties) 


        if (this.props.showPages === true)
        {
            return this.renderWithPages()
        }
        return this.renderWithoutPages()
    }

    renderWithPages = () =>
    {
        let pageMarginX = this.props.printMargin.Left + this.props.printMargin.Right

        let pages = this.props.pages

        tableKey++;


        let tableScale = this.props.printScale ? this.props.printScale : 1
        

        let cellIdentifierPerPage = []
        cellIdentifierPerPage[0] = []
        let currentPage = 0;

        let currentWidth = pageMarginX;

        for (let i = 0; i < this.cellIdentifier.length; i++)
        {
            cellIdentifierPerPage[currentPage].push(this.cellIdentifier[i])
            currentWidth += this.getWidthOfProp(this.cellIdentifier[i].ident) * tableScale;
            
            if (i + 1 < this.cellIdentifier.length)
            {
                if (currentWidth + (this.getWidthOfProp(this.cellIdentifier[i + 1].ident) * tableScale) > pages.width)
                {
                    currentWidth = pageMarginX;
                    currentPage++;
                    cellIdentifierPerPage[currentPage] = []
                }
            }
        }

        this.countPages = cellIdentifierPerPage.length

        let pageColumns = []
        let cellIndexOffset = 0
        // Render pages background
        for (let i = 0; i < this.countPages; i++)
        {
            let cellIdents = cellIdentifierPerPage[i]
            if (!Array.isArray(cellIdents)) { break;}
            let pageWidth = 0
            cellIdents.forEach((ident, index) => {
                pageWidth += this.getWidthOfProp(ident.ident)
            })


            const tableId = this.getTableName() + i
            pageColumns.push(
                <>{this.renderTable(cellIdentifierPerPage[i], pageWidth, cellIndexOffset, false, tableScale, tableId)}</>
            )

            cellIndexOffset += cellIdents.length
        }

        let marginStyle = {
            marginTop: 10,
            marginBottom: 10,
        }


        return (
            <div style={marginStyle}>{pageColumns}</div>
        )
    }

    fitColumnWidthToContent = (updateToCore, showSorting = false) => 
    {
        this.setAllWidths(50, () => {
            const traverseElements = (f, root) => {
                f(root);
                let children = root.children
                for (let i = 0; i < children.length; i++) {
                    traverseElements(f, children.item(i))
                }
            }
            
            let tables = []
            if (this.props.showPages) {
                for (let i=0; i < this.countPages; i++) {
                    tables.push(document.getElementById(this.getTableName() + i))
                }
            }
            else {
                tables = [document.getElementById(this.getTableName())]
            }
    
            // measures width of node
            let measureWidth = (node, parent) => {
                let text = document.createElement("span");
                parent.replaceChild(text, node);
      
                text.style.position = 'absolute';
                text.style.whiteSpace = 'nowrap';
                text.style.paddingRight =  showSorting ? "3rem" : ""

                text.appendChild(node)
      
                let width = Math.ceil(text.clientWidth);
                parent.replaceChild(node,text)
    
                return width
            }
            
            let dpmm = getDPMM()
            let wm = this.state.widthMap
            let sizes = []                // will store width needed for each column
            let i = 0                     // the current column/header ident
            let firstIdent = 0            // index for the first ident for the current table

            // loop over all tables and for each column determine the width
            // of the largest cell
            tables.forEach(table => {
                if (!table)
                    return

                traverseElements(elem => {
                    switch (elem.tagName) {
                        case "TR": i = firstIdent; break; // to beginning of row
                        case "TH":
                        case "TD":
                            if(!sizes[i]) sizes[i] = 0
                            
                            // if there is no content, the current size is correct
                            if (elem.firstChild){
                                sizes[i] = Math.max(sizes[i], measureWidth(elem.firstChild, elem) / dpmm)
                            }

                            i++; 
                            break;
        
                        default: break;
                    }
                }, table)

                firstIdent = i
            })
            
            sizes.forEach((size,i) => 
            {
                // In the sizes there are also extra properties from the grouping sometimes
                if(i < this.cellIdentifier.length)
                {
                    wm[this.cellIdentifier[i].ident] = size + 6 > 10 ? size + 6 : 10
                }
            
            }) // +6 to account for margin
            this.setState({widthMap: wm})
            if (updateToCore) { this.updateCellHeaderData(this.createCoreCellData(this.state.widthMap, this.state.indexMap)) }
        })
    }

    renderWithoutPages = () =>
    {
        let tableWidth = 0
        this.cellIdentifier.forEach((ident, index) => {
            tableWidth += this.getWidthOfProp(ident.ident)
        })

        return <>
            {this.renderTable(this.cellIdentifier, tableWidth)}
            <div ref={this.selectionRef} style={{left:0, right:0, top:0, bottom:0, pointerEvents: "none", border: "1px solid #55aaff", backgroundColor: "rgba(75, 160, 255, 0.3)", position: "absolute", opacity: 0, overflow:"hidden"}}></div>
        </>
    }

    getTableName = () => {
        return this.props.tableName ?? "DefaultTableName"
    }

    renderTable = (cellIdents, tableWidth, cellIdentsOffset = 0, usePrintMargin = false, scale = 1, tableId=this.getTableName()) =>
    {        
        let extraColumns = []


        let globExtraLine = false
        for (let i = 0; i < this.cellIdentifier.length; i++)
        {
            if (this.cellIdentifier[i].extraColumns)
            {
                globExtraLine = true;
                break;
            }
        }

        let marginStyle = {}
        if (usePrintMargin)
        {
            marginStyle.marginBottom = this.props.printMargin.Bottom + "mm"
            marginStyle.marginTop = this.props.printMargin.Top + "mm"
            marginStyle.marginLeft = this.props.printMargin.Left + "mm"
            marginStyle.marginRight = this.props.printMargin.Right + "mm"
        }

        return <Table  celled 
                compact 
                unstackable
                size='small' 
                sortable 
                striped
                id={tableId}
                selectable={this.props.selectionType === TABLE_SELECTION_TYPE_Row}
                key={tableKey + "Table"}
                style = {{
                    position:"relative",
                    zIndex: this.props.showPages ? 1 : 0,
                    MozUserSelect: "none", 
                    WebkitUserSelect: 'none', 
                    msUserSelect: 'none', 
                    width: !this.props.autoCellSize ? (tableWidth + "mm") : "",
                    transform: "scale(" + scale + ")",
                    transformOrigin: "top left",
                    wordBreak: "break-all",
                    tableLayout: "fixed",
                    ...marginStyle
                }}>
            <Table.Header key={tableKey + "Header"} >
                <Table.Row>
                    {cellIdents.map((ident, index) => {
                        let width = !this.props.autoCellSize ? (this.getWidthOfProp(ident.ident) + "mm") : ""
                        
                        let rowSpan = globExtraLine && !ident.extraColumns ? 2 : 1
                        let colSpan = ident.extraColumns ? ident.extraColumns.length : 1
                        
                        const isSortable = ident.sortable !== undefined ? ident.sortable : this.props.sortFunction

                        if (ident.extraColumns)
                        {
                            ident.extraColumns.forEach(column => {
                                extraColumns.push(<Table.Cell style={headerStyle}>{column.label}</Table.Cell>)
                            })
                        }

                        let selectCompleteCell = () =>
                        {
                            let selectedEntries = []
                            let lastCellData = this.cellData[this.cellData.length - 1]

                            let selected = this.selectFromTo({DataIndex: 0, ExtraIndex: 0}, {DataIndex: this.cellData.length - 1, ExtraIndex: lastCellData.ExtraData ? (lastCellData.ExtraData.length - 1) : 0 })

                            selected.SelectedRows.forEach(index => {
                                selectedEntries.push(this.cellData[index])
                            })
                            this.selectingIdent = ident.ident
                            this.setState({selectedRows: selected.SelectedRows, selectedExtraRows: selected.SelectedExtraRows})
                            this.props.selectionChanged(ident, selectedEntries, 0)
                        }
                        //
                        let sortProp
                        let sortNumber = 1
                        for (const prop of this.state.sortProperties) 
                        {
                            if(prop.ident === ident.ident)
                            {
                                sortProp = prop
                                break;
                            }
                            sortNumber++;
                        }

                        return <LRTableHeaderCell   index={index}
                                                    key         = {ident.ident + index}
                                                    rowSpan     = {rowSpan}
                                                    colSpan     = {colSpan}
                                                    onClick={isSortable ? (e) => { if (e.detail === 1) {this.onSortTable(ident.ident, e.shiftKey === true) } else if (e.detail === 2 && this.props.selectionChanged) {selectCompleteCell()}} : undefined}
                                                    draggable
                                                    data-propertyhandle={ident.RightClickIdent}
                                                    printHidden={ident.printHidden}
                                                    sorted={sortProp ? sortProp.dir : undefined} 
                                                    sortedNumber={sortNumber} 
                                                    baseUnit={ident.unit}
                                                    onMouseDown={this.HeaderMouseDown(index + cellIdentsOffset)}
                                                    onDragStart={() => {this.HeaderGrabStart(index + cellIdentsOffset)}}
                                                    onDragOver={(e) => {e.stopPropagation(); e.preventDefault(); }}
                                                    onDrop={() => {this.HeaderGrabEnd(index + cellIdentsOffset)}}
                                                    onDragEnd={() => {this.HeaderGrabEnd(this.grabIndex)}}
                                                    resizeDoubleClick={() => this.fitColumnWidthToContent(true)}
                                                    style={{...headerStyle, width: width, minWidth: width, maxWidth: width, ...PdfFormatToStyle(this.props.pdfFormat?.CellHeader)}}>{ident.label}</LRTableHeaderCell>
                    })}
                </Table.Row>
                {globExtraLine ? (
                    <Table.Row>
                        {extraColumns}
                    </Table.Row>
                ) : null}
            </Table.Header>
            <Table.Body key={tableKey + "Body"}>
                {
                    this.cellData.map((entry, index) => 
                    {
                        let rowIndex = entry.originalIndex

                        let startBoxSelection = (stringIdent, cellIdentifier, extraIndex = 0) => (e) => 
                        {
                            if (this.props.selectionType !== TABLE_SELECTION_TYPE_Cell) { return; }
                            if (cellIdentifier.DisableSelection) { return; }
                            if(!this.selectionRef.current) {return;}
                            if (!e) { return; }
                            let x = e.clientX
                            let y = e.clientY

                            let parent = this.getTabSetParent(this.selectionRef.current)

                            if(!parent.top && (window.IsIOS || window.Android))
                            {
                                parent = {
                                    top:48
                                }
                            }

                            if(parent.left)
                            {
                                x -= parent.left
                            }
                            if(parent.top)
                            {
                                y -= parent.top
                            }

                            x += 'px';
                            y += 'px';

                            this.selectionRef.current.style.opacity = 1
                            this.selectionRef.current.style.left = x
                            this.selectionRef.current.style.top = y
                            this.selectionRef.current.style.width = 0
                            this.selectionRef.current.style.height = 0

                            this.startBoxSelection = {x: e.clientX, y: e.clientY}
                            this.isBoxSelecting = true

                            if (this.selectingIdent !== stringIdent)
                            {
                                this.setState({selectedRows: [], selectedExtraRows: []})
                            }

                            this.selectingIdent = stringIdent
                            this.selectedCellIdent = cellIdentifier
                            this.selectedArrayIndex = extraIndex

                            this.boxSelectionStartIndex = {DataIndex: index, ExtraIndex: extraIndex}
                        }

                        let endBoxSelection = (extraIndex = 0) => (e) => 
                        {
                            if (!e) { return; }
                            if (this.props.selectionType !== TABLE_SELECTION_TYPE_Cell) { return; }
                            if (!this.isBoxSelecting) { return; }
                            let selectedRows = []
                            let selectedExtraRows = []
                            
                            if (e.shiftKey || (navigator.platform === "MacIntel" ? e.metaKey : e.ctrlKey) || (window.IsIOS))
                            {            
                                selectedRows = this.state.selectedRows.map(c=>c)
                                selectedExtraRows = this.state.selectedExtraRows.map(c=>c)    
                            }

                            let start = this.boxSelectionStartIndex
                            let end = {DataIndex: index, ExtraIndex: extraIndex}
                            
                            let same = false

                            if (start.DataIndex === end.DataIndex && start.ExtraIndex === end.ExtraIndex)
                            {
                                same = true
                            }

                            if (window.IsIOS)
                            {
                                if(end.ExtraIndex === 0) // Selecting normal row
                              {
                                  let deselectedIndex = selectedRows.findIndex(findingIndex => findingIndex === end.DataIndex)
                                  if (deselectedIndex !== -1)  
                                  {
                                    selectedRows = []
                                  } 
                              } else // Selecting extra row
                              {
                                  if (!Array.isArray(selectedExtraRows[end.DataIndex]))
                                  {
                                    selectedExtraRows[end.DataIndex] = []
                                  }
                                    
                                  let theExtraIndex = end.ExtraIndex - 1
                      
                                  let deselectedIndex = Array.isArray(selectedExtraRows[end.DataIndex]) ? selectedExtraRows[end.DataIndex].findIndex(findingIndex => findingIndex === theExtraIndex) : -1
                      
                                  if (deselectedIndex !== -1)
                                  {
                                    selectedExtraRows = []                                               
                                  }
                                }
                            }
                             
                            let setSelectResults = (result) =>
                            {
                                selectedRows.push(...result.SelectedRows)
                                result.SelectedExtraRows.forEach((value, index) => {
                                    if (!selectedExtraRows[index]) { selectedExtraRows[index] = []}
                                    if(value)
                                    selectedExtraRows[index].push(...value)
                                })
                            }

                            if (same)
                            {
                                if (e.shiftKey)
                                {
                                    setSelectResults(this.selectFromTo(this.lastSelectedRow, start))
                                }
                                else
                                {
                                    setSelectResults(this.selectSingleCell(start))
                                }
                            }
                            else
                            {
                                setSelectResults(this.selectFromTo(start, end))
                            }

                            this.lastSelectedRow = end
                            this.setState({selectedRows: selectedRows, selectedExtraRows: selectedExtraRows})
                        }
                        
                        let isSelected = (ident) => {
                            return this.selectingIdent === ident && this.state.selectedRows.includes(index)
                        }

                        let extraEntries = []
                        if (entry.ExtraData && Array.isArray(entry.ExtraData) && (!this.props.collapsExtraData || this.state.expandedRows.includes(entry.originalIndex)))
                        {
                            entry.ExtraData.forEach((extra, extraIndex) => 
                            {
                                let renderedExtraCells = this.props.extraCellRender(entry, extra, extraIndex, this.state.sortProperties)
                                extraEntries.push(renderedExtraCells)
                            })

                            if (Array.isArray(this.state.sortProperties) && this.state.sortProperties.length > 0) 
                            {
                                [...this.state.sortProperties].reverse().forEach(secondary => {
                                    extraEntries = extraEntries.sort(this.sortFunc(secondary.ident, secondary.dir))
                                })
                                
                            }
                        }

                        let renderedCells = this.props.cellRender(entry, rowIndex, extraEntries)
                        let result = []
                        result.push(<Table.Row active={this.state.selectedSingleLine.RowIndex === rowIndex && this.state.selectedSingleLine.ExtraIndex === 0} error={renderedCells.RowError} warning={renderedCells.RowWarning} key={tableKey + "Row" + index} onClick={() => {this.selectSingleCompleteRow({RowIndex: rowIndex, ExtraIndex: 0})}}>
                            {cellIdents.map((ident, index) => {
                                const isEditable = entry.editable!==false && ident.editable!==false // column (ident) and row (entry) must be editable
                                
                                let editableColor = rowIndex % 2 ? (theme ? "#303030" : "rgb(255, 255, 255)") : (theme ? "#303030" :"rgb(250, 250, 251)")
                                if(!isEditable){
                                    editableColor = rowIndex % 2 ? (theme ? "#303030" : "rgb(255, 255, 255)") : (theme ? "#303030" :"rgb(245, 245, 246)")
                                }

                                if (ident.extraColumns)
                                {
                                    return ident.extraColumns.map((column, i) => {

                                        if (renderedCells[ident.ident + "_" + column.label])
                                        {
                                            return <Table.Cell  key={i}
                                                                style={{backgroundColor:isSelected(ident.ident + "_" + column.label) ? "lightblue" : editableColor, ...PdfFormatToStyle(this.props.pdfFormat?.Text)}} 
                                                                onMouseDown={startBoxSelection(ident.ident + "_" + column.label, ident)}
                                                                onMouseUp={endBoxSelection()}       
                                                                data-printHidden={ident.printHidden}>{renderedCells[ident.ident + "_" + column.label]}</Table.Cell>
                                        }
                                        else
                                        {
                                            return <Table.Cell key={i} data-printHidden={ident.printHidden} style={PdfFormatToStyle(this.props.pdfFormat?.Text)}></Table.Cell>
                                        }
                                    })
                                }
                                return (
                                    <Table.Cell  
                                        key={index}
                                        style={{backgroundColor: isSelected(ident.ident) ? "lightblue" : editableColor, ...PdfFormatToStyle(this.props.pdfFormat?.Text)}} 
                                        onMouseDown={startBoxSelection(ident.ident, ident)}
                                        onMouseUp={endBoxSelection()}
                                        data-printHidden={ident.printHidden}
                                    >
                                        {
                                            Array.isArray(renderedCells[ident.ident]) 
                                                ? renderedCells[ident.ident].map((elm, idx)=>(
                                                    <p key={idx}>
                                                        {
                                                            elm.DisplayName || 
                                                            elm.DisplayValue || 
                                                            elm.Name ||
                                                            elm.Value ||
                                                            ""
                                                        }
                                                    </p>
                                                ))
                                                : renderedCells[ident.ident]
                                        }
                                        {
                                            this.props.collapsExtraData && index === 0 && (
                                                <Icon 
                                                    name = {
                                                        this.state.expandedRows.includes(entry.originalIndex) 
                                                            ? "caret down" 
                                                            : "caret right"
                                                    } 
                                                    link 
                                                    onClick={(e) => { this.onExpandRow(entry.originalIndex) }}
                                                />
                                            )
                                        }
                                    </Table.Cell>
                                )
                            })}
                        </Table.Row>)
                        if (entry.ExtraData && Array.isArray(entry.ExtraData) && (!this.props.collapsExtraData || this.state.expandedRows.includes(entry.originalIndex)))
                        {
                            if(this.props.NeedsShift) { extraEntries.shift()}
                            extraEntries.forEach((renderedExtraCells, extraIndex) => 
                            {
                                let isExtraSelected = (ident) => {
                                    return this.selectingIdent === ident && Array.isArray(this.state.selectedExtraRows[index]) && this.state.selectedExtraRows[index].includes(extraIndex)
                                }

                                result.push(
                                    <Table.Row active={this.state.selectedSingleLine.RowIndex === rowIndex && this.state.selectedSingleLine.ExtraIndex === extraIndex + 1} error={renderedExtraCells.RowError} warning={renderedExtraCells.RowWarning} key={tableKey + "RowExtra" + extraIndex} onClick={() => {this.selectSingleCompleteRow({RowIndex: rowIndex, ExtraIndex: extraIndex + 1})}}>
                                        {cellIdents.map((ident, i) => {
                                            if (ident.extraColumns)
                                            {
                                                return ident.extraColumns.map((column, i) => {
                                                    if (renderedExtraCells[ident.ident + "_" + column.label])
                                                    {
                                                        return <Table.Cell  key={i}
                                                                            style={{backgroundColor: isExtraSelected(ident.ident + "_" + column.label) ? "lightblue" : "", ...PdfFormatToStyle(this.props.pdfFormat?.Text)}}
                                                                            onMouseDown={startBoxSelection(ident.ident + "_" + column.label, ident, extraIndex + 1)}
                                                                            onMouseUp={endBoxSelection(extraIndex + 1)}
                                                                            data-printHidden={ident.printHidden}>{renderedExtraCells[ident.ident + "_" + column.label]}</Table.Cell>
                                                    }
                                                    else
                                                    {
                                                        return <Table.Cell key={i} data-printHidden={ident.printHidden} style={PdfFormatToStyle(this.props.pdfFormat?.Text)}></Table.Cell>
                                                    }
                                                })
                                            }
                                            return <Table.Cell  key={i}
                                                                style={{backgroundColor: isExtraSelected(ident.ident) ? "lightblue" : "", ...PdfFormatToStyle(this.props.pdfFormat?.Text)}}
                                                                onMouseDown={startBoxSelection(ident.ident, ident, extraIndex + 1)}
                                                                onMouseUp={endBoxSelection(extraIndex + 1)}
                                                                data-printHidden={ident.printHidden}>{renderedExtraCells[ident.ident]}</Table.Cell>
                                        })}
                                    </Table.Row>
                                )
                            })
                        }
                        return result;
                    })
                }
            </Table.Body>
            {this.props.footerRow ? 
            <Table.Footer>
                <Table.Row>
                    {this.props.footerRow}
                </Table.Row>
            </Table.Footer> : null}
        </Table>
    }

    getTabSetParent = (entry) =>
    {
        if(entry.className === "flexlayout__tab")
        {
            return entry.getBoundingClientRect()
        }
        if(entry.parentElement)
        {
            return this.getTabSetParent(entry.parentElement)
        }
        return {}
    }

    findLowestSelectedRow = () =>
     {
        let lowesetSelectedRow = Infinity
        let lowestSelectedExtraRow = Infinity
        if (this.state.selectedRows.length === 0)
        {
            lowesetSelectedRow = this.state.selectedExtraRows.length
        }
        this.state.selectedRows.forEach(row => { if (row < lowesetSelectedRow) lowesetSelectedRow = row; })
        for (let i = 0; i < lowesetSelectedRow; i++)
        {
            if (Array.isArray(this.state.selectedExtraRows[i]) && this.state.selectedExtraRows[i].length > 0)
            {
                for (let row of this.state.selectedExtraRows[i])
                {
                    if (row < lowestSelectedExtraRow)
                    {
                        lowestSelectedExtraRow = row;
                        lowesetSelectedRow = i
                    }
                }
            }
        }

        return { lowesetSelectedRow,lowestSelectedExtraRow}
        
     }

    selectSingleCellByArrowKeyDown = () => 
    {
     const {lowesetSelectedRow,lowestSelectedExtraRow} =  this.findLowestSelectedRow()

      let newSelectedRows = []
      let newSelectedExtraRows = []
      // If we have a row selected (no extra data)
      if (lowestSelectedExtraRow === Infinity)
      {
        if (Array.isArray(this.cellData[lowesetSelectedRow].ExtraData))
        {
            // Selected the first extra data cell
            newSelectedExtraRows[lowesetSelectedRow] = [0]
        }
        else
        {
            newSelectedRows.push(lowesetSelectedRow + 1)
        }
          
      }
      else
      {
          // We have a extra data set
          let newExtraRow = lowestSelectedExtraRow
          newExtraRow++;
          if (newExtraRow >= this.cellData[lowesetSelectedRow].ExtraData.length) // We are at the bottom of the extra data set
          {
              // Select the row above
              newSelectedRows.push(lowesetSelectedRow+1)
          }
          else
          {
              // Select the extra row above
              newSelectedExtraRows[lowesetSelectedRow] = [newExtraRow]
          }
      }

      if (newSelectedRows.length === 0)
        {
            for (let i = 0; i < newSelectedExtraRows.length; i++)
            {
                if (Array.isArray(newSelectedExtraRows[i]) && newSelectedExtraRows[i].length > 0)
                {
                    this.props.selectionChanged(this.selectedCellIdent, [this.cellData[i]], newSelectedExtraRows[i][0])
                    break;
                }
            }
        }
        else
        {
            if(this.props.selectionChanged)
            this.props.selectionChanged(this.selectedCellIdent, [this.cellData[newSelectedRows[0]]], 0)
        }
      
      this.setState({selectedRows: newSelectedRows, selectedExtraRows: newSelectedExtraRows});
    }

    selectSingleCellByArrowKeyUp = () => 
    {
        
       // Find the row selected at the top
       const {lowesetSelectedRow,lowestSelectedExtraRow} =  this.findLowestSelectedRow()
       let newSelectedRows = []
       let newSelectedExtraRows = []
     
       // If we have a row selected (no extra data)
       if (lowestSelectedExtraRow === Infinity)
       {
           // The row above
           let newSelectedRow = lowesetSelectedRow - 1
           if (newSelectedRow >= 0) // Valid
           {
               // The row above has extra Data
               if (Array.isArray(this.cellData[newSelectedRow].ExtraData))
               {
                   // Selected the last extra data cell
                   let newSelectedExtraIndex = this.cellData[newSelectedRow].ExtraData.length - 1
                   newSelectedExtraRows[newSelectedRow] = [newSelectedExtraIndex]
               }
               else
               {
                   // Select row above
                   newSelectedRows.push(newSelectedRow)
               }
           }
       }
       else
       {
           // We have a extra data set
           let newExtraRow = lowestSelectedExtraRow
           newExtraRow--;
           if (newExtraRow < 0) // We are at the top of the extra data set
           {
               // Select the row above
               newSelectedRows.push(lowesetSelectedRow)
           }
           else
           {
               // Select the extra row above
               newSelectedExtraRows[lowesetSelectedRow] = [newExtraRow]
           }
        }
       
        if (newSelectedRows.length === 0)
        {
            for (let i = 0; i < newSelectedExtraRows.length; i++)
            {
                if (Array.isArray(newSelectedExtraRows[i]) && newSelectedExtraRows[i].length > 0)
                {
                    this.props.selectionChanged(this.selectedCellIdent, [this.cellData[i]], newSelectedExtraRows[i][0])
                    break;
                }
            }
        }
        else
        {
            this.props.selectionChanged(this.selectedCellIdent, [this.cellData[newSelectedRows[0]]], 0)
        }
       
       this.setState({selectedRows: newSelectedRows, selectedExtraRows: newSelectedExtraRows});
    }

    clearSelection = () =>
    {
        this.selectedCellIdent = undefined
        this.setState({selectedRows: [], selectedExtraRows: []})
    }

    selectSingleCellByArrowKeyLeftOrRight = (keyCode) => 
    {
        let selectedEntries = []    
        const currentIndex = this.cellIdentifier.findIndex(findingIndex => findingIndex.ident === this.selectingIdent)
        this.state.selectedRows.forEach(index => {
            selectedEntries.push(this.cellData[index])
        })

        if(keyCode==="ArrowLeft") 
        {
            if(currentIndex>0)
            {
                this.selectingIdent = this.cellIdentifier[currentIndex-1].ident
                this.selectedCellIdent = this.cellIdentifier[currentIndex-1]
                this.forceUpdate()
                if (this.props.selectionChanged)
                {
                    this.props.selectionChanged(this.selectedCellIdent, selectedEntries, 0)
                }
               
            }

                return;
            
        }

        if(keyCode==="ArrowRight")
        {
            if(currentIndex<this.cellIdentifier.length-1)
            {
                this.selectingIdent  = this.cellIdentifier[currentIndex+1].ident
                this.selectedCellIdent = this.cellIdentifier[currentIndex+1]
                if (this.props.selectionChanged)
                {
                    this.props.selectionChanged(this.selectedCellIdent, selectedEntries, 0)
                }
            }
            
                return;        
                
        }


    }

    handleTabKeyPress = () => {
        let selectedEntries = []; 
        const currentIndex = this.cellIdentifier.findIndex(findingIndex => findingIndex.ident === this.selectingIdent); 
        this.state.selectedRows.forEach(index => {
            selectedEntries.push(this.cellData[index])
        })
    
        let nextIndex;
        if (currentIndex < this.cellIdentifier.length - 1) {
            nextIndex = currentIndex + 1;
        } else {
            nextIndex = 0; 
        }
    
        this.selectingIdent = this.cellIdentifier[nextIndex].ident;
        this.selectedCellIdent = this.cellIdentifier[nextIndex];
    
        if (this.props.selectionChanged) {
            this.props.selectionChanged(this.selectedCellIdent, selectedEntries, 0);
        }
    }

    selectSingleCell = (cellInfo) =>
    {
        let selectedRows = []
        let selectedExtraRows = []

        if (cellInfo.ExtraIndex === 0) // Selecting normal row
        {

            let foundIndex = this.state.selectedRows.findIndex(findingIndex => findingIndex === cellInfo.DataIndex)


            if (foundIndex === -1)
            {
                selectedRows.push(cellInfo.DataIndex)
            }
            else
            {
                selectedRows = [...this.state.selectedRows]
                selectedExtraRows = this.state.selectedExtraRows
                selectedRows.splice(foundIndex, 1)
            }
        }
        else // Selecting extra row
        {
            if (!Array.isArray(selectedExtraRows[cellInfo.DataIndex]))
            {
                selectedExtraRows[cellInfo.DataIndex] = []
            }

            
            let theExtraIndex = cellInfo.ExtraIndex - 1

            let foundIndex = Array.isArray(this.state.selectedExtraRows[cellInfo.DataIndex]) ? this.state.selectedExtraRows[cellInfo.DataIndex].findIndex(findingIndex => findingIndex === theExtraIndex) : -1

            if (foundIndex === -1)
            {
                selectedExtraRows[cellInfo.DataIndex].push(theExtraIndex)
            }
            else
            {
                selectedExtraRows[cellInfo.DataIndex] = [...this.state.selectedExtraRows[cellInfo.DataIndex]]
                selectedExtraRows[cellInfo.DataIndex].splice(foundIndex, 1)                                                
            }
        }

        return {SelectedRows: selectedRows, SelectedExtraRows: selectedExtraRows}
    }

    selectFromTo = (startRowInfo, endRowInfo) =>
    {
        // Copy them so we dont swap them actualy
        let start = {...startRowInfo}
        let end = {...endRowInfo}

        let swap = false

        if (start.DataIndex === end.DataIndex)
        {
            if (start.ExtraIndex > end.ExtraIndex)
            {
                swap = true
            }
        }
        else if (start.DataIndex > end.DataIndex)
        {
            swap = true
        }
        if (swap)
        {
            let temp = start
            start = end
            end = temp
        }


        let selectedRows = []
        let selectedExtraRows = []

        // ----------------------------------------------------
        // ----------------------------------------------------
        // Select first
        if (start.ExtraIndex === 0)
        {
            selectedRows.push(start.DataIndex)
        }
        
        if (Array.isArray(this.cellData[start.DataIndex].ExtraData))
        {
            let endExtraIndex = this.cellData[start.DataIndex].ExtraData.length
            if (end.DataIndex === start.DataIndex) { endExtraIndex = end.ExtraIndex}
            for (let i = start.ExtraIndex - 1; i < endExtraIndex; i++)
            {
                if (!Array.isArray(selectedExtraRows[start.DataIndex]))
                {
                    selectedExtraRows[start.DataIndex] = []
                }
                if (!selectedExtraRows[start.DataIndex].includes(i))
                {
                    selectedExtraRows[start.DataIndex].push(i)
                }
            }
        }
        // ----------------------------------------------------
        // ----------------------------------------------------
        // ----------------------------------------------------
        // Select middle 
        for (let i = start.DataIndex + 1; i < end.DataIndex; i++)
        {
            if (!selectedRows.includes(i))
            {
                selectedRows.push(i)
            }
            if (Array.isArray(this.cellData[i].ExtraData))
            {
                for (let currentExtraIndex = 0; currentExtraIndex < this.cellData[i].ExtraData.length; currentExtraIndex++)
                {
                    if (!Array.isArray(selectedExtraRows[i]))
                    {
                        selectedExtraRows[i] = []
                    }
                    if (!selectedExtraRows[i].includes(currentExtraIndex))
                    {
                        selectedExtraRows[i].push(currentExtraIndex)
                    }
                }
            }
        }
        // ----------------------------------------------------
        // ----------------------------------------------------
        // ----------------------------------------------------
        // Select last
        if (start.DataIndex !== end.DataIndex || start.ExtraIndex === 0)
        {
            selectedRows.push(end.DataIndex)
        }

        if (Array.isArray(this.cellData[end.DataIndex].ExtraData))
        {
            let startIndex = 0
            if (start.DataIndex === end.DataIndex)
            {
                startIndex = start.ExtraIndex
            }

            for (let i = startIndex; i < end.ExtraIndex; i++)
            {
                if (!Array.isArray(selectedExtraRows[end.DataIndex]))
                {
                    selectedExtraRows[end.DataIndex] = []
                }
                if (!selectedExtraRows[end.DataIndex].includes(i))
                {
                    selectedExtraRows[end.DataIndex].push(i)
                }
            }
        }
        // ----------------------------------------------------
        // ----------------------------------------------------

        return {SelectedRows: selectedRows, SelectedExtraRows: selectedExtraRows}
    }

    getWidthOfProp = (propname) => {
        if (!this.state.widthMap[propname]) { return defaultWidth }
        return this.state.widthMap[propname]
    }
  
    setWidthOfProp = (propname, width) => {
        if(width < 10) width = 10
        this.setState({
        widthMap: {
            ...this.state.widthMap,
            [propname]: width
        }
        })
    }


    setAllWidths = (width, cb) => this.setState({widthMap: Object.fromEntries(Object.entries(this.state.widthMap).map(([propname,_]) => [propname, width]))}, cb)
  
    ResizeMove = (propname, startX, startWidth) => (e) => {
        this.setWidthOfProp(propname, startWidth + (e.clientX - startX) / getDPMM())
        e.stopPropagation()
        e.preventDefault()
    }
  
    HeaderMouseDown = (i) => (e) => {
        let grabIndex = -1

        if(e.target.dataset.left) {
            grabIndex = i - 1
        } else if (e.target.dataset.right) {
            grabIndex = i
        }
        if(grabIndex >= 0) {
            e.preventDefault()
            e.stopPropagation()
            let propName = this.cellIdentifier[grabIndex].ident
            let moveFunc = this.ResizeMove(propName, e.clientX, this.getWidthOfProp(propName))
            let upFunc = (e) => {
                // Update the state in core
                this.updateCellHeaderData(this.createCoreCellData(this.state.widthMap, this.state.indexMap))

                document.removeEventListener("mousemove", moveFunc, {capture: true})
                document.removeEventListener("mouseup", upFunc, {capture: true})
                e.stopPropagation()
                e.preventDefault()
            }
            let clickCap = (e) => {
                e.preventDefault()
                e.stopPropagation()
                document.removeEventListener("click", clickCap, {capture: true})
            }
            document.addEventListener("mousemove", moveFunc, {capture: true})
            document.addEventListener("mouseup", upFunc, {capture: true})
            document.addEventListener("click", clickCap, {capture: true})
        }
    }

    HeaderGrabStart = (tableIndex) =>
    {
        this.grabIndex = tableIndex
    }

    HeaderGrabEnd = (tableIndex) =>
    {
        let newIndexMap = {}
        this.cellIdentifier.forEach((cellIdent, index) => {
            newIndexMap[cellIdent.ident] = index
        })

        if (this.grabIndex < tableIndex)
        {
            // Move the cell to the right
            for (let i = this.grabIndex + 1; i <= tableIndex; i++)
            {
                let currentCellIdent = this.cellIdentifier[i]
                newIndexMap[currentCellIdent.ident] = newIndexMap[currentCellIdent.ident] - 1
            }
            let grabbedCellIdent = this.cellIdentifier[this.grabIndex]
            newIndexMap[grabbedCellIdent.ident] = tableIndex
        }
        else if (this.grabIndex > tableIndex)
        {
            // Move the cell to the left
            
            for (let i = tableIndex; i < this.grabIndex; i++)
            {
                let currentCellIdent = this.cellIdentifier[i]
                newIndexMap[currentCellIdent.ident] = newIndexMap[currentCellIdent.ident] + 1
            }

            let grabbedCellIdent = this.cellIdentifier[this.grabIndex]
            newIndexMap[grabbedCellIdent.ident] = tableIndex
        }
        
        // Update the state in core
        this.updateCellHeaderData(this.createCoreCellData(this.state.widthMap, newIndexMap))

        this.setState({indexMap: newIndexMap})
    }

    onExpandRow = (index) =>
    {
        if (this.state.expandedRows.includes(index)) 
        {
            let newExanded = this.state.expandedRows.filter(value => value !== index);
            this.setState({expandedRows: newExanded})
        }
        else
        {
            let newExanded = this.state.expandedRows
            newExanded.push(index)
            this.setState({expandedRows: newExanded});
        }
    }

    createCoreCellData = (widthMap, indexMap) =>
    {
        let result = {}
        Object.entries(indexMap).forEach(([name, value]) => {
            result[name] = {Width: defaultWidth, Index: value}
        })
        Object.entries(widthMap).forEach(([name, value]) => {
            if (!result[name]) { result[name] = {Width: value, Index: -1}}
            else { result[name].Width = value }
        })
        return result
    }

    updateCellHeaderData = (data) =>
    {
        window.LR_SetCellData({WorksheetData: {[this.props.tableName]: data}, Worksheet: this.props.worksheet})
    }

    getSortedIndexOfData = (cb) =>
    {
        return this.cellData.findIndex(cb)
    }

    setSelectedIndex = (indices) =>
    {
        let newExtraSelected = []
        let newSelectedRows = []
        indices.forEach(index => {
            if (Array.isArray(this.state.selectedExtraRows[index]))
            {
                if (this.state.selectedRows.includes(index))
                {   
                    newSelectedRows.push(index)
                }
            }
            else
            {
                newSelectedRows.push(index)
            }

            newExtraSelected[index] = this.state.selectedExtraRows[index]
        })



        this.setState({selectedRows: this.selectedCellIdent ? newSelectedRows : [], selectedExtraRows: newExtraSelected})
    }

}

export default DynamicTable;