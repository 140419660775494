
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import escapeRegExp from "lodash/escapeRegExp";
import React, { useEffect, useState } from 'react';
import { Icon, Label, Search, Segment, Table } from 'semantic-ui-react';
import LocalizedStrings from "../../localization/NavigationContainer";
import { IsElectronContext } from '../../util/defines';
import ColorCodeNode from './ColorCodeNode';

import { globalCallbacks as mainCB } from '../../util/callback';
import { LRVector3D, globalWindowInterface, uuidStr } from '../../util/callbackTypes';
import { globalCallbacks as mockCB } from '../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB

declare const window: globalWindowInterface;

export interface ColorCodeInterface {
    Color: LRVector3D[]
    Name: string
    UUID: uuidStr
}

export default function ColorCodeTable(this: unknown) {
    const [colorCodes, setColorCodes] = useState<ColorCodeInterface[]>([])
    const [filteredColorCodes, setFilteredColorCodes] = useState<ColorCodeInterface[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [sortingState, setSortingState] = useState<string>(undefined)
    const [searchValue, setSearchValue] = useState<string>("")

    useEffect(() => {
        globalCallbacks.updateColorCodeObjects = async () => {
            setIsLoading(true)
            let data = await window.LR_GetColorCodeObjects()
            setColorCodes(data.ColorCodeObjects)
            setIsLoading(false)
        }

        globalCallbacks.updateColorCodeObjects();
    }, [])

    const onAddNewColorCode = () => window.LR_AddNewColorCodeObject({});
    const onHeaderClick = () => setSortingState((s) => {
        let newVal: string
        switch (s) {
            case undefined: newVal = "ascending"; break;
            case "ascending": newVal = "descending"; break;
            case "descending": newVal = undefined; break;
            default: break;
        }
        return newVal
    })

    useEffect(() => setFilteredColorCodes(() => {
        let ret: ColorCodeInterface[];
        if (searchValue.length) {
            const re = new RegExp(escapeRegExp(searchValue), 'i')
            const isMatch = (result: ColorCodeInterface) => re.test(result.Name)
            ret = colorCodes.filter(isMatch);
        } else {
            ret = [...colorCodes]
        }

        if(sortingState === "ascending")
        {
            ret.sort()
        }else if(sortingState === "descending")
        {
            ret.sort()
            ret.reverse()
        }

        return ret;
    }), [searchValue, sortingState, colorCodes])

    return (
        <div style={{ width: "100%", height: "100%" }}>
            <Table style={{ borderBottom: "none", margin: 0 }}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell colSpan='3' onClick={onHeaderClick} style={{ zIndex: 0 }} >
                            {<Icon link style={{ floated: 'left', position: 'absolute', zIndex: 1 }} name={sortingState === undefined ? "sort" : sortingState === "ascending" ? "sort alphabet up" : "sort alphabet down"} />}
                            <Search open={false}
                                loading={isLoading}
                                value={searchValue}
                                onClick={(e) => { e.stopPropagation() }}
                                onSearchChange={(e, { value }) => setSearchValue(value)}
                                aligned="right" />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
            </Table>

            <div style={{ width: "100%", maxHeight: IsElectronContext() ? "calc(100vh - 25em)" : "calc(100vh - 30em)", overflowY: "auto", marginBottom: "5em" }}>
                <Table striped structured compact='very' size="small">
                    <Table.Body>
                        {filteredColorCodes.map(i => <ColorCodeNode key={i.UUID} colorCode={i} />)}
                    </Table.Body>
                </Table>
            </div>

            <Segment vertical textAlign="center" style={{ position: "absolute", bottom: IsElectronContext() ? "1.5em" : "6em", width: "100%", border: "none" }}>
                <Label as="a" color="green" onClick={onAddNewColorCode}>
                    <Icon name="plus" />{LocalizedStrings.AddNewColorCode}
                </Label>
            </Segment>
        </div>
    );
}
