import React, {Component} from 'react'
import CONST from '../../webApp/CONST';
const ReactMarkdown = React.lazy(()=>import("react-markdown"))
const NewNavBar = React.lazy(() => import("../ProductionAssistLandingPage/NewNavBar"));
import NewFooter from '../WebComponents/NewFooter';
import MDE from "react-mde"
import { Loader, Segment, Button, Input, Icon } from 'semantic-ui-react';
import "./new-blog.css"
import { lrServerConnection } from '../../redux/light_right_server_connection';
import UnitInput from "../Basics/BasicUnitInput";
import { BASE_UNIT_DATE } from "../../util/defines";
import {connect} from "react-redux"
import { ACTIVE_USER } from '../../redux/redux_defines';
import LRModal from '../Basics/BasicModal';
import { withRouter } from '../../webApp/WebRouter';
import LocalizedStrings from "../../localization/ProductionAssistLandingPage"

class BlogEntryComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openEditModal : false,
      entry: null,
      edit: null,
      imageError: false
    }
    this.fileChooserRef = React.createRef();
    this.getEntry(props.name)
  }

  getEntry = (name) => 
    fetch(CONST.BASE_URL + "/api/blog/" + name)
      .then((str) => str.json())
      .then((obj) => this.setState({entry: obj}))

  extractFirstH1AndContent = (text) => {
    const lines = text.split('\n');
    let firstH1 = '';
    let remainingContent = '';
    let foundH1 = false;

    for (let i = 0; i < lines.length; i++) {
      if (!foundH1 && lines[i].startsWith('# ')) {
        firstH1 = lines[i].replace('# ', '');
        foundH1 = true;
      } else if (foundH1) {
        remainingContent += lines[i] + '\n';
      }
    }

    return { firstH1, remainingContent: remainingContent.trim() };
  };

  truncateText = (text, maxWords = 40) => {
    const words = text.split(' ');
    if (words.length <= maxWords) {
      return text;
    }
    return words.slice(0, maxWords).join(' ') + '...';
  };

  stripMarkdown = (text) => {
    return text.replace(/(#+\s*)|(!\[.*?\]\(.*?\))/g, '').trim();
  };

  handleImageError = () => this.setState({ imageError: true });


  render() {
    const { entry, edit, imageError } = this.state
    if(!entry) return <Segment><Loader/></Segment>

    const content = edit  ? edit.text : entry.content;
    const { firstH1, remainingContent } = this.extractFirstH1AndContent(content);
    const cleanContent = this.stripMarkdown(remainingContent);
    const shortContent = this.truncateText(cleanContent);
    const firstImage = entry.images.length > 0 ? entry.images[0].ref : null;
   
    return ( 
    <div className='card'>
        {firstImage && !imageError ?  (
          <img 
            className='card-img'
            src={`${CONST.BASE_URL}/api/blog/${entry.name}/image/${firstImage}`}
            alt="Blog entry" 
            onError={this.handleImageError} 
          />
        ) : (
          <div className='no-img'></div>
        )}

        {firstH1 && <h1 onClick={() => this.props.onEntryClick(entry)}>{firstH1}</h1>}
        <p className='desktop'>{shortContent}</p>
        <button className='btn details desktop' onClick={() => this.props.onEntryClick(entry)}>{LocalizedStrings.Details}</button>

      {edit ? (
        <LRModal open={this.state.openEditModal} onOkClick={this.apply} onCancelClick={this.cancel} title={LocalizedStrings.Edit}>
          <MDE 
            value={content} 
            selectedTab={this.state.selectedTab}
            onTabChange={(data) => this.setState({selectedTab: data})}
            generateMarkdownPreview={(markdown) => Promise.resolve(<ReactMarkdown children={markdown} />) }
            onChange={this.editChange}
            />
          <ul>
            {this.state.entry.images.map((img, i) => 
              <li key={i} onClick={() => { this.editChange(content + "\n\n![" + img.name + "](" + CONST.BASE_URL + "/api/blog/" + this.props.name + "/image/" + img.ref + ")") }}>
                Add <Button compact>{img.name}</Button>
              </li>
            )}
          </ul>
          <br/>
          <Button onClick={this.openFileChooser}>Upload Picture</Button>
          <br/>
          Publish Date
          <UnitInput
                  baseUnit={BASE_UNIT_DATE}
                  name="DueDate"
                  value={this.state.edit.publish}
                  onStateUpdate={this.editPublish}
                />
          <input accept="image/*" type="file" ref={this.fileChooserRef} style={{display: "none"}} onChange={this.readFile}/>
          <br/>
          <button className="btn edit-btn" onClick={this.delete}>Delete Post</button>
   
        </LRModal>)
        : (this.props.isAdmin ? <Icon style={{position: "absolute", top: "0.75rem", right: "0.5rem"}} link name='edit' onClick={this.openEdit} /> : null)}
    </div>)
  }

  openEdit = () => this.setState({edit: {
    text: this.state.entry.content,
    publish: this.state.entry.publish
  }, openEditModal: true})

  cancel = () => {
    this.setState({edit: null, openEditModal: false})
  }

  delete = () => {
    lrServerConnection.deleteBlog(this.props.name).then(() => {
      this.cancel()
      this.props.update()
    })
  }

  apply = () => {
    lrServerConnection.updateBlog(this.state.entry.name, this.state.edit.text, this.state.edit.publish).then(() => {
      this.cancel()
      this.getEntry(this.props.name)
    })
  }

  editChange = (value) => {
    this.setState({edit: {...this.state.edit, text: value}})
  }

  editPublish = (_, value) => { 
    this.setState({edit: {...this.state.edit, publish: value}})
  }

  readFile = (e) => {
    let files = e.target.files;
    let fileToUpload = files[0]
    
    lrServerConnection.uploadBlogImg(this.props.name, fileToUpload)
    .then(() => this.getEntry(this.props.name))
  }

  openFileChooser = (e) => 
  {
    // the default input-field is ugly, so we hide it and call it through an other button
    let hiddenFileChooser = this.fileChooserRef.current;
    hiddenFileChooser.click();
  }
}

const BlogEntry = React.memo(BlogEntryComponent);

class NewBlog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      entries: null,
      new: null,
      selectedEntry: null,
      imageError: false,
    }
    this.fetchEntries()
  }

  fetchEntries = () => {
    const prom = this.props.user.hasFetched && this.props.user.data.isAdmin ? lrServerConnection.getBlogAdmin.bind(lrServerConnection) : lrServerConnection.getBlog.bind(lrServerConnection);
    prom().then((obj) => {
      this.setState({ entries: obj });
    });
  };

  componentDidUpdate(prevProps)
  {
    if(prevProps.user !== this.props.user){
      this.fetchEntries()
    }
  }

  onEntryClick = (entry) => this.setState({ selectedEntry: entry });

  closeEntryDetail = () => this.setState({ selectedEntry: null, imageError: false }); 

  
  render() 
  {
    const { entries, selectedEntry, imageError } = this.state;
    if(!entries)
      return <Segment><Loader/></Segment>
    const isAdmin = this.props.user.hasFetched && this.props.user.data.isAdmin;
    let content; 
    if(this.props.match.params.page) {
      content = <BlogEntry key={this.props.match.params.page} name={this.props.match.params.page} isAdmin={isAdmin} update={this.fetchEntries} onEntryClick={this.onEntryClick}/>
    } else {
      content = entries.map((entry) => <BlogEntry key={entry.name} name={entry.name} isAdmin={isAdmin} update={this.fetchEntries} onEntryClick={this.onEntryClick}/>)
    }

    return (
      <div className="new-blog">
        <header>
          <NewNavBar/>
          <div className="main-heading">
            <h1>Blog</h1>
            <h3>{LocalizedStrings.StayUpToDate}</h3>
          </div>
        </header>

        {isAdmin ? <button className='btn entry' onClick={this.addEntry}>Add entry</button> : null}
        <div className='cards'>
          <LRModal open={this.state.new !== null} onCancelClick={this.cancelEntry} onOkClick={this.okEntry}>
            <Input onChange={this.changeEntry} value={this.state.new}/>
          </LRModal>
          {content}

          {selectedEntry && (
          <>
            <div className='entry-backdrop'></div>
            <div className='entry-detail'>
              <button className='btn desktop close' onClick={this.closeEntryDetail}>×</button>
              <button className='mobile circle-close' onClick={this.closeEntryDetail}>×</button>

              {selectedEntry.images.length > 0 && !imageError ?  (
              <img  className="entry-img" 
                    src={`${CONST.BASE_URL}/api/blog/${selectedEntry.name}/image/${selectedEntry.images[0].ref}`} 
                    onError={this.handleImageError}
                    alt="entry img" />
            ) : null }
              <ReactMarkdown children={selectedEntry.content} />
            </div>
          </>
        )}
        </div>
          <NewFooter />
      </div>
    )
  }

  changeEntry = (_,{value}) => this.setState({new: value})

  addEntry = () => this.setState({new: "new-entry"})

  okEntry = () => {
    lrServerConnection.createBlog(this.state.new)
      .then(this.cancelEntry)
      .then(this.fetchEntries)
  }

  cancelEntry = () => this.setState({new: null})

  handleImageError = () => this.setState({ imageError: true })
}

const mapStateToProps = (state) => 
{
    return {
      user: (state[ACTIVE_USER]),
    };
}

export default connect(mapStateToProps)(withRouter(NewBlog))