//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component, } from 'react';
import { connect } from 'react-redux';

import {  Image, Menu, Icon, Button } from 'semantic-ui-react';
import { Link, Navigate } from "react-router-dom";
import logo from '../../content/img/applogo_white.png'
import LocalizedStrings from "../../localization/WebSiteHeader";
import MediaQuery from 'react-responsive'

import { ACTIVE_USER } from '../../redux/redux_defines'
import { RESPONSIVE_PHONE } from '../../util/defines';
import QrCodeReader from '../QrCodeScanner/QrCodeScanner';
import { DEVELOPMENT_SERVER } from '../../webApp/CONST';

class Header extends Component 
{
  constructor(props)
    {
        super(props);
        this.state = {
          open:false
        }
    }
  render = () => 
  {    
    let url = new URL(window.location.href);

    let username = this.props.loggedInUser.data?.username;

    let style = DEVELOPMENT_SERVER ? { background: 'repeating-linear-gradient( 45deg, rgb(249,204,43), rgb(249,204,43) 150px, rgba(38,40,42,1), 150px, rgba(38,40,42,1) 402px)' } : { }
    style = {...style, zIndex: 10000, borderRadius: 0}
    let showregister = !this.props.loggedInUser.data
    return (
      <>
      <MediaQuery minWidth={RESPONSIVE_PHONE}>
        {match => 
            <Menu inverted style={style}>
              <Menu.Item as={Link} to={"/"} header>
                  <Image size='mini' src={logo} style={{ marginRight: '1.5em' }} />
                  {match ? "Production Assist":""}
              </Menu.Item>  
              {match && showregister ? <Menu.Item as={Link} to={"/documentation"}>
                {LocalizedStrings.Documentation}
              </Menu.Item> : null } 
              {match && showregister ? <Menu.Item as={Link} to={"/tutorial"}>
                {LocalizedStrings.Tutorial}
              </Menu.Item> : null } 
              {match && showregister ? <Menu.Item as={Link} to={"/pricing"}>
                {LocalizedStrings.Pricing}
              </Menu.Item>: null}
              {match && showregister ? <Menu.Item as={Link} to={"/faq"}>
                {LocalizedStrings.FAQ}
              </Menu.Item>: null}
              
              <Menu.Menu fixed='top' position="right">
                {showregister  ? <Menu.Item as={Link} to={`/login?reroute=${url.pathname}`} ><Button inverted>{LocalizedStrings.Login}</Button></Menu.Item> : null}
                {showregister  ? <Menu.Item as={Link} to={"/register"} ><Button inverted>{LocalizedStrings.Register}</Button></Menu.Item> : null}
                {!showregister ? <Menu.Item onClick={()=> this.setState({open: true})} ><Icon name="camera" /> {match ?LocalizedStrings.Scan :""} </Menu.Item> : null}
                {!showregister ? <Menu.Item as={Link} to={`/${username}/settings/profile`} ><Icon name="settings" /> {match ?LocalizedStrings.Settings :""} </Menu.Item> : null}
                {!showregister ? <Menu.Item as={Link} to={"/about"} onClick={this.logout}> <Icon name="sign-out" />{match ?LocalizedStrings.SignOut :""}</Menu.Item> : null}
              </Menu.Menu>
        </Menu>}
        </MediaQuery>
        <QrCodeReader
            open={this.state.open}
            noCancelButton
            onScan={this.handleScan}
            onOkClick={()=>{this.setState({open:false})}}
            onCancelClick={()=>{this.setState({open:false})}}
            title={"Scan QR-Code from Object"}
            size="mini"
            noCancel={true}/>
          {this.state.open && this.state.url ? <Navigate to={this.state.url}/> : null}
        </>
    );
  }

  handleScan =(data) =>
  {
    const url = new URL(data);
    console.log(url)
    if(url.hostname.includes("production-assist.com"))
    {
      this.setState({url:url.pathname}, () => 
      {
        this.setState({open:false, url: ""})
      })
    }
    

  }

  logout = () =>
  {
    window.LR_LogOutFromServer().then(() => { window.location.reload() });
  }
}

//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      loggedInUser: (state[ACTIVE_USER]),
    };
}

export default connect(mapStateToProps)(Header)


