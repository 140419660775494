
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import Timecode from "smpte-timecode"
import LocalizedStrings from "../localization/TimeStrings"

// copied from
// https://stackoverflow.com/questions/3177836/how-to-format-time-since-xxx-e-g-4-minutes-ago-similar-to-stack-exchange-site/23259289#23259289
export function timeSince(date) 
{

    let seconds = Math.floor((new Date() - date) / 1000);
    let intervalType;
  
    let interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
      intervalType = 'year';
    } else {
      interval = Math.floor(seconds / 2592000);
      if (interval >= 1) {
        intervalType = 'month';
      } else {
        interval = Math.floor(seconds / 86400);
        if (interval >= 1) {
          intervalType = 'day';
        } else {
          interval = Math.floor(seconds / 3600);
          if (interval >= 1) {
            intervalType = "hour";
          } else {
            interval = Math.floor(seconds / 60);
            if (interval >= 1) {
              intervalType = "minute";
            } else {
              interval = seconds;
              intervalType = "second";
            }
          }
        }
      }
    }
  
    if (interval > 1 || interval === 0) {
      intervalType += 's';
    }
  
    return interval + " " + LocalizedStrings[intervalType];
  }

export function timeString(date) 
{
  return date.toLocaleTimeString();
}

export function ToTimeCode(frame) 
{
  let tc = Timecode("00:00:00:00", 30)
  tc.add(frame)

  return tc.toString()
}


export function FromTimeCode(tcString)
{
  try {
    return Timecode(tcString, 30).frameCount
  } catch (error) {
    return 0
  }
}