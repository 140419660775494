import LocalizedStrings from 'localized-strings'
import LocalizeOption from './LocalizationOption';

import en from "./raw/en/CreateNewProject.json"
import de from "./raw/de/CreateNewProject.json"
import es from "./raw/es/CreateNewProject.json"
import fr from "./raw/fr/CreateNewProject.json"
import it from "./raw/it/CreateNewProject.json"
import ru from "./raw/ru/CreateNewProject.json"
import cz from "./raw/cs/CreateNewProject.json"

let Strings = new LocalizedStrings({
  en: en,
  de: de,
  es: es,
fr: fr,
ru: ru,
it: it,
cz: cz,
}, LocalizeOption)

export default Strings