//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2020 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Form, Table, Header, Checkbox, Message, Icon, Label, List, Button, Input, Pagination} from "semantic-ui-react";
import LocalizedStrings from '../../localization/CreateNewProject'
import { slugify, ValidateEmail } from '../../util/defines';
import LRModal from '../Basics/BasicModal';
import BasicSelect from '../Basics/BasicSelect';
import CollapsableDevider from '../Basics/CollapsableDevider';

class CreateNewProject extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      name:"",
      editName:"",
      collaborators:[],
      review:"",
      checks:[],
      reviews:[],
      organizations:[],
      folders:[],
      
      usersFromSearch: [],
      search: "",
      activeUsersPage: 1,
      invites: [],
      
      groups: [], // all groups of the user
      usedGroups: [], // groups added to the project
      editGroups: [], // groups currently being added (modal)
      loggedInUser: "",
      selectedUser: undefined,
      symbolMapTemplates: [],
      symMap: "",

      projectOptions: [], 
      baseProject: undefined,

      nameExists: false,
      selectedFolder: ""
      
    }

    this.updateFromServer = true;
  }

  refreshForUser = async () =>
  {
    this.props.selectedUserAcc(this.state.selectedUser)
    this.setState({loadingProject:true})

    let User = undefined

    if(this.state.selectedUser && this.state.selectedUser !== "loggedIn") {
        User = this.state.selectedUser
    }

    window.LR_GetCollaboratorsAsync({User}).then(arg =>
    {
      if(!Array.isArray(arg)) { return }
      let userArray = arg.map(entry => { return { ...entry, includeInProject: false } })
      this.setState({ collaborators: userArray})
    })
    
    window.LR_GetChecksFromUser({User}).then(checks => 
    {
      if(!Array.isArray(checks)) { return }
      let userChecks = checks.map(entry => { return { ...entry, includeInProject: false } })
      this.setState({ checks: userChecks})
    })

    window.LR_GetFoldersAsync({User}).then(folders => 
    {
      this.setState({ folders })
    })

    window.LR_GetReviewsFromUser({User}).then(reviews => 
    {
      this.setState({ reviews: reviews})
    })

    window.LR_GetGroupsFromUser({User}).then(groups => 
    {
      this.setState({ groups })
    })

    window.LR_GetOrganizationsAsync().then(organizations => 
    {
      if(Array.isArray(organizations))
      {
        this.setState({ 
          organizations: organizations.map(org => ({
            ...org,
            collaborators:  org.collaborators.map(col =>   ({...col,   includeInProject: false})),
            checks:         org.checks.map(check => ({...check, includeInProject: false})),
            usedGroups: [], 
            editGroups: [],
            review: "",
            reviews: org.reviewTemplate
          })) 
        })
      }
      
    })
    
    window.LR_GetLoggedInUser().then(user => 
    {
      this.setState({ loggedInUser: user.User})
    })

    window.LR_GetProjectsAsync({User}).then((projects) => 
    {
      let projectOptions = Array.isArray(projects) ? projects.map((proj) => {
        return {
          text: proj.name,
          value: proj.projectname,
          key: proj._id,
        };
      }) : [];

      projectOptions.unshift({
        key: "", 
        value: "", 
        text: LocalizedStrings.ReviewTemplateNone
      })

      this.setState({ projectOptions: projectOptions })
    })

    window.LR_GetSymbolMapTemplates({User}).then(temps => {
      this.setState({symbolMapTemplates: temps.templates})
    })

    window.LR_GetDataForUserAsync({templateOnly: true, User: this.state.selectedUser !== "loggedIn" ? this.state.selectedUser : (this.props.loggedInUser ?? this.state.loggedInUser)})
    .then(userData => {
      this.setState({
        baseProject: userData?.defaultBasedProject ,
        symMap     : userData?.defaultSymbolMapTemplate,
        review     : userData?.defaultReviewTemplate
      })
    })
  }

  componentDidMount = async () => 
  {
    this.refreshForUser()
  }

  renderNoSelectedUserAccError = () =>
  {
    if(this.props.errorMessage && !this.state.selectedUser)
    {
      return <Message error> {LocalizedStrings.noSelectedUserAcc}</Message>
    }
    return null
  }

  renderErrorInput = () => 
  {
    
    if(this.state.nameExists)
    {
      return <Message error>{LocalizedStrings.nameExists}</Message>
    }
    if(this.props.errorMessage && this.state.editName === "")
    {
     
      return <Message error> {LocalizedStrings.noName}</Message>
    }
    return null
  }

  render() 
  { 
    return (
      <>
        <Form>{this.renderFormHead()}</Form>
        <CollapsableDevider
          header={LocalizedStrings.Collaborators}
          icon="users"
          open={this.state.OpenMembers}
          update={() =>
            this.setState({ OpenMembers: !this.state.OpenMembers })
          }
        >
          {this.renderRightsAndGroups()}
          {this.renderMemberSelection()}
          {this.renderInvites()}
        </CollapsableDevider>
        <CollapsableDevider
          header={LocalizedStrings.Templates}
          icon="copy"
          open={this.state.OpenTemplates}
          update={() =>
            this.setState({ OpenTemplates: !this.state.OpenTemplates })
          }
        >
          <Form>{this.renderTemplateSelection()}</Form>
          {this.renderChecks()}
        </CollapsableDevider>

          <CollapsableDevider
            header={LocalizedStrings.Folders}
            icon="folder open"
            open={this.state.OpenFolders}
            update={() =>
              this.setState({ OpenFolders: !this.state.OpenFolders })
            }
          >
            {this.renderFolders()}
          </CollapsableDevider> 
      </>
    );
  }

  renderFormHead = () => {
    let organizationOptions = [{
      text: this.props.loggedInUser ?? this.state.loggedInUser,
      value: "loggedIn",
      key: "loggedIn"
    }, ...this.state.organizations.map(org => ({
      text: org.name,
      value: org.username,
      key: org.username
    }))]

    return ( 
      <>
        <Form.Group>
          <Form.Select width="5"
            label={"User/Organization"}
            options={organizationOptions}
            error={this.renderNoSelectedUserAccError()}
            search
            required
            value={this.state.selectedUser}
            onChange={this.switchOrganization}
          />
          <span style={{marginTop: "20px", marginBottom: "0px", fontSize: "200%"}}>/</span>
          <Form.Input width="11"
            autoFocus
            value={this.state.editName}
            label={LocalizedStrings.ProjectName}
            error={this.renderErrorInput()}
            fluid
            required
            onChange={(_, { value }) => {
              const actualName = slugify(value);

              // check if current projectname exists
              const nameExists = this.state.projectOptions.find(
                (p) => p.text === actualName
              );

              if (nameExists !== this.state.nameExists) {
                this.props.doesProjectNameExist(nameExists);
              }

              this.setState({
                editName: value,
                name: actualName,
                nameExists,
              });
            }}
          />
        </Form.Group>
        {this.state.name !== this.state.editName ? (
          <Message>
            <Icon name="warning" />
              {LocalizedStrings.WillCreatedAs + '"' + this.state.name + '"'} <br/>
              {this.state.selectedUser === "loggedIn" ? LocalizedStrings.CreateNewProjectByLoggedInUserAccNote : ""}
          </Message>
        ) : null}
      </>
    )
  }

  renderTemplateSelection = () => {
    let review = this.state.review
    let reviews = Array.isArray(this.state.reviews) ?  this.state.reviews : []

    let projectOptions  = this.state.selectedUser ? this.state.projectOptions : []
    let templateOptions = this.state.selectedUser ? [{key: "", value: "",text: LocalizedStrings.ReviewTemplateNone},...reviews.map(template => {return {key: template._id, value: template._id, text: template.name}})] : []
    let symMapOptions   = this.state.selectedUser ? [{value: "", text: "Default"}, ...this.state.symbolMapTemplates.map(str => ({text: str, value: str}))] : []

    return (
      <>
        <BasicSelect
          name="baseOnProject"
          options={projectOptions}
          label={LocalizedStrings.BaseOnProject}
          value={ this.state.selectedUser ? this.state.baseProject : "" }
          onStateUpdate={(name, value) => {
            this.setState({ baseProject: value });
          }}
        />
        <Form.Select
          size="mini"
          label={LocalizedStrings.SymbolMapTemplate}
          placeholder={LocalizedStrings.SymbolMapTemplateDefault}
          options={symMapOptions}
          value={ this.state.selectedUser ? this.state.symMap : "" }
          search
          fluid
          selection
          onChange={(_, { value }) => {
            this.setState({ symMap: value });
          }}
        ></Form.Select>
        <Form.Select
          size="mini"
          label={LocalizedStrings.ReviewTemplate}
          options={templateOptions}
          value={this.state.selectedUser ? review : ""}
          search
          fluid
          selection
          onChange={this.switchReview}
        ></Form.Select>
      </>
    );
  }

  renderChecks = () => {
    let checks = this.state.checks

    return (
      <>
        <Header>{LocalizedStrings.Checks}</Header>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{LocalizedStrings.Check}</Table.HeaderCell>
              <Table.HeaderCell>
                {LocalizedStrings.AddToProject}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {checks.map((check) => (
              <Table.Row key={check.id}>
                <Table.Cell>{check.name}</Table.Cell>
                <Table.Cell>
                  <Checkbox
                    checked={check.includeInProject}
                    onChange={this.onCheckboxClickedCheck(check.id)}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </>
    );
  }

  renderRightsAndGroups = () => {
    let members = this.state.collaborators
    let groups = this.state.usedGroups
    let invites = this.state.invites
    
    return( <>  
              <Header>{LocalizedStrings.Groups}</Header>
              <Table>
                <Table.Header>
                  <Table.Row>
                  <Table.HeaderCell>{LocalizedStrings.Name}</Table.HeaderCell>
                  <Table.HeaderCell>{LocalizedStrings.Write}</Table.HeaderCell>
                  <Table.HeaderCell>{LocalizedStrings.Admin}</Table.HeaderCell>
                  <Table.HeaderCell>{LocalizedStrings.Role}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {members.map((member, i) => 
                    <>
                      {member.includeInProject && <Table.Row>
                        <Table.Cell>
                          <Label style={{ padding: 5, marginRight: '0.5em'}}>M</Label>
                          {member.username}
                        </Table.Cell>
                        <Table.Cell style={{textAlign: "left"}} negative={!member.write} positive={member.write} onClick={() => this.toggleMemberRights(i, "write")}>
                          {member.write ? <Icon name="check" color="green" style={{marginLeft:"0.5rem" }} /> : <Icon name="times" color="red" style={{marginLeft:"0.5rem" }} />}
                        </Table.Cell>
                        <Table.Cell style={{textAlign: "left"}} negative={!member.admin} positive={member.admin} onClick={() => this.toggleMemberRights(i, "admin")}>
                          {member.admin ? <Icon name="check" color="green" style={{marginLeft:"0.5rem" }}  /> : <Icon name="times" color="red" style={{marginLeft:"0.5rem" }} />}
                        </Table.Cell>
                        <Table.Cell style={{textAlign: "left"}} negative={!member.admin} positive={member.admin}>
                            <Form.Input value    = {member.role}
                                                      onChange = {(e, { value }) => {this.handleRoleChange(i, value)}}
                                                >
                            </Form.Input> 
                                       
                        </Table.Cell>
                      </Table.Row>}
                    </>
                  )}
                  {invites.map((invite, i) => 
                    {
                      const mailNotValid = !ValidateEmail(invite.mail)

                      return (
                        <>
                          {<Table.Row negative={mailNotValid}>
                            <Table.Cell>
                              <Label style={{ padding: 5, marginRight: '0.5em'}}>{LocalizedStrings.Pending}</Label>
                              {invite.mail}
                            </Table.Cell>
                            <Table.Cell style={{textAlign: "left"}} negative={!invite.write} positive={invite.write} onClick={() => this.updateInvites(i, {write: !invite.write})}>
                              {invite.write ? <Icon name="check" color="green"  style={{marginLeft:"0.5rem" }}  /> : <Icon name="times" color="red"  style={{marginLeft:"0.5rem" }} />}
                            </Table.Cell>
                            <Table.Cell style={{textAlign: "left"}} negative={!invite.admin} positive={invite.admin} onClick={() => this.updateInvites(i, {admin: !invite.admin})}>
                              {invite.admin ? <Icon name="check" color="green"  style={{marginLeft:"0.5rem" }}  /> : <Icon name="times" color="red"  style={{marginLeft:"0.5rem" }} />}
                            </Table.Cell>
                                <Table.Cell style={{textAlign: "left"}} negative={!invite.admin} positive={invite.admin}>
                                <Form.Input value    = {invite.role}
                                                          onChange = {(e, { value }) => {this.updateInvites(i, {role: value})}}
                                                    >
                                </Form.Input> 
                                          
                            </Table.Cell>
                          </Table.Row>}
                        </>
                      )
                    }
                  )}
                  {groups.map((group,i ) => <Table.Row key={i}>
                    <Table.Cell>
                      <Label style={{ padding: 5, marginRight: '0.5em'}}>G</Label>
                      {group.name}
                      {<div style={{float: 'right'}}><Icon name='delete' color='red' onClick={() => {this.removeGroup(group._id)}}/></div>}
                    </Table.Cell>
                    <Table.Cell style={{textAlign: "center"}} negative={!group.write} positive={group.write} onClick={() => {this.toggleGroupRights(i, "write")}}>
                      {group.write ? <Icon name="check" color="green" /> : <Icon name="times" color="red"/>}
                    </Table.Cell>
                    <Table.Cell style={{textAlign: "center"}} negative={!group.admin} positive={group.admin} onClick={() => {this.toggleGroupRights(i, "admin")}}>
                      {group.admin ? <Icon name="check" color="green" /> : <Icon name="times" color="red"/>}
                    </Table.Cell>
                    <Table.Cell></Table.Cell>
                  </Table.Row>)}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colspan="4">
                      <Button icon="plus" primary content={LocalizedStrings.AddGroup} onClick={this.openAddGroup}/>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
              {this.addGroupModal()}
            </>)
  }

  renderMemberSelection = () => {
    let collaborators = this.state.collaborators

    return (
      <>
        <Header>{LocalizedStrings.Collaborators}</Header>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                {LocalizedStrings.Collaborator}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {LocalizedStrings.AddToProject}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {collaborators.map((collaborator) => (
              <Table.Row key={collaborator.name}>
                <Table.Cell>{collaborator.name}</Table.Cell>
                <Table.Cell>
                  <Checkbox
                    checked={collaborator.includeInProject}
                    onChange={this.onCheckboxClickedUser(collaborator.username)}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </>
    );
  }

  renderInvites = () => {
    const someInvalid = this.state.invites.some(i => !ValidateEmail(i.mail))
    return (
      <>
        <Header>
          {LocalizedStrings.InviteHeader}
        </Header>
        {this.renderCollaboratorSearch()}
        {someInvalid && <Message warning>{LocalizedStrings.InvalidMail}</Message>}
        {this.state.invites.length > 0 && this.renderMailListTable()}
      </>
    );
  }

  renderMailListTable = () => {
    return (
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan={2}>
                <Header as="h5">
                  {LocalizedStrings.MailList}
                </Header>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
          {this.state.invites.map((invite, index) => {
            const mailInvalid = !ValidateEmail(invite.mail)
            return (
              <>
                <Table.Row>
                  <Table.Cell>
                    <Input
                      error={mailInvalid}
                      fluid
                      placeholder={LocalizedStrings.Mail}
                      onChange={(e) => {
                        this.updateInvites(index, { mail: e.target.value });
                      }}
                      value={invite.mail}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Icon color="red" name="delete" onClick={() => { 
                        let newInvites = Array.from(this.state.invites)
                        newInvites.splice(index, 1)
                        this.setState({ invites: newInvites }) }
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              </>
            );
          })}
          </Table.Body>
        </Table>)
  }

  renderAddInviteMailButton = () => {
    return (<Button
      positive
      size="tiny"
      onClick={() => {
        this.setState({
          invites: [
            ...this.state.invites,
            { mail: "", write: false, admin: false },
          ],
        });
      }}
    >{LocalizedStrings.New}</Button>)
  }

  renderCollaboratorSearch = () => {
    const {collaborators, search, usersFromSearch} = this.state

    // remove users that are already collaborators
    const filteredUsersFromSearch = usersFromSearch.filter(
      (u) => !collaborators.some((c) => c.username === u.username)
    );

    // control pagination
    const usersPerPage = 10
    const firstUserToShow = (this.state.activeUsersPage - 1) * 10
    const lastUserToShow = firstUserToShow + usersPerPage
    const showUsers = this.state.searchExecuted ? filteredUsersFromSearch.slice(firstUserToShow, lastUserToShow) : [] // define users to show
    const totalPages = Math.ceil(filteredUsersFromSearch.length / usersPerPage)

    // number of characters before search is available 
    const minChars = 3
    
    // view conditions
    const noUsersFound = showUsers.length === 0 && this.state.searchExecuted
    const noSearchPossible = search.length < minChars

    return (<>
      <Form>
        <Form.Group>
          <Form.Input
            fluid
            width={10}
            value={search}
            onChange={this.onInputChange}
            onBlur={this.executeUserSearch}
          />
          <Form.Button
            width={6}
            positive
            fluid
            disabled={noSearchPossible || this.state.searchExecuted}
            onClick={this.executeUserSearch}
          >
            {LocalizedStrings.Search}
          </Form.Button>
        </Form.Group>
      </Form>
      <List celled style={{ maxHeight: "300px", overflowY: "auto" }}>
        {noUsersFound && this.renderAddInviteMailButton()}
        {search.length >= minChars &&
          <>
            {showUsers.map((user, i) => (
              <List.Item
                key={user._id}
                onClick={() => this.onUserClicked(user)}
              >
                <List.Content>
                  {user.name}
                </List.Content>
              </List.Item>
            ))}
            {totalPages >= 2 && <Pagination
              onPageChange={(_, { activePage }) => {
                this.setState({ activeUsersPage: activePage });
              }}
              activePage={this.state.activeUsersPage}
              totalPages={totalPages}
            />}
          </>
        }
      </List>
    </>)
  }

  renderFolders = () =>
  {
    return (
      <div style={{marginBottom: "3rem"}}>
        <BasicSelect
            normalDropdown
            style={{width: "13rem"}}
            button
            className='icon'
            labeled
            icon='folder open outline'
            options={this.state.folders}
            placeholder={LocalizedStrings.SelectFolder}
            onStateUpdate={(name, value, e) => {this.setState({selectedFolder: value})}}
        />
      </div>
    )
  }

  onUserClicked = (user) => {
    const newCollaborator = {
      includeInProject: false,
      name: user.name,
      username: user.username,
      _id: user._id 
    }
    this.setState({collaborators : [...this.state.collaborators, newCollaborator]})    
  }

  onInputChange = (_, {value}) => {
    this.setState({
      search: value,
      searchExecuted: false
    })    
  }

  executeUserSearch = async() => {
    const usersFromSearch = await window.LR_FindUserSubset(this.state.search)
    this.setState({usersFromSearch, searchExecuted: true})
  }

  switchReview = (_ ,{value}) => {
    this.setState({review: value})
  }

  //--------------------
  // GROUPS
  //--------------------

  removeGroup = (groupId) => {
    this.setState(state => {
      const usedGroups = state.usedGroups.filter(group => group._id !== groupId)
      return { usedGroups }
    })
  }

  toggleGroupRights = (groupIndex, rightToToggle) => {
    this.setState(({usedGroups}) => ({ 
      usedGroups: [
        ...usedGroups.slice(0,groupIndex), 
        {
          ...usedGroups[groupIndex],
          [rightToToggle]: !usedGroups[groupIndex][rightToToggle]
        },
        ...usedGroups.slice(groupIndex+1)
      ]
    }))
  }

  openAddGroup  = () => this.setState({openGroup: true})

  addGroupModal = () => {
    let groups = this.state.groups ?? []

    let newUsedGroups = (this.state.usedGroups ?? []).concat(this.state.editGroups ?? [])

    let unusedGroups = groups.filter(group => !newUsedGroups.find(usedGroup => usedGroup._id === group._id))

    return (<LRModal  open={this.state.openGroup} 
                      onCancelClick={this.cancelAddGroup} 
                      onOkClick={this.applyAddGroup} 
                      title={LocalizedStrings.AddGroup}>
              <List celled>
                {unusedGroups.map(group => <List.Item key={group.name} onClick={() => this.addGroup(group)}>
                  <List.Content>
                    {group.name}
                  </List.Content>
                </List.Item>)}
              </List>
            </LRModal>)
  }

  applyAddGroup = () => {
    this.setState({openGroup: false, usedGroups: [...this.state.usedGroups, ...this.state.editGroups], editGroups: []})
  }
  
  cancelAddGroup = () => {
    this.setState({
      openGroup: false,
      editGroups: [],
      organizations: this.state.organizations.map(org => (org.username === this.state.selectedUser ? {
        ...org,
        editGroups: []
      } : org))
    })
  }

  addGroup = (group) => {
    this.setState({editGroups: [...this.state.editGroups, group]})
  }

  //--------------------
  // RIGHTS
  //--------------------
  
  toggleMemberRights = (memberIndex, rightToToggle) => {
    this.setState(({collaborators}) => ({ 
      collaborators: [
        ...collaborators.slice(0,memberIndex), 
        {
          ...collaborators[memberIndex],
          [rightToToggle]: !collaborators[memberIndex][rightToToggle]
        },
        ...collaborators.slice(memberIndex+1)
      ]
    }))
  }

  handleRoleChange = (i, value) => {
    this.setState(({collaborators}) => ({ 
      collaborators: collaborators.map((col,j) => ({
        ...col,
        role: i === j ? value : col?.role
      }))
    }))
  }
  //--------------------
  // MEMBERS
  //--------------------

  // the new invite doesn't have to specify all key:value pairs
  updateInvites = (inviteIndex, newInvite) => {
    this.setState(({invites}) => ({ 
      invites: [
        ...invites.slice(0,inviteIndex), 
        {
          ...invites[inviteIndex],
          ...newInvite
        },
        ...invites.slice(inviteIndex+1)
      ]
    }))
  }

  onCheckboxClickedUser = (username) => (_ , {checked}) => 
  {
    let newProject = this.state.collaborators.map(entry => 
      {
        if(username === entry.username) 
        {
          return {...entry, includeInProject: checked, write: false, admin: false}
        }
        return {...entry}
      })
    this.setState({collaborators: newProject})
  }

  //--------------------
  // CHECKS
  //--------------------

  onCheckboxClickedCheck = (id) => (_ , {checked}) => 
  {
    let newChecks = this.state.checks.map(entry => 
      {
        if(id === entry.id) 
        {
          return {...entry, includeInProject: checked}
        }
        return {...entry}
      })
    this.setState({checks: newChecks})
  }

  //--------------------
  // ORGANIZATIONS
  //--------------------

  switchOrganization = (_, {value}) =>
  {
    this.setState({
      selectedUser: value
    }, this.refreshForUser)
  }

  // gets relevant data on the new project from state
  getData() 
  {
    let name = this.state.name
    let prettyName = this.state.editName
    let review = this.state.review
    let usedGroups = this.state.usedGroups
    let tmpCollaborators = this.state.collaborators
    let tmpChecks = this.state.checks
    
    let collaborators = []
    let checks = []
    let selectedFolder = this.state.selectedFolder
    tmpCollaborators.forEach( entry => { if(entry.includeInProject) { collaborators.push({username: entry.username, write: entry.write, admin: entry.admin, role: entry.role}) }})
    tmpChecks.forEach( entry =>        { if(entry.includeInProject) { checks.push(entry) }})

    // format valid invites to fit the member scheme
    const invites = this.state.invites
      .filter((i) => ValidateEmail(i.mail))
      .map((i) => ({ invite: { issuedForMail: i.mail }, admin: i.admin, write: i.write, role: i.role }));

    if(!this.state.selectedUser)
    { 
      return {};
    }

    return {
      Name            : name, 
      PrettyName      : prettyName,
      Review          : review,
      Collaborators   : collaborators,
      Invites         : invites,
      Checks          : checks,
      Groups          : usedGroups,
      ForUser         : this.state.selectedUser === "loggedIn" ? undefined : this.state.selectedUser,
      BaseProject     : this.state.baseProject,
      SymbolMapTemplate: this.state.symMap,
      selectedFolder  : selectedFolder
    }
  }
}

export default CreateNewProject;
