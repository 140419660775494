
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Button, Dimmer, Divider, Form, Grid, Header, Icon, Label, List, Loader, Message, Table } from 'semantic-ui-react';
import LocalizedStrings from "../../localization/ResourceManager";
import { FetchGroups } from '../../redux/actions/fetch_actions';
import { lrServerConnection } from '../../redux/light_right_server_connection';
import { GROUPS } from '../../redux/redux_defines';
import { globalCallbacks } from '../../util/callback';
import { IsRunningAsBrowser } from '../../util/defines';
import LRModal from '../Basics/BasicModal';
import { SymbolMapDisplayAddEntryModal } from './SymbolMapDisplayAddEntryModal';

class SymbolMapDisplay extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    {
      symbolMap:[ ],
      open: false,
      isLoading: false,
      templates: [],
      selectedMap: "",
      selectedMapUpdate: "",
      newTemplate: null,
      project: false,
      selectedGroup: "",
      selectedSymbolData: {
        IncomingSymbolName: undefined,
        PASymbol: undefined
      },
      updateIndex: undefined
    };

  }

  componentDidMount = async()  =>
  {
    this.setUpCallbacks();
    globalCallbacks.ressourceMapUpdaded();
    
  }

  

  onSelectGroup = (_, {value}) => {
    this.setState({
      selectedGroup: value
    })
  }

  getGroupsOfTemplate = () => 
    this.props.groups.data
      .filter(group => group.symMapTemplates.includes(this.state.selectedMap))
  
  getGroupsNotInTemplate = () => 
    this.props.groups.data
      .filter(group => !group.symMapTemplates.includes(this.state.selectedMap))

  render() 
  {
    if(IsRunningAsBrowser())
    {
      FetchGroups()
    }
    let templateOptions = [{value:"",text:"Default"},...this.state.templates.map(t => ({value: t, text: t}))]
    return(
      <React.Fragment>
      <Grid>
        <Grid.Column width="12">
          <Dimmer active={this.state.isLoading}>
            <Loader active={this.state.isLoading}/>
          </Dimmer>
          {this.render_table()}
        </Grid.Column>
        {this.state.project 
        ? <Grid.Column width="4">
          <Form>
            <Form.Select label="Template" selection fluid options={templateOptions} placeholder={"Default"} value={this.state.selectedMapUpdate} onChange={this.selectMapUpdate}/>
            <Form.Button primary content={"Update"} fluid onClick={this.onUpdateFromTemplate}/>
          </Form>
        </Grid.Column> 
        : <Grid.Column width="4">
          <Form>
            <Form.Dropdown label="Template" selection fluid value={this.state.selectedMap} options={templateOptions} onChange={this.selectMap} placeholder="Default"/>
            <Form.Group widths="equal">
              <Form.Button negative fluid content="Delete" disabled={this.state.selectedMap === ""} onClick={this.onDelete}/>
              <Form.Button primary fluid content="New" onClick={this.onAddTemplate}/>
            </Form.Group>
          </Form>
          {IsRunningAsBrowser() && this.state.selectedMap !== "" ? 
            <>
              <Divider/>
              <Header>Groups</Header>
              <List celled relaxed>
                { this.getGroupsOfTemplate()
                  .map((group, i) => <List.Item key={i}>
                    <List.Content>
                      <List.Content>
                        {group.name}
                        <div style={{float:"right"}} onClick={this.onDeleteGroup(group.name)}>
                          <Icon name="delete" color="red"/>
                        </div>
                      </List.Content>
                    </List.Content>
                  </List.Item>)
                }
              </List>
              <Form>
                <Form.Group>
                  <Form.Select 
                    options={
                      this.getGroupsNotInTemplate()
                        .map(group => ({text: group.name, value: group.name}))
                    } 
                    onChange={this.onSelectGroup}
                    value={this.state.selectedGroup}
                    width="11"/>
                  <Form.Button width="5" primary fluid content="Add Group" disabled={this.state.selectedGroup === ""} onClick={this.onAddGroup}/>
                </Form.Group>
              </Form>
            </> : null}
        </Grid.Column>}
      </Grid>

      <SymbolMapDisplayAddEntryModal
        open={this.state.open}
        value={this.state.selectedSymbolData.PASymbol}
        incomingSymbolName={this.state.selectedSymbolData.IncomingSymbolName}
        updateIndex={this.state.updateIndex}
        onCancelClick={this.onClose}
        onOkClick={this.onAdd}
      />

      {this.renderNewTemplateModal()}
     
      </React.Fragment>
    );
  }

  render_no_symbol_maps_message() {
    return <Table.Row>
      <Table.Cell colSpan="3">
        <Message style={{ width: '100%' }} warning>
          <Message.Header>{LocalizedStrings.NoSymbolHeader}</Message.Header>
          <p>{LocalizedStrings.NoSymbolText}</p>
        </Message>
      </Table.Cell>
    </Table.Row>
  }

  render_table() {
    return <Table selectable striped celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell></Table.HeaderCell>
          <Table.HeaderCell>{LocalizedStrings.Incoming}</Table.HeaderCell>
          <Table.HeaderCell>{LocalizedStrings.ReplaceWith}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {this.state.symbolMap.length ? this.state.symbolMap.map((entry, idx) => { return this.render_Row(entry, idx) }) : this.render_no_symbol_maps_message()}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan='3'>
            <Button
              floated='right'
              icon
              labelPosition='left'
              primary
              onClick={() => { this.setState({ open: true }) }}
              size='small'><Icon name='map signs' />{LocalizedStrings.AddMapEntry}</Button>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  }

  render_Row(entry, idx) 
  {
    return (
      <React.Fragment key={`${entry.From}-${entry.ToName}`}>
        <Table.Row>
          <Table.Cell>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-evenly"}}>
              <Icon name="delete" color="red" link onClick={() => this.onDeleteResourceEntry(entry, idx) }></Icon>
              <Icon name="pencil alternate" link onClick={() => this.editResourceEntry(entry, idx) }></Icon>
              <Icon name="copy" link onClick={() => this.copyResourceEntry(entry, idx) }></Icon>
            </div>
          </Table.Cell>
          <Table.Cell>
            {entry.From}
          </Table.Cell>
          <Table.Cell style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Label color={entry.User ? "green" : undefined}>{entry.User ? entry.User : LocalizedStrings.DefaultContent}</Label><br />
            <div style={{ marginLeft: 5 }}>{entry.ToName}</div>
          </Table.Cell>
        </Table.Row>
      </React.Fragment>
    )
  }

  editResourceEntry = (entry, idx) => {
    this.setState({
      selectedSymbolData: {
        IncomingSymbolName: entry.From,
        PASymbol: {
          newTo: entry.ToName,
          newUUID: entry.ToUUID,
          newIdent: entry.ToRessourceIdent,
          User: entry.User
        }
      },
      open: true,
      updateIndex: idx
    })
  }

  copyResourceEntry = (entry, idx) => {
    this.setState({
      selectedSymbolData: {
        IncomingSymbolName: entry.From,
        PASymbol: {
          newTo: entry.ToName,
          newUUID: entry.ToUUID,
          newIdent: entry.ToRessourceIdent,
          User: entry.User
        }
      },
      open: true,
      updateIndex: undefined
    })
  }

  onClose  = () =>
  {
    this.setState({open:false, selectedSymbolData: {}, updateIndex: undefined})
  }

  selectMap = (_,{value}) => {
    if(IsRunningAsBrowser() && !this.state.project) {
      this.setState({selectedMap: value}, this.onUpdated)
    } else {
      window.LR_SetSymbolMapTemplate({Name:value})
    }
  }
  selectMapUpdate = (_,{value}) => {
    this.setState({selectedMapUpdate: value}, this.onUpdated)
  }

  onAdd  = async (from, to, updateIdx) =>
  {
    let res = this.state.symbolMap

    if (!IsRunningAsBrowser() || this.state.project) {
      if(updateIdx !== undefined){
        await window.LR_DeleteSymbolMap({FromArray: [res[updateIdx].From]})
      }
      await window.LR_AddSymbolMap(
        {
          From: from,
          ToName: to.newTo,
          ToUUID: to.newUUID,
          ToRessourceIdent: to.newIdent,
          User: to.User
        })
    } else {
      if (updateIdx !== undefined) {
        res[updateIdx] = {
          From: from,
          ToName: to.newTo,
          ToUUID: to.newUUID,
          ToRessourceIdent: to.newIdent,
          User: to.User
        }
      } else {
        res.push({
          From: from,
          ToName: to.newTo,
          ToUUID: to.newUUID,
          ToRessourceIdent: to.newIdent,
          User: to.User
        })
      }
      await lrServerConnection.updateSymbolMapTemplate({
        templateName: this.state.selectedMap,
        templateData: res
      })
    }

    if(IsRunningAsBrowser()) this.onUpdated()
    this.onClose()
  }

  onDelete = async () => {
    await window.LR_DeleteSymbolMapTemplate({Name: this.state.selectedMap})
    this.setState({selectedMap: ""})
    if(!IsRunningAsBrowser()) {
      await window.LR_SetSymbolMapTemplate({Name: ""})
    }else{
      this.onUpdated()
    }
  }

  onAddTemplate = () => {
    this.setState({newTemplate: ""})
  }

  onCloseAddTemplate = () => {
    this.setState({newTemplate: null})
  }

  onOkAddTemplate = async () => {
    this.setState({templates: [...this.state.templates, this.state.newTemplate], selectedMap: this.state.newTemplate})
    await window.LR_NewSymbolMapTemplate({Name: this.state.newTemplate})
    if(IsRunningAsBrowser()) this.onUpdated()
    this.onCloseAddTemplate()
  }

  isAlphaNum = (c) => "abcdefghiklmnopqrstuvwxyzABCDEFGHIKLMNOPQRTSUVWXYZ0123456789-".includes(c)

  onTemplateNameChange = (_, {value}) => {
    value = value.replace(" ","-")
    let str = ""
    for (const c of value) {
      if(this.isAlphaNum(c)) str += c
    }
    this.setState({newTemplate: str})
  }

  renderNewTemplateModal = () => 
    <LRModal
      onOkClick={this.onOkAddTemplate}
      onCancelClick={this.onCloseAddTemplate}
      title="Add Template"
      size="mini"
      open={this.state.newTemplate !== null}>
      <Form fluid>
        <Form.Input value={this.state.newTemplate} onChange={this.onTemplateNameChange}/>
      </Form>
    </LRModal>

  onDeleteResourceEntry = async (entry, entryIdx) =>
  {
    this.setState({isLoading: true})
    if(IsRunningAsBrowser() && !this.state.project){
      let res = this.state.symbolMap
      res.splice(entryIdx, 1)
      await lrServerConnection.updateSymbolMapTemplate({
        templateName: this.state.selectedMap,
        templateData: res
      })
    }else{
      await window.LR_DeleteSymbolMap({FromArray: [entry.From]})
    }

    this.onUpdated()
  }

  onAddGroup = () => {
    this.setState({selectedGroup: ""})
    lrServerConnection.updateGroupsForSymbolMap(this.state.selectedMap, [...this.getGroupsOfTemplate(), this.state.selectedGroup])
    .then(() =>FetchGroups(true))
  }

  onDeleteGroup = (name) => () => {
    lrServerConnection.updateGroupsForSymbolMap(this.state.selectedMap, this.getGroupsOfTemplate().filter(group => group !== name))
    .then(() =>FetchGroups(true))
  }

  onUpdateFromTemplate = () => {
    window.LR_UpdateFromSymbolMapTemplate({Template: this.state.selectedMapUpdate})
    .then(this.onUpdated.bind(this))
  }

  async onUpdated()
  {
    let linkedProj = await window.LR_GetLinkedProject()
    this.setState({isLoading: true, project: !!(linkedProj.Project)});
    let temps = await window.LR_GetSymbolMapTemplates()
    if(!Array.isArray(temps.templates)) {
      temps = {templates: []}
    }
    this.setState({templates: temps.templates})
    if(!IsRunningAsBrowser()) {
      this.setState({ selectedMap:  temps.selected})
    }
    let res = await window.LR_GetSymbolMap({Template: this.state.selectedMap, UserBased: this.props.userBasedInWebBrowser})
    let nMap = res?.ResourceMap?.symbolMap ?? []
    nMap = nMap.sort((a, b) => b.ToName.localeCompare(a.ToName))

    this.setState({symbolMap: nMap, isLoading: false });
  }

  setUpCallbacks()
  {
    globalCallbacks.ressourceMapUpdaded = () =>
    {
      this.onUpdated()
    }
  }

}

const mapStateToProps = (state) => ({
  groups: state[GROUPS]
})

export default connect(mapStateToProps)(SymbolMapDisplay);