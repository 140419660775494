//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2019 www.deersoft.de
//-----------------------------------------------------------------------------
import LocalizedStrings from 'localized-strings';
import LocalizeOption from './LocalizationOption';

import en from "./raw/en/StructuralWizard.json"
import de from "./raw/de/StructuralWizard.json"
import es from "./raw/es/StructuralWizard.json"
import fr from "./raw/fr/StructuralWizard.json"
import it from "./raw/it/StructuralWizard.json"
import ru from "./raw/ru/StructuralWizard.json"
import cz from "./raw/cs/StructuralWizard.json"
 
let Strings = new LocalizedStrings(
{
  en: en,
  de: de,
  es: es,
  fr: fr,
  ru: ru,
  it: it,
  cz: cz,
},
  { ...LocalizeOption }
);

export default  Strings;