//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Button, Table } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/ResolveDoubleConnections";
import { RESOURCE_TYPE_SymbolDef } from '../../util/defines';
import LRModal from '../Basics/BasicModal';
import SelectResourceModal from "../ToolSelect/SelectRessourceModal";

import { globalCallbacks } from "../../util/callback";


//-----------------------------------------------------------------------------
// The component for the TreeControl
class ResolveDoubleConnections extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
      SelectionOpen: false,
      ConnectorSelection:{},
      Connectors:[],
      ObjectList:[],
    }
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }

  show = async(args) => 
  {
    this.setState( { 
        open : true, 
        ConnectorSelection: {},
        Connectors:args.Connectors,
        ObjectList: args.ObjectList
      });
  }

  close = () => 
  {
    window.LR_ResolveMultipleOutputUsage({
      ConnectorSelection: this.state.ConnectorSelection,
      ObjectList: this.state.ObjectList
    })

    this.setState({open : false});
  }
    
  render() 
  {
    let open = this.state.open;
    if(!open) {return <div/>}

    return (
      <LRModal  open={open} 
                onCancelClick={this.close} 
                closeOnDimmerClick={false} 
                size="large"
                scrolling={false}
                title={LocalizedStrings.Header}
                noCancel
                onOkClick={this.close}>
          <Table>
            <Table.Header>
            <Table.Row>
            <Table.HeaderCell>{LocalizedStrings.ConnectorType}</Table.HeaderCell>
            <Table.HeaderCell>{LocalizedStrings.SelectedSymbol}</Table.HeaderCell>
            <Table.HeaderCell>{LocalizedStrings.SelectedSymbol}</Table.HeaderCell>
            </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.Connectors.map(e=>this.renderRow(e))}
            </Table.Body>
          </Table>
          <SelectResourceModal SelectRessourceCallback={this.selectResourceSelector} open={this.state.SelectionOpen} close={this.closeResourceSelector} resourceType={RESOURCE_TYPE_SymbolDef}/>
      </LRModal>
    )
  }

  renderRow(connector)
  {
    let selectedSymbol = this.state.ConnectorSelection[connector]
    let ReplaceMent = null
    if(selectedSymbol)
    {
      ReplaceMent = <>
      {selectedSymbol.Name}
      </>
    }

    return<Table.Row>
      <Table.Cell>{connector}</Table.Cell>
      <Table.Cell>{ReplaceMent}</Table.Cell>
      <Table.Cell>
        <Button positive onClick={()=>this.openResourceSelector(connector)}>
          {LocalizedStrings.SelectSymbol}
        </Button>
      </Table.Cell>
    </Table.Row>
  }


  openResourceSelector = (connector) =>
  {
    this.setState({SelectionOpen: true})
    this.Connector = connector
  }

  closeResourceSelector = async () =>
  {
    this.setState({SelectionOpen: false})
  }

  selectResourceSelector = async (ResourceType) =>
  {
    let object = await window.LR_GetActiveResource({ResourceType})

    this.setState({ConnectorSelection: {[this.Connector] : object}})
  }

  setUpCallbacks()
  {

    globalCallbacks.ShowResolveDoubleConnections = (args) => 
    { 
      this.show(args);
    }
    
  }
}

export default ResolveDoubleConnections