//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------

import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react'
import LocalizedStrings from "../localization/MobileFooter";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ACTIVE_PROJECT, ACTIVE_USER } from "../redux/redux_defines"
import { hasFetched } from '../redux/actions/fetch_actions';


class MobileFooter extends Component 
{
    constructor(props)
    {
      super(props);
      this.state = {
      }
    }

    render()
    {
        let activeUser    = this.props.activeUser.data

        let username 
        if(activeUser)
        {
            username = activeUser.username
        }


        let owner = false
        let project = false
        if(hasFetched(this.props.activeProject) && this.props.activeProject.data.name)
        {
            owner = this.props.activeProject.data.owner.username
            project = this.props.activeProject.data.name
        }
        

        return(
            <div style={{ position: "fixed", bottom: "0", width: "100%", backgroundColor:"rgb(202, 203, 205)"}}>
                <div style={{display: 'flex', flexWrap: "wrap",   justifyContent: "center", textAlign: "center"}}>
                    {this.renderMenuItem("file", LocalizedStrings.Project, `/${username}`)}
                    {this.renderMenuItem("tasks", LocalizedStrings.Task, `/${owner}/${project}/tasks`, owner)}
                    {this.renderMenuItem("eye", LocalizedStrings.GraphicView, `/${owner}/${project}/worksheet/view`, owner)}
                    {this.renderMenuItem("table", LocalizedStrings.TableView, `/${owner}/${project}/worksheet`, owner)}
                    {this.renderMenuItem("camera", LocalizedStrings.Camera,`/camera`)}
                </div>
            </div>
        )
    }


    renderMenuItem = (icon, name, path, enabeld = true) =>
    {
        console.log("enabeld", enabeld)
        let entry = 
        <>
            <Icon name={icon} size="big"/>
            <br/>
            {name}
        </>
        return(
            <div style={{ top: "50%",fontSize:"0.9em", minHeight:"2em", flexGrow:"1", borderStyle:"solid", borderWidth: ".3px", paddingLeft: "5px", paddingTop: "4px",paddingRight: "4px", wordBreak: "normal", wordWrap: "normal", textAlign: "center"}}>
                {enabeld ? 
                <Link to={path} style={{color:"black"}}>
                {entry}
                </Link> 
                : 
                entry
                }
            </div>
        )
    }
   
}
//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      activeProject: state[ACTIVE_PROJECT],
      activeUser: state[ACTIVE_USER],
    };
}


export default connect(mapStateToProps)(MobileFooter);