//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component, } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { PROJECTS } from '../../redux/redux_defines'
import { Table, Icon, Divider, Header, Button } from 'semantic-ui-react';
import UserAvatar from '../WebComponents/UserAvatar';
import QrCodeReader from '../QrCodeScanner/QrCodeScanner';


class SheduledMentenaince extends Component 
{
  constructor(props)
    {
        super(props);
        this.state = {
          open:false
        }
    }
  render()
  {
    return(<>
    <Button onClick={()=>{this.setState({open: !this.state.open})}}><Icon name="qrcode"/>Scan QR Code</Button>
    <Header>Due Today</Header>
    <Divider/>
    {this.renderTimeSpan()}
    <Header>Due this week</Header>
    <Divider/>
    {this.renderTimeSpan()}
    <Header>Due next week</Header>
    <Divider/>
    {this.renderTimeSpan()}
    <Header>Due next month</Header>
    <Divider/>
    {this.renderTimeSpan()}
    <QrCodeReader 
      open={this.state.open}
      noCancelButton
      onCancelClick={()=>{this.setState({open:false})}}
      onOkClick={()=>{this.setState({open:false})}}
      title={"Scan QR-Code from Object"}
      size="mini"
      noCancel={true}
      />
    </>)
  }

  renderTimeSpan()
  {
    return(
      <>
      <Table celled striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell>Maintenance</Table.HeaderCell>
          <Table.HeaderCell>Duration</Table.HeaderCell>
          <Table.HeaderCell>User</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
      {this.renderTimeSpanTest()}
      {this.renderTimeSpanTest()}
      {this.renderTimeSpanTest()}
      {this.renderTimeSpanTest()}
      {this.renderTimeSpanTest()}
      {this.renderTimeSpanTest()}
      {this.renderTimeSpanTest()}
      {this.renderTimeSpanTest()}
      </Table.Body>
      </Table>
      </>
    )
  }
  renderTimeSpanTest()
  {
    return(
      <>
      <Table.Row>
      <Table.Cell>
          20.12.2032 
        </Table.Cell>
      <Table.Cell>
          UVV Prüfung 
        </Table.Cell>
        <Table.Cell>
          20min
        </Table.Cell>
        <Table.Cell>
          <UserAvatar/>mstaffel
          <UserAvatar/>mstaffel
        </Table.Cell>
        <Table.Cell>
          <Button as={Link} to="mstaffel/mentenaince/fancyId" fluid compact>Start</Button>
        </Table.Cell>
      </Table.Row>
      </>
    )
  }

}

//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      projects: (state[PROJECTS])
    };
}

export default connect(mapStateToProps)(SheduledMentenaince)
