import React from 'react'
import { connect } from 'react-redux';
import { Table, Button, Icon, Input, Form, List, Checkbox } from 'semantic-ui-react';
import { hasFetched, FetchUserReviewTemplates, FetchCollaborators, FetchActiveAdmin, FetchMiniProjects , FetchActiveUser }  from '../../redux/actions/fetch_actions';
import { USER_REVIEW_TEMPLATES, COLLABORATORS, ACTIVE_USER , ACTIVE_ADMIN, MINI_PROJECTS } from "../../redux/redux_defines"
import { lrServerConnection } from '../../redux/light_right_server_connection';
import LRModal from '../Basics/BasicModal';
import LocalizedStrings from "../../localization/Review"
import BasicSelect from '../Basics/BasicSelect';


class ReviewTemplateList extends React.Component {
    constructor(props)
    {
        super(props)

        this.state = {
            // edit modal
            editModalOpen: false,
            editTemplate: undefined,

            // used in edit modal to add users to the review template
            addUserToReviewModalOpen: false,
            addedUsers: [], // is set in this modal and used by edit modal
            search: '',

            // create modal
            createModalOpen: false,

            // table
            createName: "Review",
            defaultBasedProject: undefined,
            defaultSymbolMapTemplate: undefined,
            defaultReviewTemplate: undefined,
            defaultResourcesUser: undefined,
            orgOptions:[],
            defaultChange: false,

            symbolMapTemplates: []
        }
        this.usersBeforeEdit = [] // this can be concatenated with addedUsers to have a list of all users during edit
    }

    componentDidMount() 
    {
        FetchUserReviewTemplates(true);
        FetchActiveAdmin()
        FetchMiniProjects()
        lrServerConnection.getSymbolMapTemplates().then((templates) => this.setState({symbolMapTemplates: templates}))

        lrServerConnection.getDefaultTemplatesOfSelectedUser(this.props.loggedInUser.data.name).then(tempData => {
            this.setState({
                defaultBasedProject: tempData.defaultBasedProject,
                defaultSymbolMapTemplate: tempData.defaultSymbolMapTemplate,
                defaultReviewTemplate: tempData.defaultReviewTemplate,
                defaultResourcesUser: tempData.defaultResourcesUser
            })
        })

        lrServerConnection.getUserOrgs(this.props.loggedInUser.data.name).then(organizations => 
            {
                if(Array.isArray(organizations))
                {
                    let orgOptions = [{text: LocalizedStrings.DefaultNone,key: "",value: ""},...organizations.map(org => {return {text: org.name, value: org.username, key: org.username}})]
                    this.setState({orgOptions})
                }
            })
    }

    componentDidUpdate(prevProps, prevState)
    {
        if(prevProps.activeAdmin.data !== this.props.activeAdmin.data)
        {
            FetchUserReviewTemplates(true);
            FetchMiniProjects(true)

            lrServerConnection.getDefaultTemplatesOfSelectedUser(this.props.activeAdmin.data.name).then(tempData => {
                this.setState({
                    defaultBasedProject: tempData.defaultBasedProject,
                    defaultSymbolMapTemplate: tempData.defaultSymbolMapTemplate,
                    defaultReviewTemplate: tempData.defaultReviewTemplate,
                })
            })
        }
    }

    render() 
    {
        FetchUserReviewTemplates()
        let reviewTemplates   = hasFetched(this.props.userReviewTemplates) ? this.props.userReviewTemplates.data : []

        let projects          = hasFetched(this.props.projects)            ? this.props.projects.data            : []

        let projectOptions    = [{text: LocalizedStrings.DefaultNone,key: "",value: ""}  ,...projects.map(project                  => {return { text: project.name, value: project.projectname, key: project._id }})]
        let symMapOptions     = [{text:LocalizedStrings.DefaultNone, value:"", key:""}   ,...this.state.symbolMapTemplates.map(str => {return {text: str, value: str, key: str}})]
        let reviewTempOptions = [{text: LocalizedStrings.DefaultNone, key: "", value: ""},...reviewTemplates.map(temp              => {return { text: temp.name, key: temp._id, value: temp._id}})]
        
        return (<>
                    <Table striped celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan='2'>
                                    {LocalizedStrings.Name}
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {reviewTemplates.map(template => this.renderTableRow(template))}
                        </Table.Body>
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan='2'>
                                    <Button onClick={() => {this.setState({createModalOpen: true})}}
                                            floated="right"
                                            icon
                                            labelPosition="left"
                                            primary
                                            size="small">
                                        <Icon name="eye"/>
                                        {LocalizedStrings.AddReview}
                                    </Button>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>

                    <Table striped celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell colSpan='2'>
                                    {LocalizedStrings.AddDefaultTemplates}
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    <BasicSelect
                                        name          = "baseOnDefaultProject"
                                        label         = {LocalizedStrings.BaseOnDefaultProject}
                                        options       = {projectOptions}
                                        value         = {this.state.defaultBasedProject}
                                        onStateUpdate = {(name, value) => {this.setState({defaultBasedProject: value, defaultChange: true})}}/>
                                </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>
                                    <Form.Select
                                        label         = {LocalizedStrings.DefaultSymbolMapTemplate}
                                        placeholder   = {LocalizedStrings.SymbolMapTemplateDefault}
                                        options       = {symMapOptions}
                                        onChange      = {(_, { value }) => {this.setState({defaultSymbolMapTemplate: value, defaultChange: true})}}
                                        value         = {this.state.defaultSymbolMapTemplate}
                                        search
                                        fluid
                                        selection/>
                                    </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>
                                    <Form.Select
                                        options       = {reviewTempOptions}
                                        label         = {LocalizedStrings.DefaultReviewTemplate}
                                        onChange      = {(_, { value }) => {this.setState({defaultReviewTemplate: value, defaultChange: true})}}
                                        value         = {this.state.defaultReviewTemplate} 
                                        search
                                        fluid
                                        selection />
                                </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>
                                    <Form.Select
                                        options       = {this.state.orgOptions}
                                        label         = {LocalizedStrings.DefaultResourceTemplate}
                                        onChange      = {(_, { value }) => {this.setState({defaultResourcesUser: value, defaultChange: true})}}
                                        value         = {this.state.defaultResourcesUser} 
                                        search
                                        fluid
                                        selection />
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan='2'>
                                    <Button 
                                            disabled = {!this.state.defaultChange}
                                            floated="right"
                                            primary
                                            size="small"
                                            onClick = {this.applyDefaultTemplates}>
                                        {LocalizedStrings.ApplyDefaultTemplates}
                                    </Button>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                    {this.createModal()}
                    {this.editModal()}
                </>)
    }

    renderTableRow = (template) => {
        return  <Table.Row key={template._id}>
                    <Table.Cell>
                        <Icon name="delete" 
                              color="red" 
                              onClick={() => { this.deleteReview(template) }} />
                        <Input value = {this.state["editName" + template._id] ?? template.name}
                               transparent
                               onChange = {(e, {value}) => { this.setState({ ["editName" + template._id]: value }) }}
                               onBlur = {() => { this.changeReviewNameServer(template) }}/>
                    </Table.Cell>
                    <Table.Cell collapsing>
                        <Icon name="edit" 
                              onClick={() => {this.onEditClick(template)}} />
                    </Table.Cell>
                </Table.Row> 
    }

    onEditClick = (template) => {
        // get all the users in review before edit
        let usersWithDuplicates = template.Any
        usersWithDuplicates = usersWithDuplicates.concat(template.Audio)
        usersWithDuplicates = usersWithDuplicates.concat(template.Video)
        usersWithDuplicates = usersWithDuplicates.concat(template.Light)
        usersWithDuplicates = usersWithDuplicates.concat(template.Rigging)
        
        usersWithDuplicates.forEach(user => {
            if (!this.usersBeforeEdit.some(unique => unique._id === user._id))
            {
                this.usersBeforeEdit.push(user)
            }
        })

        this.setState({ editModalOpen: true, editTemplate: template })
    }

    deleteReview = async(template) => {
        await lrServerConnection.deleteReviewTemplate({reviewId: template._id})
        FetchUserReviewTemplates(true)
    }

    changeReviewNameServer = async(template) => {
        await lrServerConnection.editReviewTemplate({review : { name: this.state["editName" + template._id] }, reviewId : template._id})
        FetchUserReviewTemplates(true)
    }

    /*----------------------------------------------------------------------------
    EDIT MODAL
    ----------------------------------------------------------------------------*/

    editModal = () => {
        let anyUsers = this.state.editTemplate ? this.state.editTemplate.Any: []
        let audioUsers = this.state.editTemplate ? this.state.editTemplate.Audio : []
        let videoUsers = this.state.editTemplate ? this.state.editTemplate.Video : []
        let lightUsers = this.state.editTemplate ? this.state.editTemplate.Light : []
        let riggingUsers = this.state.editTemplate ? this.state.editTemplate.Rigging : []

        // all users during editing
        let reviewUsers = this.usersBeforeEdit.concat(this.state.addedUsers)
        
        let colNum = reviewUsers.length+1

        return (<>
                    <LRModal    title={LocalizedStrings.EditReview}
                                open={this.state.editModalOpen}
                                onCancelClick={() => this.closeEditModal()}
                                onOkClick={this.editReviewTemplate}>
                        <Table compact>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{LocalizedStrings.Craft}</Table.HeaderCell>
                                    {reviewUsers.map(user => { return this.renderUserHeaderCell(user)})}
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.renderReviewRow("box","Any", anyUsers, reviewUsers)}
                                {this.renderReviewRow("file audio","Audio", audioUsers, reviewUsers)}
                                {this.renderReviewRow("video","Video", videoUsers, reviewUsers)}
                                {this.renderReviewRow("lightbulb","Light", lightUsers, reviewUsers)}
                                {this.renderReviewRow("won","Rigging", riggingUsers, reviewUsers)}
                            </Table.Body>
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell colSpan={colNum}>
                                        <Icon   name='add' 
                                                color='blue' 
                                                onClick={() => {this.setState({addUserToReviewModalOpen: true})}}/>
                                        {LocalizedStrings.AddReviewer}
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                    </LRModal>
                    {this.addUserToReviewModal(reviewUsers)}
                </>)
    }

    renderUserHeaderCell = (user) => {
        return( <Table.HeaderCell key ={user.username}>
                    {user.name}
                    <Icon   name="delete" 
                            color="red" 
                            onClick={() => { this.removeUserFromTemplate(user) }} />
                </Table.HeaderCell>)
    }

    removeUserFromTemplate = (removeUser) => {
        // remove the user from all the arrays they might be part of
        let newAny = [...this.state.editTemplate.Any].filter(user => user._id !== removeUser._id)
        let newAudio = [...this.state.editTemplate.Audio].filter(user => user._id !== removeUser._id)
        let newVideo = [...this.state.editTemplate.Video].filter(user => user._id !== removeUser._id)
        let newLight = [...this.state.editTemplate.Light].filter(user => user._id !== removeUser._id)
        let newRigging = [...this.state.editTemplate.Rigging].filter(user => user._id !== removeUser._id)
        let newAddedUsers = [...this.state.addedUsers].filter(user => user._id !== removeUser._id)

        this.usersBeforeEdit = this.usersBeforeEdit.filter(user => user._id !== removeUser._id)

        this.setState({
            editTemplate : {
                ...this.state.editTemplate,
                Any: newAny,
                Audio: newAudio,
                Video: newVideo,
                Light: newLight,
                Rigging: newRigging,
            },
            addedUsers : newAddedUsers
        })
    }

    renderReviewRow = (icon, rowName, assignedUsers, reviewUsers) =>
    {
        return( <Table.Row key={rowName}>
                    <Table.Cell><Icon name={icon}/>{LocalizedStrings[rowName]}</Table.Cell>
                    {reviewUsers.map(user => { return this.renderCheckbox(user, rowName, assignedUsers) })}
                </Table.Row>)
    }

    renderCheckbox = (user, assignTo, assignedUsers) => {
        let currChecked = assignedUsers.some(assignedUser => assignedUser.username === user.username)

        return (<Table.Cell key ={user.username}>
                    <Checkbox   checked={currChecked} 
                                onClick={(e, {checked}) => {this.toggleUserAssignment(user, checked, assignTo)}}/>
                </Table.Cell>)
    }

    toggleUserAssignment = (user, checked, assignTo) => {

        // add/remove the user to/from the array 
        let newArr = [...this.state.editTemplate[assignTo]]
        if (checked)
        {
            newArr.push(user) 
        }
        else 
        {
            newArr = newArr.filter(assignedUser => assignedUser._id !== user._id)
        }

        this.setState({ editTemplate: {...this.state.editTemplate, [assignTo]: newArr } })
    }

    editReviewTemplate = async() => {
        let newVersion = {
            Audio: this.state.editTemplate.Audio.map(user => user._id),
            Video: this.state.editTemplate.Video.map(user => user._id),
            Rigging: this.state.editTemplate.Rigging.map(user => user._id),
            Light: this.state.editTemplate.Light.map(user => user._id),
            Any: this.state.editTemplate.Any.map(user => user._id),
        }

        await lrServerConnection.editReviewTemplate({review: newVersion, reviewId: this.state.editTemplate._id})
        FetchUserReviewTemplates(true)

        this.closeEditModal()
    }

    closeEditModal = () => {
        this.usersBeforeEdit = []
        this.setState({editModalOpen: false, editTemplate: undefined, addedUsers: [], search: ""})
    }

    /*----------------------------------------------------------------------------
    ADD USER TO REVIEW MODAL (PART OF EDIT MODAL)
    ----------------------------------------------------------------------------*/
    
    addUserToReviewModal = (reviewUsers) => {
        FetchCollaborators()
        let collaborators = hasFetched(this.props.collaborators) ? this.props.collaborators.data.map(e=>e) : []
        let search = this.state.search

        if(hasFetched(this.props.loggedInUser))
        {
            collaborators.push({
                ...this.props.loggedInUser.data
            })
        }


        
        // don't show the user if they're part of the review already or excluded by search
        let showUsers = collaborators.filter(user => {
            if (reviewUsers.find(u => u.username === user.username))
            {
                return false
            }

            let name = user.name.toLowerCase()
            return name.includes(search.toLowerCase())
        })

        return( <LRModal    open={this.state.addUserToReviewModalOpen}
                            size="mini"
                            title={LocalizedStrings.AddReviewer}
                            noCancel
                            onCancelClick={() => {this.setState({addUserToReviewModalOpen: false})}}
                            onOkClick={() => {this.setState({addUserToReviewModalOpen: false})}}>
                    <Input value={search} onChange={(e, {value}) => {this.setState({ search: value })}}/>
                    <List celled style={{maxHeight: "300px", overflowY: "auto"}}>
                        {showUsers.map(user =>  <List.Item key={user.username} onClick={() => {this.addUserToReview(user)}}>
                                                    <List.Content>
                                                        {user.name}
                                                    </List.Content>
                                                </List.Item>)}
                    </List>
                </LRModal>)
    }

    addUserToReview = (user) => {
        this.setState({addedUsers: [...this.state.addedUsers, user], addUserToReviewModalOpen: false})
    }

    /*----------------------------------------------------------------------------
    CREATE MODAL
    ----------------------------------------------------------------------------*/

    createModal = () => {
        return (<LRModal  title={LocalizedStrings.CreateReview}
                          open={this.state.createModalOpen}
                          onCancelClick={() => this.setState({createModalOpen: false, createName: "Review"})}
                          onOkClick={this.createReviewTemplate}>
                    <Form>
                      <Form.Input   value={this.state.createName}    
                                    onChange={(e, {value}) => {this.setState({createName: value})}}/>
                    </Form>
                </LRModal>)
    }


    createReviewTemplate = async() => {
        await lrServerConnection.createReviewTemplate({name: this.state.createName})
        FetchUserReviewTemplates(true)
        this.setState({createModalOpen: false, createName: "Review"})
    }

    applyDefaultTemplates = async () =>
    {
        let defaultTemplates = {
            defaultBasedProject:      this.state.defaultBasedProject,
            defaultSymbolMapTemplate: this.state.defaultSymbolMapTemplate,
            defaultReviewTemplate:    this.state.defaultReviewTemplate,
            defaultResourcesUser:  this.state.defaultResourcesUser
        }

       await lrServerConnection.updateDefaultTemplates(defaultTemplates)
         FetchActiveUser(true)
         FetchActiveAdmin(true)
        
         this.setState({defaultChange:false})
    }
}

//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      userReviewTemplates: state[USER_REVIEW_TEMPLATES],
      collaborators: state[COLLABORATORS],
      loggedInUser: (state[ACTIVE_USER]),
      activeAdmin: (state[ACTIVE_ADMIN]),
      projects: (state[MINI_PROJECTS])
    };
}

let componentExport;
if(!process.env.JEST_WORKER_ID) { componentExport = connect(mapStateToProps)(ReviewTemplateList) }
else                                         { componentExport = ReviewTemplateList }

export default componentExport 