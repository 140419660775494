//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component, Suspense } from 'react';
import LocalizedStrings from "../../localization/ViewStructuralResultsModal";
import LRModal from '../Basics/BasicModal';
import { connect } from 'react-redux';

const Line = React.lazy(async ()=>{
  const {
    Chart: ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } = await import("chart.js")

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  return import("react-chartjs-2").then(module=>({default:module.Line}))
})

import { getUnitValueFromCore, getUnit } from '../Basics/BasicUnitInput';
import { BASE_UNIT_FORCE, BASE_UNIT_LENGTH, BASE_UNIT_TORQUE } from '../../util/defines';
import { Form } from 'semantic-ui-react';



import { globalCallbacks } from "../../util/callback";
import { GLOBAL_SETTINGS } from '../../redux/redux_defines';

let X_COLOR = "rgb(255, 2, 1)"
let Y_COLOR = "rgb(0, 255, 3)"
let Z_COLOR = "rgb(7, 0, 255)"

//-----------------------------------------------------------------------------
// The component for the TreeControl
class ViewStructuralResultsModal extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
      ResultDisplay: [],
      ShowMax: false,
      ShowDeflection: false,
      ShowMoment: true,
      ShowForces: false
    }
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }

  show = async(arg) => 
  {
    this.setState( { 
        open : true, 
        ResultDisplay: arg
      });
  }

  close = () => 
  {
    this.setState({open : false});
  }
    
  render() 
  {
    let open = this.state.open;
    if(!open) {return <div/>}

    return (
      <LRModal  open={open} 
                onCancelClick={this.close} 
                closeOnDimmerClick={false} 
                size="fullscreen"
                scrolling={false}
                title={LocalizedStrings.Header}
                noCancel
                onOkClick={this.close}>
                  <>
                      <Form>
                        <Form.Checkbox checked={this.state.ShowMax} onClick={()=>this.setState({ShowMax: !this.state.ShowMax})} label={LocalizedStrings.ShowMaxValues} />
                        <Form.Checkbox checked={this.state.ShowDeflection} onClick={()=>this.setState({ShowDeflection: !this.state.ShowDeflection})} label={LocalizedStrings.ShowDeflection} />
                        <Form.Checkbox checked={this.state.ShowMoment} onClick={()=>this.setState({ShowMoment: !this.state.ShowMoment})} label={LocalizedStrings.ShowMoment} />
                        <Form.Checkbox checked={this.state.ShowForces} onClick={()=>this.setState({ShowForces: !this.state.ShowForces})} label={LocalizedStrings.ShowForces} />
                      </Form>
                      <Suspense fallback={<div />}>
                    {this.state.ResultDisplay.map(e=>
                      <React.Fragment key={e.Structure.UUID}>
                      {this.state.ShowDeflection ? this.renderLine(e, "Deflection", BASE_UNIT_LENGTH, 
                      [
                        {Value: e.Structure.MaxDx, Label:"Dx"},
                        {Value: e.Structure.MaxDy, Label:"Dy"},
                        {Value: e.Structure.MaxDz, Label:"Dz"},
                      ]) : null}
                      {this.state.ShowMoment ? this.renderLine(e, "Moment", BASE_UNIT_TORQUE, 
                      [
                        {Value: e.CrossSection.Mxx, Label:"CrossSectionMxx"},
                        {Value: e.CrossSection.Mby, Label:"CrossSectionMby"},
                        {Value: e.CrossSection.Mbz, Label:"CrossSectionMbz"},

                        {Value: e.Structure.MaxMt, Label:"CrossSectionMt"},
                        {Value: e.Structure.MaxMby, Label:"CrossSectionMby"},
                        {Value: e.Structure.MaxMbz, Label:"CrossSectionMbz"},
                      ]) : null}
                      {this.state.ShowForces ? this.renderLine(e, "Forces", BASE_UNIT_FORCE, 
                      [
                        {Value: e.CrossSection.Nx, Label:"CrossSectionNx"},
                        {Value: e.CrossSection.Vy, Label:"CrossSectionVy"},
                        {Value: e.CrossSection.Vz, Label:"CrossSectionVz"},

                        {Value: e.Structure.MaxNx, Label:"StructureNx"},
                        {Value: e.Structure.MaxVy, Label:"StructureVy"},
                        {Value: e.Structure.MaxVz, Label:"StructureVz"},
                      ]):null}
                      </React.Fragment>)}
                      </Suspense>
                  </>
      </LRModal>
    )
  }

  renderLine(e, name, unit, max)
  {
    let maxLines = []

    let grey = 220

    max.forEach(m=>
    {
      if(this.state.ShowMax && m.Value > 0)
      {
        maxLines.push(
          {
            label: LocalizedStrings[m.Label],
            data: e[name].map(e=>getUnitValueFromCore(m.Value, getUnit(unit, this.props.globalSettings) , unit)),
            borderColor: `rgb(${grey},${grey},${grey})`,
            backgroundColor: `rgba(${grey},${grey},${grey}, 0.5)`,
          },
          {
            label: LocalizedStrings[m.Label],
            data: e[name].map(e=>getUnitValueFromCore(-m.Value, getUnit(unit, this.props.globalSettings) , unit)),
            borderColor: `rgb(${grey},${grey},${grey})`,
            backgroundColor: `rgba(${grey},${grey},${grey}, 0.5)`,
          },
        )

        grey += 5
        }
      })
    

    return(
      <>
      <h1>{LocalizedStrings[name]}
      <i style={{color:X_COLOR}}> {LocalizedStrings[name+"X"]}</i>
      <i style={{color:Y_COLOR}}> {LocalizedStrings[name+"Y"]}</i>
      <i style={{color:Z_COLOR}}> {LocalizedStrings[name+"Z"]}</i>
      </h1>
      <Line       
      options={{
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: 'top',
          },
          title: {
            display: false,
            text: LocalizedStrings[name],
          },
        }}
      } 
        data={
        {
          labels: e.Distance.map(d=> getUnitValueFromCore(d, getUnit(BASE_UNIT_LENGTH, this.props.globalSettings) , BASE_UNIT_LENGTH).toFixed(0)),
          datasets: [
            {
              label: LocalizedStrings[name+"X"],
              data: e[name].map(e=> getUnitValueFromCore(e.X, getUnit(unit, this.props.globalSettings) , unit)),
              borderColor: X_COLOR,
              backgroundColor: 'rgba(255, 2, 1, 0.5)',
            },
            {
              label: LocalizedStrings[name+"Y"],
              data: e[name].map(e=>getUnitValueFromCore(e.Y, getUnit(unit, this.props.globalSettings) , unit)),
              borderColor: Y_COLOR,
              backgroundColor: 'rgba(0, 255, 3, 0.5)',
            },
            {
              label: LocalizedStrings[name+"Z"],
              data: e[name].map(e=>getUnitValueFromCore(e.Z, getUnit(unit, this.props.globalSettings) , unit)),
              borderColor: Z_COLOR,
              backgroundColor: 'rgba(7, 0, 255, 0.5)',
            },
            ...maxLines
          ],
        }
      } />
      </>
    )
  }

  setUpCallbacks()
  {

    globalCallbacks.ShowViewStructuralResults = (arg) => 
    { 
      this.show(arg);
    }
    
  }
}


//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      globalSettings: state[GLOBAL_SETTINGS].data.GlobalSettings,
    };
}


export default connect(mapStateToProps)(ViewStructuralResultsModal) 