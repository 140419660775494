import React from 'react'
import { Table, Icon } from "semantic-ui-react";
import { cie2hex } from '../ColorPicker/utilities';
import LocalizedStrings from "../../localization/DiffView"
import { BASE_UNIT_DATE } from '../../util/defines';
import UnitInput from '../Basics/BasicUnitInput'


// is supposed to match the enum for properties
export const propertyDiffTypes = {
    STRING   : 0,
    NUMBER   : 1,
    BOOL     : 2,
    VECTOR3D : 3,
    DATE     : 4,
    
    COLORCODEOBJ : 5,
    UUIDOBJECT   : 6,

    STRINGLIST    : 7,
    NUMBERLIST    : 8,
    UUIDOBJECTLIST: 9,
    ELECTRICALCONNECTION: 10,
    HierarchicalObjects: 11,
}

export default class DiffByProperty extends React.Component {
    constructor(props)
    {
        super(props)

        this.state = {}
    }

    // do any necessary fetching
    componentDidMount = async() => {
        switch(this.props.diffType)
        {
            case propertyDiffTypes.COLORCODEOBJ : 
                {
                    let existingColorCodeObj = await window.LR_GetColorCodeObject({UUID: this.props.existingValues[0]})
                    let newColorCodeObj = await window.LR_GetColorCodeObject({UUID: this.props.newValues[0]})
                    this.setState({existingColorCodeObj, newColorCodeObj})
                }
                break;

            default                             : return
        }
    }

    render() 
    {
        let typeBasedContent
        switch(this.props.diffType)
        {
            case propertyDiffTypes.STRING         : typeBasedContent = this.renderStringCell();      break;
            case propertyDiffTypes.NUMBER         : typeBasedContent = this.renderNumberCell();      break;
            case propertyDiffTypes.BOOL           : typeBasedContent = this.renderBoolCell();        break;
            case propertyDiffTypes.VECTOR3D       : typeBasedContent = this.renderVectorCell();      break;
            case propertyDiffTypes.DATE           : typeBasedContent = this.renderDateCell();        break;
            case propertyDiffTypes.STRINGLIST     : typeBasedContent = this.renderStringList();      break;                
            case propertyDiffTypes.NUMBERLIST     : typeBasedContent = this.renderNumberList();      break;  
            case propertyDiffTypes.UUIDOBJECT     : typeBasedContent = this.renderUUIDObjectCell();  break;            
            case propertyDiffTypes.UUIDOBJECTLIST : typeBasedContent = this.renderUUIDObjectList();  break;  
            case propertyDiffTypes.COLORCODEOBJ   : typeBasedContent = this.renderColorCodeCell();   break;                     
            case propertyDiffTypes.ELECTRICALCONNECTION         : typeBasedContent = this.renderElectricalConnection();      break;
            case propertyDiffTypes.HierarchicalObjects         : typeBasedContent = this.renderHierarchicalObjectsCell();      break;
            default                               : typeBasedContent = this.renderDefaultCell();     break;
        }
              
        return (<>
                    <Table.Cell>
                        {typeBasedContent.existingValue}
                    </Table.Cell>
                    <Table.Cell>
                        {typeBasedContent.newValue}
                    </Table.Cell>
                </>)
    }

    // Cell renderers based on diff type
    renderStringCell = () => {
        return {existingValue: this.props.existingValues, 
                newValue: this.props.newValues }
    }

    renderHierarchicalObjectsCell = () => {
        return {existingValue: this.props.existingValues, 
                newValue: this.props.newValues }
    }

    renderNumberCell = () => {
        return {existingValue: this.props.existingValues, 
                newValue: this.props.newValues }
    }

    renderElectricalConnection = () => 
    {
        return {existingValue: this.props.existingValues, 
                newValue: this.props.newValues }
    }

    renderBoolCell = () => {
        let mapBoolToIcon = (val) => {
            // eslint-disable-next-line
            let name  = val == "true" ? "check" : "delete"
            // eslint-disable-next-line
            let color = val == "true" ? "green" : "red"
            return <Icon name={name} color={color}/>
        }
        return {existingValue: mapBoolToIcon(this.props.existingValues), 
                newValue: mapBoolToIcon(this.props.newValues) }
    }

    renderVectorCell = () => {
        let existingValues = []    
        let newValues = []                 
        this.props.existingValues.forEach((existingValue, i) => {
            let newValue = this.props.newValues[i]

            if (newValue !== existingValue)
            {
                existingValues.push(<div style={{margin: "1em"}}>{existingValue}</div>)
                newValues.push(<div style={{margin: "1em"}}>{newValue}</div>)
            }
        })

        return {existingValue : existingValues,
                newValue      : newValues,}
    }

    renderDateCell = () => {
        let existingUnixTs = Date.parse(this.props.existingValues)
        let newUnixTs = Date.parse(this.props.newValues)

        let newValue = existingUnixTs > newUnixTs ? LocalizedStrings.AdvancedTo : LocalizedStrings.PostponedTo
        newValue = (<> 
                        {newValue} 
                        <UnitInput  readOnly
                                    value={this.props.newValues}
                                    baseUnit={BASE_UNIT_DATE}/>
                    </>)
        
        let existingValue = (   <UnitInput  readOnly
                                            value={this.props.newValues}
                                            baseUnit={BASE_UNIT_DATE}/>)
        
        return {existingValue : existingValue,
                newValue      : newValue,}
    }

    renderColorCodeCell = () => {
        let existingColorCodeObj = this.state.existingColorCodeObj
        let newColorCodeObj = this.state.newColorCodeObj

        let existingValues = existingColorCodeObj && existingColorCodeObj.Color ? this.getColorCodeDiv(existingColorCodeObj) : "None"
        let newValues = newColorCodeObj && newColorCodeObj.Color ? this.getColorCodeDiv(newColorCodeObj) : "None"

        return {existingValue : existingValues,
                newValue      : newValues,}
    }

    // helper
    getColorCodeDiv = (colorCodeObj) => {
        let hexColor = cie2hex(colorCodeObj.Color)
        return (<Table.Row>
                    <Table.Cell collapsing>
                        <div style={{backgroundColor:`${hexColor}`, width: "1rem", height:"1rem", borderRadius: 90}} />
                    </Table.Cell>
                    <Table.Cell collapsing>
                        {colorCodeObj.Name}
                    </Table.Cell>
                </Table.Row>)

    }

    renderStringList = () => {
        let existingValues = this.props.existingValues.map((val, i) => {
            if (!this.props.newValues.includes(val))
            {
                return <div key={i} style={{backgroundColor: "#FF8787"}}>{val}</div>
            }
            return undefined
        })

        let newValues = this.props.newValues.map((val, i) => {
            if (!this.props.existingValues.includes(val))
            {
                return <div key={i} style={{backgroundColor: "#80FF00"}}>{val}</div>
            }
            return undefined
        })

        return {existingValue : existingValues,
                newValue      : newValues,}
    }
    
    renderNumberList = () => {
        return this.renderStringList()
    }

    renderUUIDObjectCell = () => {
        return this.renderStringCell()
    }
    renderUUIDObjectList = () => {
        return this.renderStringList()
    }

    renderDefaultCell = () => {
        let existingValues = this.props.existingValues.map((val, i) => <div key={i} style={{backgroundColor: "#AAAAAA",margin: "1em"}}>{val}</div>)
        let newValues = this.props.newValues.map((val, i) => <div key={i} style={{backgroundColor: "#AAAAAA",margin: "1em"}}>{val}</div>)

        return {existingValue : existingValues,
                newValue      : newValues,}
    }

} 