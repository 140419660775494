//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Input, Grid, Message } from 'semantic-ui-react';

import LocalizedStrings from "../../../localization/TableViewComponent";
import { BASE_UNIT_DATE, BASE_UNIT_STRING, BASE_UNIT_VOLUME, BASE_UNIT_WEIGHT } from '../../../util/defines';
import UnitInput from '../../Basics/BasicUnitInput';
import DynamicTable, { TABLE_SELECTION_TYPE_Row } from '../../Basics/DynamicTableView';

const NO_ROW_SELECTED = -1

import { globalCallbacks as mainCB } from "../../../util/callback";
import { globalCallbacks as mockCB } from '../../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB


class TruckList extends Component
{
    constructor(prop)
    {
        super(prop);
        this.state = 
        {
            containers: [],
            expandedContainers: [],
            selectedRowIndex: NO_ROW_SELECTED,
        }

        this.setUpCallbacks()
        
    }

    componentDidMount()
    {
        if (globalCallbacks.updateInventoryContainerList) { globalCallbacks.updateInventoryContainerList(); }
    }

    componentDidUpdate(oldProps)
    {
        if (oldProps.rootObject !== this.props.rootObject)
        {
            if (globalCallbacks.updateInventoryContainerList) { globalCallbacks.updateInventoryContainerList(); }
        }
    }

    render()
    {
        //----------------------------------------------------
        // define dynamic table view props

        let headerIdent = [
            {sortable: true,  ident: "Name",            label: LocalizedStrings.Truck,           unit: BASE_UNIT_STRING},
            {sortable: true,  ident: "Volume",           label: LocalizedStrings.Volume,          unit: BASE_UNIT_VOLUME},
            {sortable: true,  ident: "ContainingWeight", label: LocalizedStrings.Weight,          unit: BASE_UNIT_WEIGHT},
            {sortable: true,  ident: "AllowablePayload", label: LocalizedStrings.AllowableWeight, unit: BASE_UNIT_WEIGHT},
            {sortable: true,  ident: "Licence",          label: LocalizedStrings.Licence,         unit: BASE_UNIT_STRING},
            {sortable: true,  ident: "Driver",           label: LocalizedStrings.Driver,          unit: BASE_UNIT_STRING},
            {sortable: true,  ident: "LoadingLocation",  label: LocalizedStrings.LoadingLocation, unit: BASE_UNIT_STRING},
            {sortable: false, ident: "LoadingDateStart", label: LocalizedStrings.LoadingDateStart,unit: undefined},
            {sortable: false, ident: "DeLoadingDate",    label: LocalizedStrings.DeLoadingDate,   unit: undefined},
            {sortable: false, ident: "LoadingDateEnd",   label: LocalizedStrings.LoadingDateEnd,  unit: undefined}
        ]

        const cellRender = (entry, rowIndex) => {
            return {
                Name:               <>{entry.Name}</>,
                Volume:             <UnitInput baseUnit={BASE_UNIT_VOLUME} readOnly label={false} transparent value={entry.Volume} />,
                ContainingWeight:   <UnitInput baseUnit      = {BASE_UNIT_WEIGHT} 
                                                label         = {false} 
                                                transparent 
                                                noStepper
                                                readOnly
                                                value         = {entry.ContainingWeight} />,
                AllowablePayload:   <UnitInput baseUnit      = {BASE_UNIT_WEIGHT} 
                                                label         = {false} 
                                                transparent 
                                                noStepper
                                                value         = {this.state["editAllowablePayload"+entry.UUID] ?? entry.AllowablePayload} 
                                                onStateUpdate = {(name, value) => {this.setState({["editAllowablePayload"+entry.UUID]: value})}}
                                                onUpdateData  = {() => {this.updateData("AllowablePayload", this.state["editAllowablePayload"+entry.UUID], entry.UUID)}}/>,
                Licence:            <Input value={this.state["editLicence"+entry.UUID] ?? entry.Licence} 
                                                onChange={(e, {value}) => {this.setState({["editLicence"+entry.UUID]: value})}} 
                                                onBlur={() => {this.updateData("Licence", this.state["editLicence"+entry.UUID], entry.UUID)}}
                                                transparent/>,
                Driver:             <Input value={this.state["editDriver"+entry.UUID] ?? entry.Driver} 
                                                onChange={(e, {value}) => {this.setState({["editDriver"+entry.UUID]: value})}} 
                                                onBlur={() => {this.updateData("Driver", this.state["editDriver"+entry.UUID], entry.UUID)}}
                                                transparent/>,
                LoadingLocation:    <Input value={this.state["editLoadingLocation"+entry.UUID] ?? entry.LoadingLocation} 
                                                onChange={(e, {value}) => {this.setState({["editLoadingLocation"+entry.UUID]: value})}} 
                                                onBlur={() => {this.updateData("LoadingLocation", this.state["editLoadingLocation"+entry.UUID], entry.UUID)}}
                                                transparent/>,
                LoadingDateStart:    <UnitInput name          = "LoadingDateStart" 
                                                baseUnit      = {BASE_UNIT_DATE} 
                                                isClearable 
                                                value         = {entry.LoadingDateStart} 
                                                onStateUpdate = {(name, value) => {this.updateData(name, value, entry.UUID)}}/>,
                DeLoadingDate:      <UnitInput name          = "LoadingDateEnd" 
                                                baseUnit      = {BASE_UNIT_DATE} 
                                                isClearable 
                                                value         = {entry.LoadingDateEnd} 
                                                onStateUpdate = {(name, value) => {this.updateData(name, value, entry.UUID)}}/>,
                LoadingDateEnd:     <UnitInput name          = "DeLoadingDate" 
                                                baseUnit      = {BASE_UNIT_DATE} 
                                                isClearable 
                                                value         = {entry.DeLoadingDate} 
                                                onStateUpdate = {(name, value) => {this.updateData(name, value, entry.UUID)}}/>,
            };
        };

        // Cell Data and extra cell data
        const cellData = []
        this.state.containers.forEach(container => {
            const ExtraData = [...container.Cases.map(c => ({...c, IsCase: true})), ...container.Objects.map(c => ({...c, IsCase: false}))]
            cellData.push({...container, ExtraData})
        })

        const extraCellRender = (parentEntry, entry, rowIndex) => {
            const volume = entry.IsCase ? entry.CaseSize.X * entry.CaseSize.Y * entry.CaseSize.Z : entry.Volume
            return {
                Truck:  <>{entry.Name}</>,
                Volume: <UnitInput baseUnit={BASE_UNIT_VOLUME} label={false} transparent readOnly value={volume} />,
                Weight: <UnitInput baseUnit={BASE_UNIT_WEIGHT} label={false} transparent readOnly value={entry.Weight} />,
                Licence: <></>,
                Driver: <></>,
                LoadingLocation: <></>,
                LoadingDateStart: <></>,
                DeLoadingDate: <></>,
                LoadingDateEnd: <></>,
            };
        }

        //----------------------------------------------------
        // render
        let onRowClicked = (row) =>
        {
            const index = row.RowIndex
            if (this.props.onTruckChanged && index >= 0)
            {
                let uuid = (this.state.selectedRowIndex === index) ? undefined : this.state.containers[index].UUID
                if (uuid)
                    this.props.onTruckChanged(uuid);
            }
            this.setState({selectedRowIndex: (this.state.selectedRowIndex === index) ? NO_ROW_SELECTED : index})
        }

        return (
            <div>
            {cellData.length > 0 ? (
                <DynamicTable
                headerIdent={headerIdent}
                cellData={cellData}
                cellRender={cellRender}
                extraCellRender={extraCellRender}
                singleRowSelectionChange={onRowClicked}
                collapsExtraData
                selectionType={TABLE_SELECTION_TYPE_Row}
                pages={this.props.pages}
                showPages={this.props.showPages}
                printMargin={this.props.printMargin}
                fitColumnWidthToContent={this.props.showPages}
                />
            ) : (
                <Message warning style={{ alignItem: "center" }}>
                    <Grid>
                        <Grid.Column floated="left" width="5">
                        <h3 style={{ color: "darkred" }}>{"no items"}</h3>
                        </Grid.Column>
                    </Grid>
                </Message>
            )}
            </div>
        );
    }

    updateData = (name, value, UUID) => {
        console.log("update ", name, value, UUID)
        let request = 
        {
            UUID  :  UUID,
            [name]: value, 
        }
        window.LR_SetObject(request)
    }

    setUpCallbacks()
    {
        globalCallbacks.updateInventoryContainerList = async () =>
        {
            let ret        = await window.LR_GetTrucks();
            let containers = ret.InventoryContainers;
            
            for (const container of containers)
            {
                let content = await window.LR_GetTruckObjects({UUID: container.UUID, Async: true})
                console.log("Container " + container.Name + " content: ", content)
                container.Cases = content.Cases
                container.Objects = content.Objects
                container.ContainingWeight = content.ContainingWeight
            }

            console.log("Setting trucks",containers)


            this.setState({containers});
        }
    }
}

export default TruckList;