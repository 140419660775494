//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Loader, Header, List, Container, Button, Icon, Input, Segment, Image, Tab } from 'semantic-ui-react'
import UserAvatar from '../component/WebComponents/UserAvatar';
import LocalizedStrings from "../localization/ProjectOverviewMobile";
import logo from "../content/img/applogo_white.png";
import settings from "../content/img/settings.png";
import Calendar from '../component/Calendar/Calendar';
import ProjectFolder from '../component/ProjectFolder/ProjectFolder';


class AppProjectOverview extends Component 
{
    constructor(props)
    {
      super(props);
      this.state = {
          Projects: [],
          User: undefined,
          SearchFilter: ""
      }
    }

    componentDidMount = async () =>
    {        
        let res1     = await window.LR_GetLoggedInUser()
        let projects = await window.LR_GetProjectsAsync()

        let sorted = projects.sort((a,b)=> Date.parse(b.lastChanged)-Date.parse(a.lastChanged))
        this.setState({User: res1.User, Projects: projects, SortedProjectData: sorted.slice(0,12)})

        
    }

    render()
    {
        if(!this.state.User)
        {
            return<Loader active/>
        }

        let projects = this.state.Projects.filter(this.searchFilter)

        const panes = [
            {
                menuItem: LocalizedStrings.Recent,
                render: () => <Tab.Pane attached={false}>
                            <Input value   = {this.state.SearchFilter}
                                    onChange = {(e, {value}) => this.setState({SearchFilter: value})}
                                    placeholder={LocalizedStrings.Search}
                                    fluid/>
                                    <div style={{overflowX:"hidden", height: "69h", marginTop:"1em"}}>

                                        <List divided verticalAlign='middle' >
                                            {this.state.SortedProjectData.filter(this.searchFilter).map(p => this.renderProject(p))}
                                            {projects.length===0 ? this.NoProjectsFound():null}
                                        </List>
                                    </div>


                </Tab.Pane>,
              },
            {
              menuItem: LocalizedStrings.List,
              render: () => <Tab.Pane attached={false}>
                                <Input value   = {this.state.SearchFilter}
                                    onChange = {(e, {value}) => this.setState({SearchFilter: value})}
                                    placeholder={LocalizedStrings.Search}
                                    fluid/>
                                    <div style={{overflowX:"hidden", height: "69h", marginTop:"1em"}}>

                                        <List divided verticalAlign='middle' >
                                            {projects.filter(this.searchFilter).map(p => this.renderProject(p))}
                                            {projects.length===0 ? this.NoProjectsFound():null}
                                        </List>
                                    </div>
                            </Tab.Pane>,
            },
            {
              menuItem: LocalizedStrings.Calender,
              render: () => <Tab.Pane attached={false}><Calendar/></Tab.Pane>,
            },
            {
                menuItem: LocalizedStrings.Folder,
                render: () => <Tab.Pane attached={false}><ProjectFolder /></Tab.Pane>,
              },
            
          ]

        return(
            <>
            <Container>
            <Header as="h1">
                <UserAvatar user={this.state.User}/>{this.state.User}          
                <Image floated="right" src={logo} alt="production assistant logo" size='tiny' onClick={()=>{window.LR_SetOnlineConfig()}}/>
                <Image floated="right" src={settings} alt="production assistant logo" size='tiny' onClick={()=>{window.LR_RunOpenSettings()}}/>
            </Header>
            <Tab menu={{ secondary: true }} panes={panes} />
            </Container>
            </>
        )
    }
    NoProjectsFound = () =>
    {
      return(
        <Segment placeholder>
        <Header icon>
          <Icon name='search' />
          {LocalizedStrings.NoProjectFound}
        </Header>
      </Segment>
      )
    }

    searchFilter = (project) =>
    {
        let s = this.state.SearchFilter.toLocaleLowerCase()
        if(s === "") { return true}
        else
        {
            let text = project.owner.username+project.name

            return text.toLocaleLowerCase().includes(s)
        }
    }

    renderProject (project)
    {
        let key = project.owner.username+project.name
        return( 
        <>
        <List.Item key={key}>
            <List.Content floated='right'>
            <Button primary onClick={()=>this.openInApp(project, "master")}>{LocalizedStrings.Open}</Button>
            </List.Content>
            <List.Content size="10">
                <b>
                    <Icon 
                        disabled={project.branches.length === 0} 
                        onClick={()=>{this.setState({[key]: !this.state[key]})}} 
                        name={this.state[key] ? "angle down" : "angle right"}/>
                    {`${project.owner.username}/${project.name}`}
                </b>
            </List.Content>
        </List.Item>
        {this.state[key] ? project.branches.map(b=>this.renderBranch(project, b)) : null}
        </>
        )
    }


    renderBranch =(project, branch)=>
    {
        return(
        <List.Item key={project.owner.username+project.name+branch._id}>
            <List.Content floated='right'>
            <Button secondary onClick={()=>this.openInApp(project, branch._id)}>{LocalizedStrings.Open}</Button>
            </List.Content>
            <List.Content size="10">
                <Header as="h5">{`${branch.name}`}</Header>
            </List.Content>
        </List.Item>
        )
    }


    openInApp=(project, branch)=>
    {
        window.LR_CheckoutProject(
        {
            Project:project.name,
            User:project.owner.username,
            Branch:branch,
        })
        this.props.changeComponent("tasks")
    }

   
}


export default AppProjectOverview