//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component, } from 'react';
import { connect } from 'react-redux';
import { PROJECTS } from '../../redux/redux_defines'

import { Table, Grid, Header, Button, Segment } from 'semantic-ui-react';

class MentenainceOverview extends Component 
{
  render()
  {
    return(<>
   <Grid>
     <Grid.Column width="6">
     <Header>Report Templates</Header>
     <Button positive compact>Add...</Button>
      <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Duration</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
        {this.renderRow()}
      </Table.Body>
      </Table>
     </Grid.Column>
     <Grid.Column width="10">
     <Header>Report Details</Header>
       <Segment>
         <Header>General</Header>
         <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Field</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                Name 
              </Table.Cell>
              <Table.Cell>
                [Object.Info]
              </Table.Cell>
            </Table.Row>
          </Table.Body>
         </Table>
       </Segment>
       <Segment>
         <Header>Part 1</Header>
         {this.renderCheckTable()}
       </Segment>
       <Segment>
         <Header>Part 2</Header>
         {this.renderCheckTable()}
       </Segment>
     </Grid.Column>
   </Grid>
    </>)

  }

  renderRow = () =>
  {
    return (
      <>
      <Table.Row>
        <Table.Cell>
          UVV Prüfung 
        </Table.Cell>
        <Table.Cell>
          20min
        </Table.Cell>
      </Table.Row>
      </>
    )
  }


  renderCheckTable = () =>
  {
    return(
      <>
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Number</Table.HeaderCell>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Description</Table.HeaderCell>
          <Table.HeaderCell>Time</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            1.1 
          </Table.Cell>
          <Table.Cell>
            Gesamtzustand
          </Table.Cell>
          <Table.Cell>
            
          </Table.Cell>
          <Table.Cell>
            2min
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            1.2 
          </Table.Cell>
          <Table.Cell>
            Gehäuse Technik
          </Table.Cell>
          <Table.Cell>
            
          </Table.Cell>
          <Table.Cell>
            2min
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
    <Button positive compact>Add...</Button>
    </>)
  }

}

//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      projects: (state[PROJECTS])
    };
}

export default connect(mapStateToProps)(MentenainceOverview)
