//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { EDIT_MODE } from '../redux/redux_defines';
import { connect } from 'react-redux';
import { ipcRenderer } from './ipcRenderer'
import { lrServerConnection } from '../redux/light_right_server_connection'
//---------------------------------------------------------------------
// LightRightRouter
class CallBackWrapper extends Component 
{  
  componentDidMount ()
  {
    if(lrServerConnection.__SOCKET) {
      lrServerConnection.__SOCKET.on('callback', socketCallback)
    }
  }
  
  render ()
  {
    return(
      <React.Fragment>
          {this.props.children}
      </React.Fragment>
    )
  }
}

const socketCallback = (data) => {
  ipcRenderer.runCallBack('OnLightRightCoreCallback', undefined, data)
}

const mapStateToProps = (state) => ({
  editMode: state[EDIT_MODE].data
})

export default connect(mapStateToProps)(CallBackWrapper);
