import Konva from "konva";
import { uuidStr } from "../../../../util/callbackTypes";
import { TEXTURE_TYPE } from "../../../../util/defines";
import workerSrc from 'pdfjs-dist/legacy/build/pdf.worker.js'
import LocalizedStrings from '../../../../localization/Renderer';

// code splitting
async function getPdfJs(){
    let pdfjs = await import("pdfjs-dist/legacy/build/pdf.js")
    pdfjs.GlobalWorkerOptions.workerSrc = workerSrc
    return pdfjs
}

// This is a bit hacky: by using a WeakRef, we ensure that only objects that are still in memory are used again
// -> no memory leak from creating/deleting many textures
let textureCache = new Map<uuidStr, WeakRef<HTMLImageElement>>()
export async function renderTextureToImage(texture: { ImageFormat: TEXTURE_TYPE, TextureBuffer: string, UUID?: uuidStr}){
    let cacheObj = texture.UUID ? textureCache.get(texture.UUID) : undefined
    if(cacheObj){
        let img = cacheObj.deref()
        if(img){
            return img
        }
    }

    let outImg = new Image();

    let pr = new Promise(t => outImg.onload = t)

    //without a timeout, this function could never return
    let timeout = new Promise((res, rej) => setTimeout(rej, 5000))

    let Base64ToArrayBuffer = (base64: string) => {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes;
    }

    let Base64ToString = (base64: string) => {
        return window.atob(base64);
    }

    if (texture.ImageFormat === TEXTURE_TYPE.SVG) {
        let svg = Base64ToString(texture.TextureBuffer)
        const blob = new Blob([svg], { type: 'image/svg+xml' });
        outImg.src = URL.createObjectURL(blob);
    }
    else if (texture.ImageFormat === TEXTURE_TYPE.PNG) {
        const blob = new Blob([Base64ToArrayBuffer(texture.TextureBuffer)], { type: 'image/png' });
        outImg.src = URL.createObjectURL(blob);
    }
    else if (texture.ImageFormat === TEXTURE_TYPE.JPEG) {
        const blob = new Blob([Base64ToArrayBuffer(texture.TextureBuffer)], { type: 'image/jpg' });
        outImg.src = URL.createObjectURL(blob);
    }
    else {
        let pdf = await (await getPdfJs()).getDocument(Base64ToArrayBuffer(texture.TextureBuffer)).promise
        let page = await pdf.getPage(1)

        var scale = 1.5;
        var viewport = page.getViewport({ scale: scale });

        // Prepare canvas using PDF page dimensions
        let canvas = document.createElement('canvas');
        var context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        // Render PDF page into canvas context
        var renderContext = {
            canvasContext: context,
            viewport: viewport
        };

        await page.render(renderContext).promise;
        outImg.src = canvas.toDataURL()
    }

    try {
        await Promise.race([pr, timeout])
        if(texture.UUID){
            textureCache.set(texture.UUID, new WeakRef(outImg))
        }
        return outImg
    } catch {
        console.error("timeout on texture loading. Has the texture the correct format? Returning empty Image")
        return outImg
    }
}


export async function renderTextureToKonva(texture?: { ImageFormat: TEXTURE_TYPE, TextureBuffer: string, UUID: uuidStr}): Promise<Konva.Group | Konva.Shape> {
    if(texture){
        let i = await renderTextureToImage(texture)
        return new Konva.Image({ image: i, scaleY: -0.1, scaleX: 0.1 })
    }else{
        let gr = new Konva.Group()
        gr.add(new Konva.Rect({
            x: 0,
            y: 0,
            width: 100,
            height: 100,
            scaleY: -1,
            strokeWidth: 3,
            stroke: "black"
        }))
        gr.add(new Konva.Text({
            x: 0,
            y: 0,
            width: 100,
            height: 100,
            text: LocalizedStrings.NoImageSelected,
            scaleY: -1,
            fontSize: 15,
            align: "center",
            verticalAlign: "middle"
        }))
        return gr
    }
}