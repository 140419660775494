//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loader, Segment, Button, Message, Icon, Divider } from 'semantic-ui-react';
import { hasFetched, FetchProject, FetchActiveChange } from '../../redux/actions/fetch_actions';
import { ACTIVE_PROJECT, ACTIVE_CHANGE } from "../../redux/redux_defines"
import { lrServerConnection } from '../../redux/light_right_server_connection';
import LRModal from '../Basics/BasicModal';
import DiffTable from '../DiffView/DiffTable';
import DiffRenderer from '../Renderer/DiffRenderer';
import LocalizedStrings from '../../localization/CommitOverview'
import { addNotification } from '../NotificationDisplay/NotificationDisplay';
import DiffSummary from '../DiffView/DiffSummary';
import { withRouter } from '../../webApp/WebRouter';


class CommitOverview extends Component 
{
  constructor() {
    super()
    this.state = 
    {
      searchString: '',
      modal3d: false,
      modalOn: null,
      diff: {},
      ShowDiffs: {},
      CachedDiffs: {},
      loadingDiff: false,
      changeNumber: 0
    }
  }
  componentDidMount = async () =>
  { 
    let branchWasSet = false
    if(this.props.match && this.props.match.params)
    {
      let branch       = this.props.match.params.branch
      let ChangeNumber = this.props.match.params.changenumber
      if(ChangeNumber) { 
        lrServerConnection.setFile(undefined, undefined,ChangeNumber) 
        this.setState({changeNumber: Number(ChangeNumber)})
        branchWasSet = true
      }
      else if(branch)  { 
        lrServerConnection.setFile(undefined, branch,undefined)
        branchWasSet = true
      }
      else             { lrServerConnection.setFile(undefined, undefined, undefined)}
    }
    else if(this.props.base && this.props.target)
    {
      this.setState({loadingDiff: true})
      let diff = await lrServerConnection.getMergeCompare(this.props.base, this.props.target)
      this.setState({diff: diff, loadingDiff: false})
    }
    else              
    { 
      lrServerConnection.setFile(undefined, undefined, undefined)
    }

    FetchActiveChange(branchWasSet);
    FetchProject()
  }

  render() 
  {
    let commits = []
    if(this.props.base && this.props.target)
    {
      if(this.state.loadingDiff) return <Segment placeholder><Loader active/> </Segment>
      return this.renderDiff(this.state.diff)
    }
    else
    {
      // Web App
      if(!hasFetched(this.props.changes) || !hasFetched(this.props.activeProject)) return <Loader active/>
      commits = this.getRelevantChanges();
      
      if(this.state.changeNumber) 
      {
        let includedFiles = this.props.activeProject.data.branches.find(br => br.number === this.state.changeNumber)
        
        if(includedFiles === undefined) includedFiles = []
        else                            includedFiles = includedFiles.files
        
        commits = commits.filter(com => includedFiles.includes(com.id))
      }
    }
    
    return(
      <>
      {commits.map((e,i) => this.renderCommit(e, i, commits))}
      {commits.length === 0 ? this.renderNoCommits() : null}
      </>
    )
  }

  renderNoCommits = () =>
  {
    return(
      <Message>
        <Message.Header>
          {LocalizedStrings.NoCommitsHeader}
        </Message.Header>
        {LocalizedStrings.NoCommitsText}
      </Message>
    )
  }


  getRelevantChanges = () => 
  {
    let data = [...this.props.changes.data] 
    data.sort((a, b) => Date.parse(b.date)-Date.parse(a.date))
    return data
  }


  loadChangesForCommit =(commitObj) =>
  {

  }

  toogleShowCommits = async (commitObj, oldState) =>
  {
    this.setState({ShowDiffs: {...this.state.ShowDiffs, [commitObj._id]: !oldState}})

    await this.fetchDiff(commitObj)
  }

  fetchDiff = async (commitObj) => {
    let id_diff = commitObj.id
    if(this.state.CachedDiffs[id_diff] === undefined)
    {
      this.setState({CachedDiffs: {...this.state.CachedDiffs, 
        [id_diff]: {
          loading: true, 
          loaded:false,
          diff:{}
        }}})
  
      let res = await lrServerConnection.getDiffsForCommit(id_diff)
      this.setState({CachedDiffs: {...this.state.CachedDiffs, 
        [id_diff]: {
          loading: false, 
          loaded:true,
          diff: res
        }}})
    }
  }

  renderCommit = (commit, i, comms) => 
  {
    let showDiffs = this.state.ShowDiffs[commit._id]

    let cached_diff = this.state.CachedDiffs[commit.id]

    if(showDiffs === undefined) { showDiffs = false}

    return (<Segment attached key={commit._id}>
        <Button floated='right' onClick={() => {this.open3d(commit._id); this.fetchDiff(commit) }}>{LocalizedStrings.ViewDifferencesIn3D}</Button> 
        {(i===0 && comms.length > 1) ? <Button 
        negative 
        floated='right' 
        onClick={this.rollBackCommit}
        >
          <Icon name="delete"/>{LocalizedStrings.DeleteCommit}</Button> : null }
        {new Date(commit.date).toLocaleString()}{commit.message ? " - " + commit.message : null} 
        <br/>
        <Icon name={showDiffs ? "angle down" : "angle right"} onClick={()=>this.toogleShowCommits(commit, showDiffs)}/>
        <i>{LocalizedStrings.ChangedBy}</i> <b>{commit.modifiedBy.username}</b>
        {showDiffs ? this.renderDiffFiltered(commit) : null}
        <LRModal  open={this.state.modal3d && this.state.modalOn === commit._id}
                  noCancel
                  onOkClick={this.close3d}
                  title={LocalizedStrings.ViewDifferencesIn3D}>
          {this.state.modal3d && this.state.modalOn === commit._id && cached_diff && !cached_diff.loading ? <DiffRenderer file={commit._id} prevFile={comms.length > i+1 ? comms[i+1]._id : undefined} differences={cached_diff.diff} height={600}/> : null}
        </LRModal>
    </Segment>)
  }

  rollBackCommit = async () =>
  {
    if(window.confirm(LocalizedStrings.AreYouSure))
    {

      let params = this.props.match.params
      let res = await lrServerConnection.deleteLastCommitFromBranch(params.branch ?? params.changenumber ?? "master")
      if(res.ok)
      {
        FetchActiveChange(true);
      }
      else if(res.status === 403)
      {
        addNotification(LocalizedStrings.FailedToDelete,LocalizedStrings.FailedToDeleteNotAllowed, false, true)
      }
      else if(res.status === 404)
      {
        addNotification(LocalizedStrings.FailedToDelete,LocalizedStrings.FailedToDeleteTextNotFound, false, true)
      }
      else if(res.status === 304)
      {
        addNotification(LocalizedStrings.FailedToDelete,LocalizedStrings.FailedToDeleteTextLastCommit, false, true)
      }
    }
  }


  renderDiffFiltered = (commit) =>
  {
    let cached_diff = this.state.CachedDiffs[commit.id]

    if(cached_diff)
    {
      if( ! cached_diff.loading)
      {
        return <><Divider/> {this.renderDiff(cached_diff.diff)}</>
      }
    }

    return <><Loader loading="true" /> </>

    
  }

  renderDiff = (diff) => 
  {
    return(
      <>
      <DiffSummary diff={diff}/>
      <div style={{paddingTop: "15px"}}/>
      <DiffTable
        differences={diff}
        onlyDisplay
        merge={this.props.merge}
        commitOverview={this.props.commitOverview}
        noRender/>
      </>
    )
  }

  open3d = (comId) => {
    this.setState({
      modal3d: true,
      modalOn: comId
    })
  }

  close3d = () => {
    this.setState({
      modal3d: false,
      modalOn: null
    })
  }

  onChange = (_ ,{value}) => this.setState({searchString: value})
}

//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      changes: (state[ACTIVE_CHANGE]),
      activeProject: state[ACTIVE_PROJECT]
    };
}

export default connect(mapStateToProps)(withRouter(CommitOverview))

