import React, {Component} from 'react'
import CONST from '../../webApp/CONST';
const Nav = React.lazy(()=>import("../ProductionAssistLandingPage/LandingPageNav"));
const ReactMarkdown = React.lazy(()=>import("react-markdown"))
import MDE from "react-mde"
import { Loader, Segment, Button, Input } from 'semantic-ui-react';
import "./blog.css"
import { lrServerConnection } from '../../redux/light_right_server_connection';
import UnitInput from "../Basics/BasicUnitInput";
import { BASE_UNIT_DATE } from "../../util/defines";
import {connect} from "react-redux"
import { ACTIVE_USER } from '../../redux/redux_defines';
import LRModal from '../Basics/BasicModal';
import { withRouter } from '../../webApp/WebRouter';

class BlogEntry extends Component {
  constructor(props) {
    super(props)
    this.state = {
      entry: null,
      edit: null
    }
    this.fileChooserRef = React.createRef();
    this.getEntry(props.name)
  }

  getEntry = (name) => 
    fetch(CONST.BASE_URL + "/api/blog/" + name)
      .then((str) => str.json())
      .then((obj) => this.setState({entry: obj}))

  render() {
    if(!this.state.entry) return <Segment><Loader/></Segment>

    const editContent = this.state.edit
    const content = editContent  ? editContent.text : this.state.entry.content

    return <Segment className='blog-content' style={{color: "black"}}>
      <ReactMarkdown children={content}/>
      {editContent ? <>
        <MDE 
          value={content} 
          selectedTab={this.state.selectedTab}
          onTabChange={(data) => this.setState({selectedTab: data})}
          generateMarkdownPreview={(markdown) => Promise.resolve(<ReactMarkdown children={markdown} />) }
          onChange={this.editChange}
          />
        <ul>
          {this.state.entry.images.map((img, i) => 
            <li key={i} onClick={() => { this.editChange(content + "\n\n![" + img.name + "](" + CONST.BASE_URL + "/api/blog/" + this.props.name + "/image/" + img.ref + ")") }}>
              Add <Button compact>{img.name}</Button>
            </li>
          )}
        </ul>
        <br/>
        <Button onClick={this.openFileChooser}>Upload Picture</Button>
        <br/>
        Publish Date
        <UnitInput
                baseUnit={BASE_UNIT_DATE}
                name="DueDate"
                value={this.state.edit.publish}
                onStateUpdate={this.editPublish}
              />
        <input accept="image/*" type="file" ref={this.fileChooserRef} style={{display: "none"}} onChange={this.readFile}/>
        <br/>
        <Button.Group>
        <Button positive onClick={this.apply}>Apply</Button>
        <Button negative onClick={this.cancel}>Cancel</Button>
        <Button negative onClick={this.delete}>Delete</Button>
        </Button.Group>
      </> : (this.props.isAdmin ? <Button onClick={this.openEdit}>Edit</Button> : null)}
    </Segment>
  }

  openEdit = () => this.setState({edit: {
    text: this.state.entry.content,
    publish: this.state.entry.publish
  }})

  cancel = () => {
    this.setState({edit: null})
  }

  delete = () => {
    lrServerConnection.deleteBlog(this.props.name).then(() => {
      this.cancel()
      this.props.update()
    })
  }

  apply = () => {
    lrServerConnection.updateBlog(this.state.entry.name, this.state.edit.text, this.state.edit.publish).then(() => {
      this.cancel()
      this.getEntry(this.props.name)
    })
  }

  editChange = (value) => {
    this.setState({edit: {...this.state.edit, text: value}})
  }

  editPublish = (_, value) => { 
    this.setState({edit: {...this.state.edit, publish: value}})
  }

  readFile = (e) => {
    let files = e.target.files;
    let fileToUpload = files[0]
    
    lrServerConnection.uploadBlogImg(this.props.name, fileToUpload)
    .then(() => this.getEntry(this.props.name))
  }

  openFileChooser = (e) => 
  {
    // the default input-field is ugly, so we hide it and call it through an other button
    let hiddenFileChooser = this.fileChooserRef.current;
    hiddenFileChooser.click();
  }
}

class Blog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      entries: null,
      new: null
    }
    this.fetchEntries()
  }

  fetchEntries = () => {
    const prom = this.props.user.hasFetched && this.props.user.data.isAdmin 
      ? lrServerConnection.getBlogAdmin.bind(lrServerConnection)
      : lrServerConnection.getBlog.bind(lrServerConnection)
    prom()
      .then((obj) => this.setState({entries: obj}))
  }
  componentDidUpdate(prevProps)
  {
    if(prevProps.user !== this.props.user){
      this.fetchEntries()
    }
  }
  render() 
  {
    if(!this.state.entries)
      return <Segment><Loader/></Segment>

    const isAdmin = this.props.user.hasFetched && this.props.user.data.isAdmin

    let content
    if(this.props.match.params.page) {
      content = <BlogEntry key={this.props.match.params.page} name={this.props.match.params.page} isAdmin={isAdmin} update={this.fetchEntries}/>
    } else {
      content = this.state.entries.map((entry) => <BlogEntry key={entry.name} name={entry.name} isAdmin={isAdmin} update={this.fetchEntries}/>)
    }

    return (
      <div className="blog">
        <header>
          <Nav />
          <div>
          <h1>Blog</h1>
          </div>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0, 0, 100, 38.5896"
          >
            <path
              width="100%"
              height="100%"
              d="M 100 38.5896 
              L 0 38.5896 
              L 78.9959 24.6768 
              C 91.3502 22.3542 100 12.1998 100 0 
              L 100 0 
              z"
            />
          </svg>
        </header>
        <div>
          
          {isAdmin ? <Button onClick={this.addEntry}>Add entry</Button> : null}
          <LRModal open={this.state.new !== null} onCancelClick={this.cancelEntry} onOkClick={this.okEntry}>
            <Input onChange={this.changeEntry} value={this.state.new}/>
          </LRModal>
          {content}
        </div>
      </div>
    )
  }

  changeEntry = (_,{value}) => this.setState({new: value})

  addEntry = () => this.setState({new: "new-entry"})

  okEntry = () => {
    lrServerConnection.createBlog(this.state.new)
      .then(this.cancelEntry)
      .then(this.fetchEntries)
  }

  cancelEntry = () => this.setState({new: null})
}

const mapStateToProps = (state) => 
{
    return {
      user: (state[ACTIVE_USER]),
    };
}

export default connect(mapStateToProps)(withRouter(Blog))