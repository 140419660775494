//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2019 www.deersoft.de
//-----------------------------------------------------------------------------
import LocalizedStrings from 'localized-strings';
import LocalizeOption from './LocalizationOption';
 
import en from "./raw/en/UserTaskPage.json"
import de from "./raw/de/UserTaskPage.json"
import es from "./raw/es/UserTaskPage.json"
import fr from "./raw/fr/UserTaskPage.json"
import it from "./raw/it/UserTaskPage.json"
import ru from "./raw/ru/UserTaskPage.json"
import cz from "./raw/cs/UserTaskPage.json"
 
let Strings = new LocalizedStrings(
{
  en: en,
  de: de,
  es: es,
fr: fr,
ru: ru,
it: it,
cz: cz,
},
  { ...LocalizeOption }
);

export default  Strings;