//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import LocalizedStrings from "../../localization/SaveTimerModal";
import LRModal from '../Basics/BasicModal';
import {Checkbox} from'semantic-ui-react';
import { IsUsingWebassembly } from '../../webApp/ipcRenderer';

import { globalCallbacks } from "../../util/callback";


//-----------------------------------------------------------------------------
// The component for the TreeControl
class SaveTimerModal extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
      DisableTimer: false,
    }
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }

  show = async() => 
  {
    this.setState( { 
        open : true, 
        DisableTimer: false,
      });
  }

  close = () => 
  {
    if(this.state.DisableTimer){
      window.LR_DisableAutosaveTimer({DisableAutosaveTimer: this.state.DisableTimer})
    }
    this.setState({open : false});
  }

  ok = () => 
  {
    window.LR_SaveFile()
    this.close()
  }
    
  render() 
  {
    let open = this.state.open;
    if(!open) {return <div/>}

    return (
      <LRModal  open={open} 
                onCancelClick={this.close} 
                size="small"
                customOKText={LocalizedStrings.Save}
                title={LocalizedStrings.Header}
                onOkClick={this.ok}
                additionalActions={<Checkbox checked={this.state.DisableTimer} onChange={()=>{this.setState({DisableTimer: !this.state.DisableTimer})}} label={LocalizedStrings.DontUseTimer}/>}
                >
        {LocalizedStrings.Text}
          
      </LRModal>
    )
  }

  setUpCallbacks()
  {

    globalCallbacks.ShowAutoSaveDialog = () => 
    { 
      if(!IsUsingWebassembly || window.location.href.includes("editview"))
      {
        this.show();
      }
    }
    
  }
}

export default SaveTimerModal