import { 
    TOOL_MODE_Move, 
    TOOL_MODE_Rotate, 
    SELECT_Single,
    SELECT_SameName,
    SELECT_Box, 
    SELECT_Group_Inside,
    SELECT_Group_Outside,
    TOOL_MODE_Camera,
    IsElectronContext
}  from "../../util/defines";
import { globalCallbacks } from "../../util/callback";

export function SendToCore(event, copyCommand = false)
{
  let editedObjectPositionTab = document.getElementById("editedObjectPositionTab"); 

  if(event?.target?.tagName)
  {
    if(copyCommand && event.target.tagName.toUpperCase() === 'P') { return false }
    if(copyCommand && event.target.tagName.toUpperCase() === 'H1') { return false }
    if(copyCommand && event.target.tagName.toUpperCase() === 'H2') { return false }
    if(copyCommand && event.target.tagName.toUpperCase() === 'H3') { return false }
    if(copyCommand && event.target.tagName.toUpperCase() === 'H4') { return false }
    if(copyCommand && event.target.tagName.toUpperCase() === 'H5') { return false }
    if(copyCommand && event.target.tagName.toUpperCase() === 'TD') { return false }
    if(event.target.tagName.toUpperCase() === 'INPUT') { return false }
    if(event.target.tagName.toUpperCase() === 'TEXTAREA') { return false }
    if(editedObjectPositionTab && editedObjectPositionTab.tagName.toUpperCase() === 'SPAN') { return false }
  }
  


  return true
}

export function formatKey(e)
{
  if (!e || !e.code) {
    console.error("Invalid event:", e);
    return "";
  }

  if(e.code.startsWith("Key"))
  {
    return e.key ? e.key.toUpperCase() : "";
  }
  if(e.code.startsWith("Digit"))
  {
    return e.code.replace("Digit","").toUpperCase()
  }
  if(e.code.startsWith("Arrow"))
  {
    return e.code.replace("Arrow","")
  }
  if(e.code.startsWith("Numpad"))
  {
    return e.code.replace("Numpad","num")
  }
  if(e.code === "Escape")
  {
    return e.code
  }
  if(e.code === "Delete")
  {
    return e.code
  }
  if(e.code.startsWith("F"))
  {
    return e.code
  }
  if(e.code === "Enter")
  {
    return e.code
  }
  return e.key ? e.key.toUpperCase() : "";
}

export function formatShortCut(event)
  {
    let key = formatKey(event)
    let text = ""
    if(navigator.platform === "MacIntel" ? event.metaKey : event.ctrlKey) text += "CommandOrControl+"
    if(event.shiftKey) text += "Shift+"
    if(event.altKey) text += "Alt+"
    if(key) text += key

    return text
  }

function CallEvent(name, event)
{
    let sc = window.LR_SHORTCUTS[name]
    if(sc && sc.Key=== formatShortCut(event))
    {
        return true
    }
    return false
}

window.addEventListener('paste', (event) => 
{
    if(SendToCore(event, true)){ window.LR_PasteObject() ; StopKeyEvent(event)}
});

window.addEventListener('copy', (event) => 
{
    if(SendToCore(event, true)){ window.LR_CopyObject() ; StopKeyEvent(event)}
});

window.addEventListener('cut', (event) => 
{
    if(SendToCore(event, true)){ window.LR_CutObject() ; StopKeyEvent(event)}
});

window.addEventListener('keydown', (event) => 
{
    if(globalCallbacks.RECORDING_KEY_PRESS) {return}
    if(event.keyCode === 65/*a*/ && (navigator.platform === "MacIntel" ? event.metaKey : event.ctrlKey)) 
    { 
        if (SendToCore(event))
        {
            window.LR_SelectAll({Value:true}); 
        }
        else
        {
          if(event.target && event.target.select)
          {
            event.target.select()
          }
            
        }
        StopKeyEvent(event)
    }

    if(!SendToCore(event)) { return }

    // Virtual Calls
    let toolChanged = false

    if(CallEvent("LR_V_Camera", event))                { if(globalCallbacks.HandleToolsChange)   { globalCallbacks.HandleToolsChange(TOOL_MODE_Camera); ; StopKeyEvent(event)} }
    if(CallEvent("LR_V_SelectBox", event))             { if(globalCallbacks.HandleToolsChange)   { toolChanged = true ; globalCallbacks.HandleSelectToolsChange(SELECT_Box);; StopKeyEvent(event) } }
    if(CallEvent("LR_V_SelectToolSingle", event))      { if(globalCallbacks.HandleToolsChange)   { toolChanged = true ; globalCallbacks.HandleSelectToolsChange(SELECT_Single); ; StopKeyEvent(event)} }
    if(CallEvent("LR_V_SelectToolSameNameI", event))   { if(globalCallbacks.HandleToolsChange)   { toolChanged = true ; globalCallbacks.HandleSelectToolsChange(SELECT_SameName, SELECT_Group_Inside);; StopKeyEvent(event) } }
    if(CallEvent("LR_V_SelectToolSameNameO", event))   { if(globalCallbacks.HandleToolsChange)   { toolChanged = true ; globalCallbacks.HandleSelectToolsChange(SELECT_SameName, SELECT_Group_Outside);; StopKeyEvent(event) } }
    if(CallEvent("LR_V_TransformTool", event))         { if(globalCallbacks.UpdateTransformTool) { toolChanged = true ; globalCallbacks.UpdateTransformTool(TOOL_MODE_Move);; StopKeyEvent(event) } }
    if(CallEvent("LR_V_RotateTool", event))            { if(globalCallbacks.UpdateTransformTool) { toolChanged = true ; globalCallbacks.UpdateTransformTool(TOOL_MODE_Rotate);; StopKeyEvent(event) } }
    if(CallEvent("LR_V_CancelActiveTool", event))      { if(globalCallbacks.cancelActiveTool)    { globalCallbacks.cancelActiveTool(); ; StopKeyEvent(event)} }
    if(CallEvent("LR_V_CompleteActiveTool", event))    { if(globalCallbacks.completeActiveTool)  { globalCallbacks.completeActiveTool(); ; StopKeyEvent(event)} }
  
    if(toolChanged){
      globalCallbacks.HandleToolsChange(globalCallbacks.toolMode, globalCallbacks.selectionMode)
    }

    // Core Calls, All with Command or Control will be handeled by electron, other by us
    let keybinding = formatShortCut(event)
    if(IsElectronContext() && keybinding.startsWith("CommandOrControl"))
    {
        return
    }

    let command = window.SHORT_CUTS_JUMPER[keybinding]

    if(command)
    {
        window[command.cmd](command.arg); StopKeyEvent(event)
        
    }

    if (event.shiftKey && event.metaKey === false )
    {
        if (globalCallbacks.setTransformSnapping) { globalCallbacks.setTransformSnapping(true); StopKeyEvent(event) }
    }
    else if (event.ctrlKey && event.metaKey === false )
    {
      if (globalCallbacks.setTransformObjectSnapping) { globalCallbacks.setTransformObjectSnapping(false); StopKeyEvent(event) }
    }
});

window.addEventListener('keyup', (event) => 
{
    if(globalCallbacks.RECORDING_KEY_PRESS) {return}
    if (event.shiftKey && event.metaKey === false )
    {
        if (globalCallbacks.setTransformSnapping) { globalCallbacks.setTransformSnapping(false); StopKeyEvent(event) }
    }
    else if (event.ctrlKey && event.metaKey === false )
    {
      if (globalCallbacks.setTransformObjectSnapping) { globalCallbacks.setTransformObjectSnapping(true); StopKeyEvent(event) }
    }
});


function StopKeyEvent (e)
{
  if (!e) return; 
  if(e.ctrlKey && e.shiftKey && e.key === "I"){
    return;
  }
  e.preventDefault()
  if(!IsElectronContext()){
    e.stopPropagation()
  }
}