//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Grid, Menu, Icon, Breadcrumb, Header, Divider } from 'semantic-ui-react';
import { ACTIVE_USER, ACTIVE_ADMIN } from '../../redux/redux_defines';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import LocalizedStrings from "../../localization/UserSettings"
import UserAvatar from '../WebComponents/UserAvatar';
import { lrServerConnection } from '../../redux/light_right_server_connection';
import { FetchGroups, FetchActiveAdmin } from '../../redux/actions/fetch_actions';
import BasicSelect from '../Basics/BasicSelect';
import { withRouter } from '../../webApp/WebRouter';

class ProjectSettings extends Component 
{
  constructor(props)
  {
    super(props)

    this.state = {
      admins: [],
      selectedAccount:"",
    }
  }

  componentDidMount = async () =>
  {
    let admins = await lrServerConnection.getMyAdminsAccounts()

    this.setState({admins: admins})
  }

  render() 
  {
    let username = undefined
    let adminsOptions

    if(this.props.loggedInUser && this.props.loggedInUser.data)
    {
      username = this.state.selectedAccount ? this.state.selectedAccount : this.props.loggedInUser.data.username
      lrServerConnection.setUser(username)
      FetchGroups(true)
    }
    
    if( ! username)
    {
      return <b>FAILED to get User</b>
    }

    if(this.state.admins)
    {
      adminsOptions = (this.state.admins.length ? this.state.admins : []).map(admin => { return {key:admin._id, text: admin.name, value:admin.username}});
      let isLoggedInUser = adminsOptions.findIndex(el => el.key === this.props.loggedInUser.data._id)
      if(isLoggedInUser === -1)
      {
        adminsOptions = [{key:this.props.loggedInUser.data._id, text: this.props.loggedInUser.data.name, value:this.props.loggedInUser.data.username},...adminsOptions]
      }
    }
      
    return (
      <div>
        <Breadcrumb>
          <Header>
            <UserAvatar user={username}/>
            <Breadcrumb.Section as={Link} to={`/${username}`} link>{this.props.activeAdmin?.data?.name}</Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section link>{LocalizedStrings.Settings}</Breadcrumb.Section>
          </Header>
        </Breadcrumb>
        <Divider/>
        <Grid>
          <Grid.Column width={4}>
            <Menu secondary vertical>
              <BasicSelect
                  normalDropdown
                  style={{marginBottom:"1rem"}}
                  fluid
                  button
                  className='icon'
                  floating
                  labeled
                  icon='address book'
                  options={adminsOptions}
                  search
                  placeholder={LocalizedStrings.AccountManager}
                  value={this.state.selectedAccount}
                  onStateUpdate={(name, value, e) => {this.setState({selectedAccount: value}); FetchActiveAdmin(true); this.changeAdminUsernameInUrl(value)}}
                /> 

              <Menu.Item name='profile'  as={Link} to={`/${username}/settings/profile`}  active={this.props.activeMenu === "profile" }><Icon name="user"/>{LocalizedStrings.Profile}</Menu.Item>
              <Menu.Item name='account'  as={Link} to={`/${username}/settings/account`}  active={this.props.activeMenu === "account" }><Icon name="universal access"/>{LocalizedStrings.Account}</Menu.Item>
              <Menu.Item name='security' as={Link} to={`/${username}/settings/security`} active={this.props.activeMenu === "security"}><Icon name="user secret"/>{LocalizedStrings.Security}</Menu.Item>
              <Menu.Item name='reviews'  as={Link} to={`/${username}/settings/reviews`}  active={this.props.activeMenu === "reviews" }><Icon name="eye"/>{LocalizedStrings.Reviews}</Menu.Item>
              <Menu.Item name='updatetemplates'  as={Link} to={`/${username}/settings/updatetemplates`}  active={this.props.activeMenu === "updatetemplates" }><Icon name="random"/>{LocalizedStrings.UpdateProjects}</Menu.Item>
              <Menu.Item name='settings' as={Link} to={`/${username}/settings/settings`} active={this.props.activeMenu === "settings"} onClick={() => window.LR_RunOpenSettings()}><Icon name="settings"/>{LocalizedStrings.Settings}</Menu.Item>
            </Menu>
          </Grid.Column>
          <Grid.Column width={12}>
            {this.props.children}
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  changeAdminUsernameInUrl = (selectedAccount) =>
  {
    let splittedLocationArr = this.props.match.location.pathname.split("/")
    splittedLocationArr.splice(1, 1, selectedAccount)
    this.props.match.navigate(splittedLocationArr.join("/"))
  }
}

const mapStateToProps = (state) => {
  return {
    loggedInUser: (state[ACTIVE_USER]),
    activeAdmin: (state[ACTIVE_ADMIN])
  }
}

export default connect(mapStateToProps)(withRouter(ProjectSettings));

