//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component, } from 'react';
import { connect } from 'react-redux';
import { ACTIVE_PROJECT, ACTIVE_USER, REQUESTED_CALCULATIONS, REQUESTED_CALCULATIONS_ALL } from '../../redux/redux_defines'
import LocalizedStrings from "../../localization/RequestStructuralCheck";
import { Table, Grid, Header, Button, Segment, Loader, Icon, List, Dropdown  } from 'semantic-ui-react';
import { FetchStructuralCalculations,FetchStructuralCalculationsAll, hasFetched } from '../../redux/actions/fetch_actions';
import { lrServerConnection } from '../../redux/light_right_server_connection';
import CONST from '../../webApp/CONST';

const tagOptions = [
  {
    key: 'RequestedCalculationChecks',
    text: LocalizedStrings.RequestedCalculationChecks,
    value: 0,
    label: { color: 'grey', empty: true, circular: true },
  },
  {
    key: 'StatusInProgress',
    text: LocalizedStrings.StatusInProgress,
    value: 1,
    label: { color: 'blue', empty: true, circular: true },
  },
  {
    key: 'StatusChecked',
    text: LocalizedStrings.StatusChecked,
    value: 2,
    label: { color: 'green', empty: true, circular: true },
  },
  {
    key: 'StatusRequestedFeedback',
    text: LocalizedStrings.StatusRequestedFeedback,
    value: 3,
    label: { color: 'yellow', empty: true, circular: true },
  },
  {
    key: 'CanceledByUser',
    text: LocalizedStrings.CanceledByUser,
    value: 4,
    label: { color: 'red', empty: true, circular: true },
  },
  {
    key: 'All',
    text: LocalizedStrings.AllChecks,
    value: -1,
    label: { color: 'white', empty: true, circular: true },
  },

]



class StructuralCalculationChecks extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      selected : "",
      filter: -1
    }
  }

  Filter()
  {
    return <Dropdown
      icon='filter'
      floating
      textAlign="right"
      button
      className='icon'
    >
      <Dropdown.Menu>
        <Dropdown.Header icon='tags' content={LocalizedStrings.Filter} />
        <Dropdown.Menu scrolling>
          {tagOptions.map((option) => (
            <Dropdown.Item key={option.value} {...option} onClick={()=>{this.setState({filter: option.value})}}/>
          ))}
        </Dropdown.Menu>
      </Dropdown.Menu>
    </Dropdown>
}

  LocalFetchStructuralCalculations(force = false)
  {
    if(this.props.adminView)
    {
      FetchStructuralCalculationsAll(force)
    }
    else
    {
      FetchStructuralCalculations(force)
    }
  }

  IsAllowedToSign()
  {
    if(hasFetched(this.props.active_user))
    {
      return this.props.active_user.data.isAllowedToSignJobs
    }
    return false
  }


  GetDataPool()
  {
    if(this.props.adminView)
    {
      return this.props.reports_all
    }

    return this.props.reports
  }


  render()
  {
    let selectedReport = 
    {
      checks: []
    }


    if(hasFetched(this.GetDataPool()))
    {
      for(let e of this.GetDataPool().data)
      {
        if(e._id === this.state.selected)
        {
          selectedReport = e
          break
        }
      }
    }

    let startWork = async() =>
    {
      await lrServerConnection.startStructuralCalculation(
      {
        Owner: selectedReport.user.username,
        Project: selectedReport.project.name,
        ID: selectedReport._id,
      })
      this.LocalFetchStructuralCalculations(true)
    }

    let cancelWork = async() =>
    {
      await lrServerConnection.cancelStructuralCalculation(
      {
        Owner: selectedReport.user.username,
        Project: selectedReport.project.name,
        ID: selectedReport._id,
      })
      this.LocalFetchStructuralCalculations(true)
    }

    let requestFeedback = async () =>
    {
      await lrServerConnection.requestStructuralCalculation(
      {
        Owner: selectedReport.user.username,
        Project: selectedReport.project.name,
        ID: selectedReport._id,
      })
      this.LocalFetchStructuralCalculations(true)

    }



    this.LocalFetchStructuralCalculations()
    return(<>
   <Grid>
     <Grid.Column width="10">
     <Header>{LocalizedStrings.RequestedCalculationChecks}</Header>
     {this.Filter()}
      <Table selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{LocalizedStrings.State}</Table.HeaderCell>
          <Table.HeaderCell>{LocalizedStrings.Project}</Table.HeaderCell>
          <Table.HeaderCell>{LocalizedStrings.RequestedBy}</Table.HeaderCell>
          <Table.HeaderCell>{LocalizedStrings.File}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {hasFetched(this.GetDataPool()) ? this.GetDataPool().data.map(e => this.renderRow(e)) : <Loader active={true}></Loader> }
      </Table.Body>
      </Table>
     </Grid.Column>
     <Grid.Column width="6">
     <Header>{LocalizedStrings.ReportDetails}</Header>
       <Segment>
         <Header>{LocalizedStrings.General}</Header>
         <Table definition>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>{LocalizedStrings.Value}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>{LocalizedStrings.State}</Table.Cell>
              <Table.Cell>{this.status(selectedReport.status)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{LocalizedStrings.Project}</Table.Cell>
              <Table.Cell>{selectedReport?.project?.name}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{LocalizedStrings.User}</Table.Cell>
              <Table.Cell>{selectedReport?.user?.name}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{LocalizedStrings.RequestedBy}</Table.Cell>
              <Table.Cell>{selectedReport?.userRequested?.name}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{LocalizedStrings.EngineerForTheJob}</Table.Cell>
              <Table.Cell>{selectedReport?.userRequested?.name}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{LocalizedStrings.Price}</Table.Cell>
              <Table.Cell>{this.getPricing(selectedReport)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{LocalizedStrings.Invoice}</Table.Cell>
              <Table.Cell>{(selectedReport.invoice_url === undefined || selectedReport.invoice_url === "") ? null : <a href={selectedReport?.invoice_url} target="_blank" rel="noopener noreferrer">{LocalizedStrings.DownloadInvoice}</a>}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{LocalizedStrings.Mail}</Table.Cell>
              <Table.Cell>{selectedReport.userMail}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>{LocalizedStrings.Phone}</Table.Cell>
              <Table.Cell>{selectedReport.userPhone}</Table.Cell>
            </Table.Row>
          </Table.Body>
         </Table>
         <h2>{LocalizedStrings.DoneChecks}</h2>
         <List divided relaxed>
         {selectedReport.checks.map(e=>this.renderTask(e))}
         </List>

       </Segment>
       <Segment>

         <Header>{selectedReport.Actions}</Header>
        <Button.Group vertical fluid>
         <Button disabled={selectedReport._id === undefined || selectedReport.status !== 2} positive onClick={()=>this.download(selectedReport)}>{LocalizedStrings.DownloadReport}</Button>
         <Button disabled={selectedReport._id === undefined || selectedReport.status !== 0} onClick={cancelWork} negative >{LocalizedStrings.CancelRequest}</Button>
         {this.IsAllowedToSign() ? <Button disabled={selectedReport._id === undefined || !(selectedReport.status === 0 || selectedReport.status === 3)} onClick={startWork} primary >{LocalizedStrings.StartWorkOnProject}</Button> : null}
         {this.IsAllowedToSign() ? <Button disabled={selectedReport._id === undefined || selectedReport.status !== 1} color="yellow" onClick={requestFeedback}primary >{LocalizedStrings.RequestFeedback}</Button> : null}
         {this.IsAllowedToSign() ? <Button disabled={selectedReport._id === undefined || selectedReport.status !== 1} color="cyan" onClick={()=>{window.open("productionassist://openproject?user="+selectedReport?.user?.username+"&project="+selectedReport?.project?.name+"&branch=master"+"&singingjob=" + selectedReport._id, '_blank');}} >{LocalizedStrings.CheckoutAndProve}</Button> : null}
        </Button.Group>
       </Segment>
     </Grid.Column>
   </Grid>
    </>)

  }
  download = async (selectedReport) => {
    let url = `${CONST.BASE_URL}/api/users/${selectedReport.user.username}/projects/${selectedReport.project.name}/signingjobs/${selectedReport._id}/report/`
    
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      "",
    );

    let headers = new Headers();
    headers.append('Authorization', `Bearer ${lrServerConnection.__TOKEN}`);
    
    fetch(url, { headers })
        .then(response => response.blob())
        .then(blobby => {
            let objectUrl = window.URL.createObjectURL(blobby);
    
            link.href = objectUrl;
            link.download = `${selectedReport.user.username}_${selectedReport.project.name}_report.pdf`;
            link.click();
    
            window.URL.revokeObjectURL(objectUrl);

            link.parentNode.removeChild(link);

        });
    


  }

  renderTask(e,)
  {
      return<>
      <List.Item  key={e.text}>
          {e.ok ? <List.Icon verticalAlign='middle' color="green" name="thumbs up"/> : <List.Icon verticalAlign='middle' color="red" name="thumbs down"/>}
          <List.Content>
          <List.Header>{e.text}</List.Header>
          <List.Description>{e.note}</List.Description>
          </List.Content>
      </List.Item>
      </>
  }

  status = (s) =>
  {
    switch(s)
    {
      case 0: return (<> <Icon color="grey" name="circle"/>{LocalizedStrings.RequestedCalculationChecks}</>)
      case 1: return (<> <Icon color="blue" name="circle"/>{LocalizedStrings.StatusInProgress}</>)
      case 2: return (<> <Icon color="green" name="circle"/>{LocalizedStrings.StatusChecked}</>)
      case 3: return (<> <Icon color="yellow" name="circle"/>{LocalizedStrings.StatusRequestedFeedback}</>)
      case 4: return (<> <Icon color="red"name="circle"/>{LocalizedStrings.CanceledByUser}</>)
    }
  }

  getPricing(selectedReport)
  {
    if(selectedReport.currency === undefined)
    {
      return null
    }
    let cur = ""
    switch(selectedReport.currency)
    {
      case "usd": cur = "$"; break;
      case "eur": cur = "€"; break;
    }
    
    return <>{selectedReport.amount / 100} {cur}</>
  }

  renderRow = (e) =>
  {

    if(this.props.project && this.props.active_project?.data?.id !== e.project.id)
    {
      return null
    }
    if(this.state.filter >= 0 && e.status !== this.state.filter)
    {
      return null
    }

    return (
      <>
      <Table.Row 
        active={e._id === this.state.selected}
        onClick={() => {this.setState({selected: e._id})}}
      >
        <Table.Cell>
          {this.status(e.status)} 
        </Table.Cell>
        <Table.Cell>
          {e.project.name}
        </Table.Cell>
        <Table.Cell>
          {e.user.name}
        </Table.Cell>
        <Table.Cell>
          {e.file}
        </Table.Cell>
      </Table.Row>
      </>
    )
  }
}

//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      reports: (state[REQUESTED_CALCULATIONS]),
      reports_all: (state[REQUESTED_CALCULATIONS_ALL]),
      active_user: (state[ACTIVE_USER]),
      active_project: (state[ACTIVE_PROJECT]),
    };
}

export default connect(mapStateToProps)(StructuralCalculationChecks)
