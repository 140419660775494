//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Button, Card, Icon, Segment, Header, Divider, Checkbox} from 'semantic-ui-react';
import DrawingNote from '../../DrawingNotes/Note';
import UserAvatar from '../../WebComponents/UserAvatar';
import LocalizedStrings from "../../../localization/TableViewComponent";

import { globalCallbacks as mainCB } from '../../../util/callback';
import { globalCallbacks as mockCB } from '../../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB
class NotesView extends Component
{
    constructor(prop)
    {
        super(prop);
        this.state = 
        {
            Notes:[],
            filterRead: false,
            filterDone: false,
            loggedUser: ""
        }        
    }

    componentDidMount()
    {
        globalCallbacks.RefetchNotesView = async () =>
        {
            let noteData = await window.LR_GetNotesForObjects({Async: true, Worksheet:this.props.worksheet})
            let loggedInUserInfo = await window.LR_GetLoggedInUser()

            this.setState({Notes: noteData.Notes, loggedUser: loggedInUserInfo.User})
        }

        globalCallbacks.RefetchNotesView()
    }

    render()
    {
        let notes = this.state.Notes;
        if((this.state.filterRead === false) && (this.state.filterDone === false))
        {
            notes = this.state.Notes
        }else
        {
            notes = notes.filter(note => {
                return note.NoteState.Read[this.state.loggedUser] && note.NoteState.Complete === this.state.filterDone
            })
        }
        return (
            <>
            <Segment>
            <Header style={{display:"flex", justifyContent:"space-between"}}>{LocalizedStrings.NotesHeader}
            {this.renderNoteFilter()}
            </Header>
            <Divider/>            
            <Card.Group>
                {notes.map(note=>

                    <>
                    <Card key={note.UUID} onClick={()=>{globalCallbacks.setSelected(note.LinkedObjectObject.UUID, true)}} style={{background:`${note.NoteState.Complete ? "#5ee672" : "#ffffff" }`}}>
                    <Card.Content>
                        <Card.Header style={{position: "relative"}}>
                        <UserAvatar user={note.User} floated="right"/>
                            {note.User}
                            <div  style={{position:"absolute",right: "-0.5rem", top: "-0.2rem", fontSize:"0.75rem", display:"flex", flexDirection:"column"}}>
                                <Checkbox 
                                    style={{paddingBottom: "0.25rem"}}
                                    checked={Boolean(note.NoteState.Read[this.state.loggedUser])}     
                                    label={<label>{LocalizedStrings.Read}</label>} 
                                    onChange={(_, {checked}) => {this.handleRead(note, checked)}} />
                                <Checkbox 
                                    checked={note.NoteState.Complete} 
                                    label={<label style={{ color:`${note.NoteState.Complete ? "rgba(0, 0, 0, 0.87)" : "#f58d1d" }`}}>{LocalizedStrings.Done}</label>} 
                                    onChange={(_, {checked}) => {this.handleComplete(note, checked)}} />
                            </div>
                        </Card.Header>
                        {note.LinkedObjectObject.UUID ? <Card.Meta>
                            {note.LinkedObjectObject.NameIdentifier}
                        </Card.Meta> : null}
                        <Divider/>
                        <Card.Description>
                        <DrawingNote text={note.Note}/>
                        </Card.Description>
                    </Card.Content>

                    </Card>
                    </>)}



            </Card.Group>
            </Segment>
            <Segment placeholder>
            <Header icon>
                <Icon name='sticky note' />
                {LocalizedStrings.AddNote}
                </Header>
                <Button fluid onClick={()=>{window.LR_ShowCreateDrawingNote({UUID: this.props.worksheet})}} primary><Icon name="sticky note"/>{LocalizedStrings.AddNote}</Button>
            </Segment>
            </>
        )
    }

    handleRead = (note, checked) =>
    {
        if(checked)
        {
            window.LR_SetNote({
                UUID:note.UUID, 
                NoteState: {
                    ...note.NoteState,
                    Read: {
                        ...note.NoteState.Read,
                        [this.state.loggedUser] : Date.now()
                    }
                }})
        } else {

          let readUsers = note.NoteState.Read
          delete readUsers[this.state.loggedUser]
            window.LR_SetNote({
                UUID:note.UUID, 
                NoteState: {
                    ...note.NoteState,
                    Read: readUsers
                }})
        }
      
    }
 
    handleComplete = (note, checked) =>
    {
        if(checked)
        {
            window.LR_SetNote({
                UUID:note.UUID, 
                NoteState: {
                    Read: note.NoteState.Read, 
                    Complete: checked, 
                    User:this.state.loggedUser, 
                    Date: Date.now()
                }})
        } else {
            window.LR_SetNote({
                UUID:note.UUID, 
                NoteState: {
                    Read: note.NoteState.Read, 
                    Complete: checked, 
                    User:"", 
                    Date: Date.now()
                }})
        }
     }

    renderNoteFilter = () =>
    {
        return (
            <div style={{fontSize:"0.8rem"}}>
                <span style={{marginRight:"0.25rem", fontSize:"0.9rem"}}>Filter: </span>
                <Checkbox
                    style={{marginRight:"0.3rem"}}
                    label={<label>{LocalizedStrings.Read}</label>} 
                    checked={this.state.filterRead} 
                    onChange={(e, {checked}) =>{this.setState({filterRead: checked})}} />

                <Checkbox
                    label={<label>{LocalizedStrings.Done}</label>} 
                    checked={this.state.filterDone} 
                    onChange={(e, {checked}) =>{this.setState({filterDone: checked})}} />
            </div>
        )
    }

}

export default NotesView;