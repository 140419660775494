//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Form } from 'semantic-ui-react'
import {rgb2hex, RGB2cie, colorCapRgb} from "./utilities"

import LocalizedStrings from "../../localization/ColorPicker"


export class ManufacturerField extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
          ManufacturerOption: []
        }

        import("./GelColor").then((d)=>{
          let ManufacturerOption = []
          for(const [key] of Object.entries(d.Gels))
          {
              let value = d.Gels[key]
              ManufacturerOption.push({
                  key: value.Name,
                  text: value.Name,
                  value: value.Name,
              })
          }
          this.setState({ManufacturerOption})
        })
    }
  render()
  {

    return (
        <Form.Select    
        style     ={{width: 'auto'}}
        floating
        fluid
        label={LocalizedStrings.Manufacturer}
        selection
        value = {this.props.Manufacturer}
        options={this.state.ManufacturerOption}
        onChange={this.onManufacturerChange}/>
    )
  }

  onManufacturerChange = (e , {value}) => 
  {
    this.props.onManufacturerChange({
      selectedGelName:          "",
      selectedManufacturerName: value,
    },
    e)
  }
}

export class GelsField extends Component
{
  async renderGelOptions(index)
  {
      this.Gels = (await import("./GelColor")).Gels
      let selection = this.Gels[index];
      let entry = this.Gels["MA"]
      if(selection)
      {
          entry = selection
      }
  
      let gelPool = []
      for(const [key] of Object.entries(entry.Gels))
      {
          let gel = entry.Gels[key]
          gelPool.push(
          {
              key: gel.Key,
              text: <span><svg width="1.3em" height="1.3em"><circle cx="50%" cy="50%" r="0.6em" strokeWidth="1" stroke="#000000" fill={rgb2hex([Number(gel.R) * 255,Number(gel.G) * 255,Number(gel.B) * 255])}></circle></svg> {gel.Key} {gel.Name}</span>,
              value: gel.Key,
              name: gel.Name
          })
      }
  
      this.setState({gelOptions: gelPool})
  }

  constructor(props) {
    super(props)
    this.state = {gelOptions: []}
    this.renderGelOptions(props.Manufacturer)

  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.Manufacturer !== this.props.Manufacturer)
    this.renderGelOptions(this.props.Manufacturer)
  }

  render()
  {
    return (
        <Form.Select
        style     ={{width: 'auto'}}
        floating
        fluid
        label={LocalizedStrings.Gel}
        selection
        value = {this.props.SelectedGel}
        onChange={this.onGelChange}
        options={this.state.gelOptions}/>
    )
  }

  onGelChange = (e , {value}) => 
  {
    let selection = this.Gels[this.props.Manufacturer];
    let entry = this.Gels["MA"]
    if(selection)
    {
        entry = selection
    }

    let rgb = [parseFloat(entry.Gels[value].R) * 255,parseFloat(entry.Gels[value].G) * 255,parseFloat(entry.Gels[value].B) * 255]
    colorCapRgb(rgb);
    let cie = RGB2cie(rgb);

    const selectedGel = this.state.gelOptions.find(opt => opt.value === value)
    this.props.onGelChange(
        {
            f_Y: cie.f_Y,
            fy: cie.fy,
            fx: cie.fx,
            selectedGelName: selectedGel.name,
            selectedGelID: value,
            selectedManufacturerName: this.props.Manufacturer,
        },
        e);
  }
}