//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component, } from 'react';
import {  Grid, List, Header, Container, Image, Divider,Segment, Icon } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import LocalizedStrings from "../../localization/WebSiteHeader";
import { connect } from 'react-redux';
import { ACTIVE_USER } from '../../redux/redux_defines'
import "./Footer.css"

import logo from '../../content/img/applogo_white.png'
import CONST from "../../webApp/CONST";



class Footer extends Component 
{
  
  render() 
  {    
    let isBeta = this.props.loggedInUser.data && this.props.loggedInUser.data.isBeta
    console.log("this.props.loggedInUser", isBeta)
    return (
      <div className='footer'>
      <Segment    style={{ padding: '5em 0em'}}>
        <Container  textAlign='center'>
          <Grid stackable>
            <Grid.Column width={4} style={{textAlign:"left"}} >
              <Header style={{color:"#ecd1dc" }} as='h4' content='Production Assist' />
              <i as={Link} onClick={()=>{ window.open("https://www.deersoft.de")}}>© DeerSoft GmbH {new Date().getFullYear()}</i>
            </Grid.Column>
            <Grid.Column width={4} style={{textAlign:"left"}} >
              <List link>
                <List.Item as={Link} to="/about">Production Assist</List.Item>
                <List.Item as={Link} to="/aboutus">{LocalizedStrings.AboutUs}</List.Item>
                <List.Item as={Link} to="/agb">{LocalizedStrings.AGB}</List.Item>
                <List.Item as={Link} to="/impressum">{LocalizedStrings.Impressum}</List.Item>
                <List.Item as={Link} to="/impressum#data">{LocalizedStrings.DataProtection}</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={4} style={{textAlign:"left"}} >
              <List link>
              <List.Item as={Link} to="/structuralquote">{LocalizedStrings.StructuralQuote}</List.Item>

              <List.Item as={Link} to="/download">{LocalizedStrings.Downloads}</List.Item>
                <List.Item as={Link} to="/admin">{LocalizedStrings.OnlineContent}</List.Item>
                <List.Item as={Link} to="/support">{LocalizedStrings.Support}</List.Item>
                <List.Item as={Link} to="/static">{LocalizedStrings.StaticCalculation}</List.Item>
                <List.Item as={Link} to="/collaboration">{LocalizedStrings.Collaboration}</List.Item>
                {isBeta ? <List.Item as={Link} to="/acceptance-test">{LocalizedStrings.AcceptanceTests}</List.Item> : null}
              </List>
            </Grid.Column>
            <Grid.Column width={4} style={{textAlign:"left"}}>
              <List link>
                <List.Item as={Link} to="/faq">{LocalizedStrings.FAQ}</List.Item>
                <List.Item as={Link} to="/video-tutorial">{LocalizedStrings.Tutorial}</List.Item>
                <List.Item as={Link} to="/documentation">{LocalizedStrings.Documentation}</List.Item>
                <List.Item as={Link} to="/knowhow">{LocalizedStrings.KnowHow}</List.Item>
              </List>
            </Grid.Column>
          </Grid>
  
          <Divider section />
          <List horizontal link size='small'>
            <List.Item as={"a"} href="https://www.instagram.com/production_assist/" target="_blank" rel="noreferrer"><Icon style={{color: "white"}}size='huge' name="instagram"/></List.Item>
            <List.Item as={"a"} href="https://www.facebook.com/groups/334512312106347/" target="_blank" rel="noreferrer"><Icon  style={{color: "white"}} size='huge' name="facebook"/></List.Item>
          </List>
          <Divider section />
          <Image centered size='mini' src={logo} />
          <List horizontal divided link size='small'>

            <List.Item>
              {CONST.VERSION}-{CONST.BUILD}-{CONST.HASH}
            </List.Item>
          </List>
        </Container>
      </Segment>
    </div>

    );
  }
}

//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      loggedInUser: (state[ACTIVE_USER]),
    };
}

export default connect(mapStateToProps)(Footer)


