//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from "react"
import { Link } from "react-router-dom";
import { Table, Icon, Loader, Segment, Header, Button, Form, Message } from "semantic-ui-react";
import UserAvatar from "../WebComponents/UserAvatar";
import { connect } from 'react-redux';
import { isFetching, hasFetched, FetchActiveChange, FetchProject, FetchActiveUser } from '../../redux/actions/fetch_actions';
import { ACTIVE_PROJECT, ACTIVE_CHANGE, ACTIVE_USER } from "../../redux/redux_defines"
import LocalizedStrings from "../../localization/ChangeRequest"
import LRModal from "../Basics/BasicModal";

class ChangeRequestList extends Component
{
    constructor(props)
    {
        super(props)

        this.state = 
        {
            open: false,
            showOpen: true,
            newBranch: null
        }

    }
    render()
    {
        FetchProject()
        FetchActiveChange()
        FetchActiveUser()
        
        let closed = 0
        let open = 0
        if(hasFetched(this.props.activeProject) && this.props.activeProject.data.branches) 
        {
            this.props.activeProject.data.branches.forEach(b => closed += b.closed ? 1 : 0)
            open = this.props.activeProject.data.branches.length - closed
        }
        
        let openColor = this.state.showOpen ? "#1e70bf" : "#000"
        let closeColor = this.state.showOpen ? "#000" : "#1e70bf"

        let showData = []
        if(hasFetched(this.props.activeProject))
        {
            showData = this.state.showOpen ? this.props.activeProject.data.branches.filter(b => !b.closed) : 
                                             this.props.activeProject.data.branches.filter(b => b.closed)
            
        }
        let hasWrite = false
        
        if(hasFetched(this.props.activeProject) && hasFetched(this.props.activeUser)) {
            const username = this.props.activeUser.data.username
            hasWrite =  this.props.activeProject.data.members.some(member => member.write && member.user.username === username) ||
                        this.props.activeProject.data.groups.some(group => group.write && group.group.members.some(member => member.username === username))
        }

        let branchNameExists = false
        if(hasFetched(this.props.activeProject) && this.state.newBranch) {
            branchNameExists = this.props.activeProject.data.branches.some(branch => branch.name === this.state.newBranch)
        }
        
        return <>
        <Table celled striped>
        <Loader />
            <Table.Header>
            <Table.Row>
                <Table.HeaderCell colSpan='5'>
                    {isFetching(this.props.activeProject) ? <Icon name="spinner" loading/> : <>{open}</>}
                    {" "} 
                    <div onClick = {this.toggleShow} style   = {{color : openColor, display: "inline", cursor: "pointer"}}>
                        {LocalizedStrings.Open  + " "} 
                    </div>
                    {isFetching(this.props.activeProject) ? <Icon name="spinner" loading/> : <>{closed}</>}
                    {" "} 
                    <div onClick = {this.toggleShow} style   = {{color : closeColor, display: "inline", cursor: "pointer"}}>
                        {LocalizedStrings.Closed}
                    </div>
                </Table.HeaderCell>
            </Table.Row>
            </Table.Header>
            <Table.Body>
                { isFetching(this.props.activeProject) ? <Table.Row><Table.Cell><Segment placeholder loading></Segment></Table.Cell></Table.Row> :null }
                { !isFetching(this.props.activeProject) && showData.length === 0 ? 
                    <Table.Row>
                        <Table.Cell>
                            <Segment placeholder>
                                <Header icon>
                                <Icon name="unordered list" />
                                {LocalizedStrings.NoData}
                                </Header>
                            </Segment>
                        </Table.Cell>
                    </Table.Row> 
                    : 
                    showData.map(b => this.renderRow(b)) }
            </Table.Body>
            <Table.Footer>
                <Table.Row>
                    <Table.Cell colSpan={ !isFetching(this.props.activeProject) && showData.length > 0 ? 4 : 1}>
                        <Button floated="right" primary disabled={!hasWrite} onClick={this.openAddBranch}><Icon name="add"/>{LocalizedStrings.AddNewBranch}</Button>
                    </Table.Cell>
                </Table.Row>
            </Table.Footer>
        </Table>
        <LRModal open={this.state.newBranch !== null} title={LocalizedStrings.AddNewBranch} size="mini" okDisabled={branchNameExists} onOkClick={this.okAddBranch} onCancelClick={this.cancelAddBranch}>
            <Form>
                <Form.Input value={this.state.newBranch} onChange={this.changeAddBranch} label={LocalizedStrings.Name}/>
            </Form>
            {branchNameExists ? <Message warning>
                <Message.Header>
                    {LocalizedStrings.BranchExistsHeader}
                </Message.Header>
                <Message.Content>
                    {LocalizedStrings.BranchExistsBody}
                </Message.Content>
            </Message> : null}
        </LRModal>
        </>
    }

    cancelAddBranch = () => {
        this.setState({newBranch: null})
    }

    changeAddBranch = (_, {value}) => this.setState({newBranch: value.replace(/[^a-zA-Z0-9]/g,'-')})

    openAddBranch = () => this.setState({newBranch: ""})

    okAddBranch = async () => {
        await window.LR_CreateBranchAsync({Name: this.state.newBranch})
        this.setState({newBranch: null})
        FetchProject(true)
    }

    toggleShow = () => {
        this.setState({showOpen : !this.state.showOpen})
    }


    renderRow = (branch) => 
    {
        let lastComment = branch.comments[branch.comments.length - 1] ?? undefined
        let elapsedComment  =  ""
        if(lastComment) 
        {
            let res = getElapsedTimeStr(lastComment.date) 
            elapsedComment = res.Approx + " " + res.Str  + " ago"
        }

        let file = hasFetched(this.props.files) ? this.props.files.data.find(f => f._id === branch.current) : undefined
        let elapsedFile  =  ""
        if(file) 
        {
            let res = getElapsedTimeStr(file.date)
            elapsedFile = res.Approx + " " + res.Str  + " ago"
        }


        let linkColor = this.state[branch.number + "hover"] ?? "#000"

        return <Table.Row key={branch.number}>
                    <Table.Cell collapsing>
                        <Link   style        = {{ color:  linkColor}}
                                onMouseOver  = {() => this.setState({[branch.number + "hover"] : "#1e70bf"})}
                                onMouseLeave = {() => this.setState({[branch.number + "hover"] : "#000"})}
                                to           = {hasFetched(this.props.activeProject) ? `/${this.props.activeProject.data.owner.name}/${this.props.activeProject.data.projectname}/changelists/${branch.number}` : '/'}>
                            <Icon name='code branch' /> {(branch && "#" + branch.number)}
                        </Link>
                    </Table.Cell>
                    <Table.Cell>{branch.description}</Table.Cell>
                    <Table.Cell>
                        {file ?
                        <React.Fragment>
                            {LocalizedStrings.ModifiedBy} 
                            <UserAvatar user={file.modifiedBy.username}/>
                            {file.modifiedBy.name + " " + elapsedFile + (file.message ? " • " + file.message : "")} 
                        </React.Fragment> : null}
                    </Table.Cell>
                    <Table.Cell collapsing>
                        {lastComment ? 
                            <React.Fragment>
                                {LocalizedStrings.LastCommentedBy}
                                <UserAvatar user={lastComment.user.username}/>
                                {lastComment.user.name + " " + elapsedComment} 
                            </React.Fragment>
                         : LocalizedStrings.NoComments}
                    </Table.Cell>
                </Table.Row>
    }
  
}

// takes ISO time
export function getElapsedTimeStr(dateFrom, dateTo) 
{    
    // ISO to unix timestamp or unix timestamp now
    dateFrom = dateFrom ? new Date(dateFrom).getTime() : Date.now()
    dateTo = dateTo ? new Date(dateTo).getTime() : Date.now()

    // calc the difference (each one describes the same amount!)  
    let mins = (dateTo - dateFrom) / 1000 / 60
    let hours  = mins / 60
    let days = hours / 24
    
    // get the largest indicator with value > 1 as approx
    let approx = 0
    let str = ""
    if(Math.abs(days) > 1) 
    { 
        approx = days
        str = LocalizedStrings.Days

    }
    else if(Math.abs(hours) > 1)
    {
        approx = hours
        str = LocalizedStrings.Hours
    }
    else
    {
        approx = mins
        str = LocalizedStrings.Minutes
    }

    let res = {
        Str    : str, 
        Approx : Math.round(approx)
    }

    return res
}

//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      activeProject: state[ACTIVE_PROJECT],
      files        : state[ACTIVE_CHANGE],
      activeUser   : state[ACTIVE_USER]
    };
}

export default connect(mapStateToProps)(ChangeRequestList)