
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Search, Table, Label, Icon, Segment, Dropdown, Button } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/NavigationContainer";
import escapeRegExp from "lodash/escapeRegExp"
import filter from "lodash/filter"
import PrintLabelNode from './PrintLabelNode';
import { IsElectronContext, RESOURCE_TYPE_PrintLabel } from '../../util/defines';
import SelectResourceModal from '../ToolSelect/SelectRessourceModal';

import { globalCallbacks as mainCB } from '../../util/callback';
import { globalCallbacks as mockCB } from '../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB


const SORT_BY = "Name"

class PrintLabelTable extends Component {
    constructor(props) {
        super(props);

        this.state =
        {
            PrintLabels: [],

            sorting: null,
            indexMap: [],
            size: "A4",
            resourceSelectorOpen: false
        };

    }

    componentDidMount = () => {
        this.setUpCallbacks();
        globalCallbacks.updatePrintLabels();
    }

    PrintLabelsView = () => 
    {
        let X = 0
        let Y = 0

        switch (this.state.size) 
        {
            case 'A4': X = 210; Y = 297; break;
            case 'A3': X = 297; Y = 420; break;
            case 'A2': X = 420; Y = 594; break;
            case 'A1': X = 594; Y = 841; break;
            case 'A0': X = 841; Y = 1189; break;
            case 'Letter': X = 216; Y = 279; break;
            default:  break;
        }

        window.LR_AddNewPrintLabel({
            Name: LocalizedStrings.SheetLayer + " " + this.state.size, 
            PaperSizeX: X,
            PaperSizeY: Y,
            SizeX: X,
            SizeY: Y,
            PaperFormat: this.state.size
        })
    };

    render() {

        return (
            <div style={{ width: "100%", height: "100%" }}>
                <Table style={{ borderBottom: "none", margin: 0 }}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell onClick={this.onHeaderClick} colSpan='2' style={{ zIndex: 0 }} >
                                {<Icon link style={{ floated: 'left', position: 'absolute', zIndex: 1 }} name={this.state.sorting === null ? "sort" : this.state.sorting === "ascending" ? "sort alphabet up" : "sort alphabet down"} />}
                                <Search open={false}
                                    loading={this.state.isLoading}
                                    onClick={(e) => { e.stopPropagation() }}
                                    value={this.state.value}
                                    onSearchChange={(e, { value }) => this.onSearchChange(value, this.state.PrintLabels)}
                                    aligned="right" />
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                </Table>

                <div style={{ width: "100%", maxHeight: IsElectronContext() ? "calc(100vh - 25em)" : "calc(100vh - 30em)", overflowY: "auto", marginBottom: "5em" }}>
                    <Table striped structured compact='very' size="small">
                        <Table.Body>
                            {this.renderRows()}
                        </Table.Body>
                    </Table>
                </div>
                <Segment vertical textAlign="center" style={{ position: "absolute", bottom: IsElectronContext() ? "1.5em" : "6em", width: "100%", border: "none" }}>
                    <Label as="a" color="green" onClick={this.PrintLabelsView}>
                        {LocalizedStrings.PrintLabelViews}
                        {" "}
                        <Dropdown
                            upward
                            floating
                            options={[
                                { key: 'A4', text: 'A4', value: 'A4' },
                                { key: 'A3', text: 'A3', value: 'A3' },
                                { key: 'A2', text: 'A2', value: 'A2' },
                                { key: 'A1', text: 'A1', value: 'A1' },
                                { key: 'A0', text: 'A0', value: 'A0' },
                                { key: 'Letter', text: 'Letter', value: 'Letter' },
                            ]}
                            value={this.state.size}
                            onChange={(e,{value})=>{this.setState({size: value})}}
                        />
                    </Label>
                    <Button onClick={()=>{this.setState({resourceSelectorOpen: true})}} compact primary>
                        {LocalizedStrings.Download}
                    </Button>
                    {this.state.resourceSelectorOpen ? <SelectResourceModal 
                        open={this.state.resourceSelectorOpen} 
                        close={()=>{this.setState({resourceSelectorOpen: false})}} 
                        resourceType={RESOURCE_TYPE_PrintLabel}
                        disableNewButton
                        disableTopMenu
                        /> : null}

                </Segment>
            </div>

        );
    }

    onHeaderClick = () => {
        let newVal = null
        switch (this.state.sorting) {
            case null: newVal = "ascending"; break;
            case "ascending": newVal = "descending"; break;
            default: newVal = null; break;
        }
        this.setState({
            sorting: newVal
        })
    }

    renderRows = () => {
        if (this.state.sorting) {
            return this.state.indexMap.map((_, i) => {
                let calcIndex = this.state.sorting === 'ascending' ? i : this.state.indexMap.length - i - 1
                return this.state.SavPrintLabelsedViews[this.state.indexMap[calcIndex]]
            }).map(entry => { return <PrintLabelNode key={entry.UUID} entry={entry} /> })
        }

        return this.state.PrintLabels.map(entry => { return <PrintLabelNode key={entry.UUID} entry={entry} /> })
    }

    onSearchChange = (value, resources) => {
        this.setState({ isLoading: true, value: value })

        if (value.length < 1) {
            this.onSearchReset()
        }
        else {
            const re = new RegExp(escapeRegExp(value), 'i')
            const isMatch = (result) => { let res = re.test(result.Name); return res; }

            this.setState({ results: filter(resources, isMatch), isLoading: false })
        }
    }

    onSearchReset = () => {
        this.setState({
            isLoading: false,
            results: [],
            value: "",
        })
    }


    setUpCallbacks() {
        globalCallbacks.updatePrintLabels = () => {

            window.LR_GetPrintLabels().then(res => {
                let indexMap = []
                let objects = res.PrintLabels
                objects.forEach((_, i) => indexMap.push(i))
                indexMap.sort((a, b) => objects[a][SORT_BY] === objects[b][SORT_BY] ? 0 : (objects[a][SORT_BY] < objects[b][SORT_BY] ? -1 : 1))

                this.setState({ PrintLabels: res.PrintLabels, indexMap })
            })
        }

    }
}

export default PrintLabelTable;