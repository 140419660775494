import * as THREE from "three";
import { Color } from "three";
import { RESOURCE_TYPE_Mesh, UNIT_Milimeter } from "../../util/defines";
import { FitCameraToSelectedObjects, LoadGeometryReq, LoadMeshes } from "./js/defaultfunctions";
import { WEBGL } from "./js/WebGl";
import ResourceLoadContainer from "./ResourceLoadContainer";

export default class TextureRenderer
{
    constructor(width = 100, height = 100)
    {
        this.canvas = document.createElement("canvas")
        this.canvas.width = width
        this.canvas.height = height
        this.context = this.canvas.getContext("webgl2")
        this.prom = null
        this.renderOptions = {
            ShowLights: false,
            ShowBeam: false,
            ShowLightHelpers: false,
            ShowLabels: false,
            ShowMagnets: false,
            ThreeJSObjects: new Map(),
            Unit: UNIT_Milimeter,
            ColorStructs: false,
            meshLoadContainer: new ResourceLoadContainer(),
            meshIndexMap: {}
        }
        this.finishedPreviews = []

        // This is used for fit camera to selected objects. Needs a target, but the target in this case is allways (0, 0, 0)
        this.fakeOrbitControls = {
            target: new THREE.Vector3(0, 0, 0),
            update: () => {}
        }

        this.initScene()
    }

    initScene()
    {
        if (!WEBGL.isWebGL2Available())
        {
            console.warn("WebGL2 not enabled for texture renderer. Canceling init!");
            return;
        }

        this.renderer = new THREE.WebGLRenderer({canvas: this.canvas, context: this.context})


        this.camera = new THREE.PerspectiveCamera(50, 1, 10, 10000000)
        this.camera.matrixAutoUpdate = true
        this.camera.up.set(0,0,1)
        this.camera.position.set(50000, 50000, 50000)
        this.camera.lookAt(0, 0, 0)

        this.dirLight = new THREE.DirectionalLight(0xffffff, 1);
        this.dirLight.color.setHSL(0.1, 1, 0.95);
        this.dirLight.position.set(1500, 1000, 2000);

        this.resourceQueue = []
    } 

    pushResourceToQueue(resourceType, resourceUuid)
    {
        let startRendering = this.resourceQueue.length === 0
        this.resourceQueue.push({Type: resourceType, UUID: resourceUuid})

        if (startRendering)
        {
            console.log("Toggle new renderpass")
            this.toggleNewRenderPass()
        }
    }

    sleep = (ms) => 
    {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    toggleNewRenderPass = async () =>
    {
        while (this.resourceQueue.length > 0)
        {
            this.sleep(10);
            let currentResource = this.resourceQueue[0]
            await this.renderResource(currentResource.Type, currentResource.UUID)
            this.resourceQueue.shift()
        }

        window.LR_SetPreviewTextures({Textures: this.finishedPreviews})

        this.finishedPreviews = []
        //OnLightRightCoreCallback({Command: "reload-resources"})
    }

    async renderResource(resourceType, resourceUuid)
    {
        let scene = new THREE.Scene()
        scene.add(this.dirLight)

        
        this.renderOptions.meshLoadContainer.clear()
        let meshes = await window.LR_GetMeshesForResources({ResourceType: resourceType, ResourceUUID: resourceUuid})
        await LoadMeshes(meshes.Meshes, this.renderOptions.meshLoadContainer, undefined, scene)

        let index = {value: 0}
        let geometryTree = await window.LR_GetGeometryTree({Async: true, ObjectUUID: resourceUuid})
        if (resourceType === RESOURCE_TYPE_Mesh)
        {
            this.renderOptions.meshLoadContainer.get(resourceUuid, (meshContainer) => {
                if (!meshContainer || !meshContainer.GetMesh()) { return; }

                let mesh = meshContainer.GetMesh()

                let geometry = {}
                geometry.MeshUuid = resourceUuid
                geometry.UUID = resourceUuid
                geometry.OffsetX = 0;
                geometry.OffsetY = 0;
                geometry.OffsetZ = 0;
                geometry.RotationX = 0;
                geometry.RotationY = 0;
                geometry.RotationZ = 0;
                geometry.ScaleX = mesh.scale ? mesh.scale.x : 1
                geometry.ScaleY = mesh.scale ? mesh.scale.y : 1
                geometry.ScaleZ = mesh.scale ? mesh.scale.z : 1
                geometryTree.push(geometry)
            })
        }

        LoadGeometryReq(geometryTree, index, scene, this.renderOptions, {})

        
        let usedIndexMap = {}
        let meshObjectIndexMap = {}
        scene.traverse(object => {
            if (object.MeshInstance)
            {
                let theInstance = object.MeshInstance.GetMesh();
                if (theInstance)
                {
                    let meshUuid = theInstance.uuid

                    if (meshObjectIndexMap[meshUuid] === undefined)
                    {
                        meshObjectIndexMap[meshUuid] = []
                    }
                    meshObjectIndexMap[meshUuid].push(object);

                    if (usedIndexMap[meshUuid] === undefined)
                    {
                        usedIndexMap[meshUuid] = {Index: 0, MeshInstance: object.MeshInstance};
                    }
                    let currentIndex = Number(usedIndexMap[meshUuid].Index)
                    usedIndexMap[meshUuid].Index++;
                    object.updateMatrix()
                    object.updateMatrixWorld(true)


                    theInstance.setMatrixAt(currentIndex, object.matrixWorld)
                    theInstance.setColorAt(currentIndex, new Color(1,1,1))
                    
                    theInstance.instanceColor.needsUpdate = true
                    theInstance.instanceMatrix.needsUpdate = true
                }
            }
        })
        Object.entries(usedIndexMap).forEach(([key, value]) => {
            if (value.MeshInstance.SetCount(value.Index))
            {
                let theMeshInstance = value.MeshInstance
                for (let i = 0; i < meshObjectIndexMap[key].length; i++)
                {
                    let obj = meshObjectIndexMap[key][i]
                    theMeshInstance.setMatrixAt(i, obj.matrixWorld)
                    if (obj.CurrentColor)
                    {
                        theMeshInstance.setColorAt(i, obj.CurrentColor)
                    }
                    theMeshInstance.setColorAt(i, new THREE.Color(1,1,1))
                }

                theMeshInstance.instanceColor.needsUpdate = true
                theMeshInstance.instanceMatrix.needsUpdate = true
            }
        })
        
        FitCameraToSelectedObjects(scene, this.camera, this.fakeOrbitControls, false)

        this.renderer.setClearColor(0xffffff, 1)
        this.renderer.clear(true, true)
        this.renderer.render(scene, this.camera)
        
        let pngBase64 = this.renderer.domElement.toDataURL();
        this.finishedPreviews.push({ResourceType: resourceType, ResourceUUID: resourceUuid, PreviewTexture: pngBase64})

        Object.entries(usedIndexMap).forEach(([key, value]) => {
            value.MeshInstance.SetCount(0)
        })
    }
}