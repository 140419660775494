//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------

import React from "react"
import { Form, Label, Icon, Dropdown } from "semantic-ui-react";
import { BASE_UNIT_LENGTH,
         BASE_UNIT_PAGE_LENGTH,
         BASE_UNIT_VOLUME,
         BASE_UNIT_AREA,
         BASE_UNIT_WEIGHT,
         BASE_UNIT_ONE_BASED,
         BASE_UNIT_DATE,
         BASE_UNIT_COLOR,
         BASE_UNIT_ZERO_ONE,
         BASE_UNIT_FORCE_PER_DISTANCE,
         BASE_UNIT_TIME_CODE,
         BASE_UNIT_TORQUE,
         BASE_UNIT_COLOR_OBJECT,
         BASE_UNIT_MOMENT_PER_DISTANCE,
         BASE_UNIT_WEIGHT_PER_DISTANCE,
         BASE_UNIT_FORCE_PER_AREA,
         BASE_UNIT_SELECTABLE,
         BASE_UNIT_BOOLEAN,
         UNIT_FeetInches,
         UNITS,
         BASE_UNIT_POWER,
         BASE_UNIT_STRING,
         BASE_UNIT_FORCE,
         AppLanguageToString,
         getHexColorToGradient,
         BASE_UNIT_VELOCITY,
         UNIT_Inches,
         UNIT_Feet,
         Feet_Inch_Regex,
         extractNumber,} from "../../util/defines";
import ColorPicker from '../ColorPicker/ColorInputField.js';
import dayjs from "dayjs";
const DatePicker = React.lazy(()=>import("react-datepicker"))
import "react-datepicker/dist/react-datepicker.css";
import { connect } from 'react-redux';
//TODO: Dynamic Import / Maybe use other lib?
import "dayjs/locale/de"
import "dayjs/locale/es"
import { GLOBAL_SETTINGS as JestGS } from "../../redux/redux_defines";

let GLOBAL_SETTINGS
if(!process.env.JEST_WORKER_ID) { GLOBAL_SETTINGS  = JestGS }		

import { Parser as Parser } from 'expr-eval';
import { cie2hex } from "../ColorPicker/utilities";

// UNITS obj but for each baseUnit the 'units' array is sorted by decreasing 'str' length
let UNITS_SORTED = {}
Object.entries(UNITS).forEach(([baseUnitKey, baseUnitObj]) => {
    UNITS_SORTED[baseUnitKey] = { 
                                    ...baseUnitObj,
                                    units: baseUnitObj.units ? baseUnitObj.units.sort((u1, u2) => u2.str.length - u1.str.length) : undefined
                                }
})

function isNum (val)
{ 
    return !isNaN(val) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(val)) // ...and ensure strings of whitespace fail
}

class UnitInput extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state = {
            displayValue: undefined,
            currentlyEditing: false, // for table cells, which usually are transparent, this toggles the visibility of the input 
            hover : false
        }
        this.intervalRef = React.createRef()
    }

    componentDidUpdate(oldProps, prevState) 
    {
        if(this.state.displayValue !== undefined && this.props.onStateUpdate === undefined && oldProps.value !== this.props.value)
        {
            this.setState({displayValue: undefined})
        }

        let oldGlobalSettings = oldProps.globalSettings
        let oldUnit = getUnit(this.props.baseUnit, oldGlobalSettings)
        let newGlobalSettings = this.props.globalSettings
        let newUnit = getUnit(this.props.baseUnit, newGlobalSettings)
        if (oldUnit !== newUnit && this.state.displayValue !== undefined)
        {
            if (isNum(this.state.displayValue))
            {
                let coreVal = getCoreValueFromUnit(Number(this.state.displayValue), oldUnit, this.props.baseUnit)
                let displayValue = getUnitValueFromCore(Number(coreVal), newUnit, this.props.baseUnit);
                this.setState({displayValue: displayValue})
            }
        }
        
    }



    getAccuracy = (settings) =>
    {
        if (!settings) { return 1; }
        switch(this.props.baseUnit)
        {
            case BASE_UNIT_LENGTH       : return settings.App_DisplayUnit_Length.Accuracy
            case BASE_UNIT_PAGE_LENGTH  : return settings.App_DisplayUnit_PageLength.Accuracy
            case BASE_UNIT_WEIGHT       : return settings.App_DisplayUnit_Weight.Accuracy
            case BASE_UNIT_FORCE       : return settings.App_DisplayUnit_Force.Accuracy
            case BASE_UNIT_WEIGHT_PER_DISTANCE       : return settings.App_DisplayUnit_WeightPerDistance.Accuracy
            case BASE_UNIT_VOLUME       : return settings.App_DisplayUnit_Volume.Accuracy
            case BASE_UNIT_AREA         : return settings.App_DisplayUnit_Area.Accuracy
            case BASE_UNIT_TIME_CODE    : return settings.App_DisplayUnit_TimeCode.Accuracy
            case BASE_UNIT_FORCE_PER_AREA    : return settings.App_DisplayUnit_ForcePerArea.Accuracy
            case BASE_UNIT_VELOCITY    : return settings.App_DisplayUnit_Velocity.Accuracy
            case BASE_UNIT_ZERO_ONE     : return 2
            default: return 2
        }
    }

    componentWillUnmount()
    {
        return () => this.stopSteping()
    }
    render()
    {
        let globalSettings = this.props.globalSettings
        let unit = getUnit(this.props.baseUnit,globalSettings)
        let accuracy = this.getAccuracy(globalSettings)
        const transparent = this.props.transparent && (!this.props.cellChild || !this.state.currentlyEditing)
        switch (this.props.baseUnit)
        {
            case BASE_UNIT_DATE:
                if (this.props.readOnly)
                {
                    if(this.props.dateOnly)
                    {
                        return formatDateToDateOnly(this.props.value, globalSettings.DateFormat, globalSettings?.Language?.Value)
                    }else if(this.props.timeOnly){
                        return formatDateToTimeOnly(this.props.value, globalSettings.TimeFormat, globalSettings?.Language?.Value)
                    }else{
                        return formatDateToText(this.props.value, globalSettings.DateFormat, globalSettings.TimeFormat, globalSettings?.Language?.Value)
                    }
                }
                else 
                {
                    return <Form.Field>
                        <label>{this.props.label}</label>
                                <DatePicker  selected        =   {Date.parse(this.props.value)} 
                                        isClearable     =   {this.props.isClearable ?? false}
                                        onChange        =   {(date) => { if (this.props.onStateUpdate) { this.props.onStateUpdate(this.props.name, date ? date.toISOString() : "") } }}
                                        onCalendarClose =   {() => { if (this.props.onUpdateData) this.props.onUpdateData(this.props.name) }}
                                        dateFormat      =   {"dd/MM/yyyy h:mm aa"}
                                        disabled        = {this.props.disabled}
                                        showTimeSelect
                                        />
                            </Form.Field>
                }
            case BASE_UNIT_SELECTABLE:
            {
                if (this.props.readOnly)
                {
                    const selected = this.props.options.find(opt => opt.value === this.props.value)
                    if(!selected)
                    {
                        return ""
                    }
                    return selected.text
                }

                const nonEditStyle = {borderWidth: "0px", backgroundColor: "rgba(0,0,0,0)", paddingLeft: "0px", paddingRight: "0px"}

                let showGraphics = !(!this.state.currentlyEditing && this.props.onlyShowOnEdit)
                const style = {...(this.props.style ?? {}), ...(showGraphics ? {} : nonEditStyle)}
                return (
                  <Form.Select 
                    selection
                    search={!this.props.noFluid}
                    label={this.props.label}
                    placeholder={this.props.placeholder}
                    fluid={!this.props.noFluid && showGraphics}
                    value={this.props.value}
                    options={this.props.options}
                    onClick={() => this.setState({currentlyEditing: true})}
                    onBlur={() => this.setState({currentlyEditing: false})}
                    onChange={(e, { value }) => { this.props.updateData(value, e) }}
                    style={style}
                    />
                );
            }
            case BASE_UNIT_COLOR:
            case BASE_UNIT_COLOR_OBJECT:
                if (this.props.readOnly)
                {
                    let hexColors = this.props.value.Color?.map(i => cie2hex(i)) ?? ["#CCCCCC"]
                    let gradient = getHexColorToGradient(hexColors)
                    
                    return (<React.Fragment>
                        <div
                            data-color-data={hexColors.join(";")}
                            style={{
                                minHeight: 30,
                                display: "flex",
                                alignItems: "center",
                                backgroundImage: gradient,
                                borderRadius: "0.4em",
                                padding: 5,
                                paddingLeft: 10,
                                width: "100%",
                                fontWeight: 600,
                                textShadow: "-0.75px 0 white, 0 0.75px white, 0.75px 0 white, 0 -0.75px white",
                                ...(this.props.style ?? {})
                            }}
                        >
                            {this.props.value.Name !== undefined ? this.props.value.Name : "<None>"}
                        </div>

                    </React.Fragment>
                    )
                }
                else
                {         
                    return <ColorPicker colorX         = {this.props.value.X ?? 0.3127}
                                        colorY         = {this.props.value.Y ?? 0.3290}
                                        colorL         = {this.props.value.Z ?? 100}
                                        title          = {this.props.label} 
                                        icons          = {this.props.icons}
                                        label          = {this.props.label}
                                        onColorChanged = {(res) => {this.props.onStateUpdate(this.props.name, {X: res.fx, Y: res.fy, Z: res.f_Y})}}/>
                }
            case BASE_UNIT_BOOLEAN:
                {
                    if (this.props.readOnly)
                    {
                        return this.props.value ? "yes" : "no"
                    }

                    return <Form.Checkbox checked={this.props.value}
                                          label={this.props.label}
                                          onChange={(e, {checked}) => {
                                              if (this.props.onUpdateData)
                                              {
                                                  this.props.onUpdateData(this.props.name, checked)
                                              }
                                          }}/>
                }

            case BASE_UNIT_STRING:
                {
                    return <Form.Input  label           =   {this.props.label}
                                    placeholder     =   {this.props.placeholder}
                                    disabled        =   {this.props.disabled}
                                    inline          =   {this.props.inline}
                                    name            =   {this.props.name}
                                    compact         =   {this.props.compact}
                                    size            =   {this.props.size}
                                    data-commitid={this.props.commitid} 
                                    transparent     =   {transparent}
                                    readOnly        =   {this.props.readOnly}
                                    onClick         =   {() => { this.setState({currentlyEditing: true}) }}
                                    fluid 
                                    labelPosition   =   {this.props.labelPosition ? (this.props.labelPosition ?? "right") : undefined}
                                    value           =   {this.state.displayValue ? this.state.displayValue : this.props.value}
                                    onChange        =   {(e, {value}) => {this.onChangeString(e, value) }}
                                    onBlur          =   {(e) => {this.onBlurString(e)}}
                                    onKeyDown       =   {this.onKeyDownString}>

                                        <input id={this.props.LRIDFIELD} style={{backgroundColor: this.props.presetColor ?? ""}} />
                            </Form.Input>
                }
            default:
                {
                    // prepare some values based on props
                    let step = this.props.step ?? getCoreValueFromUnit(1, unit, this.props.baseUnit)
                    
                    let max = 1
                    let min = 0
                    if(!(this.props.baseUnit === BASE_UNIT_ZERO_ONE))
                    {
                        max  = this.props.max ?? Number.POSITIVE_INFINITY
                        min  = this.props.baseUnit === BASE_UNIT_ONE_BASED ? 0 : (this.props.min ?? Number.NEGATIVE_INFINITY)
                    }
                    
                    let displayValue = getUnitValueFromCore((this.props.value ?? 0), unit, this.props.baseUnit);

                    let diff = 0
                    if(isNum(String(displayValue)))
                    {
                        let valAsNum = Number(displayValue)
                        diff = valAsNum - Number(valAsNum.toFixed(valAsNum % 1 === 0 ? 0 : accuracy))
                        displayValue = Number(valAsNum.toFixed(valAsNum % 1 === 0 ? 0 : accuracy))
                    }
                    
                    let unitStr = getUnitName(unit, this.props.baseUnit) ?? this.props.unitStr

                    let disp = this.state.displayValue !== undefined ? this.state.displayValue : displayValue

                    let placeholderValue = false;
                    if(this.props.showPlaceholder === true && 
                    this.state.displayValue === undefined)
                    {
                        disp = ""
                        placeholderValue = true
                    }

                    disp = getUnitToString(disp, unit, this.props.baseUnit)

                    if(this.props.textOnly)
                    {
                        return <span>{disp}</span>
                    }

                    
                    
                    if (this.props.readOnly && !this.props.label)
                    {
                        if(this.props.showUnit)
                        {
                            return disp + " "+ unitStr
                        }
                        return disp
                    }

                    return <Form.Input  label           =   {this.props.label}
                                        placeholder     =   {this.props.placeholder}
                                        disabled        =   {this.props.disabled}
                                        compact         =   {this.props.compact}
                                        inline          =   {this.props.inline}
                                        name            =   {this.props.name}
                                        size            =   {this.props.size}
                                        transparent     =   {transparent}
                                        readOnly        =   {this.props.readOnly}
                                        onClick         =   {() => { this.setState({currentlyEditing: true}) }}
                                        fluid 
                                        labelPosition   =   {unitStr ? (this.props.labelPosition ?? "right") : undefined}
                                        value           =   {disp}
                                        onChange        =   {(e, {value}) => {this.onChange(e, value, unit, displayValue + diff, min, max) }}
                                        onBlur          =   {(e) => {if (!placeholderValue) {this.onBlur(e)}}}
                                        onKeyDown       =   {this.onKeyDown}>
                                            { (!this.props.readOnly && !this.props.noStepper) && isNum(String(this.state.displayValue ?? displayValue)) &&  
                                                <div>
                                                    <Icon   onClick = {() => {this.onStepUp(diff, unit, displayValue, step, max)}} 
                                                            onMouseDown = {() =>  this.startStepUp(diff, unit, step, max)}
                                                            onMouseUp   = {() => this.stopSteping()}
                                                            size    = "large"
                                                            style   = {{display: "block"}} 
                                                            name    = "angle up"></Icon>
                                                    <Icon   onClick = {() => {this.onStepDown(diff, unit, displayValue, step, min)}} 
                                                            onMouseDown = {() =>  this.startStepDown(diff, unit, step, min)}
                                                            onMouseUp   = {() => this.stopSteping()}
                                                            size    = "large"
                                                            style   = {{display: "block"}} 
                                                            name    = "angle down"></Icon>
                                                </div>}
                                            <input id={this.props.LRIDFIELD} style={{backgroundColor: this.props.presetColor ?? "", ...this.props.style}} />
                                            {this.renderLabel(unitStr, this.props.baseUnit )}
                        {this.props.children}
                    </Form.Input>
                }
        }            
    }

    renderLabel(unitStr, baseUnit)
    {
        let settings        = this.props.globalSettings
        let baseUnitOptions = getUnitOptions(baseUnit, settings)
        let settingName     = getSettingName(baseUnit, settings)
        let setting         = settings[settingName]

        let updateEnumBaseUnit = (name, value) => 
        {
            let newSetting = {
              [settingName]: {
                ...settings[settingName],
                [name]: value
              }
            }

            window.LR_SetGlobalSettings(newSetting);     
        };

        if(this.props.customLabel)
        {
            return <Label>{this.props.customLabel}</Label>
        }

        if(!unitStr)
        {
            return null
        }

        

        if(!this.props.forceUnitString)
        {
            if(!(this.props.label ?? true))
            {
                return null
            }

        }

        if(Array.isArray(baseUnitOptions))
        {
            let enumBaseUnitColor = this.props.labelColor;

            if(this.props.labelColor === "blue")
            {
                enumBaseUnitColor = "#2185d0"

            }else if(this.props.labelColor === "red")
            {
                enumBaseUnitColor = "#db2828"

            }else if(this.props.labelColor === "green")
            {
                enumBaseUnitColor = "#21ba45"
            }

            if(!enumBaseUnitColor)
            {
                enumBaseUnitColor = "grey"
            }
        
            return <Dropdown
                            icon     = ""
                            style    = {{backgroundColor: enumBaseUnitColor, color: "#fff", width:"1.5rem", fontSize:"0.8rem", paddingLeft:"0.3rem", display:"flex", alignItems: "center", borderBottomRightRadius: "0.3rem", borderTopRightRadius: "0.3rem"  }}  
                            options  = {baseUnitOptions}
                            value    = {setting.Value}
                            onChange = {(event, {value}) => {updateEnumBaseUnit("Value", value)}}>
                    </Dropdown>
        }

        const labelStyle = {
                            transform: "scale(1.1)",
                            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            cursor: "pointer" } 
    
        return <Label 
                style={{ ...((this.state.hover && this.props.labelClicked) && labelStyle)}}  
                color= {this.props.labelColor} 
                onClick = {this.props.labelClicked} 
                onMouseEnter = {()=> this.setState({hover:true})} 
                onMouseLeave = {()=> this.setState({hover:false})} 
                >
                    {unitStr}
                </Label>
    }
                
    onStepUp = (diff, unit, displayValue, step, max) => 
    {
        let newVal = Number(displayValue) + diff
        newVal = getCoreValueFromUnit(newVal, unit, this.props.baseUnit)
        newVal +=  Number(step)
        
        newVal = newVal > max ? max : newVal
        
        if (this.props.onStateUpdate) { this.props.onStateUpdate(this.props.name, newVal); } 
        if (this.props.onUpdateData) { this.props.onUpdateData(this.props.name, newVal); }
    }

    onStepDown = (diff, unit, displayValue, step, min) => 
    {
        let newVal = Number(displayValue) + diff
        newVal = getCoreValueFromUnit(newVal, unit, this.props.baseUnit)
        newVal -=  Number(step)
        newVal = newVal < min ? min : newVal
        
        if (this.props.onStateUpdate) { this.props.onStateUpdate(this.props.name, newVal); } 
        if (this.props.onUpdateData) { this.props.onUpdateData(this.props.name, newVal); }
    }

    startStepUp = (diff, unit, step, max) =>
    {
        if (this.intervalRef.current) return;

        this.intervalRef.current = setInterval(() => {  
        
            let dv = getUnitValueFromCore((this.props.value ?? 0), unit, this.props.baseUnit);
                        
            this.onStepUp(diff, unit, dv, step, max)

        }, 500);
    }

    startStepDown = (diff, unit, step, min) =>
    {
        if (this.intervalRef.current) return;

        this.intervalRef.current = setInterval(() => {    
            let dv = getUnitValueFromCore((this.props.value ?? 0), unit, this.props.baseUnit);           
            this.onStepDown(diff, unit, dv, step, min)

        }, 500);
     }

    stopSteping = () =>
    {
        if (this.intervalRef.current) {
            clearInterval(this.intervalRef.current);
            this.intervalRef.current = null;
        }
    }

    convertFtinInputToCoreNum = (input) =>
    {
        input = input.replace(Feet_Inch_Regex , (match, ...arr) => 
        {
            let coreNum;
            let feets = (arr[0] ?? arr[3]) ?? 0; 
            let inches =( arr[1] ?? arr[4]) ?? 0;
            let fraction = (arr[2] ?? arr[5]) ?? 0;

            let feetNum     = extractNumber(feets);
            let inchesNum   = extractNumber(inches);
            let fractionNum = eval(fraction); 

            if(feets && !inches && fraction)
            {
                let totalFeets = feetNum + fractionNum; 
                coreNum = getCoreValueFromUnit(totalFeets, UNIT_Feet, BASE_UNIT_LENGTH);
            }
            else if (!feets && inches && fraction)
            {
                let totalInches = inchesNum + fractionNum;
                coreNum = getCoreValueFromUnit(totalInches, UNIT_Inches, BASE_UNIT_LENGTH); 
            }else{
                coreNum = getCoreValueFromUnit(feetNum, UNIT_Feet, BASE_UNIT_LENGTH) + getCoreValueFromUnit((inchesNum + fractionNum), UNIT_Inches, BASE_UNIT_LENGTH);
            }

            return String(coreNum) + "mm"; 
        })

        return input
    }

    onKeyDown = (e) =>
    {
        const globalSettings = this.props.globalSettings
        const settingsUnit = getUnit(this.props.baseUnit, globalSettings)


        let coreValue = this.state.displayValue ?? this.props.value
        if (!isNum(coreValue))
        {
            // substitute all values in equation, that reference a unit
            const substituedEquation = this.substituteAllUnitValues(settingsUnit)
    
            // calculate final value from equation with substituted values
            const eq = mathInput(substituedEquation, coreValue)
            coreValue = eq.result
        }
        coreValue = getCoreValueFromUnit(coreValue, settingsUnit, this.props.baseUnit)

        if (this.props.onKeyDown) { this.props.onKeyDown(this.props.name, coreValue, e) }
        if (e.keyCode === 13) 
        { 
            e.preventDefault();
            e.stopPropagation();

            if(this.props.baseUnit === BASE_UNIT_LENGTH && Feet_Inch_Regex.test(e.target.value))
            {
                let inputValue = e.target.value;
                inputValue = this.convertFtinInputToCoreNum(inputValue);
             
                // substitute all values in equation, that reference a unit
                const substituedEquation = this.substituteAllUnitValues(settingsUnit, inputValue)
        
                // calculate final value from equation with substituted values
                const eq = mathInput(substituedEquation, inputValue)
                inputValue = eq.result

                coreValue = getCoreValueFromUnit(inputValue, settingsUnit, this.props.baseUnit)
            }

            if (this.props.onUpdateData)
            {
                this.props.onUpdateData(this.props.name, coreValue, e); 
            }
            this.setState({displayValue: undefined})
        }
    }

    onBlur = (e) =>
    {
        const globalSettings = this.props.globalSettings
        const settingsUnit = getUnit(this.props.baseUnit, globalSettings)
                 
        let numberValue = this.state.displayValue ? this.state.displayValue : this.props.value
   
        if(this.props.baseUnit === BASE_UNIT_LENGTH && Feet_Inch_Regex.test(numberValue) )
        {
            numberValue = this.convertFtinInputToCoreNum(numberValue)
        }

       if (!isNum(numberValue))
        {
            // substitute all values in equation, that reference a unit
            const substituedEquation = this.substituteAllUnitValues(settingsUnit, numberValue)
    
            // calculate final value from equation with substituted values
            const eq = mathInput(substituedEquation, numberValue)
            numberValue = eq.result
        }

        let updateValue = numberValue

        if (this.state.displayValue ) // Only want to convert display value. props value should be stored in correct unit
        {
            updateValue = getCoreValueFromUnit(numberValue, settingsUnit, this.props.baseUnit) 
        }

        this.setState({displayValue: undefined, currentlyEditing: false})
        if (this.props.onUpdateData) { this.props.onUpdateData(this.props.name, updateValue, e) }
    }

    onBlurString = (e) =>
    {
        let value = this.state.displayValue ? this.state.displayValue : this.props.value
        this.setState({displayValue: undefined, currentlyEditing: false})
        if (this.props.onUpdateData) { this.props.onUpdateData(this.props.name, value, e) }
    }

    onKeyDownString = (e) =>
    {
        let value = this.state.displayValue ? this.state.displayValue : this.props.value

        if (this.props.onKeyDown) { this.props.onKeyDown(this.props.name, value, e) }
        if (e.keyCode === 13) 
        { 

            e.preventDefault();
            e.stopPropagation();
            if (this.props.onUpdateData)
            {
                this.props.onUpdateData(this.props.name, value, e); 
            }
            this.setState({displayValue: undefined})
        }
    }

    // substitutes all all values + unit with the converted value
    substituteAllUnitValues = (settingsUnit, numberValue = undefined) => {
        let checkEquation = numberValue ?? (this.state.displayValue ?? "") // left to check 
        let subsitutedEquation = ""                       // final result
        let wasNan = false                                // whether the previous substring was NaN

        for(let i=1; i <= checkEquation.length; i++)
        {
            let currentSubStr = checkEquation.slice(0, i) // look at an increasing substring
            const isNan = isNaN(currentSubStr[i-1]) && isNaN(currentSubStr) && !["+", "-", "*", "/"].includes(currentSubStr[i-1])
            if (wasNan && isNan)
                continue // i.e. from 'm' on, see if there is 'mm' -> match the longest possible unit 

            else if (!wasNan && isNan)
                wasNan = isNan

            else if (wasNan && !isNan)
            {
                subsitutedEquation += this.detectUnit(currentSubStr.slice(0, i-1), settingsUnit) // check if there is i.e. '2m' and subsitute it for the desired value i.e. "2000" (if the input is in 'mm') 

                // set for next iteration
                checkEquation = checkEquation.slice(i-1)
                wasNan = false
                i = 0
            }
        }
        
        // add the missing part
        if (checkEquation !== "")
            subsitutedEquation += this.detectUnit(checkEquation, settingsUnit)

        return subsitutedEquation
    }

    // if the end of the string matches a unit, convert the number in front to the desired unit 
    // and return the value str with the substituted value 
    // i.e. "2m" to "2000" if input unit is 'mm'
    detectUnit = (valueStr, settingsUnit) => 
    {
        // the unit given in the string would have to be of the same kind as the input
        let baseUnitObj = UNITS_SORTED[this.props.baseUnit]
        baseUnitObj = baseUnitObj.referTo ? UNITS_SORTED[baseUnitObj.referTo] : baseUnitObj

        // loop over possible units
        for (const unit of baseUnitObj.units)
        {
            // check if the last part of the string matches a unit
            const unitStart = valueStr.length - unit.str.length
            
            let matches
            if( ! isNaN(unitStart))
            {
                const possibleMatch = valueStr.slice(unitStart)
                matches = possibleMatch === unit.str
            }

            if (matches)
            {
                // find the longest number before the unit
                let longestNum = ""
                for (let i=1;i<=unitStart;i++)
                {
                    let num = valueStr.slice(unitStart - i, unitStart)
                    if (isNaN(num) || ["+", "-"].includes(num[0]))
                        break;
                    longestNum = num
                }
                
                // convert the number to the desired unit
                const coreNum      = getCoreValueFromUnit(Number(longestNum), unit.id, this.props.baseUnit)
                const convertedNum = getUnitValueFromCore(coreNum, settingsUnit, this.props.baseUnit)
                
                // construct the new string
                let longestNumStart = unitStart - longestNum.length
                const untilLongestNum = valueStr.slice(0, longestNumStart)
                const newStr = untilLongestNum + String(convertedNum)

                return newStr
            }
        }
        return valueStr
    }

    onChange = (e, value, unit, oldValue, min, max) =>
    {   
        value = value.replace(",", ".")
        
        //value = value === "" ? "0" : value

        this.setState({displayValue: value})

        let eq = mathInput(value === "" ? "0" : value, oldValue)
        
        value = getCoreValueFromUnit(Number(eq.result), unit, this.props.baseUnit) 

        value = value < min ? min : value
        value = value > max ? max : value
        
        if (this.props.onStateUpdate) { this.props.onStateUpdate(this.props.name, value, e); } 
    }

    onChangeString = (e, value) =>
    {
        this.setState({displayValue: value})
        if (this.props.onStateUpdate) { this.props.onStateUpdate(this.props.name, value, e); } 

    }
}

export function getUnitOptions(baseUnit,settings)
{
    if (!settings) { return ""; }
    switch(baseUnit)
    {
        case BASE_UNIT_LENGTH:              return settings.App_DisplayUnit_Length.Options
        case BASE_UNIT_PAGE_LENGTH:         return settings.App_DisplayUnit_PageLength.Options
        case BASE_UNIT_WEIGHT:              return settings.App_DisplayUnit_Weight.Options
        case BASE_UNIT_FORCE:               return settings.App_DisplayUnit_Force.Options
        case BASE_UNIT_WEIGHT_PER_DISTANCE: return settings.App_DisplayUnit_WeightPerDistance.Options
        case BASE_UNIT_VOLUME:              return settings.App_DisplayUnit_Volume.Options
        case BASE_UNIT_AREA:                return settings.App_DisplayUnit_Area.Options
        case BASE_UNIT_TORQUE:              return settings.App_DisplayUnit_Moment.Options
        case BASE_UNIT_FORCE_PER_DISTANCE:  return settings.App_DisplayUnit_ForcePerDist.Options
        case BASE_UNIT_MOMENT_PER_DISTANCE: return settings.App_DisplayUnit_MomentPerDegree.Options
        case BASE_UNIT_FORCE_PER_AREA  :    return settings.App_DisplayUnit_ForcePerArea.Options
        case BASE_UNIT_VELOCITY  :    return settings.App_DisplayUnit_Velocity.Options
        case BASE_UNIT_ZERO_ONE :           return settings.App_DisplayUnit_ZeroOne.Options
        case BASE_UNIT_POWER :              return settings.App_DisplayUnit_Power.Options
        case BASE_UNIT_TIME_CODE :          return settings.App_DisplayUnit_TimeCode.Options
        default: return ""
    }
}

export function getSettingName(baseUnit, settings)
{
    if (!settings) { return ""; }
    switch(baseUnit)
    {
        case BASE_UNIT_LENGTH:              return "App_DisplayUnit_Length"
        case BASE_UNIT_PAGE_LENGTH:         return "App_DisplayUnit_PageLength"
        case BASE_UNIT_WEIGHT:              return "App_DisplayUnit_Weight"
        case BASE_UNIT_FORCE:               return "App_DisplayUnit_Force"
        case BASE_UNIT_WEIGHT_PER_DISTANCE: return "App_DisplayUnit_WeightPerDistance"
        case BASE_UNIT_VOLUME:              return "App_DisplayUnit_Volume"
        case BASE_UNIT_AREA:                return "App_DisplayUnit_Area"
        case BASE_UNIT_TORQUE:              return "App_DisplayUnit_Moment"
        case BASE_UNIT_FORCE_PER_DISTANCE:  return "App_DisplayUnit_ForcePerDist"
        case BASE_UNIT_MOMENT_PER_DISTANCE: return "App_DisplayUnit_MomentPerDegree"
        case BASE_UNIT_FORCE_PER_AREA:      return "App_DisplayUnit_ForcePerArea"
        case BASE_UNIT_VELOCITY:            return "App_DisplayUnit_Velocity"
        case BASE_UNIT_ZERO_ONE:            return "App_DisplayUnit_ZeroOne"
        case BASE_UNIT_POWER:               return "App_DisplayUnit_Power"
        case BASE_UNIT_TIME_CODE:           return "App_DisplayUnit_TimeCode"
        default: return ""
    }
}

export function getUnit(baseUnit,settings)
{
    if (!settings) { return 0; }
    switch(baseUnit)
    {
        case BASE_UNIT_LENGTH: return settings.App_DisplayUnit_Length.Value
        case BASE_UNIT_PAGE_LENGTH: return settings.App_DisplayUnit_PageLength.Value
        case BASE_UNIT_WEIGHT: return settings.App_DisplayUnit_Weight.Value
        case BASE_UNIT_FORCE: return settings.App_DisplayUnit_Force.Value
        case BASE_UNIT_WEIGHT_PER_DISTANCE: return settings.App_DisplayUnit_WeightPerDistance.Value
        case BASE_UNIT_VOLUME: return settings.App_DisplayUnit_Volume.Value
        case BASE_UNIT_AREA  : return settings.App_DisplayUnit_Area.Value
        case BASE_UNIT_TORQUE  : return settings.App_DisplayUnit_Moment.Value
        case BASE_UNIT_FORCE_PER_DISTANCE  :  return settings.App_DisplayUnit_ForcePerDist.Value
        case BASE_UNIT_MOMENT_PER_DISTANCE  : return settings.App_DisplayUnit_MomentPerDegree.Value
        case BASE_UNIT_FORCE_PER_AREA  : return settings.App_DisplayUnit_ForcePerArea.Value
        case BASE_UNIT_VELOCITY  : return settings.App_DisplayUnit_Velocity.Value
        case BASE_UNIT_ZERO_ONE :             return settings.App_DisplayUnit_ZeroOne.Value
        case BASE_UNIT_POWER :                return settings.App_DisplayUnit_Power.Value
        case BASE_UNIT_TIME_CODE :                return settings.App_DisplayUnit_TimeCode.Value
        default: return 0
    }
}

function formatDateToText(dateISO, DateFormat, TimeFormat, lang = "en")
{
    if(!dateISO?.length) {return ""}
    return formatDateToDateOnly(dateISO, DateFormat, lang) + " " + formatDateToTimeOnly(dateISO, TimeFormat, lang)
}

export function formatDateToDateOnly(dateISO, DateFormat, lang = "en" /*can be a number (-> enum) as well*/){
    if(!dateISO?.length) {return ""}
    if(typeof lang === "number"){
        lang = AppLanguageToString(lang)
    }
    try
    {
        return dayjs(dateISO).locale(lang).format(DateFormat)
    }
    catch(e)
    {
        console.error(e)
        return ""
    }
}

export function formatDateToTimeOnly(dateISO, TimeFormat, lang = "en" /*can be a number (-> enum) as well*/){
    if(!dateISO.length) {return ""}
    
    if(typeof lang === "number"){
        lang = AppLanguageToString(lang)
    }
    
    try
    {
        return dayjs(dateISO).locale(lang).format(TimeFormat)
    }
    catch(e)
    {
        console.error(e)
        return ""
    }
}

let mathInput = (value, oldValue) =>
{
    let result = {
        ok: true,
        value: value
    }
    try
    {
        result.result = Parser.parse(value).evaluate()
    }
    catch(e)
    {
        if(value)
        {
            result.ok     = false
            result.result = oldValue
        }
        
    }

    return result
}

export function getUnitValueFromCore(value, unit, baseUnit)
{
    const unitObj = getUnitObj(unit, baseUnit)
    return unitObj && unitObj.conversion_rate ? unitObj.conversion_operation.down(value, unitObj.conversion_rate) : value
}

export function getCoreValueFromUnit(value, unit, baseUnit)
{
    const unitObj = getUnitObj(unit, baseUnit)
    return unitObj && unitObj.conversion_rate ? unitObj.conversion_operation.up(value, unitObj.conversion_rate) : value
}

export function getUnitName(unit, baseUnit)
{
    const unitObj = getUnitObj(unit, baseUnit)
    return unitObj ? unitObj.str : undefined
}

export function getUnitToString(value, unit, baseUnit)
{
    if(baseUnit === BASE_UNIT_LENGTH && unit === UNIT_FeetInches)
    {
        const unitObj = getUnitObj(unit, baseUnit)
        value = unitObj.conversion_operation.toString(value) 
    }
    return value
}

export function getUnitFromString(value, unit, baseUnit)
{
    if(baseUnit === BASE_UNIT_LENGTH && unit === UNIT_FeetInches)
    {
        const unitObj = getUnitObj(unit, baseUnit)
        value = unitObj.conversion_operation.fromString(value) 
    }
    return value
}

let getUnitObj = (unit, baseUnit) => {
    if (!baseUnit)
        return undefined

    const baseUnitObj = UNITS[baseUnit].referTo ? UNITS[UNITS[baseUnit].referTo] : UNITS[baseUnit]
    const unitObj = baseUnitObj.units.find(u => unit === u.id)
    return unitObj
}



//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      globalSettings: state[GLOBAL_SETTINGS].data.GlobalSettings,
    };
}

let componentExport;
if(!process.env.JEST_WORKER_ID) { componentExport = connect(mapStateToProps)(UnitInput) }
else                                         { componentExport = UnitInput }

export default componentExport 