//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------

import React, { Component } from 'react';
import { Button, Popup } from 'semantic-ui-react';
const ReactMarkdown = React.lazy(()=>import("react-markdown"))

import LocalizedStrings from "../../localization/ToolBarButton"

//---------------------------------------------------------------------
// LightRightRouter
class ToolBarToolTip extends Component 
{
  constructor() 
  {
    super();
    this.state = {
      closed: false
    };
  }
  

  render ()
  {
    let enterDelay = this.props.popupOpen ? 100 : 1600;
    let leaveDelay = this.props.popupOpen ? 100 : 1600;
    if(LocalizedStrings[this.props.value] === undefined)
    {
        console.error("ToolBarButton not found", this.props.value)
    }
    if(this.state.closed)
    {
      return this.props.children
    }


    return (
      <>
      
      <Popup 
      onClose={()=>this.props.onPopup(false)}
      onOpen={()=>this.props.onPopup(true)}
      mouseEnterDelay={enterDelay} mouseLeaveDelay={leaveDelay} 
      on='hover' 
      trigger={this.props.children} hoverable>
        <Button fluid negative onClick={()=>this.setState({closed:true})}>{LocalizedStrings.HideToolTip}</Button>
        
          {
            this.props.VIDEO ? 
            <video style={{width:"30rem",}}  preload="true" autoPlay="1" loop muted  webkit-playsinline="true" playsinline="true" scrolling="false">
                <source src={this.props.VIDEO} type="video/mp4" />
          </video> : null
          }
              
        <ReactMarkdown>{LocalizedStrings[this.props.value]}</ReactMarkdown>
        <Button fluid positive onClick={()=>window.LR_OpenLink({UseBaseUrl: true,  URL: "/documentation/toolbar#"+this.props.value})} >{LocalizedStrings.OpenDocumentation}</Button>
      </Popup>
      </>
  );
}


  }

export default ToolBarToolTip;
