//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import LRModal from '../Basics/BasicModal';
import { globalCallbacks } from '../../util/callback';
import { Form } from 'semantic-ui-react';
import LocalizedStrings from '../../localization/MoveToAssemblyGroupModal';


class MoveToAssemblyGroupModal extends Component
{
    constructor(props)
    {
        super(props)

        this.state = {
            open: false,
            AssemblyGroupOptions: [],
            SelectedAssemblyGroup: undefined,
        };
    }

    componentDidMount()
    {
        this.setUpCallbacks();
    }

    render()
    {
        return (<>
            <LRModal    open={this.state.open}
                        title={LocalizedStrings.Header}
                        onOkClick={this.onOkClick}
                        scrolling={false}
                        onCancelClick={this.close}>
                <Form>
                    <Form.Select    options={this.state.AssemblyGroupOptions}
                                    value={this.state.SelectedAssemblyGroup}
                                    label={LocalizedStrings.AssemblyGroup}
                                    search
                                    onChange={(e, {value}) => {this.setState({SelectedAssemblyGroup: value})}}/>
                </Form>
            </LRModal>
        </>)
    }

    onOkClick = () =>
    {
        window.LR_MoveToAssemblyGroup({UUID: this.state.SelectedAssemblyGroup})
        this.close()
    }

    close = () =>
    {
        this.setState({open: false})
    }

    open = () =>
    {
        this.setState({open: true})
    }

    setUpCallbacks()
    {
        globalCallbacks.showMoveToAssemblyGroup = () =>
        {
            window.LR_GetAssemblyGroups().then(res => {
                if (res.AssemblyGroups)
                {
                    let options = res.AssemblyGroups.map(group => {
                        return {
                            key: group.UUID,
                            text: group.Name,
                            value: group.UUID
                        }
                    });
                    options.sort((a,b)=>{ return a.text < b.text})

                    this.setState({AssemblyGroupOptions: options})
                }
            })
            this.open();
        }
    }
}

export default MoveToAssemblyGroupModal;