import React, { Component } from 'react';
import { Grid, List, Button, Form, Header, Container, Icon, Loader, Dimmer, Segment} from 'semantic-ui-react';
import { lrServerConnection } from '../../redux/light_right_server_connection';
import LRModal from '../Basics/BasicModal';
import LocalizedStrings from "../../localization/AdminResourceManager";
import { connect } from 'react-redux';
import { ACTIVE_USER} from "./../../redux/redux_defines"
import { hasFetched,  } from "../../redux/actions/fetch_actions";
import BasicSelect from '../Basics/BasicSelect';
import { AddNewResourceModal } from './addModal';

let allowDrop = (e) => {
  e.preventDefault()
}

class CategoryMenu extends Component {

  headerStyle = {
    marginLeft:'0.5em'
  }
  headerStyleActive = {
    ...this.headerStyle,
    backgroundColor: 'rgba(0,0,0,0.1)'
  }
  contentStyle = {
    borderLeftStyle:'solid',
    borderWidth: 1,
    marginLeft: '0.3em'
  }
  listStyle = {
    paddingTop: '0.1em'
  }

  onDrop = (e) => {
    let type = e.dataTransfer.getData("type")
    let ident = e.dataTransfer.getData("ident")
    if(type === "category") {
        lrServerConnection.moveCategory(ident, this.props.category.identifier, this.props.selectedUserForRes)
        .then((res) => this.props.reFetch())
      
    } else if (type === "resource") {
        lrServerConnection.moveResource(ident, this.props.category.identifier, this.props.selectedUserForRes)
        .then((res) => this.props.reFetch())
    }
  }

  onDrag = (ident) => (e) => {
    e.dataTransfer.setData("type", "category")
    e.dataTransfer.setData("ident", ident)
  }

  render() {
    let {category, active, setActive} = this.props

    let hStyle = (category && active === category.identifier) || (!category && active === undefined) ? {backgroundColor: 'rgba(0,0,0,0.1)'} : undefined

    if(!category.identifier) return <div style={hStyle}>
      <Header onClick={()=>setActive()}
              onDrop={this.onDrop}
              onDragOver={allowDrop}>{category.name}{this.renderButtons()}</Header>
      <List celled>
        {this.renderChildren()}
      </List>
    </div>

    return <List.Content>
      <List.Header draggable 
                   onClick={this.onClick}
                   onDrop={this.onDrop}
                   onDragStart={this.onDrag(category.identifier)}
                   onDragOver={allowDrop}>{category.name}{this.renderButtons()}</List.Header>
      {category.subCategories.length > 0 ? <List.List style={this.listStyle} celled>
        {this.renderChildren()}
      </List.List> : null}
    </List.Content>
  }

  onAddClick = () => {
    this.props.setActive(this.props.category ? this.props.category.identifier : undefined)
    this.props.openAddCategory()
  }

  onDelClick = () => {
    this.props.setActive(this.props.category ? this.props.category.identifier : undefined)
    this.props.openDelCat()
  }

  renderButtons() {
    return <div style={{float: 'right'}}>
      <div  style={{display: 'inline', paddingLeft: '0.3em', paddingRight: '0.3em', backgroundColor: 'rgba(0,255,0,0.2)' }}
            onClick={this.onAddClick}>
        <b>+</b>
      </div>
      {this.props.category ?
      <div  style={{display: 'inline', paddingLeft: '0.3em', paddingRight: '0.3em', backgroundColor: 'rgba(255,0,0,0.2)' }}
            onClick={this.onDelClick}><b>x</b></div> : 
      <div style={{display: 'inline', paddingLeft: '0.3em', paddingRight: '0.3em', color: 'rgba(0,0,0,0)' }}><b>x</b></div>}
    </div>
  } 

  onClick = () => {
    this.props.setActive(this.props.category.identifier)
  }

  renderChildren() {
    let subcats = this.props.category.subCategories
    return subcats.map((cat, i) => <List.Item key={i} style={cat.identifier === this.props.active ? this.headerStyleActive : this.headerStyle}>
      <CategoryMenu category={cat}
                    selectedUserForRes={this.props.selectedUserForRes}
                    activeUser={this.props.activeUser}
                    active={this.props.active} 
                    setActive={this.props.setActive} 
                    openAddCategory={this.props.openAddCategory}
                    openDelCat={this.props.openDelCat}
                    reFetch={this.props.reFetch}/>
    </List.Item>)
  }
}

class AdminResourceManager extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeCategory: undefined,
      activeResource: undefined,
      categories: [],
      selectedUserForRes:"",
      Organizations: [],
      loggedInUser:"",
      fetched: false,
      addModal: false,
      uploading: false,
      categoryModal: false,
      newCategoryName: "",
      deleteOpen: false,
      delCatOpen: false,
      editCatOpen: false,
      editCatName: "",
      editRes: {
        open: false,
        name: "",
        desc: ""
      },
      revision: {
        open: false,
        text: "",
        file: undefined
      }
    }
  }

  getCategories = (cat) =>
  {
    this.CATEGORIES[cat._id] = cat.name
    cat.subCategories.forEach(this.getCategories)
  }

  componentDidMount = async () =>
  {
    let orgs = await  window.LR_GetOrganizationsAsync()
    this.setState({Organizations: orgs})

  }

  render() {
    let {activeCategory, fetched, activeResource, categories} = this.state

    let resources = this.getResources()

    if(!fetched) this.fetchResources()

    let currentResource = resources.find(res => res.identifier === activeResource)

    let allCategory = {
      name: "All",
      subCategories: categories
    }

    this.CATEGORIES = {}
    categories.forEach(this.getCategories)

    let category = this.getActiveCategory()

    let categoryOptions = 
                [
                  {key:"Shared Resources", text: LocalizedStrings.SharedResources, value:""},
                  ...this.state.Organizations.map(o => {return {key:o._id, text: o.username, value:o.username}})
                ]

    if(hasFetched( this.props.activeUser ))
    {
      categoryOptions.push( {key:this.props.activeUser.data.username,   text: this.props.activeUser.data.username,   value:this.props.activeUser.data.username  })
    }

    if(!fetched)
    {
      return<Segment style={{height:"100vh" }} secondary>
      <Dimmer active >
        <Loader active size="big" >{LocalizedStrings.Loading} </Loader>
      </Dimmer>
    </Segment>
    }

   
    return <Grid>
      <Grid.Column width={4}>
        <BasicSelect
          normalDropdown
          style={{marginBottom:"1.5rem"}}
          button
          className='icon'
          floating
          labeled
          fluid
          icon='user'
          options={categoryOptions}
          search
          placeholder='Shared Resources'
          value={this.state.selectedUserForRes}
          onStateUpdate={(name, value, e) => {this.setState({selectedUserForRes: value, fetched:false})}}
        />     
        <CategoryMenu active={activeCategory} 
                      selectedUserForRes={this.state.selectedUserForRes}
                      activeUser={this.state.selectedUserForRes}
                      setActive={this.setActiveCategory}
                      openAddCategory={this.openAddCategory}
                      category={allCategory}
                      openDelCat={this.openDelCat}
                      reFetch={()=>this.setState({fetched: false})}/>
      </Grid.Column>
      <Grid.Column width={6}>
        <Header>{activeCategory ? category?.name : "All"}{activeCategory ? <Icon name="edit" color="blue" style={{float: 'right'}} size="mini" onClick={this.openEditCat}/> : null}</Header>
        <List celled>
          {resources.map(this.getResourceItem)}
          {fetched ? <List.Item>
            <Button disabled={activeCategory === undefined}
                    onClick={this.openAdd}>
              Add Resource
            </Button>
          </List.Item> : null}
        </List>
      </Grid.Column>
      <Grid.Column width={6}>
        { currentResource ? <Header>{currentResource.name}{activeResource ? <Icon name="edit" color="blue" style={{float: 'right'}} size="mini" onClick={this.openEditRes}/> : null}</Header> : null}
        { currentResource ? <Container>
          {currentResource.description}
        </Container> : null}
        {
          currentResource ? <List celled>
            {currentResource.revisions.map(this.getRevisionItem)}
            <List.Item>
              <Button onClick={this.openRevision}>
                Add Revision
              </Button>
            </List.Item>
          </List> : null
        }
      </Grid.Column>
      {this.renderModal()}
      {this.renderAddCategory()}
      {this.renderDelete()}
      {this.renderDeleteCat()}
      {this.renderEditCat()}
      {this.renderEditRes()}
      {this.renderRevModal()}
    </Grid>
  }


  getResources = () => {

    let {activeCategory, categories} = this.state
    if(!Array.isArray(categories))
    {
      return []
    }
    let resources = []
    if(!activeCategory) {
      categories.forEach(cat => {
        resources.push(...this.getResourcesOf(cat))
      })
    } else {
      let category = this.getActiveCategory(categories)
      resources = this.getResourcesOf(category)
    }
    return resources
  }

  getResourcesOf = (category) => {
    let resources = [];
    if(category)
    {
      resources = [...category.files]
      category.subCategories.forEach(subCat => {
        resources.push(...this.getResourcesOf(subCat))
      })
    }

    return resources;
  }
  
  getActiveCategory = (categories) => {
    let {activeCategory, categories: cats} = this.state
    if(categories === undefined) { categories = cats; }
    let foundCat = undefined
    categories.every(cat => {
      if(cat.identifier === activeCategory) {
        foundCat = cat
        return false
      } else {
        foundCat = this.getActiveCategory(cat.subCategories)
        if(foundCat) {
          return false
        } else {
          return true
        }
      }
    })
    return foundCat
  }

  onDrag = (ident) => (e) => {
    e.dataTransfer.setData("type", "resource")
    e.dataTransfer.setData("ident", ident)
  }

  renderDelete = () => {
    let {deleteOpen, activeResource} = this.state
    return (
      <LRModal  open={deleteOpen}
                title={"Delete resource " + activeResource}
                onCancelClick={this.closeDeleteRes}
                onOkClick={this.okDeleteRes}>
          Are you sure?
      </LRModal>
    )
  }

  closeDeleteRes = () => this.setState({deleteOpen: false })

  openDeleteRes = (ident) => this.setState({deleteOpen: true, activeResource: ident})

  okDeleteRes = () => {
    let {activeResource} = this.state
    if(activeResource) 
    {
      lrServerConnection.deleteResource(activeResource, this.state.selectedUserForRes)
      .then(res => this.setState({fetched: false}))
    }
    this.closeDeleteRes()
  }
  
  getResourceItem = (resource) => {
    let {activeResource} = this.state
    return <List.Item onClick={()=>{this.setActiveResource(resource.identifier)}}
                      style={activeResource === resource.identifier ? {backgroundColor: 'rgba(0,0,0,0.1)'} : undefined}
                      draggable
                      onDragStart={this.onDrag(resource.identifier)}>
        <List.Content>
          <List.Header>
            {resource.name}
            <Icon name='delete' color='red' style={{float: 'right'}} onClick={this.openDeleteRes}/>
          </List.Header>
          <List.Description>{resource.description}</List.Description>
          <List.Description>{this.CATEGORIES[resource.category]}</List.Description>
        </List.Content>
      </List.Item>
  }

  getRevisionItem (revision) {
    return <List.Item>
      <List.Content>
        <List.Header>{revision.text}</List.Header>
        <List.Content>{new Date(revision.date).toLocaleString()}</List.Content>
      </List.Content>
    </List.Item>
  }

  renderModal = () => {
    return <AddNewResourceModal
    open={this.state.addModal}
    currentCategory={this.state.activeCategory}
    uploading={this.state.uploading}
    onCancelClick={this.closeAdd}
    onOkClick={this.okAdd}
    requiresFile
    />
  }

  openAddCategory = () => this.setState({ categoryModal: true})
  openAdd = () => this.setState({ addModal: true })
  closeAdd = () => this.setState({ addModal: false })

  okAdd = (data) => {
    let {activeCategory, selectedUserForRes} = this.state

    this.setState({uploading: true})
    lrServerConnection.addResource(activeCategory, data.name, data.manufacturer, data.description, data.file, selectedUserForRes)
    .then((res) => this.setState({
      fetched: false,
      addModal: false,
      uploading: false
    }))
  }


  fetchResources = async () => {
    let categories
    categories = await lrServerConnection.getResources(this.state.selectedUserForRes)
    if(!Array.isArray(categories))
    {
      categories = []
    }

    this.setState({
      fetched: true,
      categories,
    })
  }

  

  setActiveCategory = (ident) => {
    this.setState({
      activeCategory: ident,
    })
  }

  setActiveResource = (ident) => {
    this.setState({
      activeResource: ident
    })
  }

  changeAddCatName = (_, {value}) => {
    this.setState({
      newCategoryName: value
    })
  }

  closeAddCat = () => this.setState({categoryModal: false})

  okAddCat = () => {
    let {activeCategory, newCategoryName} = this.state
    if(!activeCategory)
    {
      lrServerConnection.addResourceCategory(newCategoryName, this.state.selectedUserForRes).then(res => this.setState({fetched: false}))
    }
    else 
    {            
      lrServerConnection.addResourceSubCategory(newCategoryName, activeCategory, this.state.selectedUserForRes ).then(res => this.setState({fetched: false}))
    }
    this.closeAddCat()
  }

  renderAddCategory = () => {
    let {categoryModal, newCategoryName} = this.state
    return (
      <LRModal  open={categoryModal}
                title="Add Category"
                onOkClick={this.okAddCat}
                onCancelClick={this.closeAddCat}>
        <Form>
          <Form.Input name="name" label="Name" value={newCategoryName} onChange={this.changeAddCatName}/>
        </Form>
      </LRModal>
    )
  }


  closeDelCat = () => this.setState({delCatOpen: false})
  
  openDelCat = () => {
    let active = this.getActiveCategory()
    if(!active || active.subCategories.length > 0  || active.files > 0) window.alert("Cannot delete non-empty Categories")
    else this.setState({delCatOpen: true})
  }

  okDelCat = () => {
    let {activeCategory} = this.state
    if(this.isSharedResourcesSelected())
    {
      lrServerConnection.deleteCategory(activeCategory).then(res => this.setState({fetched: false, activeCategory: undefined }))
    }else
    {
      lrServerConnection.deleteCategory(activeCategory, this.state.selectedUserForRes).then(res => this.setState({fetched: false, activeCategory: undefined }))
    }
    this.closeDelCat()
  }

  renderDeleteCat = () => {
    let {activeCategory, delCatOpen} = this.state 
    return (
      <LRModal  open={delCatOpen}
                title={"Delete category " + activeCategory}
                onOkClick={this.okDelCat}
                onCancelClick={this.closeDelCat}>
                Are you sure?
      </LRModal>
    )
  }

  openEditCat = () => {
    let category = this.getActiveCategory()
    this.setState({
      editCatOpen: true,
      editCatName: category.name
    })
  }

  closeEditCat = () => {
    this.setState({
      editCatOpen: false
    })
  }

  onChange = (_ ,{name, value}) => {
    this.setState({
      [name]: value
    })
  }

  okEditCat = () => {
    let {activeCategory, editCatName} = this.state
    this.closeEditCat()
    lrServerConnection.editCategory(activeCategory, editCatName, this.state.selectedUserForRes)
    .then(() => this.setState({fetched: false}))
  }

  renderEditCat = () => {
    let {editCatOpen, editCatName} = this.state
    return (
      <LRModal  open={editCatOpen}
                title="Edit Category"
                onOkClick={this.okEditCat}
                onCancelClick={this.closeEditCat}
                okDisabled={editCatName === ""}>
        <Form>
          <Form.Input label="Name" value={editCatName} name="editCatName" onChange={this.onChange}/>
        </Form>  
      </LRModal>
    )
  }

  onResChange = (_, {name, value}) => {
    this.setState({
      editRes: {
        ...this.state.editRes,
        [name]: value
      }
    })
  }

  closeEditRes = () => this.setState({
    editRes: {
      open: false
    }
  })

  okEditRes = () => {
    let {activeResource, editRes:{name, desc}} = this.state
    lrServerConnection.editResource(activeResource, name, desc, this.state.selectedUserForRes)
    .then(() => {
      this.closeEditRes()
      this.setState({fetched: false})
    })
    
  }

  openEditRes = () => {
    let { activeResource } = this.state
    let resources = this.getResources()


    let currentResource = resources.find(res => res.identifier === activeResource)

    this.setState({
      editRes: {
        open: true,
        name: currentResource.name,
        desc: currentResource.description
      }
    })
  }

  renderEditRes = () => {
    let {editRes:{open, name, desc}} = this.state
    return (
      <LRModal  open={open}
                title="Edit Resource"
                onOkClick={this.okEditRes}
                onCancelClick={this.closeEditRes}
                okDisabled={name === ""}>
        <Form>
          <Form.Input name="name" label="Name"        value={name} onChange={this.onResChange}/>
          <Form.Input name="desc" label="Description" value={desc} onChange={this.onResChange}/>
        </Form>
      </LRModal>
    )
  }

  readRevision = (e) => {
    let files = e.target.files;
    let fileToUpload = undefined
    for (let i in files) {
      let file = files[i]
      if(file.name.endsWith('.lrwx')) {
        fileToUpload = file
        break;
      }
    }
    
    this.setState({
      revision: {
        ...this.state.revision,
        file: fileToUpload
      }
    })
  }

  closeRevision = () => {
    this.setState({
      revision: {
        ...this.state.revision,
        open: false
      }
    })
  }

  okRevision = () => {
    let {revision:{file,text}, activeResource} = this.state
    if(file) {
      this.setState({uploading: true})
      lrServerConnection.addResourceRevision(activeResource, file, text, this.state.selectedUserForRes)
      .then((res) => this.setState({
        fetched: false,
        uploading: false
      }))
    
    }
    this.closeRevision()
  }

  openRevision = () => {
    this.setState({
      revision: {
        file: undefined,
        open: true,
        text: ""
      }
    })
  }

  changeRevText = (_, {value}) => {
    this.setState({
      revision: {
        ...this.state.revision,
        text: value
      }
    })
  }

  renderRevModal = () => {
    let {revision:{open,text}, activeResource, uploading} = this.state
    return (
      <LRModal  open={open}
                title={"Add Revision to " + activeResource}
                onOkClick={this.okRevision}
                onCancelClick={this.closeRevision}>
        <Dimmer active={uploading}>
          <Loader/>
        </Dimmer>
        <Form>
          <Form.Input name='text' label='Revision Text' value={text} onChange={this.changeRevText}/>
          <Form.Input name='file' type='file' onChange={this.readRevision}/>
        </Form>
      </LRModal>
    )
  }

  isSharedResourcesSelected = () => this.state.selectedUserForRes === "Shared Resources"
}


//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      activeUser: (state[ACTIVE_USER])
    };
}

export default connect(mapStateToProps)(AdminResourceManager)

