//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Form } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/AddNewLineloadDialog";
import LRModal from '../Basics/BasicModal';
import {  BASE_UNIT_FORCE, BASE_UNIT_LENGTH, BASE_UNIT_PERCENT } from '../../util/defines';
import UnitInput from '../Basics/BasicUnitInput';

import { globalCallbacks } from "../../util/callback";


//-----------------------------------------------------------------------------
// The component for the TreeControl
class AddNewPointLoadDialog extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
      X: 0,
      Y: 0,
      Z: 0,
      Height: 0,
      Percent: 50,
      GKS: true,
      ByHeight: false,
      Name: LocalizedStrings.LoadNameDefaultPointLoad,
    }
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }

  show = async() => 
  {
    this.setState( { 
        open : true, 
      });
  }

  close = () => 
  {
    this.setState({open : false});
  }

  add = () => 
  {
    window.LR_AddNewPointLoad({
      X: this.state.X,
      Y: this.state.Y,
      Z: this.state.Z,
      Name: this.state.Name,
      GKS: this.state.GKS,
      ByHeight: this.state.ByHeight,
      Percent: this.state.Percent,
      Height: this.state.Height
    });
    this.close()
  }
    
  render() 
  {
    let open = this.state.open;
    if(!open) {return <div/>}

    return (
      <LRModal  open={open} 
                onCancelClick={this.close} 
                closeOnDimmerClick={false} 
                size="small"
                scrolling={false}
                title={LocalizedStrings.HeaderPointLoad}
                onOkClick={this.add}>
        <Form>
          <Form.Input name='text' label={LocalizedStrings.LoadName} value={this.state.Name} onChange={(name, {value}) => { this.setState({Name:value}) }} />

          <Form.Group widths="equal">
            <UnitInput
                        fluid
                        label={LocalizedStrings.LoadX}
                        baseUnit      = {BASE_UNIT_FORCE}
                        value         = {this.state.X}
                        onStateUpdate = {(name, value) => { this.setState({X:value}) }} />
            <UnitInput
                        fluid
                        label={LocalizedStrings.LoadY}
                        baseUnit      = {BASE_UNIT_FORCE}
                        value         = {this.state.Y}
                        onStateUpdate = {(name, value) => { this.setState({Y:value}) }} />
                        <UnitInput
                        fluid
                        label={LocalizedStrings.LoadZ}
                        baseUnit      = {BASE_UNIT_FORCE}
                        value         = {this.state.Z}
                        onStateUpdate = {(name, value) => { this.setState({Z:value}) }} />
          </Form.Group>
          <Form.Checkbox name="LoadGKS" label={LocalizedStrings.LoadGKS} checked={this.state.GKS} onChange={(_, {checked}) => {this.setState({GKS:checked})}}/>
          <Form.Checkbox name="ByHeight" label={LocalizedStrings.ByHeight} checked={this.state.ByHeight} onChange={(_, {checked}) => {this.setState({ByHeight:checked})}}/>
          <UnitInput
                        fluid
                        label={LocalizedStrings.Height}
                        baseUnit      = {BASE_UNIT_LENGTH}
                        value         = {this.state.Height}
                        onStateUpdate = {(name, value) => { this.setState({Height:value}) }} />
                        <UnitInput
                        fluid
                        label={LocalizedStrings.Percent}
                        baseUnit      = {BASE_UNIT_PERCENT}
                        value         = {this.state.Percent}
                        onStateUpdate = {(name, value) => { this.setState({Percent:value}) }} />

          
        </Form>
           
      </LRModal>
    )
  }

  setUpCallbacks()
  {

    globalCallbacks.ShowAddPointLoadDialog = () => 
    { 
      this.show();
    }
    
  }
}

export default AddNewPointLoadDialog