//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2019 www.deersoft.de

//-----------------------------------------------------------------------------
let LocalizeOption = 
{
    // Output issues finding strings and references
    logsEnabled: true,
    // Helper for finding string that is implemented
    // @see Pseudo Helper for more info
    pseudo: false,
    // Helper for preparing multiple langauges
    // @see Pseudo Helper for more info
    pseudoMultipleLanguages: false,

    customLanguageInterface: () => 
    {
        let lang = window.localStorage.getItem("language")

        if(!lang)
        {
            lang = navigator.language
        }
        return lang;
    }
};

export default  LocalizeOption;