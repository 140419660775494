//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import { REQUESTED_CALCULATIONS, PROJECT_REVIEW_TEMPLATE, REVIEW, CUSTOMER_INFO, ACTIVE_CHECK_PROJECT, ACTIVE_USER, SET, FETCH, PROJECTS, CHANGES, ACTIVE_PROJECT, CLEAR, EDIT_MODE, COLLABORATORS, ACTIVE_CHANGE, SLACK_CHANNELS, GLOBAL_SETTINGS, MEMBERS, USER_REVIEW_TEMPLATES, GROUPS, ADMINS, API_TOKENS, SHARE_LINKS, RESET, ACTIVE_ADMIN, SHAREDLICENCE, MINI_PROJECTS, CONTENT_ADMINS, REQUESTED_CALCULATIONS_ALL} from "../redux_defines"

function getInit(init) 
{
  return ({
    data: init,
    isFetching: false,
    hasFetched: false
  })
}

//-----------------------------------------------------------------------------
// Initial State
const initialState = 
{
  [PROJECTS]          : getInit([]),
  [MINI_PROJECTS]     : getInit([]),
  [ACTIVE_USER]       : getInit(undefined),
  [ACTIVE_ADMIN]      : getInit(undefined),
  [CHANGES]           : getInit([]),
  [ACTIVE_PROJECT]    : getInit(undefined),
  [EDIT_MODE]         : getInit(false),
  [COLLABORATORS]     : getInit([]),
  [REQUESTED_CALCULATIONS]     : getInit([]),
  [REQUESTED_CALCULATIONS_ALL]     : getInit([]),
  [SHAREDLICENCE]     : getInit([]),
  [MEMBERS]           : getInit([]),
  [SLACK_CHANNELS]    : getInit([]),
  [ACTIVE_CHANGE]     : getInit(undefined),
  [GLOBAL_SETTINGS]   : getInit({}),
  [ACTIVE_CHECK_PROJECT]: getInit({}),
  [USER_REVIEW_TEMPLATES]: getInit([]),
  [PROJECT_REVIEW_TEMPLATE]: getInit({}),
  [REVIEW]            : getInit({}),
  [GROUPS]            : getInit([]),
  [CUSTOMER_INFO]     : getInit({}),
  [ADMINS]            : getInit([]),
  [CONTENT_ADMINS]    : getInit([]),
  [API_TOKENS]        : getInit([]),
  [SHARE_LINKS]       : getInit([])
}

let HandleStateChange = (state, action ) => {
  let valToChange = state[action.value]
  switch (action.type) {
    case FETCH:
      valToChange = {
        ...valToChange,
        isFetching: true,
        hasFetched: false
      }
      break;
      
    case SET:
      valToChange = {
        ...valToChange,
        isFetching: false,
        hasFetched: true,
        data: action.data
      }
      break;

    case CLEAR:
      valToChange = {
        ...valToChange,
        isFetching: false,
        hasFetched: false
      }
      break;
  
    default:
      break;
  }
  return {
    ...state,
    [action.value]: valToChange
  }
}

//-----------------------------------------------------------------------------
// Main Reducer
const MainReducer = (state = initialState, action) => 
{
  if(action.type === SET && action.value === ACTIVE_USER && action.data === undefined) return initialState
  if(action.type === RESET) return initialState
  return HandleStateChange( state, action )
}

export default MainReducer;

