
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Search, Table, Label, Icon, Segment, Dimmer, Loader} from 'semantic-ui-react'
import UserNode from "./UserNode";
import LocalizedStrings from "../../localization/NavigationContainer";
import escapeRegExp from "lodash/escapeRegExp"
import filter from "lodash/filter"
import { EMPTY_UUID, IsElectronContext } from '../../util/defines';

import { globalCallbacks as mainCB } from '../../util/callback';
import { globalCallbacks as mockCB } from '../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB


class UserTable extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = 
        {
            Users  : [],

            // search
            isLoading: false,
            results  : [], 
            value    : "",
            LoadingUser: false,
            Project: "",
            Owner: "",

            sorting: null,
            indexMap: [],
            DepartmentsOptions:[]
        };

    }

    componentDidMount = () => 
    {
        this.setUpCallbacks();
        globalCallbacks.getUserOverview();
    }

    componentWillUnmount = () => 
    {
        this.takeDownCallbacks();
    }

    renderLoader()
    {
        return(
        <Segment>
            <Dimmer active={true}><Loader active={true}/></Dimmer>
        </Segment>
        ) 
    }

    renderTable()
    {
        return(
        <div style={{width:"100%", height:"100%"}}>
             <Table style={{borderBottom: "none", margin:0}}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell colSpan='6' style={{zIndex: 0}}>
                            {<Icon link style={{floated: 'left', position: 'absolute',zIndex: 1}} onClick={this.onHeaderClick} name={this.state.sorting === null ? "sort" : this.state.sorting === "ascending" ? "sort alphabet up": "sort alphabet down"}/>}
                            <Search open    = {false}
                                    loading = {this.state.isLoading}
                                    value   = {this.state.value}
                                    onClick = {(e)=>{e.stopPropagation()}}
                                    onSearchChange = {(e, {value}) => this.onSearchChange(value, this.state.users)}
                                    aligned = "right"/>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
             </Table>

             <div style={{width:"100%", maxHeight:IsElectronContext() ? "calc(100vh - 25em)" :"calc(100vh - 30em)", overflowY:"auto", marginBottom:"5em"}}>
                <Table striped structured compact='very' size="small">
                    <Table.Body>
                        {this.renderRows()}
                    </Table.Body>
                </Table>
            </div>
        </div>
        )
    }

    onHeaderClick = () => {
        let newVal = null
        switch (this.state.sorting) {
            case null:          newVal = "ascending";   break;
            case "ascending":   newVal = "descending";  break;
            default:            newVal = null;          break;
        }
        this.setState({
            sorting: newVal
        })
    }

    renderRows = () => {

        let users = this.state.Users
        if(this.state.sorting) {
            let indexMap = []
            users.forEach((_, i) => indexMap.push(i))
            indexMap.sort((a,b) => users[a].Name === users[b].Name  ? 0 : (users[a].Name < users[b].Name  ? -1 : 1))
            users = indexMap.map((_, i) => {
                let calcIndex = this.state.sorting === 'ascending' ? i : indexMap.length - i - 1
                return users[indexMap[calcIndex]]
            })
        }

        return users.map((user) => {return <UserNode DepartmentsOptions={this.state.DepartmentsOptions} key={user.UUID} user={user} />})
    }

    renderAddUser(hasProject)
    {
        return(
            <Segment vertical textAlign="center" style={{position:"absolute", bottom:IsElectronContext() ? "1.5em" : "6em", width:"100%", border:"none"}}>
               
                <Label as="a" color="green" onClick={this.onAddNewUser}>
                    <Icon name="plus"/>{LocalizedStrings.AddNewUser}
                </Label>
                <Label as="a" color="blue" onClick={this.showInviteProjectDialog} >
                    <Icon name="plus"/>{LocalizedStrings.InvitePAUser}
                </Label>
            </Segment>
        )
    }

    onAddNewUser()
    {
        window.LR_AddNewUser()
    }

    render() 
    {

        let hasProject = this.state.Project !==""
        let isLoad = this.state.LoadingUser
        return (
            <div>
                { this.state.LoadingUser   ? this.renderLoader() : null}
                {!isLoad     ? this.renderTable() : null}
                {this.renderAddUser(hasProject)}
               
                
                
            </div>

        );
    }

    onSearchChange = (value, resources) => 
    {
      this.setState({isLoading: true, value: value})

        if(value.length < 1)
        {
          this.onSearchReset()
        }
        else
        {
          const re = new RegExp(escapeRegExp(value), 'i')
          const isMatch = (result) => {let res=re.test(result.Name); return res;}
        
          this.setState({results: filter(resources, isMatch), isLoading: false})
        }
    }

    onSearchReset = () => {
      this.setState({isLoading: false,
                     results  : [], 
                     value    : "",})
    }

    setUpCallbacks()
    {
        globalCallbacks.getUserOverview = async () =>
        {
            this.setState({LoadingUser: true})
            let project         = await window.LR_GetLinkedProject()
            let departments     = await window.LR_GetDepartments()
            let users           = await window.LR_GetUsers()

            let departments_options = departments.Departments.map((e, i) => 
                {
                return{
                    text: e.Name,
                    key: e.UUID,
                    value: e.UUID}
                })

                departments_options.push({
                    text: LocalizedStrings.NoDepartment,
                    key: EMPTY_UUID,
                    value: EMPTY_UUID
                })


            this.setState({LoadingUser: false, Owner: project.Owner, Project: project.Project, Users: users.Users, DepartmentsOptions: departments_options});
        }
    }

    takeDownCallbacks()
    {
        globalCallbacks.getUserOverview = undefined;
    }

    showInviteProjectDialog = () =>
    {
        window.LR_ShowAddUserToProject();
    }
}

export default UserTable;

