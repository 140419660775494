//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from "react";
import * as THREE from "three";
import { WEBGL } from './js/WebGl'
import TDSLoader from "./js/TDSLoader";
import ReactResizeDetector from 'react-resize-detector';
import { lrServerConnection } from '../../redux/light_right_server_connection'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import {InitScene, LoadGeometryReq, FitCameraToSelectedObjects, LoadMeshes, LoadResourceContainer} from "./js/defaultfunctions";
import ResourceLoadContainer from "./ResourceLoadContainer";
import { RESOURCE_TYPE_Mesh } from "../../util/defines";

// Component for the WebGL Control
class PreviewRenderer extends Component
{
  constructor(props)
  {
    super(props);

    this.renderOptions = {}
    this.renderOptions.ShowLights       = false;
    this.renderOptions.ShowBeams        = false;
    this.renderOptions.ShowLightHelpers = false;
    this.renderOptions.ShowLabels       = false;
    this.renderOptions.ThreeJSObjects   = new Map();

    this.renderOptions.meshLoadContainer        = new ResourceLoadContainer();
    this.renderOptions.SymbolLoadContainer      = new ResourceLoadContainer();
    this.renderOptions.FixtureTypeLoadContainer = new ResourceLoadContainer();

    this.loader = new TDSLoader(undefined, lrServerConnection.__TOKEN);
  }
  // -----------------------------------------------------------------------------------------------------------------------
  // React Lifecycle Methods
  // -----------------------------------------------------------------------------------------------------------------------

  // Initialize Data
  componentDidMount ()
  {
    this.loadResources();

    if(WEBGL.isWebGLAvailable())
    {

      this.scene     = new THREE.Scene();
      this.renderOptions.Scene = this.scene;
      this.camera    = new THREE.PerspectiveCamera(50, this.mount.clientWidth / this.mount.clientHeight, 10, 30000000)
      this.camera.matrixAutoUpdate = true

      if(WEBGL.isWebGL2Available())
      {
        let canvas          = document.createElement( 'canvas' ); 
        let context         = canvas.getContext( 'webgl2' ); 
        this.renderer       = new THREE.WebGLRenderer( { canvas: canvas, context: context } );
      }
      else
      {
        this.renderer       = new THREE.WebGLRenderer();
      }

      InitScene(this.scene, this.camera,this.renderer,  undefined, this.mount.clientWidth, this.mount.clientHeight);

      this.sceneContainerObject = new THREE.Group();
      this.sceneContainerObject.name = "Scene Container";
      this.scene.add(this.sceneContainerObject);
      // Init Controls
      this.initControls();
      
      // Attach Renderer to DOM
      this.mount.appendChild(this.renderer.domElement);
      
      // Start Animation
      if (!this.frameId) 
      {
        this.frameId = requestAnimationFrame(this.animate);
      }
    }

    this.forceUpdate();
  }

  // Unmount
  componentWillUnmount() 
  {
    // Stop Animation
    if(WEBGL.isWebGLAvailable())
    {
      cancelAnimationFrame(this.frameId);
      if (this.renderer)
      {
        this.mount.removeChild(this.renderer.domElement);
      }
    }
  }

  componentDidUpdate(oldProps) 
  {
    if (this.props.resourceUuid !== oldProps.resourceUuid)
    {
      // Clean the scene
      while (this.sceneContainerObject.children.length > 0)
      {
        this.sceneContainerObject.remove(this.sceneContainerObject.children[0]);
      }

      this.loadResources();
    }
  }
  
  render() 
  {
    return(
      <div>
        <div  className="Renderer" 
              style={{ width: "100%", height: "100%" }} 
              ref={mount => { this.mount = mount; }}/>
        <ReactResizeDetector handleWidth handleHeight onResize={this.onResize}/>
      </div>
    );
  }

  onResize = () => 
  {
    this.camera.aspect = this.mount.clientWidth / this.props.height; // without the -3 there is a useless scrollbar
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(this.mount.clientWidth, this.props.height);  // without the -3 there is a useless scrollbar
  }

  //------------------------------------------------------------------------------------------------------------
  // Updates Scene
  // This will be called very often, 
  updateMeshInstances = () =>
  {
    // Update all the mesh positions
    let usedIndexMap = {}
    this.meshObjectIndexMap = {}

    this.scene.traverse(obj => {
      let objectVisible = () => {
        let tempObject = obj
        while (tempObject.parent && !tempObject.LRLayer && !tempObject.LRClass)
        {
          tempObject = tempObject.parent
        }
        if (tempObject.LRLayer || tempObject.LRClass)
        {
          return tempObject.visible
        }
        return true
      }

      if (obj.MeshInstance)
      {
        
        let thisInstanceMesh = obj.MeshInstance.GetMesh()
        if (!thisInstanceMesh)
        {
          return;
        }
        let meshUuid = thisInstanceMesh.uuid
        
        
        if (!objectVisible())
        {
          if (usedIndexMap[meshUuid] === undefined)
          {
            usedIndexMap[meshUuid] = {Index: 0, MeshInstance: obj.MeshInstance}
          }
          return;
        }


        if (this.meshObjectIndexMap[meshUuid] === undefined)
        {
          this.meshObjectIndexMap[meshUuid] = []
        }
        this.meshObjectIndexMap[meshUuid].push(obj);
        


        if (usedIndexMap[meshUuid] === undefined)
        {
          usedIndexMap[meshUuid] = {Index: 0, MeshInstance: obj.MeshInstance};
        }
        let index = Number(usedIndexMap[meshUuid].Index)
        usedIndexMap[meshUuid].Index++;

        thisInstanceMesh.setMatrixAt(index, obj.matrixWorld);

        if (obj.CurrentColor)
        {
          thisInstanceMesh.setColorAt(index, obj.CurrentColor)
        }
        else
        {
          thisInstanceMesh.setColorAt(index, new THREE.Color(1,1,1))
        }
        
        thisInstanceMesh.instanceColor.needsUpdate = true
        thisInstanceMesh.instanceMatrix.needsUpdate = true
      }
    })

    Object.entries(usedIndexMap).forEach(([key, value]) => {
      value.MeshInstance.SetCount(value.Index)
    })
  }


  animate = () => 
  {
    this.frameId = window.requestAnimationFrame(this.animate);
    this.updateMeshInstances()
    this.orbitControls.update();
    this.renderer.render(this.scene, this.camera);
  };

  // Initialize Controls
  initControls = () =>
  {
    this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement, this.scene);
    this.orbitControls.rotateSpeed = 1;
    this.orbitControls.panSpeed = 1;
  }

  //Load models
  loadResources = async () =>
  {
    let request = {
      ResourceType : this.props.resourceType,
      ResourceUUID : this.props.resourceUuid
    }
    if (this.meshObjectIndexMap)
    {
      Object.entries(this.meshObjectIndexMap).forEach(([key, val]) => {
        if (val && val[0].MeshInstance)
        {
          val[0].MeshInstance.SetCount(0)
        }
      })
    }

    this.meshObjectIndexMap = {}
    this.renderOptions.meshLoadContainer.clear()
    this.renderOptions.SymbolLoadContainer.clear()
    this.renderOptions.FixtureTypeLoadContainer.clear()

    const meshes = await window.LR_GetMeshesForResources(request);
    const sym = await window.LR_GetSymbolDefs();
    await LoadResourceContainer(sym.SymbolDefs, this.renderOptions.meshLoadContainer)

    const ft = await window.LR_GetFixtureTypes();
    await LoadResourceContainer(ft.FixtureTypes, this.renderOptions.meshLoadContainer)

    LoadMeshes(meshes.Meshes, this.renderOptions.meshLoadContainer, this.loader, this.scene).then(async () => 
    {
      this.index = {value: 0};
      let tree = await window.LR_GetGeometryTree({Async: true, ObjectUUID: this.props.resourceUuid});

      if(this.props.resourceType === RESOURCE_TYPE_Mesh)
      {
        this.renderOptions.meshLoadContainer.get(this.props.resourceUuid, (meshContainer) => {
          //let meshContainer = this.renderOptions.Meshes[this.props.resourceUuid]
          if(!meshContainer || !meshContainer.GetMesh()) { return; }
  
          let mesh =  meshContainer.GetMesh();
  
          let geometry = {};
          geometry.MeshUuid       = this.props.resourceUuid;
          geometry.UUID           = this.props.resourceUuid;
          geometry.OffsetX        = 0;
          geometry.OffsetY        = 0;
          geometry.OffsetZ        = 0;
          geometry.RotationX      = 0;
          geometry.RotationY      = 0;
          geometry.RotationZ      = 0;
          geometry.MagnetInfo     = {IsMagnet: false}
          if(mesh)
          {
            geometry.ScaleX         = mesh.scale ? mesh.scale.x : 1;
            geometry.ScaleY         = mesh.scale ? mesh.scale.y : 1;
            geometry.ScaleZ         = mesh.scale ? mesh.scale.z : 1;
          }
          tree.push(geometry);
        })
      }

      LoadGeometryReq(tree, this.index, this.sceneContainerObject, this.renderOptions, {});

      FitCameraToSelectedObjects(this.scene, this.camera, this.orbitControls, false);
    });
  }

}
// Export
export default PreviewRenderer;