//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2019 www.deersoft.de
//-----------------------------------------------------------------------------
import LocalizedStrings from 'localized-strings';
import LocalizeOption from './LocalizationOption';
 
import en from "./raw/en/AddNewUserToProject.json"
import de from "./raw/de/AddNewUserToProject.json"
import es from "./raw/es/AddNewUserToProject.json"
import fr from "./raw/fr/AddNewUserToProject.json"
import it from "./raw/it/AddNewUserToProject.json"
import ru from "./raw/ru/AddNewUserToProject.json"
import cz from "./raw/cs/AddNewUserToProject.json"


let Strings = new LocalizedStrings(
{
  en: en,
  de: de,
  es: es,
fr: fr,
ru: ru,
it: it,
cz: cz,
},
  { ...LocalizeOption }
);

export default  Strings;