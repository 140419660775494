//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, {Component} from 'react';
import ReactMde, {getDefaultToolbarCommands} from 'react-mde';
import {  Form, Grid, Icon,Button, Dropdown, Message, Tab, Popup } from 'semantic-ui-react';
import LocalizedStrings from "../../localization/ExportStructuralCalculation";
import LocalizedStrings_MarkDownToolTips from "../../localization/MarkdownToolTips";
import LRModal from '../Basics/BasicModal';
import './ExportStructuralCalculation.css';
import gfm from 'remark-gfm'
const ReactMarkdown = React.lazy(()=>import("react-markdown"))

import { Text} from "../ExportPaperworksReport/MarkdowTableSupport";
import { CompareObjects, EMPTY_UUID } from '../../util/defines';

import { globalCallbacks } from "../../util/callback";
import CustomPDFFormatPicker from '../TableViews/CustomPDFFormatPicker';
import RequestStructuralCalculationButton from './RequestStructualCalculation';

const DEFAULT_CONTEXT = [
  {Name: "HOIST_RESULTS", text: "%%HOIST_RESULTS%%",},
  {Name: "HOIST_RESULTS_SCOPED", text: "%%HOIST_RESULTS_SCOPED(name)%%",},
  {Name: "HOIST_RESULTS_FILTERED", text: "%%HOIST_RESULTS_SCOPED(property, filter)%%",},
  {Name: "BRIDLE_RESULTS", text: "%%BRIDLE_RESULTS%%",},
  {Name: "BRIDLE_RESULTS_SCOPED", text: "%%BRIDLE_RESULTS_SCOPED(name)%%",},
  {Name: "BRIDLE_RESULTS_NO_LEGS", text: "%%BRIDLE_RESULTS_NO_LEGS%%",},
  {Name: "BRIDLE_RESULTS_FILTERED", text: "%%BRIDLE_RESULTS_FILTERED(property, filter)%%",},
  {Name: "BRIDLE_RESULTS_FILTERED_NO_LEGS", text: "%%BRIDLE_RESULTS_FILTERED_NO_LEGS(property, filter)%%",},
  {Name: "DROP_RESULTS", text: "%%DROP_RESULTS%%",},
  {Name: "DROP_RESULTS_SCOPED", text: "%%DROP_RESULTS_SCOPED(name)%%",},
  {Name: "DROP_RESULTS_FILTERED", text: "%%DROP_RESULTS_FILTERED(property, filter)%%",},
  {Name: "GROUND_SUPPORT_RESULTS", text: "%%GROUND_SUPPORT_RESULTS%%",},
  {Name: "GROUND_SUPPORT_RESULTS_SCOPED", text: "%%GROUND_SUPPORT_RESULTS_SCOPED(name)",},
  {Name: "TRUSS_RESULTS" , text:"%%TRUSS_RESULTS%%",},
  {Name: "TRUSS_RESULTS_SCOPED" , text:"%%TRUSS_RESULTS_SCOPED(name)%%",},
  {Name: "TRUSS_RESULTS_FILTERED" , text:"%%TRUSS_RESULTS_FILTERED(property, filter)%%",},
  {Name: "TRUSS_RESULTS_WITH_ROPES", text: "%%TRUSS_RESULTS_WITH_ROPES%%",},
  {Name: "TRUSS_RESULTS_WITH_ROPES_FILTERED" , text:"%%TRUSS_RESULTS_WITH_ROPES_FILTERED(property, filter)%%",},
  {Name: "SCRIPT_PROVE_CHECK" , text:"%%SCRIPT_PROVE_CHECK(name)%%",},
  {Name: "LOAD_OVERVIEW", text: "%%LOAD_OVERVIEW%%",},
  {Name: "LOAD_OVERVIEW_FILTERED", text: "%%LOAD_OVERVIEW_FILTERED(property, filter)%%",},
  {Name: "LOAD_CASE_OVERVIEW", text: "%%LOAD_COMBINATION%%",},
  {Name: "FEM_APPENDIX", text: "%%FEM_APPENDIX%%",},
  {Name: "SAVED_VIEW", text: "%%SAVED_VIEW( name, label, width_mm, height_mm, )%%", options: "SavedViewOption", value: "SelectedSavedViewOption"},
  {Name: "WORKSHEET", text: "%%WORKSHEET( name )%%", options: "WorksheetOptions", value: "SelectedWorksheetOption"},
  {Name: "QR_CODE_PROJECT", text: "%%QR_CODE_PROJECT%%",},
  {Name: "QR_CODE_WORKSHEET", text: "%%QR_CODE_WORKSHEET( name )%%", options: "WorksheetOptions", value: "SelectedWorksheetOption"},
  {Name: "QR_CODE_OBJECT", text: "%%QR_CODE_OBJECT( name )%%"},
  {Name: "TOC", text: "%%TOC( level )%%",},
  {Name: "LINEBREAK", text: "%%LINEBREAK%%",},
  {Name: "SHEETBREAK", text: "%%SHEETBREAK%%",},
]

const SIMPLE_EXPORT   = 0
class ExportStructuralCalculationModal extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
      protocolText: "",
      changed: false,
      changedPreset: false,

      reportPresets: [],
      selectedPreset: "",

      createOpen: false,
      createName: "",

      PrintLabels: [],
      selectedPrintLabel:EMPTY_UUID,
      selectedPrintLabelFirstPage:EMPTY_UUID,
      WorksheetOptions:[],
      PublicShareLinkOptions:[],
      PublicShareLink: "",
      SelectedWorksheetOption:"",
      SelectedSavedViewOption:"",
      Project:"",

      SimpleProjectDescription: "",
      ExportHoist: true,
      ExportDrop: true,
      ExportGroundSupport: true,
      ExportTrusses: true,
      SimpleAddCurrentView: true,

      activeIndex: SIMPLE_EXPORT,
      PDFFormatsSettings: undefined
    }


    this.SHARE_LINK_MAP = {}
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }

  show = async() => 
  {
    let result = await window.LR_GetLinkedProject()
    let ret  = await window.LR_GetWorksheets()
    let ret2 = await window.LR_GetCalculationReportMarkdown()
    let ret3 = await window.LR_GetCalculationReportTemplateMap()
    let ret4 = await window.LR_GetPrintLabels();
    let ret5 = await window.LR_GetSavedViews();


    let Worksheets = {}

    ret.Worksheets.forEach(ws => 
      {
        Worksheets[ws.Name] = ws.UUID
      })

      globalCallbacks.__gWorksheets = Worksheets;

      let WorksheetOptions = ret.Worksheets.map(w=> { return {key: w.UUID, value:w.Name, text: w.Name}})

      WorksheetOptions.push({key: EMPTY_UUID, value:"", text: "<Scene>"})


      let SavedViewOption = ret5.SavedViews.map(w=> { return {key: w.UUID, value:w.Name, text: w.Name}})

      SavedViewOption.push({key: EMPTY_UUID, value:"", text: "<Current View>"})

      let shareLinks = await window.LR_GetShareLinksForProject()    
      let options = []

      

      this.SHARE_LINK_MAP = {}
      if(Array.isArray(shareLinks))
      {
        options = shareLinks.map((e)=>
        {
          this.SHARE_LINK_MAP[e.name] = e.token
          return {
          key: e.name,
          text: e.name,
          value:e.name
        }})
      }
  
      options.push(
        {
          text:"<None>",
          value: "",
          key:"<None>"
        }
      )

    this.setState( { 
        open : true, 
        Worksheets: Worksheets,
        protocolText: ret2.Report,
        selectedPrintLabelFirstPage: ret2.Front,
        selectedPrintLabel: ret2.Back,
        reportPresets: ret3.CalculationReports.calculationReport,
        PrintLabels:ret4.PrintLabels,
        WorksheetOptions,
        SavedViewOption,
        PublicShareLinkOptions: options,
        Project: result.Project,
        PublicShareLink: ret2.ShareLink
      });
  }

  export = () => 
  {
    window.LR_ExportStructuralReport(
      {
        ProtocolDef:this.state.protocolText, 
        SelectedPrintLabelBackground: this.state.selectedPrintLabel, 
        SelectedPrintLabelFrontPage: this.state.selectedPrintLabelFirstPage, 
        PublicShareLink: this.SHARE_LINK_MAP[ this.state.PublicShareLink ],
        PublicShareLinkName: this.state.PublicShareLink,
        SimpleProjectDescription: this.state.SimpleProjectDescription,
        ExportHoist: this.state.ExportHoist,
        ExportDrop: this.state.ExportDrop,
        ExportGroundSupport: this.state.ExportGroundSupport,
        ExportTrusses: this.state.ExportTrusses,
        SimpleAddCurrentView: this.state.SimpleAddCurrentView,
        ExportSimpleWay: this.state.activeIndex === SIMPLE_EXPORT,
        PDFFormats: this.state.PDFFormatsSettings
      })
    this.close()
  }

  cancel = () => 
  {
    window.LR_SetCalculationReportMarkdown(
    {
      Report:this.state.protocolText,
      Front:this.state.selectedPrintLabelFirstPage,
      Back:this.state.selectedPrintLabel,
      SimpleProjectDescription: this.state.SimpleProjectDescription,
    })
    this.close()
  }

  close = () => 
  {
    this.setState({open : false});
  }
    
  render() 
  {
    let open = this.state.open;
    
    let wasChanged = false
    let theSelectedPreset = this.state.reportPresets.find(pr => pr.Name === this.state.selectedPreset)
    if (theSelectedPreset)
    {
      wasChanged =  
      this.state.protocolText !== theSelectedPreset.Markdown || 
      this.state.selectedPrintLabel !== theSelectedPreset.BackgroundTemplate || 
      this.state.selectedPrintLabelFirstPage !== theSelectedPreset.FirstPageBackgroundTemplate || 
      this.state.PublicShareToken !== theSelectedPreset.PublicShareToken ||
      !CompareObjects(this.state.PDFFormatsSettings, theSelectedPreset.PDFFormats)
    }

   

    let panes = 
        [
            {
                menuItem: LocalizedStrings.BasicExport, render: () => this.renderSimple()
            },
            {
                menuItem: LocalizedStrings.ExtendedExport, render: () => this.renderExtended()
            }
        ]


    return <>
      <LRModal  open={open} 
                closeOnDimmerClick={false} 
                closeOnEnter={false}
                title={LocalizedStrings.Header}
                size="fullscreen"
                onOkClick={this.export}
                changed={this.state.changed}
                preventDataLoss
                dimmer=""
                onCancelClick={this.cancel}
                onDiscardClick={this.close}
                >
          <Grid  className='ExportStructuralCalculation'>
    <Grid.Row>

    
        <Grid.Column width="13">
          <Tab activeIndex={this.state.activeIndex} menu={{ secondary: true, pointing: true }} panes={panes} onTabChange={(data, {activeIndex}) =>{ this.setState({activeIndex: activeIndex})}}/>
        </Grid.Column>
        <Grid.Column width="3">
          <RequestStructuralCalculationButton/>
        <Form>
          <Form.Select  label={LocalizedStrings.SavedCalculationReports} 
                        disabled={this.state.activeIndex === SIMPLE_EXPORT}
                        options={this.state.reportPresets.map(pres => ({key: pres.Name, value: pres.Name, text: pres.Name}))} 
                        value={this.state.selectedPreset}
                        onChange={(_, {value}) => {this.changeSelectedPreset(value)}}/>
          <Button.Group fluid style={{marginBottom: 15}}>
            <Button disabled={this.state.activeIndex === SIMPLE_EXPORT || !wasChanged} size="mini" color="blue" content={LocalizedStrings.UpdatePreset} onClick={this.updateClick}/>
            <Button disabled={this.state.activeIndex === SIMPLE_EXPORT} size="mini" positive content={LocalizedStrings.CreatePreset} onClick={this.createClick}/>
            <Button disabled={this.state.activeIndex === SIMPLE_EXPORT} size="mini" negative content={LocalizedStrings.DeletePreset} onClick={this.deleteClick} />
          </Button.Group>
          
          <Form.Select  label={LocalizedStrings.PrintLabel} 
                        options={[{key: EMPTY_UUID, value: EMPTY_UUID, text: LocalizedStrings.NoBackground}, ...this.state.PrintLabels.map(lb => ({key: lb.UUID, value: lb.UUID, text: lb.Name}))]} 
                        value={this.state.selectedPrintLabel}
                        onChange={(_, {value}) => {this.setState({selectedPrintLabel: value})}}/>
          <Form.Select  label={LocalizedStrings.PrintLabelFirstPage} 
                        options={[{key: EMPTY_UUID, value: EMPTY_UUID, text: LocalizedStrings.NoBackground}, ...this.state.PrintLabels.map(lb => ({key: lb.UUID, value: lb.UUID, text: lb.Name}))]} 
                        value={this.state.selectedPrintLabelFirstPage}
                        onChange={(_, {value}) => {this.setState({selectedPrintLabelFirstPage: value})}}/>
          <CustomPDFFormatPicker
            PDFFormat={this.state.PDFFormatsSettings}
            onChangePdfFormat={i => this.setState({PDFFormatsSettings: i})}
          />
          <Form.Select
            onAddItem={this.createShareLink}
            search
            disabled={this.state.Project===""}
            allowAdditions={true}
            label={LocalizedStrings.PublicShareLink}
            value={this.state.PublicShareLink}
            fluid
            compact
            options={this.state.PublicShareLinkOptions}
            onChange={(_, { value }) => {
              this.setState({ PublicShareLink: value });
            }}
          />
        </Form>
        
        {this.state.Project === "" ? (
          <Message
            icon="dont"
            error
            header={LocalizedStrings.NoOnlineProjectHeader}
            content={LocalizedStrings.NoOnlineProjectText}
          />
        ) : null}
            </Grid.Column>
            <LRModal open={this.state.createOpen}
              title={LocalizedStrings.CreatePreset}
              onCancelClick={this.closeCreateModal}
              onOkClick={this.createNewPreset}>
              <Form>
                <Form.Input value={this.state.createName}
                  label={LocalizedStrings.PresetName}
                  onChange={(e, { value }) => { this.setState({ createName: value }) }} />
              </Form>
              {this.state.reportPresets.map(i => i.Name).includes(this.state.createName) ?
                <Message
                  icon="exclamation"
                  warning
                  header={LocalizedStrings.OverwritingPresetHeader}
                  content={LocalizedStrings.OverwritingPresetText}
                /> : null}
            </LRModal>
          </Grid.Row>
        </Grid>
      </LRModal>
    </>
  }

  renderSimple()
  {
    return(
      <>
      <h4>{LocalizedStrings.SimpleProjectExtent}</h4>
        <Form>
          <Form.Checkbox onChange={(_, {checked}) => {this.setState({ExportHoist: checked})}}  checked={this.state.ExportHoist} label={LocalizedStrings.ExportHoist}></Form.Checkbox>
          <Form.Checkbox onChange={(_, {checked}) => {this.setState({ExportDrop: checked})}}checked={this.state.ExportDrop} label={LocalizedStrings.ExportDrop}></Form.Checkbox>
          <Form.Checkbox onChange={(_, {checked}) => {this.setState({ExportGroundSupport: checked})}}checked={this.state.ExportGroundSupport} label={LocalizedStrings.ExportGroundSupport}></Form.Checkbox>
          <Form.Checkbox onChange={(_, {checked}) => {this.setState({ExportTrusses: checked})}}checked={this.state.ExportTrusses} label={LocalizedStrings.ExportTrusses}></Form.Checkbox>
          <Form.Checkbox onChange={(_, {checked}) => {this.setState({SimpleAddCurrentView: checked})}}checked={this.state.SimpleAddCurrentView} label={LocalizedStrings.SimpleAddCurrentView}></Form.Checkbox>
        </Form>
      <h4>{LocalizedStrings.SimpleProjectDescription}</h4>
      <ReactMde
          getIcon={(commandName) => <Icon name={commandName} />}
          value={this.state.SimpleProjectDescription}
          onChange={(data) => this.setState({SimpleProjectDescription: data, changed: true})}
          selectedTab={this.state.selectedTabSimple}
          onTabChange={(data) => this.setState({selectedTabSimple: data})}
          generateMarkdownPreview={(markdown) => Promise.resolve(<ReactMarkdown components={{p: Text}} remarkPlugins={[gfm]}>{markdown}</ReactMarkdown>) }
          minEditorHeight={300}
          childProps={{ writeButton: { tabIndex: -1 }, textArea: {
            onKeyDown: (e)=>{
              // dont jump out of text field on tab
              if (e.key == 'Tab') {
                e.preventDefault();
                let t = e.target
                let start = t.selectionStart;
                let end = t.selectionEnd;

                let np = this.state.SimpleProjectDescription
                np = np.substring(0, start) + "\t" + np.substring(end);

                this.setState({SimpleProjectDescription: np}, ()=>{
                  t.selectionStart = t.selectionEnd = start + 1
                })
              }
            }
          } }}
        />

        </>
    )
  }

  renderExtended()
  {
    let customCommand = {}
    let customCommandList = []


    DEFAULT_CONTEXT.forEach(e => 
    {
      customCommandList.push(e.Name)
      customCommand[e.Name] = {
        name: e.Name,
        icon: () => (
        <Popup content={ <ReactMarkdown>{LocalizedStrings_MarkDownToolTips[e.Name]}</ReactMarkdown> }          trigger={
          <span >
            <Icon name="add"/>
            {e.options ? <Dropdown onChange={(event, {value})=>{this.setState({[e.value]: value})}} value={this.state[e.value]} options={this.state[e.options]}></Dropdown> : null}
            <b>{LocalizedStrings[e.Name]}</b>
          </span>}/>
        ),
        execute: opts => {
          let text = e.text
          if(e.value) { text = text.replace("name", this.state[e.value]) }
          opts.textApi.replaceSelection("\n" + text + "\n");
        }
      }
    });

    return( 
        <ReactMde
          commands={customCommand}
          toolbarCommands={ [...getDefaultToolbarCommands(),customCommandList]}
          getIcon={(commandName) => <Icon name={commandName} />}
          value={this.state.protocolText}
          onChange={(data) => this.setState({protocolText: data, changed: true})}
          selectedTab={this.state.selectedTab}
          onTabChange={(data) => this.setState({selectedTab: data})}
          generateMarkdownPreview={(markdown) => Promise.resolve(<ReactMarkdown components={{p: Text}} remarkPlugins={[gfm]}>{markdown}</ReactMarkdown>) }
          loadSuggestions={this.loadSuggestions}
          minEditorHeight={800}
          childProps={{ writeButton: { tabIndex: -1 }, textArea: {
            onKeyDown: (e)=>{
              // dont jump out of text field on tab
              if (e.key == 'Tab') {
                e.preventDefault();
                let t = e.target
                let start = t.selectionStart;
                let end = t.selectionEnd;

                let np = this.state.protocolText
                np = np.substring(0, start) + "\t" + np.substring(end);

                this.setState({protocolText: np}, ()=>{
                  t.selectionStart = t.selectionEnd = start + 1
                })
              }
            }
          } }}
        />)

  }

  createShareLink = async(e, data) =>
  {
    await window.LR_CreateShareLinkForProject({Name: data.value})
    this.show()
  }

  updateClick = () => {
    window.LR_SetCalculationReportTemplate({ 
      Name: this.state.selectedPreset, 
      Markdown: this.state.protocolText, 
      BackgroundTemplate: this.state.selectedPrintLabel, 
      FirstPageBackgroundTemplate: this.state.selectedPrintLabelFirstPage, 
      PublicShareToken: this.SHARE_LINK_MAP[this.state.PublicShareLink],
      PDFFormats: this.state.PDFFormatsSettings,
    })
  }

  deleteClick = () =>
  {
    window.LR_DeleteCalculationReportTemplate({Name: this.state.selectedPreset})
  }

  createClick = () =>
  {
    this.setState({createOpen: true, createName: "Unknown Preset"})
  }

  closeCreateModal = () =>
  {
    this.setState({createOpen: false})
  }

  createNewPreset = () => {
    window.LR_AddCalculationReportTemplate({ 
      Name: this.state.createName, 
      Report: this.state.protocolText, 
      BackgroundTemplateUUID: this.state.selectedPrintLabel, 
      FirstPageBackgroundTemplateUUID: this.state.selectedPrintLabelFirstPage, 
      PublicShareToken: this.SHARE_LINK_MAP[this.state.PublicShareLink],
      PDFFormats: this.state.PDFFormatsSettings
    })
    this.setState({ selectedPreset: this.state.createName })
    this.closeCreateModal();
  }

  fetchReportPresets = async () =>
  {
    let presets = await window.LR_GetCalculationReportTemplateMap()

    this.setState({reportPresets: presets.CalculationReports.calculationReport})
  }

  changeSelectedPreset = (preset) =>
  {
    let thePreset = this.state.reportPresets.find(pr => pr.Name === preset)
    if (thePreset) {
      this.setState({ 
        selectedPreset: preset, 
        protocolText: thePreset.Markdown, 
        selectedPrintLabel: thePreset.BackgroundTemplate, 
        selectedPrintLabelFirstPage: thePreset.FirstPageBackgroundTemplate, 
        PublicShareToken: thePreset.PublicShareToken,
        PDFFormatsSettings: thePreset.PDFFormats
      })
    }
  }

  setUpCallbacks()
  {

    globalCallbacks.ShowExportStructuralCalculation = () => 
    { 
      this.show();
    }

    globalCallbacks.GetCalculationReportPresets = () =>
    {
      this.fetchReportPresets()
    }
    
    window.LR_GetProjectSettings().then((res) => {
        if (res) {
            this.setState({PDFFormatsSettings: res.PdfFormat})
        }
    })
  }
}

export default ExportStructuralCalculationModal