
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import LocalizedStrings from "../../localization/NavigationContainer";
import React, { Component } from 'react';
import { Table, Icon, Input, Popup, } from 'semantic-ui-react'
import { SELECT_Single } from '../../util/defines';

import { globalCallbacks as mainCB } from '../../util/callback';
import { globalCallbacks as mockCB } from '../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB

class SelectionGroupNode extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = this.props.selectionGroup;
    }

    componentDidUpdate(oldProps) 
    {
        const newProps = this.props;
        for(const [name, value] of Object.entries(newProps.selectionGroup))
        if(oldProps.selectionGroup[name] !== value) 
        {
            this.setState({[name]: value});
        }
    }

    render() 
    {
        let selectionGroup = this.state;
        return (
            <Table.Row >
                <Table.Cell collapsing>
                    <Popup content={LocalizedStrings.ActivateSelectionGroup}
                    trigger=
                    {
                        <Icon link name="selected radio" onClick={(e) => { this.onSelectObjects(); }}/>
                    }/>
                    <Popup content={LocalizedStrings.ActivateSelectionGroupCalc}
                    trigger=
                    {
                        <Icon link name="calculator" onClick={(e) => { this.onSelectObjectsAndCalc(); }}/>
                    }/>
                    </Table.Cell>
                    <Table.Cell>
                    
                    <Input  transparent
                            name      = "Name" 
                            value     = {selectionGroup.Name} 
                            onChange  = {this.onValueChanged} 
                            onBlur    = {() => this.updateData("Name", selectionGroup.Name)}
                            onKeyDown = {this.onKeyDown}
                            fluid/>
                </Table.Cell>
                <Table.Cell collapsing>
                    <Popup content={LocalizedStrings.StoreSelectionGroup}
                    trigger=
                    {
                        <Icon   name    = "file save" 
                                link
                                onClick = {() => {this.onUpdateObjectList()}}/>
                    }/>
                    <Popup content={LocalizedStrings.DeleteSelectionGroup}
                    trigger={
                    <Icon   name    = "delete"
                            color   = "red"
                            link
                            onClick = {() => this.onDelete(selectionGroup.UUID)}
                            />
                    }/>
                   
                </Table.Cell>
            </Table.Row>
        );
    }

    onValueChanged = (e, {name, value}) =>
    {
        this.setState({[name]:value});
    }

    updateData = (name, value) =>
    {
        let request = 
        {
            UUID: this.state.UUID
        };

        if(name)    { request[name] = value; }
        else        { request.Name  = this.state.Name; }
        
        window.LR_SetSelectionGroup(request);
        this.setState({[name]:value});
    }

    onKeyDown = (event) =>
    {
        if(event.keyCode === 13) { this.updateData(); event.target.blur() }
    }

    onDelete = (uuid) =>
    {
        let request = 
        {
            UUID: uuid,
        }
        window.LR_DeleteSelectionGroup(request);
        
    }
    onUpdateObjectList = async () => 
    {
        let selectedObjects = await window.LR_GetSelectedObjects();

        let selectionUuidList = selectedObjects.map(val => val.UUID)
        window.LR_SetSelectionGroup({UUID: this.props.selectionGroup.UUID, ObjectList: selectionUuidList});
    }

    onSelectObjects = () =>
    {
        let request = 
        {
            Selected      : true,
            SelectionMode : SELECT_Single,
            SelectionGroupMode : globalCallbacks.selectionGroupMode,
            ShiftKey      : false,
            MetaKey       : false,
            AltKey        : false,
            SelectedList  : this.props.selectionGroup.ObjectList
        }

        window.LR_Select(request);
    }

    onSelectObjectsAndCalc = async () =>
    {
        let request = 
        {
            Selected      : true,
            SelectionMode : SELECT_Single,
            SelectionGroupMode : globalCallbacks.selectionGroupMode,
            ShiftKey      : false,
            MetaKey       : false,
            AltKey        : false,
            SelectedList  : this.props.selectionGroup.ObjectList
        }

        await window.LR_Select(request);
        await window.LR_SetCalculationObject();
    }

}

export default SelectionGroupNode;

