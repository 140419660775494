//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react'
import { Icon, Input, Popup, Table } from 'semantic-ui-react';
import {cie2hex} from "./../ColorPicker/utilities"

import LocalizedStrings from "../../localization/NavigationContainer";

import { globalCallbacks as mainCB } from '../../util/callback';
import { globalCallbacks as mockCB } from '../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB

class DepartmentNode extends Component
{
    constructor(props)
    {
        super(props)

        this.state = this.props.department

        
    }

    componentDidUpdate(oldProps) 
    {
        const newProps = this.props;
        for(const [name, value] of Object.entries(newProps.department))
        {
            if(oldProps.department[name] !== value) 
            {
                this.setState({[name]: value});
            }
        }
    }

    render()
    {
        let department = this.state
        let hexColor = cie2hex({fx: department.DepartmentColor.X, fy: department.DepartmentColor.Y, f_Y: department.DepartmentColor.Z})

        return <Table.Row>
            <Table.Cell collapsing>
                <div style={{backgroundColor:`${hexColor}`, width: "1rem", height:"1rem", borderRadius: 90}}/>

            </Table.Cell>
            <Table.Cell>

                <Input  transparent
                        name = "Name"
                        value = {department.Name}
                        onChange = {this.onValueChanged}
                        onBlur   = {() => this.updateData("Name", department.Name)}
                        onKeyDown = {this.onKeyDown}
                        fluid/>
            </Table.Cell>
            <Table.Cell textAlign="center" collapsing>
                    <Popup content={LocalizedStrings.EditColorDepartment}
                    trigger={
                        <Icon   name    = "edit"
                        link
                        onClick = {() => {this.openColorEdit()}} />
                    }/>
            </Table.Cell>
            <Table.Cell textAlign="center" collapsing>
                <Popup  content={LocalizedStrings.DeleteDepartment}
                        trigger={
                            <Icon   name    = "delete"
                                    color   = "red"
                                    link
                                    onClick = {this.deleteDepartment(department.UUID)} />
                        }/>
            </Table.Cell>
        </Table.Row>
    }

    onValueChanged = (e, {name, value}) =>
    {
        this.setState({[name]: value})
    }

    openColorEdit = () => 
    {
        if (globalCallbacks.openColorDepartmentEdit) { globalCallbacks.openColorDepartmentEdit({UUID: this.state.UUID})}
    }

    updateData = (name, value, event) => 
    {
        let request = 
        {
            UUID: this.state.UUID
        }

        if (name) { request[name] = value }
        else      { request.Name = this.state.Name}

        window.LR_SetDepartment(request)
    }

    onKeyDown = (event) =>
    {
        if(event.keyCode === 13) { this.updateData(); event.target.blur() }
    }

    openDepartmentEdit = () =>
    {

    }

    deleteDepartment = (uuid) => () =>
    {
        window.LR_DeleteDepartment({UUID: uuid})
    }
}

export default DepartmentNode;