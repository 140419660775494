//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import * as THREE from "three";

class LRInstanceMesh
{
    constructor(mesh, name = "Unknown")
    {
        this.count = 0
        this.allocatedCount = 0

        this.scene = undefined;

        this.meshInstance = mesh

        this.objectMap = new Map()

        this.indicesToTake = []

        this.name = name
    }

    SetMeshScene = (scene) => 
    {
        if (!scene)             { console.error("SetMeshScene failed - No Scene"); return; }
        if (!this.meshInstance) { console.error("SetMeshScene failed - Mesh Not there"); return; }

        // When setting the scene, we always also have to reset the count
        let geometry = this.meshInstance.geometry
        let material = this.meshInstance.material.clone()
        scene.remove(this.meshInstance)
        this.meshInstance = new THREE.InstancedMesh(geometry, material, 0)
        this.count = 0
        this.allocatedCount = 0

        this.scene = scene;
        this.scene.add(this.meshInstance)
    }

    IncreaseCount = () =>
    {
        this.count++;
        return this.updateMesh();
    }

    DecreaseCount = () =>
    {
        this.count--;
        return this.updateMesh();
    }

    SetCount = (count) =>
    {
        this.count = count
        return this.updateMesh()
    }

    GetMesh = () => {
        return this.meshInstance
    }

    // returns true if mesh was regenerated
    updateMesh()
    {
        if (this.count < 0) { this.count = 0; this.allocatedCount= 0 }
        if (this.count > this.allocatedCount)
        {
            let uuid = this.meshInstance.uuid
            let geometry = this.meshInstance.geometry
            let material = this.meshInstance.material.clone()
            if(this.scene)
            {
                this.scene.remove(this.meshInstance)
            }

            if(this.meshInstance)
            {
                this.meshInstance.dispose()
            }
            
            // the  Count that we want to allocate, so that we not always allocate more that we actually want
            this.allocatedCount = this.count + 500
            
            this.meshInstance = new THREE.InstancedMesh(geometry, material, this.allocatedCount)
            this.meshInstance.matrixWorldAutoUpdate = false

            this.meshInstance.setColorAt(0, new THREE.Color(1,1,1))
            this.meshInstance.uuid = uuid
            if(this.scene)
            {
                this.scene.add(this.meshInstance)
            }
            this.meshInstance.uuid = uuid
            // The count that is actually used
            this.meshInstance.count = this.count
            return true
        }
        this.meshInstance.count = this.count
        return false
    }

    addObject = (object) =>
    {
        let indexForObject = this.objectMap.size()
        if (this.indicesToTake.length > 0)
        {
            indexForObject = this.indicesToTake[0]
            this.indicesToTake.shift()
        }

        let toAdd = {object: object, index: indexForObject}
        this.objectMap.set(object.uuid, toAdd);
    }

    removeObject = (object) =>
    {
        let foundObject = this.objectMap.get(object.uuid)
        if (foundObject)
        {
            this.indicesToTake.push(foundObject.index)
            this.objectMap.delete(object.uuid)
        }
    }
}

export default LRInstanceMesh;