//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component, Suspense } from 'react';
import LocalizedStrings from "../../localization/AboutDialog";
import LRModal from '../Basics/BasicModal';
import StructuralList from '../TableViews/Structural/StructuralList'
import { globalCallbacks } from "../../util/callback";

const Line = React.lazy(async ()=>{
  const {
    Chart: ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } = await import("chart.js")

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  return import("react-chartjs-2").then(module=>({default:module.Line}))
})

//-----------------------------------------------------------------------------
// The component for the TreeControl
class CalculationOverviewDialog extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
      tree: []
    }
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }

  show = async() => 
  {
    this.setState( { 
        open : true, 
      });

      if (globalCallbacks.refreshStructuralCalculationList) {globalCallbacks.refreshStructuralCalculationList()}

      
      
  }

  close = () => 
  {
    this.setState({open : false});
  }
    
  render() 
  {
    let open = this.state.open;
    if(!open) {return <div/>}

    return (
      <LRModal  open={open} 
                onCancelClick={this.close} 
                closeOnDimmerClick={false} 
                size="large"
                scrolling={false}
                title={LocalizedStrings.CalculationOview}
                noCancel
                onOkClick={this.close}>
          {this.state.open ? 
          <>
            <StructuralList /> 
          </>: null}
      </LRModal>
    )
  }



  setUpCallbacks()
  {

    globalCallbacks.ShowCalculationOverview = () => 
    { 
      this.show();
    }
    
  }
}

export default CalculationOverviewDialog