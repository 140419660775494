//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component, Suspense } from 'react';
import {  Button, Icon, Label, Progress, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';

import LocalizedStrings from "../../../localization/TableViewComponent";
import DynamicTable from '../../Basics/DynamicTableView';
import UnitInput from '../../Basics/BasicUnitInput';
import { BASE_UNIT_PERCENT, GEOMETRY_SUPPORT_TYPE_Rope, GetPayLoadColor_2 } from '../../../util/defines';
import { GLOBAL_SETTINGS } from '../../../redux/redux_defines';

import { globalCallbacks as mainCB } from '../../../util/callback';
import { globalCallbacks as mockCB } from '../../../util/mock_callback';
import CollapsableDevider from '../../Basics/CollapsableDevider';
import DrawingErrorNode from '../../Navigation/DrawingErrorNode';
import RequestStructuralCalculationButton from '../../ExportStructuralCalculation/RequestStructualCalculation';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB


const Line = React.lazy(async ()=>{
    const {
      Chart: ChartJS,
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend,
    } = await import("chart.js")
  
    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend
    );
    return import("react-chartjs-2").then(module=>({default:module.Line}))
  })

class StructuralList extends Component
{
    constructor(prop)
    {
        super(prop);
        this.state = 
        {
            Results: [],
            Active: {},
            tree: [],
            Errors: {},
            ErrorNodes: true

        }
        
    }

    componentDidMount()
    {
        this.setupCallbacks();

        if (globalCallbacks.refreshStructuralCalculationList) {globalCallbacks.refreshStructuralCalculationList()}
    }

    render()
    {
        const headerIdent = [
            {editable: true, unit: undefined,  sortable: true, ident: "Name",         label: LocalizedStrings.ResultName},
            {editable: true, unit: undefined,  sortable: true, ident: "ObjectID",     label: LocalizedStrings.ResultObjectID},
            {editable: true, unit: undefined,  sortable: true, ident: "Value",        label: LocalizedStrings.ResultValue},
            {editable: true, unit: undefined,  sortable: true, ident: "MaxValue",     label: LocalizedStrings.ResultMaxValue},
            {editable: true, unit: undefined,  sortable: true, ident: "Workload",     label: LocalizedStrings.ResultWorkload},
            {editable: true, unit: undefined,  sortable: true, ident: "Select",       label: LocalizedStrings.ResultSelect},
        ]
        const cellRender = (entry, rowIndex) => 
        {
            return {
                Name                        : entry.Name,
                ObjectID                    : entry.ObjectID,
                Value                       : <UnitInput readOnly value={entry.Value} baseUnit={entry.Unit}/>,
                MaxValue                    : <UnitInput readOnly value={entry.MaxValue} baseUnit={entry.Unit}/>,
                Workload                    : <div style={{backgroundColor: GetPayLoadColor_2(entry.Workload, 1)}}><UnitInput readOnly value={entry.Workload * 100} baseUnit={BASE_UNIT_PERCENT}/></div>,
                Select                      : <Button onClick={()=>{ globalCallbacks.setSelected(entry.ObjectUuid, true, undefined)}}>{LocalizedStrings.HighlightResult}</Button>,
            };
        };


        let workloadDrops           = 0
        let workloadHoist           = 0
        let workloadBridle          = 0
        let workloadHouseRigging    = 0
        let workloadGroundSupport   = 0
        let workloadTruss           = 0
        let workloadTrussKeyed =  {}

        this.state.Results.forEach(e=>
        {
            if     (e.Type ===  0) { workloadDrops = Math.max(e.Workload, workloadDrops)}
            else if(e.Type ===  1) { workloadHoist = Math.max(e.Workload, workloadHoist)}
            else if(e.Type ===  2) { workloadBridle = Math.max(e.Workload, workloadBridle)}
            else if(e.Type ===  3) { workloadGroundSupport = Math.max(e.Workload, workloadGroundSupport)}
            else if(e.Type ===  5) { workloadHouseRigging = Math.max(e.Workload, workloadHouseRigging)}
            else if(e.Type ===  4) 
            {
                workloadTruss = Math.max(e.Workload, workloadTruss)
                if( ! workloadTrussKeyed[e.Key])
                {
                    workloadTrussKeyed[e.Key] = {Workload: e.Workload, List: [e]};
                }
                else
                {
                    workloadTrussKeyed[e.Key].Workload = Math.max(workloadTrussKeyed[e.Key].Workload, e.Workload);
                    workloadTrussKeyed[e.Key].List.push(e)
                }
            }
        })

        workloadDrops = Math.ceil(workloadDrops * 100)
        workloadHoist =Math.ceil(workloadHoist * 100)
        workloadBridle =Math.ceil(workloadBridle * 100)
        workloadGroundSupport =Math.ceil(workloadGroundSupport * 100)
        workloadTruss =Math.ceil(workloadTruss * 100)
        workloadHouseRigging =Math.ceil(workloadHouseRigging * 100)

        let renderCSTable = (key) =>
        {
            return(
            <>
                <h2>{this.renderIconCollapse(key)}{key}</h2>
                <Progress progress percent={Math.ceil(workloadTrussKeyed[key].Workload * 100)} total={100}  success={this.state.Active.LowWorkload > workloadTrussKeyed[key].Workload * 100} warning={this.state.Active.LowWorkload < workloadTrussKeyed[key].Workload * 100} error = {this.state.Active.OverloadWorkload < workloadTrussKeyed[key].Workload * 100}/>
                {this.state[key] ? <DynamicTable cellRender={cellRender} headerIdent={headerIdent} cellData={workloadTrussKeyed[key].List} />  : null}
            </>
            )
        }

        let workloadMax = Math.max(workloadDrops, workloadHoist,workloadBridle , workloadGroundSupport, workloadTruss, workloadHouseRigging)

        let isGreen  = workloadMax <= 80;
        let isYellow = workloadMax > 80 && workloadMax <= 100;
        let isRed    = workloadMax > 100;


        for(let errorType of Object.entries(this.state.Errors))
        {
          for(let error of this.state.Errors[errorType[0]])
          {
            if(error.Error)
            {
              isGreen  = false;
              isYellow = false;
              isRed    = true;
              break;
            }
          }
        }

        return (<>
        <Segment placeholder>
          <h1>{LocalizedStrings.CalculationState}</h1>  
          <Label size="massive" circular color={"green"}  style={{opacity: isGreen ? 1 : 0.1}}/>
          <Label size="massive" circular color={"yellow"} style={{opacity: isYellow ? 1 : 0.1}}/>
          <Label size="massive" circular color={"red"}    style={{opacity: isRed ? 1 : 0.1}}/>
        </Segment>
        <RequestStructuralCalculationButton/>
        <h1>{this.renderIconCollapse("ErrorNodes")}{LocalizedStrings.ErrorNodes}</h1>    
        {this.state.ErrorNodes ? Object.entries(this.state.Errors).map((entry, i) => { return this.renderError(entry[0], i)}) : null}
        <h1>{this.renderIconCollapse("ResultHRP")}{LocalizedStrings.ResultHRP}</h1>    
        <Progress progress percent={workloadHouseRigging} total={100} success={this.state.Active.LowWorkload > workloadHouseRigging} warning={this.state.Active.LowWorkload < workloadHouseRigging} error = {this.state.Active.OverloadWorkload < workloadHouseRigging} />
        {this.state.ResultHRP ? <DynamicTable cellRender={cellRender} headerIdent={headerIdent} cellData={this.state.Results.filter(v => v.Type === 5)} /> : null}
        <h1>{this.renderIconCollapse("ResultHoist")}{LocalizedStrings.ResultHoist}</h1>    
        <Progress progress percent={workloadHoist} total={100} success={this.state.Active.LowWorkload > workloadHoist} warning={this.state.Active.LowWorkload < workloadHoist} error = {this.state.Active.OverloadWorkload < workloadHoist} />
        {this.state.ResultHoist ? <DynamicTable cellRender={cellRender} headerIdent={headerIdent} cellData={this.state.Results.filter(v => v.Type === 1)} /> : null}
        <h1>{this.renderIconCollapse("ResultDrops")}{LocalizedStrings.ResultDrops}</h1>
        <Progress progress percent={workloadDrops} total={100}  success={this.state.Active.LowWorkload > workloadDrops} warning={this.state.Active.LowWorkload < workloadDrops} error = {this.state.Active.OverloadWorkload < workloadDrops}/>
        {this.state.ResultDrops ? <DynamicTable cellRender={cellRender} headerIdent={headerIdent} cellData={this.state.Results.filter(v => v.Type === 0)} /> : null}
        <h1>{this.renderIconCollapse("ResultBridle")}{LocalizedStrings.ResultBridle}</h1>
        <Progress progress percent={workloadBridle} total={100}  success={this.state.Active.LowWorkload > workloadBridle} warning={this.state.Active.LowWorkload < workloadBridle} error = {this.state.Active.OverloadWorkload < workloadBridle}/>
        {this.state.ResultBridle ? <DynamicTable cellRender={cellRender} headerIdent={headerIdent} cellData={this.state.Results.filter(v => v.Type === 2)} /> : null}
        <h1>{this.renderIconCollapse("ResultTruss")}{LocalizedStrings.ResultTruss}</h1>
        <Progress progress percent={workloadTruss} total={100}  success={this.state.Active.LowWorkload > workloadTruss} warning={this.state.Active.LowWorkload < workloadTruss} error = {this.state.Active.OverloadWorkload < workloadTruss}/>
        {this.state.ResultTruss ? Object.keys(workloadTrussKeyed).map((e)=> renderCSTable(e)) : null}
        <Suspense fallback={<></>}>
            {this.renderLine()}
        </Suspense>
        
        <h1>{this.renderIconCollapse("ResultGroundSupport")}{LocalizedStrings.ResultGroundSupport}</h1>
        <Progress progress percent={workloadGroundSupport} total={100}  success={this.state.Active.LowWorkload > workloadGroundSupport} warning={this.state.Active.LowWorkload < workloadGroundSupport} error = {this.state.Active.OverloadWorkload < workloadGroundSupport}/>
        {this.state.ResultGroundSupport ? <DynamicTable cellRender={cellRender} headerIdent={headerIdent} cellData={this.state.Results.filter(v => v.Type === 3)} /> : null}
        </>
        );
    }



    renderIconCollapse(key)
    {
        return <Icon name={!this.state[key] ? "angle right": "angle down"} onClick={()=>this.setState({[key]: !this.state[key]})}/>
    }




    
   setupCallbacks()
   {
    globalCallbacks.refreshStructuralCalculationList = async () => 
    {
        let res     = await window.LR_GetStructuralResult()
        let active  = await window.LR_GetActiveLoadCombination()
        let errors  = await window.LR_GetDrawingErrors()


        let tree = await window.LR_GetObjectTree({
            Async: true, 
            IncludeProperties:
            [
              "Name",
              "SupportProperties"
            ]
          })
    
          this.setState( { 
            tree : tree, 
          });
        this.setState({Results: res, Active: active, tree: tree, Errors: errors})
    }


    
   }

   renderLine()
   {
     let entries = []
     for(let e of this.state.tree)
     {
       if(e.SupportProperties )
       {
         for(let s of e.SupportProperties)
         {
          if(s.SupportType == GEOMETRY_SUPPORT_TYPE_Rope)
          {
           entries.push({
             Name: e.NameIdentifier + " - " + s.Name,
             ChainShortenEffectWorkload: s.ChainShortenEffectWorkload,
             ChainShortenEffectTrussWorkload: s.ChainShortenEffectTrussWorkload,
             ChainShortenEffectTruss: s.ChainShortenEffectTruss,
             ChainShortenEffect: s.ChainShortenEffect,
           })
          }
         }
       }
     }
 
 
 
     return(
       <>
       
       <h1>{this.renderIconCollapse("ChainShortenEffectPercent")}{LocalizedStrings.ChainShortenEffectPercent}</h1>
       {this.state.ChainShortenEffectPercent ? <Line       
       options={{
         responsive: true,
         plugins: {
           legend: {
             display: false,
             position: 'top',
           },
           title: {
             display: false,
           },
         }}
       } 
         data={
         {
           labels: entries.map(e=>e.Name),
           datasets: [
             {
               stepped: "middle",
               label: LocalizedStrings.ChainShortenEffectTruss,
               data: entries.map(e=>e.ChainShortenEffectTruss),
               borderColor: 'green',
               backgroundColor: 'rgba(255, 2, 1, 0.5)',
             },
             {
               stepped: "middle",
               label: LocalizedStrings.ChainShortenEffect,
               data: entries.map(e=>e.ChainShortenEffect),
               borderColor: 'blue',
               backgroundColor: 'rgba(255, 2, 1, 0.5)',
             },
           ],
         }
       } /> : null}

       <h1>      {this.renderIconCollapse("ChainShortenEffectByWorkload")}
{LocalizedStrings.ChainShortenEffectByWorkload}</h1>
       {this.state.ChainShortenEffectByWorkload ? <Line       
       options={{
         responsive: true,
         plugins: {
           legend: {
             display: false,
             position: 'top',
           },
           title: {
             display: false,
           },
         }}
       } 
         data={
         {
           labels: entries.map(e=>e.Name),
           datasets: [
             {
               stepped: "middle",
               label: LocalizedStrings.ChainShortenEffectTrussWorkload,
               data: entries.map(e=>e.ChainShortenEffectTrussWorkload),
               borderColor: 'green',
               backgroundColor: 'rgba(255, 2, 1, 0.5)',
             },
             {
               stepped: "middle",
               label: LocalizedStrings.ChainShortenEffectWorkload,
               data: entries.map(e=>e.ChainShortenEffectWorkload),
               borderColor: 'blue',
               backgroundColor: 'rgba(255, 2, 1, 0.5)',
             },
           ],
         }
       } /> : null}
       </>
     )
   }


   renderError = (name, j) => 
    {
        let errorHeader = name.split("_").join(" ")
        if(name === "OUT_USED_TWO_TIMES")
        {
            errorHeader = "OUTPUT USED TWO TIMES"
        }

        let countErrors = this.state.Errors[name]?.length

        return(
            <CollapsableDevider countErrors={countErrors} header={errorHeader} navigationTable>
                {this.state.Errors[name]?.map((entry, i) => {return <DrawingErrorNode key={i} entry={entry} i={i} j={j}/>})}
            </CollapsableDevider>
        )
    }
}

//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      globalSettings: state[GLOBAL_SETTINGS].data.GlobalSettings,
    };
}

let componentExport;
if(!process.env.JEST_WORKER_ID) { componentExport = connect(mapStateToProps)(StructuralList) }
else                                         { componentExport = StructuralList }


export default componentExport 