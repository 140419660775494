//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { CSSProperties, useEffect, useMemo, useState } from "react";
import { Table } from "semantic-ui-react";
import { globalWindowInterface, lrObjectInterface, lrWorksheetStateInterface, uuidStr } from "../../../util/callbackTypes";
import { BASE_UNIT, kWireType } from "../../../util/defines";
import UnitInput from '../../Basics/BasicUnitInput';
import { TableGroupingResultInterface } from "../ObjectProperties/DefaultTable";
import { PropertyListItemInterface } from "../ObjectProperties/FieldSearch";
import InventoryList from "./InventoryList";
import { globalCallbacks } from "../../../util/callback";

declare const window: globalWindowInterface;

interface SingleAccessory {
  Inv_BookedTotal: number,
  Inv_CountSpare: number,
  Inv_CountTotal: number,
  Inv_Count: number
  UUID: uuidStr,
  Name: string,
  PrefixName: string
}
interface SingleInventoryGroupingResult {
  AccessoriesData: {
    AffectedList: uuidStr[],
    Accessory: SingleAccessory,
    ArrayIndex: number
  }[]
  FullDataList: {
    Accessories: SingleAccessory[]
    DefiningResource: uuidStr
    DifferentSupplierPart: boolean
    FoundSuppliers: {
      PartNumber: string
      Supplier: string
    }[]
    GroupValues: { [key: string]: string }
    Inv_BookedTotal: number
    Inv_Count: number
    Inv_CountSpare: number
    Inv_CountTotal: number
    Objects: uuidStr[]
  }[]
}

type InventoryGroupingResult = SingleInventoryGroupingResult[]

interface InventoryGroupingResultInterface<T> extends TableGroupingResultInterface<T> {
  LeafCounter?: number
  GroupChildren: InventoryGroupingResultInterface<T>[]
}

const LEVEL_WIDTH_INCREASE = 7.5 //mm

function SingleHeaderCell(this: unknown, { style, text }: { style: CSSProperties, text: string }) {
  const toShow = useMemo(() => {
    if (/(%%__COLOR\(((([+-]?[0-9]*[.])?[0-9]+,){2}([+-]?[0-9]*[.])?[0-9]+;?)+\)__%%)/.test(text)) {
      let cStr = text.slice(10, -5);
      let split = cStr.split(";")
      let subSplit = split.map(i => i.split(",").map(j => Number(j))).map(i => ({ X: i[0], Y: i[1], Z: i[2] }))
      return <UnitInput
        baseUnit={BASE_UNIT.COLOR}
        value={{
          Color: subSplit,
          Name: ""
        }}
        readOnly
        transparent
        label={false}
      />
    }

    return text
  }, [text])


  return <Table.HeaderCell style={style} data-is_header_cell="true">
    {toShow}
  </Table.HeaderCell>
}

function SingleTable(this: unknown, { currLevel, tableProps, maxLevels, indent, headerWidth, inventoryData }: {
  currLevel: InventoryGroupingResultInterface<lrObjectInterface>,
  maxLevels: number,
  tableProps: any,
  headerWidth: number,
  indent?: boolean,
  inventoryData: InventoryGroupingResult
}) {
  let lv = currLevel.Level - 1

  let incValue = indent ? LEVEL_WIDTH_INCREASE : 0
  let nHeaderW = headerWidth + incValue * (maxLevels - lv)
  return <div style={{ width: "auto" }}>

    {
      (maxLevels > 0) ?
        <Table style={{ marginLeft: `${incValue * lv}mm`, width: "auto" }} celled>
          <Table.Header>
            <Table.Row data-is_header_row="true">
              {
                currLevel.Grouping.PropertiesCellList.map(i =>
                  <SingleHeaderCell
                    style={{ width: `${nHeaderW / currLevel.Grouping.PropertiesCellList.length}mm`, paddingLeft: 5, paddingRight: 5, paddingBottom: 5, paddingTop: 5 }}
                    text={i}
                  />
                )
              }
            </Table.Row>
          </Table.Header>
        </Table>
        :
        null
    }

    {currLevel.GroupChildren.length ?
      currLevel.GroupChildren.map(i => <SingleTable
        inventoryData={inventoryData}
        indent={indent}
        maxLevels={maxLevels}
        currLevel={i}
        tableProps={tableProps}
        headerWidth={headerWidth}
        key={i.Grouping.GroupByProperty} 
        />
        )
      :
      <div style={{ marginLeft: `${incValue * (lv + 1)}mm` }}>
        <InventoryList
          {...tableProps}
          inventoryData={inventoryData[currLevel.LeafCounter ?? 0] ?? { AccessoriesData: [], FullDataList: []}}
        />
      </div>
    }
  </div>
}

// Doing this here because doing it in the core would require an unreasonable amount of work
function FilterPowerPorts(obj: { Resolved: lrObjectInterface, UUID: uuidStr }, allowInputs: boolean, allowOutputs: boolean) {
  let d = obj.Resolved
  if (d.ElectricalConnections && d.ElectricalConnections.length && (!allowInputs || !allowOutputs)) {
    let nArr = d.ElectricalConnections.filter(item => {
      if (item.OwnWireType === kWireType.Input) {
        return allowInputs
      } else if (item.OwnWireType === kWireType.Output) {
        return allowOutputs
      }
      return true
    })
    if (nArr.length !== d.ElectricalConnections.length) {
      return { Resolved: { ...obj.Resolved, ElectricalConnections: nArr }, UUID: d.UUID }
    }
  }

  return obj
}

export default function InventoryTable(this: unknown, props: {
  inventoryWorksheetState: lrWorksheetStateInterface,
  worksheetUUID: uuidStr
  propertyList: PropertyListItemInterface[],
  headerData: any,
  objects: { Resolved: lrObjectInterface, UUID: uuidStr }[]
  onUpdateWorksheetStateSortBy: (...arg: any) => void
  worksheetScale: number,
  pages: any
  showPages: boolean,
  UsePageBreak: boolean,
  showInventoryError: boolean,
  showAccessories: boolean,
  selectedPreset: string,
  onSelectionChanged: (a: string, b: any[], c: number) => any,
  printMargin: {
    Bottom: number,
    Left: number,
    Right: number,
    Top: number
  },
}) {
  let currentWorksheetState = props.inventoryWorksheetState
  let searchFilter = currentWorksheetState.SearchFilter

  let objectMap = useMemo(() => {
    let ret: { [key: uuidStr]: { Resolved: lrObjectInterface, UUID: uuidStr } } = {}
    for (let it of props.objects) {
      ret[it.UUID] = FilterPowerPorts(it, currentWorksheetState.ShowFilterInputs, currentWorksheetState.ShowFilterOutputs)
    }
    return ret
  }, [props.objects, currentWorksheetState])

  let [objectTree, setObjectTree] = useState<InventoryGroupingResultInterface<lrObjectInterface>[]>([])
  let [inventoryData, setInventoryData] = useState<InventoryGroupingResult>([])
  let [maxLevels, setMaxLevels] = useState(0)

  let recReplaceCounter = 0;
  const recReplace = (item: InventoryGroupingResultInterface<uuidStr>): InventoryGroupingResultInterface<lrObjectInterface> => {
    return {
      ...item,                                                                //Grouping header
      ItemChildren: item.ItemChildren.map(i => objectMap[i]).filter(i => i),  // Objects on that level
      GroupChildren: item.GroupChildren.map(i => recReplace(i)),              // Subgroups on that level
      LeafCounter: item.ItemChildren.length ? (recReplaceCounter++) : undefined
    }
  }
  useEffect(() => {
    const toLRWorksheet = (data: lrWorksheetStateInterface) => {
      const mapProp = (i)=>({
        PropertyName: i.PropertyName,
        ArrayName: i.ArrayName
      })
      return {
        ExtraEnabledProperties:  (data as any).ExtraEnabledProperties.map(mapProp),
        GroupByProperty:         data.GroupByProperty,
        ShowFilterConsumer:      data.ShowFilterConsumer,
        ShowFilterAssemblyGroup: data.ShowFilterAssemblyGroup,
        ShowFilterDistributer:   data.ShowFilterDistributer,
        ShowFilterPlugBox:       data.ShowFilterPlugBox,
        ShowFilterGenerator:     data.ShowFilterGenerator,
        ShowFilterFixture:       data.ShowFilterFixture,
        ShowFilterStructures:    data.ShowFilterStructures,
        ShowFilterSupport:       data.ShowFilterSupport,
        ShowFilterAudio:         data.ShowFilterAudio,
        ShowFilterMeshes:        data.ShowFilterMeshes,
        ShowFilterDockPoints:    data.ShowFilterDockPoints,
        ShowFilterVisible:       data.ShowFilterVisible,
        ShowFilterSelected:      data.ShowFilterSelected,
        PropertyBasedFilter:     data.PropertyBasedFilter,
        ShowFilterGroundSupport: data.ShowFilterGroundSupport,
        ShowFilterHouseRiggingPoint: data.ShowFilterHouseRiggingPoint,
        InventorySummeryProps:   data.InventorySummeryProps.map(mapProp),
      }
    }
  
      window.LR_GetInventoryData({
        WorksheetState: toLRWorksheet(currentWorksheetState),
        UseGrouping: !props.UsePageBreak,
        Async: true,
      }).then(({ result, inventoryResult, maxLevels: mLV }) => {
        recReplaceCounter = 0;
        let a = result.map(j => recReplace(j))
        setMaxLevels(mLV)

        for(let i of inventoryResult)
        {
          let nAccList: SingleInventoryGroupingResult["AccessoriesData"] = []
          for(let j of i.AccessoriesData)
          {
            for(let [key, value] of Object.entries(j.Accessory.InventoryMap) as any){
              nAccList.push({
                ...j,
                Accessory: {
                  ...value,
                  UUID: j.Accessory.UUID,
                  BaseName: j.Accessory.Name,
                  PrefixName: key,
                  Name: key ? `${j.Accessory.Name} (${key})` : j.Accessory.Name
                }
              })
            }
          }

          i.AccessoriesData = nAccList
        }

        setInventoryData(inventoryResult)
        setObjectTree(a)
      })

  }, [currentWorksheetState, props.worksheetUUID, props.selectedPreset, props.UsePageBreak])

  let headerWidth = useMemo(() => {
    let arrNames = props.propertyList.map(i => i.ArrayName ? `${i.PropertyName};${i.ArrayName}` : i.PropertyName)
    arrNames.push(
      "Inv_BookedTotal;CountDefinition",
      "Inv_Count",
      "Inv_CountSpare;CountDefinition",
      "Inv_CountTotal"
    )

    for (let i of currentWorksheetState.InventorySummeryProps) {
      arrNames.push("Grouping_" + i.PropertyName)
    }

    let list = Object.entries(props.headerData?.InventoryList ?? {}) as [string, { Width: number }][];
    let size = 0

    for (let i of list) {
      if (arrNames.includes(i[0])) {
        size += i[1].Width
      }
    }

    let keys = list.map(i => i[0])
    for (let i of arrNames) {
      if (!keys.includes(i)) {
        size += 50
      }
    }
    return size
  }, [props.propertyList, props.headerData, currentWorksheetState.InventorySummeryProps])

  return <>{
    objectTree.map((t, i) => (<SingleTable
      key={i}
      headerWidth={headerWidth}
      inventoryData={inventoryData}
      currLevel={t}
      maxLevels={maxLevels}
      indent={currentWorksheetState.UseGroupingIndentation}
      tableProps={{
        onSelectionChanged: props.onSelectionChanged,
        properties: props.propertyList,
        showOnlySelected:currentWorksheetState.ShowFilterSelected,
        showInventoryError: props.showInventoryError,
        showAccessories: props.showAccessories,
        headerData: props.headerData,
        showPages: props.showPages,
        pages: props.pages,
        printMargin: props.printMargin,
        printScale: props.worksheetScale,
        searchFilter,
        inventorySummeryProps: currentWorksheetState.InventorySummeryProps
      }} />
    ))
  }</>
}