//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
class ResourceLoadContainer
{
    constructor()
    {
        this.map = {};

        this.callbacks = {}
    }

    get(key, callback)
    {
        if (this.map[key])
        {
            callback(this.map[key])
        }
        else
        {
            this.listenForLoad(key, callback);
        }
    }

    listenForLoad(key, callback)
    {
        if (this.callbacks[key])
        {
            this.callbacks[key].push(callback);
        }
        else
        {
            this.callbacks[key] = [callback]
        }
    }

    load(key, texture)
    {
        if (!texture) { return; }
        this.map[key] = texture;
        if (this.callbacks[key])
        {
            this.callbacks[key].forEach(callback => callback(texture))
            this.callbacks[key] = undefined
        }
    }

    has(key)
    {
        return this.map[key] !== undefined
    }

    hasOrLoading(key)
    {
        return this.has(key) || !!this.callbacks[key]
    }

    clear()
    {
        this.map = {}
        this.callbacks = {}
    }
}

export default ResourceLoadContainer;