//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Icon, Button, Table, Message } from 'semantic-ui-react'
import { globalCallbacks } from '../../util/callback';
import LocalizedStrings from "../../localization/ResourceManager";
import LRModal from '../Basics/BasicModal';

class CaseTemplateDisplay extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    {
      documents: [
        {
          Name: "Template Case",
        },
      ],

      openedDocumentBuffer : "",

      open:false,
    };

  }

  componentDidMount() 
  {
    this.setUpCallbacks();
    globalCallbacks.getExternalDocuments();
  }

  render() 
  {
    return(
      <React.Fragment>
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{LocalizedStrings.Name}</Table.HeaderCell>
            <Table.HeaderCell/>
          </Table.Row>
        </Table.Header>
        {this.state.documents.map( entry => {return this.renderRow(entry)})}
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan='3'>
              <Button
                floated='right'
                icon
                labelPosition='left'
                positive
                onClick={this.onAddDocument}
                size='small'>
                  <Icon name='file outline pdf' />{LocalizedStrings.AddDocument}
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>

      {this.getPdfViewer()}

      {/* This needs to be removed when we fix this. */}
      <Message warning>
        <Message.Header>WARNING: files larger than 1.5 MB can not be opened.</Message.Header>
      </Message>

      </React.Fragment>
    );
  }

  renderRow(entry) 
  {
    return(<React.Fragment>
          <Table.Row>
            <Table.Cell>{entry.Name}</Table.Cell>
            <Table.Cell>
              <Button floated='right' color="red"  size="tiny" onClick={()=> this.onDeleteDocument(entry.UUID)}>{LocalizedStrings.Delete}</Button>
              <Button floated='right' color="blue" size="tiny" onClick={()=> this.onOpenDocument(entry)}>{LocalizedStrings.Open}</Button>
            </Table.Cell>
          </Table.Row>
          </React.Fragment>)
  }

  getPdfViewer = () =>
  {
    let openedDoc = this.state.openedDocumentBuffer;
    return (
      <LRModal  closeIcon
                size="large"
                title={this.state.openedDocumentName}
                open={this.state.open}
                onCancelClick={this.onCloseDocument}>
                <object width="100%" height="100%" type="application/pdf" data={"data:application/pdf;base64," + openedDoc}>An error occured while opening the document</object>
      </LRModal>
    )
  }

  onAddDocument = () =>
  {
    window.LR_ImportExternalDocument({ });
  }

  onDeleteDocument = (documentUuid) =>
  {
    window.LR_DeleteExternalDocument({UUID: documentUuid});
  }

  onOpenDocument = (document) =>
  {
    let openedDocumentBuffer  = document.DocumentBuffer;
    let openedDocumentName    = document.Name;

    this.setState({openedDocumentBuffer, openedDocumentName, open: true});
  }

  onCloseDocument = () =>
  {
    this.setState({open: false});
  }

  setUpCallbacks()
  {
    globalCallbacks.getExternalDocuments = async () =>
    {
      const ret    = await window.LR_GetExternalDocuments({});
      let documents = ret.ExternalDocuments;
      if (documents === undefined) { documents = []; }
      this.setState({documents});
    }
  }
}

export default CaseTemplateDisplay;

