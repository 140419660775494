//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Segment, Header, Icon, Button } from 'semantic-ui-react';
import { BASE_UNIT_AMPERE, BASE_UNIT_ANGLE, BASE_UNIT_AREA, BASE_UNIT_DENSITY, BASE_UNIT_FORCE, BASE_UNIT_FORCE_PER_DISTANCE, BASE_UNIT_LENGTH, BASE_UNIT_MOMENT_PER_DISTANCE, BASE_UNIT_NUMBER, BASE_UNIT_PAGE_LENGTH, BASE_UNIT_POWER, BASE_UNIT_PRESSURE, BASE_UNIT_VOLTAGE, BASE_UNIT_VOLUME, BASE_UNIT_WEIGHT, RESOURCE_TYPE_AssemblyGroup } from '../../../util/defines';
import DynamicTable, { TABLE_SELECTION_TYPE_Cell, TABLE_SELECTION_TYPE_None } from '../../Basics/DynamicTableView';
import LocalizedStrings from "../../../localization/TableViewComponent";
import {  getUnit, getUnitValueFromCore } from '../../Basics/BasicUnitInput';


class PropertyList extends Component
{
    constructor(props)
    {
        super(props)

        this.state = {
            selectedCells: [],
            sortedProperty: "",
            sortedDirection: "descending",
            widthMap: {}
        }
        
        this.selectionMode = true
        this.isBoxSelecting = false
        this.boxSelectStartCell = undefined
        this.startBoxSelection = {x: 0, y: 0}
        this.sortedObjects = []

        this.grabIndex = 0

        this.tableRef = React.createRef()
    }

    render()
    {
        // Return empty table not null
        if (!this.props.properties) { return null; }
        if (!this.props.objects) { return null;}

        let cellIdentifier = this.props.properties.filter((prop) => {
            if (!prop?.CustomFilter) { return true; }
            let display = false
            this.props.objects.forEach(obj => {
                display |= prop.CustomFilter(obj)
            })
            return display
        }).map(prop => {
            let ident = this.getPropertyIdentifier(prop)
            return {
                ident: ident,
                label: this.props.LocalizedOverwrite[ident] ? this.props.LocalizedOverwrite[ident] : prop.LocalizedName,
                RightClickIdent: JSON.stringify({...prop, Ident: ident}),
                unit: prop.IsUnitBased ? prop.BaseUnit : undefined,
                IfInArrayWithName: prop.IfInArrayWithName
            }
        })

        let cellRendering = (entry, rowIndex, extraEntries) =>
        {
            let result = {}
            
            this.props.properties.forEach(prop => {
                let propertyName = prop.PropertyName
                let arrayName    = prop.ArrayName

                if (arrayName)
                {
                    let ident = this.getPropertyIdentifier(prop)
                    let array = entry.Resolved[arrayName]
                    if (Array.isArray(array) && array.length > 0)
                    {
                        if (prop.CustomCellFunction)
                        {
                            let selectedRow = 0
                            if(Array.isArray(extraEntries) && extraEntries.length > 0)
                            {
                                selectedRow = extraEntries[0].__RowIndex
                            }
                            result[this.getPropertyIdentifier(prop)] = prop.CustomCellFunction(propertyName, array[selectedRow])
                            return;
                        }
                        else if(Array.isArray(extraEntries) && extraEntries.length > 0)
                        {
                            result[this.getPropertyIdentifier(prop)] = extraEntries[0][ident]
                            return;
                        }
                    }
                }
                else
                {
                    if (prop.CustomCellFunction)
                    {
                        result[this.getPropertyIdentifier(prop)] = prop.CustomCellFunction(propertyName, entry.Resolved)
                        return;
                    }
                    else
                    {
                        result[this.getPropertyIdentifier(prop)] = entry.Resolved[prop.PropertyName]
                        return;
                    }
                }
            })

            return result
        }

        // Rendering array properties
        let extraCellRendering = (parentEntry, entry, rowIndex) =>
        {

            let result = {}
            result.__Values = {}
            result.__RowIndex = 0
            this.props.properties.forEach(prop => {
                let propertyName = prop.PropertyName;
                let arrayName = prop.ArrayName;

                if (arrayName)
                {
                    let array = parentEntry.Resolved[arrayName]
                    if (Array.isArray(array) && array.length > rowIndex)
                    {
                        result.__RowIndex = rowIndex
                        result.__Values[this.getPropertyIdentifier(prop)] = array[rowIndex][propertyName]
                        if (prop.CustomCellFunction)
                        {
                            result[this.getPropertyIdentifier(prop)] = prop.CustomCellFunction(propertyName, array[rowIndex])
                            return;
                        }
                        else
                        {
                            result[this.getPropertyIdentifier(prop)] = array[rowIndex][propertyName]
                            return;
                        }
                    }
                }
            })

            return result
        }

        //this.sortedObjects = this.sortBy(this.props.objects, this.state.sortedProperty, this.state.sortedDirection)
        let sortedObjects = this.stripEmptyObjects(this.props.objects)
        sortedObjects = this.applySearchFilter(sortedObjects)
        
        
        // We have to add the Extra data array the size of the biggest array displayed in the object
        sortedObjects.forEach(obj => {
            let arraySize = 0; // No Array
            this.props.properties.forEach(prop => {
                if (prop.ArrayName)
                {
                    let array = obj.Resolved[prop.ArrayName]
                    if (Array.isArray(array) && array.length > arraySize)
                    {
                        let count = 0;
                        array.forEach(elem => {
                            if (elem[prop.PropertyName]) 
                            { 
                                count++; 
                            }
                        })
                        // One less than original, because index 0 will be rendered by normal cellRendering
                        let newArraySize = count

                        if (newArraySize > arraySize)
                        {
                            arraySize = newArraySize
                        }
                    }
                }
            })
            if (arraySize > 0)
            {
                obj.ExtraData = [];
                for (let i = 0; i < arraySize; i++) { obj.ExtraData.push(0); }
            }
            else
            {
                obj.ExtraData = undefined
            }
        })

        let sortFunction = (objectA, objectB, propertyIdentifer) =>
        {
            if (propertyIdentifer.includes(";")) // Array Property
            {
                //------------------------------------------------------------------------------------------------------
                // This is for sorting the array properties. The __Values Entry is generated by the function 'extraCellRendering'
                if(objectA.__Values && objectB.__Values)
                {
                    return {First: objectA.__Values[propertyIdentifer], Second: objectB.__Values[propertyIdentifer]}
                }
                //------------------------------------------------------------------------------------------------------
                // Otherwise do the normal array behavior
                else
                {
                    let stringNames = propertyIdentifer.split(";");
                    let propertyName = stringNames[0]
                    let arrayName = stringNames[1]
                    if (arrayName && propertyName)
                    {
                        let arrayA = objectA.Resolved[arrayName]
                        let arrayB = objectB.Resolved[arrayName]
                        if (Array.isArray(arrayA) && Array.isArray(arrayB))
                        {
                            if (arrayA.length > 0 && arrayB.length > 0)
                            {
                                return {First: arrayA[0][propertyName], Second: arrayB[0][propertyName]}
                            }
                        }
                    }
                }
            }
            else // Not an Array
            {
                return {First: objectA?.Resolved?.[propertyIdentifer], Second: objectB?.Resolved?.[propertyIdentifer]}
            }
        }

        if(cellIdentifier.length === 0)
        {
            return <Segment placeholder>
            <Header icon>
              <Icon name='columns' />
              {LocalizedStrings.NoColumns}
            </Header>
            <Button primary onClick={(e)=>{if(this.props.AddDefaultColum)this.props.AddDefaultColum(e)}}>{LocalizedStrings.AddNameColumn}</Button>
          </Segment>
        }

        let summeryTable = undefined
        if (this.props.ShowSummeryTable && !this.props.showPages)
        {
            summeryTable = this.renderSummeryTable(cellIdentifier, sortedObjects, cellRendering);
        }
        
        return (
          <>
            <div>
              <DynamicTable
                headerIdent={cellIdentifier}
                cellRender={cellRendering}
                extraCellRender={extraCellRendering}
                sortFunction={sortFunction}
                selectionChanged={this.selectionChanged}
                headerData={this.props.headerData}
                tableName="PropertyList"
                NeedsShift={true}
                selectionType={TABLE_SELECTION_TYPE_Cell}
                pages={this.props.pages}
                cellData={sortedObjects}
                worksheet={this.props.worksheet}
                onSort={this.props.onSort}
                sortedProperties={this.props.sortedProperties}
                showPages={this.props.showPages}
                printMargin={this.props.printMargin}
                pdfFormat={this.props.pdfFormat}
                printScale={this.props.worksheetScale}
                ref={this.tableRef}
                selectedPreset={this.props.selectedPreset}
              />
            </div>
            <div>
                {this.props.ShowSummeryTable ? (
                    <>
                    <div style={{ position: "sticky", bottom: "0" }}>
                        {summeryTable}
                    </div>
                    </>
                ) : null}
            </div>
          </>
        );
    }

    isUnitNumber = (unit) =>
    {
        if (unit === undefined)
        {
            return false;
        }
        switch (unit)
        {
        case BASE_UNIT_NUMBER:
        case BASE_UNIT_LENGTH:
        case BASE_UNIT_WEIGHT:
        case BASE_UNIT_VOLUME:
        case BASE_UNIT_AREA:
        case BASE_UNIT_ANGLE:
        case BASE_UNIT_VOLTAGE:
        case BASE_UNIT_AMPERE:
        case BASE_UNIT_POWER:
        case BASE_UNIT_FORCE:
        case BASE_UNIT_PAGE_LENGTH:
        case BASE_UNIT_PRESSURE:
        case BASE_UNIT_DENSITY:
        case BASE_UNIT_FORCE_PER_DISTANCE:
        case BASE_UNIT_MOMENT_PER_DISTANCE:
            return true;
        default: return false;
        }
    }

    renderSummeryTable = (headers, cellData) =>
    {
        let summeryData = {}


        for (let cellIdent of this.props.properties)
        {
            let cellIdentString = this.getPropertyIdentifier(cellIdent)
            summeryData[cellIdentString] = undefined
            if (cellIdent.IsUnitBased && this.isUnitNumber(cellIdent.BaseUnit))
            {
                summeryData[cellIdentString] = 0
                for (let data of cellData)
                {
                    if (cellIdent.ArrayName)
                    {
                        let array = data.Resolved[cellIdent.ArrayName]
                        if (Array.isArray(array))
                        {
                            for (let elem of array)
                            {
                                if (cellIdent.PropertyName)
                                {
                                    if (elem[cellIdent.PropertyName])
                                    {
                                        summeryData[cellIdentString] += elem[cellIdent.PropertyName]
                                    }
                                }
                                else
                                {
                                    summeryData[cellIdentString] += elem
                                }
                            }
                        }
                    }
                    else
                    {
                        
                        summeryData[cellIdentString] += data.Resolved[cellIdent.PropertyName]
                    }
                }

                summeryData[cellIdentString] = getUnitValueFromCore(summeryData[cellIdentString], getUnit(cellIdent.BaseUnit, this.props.globalSettings), cellIdent.BaseUnit)
            }
        }

        let cellRendering = (entry, index) =>
        {
            return entry.Resolved
        }
        

        return <DynamicTable    headerIdent={headers}
                                cellRender={cellRendering}
                                headerData={this.props.headerData}
                                tableName="PropertyList"
                                selectionType={TABLE_SELECTION_TYPE_None}
                                pages={this.props.pages}
                                cellData={[{Resolved: summeryData}]}
                                worksheet={this.props.worksheet}
                                onSort={this.props.onSort}
                                sortedProperties={this.props.sortedProperties}
                                showPages={this.props.showPages}
                                printMargin={this.props.printMargin}
                                printScale={this.props.worksheetScale}/>
    }

    selectionChanged = (selectedIdent, selectedObjects, arrayIndex) => 
    {
        window.LR_Select({SelectionMode: 0, SelectedList: selectedObjects.map(obj => obj.UUID), Selected: true})
        if (this.props.onSelectionChanged)
        {
            this.props.onSelectionChanged(selectedIdent, selectedObjects, arrayIndex)
        }
    }

    getPropertyIdentifier(property)
    {
        if (property.ArrayName)
        {
            return property.PropertyName + ";" + property.ArrayName
        }
        return property.PropertyName
    }


    stripEmptyObjects(dataArray)
    {
        return dataArray.filter(elem => {
            for (let i = 0; i < this.props.properties.length; i++)
            {
                let currentProp = this.props.properties[i]
                if (currentProp.ArrayName)
                {
                    if (elem.Resolved[currentProp.ArrayName])
                    {
                        return true;
                    }
                }
                else 
                {
                    if (elem.Resolved[currentProp.PropertyName])
                    {
                        return true;
                    }
                }
            }
            return false;
        })
    }

    applySearchFilter = (objects) =>
    {
        let searchFilter = this.props.searchFilter;

        if(!searchFilter)
        {
            return objects
        }

        searchFilter = searchFilter.toLowerCase()

        return objects.filter(element => {
            //------------------------------------------------------------------------------
            // Check Property List
            for (let i = 0; i < this.props.properties.length; i++)
            {
                if (!element || !element.Resolved) { continue; }
                let currentProperty = this.props.properties[i];
                let currentValue = element.Resolved[currentProperty.PropertyName];
                
                if (currentValue !== undefined) {

                    
                    
                    if (currentValue.toString().toLowerCase().includes(searchFilter)) { return true;}
                    if (currentValue.toString().toLowerCase().includes(searchFilter)) { return element.Resolved.ResourceType !== RESOURCE_TYPE_AssemblyGroup;}
                }
            }
            return false
        })
    }

    /**
     * Get called from outside
     */
    objectSelectionChanged = (selectedObjects) =>
    {
        if (!this.tableRef.current)
        {
            return;
        }
        let newSelectedIndex = []
        selectedObjects.forEach(uuid => {
            let index = this.tableRef.current.getSortedIndexOfData(element => element.Resolved.UUID === uuid)
            if (index !== -1)
            {
                newSelectedIndex.push(index)
            }
        })
        this.tableRef.current.setSelectedIndex(newSelectedIndex)
    }

}

export default PropertyList;