//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import {Form, Grid, Tab} from 'semantic-ui-react'
import LocalizedStrings from "../../../localization/AlignDialog";
import ColorInputField from "../../ColorPicker/ColorInputField";
import {defaultCie} from "../../ColorPicker/utilities"

class AlignColor extends Component 
{   
  constructor(props)
  {
    super(props);
    
    console.log("props: ", props)
    let initState = {
      value1_x: defaultCie.fx,
      value1_y: defaultCie.fy,
      value1_L: defaultCie.f_Y,
      value2_x: defaultCie.fx,
      value2_y: defaultCie.fy,
      value2_L: defaultCie.f_Y,
    }
    if (props.InitValue)
    {
      let splittedVal = props.InitValue.split(";");
      initState.value1_x = parseFloat(splittedVal[0]);
      initState.value1_y = parseFloat(splittedVal[1]);
      initState.value1_L = parseFloat(splittedVal[2]);
      initState.value2_x = initState.value1_x
      initState.value2_y = initState.value1_y
      initState.value2_L = initState.value1_L
    }

    this.state = {
      type: 0,
      value1_x: initState.value1_x,
      value1_y: initState.value1_y,
      value1_L: initState.value1_L,
      value2_x: initState.value2_x,
      value2_y: initState.value2_y,
      value2_L: initState.value2_L,
    }

    this.panes = [
      { menuItem: LocalizedStrings.Distribute, render: () => 
        <Tab.Pane >
          <EditColorComponent value1_x={this.state.value1_x} 
                              value1_y={this.state.value1_y}
                              value1_L={this.state.value1_L}
                              value2_x={this.state.value2_x}
                              value2_y={this.state.value2_y}
                              value2_L={this.state.value2_L}
                              onColor1Changed={this.color1Change} 
                              onColor2Changed={this.color2Change}/>
        </Tab.Pane> 
      },
      { menuItem: LocalizedStrings.Alternate,  render: () => 
        <Tab.Pane >
          <EditColorComponent value1_x={this.state.value1_x} 
                              value1_y={this.state.value1_y}
                              value1_L={this.state.value1_L}
                              value2_x={this.state.value2_x}
                              value2_y={this.state.value2_y}
                              value2_L={this.state.value2_L}
                              onColor1Changed={this.color1Change} 
                              onColor2Changed={this.color2Change}/>
        </Tab.Pane> 
      },
    ]
  }

  color1Change = (cie) =>
  {
    this.setState({value1_x: cie.fx, value1_y: cie.fy, value1_L: cie.f_Y})
  }

  color2Change = (cie) =>
  {
    this.setState({value2_x: cie.fx, value2_y: cie.fy, value2_L: cie.f_Y})
  }

  render() 
  {

    return (
      <Tab panes={this.panes} 
          onTabChange={(e, { activeIndex }) => {this.setState({type: activeIndex})}}
          activeIndex = {this.state.type}/>
    )
  }

  OnDefaultButton = () =>
  {
    let arg = {...this.state, 
      Property: this.props.Property, 
      Preset: this.props.Preset,
      value1: this.state.value1_x + ";" + this.state.value1_y + ";" + this.state.value1_L,
      value2: this.state.value2_x + ";" + this.state.value2_y + ";" + this.state.value2_L}

      window.LR_SetAlignedValue(arg)
  }
}

//-----------------------------------------------------------------------------
class EditColorComponent extends React.Component
{
  render()
  {
    return (
    <Grid columns={2} divided>
      <Grid.Column>
      <Form.Group widths='equal'>
        <ColorInputField name="Color1" label={LocalizedStrings.LightColor} labelPosition='right'
                         colorX={this.props.value1_x} colorY={this.props.value1_y} colorL={this.props.value1_L} onColorChanged={this.props.onColor1Changed}/>      
      </Form.Group>
      </Grid.Column>
      <Grid.Column>
      <Form.Group widths='equal'>
        <ColorInputField name="Color2"label={LocalizedStrings.LightColor} labelPosition='right'
                         colorX={this.props.value2_x} colorY={this.props.value2_y} colorL={this.props.value2_L} onColorChanged={this.props.onColor2Changed}/>      
      </Form.Group>
      </Grid.Column>
    </Grid>
    )
  }
}

export default AlignColor