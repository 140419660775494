//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import { Component, } from 'react';
import { ipcRenderer } from '../../webApp/ipcRenderer';
import { EDIT_MODE } from '../../redux/redux_defines';
import { connect } from 'react-redux';
import { globalCallbacks } from '../../util/callback';

class WebCommit extends Component 
{
    constructor(props) {
        super(props)
        this.state = {
            diffData: undefined
        }
    }

    componentDidMount() {
        ipcRenderer.on('OnLightRightCoreCallback', this.onShowDiff)
    }

    onShowDiff(_, command) {
        if(command.Command === 'ShowDiffDialog') {
            globalCallbacks.ShowDiffDialog(command.Argument)
        }
    }

    render() 
    {   
        return null
 
    }

    openEditMode() {
        ipcRenderer.sendSync('open-editmode')
    }

    closeEditMode() {
        ipcRenderer.sendSync('close-editmode')
        ipcRenderer.runCallBack('OnLightRightCoreCallback', undefined, {Command:'read-file', Argument: {Import: true}});
    }

    commit() {
        ipcRenderer.sendSync('web-commit')
    }
}

const mapStateToProps = (state) => ({
    editOpen: state[EDIT_MODE].data
})

export default connect(mapStateToProps)(WebCommit)