//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import path from 'path';
import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import { Button, Divider, Dropdown, Icon, Label, List, Loader, Menu, Message, Search } from 'semantic-ui-react';
import LocalizedStrings from "../../localization/ToolSelectComponent";
import { globalCallbacks } from '../../util/callback';
import { globalWindowInterface, uuidStr } from '../../util/callbackTypes';
import { IsRunningAsBrowser, RESOURCE_TYPE, RESOURCE_TYPE_FixtureType, RESOURCE_TYPE_Mesh, RESOURCE_TYPE_PrintLabel, RESOURCE_TYPE_Support, RESOURCE_TYPE_SymbolDef } from '../../util/defines';
import GenericTree from '../../util/GenericTree';
import LRModal from '../Basics/BasicModal';
import DynamicTable, { TABLE_SELECTION_TYPE_Row } from '../Basics/DynamicTableView';
import './SelectResourceModal.css';


declare const window: globalWindowInterface;
const SEARCH_LIMIT = 100
const inBrowser = IsRunningAsBrowser()

type avaiableMessages = "download" | "import" | "modifyResource";

class DataModelNode extends GenericTree{
  visible: boolean = false   //If the Object is opened in the Sidebar Tree, showing its children
  rawData: any
  isCached = false  //If the Object is locally available
  isLoading = false
  ResourceType: RESOURCE_TYPE
  connectedUser: string //if DataNodeType == user -> User owning the Resource
  revisionId?: string

  constructor(ident: string, type?: RESOURCE_TYPE){
    super(ident)
    this.ResourceType = type
  }

  protected mergeObjects(toMerge: DataModelNode){
    this.label = toMerge.label
    this.rawData = toMerge.rawData
    this.ResourceType = toMerge.ResourceType
    this.isCached = toMerge.isCached
    this.connectedUser = toMerge.connectedUser
  }
}


let globalModel = new DataModelNode("~TopResourceNode~")
globalModel.addChild(new DataModelNode("current"))
globalModel.addChild(new DataModelNode("local"))
globalModel.addChild(new DataModelNode("online"))
globalModel.addChild(new DataModelNode("user"))

enum TABS_ENUM{
  CURRENT,
  LOCAL,
  ONLINE,
  USER
}

interface propsInterface {
  open: boolean
  headerAdd: string
  close?: () => void
  resourceType?: RESOURCE_TYPE
  disableNewButton?: boolean,
  disableLocalRessources?: boolean
  handleChangeType?: (v: RESOURCE_TYPE, cb?) => void
  disableTopMenu?: boolean,
  OnlySelect?: boolean,
  SelectRessourceCallback?: (ResourceType: RESOURCE_TYPE, UUID: string, RessourceIdent: string, UserName: string, item: any)=>void
}

interface stateInterface {
  downloadBulkInProgress?: number
  selectedUser?: string
  fixtureTypes?: any
  meshes?: any
  symbolDefs?: any
  localeResources?: any
  UserContentOptions: { key: string, text: string, value: string }[]
  userResourcesLoading: boolean
  serverResourcesLoading: boolean
  previewTextures: any
  isLoading: boolean
  searchValue: string
  activeTab: TABS_ENUM
  cellData: DataModelNode[]
  selectedDataModelNode: DataModelNode
}

function ImportButtons(this: unknown, {resourceType, disableNewButton}: {resourceType: RESOURCE_TYPE, disableNewButton: boolean}){
  const newResource = () => {
    if (resourceType === RESOURCE_TYPE.Mesh) {
      window.LR_ImportMesh({});
    }
    else if (resourceType === RESOURCE_TYPE.FixtureType) {
      window.LR_ImportGdtf({});
    }
  }

  return <>
    {disableNewButton || resourceType === RESOURCE_TYPE.SymbolDef ? null : <Button compact floated='right' positive content={LocalizedStrings.Import} onClick={newResource} />}
    <Button compact floated='right' positive content={LocalizedStrings.OpenLocalResources} onClick={()=>window.LR_OpenLocalRessourceFolder()} />
  </>
}

export default class extends Component<propsInterface, stateInterface>{
  constructor(props) {
    super(props)
    this.state = {
      selectedUser: "",
      UserContentOptions: [],
      userResourcesLoading: false,
      serverResourcesLoading: false,
      previewTextures: {},
      isLoading: false,
      searchValue: "",
      activeTab: TABS_ENUM.ONLINE,
      cellData: [],
      selectedDataModelNode: undefined
    }
  }


  oldData: DataModelNode[] = []
  firstSearch = true
  onSearchChange(val: string) {
    if (val === "") {
      this.setState({ searchValue: "", cellData: this.oldData })
      this.oldData = []
      this.firstSearch = true
    }
    else {
      if (this.firstSearch) {
        this.oldData = [...this.state.cellData]
        this.firstSearch = false
      }
      let duplicateList = new Set()
      this.setState({
        cellData: Array.from(globalModel.getLeafsWithLimit(SEARCH_LIMIT, (item) => {
          if(duplicateList.has(item.ident)){
            return false
          }else{
            duplicateList.add(item.ident)
            return item.rawData && item.label.toLowerCase().includes(val.toLowerCase())
          }
        })),
        searchValue: val
      })
    }
  }

  handleChangeType(type: RESOURCE_TYPE) {
    if (this.props.handleChangeType) { this.props.handleChangeType(type) }
    this.setState({cellData: [], selectedDataModelNode: undefined})
  }

  onClickNode(node: DataModelNode, mode: avaiableMessages) {
    node.isLoading = true
    this.forceUpdate()
    node.emit({}, mode, () => {
      node.isCached = true
      node.isLoading = false
      this.forceUpdate()
    })
  }

  CheckoutCurrentRessourceDrawing()
  {
    if(
      !this.state.selectedDataModelNode ||
      this.props.resourceType !== RESOURCE_TYPE.SymbolDef
      ){
      return
    }

    let node = this.state.selectedDataModelNode

    if(!node.revisionId){
      return
    }

    let req = {
      Identifier: node.ident,
      RevisionId: node.revisionId,
      User: this.state.selectedUser
    }

    window.LR_CheckoutRessourceDrawing(req)
    this.props.close();
  }

  RenderCurrentPath()
  {
    if(
      this.state.searchValue ||
      !this.state.selectedDataModelNode ||
      this.props.resourceType !== RESOURCE_TYPE.SymbolDef
      ){
      return null
    }

    let node = this.state.selectedDataModelNode

    let data = [node, ...Array.from(node.getParents())]

    data.pop() // TopResourceNode
    data.pop() // sub resource node

    data.reverse()

    return <Message>
      <Message.Content style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}>
        <div>
          {LocalizedStrings.CurrentPosition}: <code>/{data.map(i => i.label).join("/")}</code>
        </div>
        <Button compact content={LocalizedStrings.ModifyThisResourceFile} onClick={this.CheckoutCurrentRessourceDrawing.bind(this)} />
      </Message.Content>
    </Message>
  }

  RenderDataComponent() {
    const headerIdent = [
      { sortable: true, ident: "label", label: "Name", unit: undefined },
      { sortable: false, ident: "Preview", label: "Preview", unit: undefined },
      { sortable: false, ident: "Import", label: "Import", unit: undefined }
    ]

    const cellData = this.state.cellData

    const cellRender = (currNode: DataModelNode) => {
      currNode = cellData.find((node) => node.ident === currNode.ident)

      let e = currNode.rawData

      let entry = e.File ? e.Entry : e

      let previewTexture = undefined;
      if (entry.PreviewTextureObject) {
        previewTexture = entry.PreviewTextureObject.Buffer;
      }
      else {
        let textureObject = this.state.previewTextures[entry.PreviewTexture];
        if (textureObject) {
          previewTexture = textureObject.Buffer;
        }
      }

      return {
        label: currNode.label,
        Preview:
          previewTexture ? (
            <img
              src={"data:image/png;base64," + previewTexture}
              alt={entry.UUID}
            />
          ) : null,
        Import: (
          <>
            <Button
              loading={currNode.isLoading}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                this.props.close();
                this.onClickNode(currNode, "import")
              }}
            >
              <Icon name={!currNode.isCached || inBrowser ? "cloud download" : "folder open"} />
              {!currNode.isCached || inBrowser ? (LocalizedStrings as any).DownloadImportButton : LocalizedStrings.ImportButton}
            </Button>
            {
              !inBrowser ? 
              <Button
                positive={currNode.isCached}
                disabled={currNode.isCached}
                loading={currNode.isLoading}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  this.onClickNode(currNode, "download")
                }}
              >
                <Icon name="cloud download" />
                {currNode.isCached ? (LocalizedStrings as any).OfflineAvaiable : (LocalizedStrings as any).DownloadButton}
              </Button> 
              : null
            }

          </>
        ),
      };
    };

    return (
      <>
        {this.RenderCurrentPath()}
        {cellData.length !== 0 ? (
          <DynamicTable
            cellRender={cellRender}
            headerIdent={headerIdent}
            cellData={cellData}
            selectionType={TABLE_SELECTION_TYPE_Row}
            singleRowSelectionChange={({ RowIndex }) => { if (RowIndex > -1) { this.props.close(); this.onClickNode(cellData[RowIndex], "import") } }}
            autoCellSize
          />
        ) : (
          <Message warning>
            <Message.Header>
              {LocalizedStrings.NoRessourceHeader}
            </Message.Header>
            <p>{LocalizedStrings.NoRessourceText}</p>
          </Message>
        )}
      </>
    );
  }

  /**
   * Download all leafs of node
   * @param node 
   */
  async downloadBulk(node: DataModelNode) {
    let aborted = false

    let jobs = Array.from(node.getLeafs(l => l.rawData))
    for (let i of jobs) { i.isLoading = true }
    for (let i of jobs) {
      if (aborted) {
        break;
      }

      if(i.isCached){
        i.isLoading = false
        continue
      }

      await new Promise(res => i.emit({}, "download", res))
      i.isLoading = false
      i.isCached = true
      this.forceUpdate()
    }
  }

  renderSidebar(node: DataModelNode) {
    let outA = []
    let outB = []

    for (let i of node.children.sort((a, b) => a.label < b.label ? -1 : 1)) {
      let showCloud = i.checkIfLeafExists(i => !i.isCached && i.ResourceType === this.props.resourceType && i.rawData)

      if (i.isBeforeLeaf) {
        outA.push(
          <List.Item 
          key={i.label}
            link
            onClick={() => {
              this.updateDisplay()
              this.setState({ 
                selectedDataModelNode: i,
                cellData: Array.from(i.getLeafs(obj => obj.ResourceType === this.props.resourceType && obj.rawData)) 
              })
            }}>
              <div>
              {
                !inBrowser ?
                  showCloud ?  
                  <Icon name='cloud download' onClick={() => this.downloadBulk(i)} style={{ "marginLeft": "5px", "cursor": "pointer" }} /> 
                  : 
                  <Icon name="check" style={{ "marginLeft": "5px"}} />
                :
                null
              }
                <Label icon="file" style={{ cursor: "pointer" }}>
                  <Icon name="file" />
                  {i.label}
                </Label>
              </div>
            
          </List.Item>
        )
      }
      else if (i.checkIfLeafExists(obj => obj.ResourceType === this.props.resourceType))
      {
        outB.push(
          <List.Item key={i.label}>
            <div style={{ display: "flex", "flexDirection": "row", "cursor": "pointer" }}>
              <Icon name='dropdown' rotated={i.visible ? undefined : "counterclockwise"} onClick={() => { i.visible = !i.visible; this.forceUpdate() }} />
              {
                !inBrowser ? 
                  showCloud ? 
                    <Icon name='cloud download' onClick={() => this.downloadBulk(i)} style={{ "marginLeft": "5px" }} /> 
                    : 
                    <Icon name="check" style={{ "marginLeft": "5px"}} />
                :
                null
              }
              <div onClick={() => { i.visible = !i.visible; this.forceUpdate() }} style={{ "marginLeft": "5px" }}>{i.label}</div>
            </div>
            {i.visible ? this.renderSidebar(i) : null}
          </List.Item>
        )
      }
    }



    return <List>{outB}{outA}</List>
  }

  updateDisplay() {
    switch (this.state.activeTab) {
      case TABS_ENUM.CURRENT:
        this.setState({ selectedDataModelNode: undefined, cellData: Array.from(globalModel.getChildByIdent("current").getLeafs(obj => obj.ResourceType === this.props.resourceType)) })
        break;
      case TABS_ENUM.LOCAL:
        this.setState({ selectedDataModelNode: undefined, cellData: Array.from(globalModel.getChildByIdent("local").getLeafs(obj => obj.ResourceType === this.props.resourceType)) })
        break;
    }
  }

  setActiveTab(tab: number) {
    this.setState({ searchValue: "" })
    switch (tab) {
      case TABS_ENUM.CURRENT:
        this.setState({ selectedDataModelNode: undefined, cellData: Array.from(globalModel.getChildByIdent("current").getLeafs(obj => obj.ResourceType === this.props.resourceType)) })
        break;
      case TABS_ENUM.LOCAL:
        this.setState({ selectedDataModelNode: undefined, cellData: Array.from(globalModel.getChildByIdent("local").getLeafs(obj => obj.ResourceType === this.props.resourceType)) })
        break;
      case TABS_ENUM.ONLINE:
        this.setState({ selectedDataModelNode: undefined, cellData: [] })
        break;
      case TABS_ENUM.USER:
        this.setState({ selectedDataModelNode: undefined, cellData: [] })
        break;
    }
    this.setState({ activeTab: tab })
  }

  render(): React.ReactNode {
    let panes = []

    if(this.props.open){
      panes.push(<div style={{ "height": "90%", "overflowY": "scroll" }}>{this.RenderDataComponent()}</div>)
      panes.push(this.props.disableLocalRessources ? null : <div style={{ "height": "90%", "overflowY": "scroll" }}>{this.RenderDataComponent()}</div>)
      panes.push(
        <div style={{ "height": "82%" }}>
          <MediaQuery maxWidth={510}>
            <div style={{ "overflowY": "scroll", "height": "100%" }}>
              <div style={{}}>
                <Button primary fluid onClick={() => { globalCallbacks.refreshServerResources(true) }}>
                  <Icon loading={this.state.serverResourcesLoading} name='redo' />
                  {LocalizedStrings.ReloadResources}
                </Button>
                {this.renderSidebar(globalModel.getChildByIdent("online"))}
              </div>
              <Divider />
              <div>
                {this.RenderDataComponent()}
              </div>
            </div>
          </MediaQuery>
          <MediaQuery minWidth={511}>
            <div style={{ display: "flex", "flexDirection": "row", "height": "100%" }}>
              <div style={{ "flexBasis": "20%", "overflowY": "scroll", marginRight: "20px" }}>
                <Button primary fluid onClick={() => { globalCallbacks.refreshServerResources(true) }}>
                  <Icon loading={this.state.serverResourcesLoading} name='redo' />
                  {LocalizedStrings.ReloadResources}
                </Button>
                {this.renderSidebar(globalModel.getChildByIdent("online"))}
              </div>
              <div style={{ "flexBasis": "80%", "overflowY": "scroll" }}>
                {this.RenderDataComponent()}
              </div>
            </div>
          </MediaQuery>
        </div>
  
      )
  
      let userNode = globalModel.getChildByIdent("user").getChildByIdent(this.state.selectedUser)
      panes.push(
        <div style={{ "height": "90%", "overflowY": "scroll" }}>
          <Button primary fluid onClick={() => { globalCallbacks.refreshUserResources(true) }}>
            <Icon loading={this.state.userResourcesLoading} name='redo' />
            {LocalizedStrings.ReloadResources}
          </Button>
          <Dropdown
            button
            className='icon'
            floating
            labeled
            fluid
            icon='user'
            options={this.state.UserContentOptions}
            search
            value={this.state.selectedUser}
            onChange={(e, { value }) => { this.setState({ selectedUser: String(value) }, ()=>globalCallbacks.refreshUserResources(true)); }}
          />
          {userNode ? this.renderSidebar(userNode) : this.state.userResourcesLoading ? <Loader active /> : null}
          {this.RenderDataComponent()}
        </div>
      )
    }

    return (
      <LRModal open={this.props.open}
        title={LocalizedStrings.Header + " " + this.props.headerAdd ? this.props.headerAdd : ""}
        additionalHeader={<ImportButtons disableNewButton={this.props.disableNewButton} resourceType={this.props.resourceType}/>}
        size="fullscreen"
        onCancelClick={this.props.close}
        onOkClick={this.props.close}
        noCancel
        onOpen={this.refetchPreviewTextures}
        contentStyle={{ height: "80vh" }}
        scrolling={false}>
        <Menu>
          {!this.props.disableTopMenu ? <>
            <Menu.Item active={this.props.resourceType === RESOURCE_TYPE.Mesh} onClick={() => { this.handleChangeType(RESOURCE_TYPE.Mesh) }}>
              {LocalizedStrings.Mesh}
            </Menu.Item>
            <Menu.Item active={this.props.resourceType === RESOURCE_TYPE.SymbolDef} onClick={() => { this.handleChangeType(RESOURCE_TYPE.SymbolDef) }}>
              {LocalizedStrings.SymbolDefinition}
            </Menu.Item>
            <Menu.Item active={this.props.resourceType === RESOURCE_TYPE.FixtureType} onClick={() => { this.handleChangeType(RESOURCE_TYPE.FixtureType) }}>
              {LocalizedStrings.GDTF}
            </Menu.Item>
          </> : null}
          <Menu.Item position="right">
            <Search
              className='searchInput'
              open={false}
              placeholder={LocalizedStrings.Search}
              loading={this.state.isLoading}
              value={this.state.searchValue}
              onSearchChange={(e, { value }) =>
                this.onSearchChange(value)
              }
            />
          </Menu.Item>
        </Menu>
        <Menu>
          {!this.props.disableLocalRessources ? <>
          <Menu.Item active={this.state.activeTab === TABS_ENUM.CURRENT} onClick={() => { this.setActiveTab(TABS_ENUM.CURRENT) }}>
            {LocalizedStrings.OpenDocument}
          </Menu.Item>
          <Menu.Item active={this.state.activeTab === TABS_ENUM.LOCAL} onClick={() => { this.setActiveTab(TABS_ENUM.LOCAL) }}>
            {LocalizedStrings.LocaleResources}
          </Menu.Item>
          </> : null}
          <Menu.Item active={this.state.activeTab === TABS_ENUM.ONLINE} onClick={() => { this.setActiveTab(TABS_ENUM.ONLINE) }}>
            {LocalizedStrings.ServerDrawing}
          </Menu.Item>
          <Menu.Item active={this.state.activeTab === TABS_ENUM.USER} onClick={() => { this.setActiveTab(TABS_ENUM.USER) }}>
            {LocalizedStrings.UserResource}
          </Menu.Item>
        </Menu>
        {
          panes[this.state.activeTab]
        }

      </LRModal>
    )
    /*
    
    
    */
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.open === false && this.props.open === true) {
      globalCallbacks.refreshResources();
      window.LR_GetCachedResources().then(d => {
        this.cachedResources = d["Files"].map((val: string) => path.dirname(val))
        globalCallbacks.refreshServerResources();
      })
      globalCallbacks.refreshOrganization();
      this.setState({ 
        searchValue: "", 
        cellData: this.state.searchValue ? [] : this.state.cellData,  //Only clear list, if it was a search result
        selectedDataModelNode: this.state.searchValue ? undefined : this.state.selectedDataModelNode
      }) 
    }
    if (prevProps.resourceType !== this.props.resourceType) {
      this.updateDisplay()
    }
  }

  componentDidMount() {
    this.setUpCallbacks();

    // When the component is mounted and directly shown, we need to fetch the data
    if (this.props.open) {
      globalCallbacks.refreshResources();
      window.LR_GetCachedResources().then(d => {
        this.cachedResources = d["Files"].map((val: string) => path.dirname(val))
        globalCallbacks.refreshServerResources();
      })
      globalCallbacks.refreshOrganization();
    }
  }

  async ImportServerDrawing(UUID: uuidStr, RevisionId: string, Identifier: string, ResourceType: RESOURCE_TYPE, forceUser: boolean, doImport = true, item: DataModelNode) {
    let req = { UUID: UUID, RevisionId: RevisionId, Identifier: Identifier, ResourceType: ResourceType, User: undefined }
    if (this.state.activeTab === TABS_ENUM.USER) {
      req.User = this.state.selectedUser
    }

    if(this.props.OnlySelect)
    {
      if(this.props.SelectRessourceCallback)
      {
        this.props.SelectRessourceCallback(ResourceType, UUID, Identifier, req.User, item.rawData)
      }
      return 
    }

    if (doImport || IsRunningAsBrowser()) {
      let result = await window.LR_ImportServerResourceAsync({ ...req, Async: true })
      if (result) {
        await this.MakeActive(ResourceType, result.UUID)
        await globalCallbacks.refreshResources()
      }
    } else {
      await window.LR_CacheServerResourceAsync({ ...req, Async: true })
    }

  }

  async ImportLocalDrawing(UUID: uuidStr, File, ResourceType: RESOURCE_TYPE) {
    let result: any = {}
    switch (ResourceType) {
      case RESOURCE_TYPE_FixtureType: result = await window.LR_ImportFixtureType({ UUID: UUID, File: File }); break;
      case RESOURCE_TYPE_SymbolDef: result = await window.LR_ImportRessource({ UUID: UUID, File: File }); break;
      case RESOURCE_TYPE_PrintLabel: result = await window.LR_ImportPrintLabel({ File: File, UUID: UUID }); break;
      default: break;
    }
    await this.MakeActive(ResourceType, result.UUID)
  }

  async MakeActive(ResourceType: RESOURCE_TYPE, uuid: uuidStr) {
    if(this.props.resourceType !== ResourceType){
      this.handleChangeType(ResourceType)
    }

    let object = {}
    switch (ResourceType) 
    {
      case RESOURCE_TYPE.FixtureType: object = await window.LR_SetDefaultFixtureType({ UUID: uuid }); break;
      case RESOURCE_TYPE.SymbolDef:   object = await window.LR_SetDefaultSymbolDef({ UUID: uuid }); break;
      case RESOURCE_TYPE.Mesh:        object = await window.LR_SetDefaultMesh({ UUID: uuid }); break;
      default: break;
    }

    if(this.props.SelectRessourceCallback)
    {
      this.props.SelectRessourceCallback(ResourceType, uuid, "", this.state.selectedUser, object)
    }
  }

  cachedResources: string[]
  setUpCallbacks = () => {

    globalCallbacks.refreshResources = async () => {
      let cached = await window.LR_GetLocaleResourcesCached();
      let currNode = globalModel.getChildByIdent("current")
      currNode.clearChildren()
      let localNode = globalModel.getChildByIdent("local")

      currNode.removeAllListeners()
      localNode.removeAllListeners()

      currNode.eventHandler.on("fromChild", (item)=>this.MakeActive(item.ResourceType, item.rawData.UUID))
      currNode.eventHandler.on("thisObj", (item)=>this.MakeActive(item.ResourceType, item.rawData.UUID))
      localNode.eventHandler.on("fromChild", (item)=>this.ImportLocalDrawing(item.rawData.UUID, item.rawData.FullPath, item.ResourceType))
      localNode.eventHandler.on("thisObj", (item)=>this.ImportLocalDrawing(item.rawData.UUID, item.rawData.FullPath, item.ResourceType))

      let toNode = (item, type: RESOURCE_TYPE, path?: string) => {
        let n = new DataModelNode(item.UUID, type)
        n.rawData = item ?? {}
        n.label = item.Name
        if (path) { n.rawData.FullPath = path }
        n.isCached = true
        return n
      }

      currNode.addChildren((await window.LR_GetFixtureTypes()).FixtureTypes.map(item => toNode(item, RESOURCE_TYPE.FixtureType)))
      currNode.addChildren((await window.LR_GetMeshes()).Meshes.map(item => toNode(item, RESOURCE_TYPE.Mesh)))
      currNode.addChildren((await window.LR_GetSymbolDefs()).SymbolDefs.map(item => toNode(item, RESOURCE_TYPE.SymbolDef)))
      currNode.addChildren((await window.LR_GetPrintLabels()).PrintLabels.map(item => toNode(item, RESOURCE_TYPE.PrintLabel)))

      if (cached && Array.isArray(cached)) {
        for (let i of cached) {
          localNode.addChildren(i.FixtureTypes.map(item => toNode(item, RESOURCE_TYPE.FixtureType, i.FullPath)))
          localNode.addChildren(i.Symbols.map(item => toNode(item, RESOURCE_TYPE.SymbolDef, i.FullPath)))
          localNode.addChildren(i.PrintLabels.map(item => toNode(item, RESOURCE_TYPE.PrintLabel, i.FullPath)))
        }
      }

      //this.updateDisplay()

      cached = await window.LR_GetLocaleResources({ Async: true });

      for (let i of cached) {
        localNode.addChildren(i.FixtureTypes.map(item => toNode(item, RESOURCE_TYPE.FixtureType, i.FullPath)))
        localNode.addChildren(i.Symbols.map(item => toNode(item, RESOURCE_TYPE.SymbolDef, i.FullPath)))
        localNode.addChildren(i.PrintLabels.map(item => toNode(item, RESOURCE_TYPE.PrintLabel, i.FullPath)))
      }

      this.updateDisplay()
    }

    globalCallbacks.refreshOrganization = async () => {
      let orgs = await window.LR_GetOrganizationsAsync()
      let user = await window.LR_GetLoggedInUser()

      if (!Array.isArray(orgs)) {
        orgs = []
      }
      this.setState({
        UserContentOptions:
          [
            ...orgs.map(o => { return { key: o.username, text: o.username, value: o.username } }),
            { key: user.User, text: user.User, value: user.User }
          ]
      })

      if (this.state.selectedUser === "") {
        this.setState({ selectedUser: user.User }, ()=>globalCallbacks.refreshUserResources(true))
      }
    }

    let doFile = (file) => {
      let rev = file.revisions[file.revisions.length - 1]
      if (rev) {
        let nc = new DataModelNode(file.identifier)
        nc.revisionId = rev._id
        let handleEv = async (item: DataModelNode, msg: avaiableMessages, cb: () => void) => {
          let current = globalModel.getChildByIdent("current").getChildByIdent(item.ident, true)
          let local = current || globalModel.getChildByIdent("local").getChildByIdent(item.ident, true)

          if ((current || local || item.isCached) && msg === "download") {
            if (cb) { cb() }
            return;
          }

          if (current) { 
            await this.MakeActive(current.ResourceType, current.rawData.UUID)
          } else if (local) {
            await this.ImportLocalDrawing(local.rawData.UUID, local.rawData.FullPath, local.ResourceType)
          } else {
            await this.ImportServerDrawing(item.rawData.UUID, rev._id, file.identifier, item.ResourceType, this.state.activeTab === TABS_ENUM.USER, msg === "import", item)
          }

          for (let i of nc.getLeafs()) {
            i.isCached = true
          }
          if (cb) { cb() }
        }

        nc.eventHandler.on("fromChild", (item, msg, cb) => handleEv(item, msg, cb))
        nc.eventHandler.on("thisObj", (item, msg, cb) => handleEv(item, msg, cb))

        nc.label = file.name
        let included = this.cachedResources?.includes(file.identifier)
        for (let i of rev.fixtureTypes) {
          let k = nc.addChild(new DataModelNode(i.UUID, RESOURCE_TYPE.FixtureType))
          k.rawData = i
          k.label = i.Name
          k.isCached = included
        }
        for (let i of rev.labels) {
          let k = nc.addChild(new DataModelNode(i.UUID, RESOURCE_TYPE.PrintLabel))
          k.rawData = i
          k.label = i.Name
          k.isCached = included
        }
        for (let i of rev.symbols) {
          let k = nc.addChild(new DataModelNode(i.UUID, RESOURCE_TYPE.SymbolDef))
          k.rawData = i
          k.label = i.Name
          k.isCached = included
        }
        return nc
      }
    }

    let gdtfResourceToNode = (res) => {
      let out = new DataModelNode(res.identifier)

      let manufaturerGrouping = {}
      for (let i of res.files) {
        if (i.manufacturer !== "") {
          if (!manufaturerGrouping[i.manufacturer]) { manufaturerGrouping[i.manufacturer] = [] }
          manufaturerGrouping[i.manufacturer].push(i)
        } else {
          out.addChild(doFile(i))
        }
      }

      for (let i in manufaturerGrouping) {
        let nc = out.addChild(new DataModelNode(i))
        for (let j of manufaturerGrouping[i]) {
          if (j.revisions.length && j.revisions[0].fixtureTypes.length) {
            let fixture = j.revisions[0].fixtureTypes[0]
            let k = nc.addChild(new DataModelNode(j.identifier, RESOURCE_TYPE.FixtureType))
            k.label = fixture.Name
            k.rawData = fixture
            k.isCached = this.cachedResources?.includes(j.identifier)
            k.eventHandler.on("thisObj", async (msg: avaiableMessages, cb) => {
              let current = globalModel.getChildByIdent("current").getChildByIdent(k.ident, true)
              let local = current || globalModel.getChildByIdent("local").getChildByIdent(k.ident, true)
              if ((current || local || k.isCached) && msg === "download") {
                if (cb) { cb() }
                return;
              }

              if (current) {
                await this.MakeActive(current.ResourceType, current.rawData.UUID)
              } else if (local) {
                await this.ImportLocalDrawing(local.rawData.UUID, local.rawData.FullPath, local.ResourceType)
              } else {
                await this.ImportServerDrawing(k.rawData.UUID, j.revisions[0]._id, j.identifier, k.ResourceType, this.state.activeTab === TABS_ENUM.USER, msg === "import", k)
              }
              k.isCached = true
              if (cb) { cb() }
            })
          }
        }
      }
      return out
    }

    let serverResourceToNode = (res) => {
      let out = new DataModelNode(res.identifier)
      out.label = res.name
      if (res.files) {
        for (let i of res.files) {
          out.addChild(doFile(i))
        }
      }
      if (res.subCategories) {
        for (let i of res.subCategories) {
          out.addChild(serverResourceToNode(i))
        }
      }
      return out
    }

    let refreshInProgress = false
    globalCallbacks.refreshServerResources = async (force = false) => {

      if (!this.props.open && !force) { return }

      this.setState({ serverResourcesLoading: true })
      if(refreshInProgress){
        return
      }
      refreshInProgress = true
      let serverNode = globalModel.getChildByIdent("online")

      let serverResources = await window.LR_GetServerResourceCached({ Async: true })
      if (Array.isArray(serverResources)) {
        for (let i of serverResources) {
          if (i.identifier !== "gdtf") {
            serverNode.addChild(serverResourceToNode(i))
          } else {
            serverNode.addChild(gdtfResourceToNode(i))
          }
        }
      }

      serverResources = await window.LR_GetServerResourceAsync({ Async: true, Minified: true })
      if (Array.isArray(serverResources)) {
        for (let i of serverResources) {
          if (i.identifier !== "gdtf") {
            serverNode.addChild(serverResourceToNode(i))
          } else {
            serverNode.addChild(gdtfResourceToNode(i))
          }
        }
      }
      
      refreshInProgress = false

      this.setState({ serverResourcesLoading: false })
    }

    globalCallbacks.refreshUserResources = async (force = false) => {
      this.setState({ userResourcesLoading: true })

      if (this.state.selectedUser === "") { return }

      let serverNode = globalModel.getChildByIdent("user")
      let userNode = serverNode.getChildByIdent(this.state.selectedUser) ?? serverNode.addChild(new DataModelNode(this.state.selectedUser))
      let userRessources = await window.LR_GetUserResourceAsync({ User: this.state.selectedUser })

      if (Array.isArray(userRessources)) {
        for (let i of userRessources) {
          userNode.addChild(serverResourceToNode(i))
        }
      }
      this.setState({ userResourcesLoading: false })

    }
  }

  refetchPreviewTextures = async () => {
    let res = await window.LR_GetPreviewTextures({ WriteBuffer: true })
    let texturesMap = {}
    for (let i of res.Textures) {
      texturesMap[i.UUID] = i
    }
    this.setState({ previewTextures: texturesMap })
  }
}