//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import CreateWorksheetModal from "../WorkSheet/CreateWorksheetModal";
import GlobalSettingsModal from '../GlobalSettings/GlobalSettingsModal';
import AlignLabelModal from '../PrintLabels/AlignLabelModal';
import MoveToAssemblyGroupModal from '../MoveToAssemblyGroup/MoveToAssemblyGroupModal';
import ChangeOriginModal from '../ChangeOrigin/ChangeOriginModal';
import NumberingFixtureModal from '../FixtureNumbering/NumberingFixtureModal';
import AskForGroupNameModal from '../AskForGroupName/AskForGroupNameModal';
import InsertDropComponent from '../InsertDrop/InsertDropComponent';
import PackObjectsIntoContainer from '../PackObjectsIntoContainer/PackObjectsIntoContainer';
import SetPropertyModal from '../SetProperty/SetProperty';
import DiffView from '../DiffView/DiffView'
import SelectProjectView from '../CheckOutProject/SelectProjectView';
import ArrayModifierModal from '../ArrayModifier/ArrayModifier';
import ConnectToRemote from '../ConnectToRemote/ConnectToRemote';
import AskForLRNETMaster from '../ConnectToRemote/AskForLRNETMaster';
import AboutDialog from '../AboutDialog/AboutDialog';
import PlaceToStructureModal from '../PlaceToStructure/PlaceToStructureComponent';
import CreateNewBranchView from '../CreateNewBranch/CreateNewBranch';
import ResourceImportMap from '../ResourceImportMap/ResourceImportMap';
import MergeView from '../MergeView/MergeView';
import FirstStartTour from '../../tour/FirstStart';
import ExportStructuralCalculationModal from '../ExportStructuralCalculation/ExportStructuralCalculation';
import ExportPaperworkModal from '../ExportPaperworksReport/ExportPaperworksReport';
import ConnectLoadCells from '../ConnectLoadCells/ConnectLoadCells';
import CreateDrawingNote from '../DrawingNotes/CreateDrawingNote';
import AddNewUserToProject from '../AddNewUserToProject/AddNewUserToProject';
import EditShortCutModal from '../ShortCutDisplay/EditShortCutModal';
import SaveTimerModal from '../SaveTimerModal/SaveTimerModal';
import InAppFeedback from '../InAppFeedback/InAppFeedback';
import SelectResourceModal from "../ToolSelect/SelectRessourceModal";
import ProgressBar from "../ProgressBar/ProgressBar";
import CalculateTrussCrossSection from "../CalculateTrussCrossSection/CalculateTrussCrossSection";
import Hotpatch from "../Hotpatch/Hotpatch";
import RandomizeChainShortenModal from "../RandomizeChainShorten/RandomizeChainShorten";
import ChainShortenEffectModal from "../RandomizeChainShorten/ChainShortenEffect";
import RequestFile from "../MVRxchange/MVRXchangeSettingsModal";
import CalculationOverviewDialog from "../CalculationOverviewDialog/CalculationOverviewDialog";
import RequestStructuralCheck from "../RequestStructuralCheck/RequestStructuralCheck";
import VersionConflict from "../VersionConflict/VersionConflict";
import LinkWithHostDialog from "../LinkWithHostDialog/LinkWithHostDialog";

import React, { Component } from "react";

import {  RESOURCE_TYPE_FixtureType, RESOURCE_TYPE_SymbolDef} from "../../util/defines";
import CustomURLDialog from "../CustomURLDialog/CustomURLDialog";
import AddNewWindLoadDialog from "../AddNewWindloadDialog/AddNewWindloadDialog";
import BenchmarkDialog from "../BenchMarkDialog/BenchMarkDialog";
import ViewStructuralResultsModal from "../ViewStructuralResultsModal/ViewStructuralResultsModal";
import StructuralSettingsModal from "../ViewStructuralResultsModal/StructuralSettingsModal";
import DWGImportScale from "../DWGImportScale/DWGImportScale";
import ExportSymbolsModal from "../ExportSymbols/ExportSymbolsModal";
import CalendarModal from "../Calendar/CalendarModal";
import AddNewLineLoadDialog from "../AddNewLineLoad/AddNewLineLoad";
import AddNewPointLoad from "../AddNewPointLoad/AddNewPointLoad";
import ShareModal from "../ShareModal/ShareModal";
import ExportViewModal from "../ExportViews/ExportView";
import InstallPluginModal from "../InstallPlugin/InstallPlugin";
import ResolveDoubleConnections from "../ResolveDoubleConnections/ResolveDoubleConnections";
import ScaleByRef from "../ScaleByRef/ScaleByRef";
import { generateUUID } from "three/src/math/MathUtils";
import { IsUsingWebassembly } from "../../webApp/ipcRenderer";
import UploadModal from "../../webApp/uploadModal"

import { globalCallbacks } from "../../util/callback";

class DefaultModalContainer extends Component 
{
  
    constructor(props)
    {    
      super(props);
      this.keyMap = new Map()
      this.state = {
        fixtureTypesOpen : false,
        showTour: false
      }
  
    }

    componentDidMount = async () => 
    {
      this.setUpCallbacks();
  
      setTimeout(()=>this.setState({showTour: true}), 1000)
    }

    render()
    {
      let toRender = [
        ProgressBar,
        SetPropertyModal ,
        DiffView ,
        SelectProjectView ,
        ArrayModifierModal ,
        PlaceToStructureModal ,
        GlobalSettingsModal ,
        Hotpatch ,
        ConnectToRemote ,
        AboutDialog ,
        AlignLabelModal,
        MoveToAssemblyGroupModal ,
        RequestStructuralCheck ,
        ChangeOriginModal,
        CreateNewBranchView,
        NumberingFixtureModal ,
        AddNewPointLoad ,
        AskForGroupNameModal ,
        PackObjectsIntoContainer ,
        ResourceImportMap,
        DWGImportScale ,
        ExportSymbolsModal ,
        MergeView,
        InsertDropComponent,
        ExportStructuralCalculationModal,
        CalculationOverviewDialog,
        ExportPaperworkModal,
        AskForLRNETMaster,
        CreateDrawingNote,
        AddNewUserToProject,
        EditShortCutModal,
        SaveTimerModal,
        InAppFeedback,
        CreateWorksheetModal,
        LinkWithHostDialog,
        CustomURLDialog,
        AddNewWindLoadDialog,
        BenchmarkDialog,
        ViewStructuralResultsModal,
        StructuralSettingsModal,
        CalendarModal,
        AddNewLineLoadDialog,
        ConnectLoadCells,
        ShareModal,
        ExportViewModal,
        InstallPluginModal,
        ResolveDoubleConnections,
        VersionConflict,
        CalculateTrussCrossSection,
        RequestFile,
        ScaleByRef,
        RandomizeChainShortenModal,
        ChainShortenEffectModal
      ]

      toRender = toRender.map(e => {
        if(!this.keyMap.has(e)){
          this.keyMap.set(e, generateUUID())
        }
        return React.createElement(e, {key: this.keyMap.get(e)}, null)
      })

      if(this.state.fixtureTypesOpen && this.resourceType){
        if(!this.keyMap.has(SelectResourceModal)){
          this.keyMap.set(SelectResourceModal, generateUUID())
        }
        toRender.push(<SelectResourceModal SelectRessourceCallback={this.selectResourceSelector} key={this.keyMap.get(SelectResourceModal)} open={this.state.fixtureTypesOpen && this.resourceType} close={this.closeFixtureTypes} resourceType={this.resourceType}/>)
      }
      if(!this.props.webApp && this.state.showTour){
        if(!this.keyMap.has(FirstStartTour)){
          this.keyMap.set(FirstStartTour, generateUUID())
        }
        toRender.push(<FirstStartTour key={this.keyMap.get(FirstStartTour)}/>)
      }

      if(IsUsingWebassembly){
        if(!this.keyMap.has(UploadModal)){
          this.keyMap.set(UploadModal, generateUUID())
        }
        toRender.push(<UploadModal key={this.keyMap.get(UploadModal)}/>)
      }
      
    return <>{toRender}</>
  }

  closeFixtureTypes = () =>
  {
    this.setState({fixtureTypesOpen : false});
  }
  selectResourceSelector = (replaceFixtureType) =>
  {
    if (this.resourceType === RESOURCE_TYPE_FixtureType)
    {
      window.LR_ReplaceSelectedFixtureTypes(); 
    }
    else if (this.resourceType === RESOURCE_TYPE_SymbolDef)
    {
      window.LR_ReplaceSelectedSymbolDefinition();
    }
    
  }

  setUpCallbacks = () =>
  {
    globalCallbacks.openFixtureTypes = (resourceType = RESOURCE_TYPE_SymbolDef) =>
    {
      this.resourceType = resourceType
      this.setState({fixtureTypesOpen : true});
    }
  }

}



export default DefaultModalContainer;
