//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';

import LocalizedStrings from "../../../localization/TableViewComponent";


class ElectricalFilter extends Component
{
    render()
    {
        return(
            <>
                <h5>{LocalizedStrings.Filter}</h5>
                <Form.Input icon='search' placeholder={LocalizedStrings.Search} value={this.props.value} onChange={this.props.searchFieldUpdate} />

            </>
          )
    }
}


export default ElectricalFilter;