//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Table, Icon, Popup, Input } from 'semantic-ui-react'
import UserAvatar from '../WebComponents/UserAvatar';
import LocalizedStrings from "../../localization/NavigationContainer";
import BasicSelect from '../Basics/BasicSelect';
import { IsVectorworksContext } from '../../util/defines';



class UserNode extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = {
            
        }

      
    }

    updateData = (name, value, event) =>
    {
        let request = 
        {
            UUID: this.props.user.UUID
        };

        if(name)    { request[name] = value; }
        else        { request.Name  = this.state.Name; }

        if(event)
        {
            if(event.altKey)
            {
                request.ModifierKey = true;
                this.setState({Visible: true});
            }
            else
            {
                request.ModifierKey = false;
                this.setState({Visible: value});
            }
            
        }

        window.LR_SetUser(request);
    }

    onKeyDown = (event) =>
    {
        if(event.keyCode === 13) { this.updateData(); event.target.blur() }
    }

    onValueChanged = (e, {name, value}) =>
    {
        this.setState({[name]:value});
    }

    getName()
    {
        return <Input  transparent
                        onContextMenu   = {this.showInviteProjectDialog}
                        name      = "Name" 
                        value     = {this.state.Name ?? this.props.user.Name} 
                        onChange  = {this.onValueChanged} 
                        onBlur    = {() => this.updateData("Name", this.state.Name)}
                        onKeyDown = {this.onKeyDown}
                        fluid/>
    }

    getRole()
    {
        return this.props.user.Role;
    }

    getAvatar()
    {

        if(this.props.user.LinkedPAUser === "")
        {
            return "DUMMY";
        }

        return this.props.user.LinkedPAUser
    }

    componentDidUpdate(oldProps) 
    {
        const newProps = this.props;
        for(const [name, value] of Object.entries(newProps.user))
        {
            if(oldProps.user[name] !== value) 
            {
                this.setState({[name]: value});
            }
        }

    }


    render() 
    {
        let user        = this.props.user;
        return (
            <Table.Row warning={this.props.user.LinkedPAUser === "" ? "" : "true"}>
                <Table.Cell collapsing width={1}>
                    <UserAvatar user={this.getAvatar()} />
                </Table.Cell>
                <Table.Cell className="test"  width={3} style={{minWidth:"6rem"}}>
                    {this.getName()}
                </Table.Cell>
                <Table.Cell collapsing>
                {this.props.user.LinkedPAUser}
                </Table.Cell>
                <Table.Cell collapsing width={3}>
                    <BasicSelect 
                    options={this.props.DepartmentsOptions}
                    value={this.props.user.LinkedDepartment}
                    onStateUpdate={(name, value)=>{window.LR_SetUser({UUID: this.props.user.UUID, LinkedDepartment : value});}}
                    />
                </Table.Cell>
                <Table.Cell collapsing width={2} >
                    <div style={{marginLeft:"5rem"}}>
                    <Popup content={LocalizedStrings.PhoneNumber.replace("$1", this.props.user.LinkedPAUser)}
                        trigger = { 
                            <div style={{width: "10%"}}>
                                {IsVectorworksContext() ? 
                                <>
                                </>:
                                    <a href={`tel:${user.PhoneNumber}`} style={{color:"#444a46"}} >
                                    <Icon   name    = "phone square"
                                            color   = "gray"
                                            link/>
                                </a>}
                            <Input  transparent 
                                value={this.state.PhoneNumber ?? user.PhoneNumber}
                                
                                onChange={(e, { value }) => {this.setState({PhoneNumber: value})}}
                                onKeyDown= {(e) => {if(e.keyCode === 13){this.updatePhone(); e.target.blur()}}}
                                onBlur = {this.updatePhone}/>
                                </div>
                            }/>    
                    <Popup content={LocalizedStrings.Role}
                        trigger = { 
                            <div style={{cursor:"none"}}>
                                <Icon   name    = "registered"
                                        style={{cursor:"none"}}
                                        color   = "gray"
                                        link/>
                                <Input  transparent 
                                        value={this.state.role ?? user.Role}
                                        onChange={(e, { value }) => {this.setState({role: value})}}
                                        onKeyDown= {(e) => {if(e.keyCode === 13){this.updateRole(); e.target.blur()}}}
                                        onBlur = {this.updateRole}
                                />
                                    
                            </div>
                        }/>
                        </div>
                </Table.Cell>

                <Table.Cell textAlign="center" collapsing width={1}>
                    <Icon   name    = "delete"
                            color   = "red"
                            onClick = {() => this.onDelete()}
                            />
                </Table.Cell>
            </Table.Row>
        );
    }
    updateRole = () => 
    {
        let role = this.state.role ?? this.props.user.Role
        window.LR_SetUser({UUID: this.props.user.UUID, Role : role});
    }

    updatePhone = () => 
    {
        let PhoneNumber = this.state.PhoneNumber ?? this.props.user.PhoneNumber
        window.LR_SetUser({UUID: this.props.user.UUID, PhoneNumber : PhoneNumber});
    }

    onDelete = () =>
    {
        if(this.props.user.UUID)
        {
            window.LR_DeleteUser({UUID: this.props.user.UUID})
        }
    }

    showInviteProjectDialog = () =>
    {
        window.LR_ShowAddUserToProject();
    }

}

export default UserNode;

