//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Button, Form, Icon, Input, Table } from 'semantic-ui-react';
import { BASE_UNIT_STRING, PROPERTY_BASED_FILTER_MODE } from '../../../util/defines';
import LRModal from '../../Basics/BasicModal';

import LocalizedStrings from "../../../localization/TableViewComponent";
import BasicSelect from '../../Basics/BasicSelect';
import UnitInput from "../../Basics/BasicUnitInput";

class PropertyBasedFilterModal extends Component
{
    constructor(props)
    {
        super(props)

        this.state={
            currentFilterEdit: props.filter.map(filter => ({...filter}))
        }
    }

    render()
    {
        this.propertyFieldOptions = this.props.fields.filter(prop => !prop.ArrayName && prop.IsUnitBased)
                                                      .map(prop => ({ key: prop.PropertyName,  
                                                                      value: prop.PropertyName,
                                                                      text: prop.LocalizedName}))

        if (!this.state.currentFilterEdit) { return null; }
        return (
            <LRModal title={LocalizedStrings.EditPropertyFilter}scrolling={false} open={true} onCancelClick={this.props.onClose} onOkClick={() => {if (this.props.onFinish) { this.props.onFinish(this.state.currentFilterEdit) }}}>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      {LocalizedStrings.PropertyName}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                       {LocalizedStrings.FilteredMode}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {LocalizedStrings.PropertyValue}
                    </Table.HeaderCell>
                    <Table.HeaderCell style={{width: "2rem"}}>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.state.currentFilterEdit.map((propertyFilter, index) => {
                      return this.renderPropertyBasedFilter(propertyFilter, index)
                  })}
                </Table.Body>
              </Table>
              <Button positive onClick={this.addPropertyBasedFilter}>{LocalizedStrings.AddPropertyFilter}</Button>

            </LRModal>
        )
    }

    onBasedFilterModeChange = (value, baseUnit, theFilterIndex ) =>
    {
      let newFilters = [...this.state.currentFilterEdit]
      newFilters[theFilterIndex].Mode = value

      if(baseUnit === BASE_UNIT_STRING)
      {
        newFilters[theFilterIndex].NumberValue = 0
        newFilters[theFilterIndex].RangeMin    = 0
        newFilters[theFilterIndex].RangeMax    = 0

      }else{
        newFilters[theFilterIndex].StringValue = ""
      }

      this.setState({currentFilterEdit: newFilters})
    }

    getFilterInput = (completeProperty, theFilter, theFilterIndex) =>
    {
      let stringFilterOptions = [
        {key: "IsString",    value: PROPERTY_BASED_FILTER_MODE.IsString,    text: LocalizedStrings.CompareIsString   },
        {key: "IsNotString", value: PROPERTY_BASED_FILTER_MODE.IsNotString, text: LocalizedStrings.CompareIsNotString},
        {key: "Includes",    value: PROPERTY_BASED_FILTER_MODE.Includes,    text: LocalizedStrings.CompareIncludes   },
        {key: "Excludes",    value: PROPERTY_BASED_FILTER_MODE.Excludes,    text: LocalizedStrings.CompareExcludes   },
        {key: "StartsWith",  value: PROPERTY_BASED_FILTER_MODE.StartsWith,  text: LocalizedStrings.CompareStartsWith },
        {key: "EndsWith",    value: PROPERTY_BASED_FILTER_MODE.EndsWith,    text: LocalizedStrings.CompareEndsWith   },
      ]

      let numberFilterOptions = [
        {key: "IsValue",          value: PROPERTY_BASED_FILTER_MODE.IsValue,          text: LocalizedStrings.CompareIsValue         },
        {key: "IsNotValue",       value: PROPERTY_BASED_FILTER_MODE.IsNotValue,       text: LocalizedStrings.CompareIsNotValue      },
        {key: "RangeValue",       value: PROPERTY_BASED_FILTER_MODE.RangeValue,       text: LocalizedStrings.CompareRangeValue      },
        {key: "SmallerThan",      value: PROPERTY_BASED_FILTER_MODE.SmallerThan,      text: LocalizedStrings.CompareSmallerThan     },
        {key: "SmallerEqualThan", value: PROPERTY_BASED_FILTER_MODE.SmallerEqualThan, text: LocalizedStrings.CompareSmallerEqualThan},
        {key: "LargerThan",       value: PROPERTY_BASED_FILTER_MODE.LargerThan,       text: LocalizedStrings.CompareLargerThan      },
        {key: "LargerEqualThan",  value: PROPERTY_BASED_FILTER_MODE.LargerEqualThan,  text: LocalizedStrings.CompareLargerEqualThan },
      ]

      if (completeProperty.BaseUnit === BASE_UNIT_STRING)
      {
        let stringValueChange = (_, {value}) =>
        {
            let newFilters = [...this.state.currentFilterEdit]
            newFilters[theFilterIndex].StringValue = value

            this.setState({currentFilterEdit: newFilters})
        }

        if(!theFilter.Mode || !stringFilterOptions.some(i => i.value === theFilter.Mode)){
          theFilter.Mode = PROPERTY_BASED_FILTER_MODE.IsString
        }

        return( 
        <>
          <Table.Cell width={4}>
            <BasicSelect options       = {stringFilterOptions}
                         value         = {theFilter.Mode}
                         onStateUpdate = {(name, value) => this.onBasedFilterModeChange(value, completeProperty.BaseUnit, theFilterIndex)}/> 
          </Table.Cell>

          <Table.Cell>
            <Input fluid value={theFilter.StringValue ?? ""} onChange={stringValueChange}/>
          </Table.Cell>
        </>
        )
      }
      else
      {
        if(!theFilter.Mode || !numberFilterOptions.some(i => i.value === theFilter.Mode)){
          theFilter.Mode = PROPERTY_BASED_FILTER_MODE.IsValue
        }

        let minValChange = (_, value) =>
        {
            let newFilters = [...this.state.currentFilterEdit]
            newFilters[theFilterIndex].RangeMin = Number(value)
            
            this.setState({currentFilterEdit: newFilters})
        }

        let maxValChange = (_, value) =>
        {
            let newFilters = [...this.state.currentFilterEdit]
            newFilters[theFilterIndex].RangeMax = Number(value)

            this.setState({currentFilterEdit: newFilters})
        }
        
        let numberValueChange = (_, value) =>
        {
            let newFilters = [...this.state.currentFilterEdit]
            newFilters[theFilterIndex].NumberValue = Number(value)

            this.setState({currentFilterEdit: newFilters})
        }

        return (
        <>
          <Table.Cell width={4}>
            <BasicSelect options      = {numberFilterOptions}
                         value         = {theFilter.Mode}
                         onStateUpdate = {(name, value) => this.onBasedFilterModeChange(value, completeProperty.BaseUnit, theFilterIndex)}/> 
          </Table.Cell>

          <Table.Cell>
            {theFilter.Mode === PROPERTY_BASED_FILTER_MODE.RangeValue
            ? 
            (<Form>
              <Form.Group widths="equal">
                <UnitInput baseUnit={completeProperty.BaseUnit} value={theFilter.RangeMin ?? ""} onStateUpdate={minValChange}/>
                <UnitInput baseUnit={completeProperty.BaseUnit} value={theFilter.RangeMax ?? ""} onStateUpdate={maxValChange}/>
              </Form.Group>
            </Form>)
            : 
            <UnitInput baseUnit={completeProperty.BaseUnit} value={theFilter.NumberValue ?? ""} onStateUpdate={numberValueChange}/>
            }
          </Table.Cell>
        </>
        )
      }
    }

    renderPropertyBasedFilter = (propertyFilter, filterIndex) =>
    {
      let completeProperty = this.getPropertyByName(propertyFilter.PropertyName)

      let propertySelectionChange = (_, {value}) =>
      {
        let newFilters = [...this.state.currentFilterEdit]
        newFilters[filterIndex].PropertyName = value
        
        this.setState({currentFilterEdit: newFilters})
      }

      return <Table.Row key={filterIndex}>
        <Table.Cell>
          <Form.Select fluid search value={propertyFilter.PropertyName ?? ""} options={this.propertyFieldOptions} onChange={propertySelectionChange}/>
        </Table.Cell>
          {this.getFilterInput(completeProperty, propertyFilter, filterIndex)}    
        <Table.Cell>
          <Icon link name="delete" color="red" onClick={this.removePropertyBasedFilter(filterIndex)} />
        </Table.Cell>
      </Table.Row>
    }

    addPropertyBasedFilter = () =>
    {
      let newPropertyBasedFilter = [...this.state.currentFilterEdit]
      newPropertyBasedFilter.push({PropertyName: "ObjectId"})
      this.setState({currentFilterEdit: newPropertyBasedFilter})
    }

    removePropertyBasedFilter = (propertyIndex) => () =>
    {
        let newPropertyBasedFilter = [...this.state.currentFilterEdit]
        newPropertyBasedFilter.splice(propertyIndex, 1)
        this.setState({currentFilterEdit: newPropertyBasedFilter})
    }

    getPropertyByName(propertyName)
    {
      return this.props.fields.find(prop => prop.PropertyName === propertyName)
    }
}

export default PropertyBasedFilterModal;