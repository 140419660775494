//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2019 www.deersoft.de
//-----------------------------------------------------------------------------
import LocalizedStrings from 'localized-strings';
import LocalizeOption from './LocalizationOption';
 
let en = require("./raw/en/Hotpatch.json")
let de = require("./raw/de/Hotpatch.json")
let es = require("./raw/es/Hotpatch.json")
let fr = require("./raw/fr/Hotpatch.json")
let it = require("./raw/it/Hotpatch.json")
let ru = require("./raw/ru/Hotpatch.json")
let cz = require("./raw/cs/Hotpatch.json")


let Strings = new LocalizedStrings<Partial<typeof en>>(
{
  en: en,
  de: de,
  es: es,
fr: fr,
ru: ru,
it: it,
cz: cz,
},
  { ...LocalizeOption }
);

export default  Strings;