//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------

import React, { Component } from 'react';
import { Container, ButtonGroup, Dimmer, Loader} from 'semantic-ui-react';
import "./video-react.css"; 
import "./tutorial.css"
import { getLocale } from "../../util/defines";
import LocalizedStrings from "../../localization/ProductionAssistLandingPage";
const ReactMarkdown = React.lazy(() => import("react-markdown"))
import {unified} from 'unified';
import remarkParse from 'remark-parse';
import gfm from "remark-gfm";
import YouTube from "react-youtube";
import { slugify } from '../../util/defines'
const Nav = React.lazy(() => import("../ProductionAssistLandingPage/LandingPageNav"));
import BasicSelect from '../Basics/BasicSelect';
import CONST from "../../webApp/CONST";





class TutorialVideos extends Component {

  constructor() {
    super();
    this.state = {
      value: "",
      loading: false,
      lang: getLocale(),
      ast: null,
    };
  }
  componentDidMount = async () => {

    this.reloadMarkdown()
  }

  reloadMarkdown = async () => {
    const url = new URL(`${CONST.BASE_URL}/api/markdown/${this.state.lang}/pa_tutorial.md`);
    this.setState({ loading: true });

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Failed to load markdown content');
      }

      const text = await response.text();
      const processor = unified().use(remarkParse);
      const ast = processor.parse(text);
      this.setState({ ast, loading: false, value: text });
    } catch (error) {
      console.error(error);
      this.setState({ value: LocalizedStrings.FailedToLoad, loading: false });
    }
  };

  generateSectionNumbers = () => {
    const toc = [];
    const sectionNumbers = [0, 0, 0, 0];

    const renderTocItem = (node, depth) => {
      if (node.type === 'heading') {
        sectionNumbers[depth - 1]++;
        const sectionNumber = sectionNumbers.slice(0, depth).join('.');
        const numberedText = `${sectionNumber} ${node.children[0].value}`;
        toc.push({ ...node, children: [{ type: 'text', value: numberedText }] });
      }

      if (node.children) {
        node.children.forEach((child) => renderTocItem(child, depth + 1));
      }
    };

    if (this.state.ast) {
      this.state.ast.children.forEach((child) => renderTocItem(child, 1));
    }

    return toc;
  };

  render() {
    const {  loading, ast } = this.state;
    const languageOptions = [
      { key: 'English', flag: "uk", text: "English", value: 'en' },
      { key: 'German', flag: "de", text: "Deutsch", value: 'de' },
      { key: 'Spanish', flag: "es", text: "Español", value: 'es' },
      { key: 'French', flag: "fr", text: "Français", value: 'fr' },
    ];

    if (loading) {
      return (
        <div className='tutorial'>
          <header>
            <Nav />
            <div>
              <h1>{LocalizedStrings.Header_Tutorial}</h1>
            </div>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0, 0, 100, 38.5896"
            >
              <path
                width="100%"
                height="100%"
                d="M 100 38.5896 
              L 0 38.5896 
              L 78.9959 24.6768 
              C 91.3502 22.3542 100 12.1998 100 0 
              L 100 0 
              z"
              />
            </svg>
          </header>
          <div style={{ position: 'relative' }}>
            <Container className='impressum content-container' style={{ marginTop: '-30vw', position: 'relative' }}>
              <Dimmer active>
                <Loader size='large'>Loading...</Loader>
              </Dimmer>
            </Container>
          </div>
        </div>
      );
    }

    const toc = ast ? this.generateSectionNumbers() : [];

   return (
  <>
    <div className='tutorial'>
      <header>
        <Nav />
        <div>
          <h1>{LocalizedStrings.Header_Tutorial}</h1>
        </div>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0, 0, 100, 38.5896"
        >
          <path
            width="100%"
            height="100%"
            d="M 100 38.5896 
          L 0 38.5896 
          L 78.9959 24.6768 
          C 91.3502 22.3542 100 12.1998 100 0 
          L 100 0 
          z"
          />
        </svg>
      </header>
      <div style={{ position: 'relative' }}>
        <Container className="impressum content-container " style={{ marginTop: "-30vw", position: 'relative' }}>
          <Dimmer active={this.state.loading}><Loader /> </Dimmer>
          <ButtonGroup floated="right">
            <BasicSelect
              normalDropdown
              style={{ position: "absolute", top: "25px", right: "25px" }}
              button
              className='icon'
              labeled
              icon='world'
              options={languageOptions}
              onStateUpdate={(name, value, e) => { this.setState({ lang: value }, () => this.reloadMarkdown()) }}
              value={this.state.lang}
            />
          </ButtonGroup>

          <div className="table-of-contents">
            <h2>Table of Contents</h2>
            <ol>
              {toc.map((item, index) => (
                <li key={index}>{item.children && item.children[0].value}</li>
              ))}
            </ol>
          </div>

                  <ReactMarkdown
                    remarkPlugins={[gfm]}
                    components={{
                      img: Image,
                      h1: h1,
                      h2: h2,
                      h3: h3,
                      h4: h4,
                      toc: ({ children }) => (
                        <ol>
                          {children.map((node, index) => (
                            <li key={index}>{node.props.children}</li>
                          ))}
                        </ol>
                      ),
                    }}
                  >
                    {this.state.value}
                  </ReactMarkdown>
        </Container>
      </div>
    </div>
  </>
);

  }
 
}


function id(props) {
  let id = props.children[0].toLowerCase()
  id = slugify(id);
  return id;
}
function h1(props) {
  return <h1 id={id(props)}>{props.children}</h1>
}
function h2(props) {
  return <h2 id={id(props)}>{props.children}</h2>
}
function h3(props) {
  return <h3 id={id(props)}>{props.children}</h3>
}
function h4(props) {
  return <h4 id={id(props)}>{props.children}</h4>
}

function Image(props) {

  const url = new URL(props.src);
  const urlParams = new URLSearchParams(url.search);
  let id = urlParams.get('v')
  return (
    <YouTube
      videoId={id}
    />
  );
}

export default TutorialVideos