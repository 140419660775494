//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';
import LabelField from './LabelField';
import { getDPMM } from '../../util/defines';
import LocalizedStrings from "../../localization/PrintLabelComponent";
import LocalizedStringsObjectProps from "../../localization/LightRightObjectsFields";
import { QRCodeCanvas } from 'qrcode.react';

const SPACINGX = 0; 
const SPACINGY = 1; 
const MARGINX = 2; 
const MARGINY = 3; 
class LabelEntry extends Component 
{
  constructor(props)
  {
    super(props)

    this.labelRef = React.createRef()
    this.labelFieldRef = []
  }

  componentDidUpdate()
  {
    this.labelFieldRef = []
  }

  elements = (x, y) => {
    let elements = []

    for(let i = 0; i < (x * y) ; i++) {
      elements.push(<div key={i} style={{backgroundColor:"white"}} />)
    }

    return elements
  }

  grid = () => {
    // round to next 10 i.e. 48 to 50 so the grid covers everything
    let widthToUpperTen = 10 - (this.props.sizeX % 10) + this.props.sizeX
    let heightToUpperTen = 10 - (this.props.sizeY % 10) + this.props.sizeY

    // depending on the grid size calculate the number of cells
    let numCellsX = widthToUpperTen / 10
    let numCellsY = heightToUpperTen / 10

    // height, width was in mm, now get scaled px
    let width = widthToUpperTen * this.props.scale * getDPMM() - 1
    let height = heightToUpperTen * this.props.scale * getDPMM() -1

    let elements = this.elements(numCellsX, numCellsY)
    return( <React.Fragment>
              <div style={{ display: "grid",
                            gridTemplateColumns: `repeat(${numCellsX}, 1fr)`,
                            gridTemplateRows: `repeat(${numCellsY}, 1fr)`,
                            height: height + "px",
                            gap: "1px",
                            width: width + "px"}} >{elements}</div>
            </React.Fragment>)
  }

  render() 
  {
    let backgroundColor = this.props.editable ? "rgba(0, 0, 0, 0.1)" : "white"    
    let width = this.props.sizeX * this.props.scale * getDPMM()
    let height = this.props.sizeY * this.props.scale * getDPMM()
    let borderWidth = this.props.editable ? ".3px" : ".3px"

    return (
      <div>
        {this.arrow(SPACINGY, width, height)}
        <div style={{ display: "inline-block",
                      whiteSpace: 'nowrap'}}>
          {this.arrow(SPACINGX, width, height)}
          <div style={{   width: width + "px", 
                          height: height + "px",
                          verticalAlign: "middle",
                          display: "inline-block",
                          backgroundColor: backgroundColor,
                          wordBreak: "normal", 
                          wordWrap: "normal",          
                          borderStyle:"solid", 
                          zIndex: "50",
                          borderWidth: borderWidth,
                          overflow: 'hidden'}}
                          ref = {this.labelRef}>
                  {this.props.editable && this.grid()}
                    {this.props.field ? this.props.field.map((value, index) => {
                      let name = value.PropertyName
                      let key = value.UUID
                      if (name === "QRCodeField") { 
                        return (
                          <LabelField key           = {key}
                                      scale         = {this.props.scale} 
                                      labelUuid     = {this.props.labelUuid} 
                                      editable      = {this.props.editable}
                                      isSquare 
                                      keys          = "qr-field" 
                                      id            = "qr-field" 
                                      field         = {value} 
                                      onChange      = {this.onLabelChanged} 
                                      ref           = {el => this.labelFieldRef[index] = el} 
                                      fieldName     = {name}>
                            <QRCodeCanvas value="{EB9F93D1-FBE2-44A4-8B16-1BD489748900}" size={100 * this.props.scale * (value.SizeX / 25)} />
                          </LabelField>
                        )
                      }
                      return <LabelField key       = {key} 
                                         scale     = {this.props.scale} 
                                         labelUuid = {this.props.labelUuid}
                                         editable  = {this.props.editable} 
                                         field     = {value} 
                                         onChange  = {this.onLabelChanged} 
                                         ref       = {el => this.labelFieldRef[index] = el} 
                                         fieldName = {name}>{LocalizedStringsObjectProps[name]}</LabelField>
                    }) : null}
            </div>
            {this.arrow(MARGINX, width, height)}
        </div>
        {this.arrow(MARGINY, width, height)}
      </div>
    )
  }

  arrow = (type, width, height) => {
    if (!this.props.editable) { return null }
    let left = width / 2 + (this.labelRef.current ? this.labelRef.current.offsetLeft : 72)

    switch(type) 
    {
      case SPACINGX : return (<div style = {{display: "inline-block", zIndex: "100", width:"max-content", verticalAlign: "middle"}}>
                                <Icon name  = "arrow left"/>
                                {LocalizedStrings.SpacingX}
                              </div>);

      case SPACINGY : return (<div style={{display: "block", paddingLeft: left, width: "100%", whiteSpace: 'nowrap'}}>
                                  <Icon style = {{display: "block", zIndex: "100", width:"max-content"}} 
                                        name  = "arrow up" />
                                  {LocalizedStrings.SpacingY}
                              </div>);

      case MARGINX  : return (<div style = {{display: "inline-block", zIndex: "100", width:"max-content", verticalAlign: "middle"}} >
                                {LocalizedStrings.MarginX}
                                <Icon name  = "arrow right"/>
                              </div>);

      case MARGINY  : return (<div style={{display: "block", paddingLeft: left, width: "100%", whiteSpace: 'nowrap'}}>
                                  {LocalizedStrings.MarginY}
                                  <Icon style = {{display: "block", zIndex: "100", width:"max-content"}} 
                                        name  = "arrow down" />
                              </div>);
      default       : return null;
    }
  }

  fieldsNeedUpdate(labelObject)
  {
    this.labelFieldRef.forEach(fieldRef => {
      if (fieldRef)
      {
        fieldRef.updateField(labelObject)
      }
    })
  }

  onLabelChanged = (changedField) =>
  {
    let labelUuid = this.props.labelUuid
    window.LR_SetPrintLabel({UUID: labelUuid, ...changedField})
  }

  getFieldInfos()
  {
    return this.labelFieldRef.map(elem => {
      return elem.state
    })
  }

}

export default LabelEntry;
