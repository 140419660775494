//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Accordion, Button, Checkbox, Form, Header, Icon, Input, Menu, Segment, Select, Table, Dropdown } from 'semantic-ui-react';
import LocalizedStrings from "../../localization/GlobalSettingsModal";
import LocalizedStringsDescription from "../../localization/GlobalSettingsModalDescription";
import { globalCallbacks } from '../../util/callback';
import { BASE_UNIT_FORCE_PER_DISTANCE, BASE_UNIT_LENGTH, BASE_UNIT_NUMBER, BASE_UNIT_WEIGHT, EWorkloadColoringMode_Gradient, EWorkloadColoringMode_Threshold, SupportTypeOptions } from '../../util/defines';
import LRModal from '../Basics/BasicModal';
import UnitInput, { formatDateToDateOnly, formatDateToTimeOnly } from '../Basics/BasicUnitInput';
import General from '../ProjectSettings/General';
import PrintSettings from './PrintSettings';
import ColorInputField from '../ColorPicker/ColorInputField';

const headerStyle = {
  cursor: 'pointer',
  display: "flex",
  flexDirection: "row",
  alignItems: "center"
};

class GlobalSettingsModal extends Component {
  constructor(props) {

    super(props);

    this.state = {
      open: false,
      settings: undefined,
      activeTab: "App",
      activeIndex: -1,
      NewLayerName: "",
      NewLayerNameSupport: "",
      NewLayerNameLoad: "",
      TrussCrossSection:[],
      LayerForSupports:[],
      LayerForFrames:[],
      LayerForLoads:[],
      PdfFormat : {}
    };
  }

  componentDidMount = async () => {
    this.setUpCallbacks();
  };

  onOkClicked = async () => {
    await window.LR_SetGlobalSettings({...this.state.settings});

    if (this.state.projectSettings) {
      await window.LR_SetProjectSettings({
        DrawingSettings: {
          ...this.state.projectSettings,
        },
        PdfFormat: {
          ...this.state.PdfFormat
        },
        LayerForFrames: this.state.LayerForFrames,
        LayerForSupports: this.state.LayerForSupports,
        LayerForLoads: this.state.LayerForLoads,
        DefaultConnectorType_Electrical: this.state.DefaultConnectorType_Electrical,
        DefaultConnectorType_Data: this.state.DefaultConnectorType_Data,
        DefaultBridleSet: this.state.DefaultBridleSet,

      });
    }

    this.close();
  };

  onCancelClick = () => {
    this.close();
  };

  render() {
    const tabs = ["App", "Project", "Printing", "Structure", "Electrical", "Bridle"];
    const tabRenderer = {
      App: this.renderAppSettings,
      Project: this.renderProjectSettings,
      Printing: this.renderProjectPaperSettings,
      Structure: this.renderLayerProperties,
      Electrical: this.renderElectricalProperties,
      Bridle: this.renderBridleProperties,
    }

    return (
      <LRModal
        open={this.state.open}
        title={LocalizedStrings.Header}
        onOkClick={this.onOkClicked}
        onCancelClick={this.onCancelClick}
        size="large"
      >
        <div style={{ height: "60vh", display: "flex", flexDirection: "row", width: "100%" }}>
          <Menu vertical>
            {tabs.map((tab, i) => (
              <Menu.Item
                key={i}
                name={tab}
                active={tab === this.state.activeTab}
                onClick={(e, { name }) => {
                  this.setState({ activeTab: name });
                }}
              ></Menu.Item>
            ))}
          </Menu>
          <div style={{ marginRight: 5, marginLeft: 5, width: "100%" }}>
            {tabRenderer[this.state.activeTab]()}
          </div>
        </div>
      </LRModal>
    );
  }

  renderAppSettings = () => {
    let settingsGrouping = this.state.settingsGrouping;



    return (
      <Accordion fluid styled>
        {settingsGrouping?.map((group, index) => {
          const isActive = this.state.activeIndex === index;
          return (
            <React.Fragment key={group.GroupingName}>
              <Accordion.Title
                active={isActive}
                index={index}
                onClick={this.handleAccordionClick}
                style={headerStyle}
              >
                <Icon name='dropdown' />
                <Header style={{ margin: 0 }} as="h2">{LocalizedStrings[group.GroupingName]}</Header>
              </Accordion.Title>
              <Accordion.Content active={isActive}>
                <Table>
                  {group.GroupingItems.map(item => {
                    let value = this.state.settings[item];

                    return (
                      <Table.Row key={item}>
                        <Table.Cell>
                          <p style={{ lineHeight: "0.4rem" }}>
                            {LocalizedStrings[item]}
                          </p>
                          <p
                            style={{
                              fontSize: "11px",
                              fontStyle: "italic",
                              letterSpacing: 0.25,
                            }}
                          >
                            {LocalizedStringsDescription[item]}
                          </p>
                        </Table.Cell>
                        <Table.Cell style={{ width: "225px" }}>
                          <Form>{this.renderValue(item, value)}</Form>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table>
              </Accordion.Content>
            </React.Fragment>
          );
        })}
      </Accordion>
    );
  }

  handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  renderProjectSettings = () => {
    if (this.state.projectSettings) {
      return <General
        noDeleteButton
        drawingSettings={this.state.projectSettings}
        description={this.state.projectSettings.ProjectDescription}
        onDataUpdate={(name, value) => {
          this.setState({
            projectSettings: {
              ...this.state.projectSettings,
              [name]: value
            }
          })
        }}
      />
    } else {
      return <Segment placeholder>
        <Header icon>{LocalizedStrings.NoProjectFound}</Header>
      </Segment>
    }
  };

  renderProjectPaperSettings = ()=>{
    return <PrintSettings
      format={this.state.PdfFormat}
      onFormatChange={(format, name, val) => this.setState({ PdfFormat: { ...this.state.PdfFormat, [format]: { ...this.state.PdfFormat[format], [name]: val } } }) }
      onAllFormatChange={format => this.setState({ PdfFormat: { ...this.state.PdfFormat, ...format } })}
    />
  }

  renderLayerProperties = ()=>{
    return<>
    {this.renderProjectStructure()}
    {this.renderProjectSupport()}
    {this.renderProjectLoad()}
    </>
  }

  renderElectricalProperties = ()=>{
    return<>
    <h2>{LocalizedStrings.DefaultConnectors}</h2>
    <Form>
              <Form.Dropdown
            search
            labeled
            label     = {LocalizedStrings.PowerConnectors}
            value     = {this.state.DefaultConnectorType_Electrical}
            options   = {this.state.ConnectorsElectric}  
            onChange  = {(event, {value})=>{this.setState({DefaultConnectorType_Electrical: value})}}
            />
          <Form.Dropdown
            labeled
            search
            label     = {LocalizedStrings.DataConnectors}
            value     = {this.state.DefaultConnectorType_Data}
            options   = {this.state.ConnectorsData}  
            onChange  = {(event, {value})=>{this.setState({DefaultConnectorType_Data: value})}}
            />

  </Form>
    </>
  }

renderBridleProperties = ()=>{
  return<>
  <h2>{LocalizedStrings.DefaultBridleParts}</h2>
  <Form>
            <Form.Dropdown
          search
          labeled
          label     = {LocalizedStrings.DefaultBridleSet}
          value     = {this.state.DefaultBridleSet}
          options   = {this.state.DefaultBridleSets}  
          onChange  = {(event, {value})=>{this.setState({DefaultBridleSet: value})}}
          />
</Form>
  </>

  }

  renderProjectStructure = ()=>{

    let deleteEntry = (e) =>
    {
      this.setState({LayerForFrames: this.state.LayerForFrames.filter(i=>i.LayerName!==e.LayerName)})
    }

    let addEntry = () =>
    {
      if(this.state.LayerForFrames.filter(i=>i.LayerName===this.state.NewLayerName).length == 0)
      {
        this.setState({LayerForFrames: [...this.state.LayerForFrames, {LayerName: this.state.NewLayerName, CrossSection:""}]})
      }
    }

    let update = (LayerName, newCS, property) =>
    {
      this.setState({LayerForFrames: this.state.LayerForFrames.map(i=>{
        return{
          ...i,
          [property]: i.LayerName === LayerName ? newCS : i[property]
        }
      })})

    }


    return <>
    <h2>{LocalizedStrings.StructureLayers}</h2>
    <Table striped structured compact='very' size="small">
      <Table.Header>
        <Table.Row>
        <Table.HeaderCell>{LocalizedStrings.LayerName}</Table.HeaderCell>
        <Table.HeaderCell>{LocalizedStrings.CrossSection}</Table.HeaderCell>
        <Table.HeaderCell>{LocalizedStrings.IsInternalStructure}</Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {this.state.LayerForFrames.map(e=>
        <Table.Row key={e.LayerName}>
          <Table.Cell>{e.LayerName}</Table.Cell>
          <Table.Cell>
          <Dropdown
            fluid
            search
            value     = {e.CrossSection}
            options   = {[...this.state.TrussCrossSection, {
              key   : "EMPTY",
              value : '',
              text  : LocalizedStrings.UseFromGeometry,
            }, ]}  
            onChange  = {(event, {value})=>{update(e.LayerName, value, "CrossSection")}}
            />
            </Table.Cell>
            <Table.Cell>
              <Checkbox checked={e.IsInternalStructure} onChange={()=>{update(e.LayerName, !e.IsInternalStructure, "IsInternalStructure")}} />
            </Table.Cell>
          <Table.Cell><Icon name="delete" color='red' onClick={()=>deleteEntry(e)} /></Table.Cell>
        </Table.Row>)}

      </Table.Body>
    </Table>
    <Input
    fluid
    value={this.state.NewLayerName}
      onChange={(e, {value}) => this.setState({NewLayerName: value})}
      action
    >
      <input/>
      <Button positive onClick={addEntry}>
        <Icon name='plus'/>
        {LocalizedStrings.AddLayerName}
      </Button>
    </Input>
    </>
  }

  renderProjectSupport = ()=>{

    let deleteEntry = (e) =>
    {
      this.setState({LayerForSupports: this.state.LayerForSupports.filter(i=>i.LayerName!==e.LayerName)})
    }

    let addEntry = () =>
    {
      if(this.state.LayerForSupports.filter(i=>i.LayerName===this.state.NewLayerNameSupport).length == 0)
      {
        this.setState({LayerForSupports: [...this.state.LayerForSupports, {
          LayerName: this.state.NewLayerNameSupport, 
          CrossSection:""
        
        }]})
      }
    }

    let update = (LayerName, newCS, property) =>
    {
      this.setState({LayerForSupports: this.state.LayerForSupports.map(i=>{
        return{
          ...i,
          [property]: i.LayerName === LayerName ? newCS : i[property]
        }
      })})

    }


    return <>
    <h2>{LocalizedStrings.SupportLayers}</h2>
    <Table striped structured compact='very' size="small">
      <Table.Header>
        <Table.Row>
        <Table.HeaderCell>{LocalizedStrings.LayerName}</Table.HeaderCell>
        <Table.HeaderCell>{LocalizedStrings.CrossSection}</Table.HeaderCell>
        <Table.HeaderCell>{LocalizedStrings.SupportType}</Table.HeaderCell>
        <Table.HeaderCell>{LocalizedStrings.ResistanceX}</Table.HeaderCell>
        <Table.HeaderCell>{LocalizedStrings.ResistanceY}</Table.HeaderCell>
        <Table.HeaderCell>{LocalizedStrings.ResistanceZ}</Table.HeaderCell>
        <Table.HeaderCell>{LocalizedStrings.ResistanceXX}</Table.HeaderCell>
        <Table.HeaderCell>{LocalizedStrings.ResistanceYY}</Table.HeaderCell>
        <Table.HeaderCell>{LocalizedStrings.ResistanceZZ}</Table.HeaderCell>
        <Table.HeaderCell>{LocalizedStrings.Capacity}</Table.HeaderCell>
        <Table.HeaderCell>{LocalizedStrings.AllowUpliftForces}</Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {this.state.LayerForSupports.map(e=>
        <Table.Row key={e.LayerName}>
          <Table.Cell>{e.LayerName}</Table.Cell>
          <Table.Cell>
          <Dropdown
            fluid
            search
            value     = {e.CrossSection}
            options   = {[...this.state.TrussCrossSection, {
              key   : "EMPTY",
              value : '',
              text  : LocalizedStrings.UseFromGeometry,
            }, ]}  
            onChange  = {(event, {value})=>{update(e.LayerName, value, "CrossSection")}}
            />
            </Table.Cell>
            <Table.Cell>
          <Dropdown
            fluid
            search
            value     = {e.SupportType}
            options   = {SupportTypeOptions}  
            onChange  = {(event, {value})=>{update(e.LayerName, value, "SupportType")}}
            />
            </Table.Cell>
            <Table.Cell><UnitInput name="ResistanceX" baseUnit={BASE_UNIT_FORCE_PER_DISTANCE} value={e.ResistanceX} onStateUpdate={(name, val) => {update(e.LayerName, val, name)}}/></Table.Cell>
            <Table.Cell><UnitInput name="ResistanceY" baseUnit={BASE_UNIT_FORCE_PER_DISTANCE} value={e.ResistanceY} onStateUpdate={(name, val) => {update(e.LayerName, val, name)}}/></Table.Cell>
            <Table.Cell><UnitInput name="ResistanceZ" baseUnit={BASE_UNIT_FORCE_PER_DISTANCE} value={e.ResistanceZ} onStateUpdate={(name, val) => {update(e.LayerName, val, name)}}/></Table.Cell>
            <Table.Cell><UnitInput name="ResistanceXX" baseUnit={BASE_UNIT_FORCE_PER_DISTANCE} value={e.ResistanceXX} onStateUpdate={(name, val) => {update(e.LayerName, val, name)}}/></Table.Cell>
            <Table.Cell><UnitInput name="ResistanceYY" baseUnit={BASE_UNIT_FORCE_PER_DISTANCE} value={e.ResistanceYY} onStateUpdate={(name, val) => {update(e.LayerName, val, name)}}/></Table.Cell>
            <Table.Cell><UnitInput name="ResistanceZZ" baseUnit={BASE_UNIT_FORCE_PER_DISTANCE} value={e.ResistanceZZ} onStateUpdate={(name, val) => {update(e.LayerName, val, name)}}/></Table.Cell>
            <Table.Cell><UnitInput name="Capacity" baseUnit={BASE_UNIT_WEIGHT} value={e.Capacity} onStateUpdate={(name, val) => {update(e.LayerName, val, name)}}/></Table.Cell>
            <Table.Cell> <Checkbox checked={e.AllowNegativeZ} onChange={()=>{update(e.LayerName, !e.AllowNegativeZ, "AllowNegativeZ")}} /></Table.Cell>
          <Table.Cell><Icon name="delete" color='red' onClick={()=>deleteEntry(e)} /></Table.Cell>
        </Table.Row>)}

      </Table.Body>
    </Table>
    <Input
    fluid
    value={this.state.NewLayerNameSupport}
      onChange={(e, {value}) => this.setState({NewLayerNameSupport: value})}
      action
    >
      <input/>
      <Button positive onClick={addEntry}>
        <Icon name='plus'/>
        {LocalizedStrings.AddLayerName}
      </Button>
    </Input>
    </>
  }

  renderProjectLoad= ()=>{

    let deleteEntry = (e) =>
    {
      this.setState({LayerForLoads: this.state.LayerForLoads.filter(i=>i.LayerName!==e.LayerName)})
    }

    let addEntry = () =>
    {
      if(this.state.LayerForLoads.filter(i=>i.LayerName===this.state.NewLayerNameLoad).length == 0)
      {
        this.setState({LayerForLoads: [...this.state.LayerForLoads, {
          LayerName: this.state.NewLayerNameLoad, 
          IsAreaLoad:false
        
        }]})
      }
    }

    let update = (LayerName, newCS, property) =>
    {
      this.setState({LayerForLoads: this.state.LayerForLoads.map(i=>{
        return{
          ...i,
          [property]: i.LayerName === LayerName ? newCS : i[property]
        }
      })})

    }


    return <>
    <h2>{LocalizedStrings.LoadLayers}</h2>
    <Table striped structured compact='very' size="small">
      <Table.Header>
        <Table.Row>
        <Table.HeaderCell>{LocalizedStrings.LayerName}</Table.HeaderCell>
        <Table.HeaderCell>{LocalizedStrings.IsAreaLoad}</Table.HeaderCell>
        <Table.HeaderCell>{LocalizedStrings.IsFreeLineLoad}</Table.HeaderCell>
        <Table.HeaderCell>{LocalizedStrings.IsWindLoad}</Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {this.state.LayerForLoads.map(e=>
        <Table.Row key={e.LayerName}>
          <Table.Cell>{e.LayerName}</Table.Cell>
          <Table.Cell> <Checkbox checked={e.IsAreaLoad} onChange={()=>{update(e.LayerName, !e.IsAreaLoad, "IsAreaLoad")}} /></Table.Cell>
          <Table.Cell> <Checkbox checked={e.IsFreeLineLoad} onChange={()=>{update(e.LayerName, !e.IsFreeLineLoad, "IsFreeLineLoad")}} /></Table.Cell>
          <Table.Cell> <Checkbox checked={e.IsWindLoad} onChange={()=>{update(e.LayerName, !e.IsWindLoad, "IsWindLoad")}} /></Table.Cell>
          <Table.Cell><Icon name="delete" color='red' onClick={()=>deleteEntry(e)} /></Table.Cell>
        </Table.Row>)}

      </Table.Body>
    </Table>
    <Input
    fluid
    value={this.state.NewLayerNameLoad}
      onChange={(e, {value}) => this.setState({NewLayerNameLoad: value})}
      action
    >
      <input/>
      <Button positive onClick={addEntry}>
        <Icon name='plus'/>
        {LocalizedStrings.AddLayerName}
      </Button>
    </Input>
    </>
  }

  renderValue = (settingName, val) => {
    let valueType = typeof val;
    // "string" | "number" | "bigint" | "boolean" | "symbol" | "undefined" | "object" | "function"

    switch (valueType) {
      case "boolean":
        return this.renderBoolVal(settingName, val);
      case "number":
        return this.renderNumberVal(settingName, val);
      case "string":
        return this.renderStringVal(settingName, val);
      case "object":
        return this.renderObjectVal(settingName, val);
      default:
        return null;
    }
  };

  renderBoolVal = (settingName, val) => {
    return (
      <Checkbox
        toggle
        checked={val}
        onChange={(e, { checked }) => {
          this.updateValueBool(settingName, checked);
        }}
      />
    );
  };

  renderNumberVal = (settingName, val) => {
    if (settingName === "Renderer_Workload_Coloring") {
      return this.renderWorkloadColoring(val);
    } else if (settingName.startsWith("GridSize")) {
      return <UnitInput
        name={settingName}
        label={LocalizedStrings["settingName"]}
        baseUnit={BASE_UNIT_LENGTH}
        value={val}
        min={0}
        onStateUpdate={(name, val) => {
          this.setState({
            settings: {
              ...this.state.settings,
              [settingName]: val
            }
          })
        }}
      />
    } else {
      return <UnitInput
        name="Accuracy"
        label={LocalizedStrings.Accuracy}
        baseUnit={BASE_UNIT_NUMBER}
        value={val}
        min={0}
        onStateUpdate={(name, val) => {
          this.setState({
            settings: {
              ...this.state.settings,
              [settingName]: val
            }
          })
        }}
      />
    }

  };

  renderWorkloadColoring(val) {
    let options = [
      {
        key: EWorkloadColoringMode_Threshold,
        value: EWorkloadColoringMode_Threshold,
        text: "Threshold",
      },
      {
        key: EWorkloadColoringMode_Gradient,
        value: EWorkloadColoringMode_Gradient,
        text: "Gradient",
      },
    ];
    return (
      <Select
        value={val}
        options={options}
        onChange={(e, { value }) => {

          this.setState({
            settings: {
              ...this.state.settings,
              Renderer_Workload_Coloring: value,
            },
          });
        }}
      />
    );
  }


  renderStringVal = (settingName, val) => {

    if (settingName === "DateFormat" || settingName === "TimeFormat") {
      let fun = settingName === "DateFormat" ? formatDateToDateOnly : formatDateToTimeOnly
      return <div style={{ display: "flex", flexDirection: "column" }}>
        <Input list={settingName === "DateFormat" ? "AvailableDateFormats" : "AvailableTimeFormats"} value={this.state.settings[settingName]} onChange={(e) => {
          this.setState({
            settings: {
              ...this.state.settings,
              [settingName]: e.target.value
            }
          })
        }}
        />
        <datalist id='AvailableDateFormats'>
          <option value='DD.MM.YYYY'>21.06.2022</option>
          <option value='MM.DD.YYYY'>06.21.2022</option>
          <option value='dddd DD.MM.YYYY'>Tuesday 21.06.2022</option>
          <option value='DD. MMMM YYYY'>21. June 2022</option>
        </datalist>

        <datalist id='AvailableTimeFormats'>
          <option value='hh:mm A'>02:30 PM</option>
          <option value='HH:mm'>14:13</option>
          <option value='HH:mm:ss'>14:13:30</option>
        </datalist>

        <Input value={fun(new Date(), this.state.settings[settingName], this.state.settings?.Language?.Value)} disabled />
      </div>
    }

    return <Input value={this.state.settings[settingName]} onChange={(e) => {
      this.setState({
        settings: {
          ...this.state.settings,
          [settingName]: e.target.value
        }
      })
    }} />;
  };

  renderObjectVal = (settingName, val) => {
    let objectType = val.Type;
    switch (objectType) {
      case "enum":
        return this.renderEnum(settingName);
      case "Vector3D":
        return this.renderVector(settingName);
      case "Color3D":
        return this.renderColor(settingName);
      default:
        return null;
    }
  };

  updateVector = (settingName, name, value) => {
    let newSetting = {
      [settingName]: {
        ...this.state.settings[settingName],
        [name]: value
      }
    }


    this.setState({
      settings: {
        ...this.state.settings,
        ...newSetting
      },
    });
  }

  renderVector = (settingName) => {
    let setting = this.state.settings[settingName];
    return (
      <Form.Group key={settingName} widths="equal">
        {
          ["X", "Y", "Z"].map(i => <UnitInput
            name={i}
            key={i}
            label={LocalizedStrings[i]}
            baseUnit={BASE_UNIT_NUMBER}
            value={setting[i]}
            onStateUpdate={(name, val) => this.updateVector(settingName, name, val)}
          />)
        }
      </Form.Group>
    );
  };

  renderColor = (settingName) => {
    let setting = this.state.settings[settingName];

    return (
      <Form.Group key={settingName} widths="equal">
        <ColorInputField
          colorX={setting.X}
          colorY={setting.Y}
          colorL={setting.Z}
          onColorChanged={(cie) =>  {
              this.setState({
                settings:{
                  ...(this.state.settings),
                  [settingName]: {
                    ...setting,
                    X: cie.fx, 
                    Y: cie.fy, 
                    Z: cie.f_Y 
                  }
                }
              })
          }}
        />
      </Form.Group>
    );
  };

  renderEnum = (settingName) => {
    let setting = this.state.settings[settingName];

    //let options = setting.Options.map(option => {return {text: getUnitName(option), value: option}});
    let options = setting.Options;

    let update = (name, value) => {
      let newSetting = {
        [settingName]: {
          ...this.state.settings[settingName],
          [name]: value
        }
      }


      this.setState({
        settings: {
          ...this.state.settings,
          ...newSetting
        },
      });
    };
    return (
      <Form.Group key={settingName} widths="equal">
        <Form.Select
          style={{width: setting.Accuracy ? 110 : undefined}}
          options={options}
          value={setting.Value}
          onChange={(e, { value }) => {
            update("Value", value);
          }}
        />
        {setting.Accuracy !== undefined ? (
          <UnitInput
            name="Accuracy"
            label={LocalizedStrings.Accuracy}
            baseUnit={BASE_UNIT_NUMBER}
            value={setting.Accuracy}
            min={0}
            onStateUpdate={(name, val) => {
              update("Accuracy", val);
            }}
          />
        ) : null}
      </Form.Group>
    );
  };

  updateValueBool = (settingName, val) => {
    let changedSettings = {};
    changedSettings[settingName] = val;

    this.setState({
      settings: {
        ...this.state.settings,
        ...changedSettings,
      },
    });
  };

  show = () => {
    this.setState({ open: true });
  };

  close = () => {
    this.setState({ open: false });
  };

  setUpCallbacks = () => {
    globalCallbacks.openSettings = async () => {
      let res = await window.LR_GetProjectSettings()
      if (res) {
        this.setState({ 
          projectSettings: res.DrawingSettings, 
          PdfFormat: res.PdfFormat, 
          LayerForFrames: res.LayerForFrames, 
          LayerForSupports: res.LayerForSupports, 
          DefaultConnectorType_Electrical: res.DefaultConnectorType_Electrical, 
          DefaultConnectorType_Data: res.DefaultConnectorType_Data, 
          DefaultBridleSet: res.DefaultBridleSet, 
          LayerForLoads: res.LayerForLoads 
        });
      }

      console.log("res", res)


      let ret        = await window.LR_GetTrussCrossSection();
      let trussCrossSection = ret.ResourceMap.crossSection.map((tc) =>
      {
        return(
          {
            key   : tc.Name,
            value : tc.Name,
            text  : tc.Name,
          }
        );
      })


      let ret2        = await window.LR_GetConnectors_Electric()
      let ret3        = await window.LR_GetConnectors_Data()

      let ConnectorsElectric = ret2.Connectors.map((tc) =>
        {
          return(
            {
              key   : tc.ConnectorName,
              value : tc.ConnectorName,
              text  : tc.LocalizedName,
            }
          );
        })

        let ConnectorsData = ret3.Connectors.map((tc) =>
          {
            return(
              {
                key   : tc.ConnectorName,
                value : tc.ConnectorName,
                text  : tc.LocalizedName,
              }
            );
          })

        let res4 = await window.LR_GetSymbolDefs()
          
        let DefaultBridleSets = []
        if(res4?.SymbolDefs && Array.isArray(res4.SymbolDefs))
        {
          for(let sym of res4.SymbolDefs)
            {
    
              if(sym.UseAsBridleInventory){
                DefaultBridleSets.push({
                  key: sym.UUID,
                  value:sym.UUID,
                  text: sym.Name
                })
              }            
            }
        }

        
      
      const currentSettings = await window.LR_GetGlobalSettings();
      this.setState({ 
        ConnectorsData, 
        ConnectorsElectric, 
        DefaultBridleSets, 
        settings: currentSettings.GlobalSettings, 
        settingsGrouping: currentSettings.GlobalSettingsGrouping, 
        TrussCrossSection: trussCrossSection 
      });
      this.show();
    };
  };
}

export default GlobalSettingsModal;