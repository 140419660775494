
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Icon, Popup, Table } from 'semantic-ui-react'
import LocalizedStrings from '../../localization/SceneTimeLineComponent';
import {  IsRunningAsBrowser } from '../../util/defines';
import { Link } from 'react-router-dom';
import { ACTIVE_PROJECT } from '../../redux/redux_defines';
import { connect } from 'react-redux';



class WorksheetRow extends Component 
{

  constructor(props)
  {
    super(props);
    this.state = 
    {
    };

  }
  render() 
  {
    let worksheet = this.props.worksheet
    let linkName = false
    if(IsRunningAsBrowser())
    {
      if (this.props.active_project.data)
      {
        linkName = this.props.active_project.data.owner.username + "/" + this.props.active_project.data.name
      }
    }
    return <React.Fragment>
        <Table.Row style = {{"backgroundColor": "white"}}>
            <Table.Cell colSpan="3" />
            <Table.Cell colSpan="2">{worksheet.Name}</Table.Cell>
            <Table.Cell/>
            <Table.Cell>
            
            <Popup content={LocalizedStrings.OpenWorksheet}
            trigger={   
                linkName ?                    
                <Icon name='file' as={linkName?Link:undefined} to={linkName??`/${linkName}/worksheet/uuid/${worksheet.UUID}`}/> :
                <Icon name='file' onClick={()=>{window.LR_ShowWorkSheet({UUID: worksheet.UUID})}}/>     
                    
            }/>
                
            </Table.Cell>
            <Table.Cell/>
        </Table.Row>    
    </React.Fragment>
  }

}

const mapStateToProps = (state) => 
{
    return {
      active_project: (state[ACTIVE_PROJECT]),
    };
}

export default connect(mapStateToProps)(WorksheetRow)

