//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2019 www.deersoft.de
//-----------------------------------------------------------------------------
import LocalizedStrings from '../../localization/ChangeRequest';
import React, { Component } from 'react';
import { Header, Table } from 'semantic-ui-react';
import { globalCallbacks } from '../../util/callback';
import LRModal from '../Basics/BasicModal';

const mapObject = (obj, func) => {
    let ret = {}
    Object.keys(obj).forEach(key => ret[key] = func(obj[key], key))
    return ret
}

const filterObject = (obj, pred) => {
    let ret = {}
    if(typeof obj !== 'object') return obj
    Object.keys(obj).forEach(key => {
        if(pred(obj[key])) {
            ret[key] = obj[key]
        }
    })
    return ret
}


const isEmpty = (obj) => 
    obj === undefined || (
        typeof obj === 'object' 
        && obj !== null 
        && Object.keys(obj).length === 0
    )

const isNotEmpty = (obj) => 
    !isEmpty(obj)

const filterNonConflictsOfObj = changes =>
    filterObject(changes, change => change && change.Value && change.Value.Conflict !== undefined)

const removeEmptyFromChangeType = (changeType) => 
    filterObject(mapObject(changeType, (x) => ({...x, Changes: filterNonConflictsOfObj(x.Changes)})), isNotEmpty)

const removeEmptyChangesFromObjType = objType => 
    filterObject(mapObject(objType, removeEmptyFromChangeType), isNotEmpty)

function filterConflicts(diff) {
    return filterObject(mapObject(diff, removeEmptyChangesFromObjType), isNotEmpty)
}

const filterNonApplyOfObj = changes =>
    filterObject(changes, change => change && change.Value && change.Value.Apply)

const removeEmptyApplyFromChangeType = (changeType, key) => 
    filterObject(
        mapObject(changeType, (x) => ( x && key !== 'Changed' && !x.Apply ? {} : {...x, Changes: filterNonApplyOfObj(x.Changes)})), 
        obj => (key === 'Changed' && isNotEmpty(obj.Changes)) || (key !== 'Changed' && isNotEmpty(obj))
    )

const removeEmptyApplyChangesFromObjType = objType => 
    filterObject(mapObject(objType, removeEmptyApplyFromChangeType), isNotEmpty)

export function filterApply(diff) {
    return filterObject(mapObject(diff, removeEmptyApplyChangesFromObjType), isNotEmpty)
}

const mapKeyVal = (obj, func, update = () => {}) => 
    Object.entries(obj).map(([key, val]) => 
        func(key, val, (val) => 
            update({
                ...obj,
                [key]: val
            })
        )
    )

export function deepAssign(target, src) {
    if(typeof src !== 'object' && src !== undefined) return src
    if(src === undefined) return target
    if(typeof target !== 'object') return target
    if(Array.isArray(target)) return target.map((v,i) => src[i] !== undefined ? src[i] : v)
    let res = {...target}
    for (const key in src) {
        if (Object.hasOwnProperty.call(src, key)) {
            res[key] = deepAssign(res[key], src[key]);
        }
    }
    return res
}

function hasConflict(obj) {
    if(typeof obj !== 'object') return false
    return Object.entries(obj).some(([key, val]) => (key === 'Conflict' && val === true) || hasConflict(val))
}

class MergeView extends Component 
{
    constructor() 
    {
        super()
        this.state = 
        {
            open: false,
            diffs: {},
            origDiff: {},
        }
    }
    
    componentDidMount = () => 
    {
        this.setUpCallbacks()
    }

    render() 
    {
        return <LRModal 
                open={this.state.open} 
                title={LocalizedStrings.Merge}
                onCancelClick={this.close}
                onOkClick={this.onOk}
                okDisabled= {hasConflict(this.state.diffs)}
                >
                {mapKeyVal(filterConflicts(this.state.diffs), this.renderObjectType, this.updateDiffs)}          
        </LRModal>
    }

    close = () => {
        this.setState({open:false})
    }

    onOk = () => {
        window.LR_UpdateApprovedChanges(deepAssign(this.state.origDiff,this.state.diffs))
        this.close()
    }

    updateDiffs = (diffs) => {
        this.setState({
            diffs
        })
    }

    renderObjectType = (objType, data, update) => {
        return <>
            <Header>{objType}</Header>
            {mapKeyVal(data, this.renderChangeType, update)}
        </> 
    }

    renderChangeType = (changeType, data, update) => {
        if(changeType === "Changed") {
            return mapKeyVal(data, this.renderChangedObject, update)
        }
    }

    renderChangedObject = (uuid, data, update) => {
        return <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>{data.ObjectName}</Table.HeaderCell>
                    <Table.HeaderCell>{LocalizedStrings.Base}</Table.HeaderCell>
                    <Table.HeaderCell>{LocalizedStrings.Target}</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {mapKeyVal(data.Changes, this.renderChangedProp, (val) => update({
                    ...data,
                    Changes: val
                }))}
            </Table.Body>
        </Table>
    }

    renderChangedProp = (name, value, update) => {
        return <Table.Row error={value.Value.Conflict}>
            <Table.Cell>
                {name}
            </Table.Cell>
            <Table.Cell 
                positive={value.Value.Apply === false && !value.Value.Conflict}
                onClick={() => update({
                    ...value,
                    Value: {
                        ...value.Value,
                        Conflict: false,
                        Apply: false
                    }
                })}
            >
                {JSON.stringify(value.Value.ExistingValue)}
            </Table.Cell>
            <Table.Cell
                positive={value.Value.Apply === true && !value.Value.Conflict}
                onClick={() => update({
                    ...value,
                    Value: {
                        ...value.Value,
                        Conflict: false,
                        Apply: true
                    }
                })}
            >
                {JSON.stringify(value.Value.NewValue)}
            </Table.Cell>
        </Table.Row>
    }

    setUpCallbacks()
    {
        globalCallbacks.showMergeView = (args) =>
        {
            this.setState({
                open: true,
                diffs: args,
                origDiff: args
            })
        }
    }
}
export default MergeView