
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Table, Icon, Input, Popup, } from 'semantic-ui-react'
import {cie2hex} from "./../ColorPicker/utilities"
import LocalizedStrings from "../../localization/NavigationContainer";
import { globalCallbacks as mainCB } from '../../util/callback';
import { globalCallbacks as mockCB } from '../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB

class ClassNode extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = this.props.preset;
    }

    componentDidUpdate(oldProps) 
    {
        const newProps = this.props;
        for(const [name, value] of Object.entries(newProps.preset))
        if(oldProps.preset[name] !== value) 
        {
            this.setState({[name]: value});
        }
    }

    render() 
    {
        let preset = this.state;
        let hexColor = cie2hex({fx: preset.Color.X, fy: preset.Color.Y, f_Y: preset.Color.Z})
        return (
            <Table.Row  positive={preset.Editable} 
                        draggable       = {true}
                        onDragStart     = {() => {if (this.props.onDragStart) {this.props.onDragStart()}}}
                        onDragEnter     = {() => {if (this.props.onDragEnter) {this.props.onDragEnter()}}}
                        onDragEnd       = {(e) => {if (this.props.onDragEnd) {this.props.onDragEnd()} e.stopPropagation();}}>
                <Table.Cell collapsing>
                    <Popup content={LocalizedStrings.PresetColor}
                    trigger={
                        <div style={{backgroundColor:`${hexColor}`, width: "1rem", height:"1rem", borderRadius: 90}}/>
                    }/>
                </Table.Cell>
                <Table.Cell collapsing>
                    <Popup content={LocalizedStrings.PresetReorder}
                    trigger=
                    {
                        <Icon name="bars"/>
                    }/>
                    </Table.Cell>
                    <Table.Cell >
                    <Input  transparent
                            name      = "Name" 
                            value     = {preset.Name} 
                            onChange  = {this.onValueChanged} 
                            onBlur    = {() => this.updateData("Name", preset.Name)}
                            onKeyDown = {this.onKeyDown}
                            fluid/>
                </Table.Cell>
                <Table.Cell textAlign="center" collapsing>
                    
                    <PresetToggle Active={preset.Active} UUID={preset.UUID}/>
                    <Popup content={LocalizedStrings.EditPreset}
                    trigger=
                    {
                        <Icon   name    = "edit"
                                link
                                onClick = {() => this.onShowPresetEdit(preset.UUID)}
                                />
                    }/>
                    <Popup content={LocalizedStrings.DeletePreset}
                    trigger={
                    <Icon   name    = "delete"
                            color   = "red"
                            link
                            onClick = {() => this.onDelete(preset.UUID)}
                            />
                    }/>
                </Table.Cell>
            </Table.Row>
        );
    }

    onValueChanged = (e, {name, value}) =>
    {
        this.setState({[name]:value});
    }

    updateData = (name, value) =>
    {
        let request = 
        {
            UUID: this.state.UUID
        };

        if(name)    { request[name] = value; }
        else        { request.Name  = this.state.Name; }
        
        window.LR_SetPreset(request);
        this.setState({[name]:value});
    }

    onKeyDown = (event) =>
    {
        if(event.keyCode === 13) { this.updateData(); event.target.blur()}
    }

    onDelete = (uuid) =>
    {
        let request = 
        {
            UUID: uuid,
        }
        window.LR_DeletePreset(request);
    }

    onShowPresetEdit =(uuid) =>
    {
        let request = 
        {
            UUID: uuid,
        }
    
        if (globalCallbacks.ShowPresetEdit) { globalCallbacks.ShowPresetEdit(request);}
    }

}

class PresetToggle extends Component 
{
    render()
    {
        let colorForPreset = {}
        if(this.props.Active)
        {
            colorForPreset.color = "green"
        }
        return(
            <React.Fragment>
                <Popup content={LocalizedStrings.PresetEditMode}
                trigger=
                {
                <Icon   name    ="cogs" 
                        link
                        onClick = {() => {this.setEditable(this.props.UUID)}}
                />
                }/>
                <Popup content={LocalizedStrings.PresetActiveMode}
                trigger={
                <Icon   name    ={this.props.Active ? "toggle on" : "toggle off"} 
                        style ={colorForPreset}
                        link
                        onClick = {() => {this.setActive(this.props.UUID, !this.props.Active)}}
                />
                }/>
            </React.Fragment>
        )
    }
    setActive = async (uuid, active) =>
    {
        let request = 
        {
            UUID : uuid,
            Active : active,
        };
        window.LR_SetActivePreset(request);
    }

    setEditable = async (uuid) =>
    {
        this.setActive(uuid, true)
        let request = 
        {
            UUID : uuid
        };
        window.LR_SetEditablePreset(request);
    }
}
export default ClassNode;

