//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { List, Message, Segment, Loader } from 'semantic-ui-react';
import { Link } from "react-router-dom";
import LocalizedStrings from "../../localization/TimeStrings";
import { timeSince } from '../../util/time';
import { getProjectName } from '../../util/defines';

/*const getChangeCount = (obj) => {
  if(!obj) return 0
  if(obj.Apply !== undefined) {
    if(obj.Apply) return 1
    else          return 0
  }
  if(typeof obj === 'string') return 0
    
  let sum = 0
  Object.entries(obj).forEach(([_, val]) => {
    sum += getChangeCount(val)
  })
  return sum
}*/

class Notification extends Component 
{
  render() 
  {
    if(this.props.loading) {
      return <List.Item >
        <List.Icon name='exchange' size='large' verticalAlign='middle' />
        <List.Content>
          <Segment basic>
            <Loader active/>
          </Segment>
        </List.Content>
      </List.Item>
    }
    let lastMod    = this.props.file
    //let changeList = lastMod.diffs;
    let date       = new Date(lastMod.date);
    let time       = timeSince(date);
    let project    = lastMod.project
    let projectName = getProjectName(project)

    return (
      <List.Item >
        <List.Icon name='exchange' size='large' verticalAlign='middle' />
        <List.Content>
          <List.Header>
            <i>
              <Link to={`/${lastMod.modifiedBy.username}`}>
              {lastMod.modifiedBy.name}
              </Link>
            </i>
            {LocalizedStrings.HasChanged}
            <i>
              <Link to={`/${project.owner.username}/${project.projectname}`}>
                {project.owner.name + "/" + projectName}
              </Link>
            </i>
          </List.Header>
          <List.Description as={Link} to={`/${project.owner.username}/${project.projectname}/${lastMod._id}`}>
            <Message>
              <p>{LocalizedStrings.formatString(LocalizedStrings.Changed, time, date.toLocaleTimeString())}</p>
              {/*<p>{ getChangeCount(changeList) + ' changes' }</p>*/}
            </Message>
          </List.Description>
        </List.Content>
      </List.Item>
    );
  }
}

export default Notification;

