//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2019 www.deersoft.de
//-----------------------------------------------------------------------------
import LocalizedStrings from 'localized-strings';
import LocalizeOption from './LocalizationOption';
 
import en from "./raw/en/Tour.json"
import de from "./raw/de/Tour.json"
import es from "./raw/es/Tour.json"
import fr from "./raw/fr/Tour.json"
import it from "./raw/it/Tour.json"
import ru from "./raw/ru/Tour.json"
import cz from "./raw/cs/Tour.json"
 
let Strings = new LocalizedStrings(
  {
    en: en,
    de: de,
    es: es,
fr: fr,
ru: ru,
it: it,
cz: cz,
  },
    { ...LocalizeOption }
  );
export default  Strings;