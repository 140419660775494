//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from "react";
import * as THREE from "three";
import { WEBGL } from "../../Renderer/js/WebGl";
import ReactResizeDetector from "react-resize-detector";
import { Sidebar } from "semantic-ui-react";
import { SetRendererView } from "../../Renderer/js/defaultfunctions";
import { RENDERER_VIEW_BACK, RENDERER_VIEW_BOTTOM, RENDERER_VIEW_FRONT, RENDERER_VIEW_FRONT_LEFT, RENDERER_VIEW_FRONT_RIGHT, RENDERER_VIEW_LEFT, RENDERER_VIEW_REAR_LEFT, RENDERER_VIEW_REAR_RIGHT, RENDERER_VIEW_RIGHT, RENDERER_VIEW_TOP } from "../../../util/defines";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

class TruckRenderer extends Component
{
    constructor(props)
    {
        super(props)

        this.scene          = undefined;
        this.camera         = undefined;
        this.renderer       = undefined;
        this.frameId        = undefined;
        this.orbitControls  = undefined;
        this.truckObject    = undefined;
        this.threeContainer = undefined;

        this.firstMouseDown = true;
        this.canvasRect = new DOMRect(0, 0, 0, 0);
    }

    // Init webgl and basic data
    componentDidMount()
    {
        if (WEBGL.isWebGLAvailable())
        {
            // Init basic scene and camera for rendering
            this.scene      = new THREE.Scene()
            this.camera     = new THREE.PerspectiveCamera(50, this.mount.clientWidth / this.mount.clientHeight, 100, 1000000);
            this.camera.matrixAutoUpdate = true

            if (WEBGL.isWebGLAvailable())
            {
                let canvas = document.createElement('canvas');
                let context = canvas.getContext('webgl2');
                this.renderer = new THREE.WebGLRenderer({canvas: canvas, context:context});
            }
            else
            {
                this.renderer = new THREE.WebGLRenderer();
            }

            this.initScene(this.mount.clientWidth, this.mount.clientHeight);

            this.initControls();

            this.mount.appendChild(this.renderer.domElement)

            if (!this.frameId)
            {
                this.frameId = requestAnimationFrame(this.animate)
            }

            this.createTruckObject();



            this.threeContainer = new THREE.Group();
            this.scene.add(this.threeContainer);
        }
        this.forceUpdate();
        window.addEventListener("keydown", this.windowKeyDown)
    }


    componentDidUpdate(prevProps)
    {
        this.truckObject.visible = !!this.props.truckUuid

        while (this.threeContainer.children.length > 0)
        {   
            this.threeContainer.remove(this.threeContainer.children[0])
        }

        if (this.props.truckUuid)
        {
            console.log("Getting inv container of truck uuid: ", this.props.truckUuid);
            window.LR_GetTruck({UUID: this.props.truckUuid}).then(result => {
                this.truckObject.scale.set(result.TruckSizeX, result.TruckSizeY, result.TruckSizeZ)
                this.truckObject.position.set(result.TruckSizeX / 2, result.TruckSizeY / 2, result.TruckSizeZ / 2)
            })
            
            window.LR_GetTruckObjects({UUID: this.props.truckUuid, Async: true}).then(result => {
                // Clear the inventory objects from threejs
                

                result.Objects.forEach(invObj => {
                    let objectMesh = this.createInventoryObject()
                    
                    let caseSize = {X: invObj.CaseSizeX, Y: invObj.CaseSizeY, Z: invObj.CaseSizeZ}
                    if (invObj.CaseSizeX === 0 && invObj.CaseSizeY === 0 && invObj.CaseSizeZ === 0)
                    {
                        caseSize = {X: invObj.BoundingMax.X - invObj.BoundingMin.X, Y: invObj.BoundingMax.Y - invObj.BoundingMin.Y, Z: invObj.BoundingMax.Z - invObj.BoundingMin.Z}
                    }
                    
                    objectMesh.position.set(invObj.InventoryOffsetX + caseSize.X / 2, invObj.InventoryOffsetY + caseSize.Y / 2, invObj.InventoryOffsetZ + caseSize.Z / 2)
                    objectMesh.rotation.set(invObj.InventoryRotationX, invObj.InventoryRotationY, invObj.InventoryRotationZ)
                    objectMesh.scale.set(caseSize.X, caseSize.Y, caseSize.Z)

                    if (invObj.HasWheels)
                    {
                        let wheelGroup = this.createWheelMeshes(caseSize.X, caseSize.Y)
                        
                        objectMesh.position.set(invObj.InventoryOffsetX, invObj.InventoryOffsetY, invObj.InventoryOffsetZ + 60)
                        this.threeContainer.add(wheelGroup)
                    }

                    this.threeContainer.add( objectMesh );
                })
            })
        }
    }

    render()
    {
        return (<Sidebar.Pushable>
        <div  className="Renderer" 
              style={{ width: "100%", height: "100%" }} 
              ref={mount => { this.mount = mount; }}
              onMouseDown={this.onRendererDown}
              onMouseUp={this.onRendererUp}/>
        <ReactResizeDetector handleWidth onResize={this.onResize}/>
        </Sidebar.Pushable>)
    }

    initControls = () =>
    {
        this.orbitControls = new OrbitControls(this.camera, this.renderer.domElement, this.scene);
        this.orbitControls.rotateSpeed = 0.2;
        this.orbitControls.panSpeed = 0.2;
    }

    onResize = () =>
    {
        this.camera.aspect = this.mount.clientWidth / (this.mount.clientHeight);
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.mount.clientWidth, this.mount.clientHeight);
    }

    initScene = (width, height) =>
    {

        this.scene.background = new THREE.Color().setHex(0xAAAAAA);

        //--------------------------------------------------------------------------------
        // Light
        let dirLight = new THREE.DirectionalLight(0xffffff, 1);
        dirLight.color.setHSL(0.1, 1, 0.95);
        dirLight.position.set(-1000, 1750, 1000);
        dirLight.position.multiplyScalar(30);
        dirLight.matrixAutoUpdate = true;
        this.scene.add(dirLight);

        let dirLight2 = new THREE.DirectionalLight(0xffffff, 1);
        dirLight2.color.setHSL(0.1, 1, 0.95);
        dirLight2.position.set(2000, -750, -1000);
        dirLight2.position.multiplyScalar(30);
        dirLight2.matrixAutoUpdate = true;
        this.scene.add(dirLight2);




        let camPos = new THREE.Vector3(4500, -2000, 3000)

        this.camera.name = 'Camera';
        this.camera.position.set(camPos.x * 0.7, camPos.y * 0.7, camPos.z * 0.7);
        this.camera.up.set(0, 0, 1);
        this.camera.lookAt(this.scene.position);

        this.camera.aspect = width / (height);
        this.camera.updateProjectionMatrix();


        //--------------------------------------------------------------------------------
        // Renderer

        // this.renderer.setClearColor(0xffffff, 0);
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.gammaInput = true;
        this.renderer.gammaOutput = true;
        this.renderer.antialias = true;
        this.renderer.setSize(width, height);
    
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    }

    createTruckObject()
    {
        //Add temp geo to test if rendering works
        var boxGeo = new THREE.BoxGeometry(1, 1, 1);

        var material = new THREE.MeshPhongMaterial( {color: 0xcacaca} );
        material.side = THREE.BackSide
        this.truckObject = new THREE.Mesh( boxGeo, material );
        this.truckObject.visible = !!this.props.truckUuid
        this.truckObject.matrixAutoUpdate = true;
        this.scene.add( this.truckObject );
    }

    createInventoryObject()
    {
        //Add temp geo to test if rendering works
        let geometry = new THREE.BoxGeometry(1, 1, 1);

        var material = new THREE.MeshPhongMaterial( {color: Math.random() * 0xffffff} );
        material.side = THREE.DoubleSide
        let result = new THREE.Mesh( geometry, material );
        result.matrixAutoUpdate = true;
        return result
    }

    createWheelMeshes(spacingX, spacingY)
    {
        let wheelGroup = new THREE.Group()

        const geometry = new THREE.CylinderGeometry( 30, 30, 10, 32 );
        const material = new THREE.MeshBasicMaterial( {color: 0xffff00} );


        let wheel1 = new THREE.Mesh( geometry, material );
        wheel1.position.set(30, 0, 0)
        let wheel2 = new THREE.Mesh( geometry, material );
        wheel2.position.set(spacingX - 30, 0, 0)
        let wheel3 = new THREE.Mesh( geometry, material );
        wheel3.position.set(30, spacingY, 0);
        let wheel4 = new THREE.Mesh( geometry, material );
        wheel4.position.set(spacingX - 30, spacingY, 0)

        wheelGroup.add(wheel1)
        wheelGroup.add(wheel2)
        wheelGroup.add(wheel3)
        wheelGroup.add(wheel4)
        
        wheelGroup.position.set(0, 0, 30)

        return wheelGroup
    }

    componentWillUnmount()
    {
        if (WEBGL.isWebGLAvailable())
        {
            cancelAnimationFrame(this.frameId)
            if (this.renderer)
            {
                this.mount.removeChild(this.renderer.domElement);
            }
        }
        window.removeEventListener("keydown", this.windowKeyDown)
    }

    //------------------------------------------------------------------------------------------------------------
    // Updates Scene
    // This will be called very often, 
    animate = () => 
    {
        this.frameId = window.requestAnimationFrame(this.animate);
        
        this.orbitControls.update();
        this.renderer.render(this.scene, this.camera);
    };

    onRendererDown = (e) =>
    {
        this.canvasRect = this.renderer.domElement.getBoundingClientRect()

        if (this.firstMouseDown)
        {
            this.firstMouseDown = false
            if (e.altKey)
            {
                this.orbitControls.target = this.getGroundIntersection(e)
            }
        }
    }

    onRendererUp = (e) =>
    {
        this.firstMouseDown = true
    }

    getGroundIntersection = (pointer) =>
    {
        let x = (pointer.clientX - this.canvasRect.left) / this.canvasRect.width
        let y = (pointer.clientY - this.canvasRect.top) / this.canvasRect.height
        let mouse = new THREE.Vector2((x * 2) - 1, -(y * 2) + 1)

        let plane = new THREE.Plane(new THREE.Vector3(0,0,1), 0)

        let raycast = new THREE.Raycaster()
        raycast.setFromCamera(mouse, this.camera)
        let intersection = new THREE.Vector3()
        raycast.ray.intersectPlane(plane, intersection)
        return intersection
    }

    windowKeyDown = (e) =>
    {
        if(e.code === "Numpad0" /* 'Numpad 0' key */ && this.HandleAsInput(e)) { SetRendererView(RENDERER_VIEW_BOTTOM, this.scene, this.camera, this.orbitControls); }
        if(e.code === "Numpad1" /* 'Numpad 1' key */ && this.HandleAsInput(e)) { SetRendererView(RENDERER_VIEW_FRONT_LEFT, this.scene, this.camera, this.orbitControls); }
        if(e.code === "Numpad2" /* 'Numpad 2' key */ && this.HandleAsInput(e)) { SetRendererView(RENDERER_VIEW_FRONT, this.scene, this.camera, this.orbitControls); }
        if(e.code === "Numpad3" /* 'Numpad 3' key */ && this.HandleAsInput(e)) { SetRendererView(RENDERER_VIEW_FRONT_RIGHT, this.scene, this.camera, this.orbitControls); }
        if(e.code === "Numpad4" /* 'Numpad 4' key */ && this.HandleAsInput(e)) { SetRendererView(RENDERER_VIEW_LEFT, this.scene, this.camera, this.orbitControls); }
        if(e.code === "Numpad5" /* 'Numpad 5' key */ && this.HandleAsInput(e)) { SetRendererView(RENDERER_VIEW_TOP, this.scene, this.camera, this.orbitControls); }
        if(e.code === "Numpad6" /* 'Numpad 6' key */ && this.HandleAsInput(e)) { SetRendererView(RENDERER_VIEW_RIGHT, this.scene, this.camera, this.orbitControls); }
        if(e.code === "Numpad7" /* 'Numpad 7' key */ && this.HandleAsInput(e)) { SetRendererView(RENDERER_VIEW_REAR_LEFT, this.scene, this.camera, this.orbitControls); }
        if(e.code === "Numpad8" /* 'Numpad 8' key */ && this.HandleAsInput(e)) { SetRendererView(RENDERER_VIEW_BACK, this.scene, this.camera, this.orbitControls); }
        if(e.code === "Numpad9" /* 'Numpad 9' key */ && this.HandleAsInput(e)) { SetRendererView(RENDERER_VIEW_REAR_RIGHT, this.scene, this.camera, this.orbitControls); }
    }

    HandleAsInput = (event) =>
    {
        if(event.target.tagName.toUpperCase() === 'INPUT') { return false }
        if(event.target.tagName.toUpperCase() === 'TEXTAREA') { return false }
        return true
    }
}

export default TruckRenderer;