
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React from 'react';
import { Menu, Label, Icon, Input } from 'semantic-ui-react';
import {    globalCallbacks }from '../../util/callback';
import {    RESOURCE_TYPE_FixtureType,
            RESOURCE_TYPE_Electrical,
            RESOURCE_TYPE_AssemblyGroup,
            RESOURCE_TYPE_Mesh,
            RESOURCE_TYPE_CalculationResult,
            RESOURCE_TYPE_Magnet,
            RESOURCE_TYPE_SymbolDef,
            RESOURCE_TYPE_Structure,
            RESOURCE_TYPE_Inventory,
            RESOURCE_TYPE_Support,
            RESOURCE_TYPE_TrussCross,
            RESOURCE_TYPE_Audio,
            RESOURCE_TYPE_Dimension,
            RESOURCE_TYPE_Origin, 
            RESOURCE_TYPE_TextObject,
            RESOURCE_TYPE_Dock,
            RESOURCE_TYPE_Connection,
            RESOURCE_TYPE_PrintLabelField,
            RESOURCE_TYPE_Polygon,
            RESOURCE_TYPE_CablePath,
            RESOURCE_TYPE_Load,
            IsDarkTheme,
            BASE_UNIT_FORCE,
            BASE_UNIT_PERCENT} from '../../util/defines';
import UnitInput from '../Basics/BasicUnitInput';

const icons_atlas = {
    [RESOURCE_TYPE_AssemblyGroup]: "wrench",
    [RESOURCE_TYPE_FixtureType]: "lightbulb",
    [RESOURCE_TYPE_Electrical]: "plug",
    [RESOURCE_TYPE_Mesh]: "cubes",
    [RESOURCE_TYPE_Magnet]: "magnet",
    [RESOURCE_TYPE_SymbolDef]: "boxes",
    [RESOURCE_TYPE_Inventory]: "cubes",
    [RESOURCE_TYPE_Structure]: "yen",
    [RESOURCE_TYPE_Support]: "upload",
    [RESOURCE_TYPE_Audio]: "file audio",
    [RESOURCE_TYPE_Dimension]: "tag",
    [RESOURCE_TYPE_Origin]: "map pin",
    [RESOURCE_TYPE_CalculationResult]: "calculator",
    [RESOURCE_TYPE_Connection]: "code",
    [RESOURCE_TYPE_PrintLabelField]: "print",
    [RESOURCE_TYPE_Polygon]: "pencil alternate",
    [RESOURCE_TYPE_CablePath]: "plug",
    [RESOURCE_TYPE_Load]: "weight",
    [RESOURCE_TYPE_TrussCross]: "arrows alternate vertical",
    [RESOURCE_TYPE_TextObject]: "font",
    [RESOURCE_TYPE_Dock]: "venus mars",
}

class TreeNode extends React.PureComponent 
{   
    constructor(props) {
        super(props)
        this.state = {
            dragOver: null
        }

        this.lastClickTime = Date.now()
    }

    getBorderStyle = () => {
        let theme = IsDarkTheme()
        let general = {
            borderWidth: '2px',
            borderColor: '#fff',
            display: 'inline-block',
            borderStyle: 'solid'
        }
        if(this.state.dragOver === 'top')
        return {
            ...general,
            borderTopColor:  'red'
        }
        if(this.state.dragOver === 'mid')
        return {
            ...general,
            borderColor: 'red'
        }
        if(this.state.dragOver === 'bot')
        return {
            ...general,
            borderBottomColor:  'red'
        }
        return {
            ...general,
            borderColor: theme ? "#222" :  'white',
        }
    }

    getColor()
    {
        if(this.state.dragOver)
        {
            return "green";
        }
        if(this.props.useCalculationEntry && this.props.calculationEntry)
        {
            return this.props.selected ? "red" : "pink" 
        }
        else
        {
            return this.props.selected ? "blue" : "grey" 
        }
    }
    
    componentDidUpdate(prevProp, prevState){
        if(!this.props.selected && this.state.edit){
            this.setState({edit: false})
        }
    }

    render() 
    {
        const {name, selected, expanded, children, UUID, resourceType, fixtureId, isElectrical, duplicatedFid, hasFid, existing, duplicatedOid} = this.props;
        let color = ""
        if(hasFid &&duplicatedFid.includes(fixtureId) )
        {
            color = "red"
        }
        if(!hasFid &&duplicatedOid?.includes(fixtureId) )
        {
            color = "red"
        }

        return (
            <div style={{ height: "100%"}}>
                <Menu.Item  style={{whiteSpace: "nowrap"}}>
                    <Icon 
                        name='dropdown' 
                        link
                        onClick={this.setExpanded} 
                        rotated= {expanded ? undefined : "counterclockwise"}
                        style={{opacity: children.length ? 1 : 0}}
                    />
                    <div style = {this.getBorderStyle()}>
                        <Label  as              = 'a'
                                data-lrhandle   = {UUID}
                                color           = {this.getColor()} 
                                onClick         = {(e)=>this.setSelected(e, false)} 
                                draggable
                                onDragStart     = {e => {
                                    const data = {
                                        UUID: UUID,
                                    }
                                    e.dataTransfer.setData('text/plain', JSON.stringify(data)); 
                                    this.props.onDragStart(UUID)
                                }}
                                onDragOver      = {this.onDragOver.bind(this)} //necessary to fire onDrop
                                onDrop          = {(e) => {this.onDrop(e, UUID); e.stopPropagation();}}
                                onDragEnd       = {this.props.onDragEnd}
                                onDragLeave     = {this.onDragLeave.bind(this)}
                                style={{ height: "100%"}}>
                                {existing === false ? <Icon name='delete'/> : null}
                                <b style={{color: color}}>{fixtureId} </b>
                                {isElectrical ? <>{" "}<Icon name="plug" color={this.props.HasCableSelected ? "green": "red"}/></> : null}
                                <Icon onClick         = {(e)=>this.setSelected(e, true)}  name={icons_atlas[resourceType]} color = {!isElectrical ? undefined : this.getElectricalColor()}/>
                                {selected && this.state.edit ? this.renderInput() : name}
                        </Label>
                        {
                            this.props.LoadConnectedCalculated !== 0 && this.props.LoadConnectedCalculated !== undefined ? this.renderLoadCell(): null
                        }

                    </div>
                </Menu.Item>                
            </div>
        )
    }

    renderLoadCell()
    {
        let percentage = 100
        let show = false
        if(Math.abs(this.props.LoadConnectedMeasured) > 0.1)
        {
            show = true;
            percentage = 100 + this.props.LoadConnectedCalculated / this.props.LoadConnectedMeasured * 100
        }

        return <Label>
        {show ? <Icon name="circle" color={Math.abs(percentage) > 5 ? "red" : "green"}></Icon> : null}
        <UnitInput readOnly showUnit value={Math.abs(this.props.LoadConnectedCalculated)}             baseUnit      = {BASE_UNIT_FORCE} />
        {show ? <>{"/"}
        <UnitInput readOnly showUnit value={this.props.LoadConnectedMeasured}             baseUnit      = {BASE_UNIT_FORCE} />
        {"/Δ"}
        <UnitInput readOnly showUnit value={this.props.LoadConnectedMeasured + this.props.LoadConnectedCalculated}             baseUnit      = {BASE_UNIT_FORCE} />
        {"/"}
        <UnitInput readOnly showUnit value={percentage}             baseUnit      = {BASE_UNIT_PERCENT} />
        </>:null}

    </Label>
    }



    renderInput()
    {
        let HandleDoubleClick = (e)=>
        {
            e.stopPropagation()
        }
        return <Input   transparent
                        onClick={HandleDoubleClick} 
                        onDoubleClick={HandleDoubleClick} 
                        value={this.state.editName} 
                        onBlur={()=>{this.setState({edit: false}); window.LR_SetObject({UUID: this.props.UUID, Name: this.state.editName})}} 
                        onChange={(_,{value})=>{this.setState({editName: value})}}  
                        onKeyDown={(e) => {if (e.keyCode === 13) {
                            e.target.blur()
                        }}}/>
    }

    onDragOver = (e) => {
        e.preventDefault()
        let clientHeight = e.target.clientHeight;
        let offsetY = e.nativeEvent.offsetY;


        if (offsetY < (1.0/5.0) * clientHeight) // Drop on the top part of the node
        {
            this.setState({
                dragOver: 'top'
            })
        }
        else if (offsetY > (4 * clientHeight) / 5.0) // Drop on the lower part of the node
        {
            this.setState({
                dragOver: 'bot'
            })
        }
        else    // Drop in the center
        {
            this.setState({
                dragOver: 'mid'
            })
        }
    }

    onDragLeave= () => {
        this.setState({
            dragOver: null
        })
    }

    onDrop = (e, UUID) =>
    {
        this.onDragLeave()
        if(UUID !== this.props.dragged)
        {
            let clientHeight = e.target.clientHeight;
            let offsetY = e.nativeEvent.offsetY;
            let globalCoordinates = !e.altKey

            if (offsetY < (1.0/5.0) * clientHeight) // Drop on the top part of the node
            {
                let request = { 
                    PrevUUID: this.props.dragged,
                    NextUUID: UUID,
                    GlobalCoordinates: globalCoordinates
                }
                window.LR_SetPrev(request);
            }
            else if (offsetY > (4 * clientHeight) / 5.0) // Drop on the lower part of the node
            {
                let request = {
                    PrevUUID: UUID,
                    NextUUID: this.props.dragged,
                    GlobalCoordinates: globalCoordinates
                }
                window.LR_SetNext(request);
            }
            else    // Drop in the center
            {
                let request = 
                {
                    ParentUUID      : UUID,
                    ChildUUID       : this.props.dragged,
                    GlobalCoordinates : globalCoordinates,
                }
                window.LR_SetChild(request);
            }
        }
        
    }

    getChildrenAsUUIDArray(child, array) {
        if (!array) {return;}
        array.push(child.UUID);
        child.children.forEach(val => {
            this.getChildrenAsUUIDArray(val, array);
        });
    }

    // -------------------------------------------------------------------------------------------------------
    // Addon interaction functions
    setExpanded = (e) =>
    {
        if (e.altKey) 
        {
            window.LR_CollapseChildren({UUID: this.props.UUID});
        }
        else
        {
            let request = 
            {
                UUID  :  this.props.UUID,
                Expanded: !this.props.expanded, 
            }
            window.LR_SetObject(request);        }
    }

    setSelected = (e, children) =>
    {
        let now             = Date.now();
        let duration        = now - this.lastClickTime;
        this.lastClickTime  = now;
        this.isDoubleClick  = duration < 300;

        if (!this.isDoubleClick) {
            e.persist();
            this.props.onSelectObject(this.props.UUID)

            let childrenHandleArray = [this.props.UUID];
            if (children && this.props.children?.length) {
                childrenHandleArray = [...this.props.children.map(e => e.UUID)]
            }

            if (globalCallbacks.setSelected) { globalCallbacks.setSelected(this.props.UUID, !this.props.selected, childrenHandleArray, e.shiftKey, (navigator.platform === "MacIntel" ? e.metaKey : e.ctrlKey) || window.IsIOS, e.altKey) }
            e.stopPropagation()
            e.preventDefault()
        } else {
            this.setState({edit: true, editName: this.props.name})

            e.stopPropagation()
            e.preventDefault()
        }
    }

    getElectricalColor = () =>
    {
        switch(this.props.isFullyConnected)
        {
            case 1:     return "red"
            case 2:     return "green"
            case 3:     return "yellow"
            default:    return "white"
        }
    }

}

export default TreeNode