//-----------------------------------------------------------------------------
//----- Copyright deersoft 2022 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { globalWindowInterface } from '../util/callbackTypes';
import { IsUsingWebassembly } from './ipcRenderer';

declare const window: globalWindowInterface

//This is only for compatibility with WASM


class UploadModal extends Component {
    fileRef = React.createRef<HTMLInputElement>()
    promiseCB: (file: File) => void

    constructor(props) {
        super(props);
        this.state =
        {
            open: false,
            message: ""
        }
    }

    componentDidMount = () => {
        this.setUpCallbacks();
    }

    render() {
        return (
            <input type="file" ref={this.fileRef} style={{ display: "none" }} onChange={e => {
                e.stopPropagation()
                e.preventDefault()
                if(e.target.files[0]){
                    this.promiseCB(e.target.files[0])
                }
            }}/>
        )
    }

    setUpCallbacks() {
        if (IsUsingWebassembly) {
            window.WEB_CORE.onOpenFileCB = async (typeData: string) : Promise<File> => {
                this.fileRef.current.accept = typeData;
                this.fileRef.current.click()
                return await new Promise(cb => this.promiseCB = cb)
            }
        }
    }
}

export default UploadModal