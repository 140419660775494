//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------

import escapeRegExp from "lodash/escapeRegExp"
import filter from "lodash/filter"
import React, { Component } from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Link } from "react-router-dom";
import { Button, Divider, Form, Header, Icon, Label, Menu, Popup, Search, Segment, Table } from 'semantic-ui-react';
import LocalizedStrings from '../../localization/StartPageProject';
import { FetchProject, hasFetched } from '../../redux/actions/fetch_actions';
import { ACTIVE_PROJECT, ACTIVE_USER } from "../../redux/redux_defines";
import { RESPONSIVE_PHONE } from '../../util/defines';
import { withRouter } from '../../webApp/WebRouter';


class BranchSelection extends Component 
{
  constructor(props)
  {
      super(props);
      
      this.state = 
      {
        loadingBranches: true,
        Branches: [],

         // search
         isLoading: false,
         results  : [], 
         value    : "",
      } 
  }

  prepareBranchesOptions = (branches) =>
  {
    let branchesOption = branches.map(b => 
      {
        return{
          key: b.name,
          text: b.name,
          value: b.name,
          label: { color: 'red', empty: true, circular: true },
        }
      })
      return branchesOption
  }


  render() 
  {
    FetchProject()
    let isLoading = hasFetched(this.props.activeProject)

    let branches = []
    if(isLoading)
    {
      if(this.props.activeProject.data && this.props.activeProject.data.defaultBranch)
      {
        branches.push({
          name: this.props.activeProject.data.defaultBranch.name, 
          _id: this.props.activeProject.data.defaultBranch._id
        })
      }
      branches = [
        ...branches, 
        ...this.props.activeProject.data.branches
      ]
    }


    let showData = !this.state.value ? branches : this.state.isLoading ? branches : this.state.results
    showData = showData.filter(data => !data.closed)

    let activeworksheet = this.props.branchview === "worksheet"
    let activelightplot = this.props.branchview === "lightplot"
    let activetimeline  = this.props.branchview === "timeline"
    let activeelectric  = this.props.branchview === "electric"
    let activedata      = this.props.branchview === "data"
    let activetasks     = this.props.branchview === "tasks"
    let activecommits   = this.props.branchview === "commits"
    let activechecks    = this.props.branchview === "checks"
    let activesybolmap    = this.props.branchview === "symbolmap"
    let activefieldmap    = this.props.branchview === "fieldmap"
    let signingjob    = this.props.branchview === "signingjob"
    let currBranch = branches.find(entry => entry._id === this.props.match.params.branch)

    let currentBranchName = currBranch ? currBranch.name : "master"

    let restrictedAccess = this.dataRestriction()
    return (
      <>
        <MediaQuery minWidth={RESPONSIVE_PHONE}> 
        <Menu secondary style={{overflow: "auto"}}>  
          <Popup
            basic
            onClose  = {() => {this.setState({branchMenuOpen: false, createBranchName: undefined})}}
            on       = 'click'
            trigger  = {<Label style={{paddingTop: "15px"}} >
                          <Icon name='code branch' />{currentBranchName}<Icon name='caret down' />
                        </Label>}
            offset   = {[0, -10]}
            position = 'bottom right'
            style    = {{padding: "0px"}}>  
            <Segment>
              <Header as="h4" >
                {LocalizedStrings.Branches}
              </Header>
            <Divider />
            <Form>
              <Search   open    = {false}
                        loading = {this.state.isLoading}
                        value   = {this.state.value}
                        placeholder = {LocalizedStrings.Search}
                        onSearchChange = {(e, {value}) => this.onSearchChange(value.replace(/[^a-zA-Z0-9]/g,'-'), branches)}/>
            </Form>
            <Divider />       
            {this.renderBranchList(showData, isLoading, currBranch)}
            </Segment>
          </Popup> 
          <Menu.Item active={activeworksheet} as={Link} to={`/${this.props.username}/${this.props.projectname}/worksheet/${this.props.match.params.branch ?? ""}`}>{LocalizedStrings.Worksheets}</Menu.Item>
          <Menu.Item active={activelightplot} as={Link} to={`/${this.props.username}/${this.props.projectname}/lightplot/${this.props.match.params.branch ?? ""}`}>{LocalizedStrings.LightPlot}</Menu.Item>
          <Menu.Item active={activetimeline}  as={Link} to={`/${this.props.username}/${this.props.projectname}/timeline/${this.props.match.params.branch ?? ""}`}>{LocalizedStrings.Timeline}</Menu.Item>
          <Menu.Item active={activeelectric}  as={Link} to={`/${this.props.username}/${this.props.projectname}/electric/${this.props.match.params.branch?? ""}`}>{LocalizedStrings.ElectricalNetwork}</Menu.Item>
          <Menu.Item active={activedata}      as={Link} to={`/${this.props.username}/${this.props.projectname}/data/${this.props.match.params.branch ?? ""}`}>{LocalizedStrings.Data}</Menu.Item>
          <Menu.Item active={signingjob}     as={Link} disabled={restrictedAccess} to={`/${this.props.username}/${this.props.projectname}/signingjobs/${this.props.match.params.branch?? ""}`}>{LocalizedStrings.RequestedCalculationChecks}</Menu.Item>
          <Menu.Item active={activetasks}     as={Link} disabled={restrictedAccess} to={`/${this.props.username}/${this.props.projectname}/tasks/${this.props.match.params.branch?? ""}`}>{LocalizedStrings.Tasks}</Menu.Item>
          <Menu.Item active={activecommits}   as={Link} disabled={restrictedAccess} to={`/${this.props.username}/${this.props.projectname}/commits/${this.props.match.params.branch?? ""}`}>{LocalizedStrings.Commits}</Menu.Item>
          <Menu.Item active={activechecks}    as={Link} disabled={restrictedAccess} to={`/${this.props.username}/${this.props.projectname}/checks/${this.props.match.params.branch?? ""}`}>{LocalizedStrings.ChangeListChecks}</Menu.Item>
          <Menu.Item active={activesybolmap}  as={Link} disabled={restrictedAccess} to={`/${this.props.username}/${this.props.projectname}/symbolmap/${this.props.match.params.branch?? ""}`}>{LocalizedStrings.SymbolMap}</Menu.Item>
          <Menu.Item active={activefieldmap}  as={Link} disabled={restrictedAccess} to={`/${this.props.username}/${this.props.projectname}/fieldmap/${this.props.match.params.branch?? ""}`}>{LocalizedStrings.FieldMap}</Menu.Item>
          <Menu.Menu position="right">

          <Menu.Item ><Button disabled={restrictedAccess} onClick={()=>{window.open("productionassist://openproject?user="+this.props.username+"&project="+this.props.projectname+"&branch=" + currentBranchName, '_blank');}} color='blue'>{LocalizedStrings.CheckoutInApp}</Button></Menu.Item>
          <Menu.Item ><Button disabled={restrictedAccess} fluid onClick={()=>{window.LR_ShowShareDialog()}} color='blue'>{LocalizedStrings.Share}</Button></Menu.Item>
          <Menu.Item ><Button disabled={restrictedAccess} fluid as={Link} to={`/${this.props.username}/${this.props.projectname}/editview/${this.props.match.params.branch?? ""}`} color='blue'>{LocalizedStrings.EditInWeb}</Button></Menu.Item>

          </Menu.Menu>
          </Menu>
          </MediaQuery>
        {this.props.children ? React.cloneElement(this.props.children, { projectname: this.props.projectname, username: this.props.username, WebApp:true, fileId: this.props.fileId }) : null}
        
      </>

    );
  }

  // list existing branches for switching
  renderBranchList = (showData, isLoading, currBranch) => 
  {
    return (<>
              {showData.length > 0 || !isLoading ? 
                  <Table divided relaxed textAlign="center" compact striped>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell collapsing>Active</Table.HeaderCell>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                  { showData.map((option, i) => 
                  {
                    let activeBranch = this.props.match.params.branch === option._id ||  (!currBranch && option._id === this.props.activeProject.data.defaultBranch._id)
                    return( <Table.Row key={i} verticalAlign='left'>
                              <Table.Cell>
                                {activeBranch ? <Icon name="check"/> : null}
                              </Table.Cell>
                              <Table.Cell>
                                <Link to={`/${this.props.username}/${this.props.projectname}/worksheet/${option._id}`}>
                                  {option.name}
                                </Link>
                              </Table.Cell>
                            </Table.Row>
                            )
                  })}  
                </Table.Body>     
                </Table> : <>{<Button fluid size="mini" positive onClick={this.createBranch}>{LocalizedStrings.CreateBranch}</Button>}</>}
            </>)
  }

  onSearchChange = (value, resources) => 
    {
      this.setState({isLoading: true, value: value})

        if(value.length < 1)
        {
          this.onSearchReset()
        }
        else
        {
          const re = new RegExp(escapeRegExp(value), 'i')
          const isMatch = (result) => {   let res = re.test(result.name); 
                                          return res; }
        
          this.setState({results: filter(resources, isMatch), isLoading: false})
        }
    }

    onSearchReset = () => {
      this.setState({isLoading: false,
                     results  : [], 
                     value    : "",})
    }

  createBranch = async () =>
  {
    await window.LR_CreateBranchAsync({Name: this.state.value ?? "NewBranch"})
    FetchProject(true)
    this.onSearchReset()
  }

  dataRestriction = () =>
  {
    let restrictedAccess  = false;
    let shareLinkToken    = sessionStorage.getItem("last_sharetoken")
    
    // If user is not logged in and has share token, user is restricted to access all data
    if(!this.props.loggedInUser.data && shareLinkToken)
    {
      restrictedAccess = true
    }

    if(this.props.loggedInUser.data)
    {
      if(this.props.loggedInUser.data.username === this.props.activeProject.data.owner.username)
      {
        return false
      }
      // Check if it is a member of the project
      let membersOfProject = this.props.activeProject.data.members;
      let groupsOfProject  = this.props.activeProject.data.groups;
      let thisMemberOfProject = membersOfProject.find(member => member.user.username === this.props.loggedInUser.data.username)

      if(!Array.isArray(groupsOfProject))
      {
        groupsOfProject = []
      }
      
      // Check if it is a member of a group of a project
      for(let i of groupsOfProject)
      {
        for(let member of i.group.members)
        {
          if(i.write && member.username === this.props.loggedInUser.data.username)
          {
            return false
          }
        }
      }
      
      if(!(thisMemberOfProject?.write))
      {
        restrictedAccess = true
      }
    }

    return restrictedAccess
  }
}

//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      loggedInUser: (state[ACTIVE_USER]),
      activeProject: state[ACTIVE_PROJECT]
    };
}

export default connect(mapStateToProps)(withRouter(BranchSelection))