//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Grid, Image, Icon } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/AboutDialog";
import logo from '../../content/img/applogo.png'
import LRModal from '../Basics/BasicModal';
import CONST from '../../webApp/CONST';
import { globalCallbacks } from "../../util/callback";


//-----------------------------------------------------------------------------
// The component for the TreeControl
class ConnectToRemote extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
      ip: ""
    }
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }

  show = async() => 
  {
    let info = await window.LR_GetVersionInfo()
    this.setState( { 
        open : true, 
        GitHash: info.GitHash,
        BuildNumber: info.BuildNumber,
        VersionNumber: info.VersionNumber,
        BuildType: info.BuildType,
      });
  }

  close = () => 
  {
    this.setState({open : false});
  }
    
  render() 
  {
    let open = this.state.open;
    if(!open) {return <div/>}

    return (
      <LRModal  open={open} 
                onCancelClick={this.close} 
                closeOnDimmerClick={false} 
                size="mini"
                scrolling={false}
                title={LocalizedStrings.Header}
                noCancel
                onOkClick={this.close}>
          <Grid centered columns={1} >
            <Grid.Column textAlign='center' >
              <Image src={logo} verticalAlign='middle' size="medium"/>
            </Grid.Column>
            <Grid.Row centered columns={2}>
              <Grid.Column textAlign='right'>
              {LocalizedStrings.BuildType}
              </Grid.Column >
              <Grid.Column textAlign='left'>
                {this.state.BuildType}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered columns={2}>
              <Grid.Column textAlign='right'>
              {LocalizedStrings.VersionNumber}
              </Grid.Column >
              <Grid.Column textAlign='left'>
                <Icon name="laptop"/>
                Core
                <p>{this.state.VersionNumber}</p>
                <Icon name="browser"/>
                UI
                <p>{CONST.VERSION}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered columns={2}>
              <Grid.Column textAlign='right'>
              {LocalizedStrings.GitHash}
              </Grid.Column >
              <Grid.Column textAlign='left'>
                <Icon name="laptop"/>
                Core
                <p>{this.state.GitHash}</p>
                <Icon name="browser"/>
                UI
                <p>{CONST.HASH}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered columns={2}>
              <Grid.Column textAlign='right'>
              {LocalizedStrings.BuildNumber}
              </Grid.Column>
              <Grid.Column textAlign='left'>
              <Icon name="laptop"/>
              Core
              <p>{this.state.BuildNumber}</p>
              <Icon name="browser"/>
              UI
              <p>{CONST.BUILD}</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
      </LRModal>
    )
  }

  setUpCallbacks()
  {

    globalCallbacks.ShowAboutDialog = () => 
    { 
      this.show();
    }
    
  }
}

export default ConnectToRemote