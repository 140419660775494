//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Checkbox, Form } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/BenchMarkDialog";
import { BASE_UNIT_FORCE, BASE_UNIT_LENGTH, BASE_UNIT_TORQUE } from '../../util/defines';
import LRModal from '../Basics/BasicModal';
import UnitInput from '../Basics/BasicUnitInput';
import { globalCallbacks } from "../../util/callback";


//-----------------------------------------------------------------------------
// The component for the TreeControl
class BenchmarkDialog extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
    }
  }

  componentDidMount = () => 
  {
    this.setUpCallbacks();
  }

  show = async(arg) => 
  {
    this.setState( { 
        open : true, 
        ...arg
      });
  }

  close = () => 
  {
    this.setState({open : false});
  }

  ok = () => 
  {
    window.LR_StoreResultsAsStructuralBenchmark({
      ...this.state
    })
    this.close()
  }
    
  render() 
  {
    let open = this.state.open;
    if(!open) {return <div/>}

    return (
      <LRModal  open={open} 
                onCancelClick={this.close} 
                closeOnDimmerClick={false} 
                size="big"
                scrolling={false}
                title={LocalizedStrings.Header}
                onOkClick={this.ok}>
            <Form>
              <Form.Group widths="equal">

            <Checkbox label={LocalizedStrings.StoreSupportResult} checked={this.state.StoreSupport} onChange={()=>{this.setState({StoreSupport: !this.state.StoreSupport})}}/>
            </Form.Group>

            <Form.Group widths="equal">
              <Checkbox checked={this.state.StoreDu} onChange={()=>{this.setState({StoreDu: !this.state.StoreDu})}}/>
              <UnitInput name="ThresholdDu" label={LocalizedStrings.ThresholdDu} baseUnit={BASE_UNIT_LENGTH} value={this.state.ThresholdDu} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>
            </Form.Group>
            <Form.Group widths="equal">
              <Checkbox checked={this.state.StoreDx} onChange={()=>{this.setState({StoreDx: !this.state.StoreDx})}}/>
              <UnitInput name="ThresholdDx" label={LocalizedStrings.ThresholdDx} baseUnit={BASE_UNIT_LENGTH} value={this.state.ThresholdDx} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>
            </Form.Group>
            <Form.Group widths="equal">
              <Checkbox checked={this.state.StoreDy} onChange={()=>{this.setState({StoreDy: !this.state.StoreDy})}}/>
              <UnitInput name="ThresholdDy" label={LocalizedStrings.ThresholdDy} baseUnit={BASE_UNIT_LENGTH} value={this.state.ThresholdDy} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>
            </Form.Group>
            <Form.Group widths="equal">
              <Checkbox checked={this.state.StoreDz} onChange={()=>{this.setState({StoreDz: !this.state.StoreDz})}}/>
              <UnitInput name="ThresholdDz" label={LocalizedStrings.ThresholdDz} baseUnit={BASE_UNIT_LENGTH} value={this.state.ThresholdDz} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>
            </Form.Group>
            <Form.Group widths="equal">
              <Checkbox checked={this.state.StoreDxLocal} onChange={()=>{this.setState({StoreDxLocal: !this.state.StoreDxLocal})}}/>
              <UnitInput name="ThresholdDxLocal" label={LocalizedStrings.ThresholdDxLocal} baseUnit={BASE_UNIT_LENGTH} value={this.state.ThresholdDxLocal} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>
            </Form.Group>
            <Form.Group widths="equal">
              <Checkbox checked={this.state.StoreDyLocal} onChange={()=>{this.setState({StoreDyLocal: !this.state.StoreDyLocal})}}/>
              <UnitInput name="ThresholdDyLocal" label={LocalizedStrings.ThresholdDyLocal} baseUnit={BASE_UNIT_LENGTH} value={this.state.ThresholdDyLocal} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>
            </Form.Group>
            <Form.Group widths="equal">
              <Checkbox checked={this.state.StoreDzLocal} onChange={()=>{this.setState({StoreDzLocal: !this.state.StoreDzLocal})}}/>
              <UnitInput name="ThresholdDzLocal" label={LocalizedStrings.ThresholdDzLocal} baseUnit={BASE_UNIT_LENGTH} value={this.state.ThresholdDzLocal} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>
            </Form.Group>
            <Form.Group widths="equal">
            <Checkbox checked={this.state.StoreNx} onChange={()=>{this.setState({StoreNx: !this.state.StoreNx})}}/>
            <UnitInput name="ThresholdNx" label={LocalizedStrings.ThresholdNx} baseUnit={BASE_UNIT_FORCE} value={this.state.ThresholdNx} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>
              </Form.Group>
              <Form.Group widths="equal">
            <Checkbox checked={this.state.StoreVy} onChange={()=>{this.setState({StoreVy: !this.state.StoreVy})}}/>
            <UnitInput name="ThresholdVy" label={LocalizedStrings.ThresholdVy} baseUnit={BASE_UNIT_FORCE} value={this.state.ThresholdVy} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>
              </Form.Group>
              <Form.Group widths="equal">
            <Checkbox checked={this.state.StoreVz} onChange={()=>{this.setState({StoreVz: !this.state.StoreVz})}}/>
            <UnitInput name="ThresholdVz" label={LocalizedStrings.ThresholdVz} baseUnit={BASE_UNIT_FORCE} value={this.state.ThresholdVz} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>
            </Form.Group>
              <Form.Group widths="equal">
            <Checkbox checked={this.state.StoreMt} onChange={()=>{this.setState({StoreMt: !this.state.StoreMt})}}/>
            <UnitInput name="ThresholdMt" label={LocalizedStrings.ThresholdMt} baseUnit={BASE_UNIT_TORQUE} value={this.state.ThresholdMt} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>
            </Form.Group>
              <Form.Group widths="equal">
            <Checkbox checked={this.state.StoreMby} onChange={()=>{this.setState({StoreMby: !this.state.StoreMby})}}/>
            <UnitInput name="ThresholdMby" label={LocalizedStrings.ThresholdMby} baseUnit={BASE_UNIT_TORQUE} value={this.state.ThresholdMby} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>
            </Form.Group>
              <Form.Group widths="equal">
            <Checkbox checked={this.state.StoreMbz} onChange={()=>{this.setState({StoreMbz: !this.state.StoreMbz})}}/>
            <UnitInput name="ThresholdMbz" label={LocalizedStrings.ThresholdMbz} baseUnit={BASE_UNIT_TORQUE} value={this.state.ThresholdMbz} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>
            </Form.Group>
            </Form>
      </LRModal>
    )
  }

  setUpCallbacks()
  {

    globalCallbacks.OpenBenchmarkDialog = (arg) => 
    { 
      this.show(arg);
    }
    
  }
}

export default BenchmarkDialog