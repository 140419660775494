import React, { Component } from "react";
import { Segment, Form, Message } from 'semantic-ui-react';
import LocalizedStrings from "../../localization/CreateNewBranch";
import LRModal from "../Basics/BasicModal";

import { globalCallbacks } from "../../util/callback";


class CreateNewBranchView extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    { 
      open : false,
      branchName:"",
      errorText: false,
    }
  }

  componentDidMount = () =>
  {
    this.setUpCallbacks();

  }

  render() 
  {
    return (
      <LRModal  open={this.state.open}
                title={LocalizedStrings.Header}
                onCancelClick={this.close}
                onOkClick={this.ok}
                okDisabled={this.state.branchName===""}
                >
        <Segment placeholder>
          <Form>
            <Form.Input fluid label={LocalizedStrings.BranchName} value={this.state.branchName} onChange={(_, {value})=>{this.setState({ branchName: value.replace(/[^a-zA-Z0-9]/g,'-')})}}/>
          </Form>
          {this.state.errorText ?
          <Message
            error
            icon='lock'
            header={LocalizedStrings.HeaderNoRights}
            content={LocalizedStrings.TextNoRights}
          /> : null}
        </Segment>
      </LRModal>
    )
  }

  close =  () =>
  {
    this.setState({open: false, errorText: false})
  }

  ok = async () =>
  {
    let data = await window.LR_CreateBranchAsync({Name: this.state.branchName})
    if(data.Project.BranchName)
    {
      this.setState({open: false})
    }

    if(data.Project.NotAllowed)
    {
      this.setState({errorText: true})
    }
    
  }

  setUpCallbacks()
  {
    globalCallbacks.openNewBranch = async (arg) => 
    {
      this.setState({open:true})
    }
  } 
}


export default CreateNewBranchView;