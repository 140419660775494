import React, { Component } from "react";
import LocalizedStrings from "../../localization/TimeStrings";
import LRModal from "../Basics/BasicModal";
import Calendar from "./Calendar";

import { globalCallbacks } from "../../util/callback";


class CalendarModal extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = {
        open: false
    }
  }

  componentDidMount = () =>
  {
    this.setUpCallbacks();

  }

  render() 
  {
    return (
      <LRModal  open={this.state.open}
                onOkClick={this.onOkClicked}
                title={<h2>{LocalizedStrings.Calendar}</h2>}
                noCancel={true}
                size="fullscreen"
                >
        <Calendar/>
      </LRModal>
    )
  }

  onOkClicked = () =>
  {
      this.close()
  }

  close = () =>
  {
      this.setState({open: false})
  }


    setUpCallbacks()
  {
    globalCallbacks.OpenCalenderView = async (arg) => 
    {
      this.setState({open:true})
    }
  } 




}


export default CalendarModal;