
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import LocalizedStrings from "../../localization/NavigationContainer";
import React, { Component } from 'react';
import { Table, Icon, Input, Popup } from 'semantic-ui-react'

import { globalCallbacks as mainCB } from '../../util/callback';
import { globalCallbacks as mockCB } from '../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB

class SavedViewNode extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = this.props.entry;
    }

    componentDidUpdate(oldProps) 
    {
        const newProps = this.props;
        for(const [name, value] of Object.entries(newProps.entry))
        if(oldProps.entry[name] !== value) 
        {
            this.setState({[name]: value});
        }
    }

    render() 
    {
        let entry = this.state;
        return (
            <Table.Row>
                <Table.Cell collapsing>
                    <Popup content={LocalizedStrings.ActivateSavedView}
                    trigger={
                    <Icon  link name    = "selected radio"
                            onClick = {() => this.activate(entry.UUID)}
                            />
                    }/>
                </Table.Cell>
                <Table.Cell>
                    <Input  transparent
                            name      = "Name" 
                            value     = {entry.Name} 
                            onChange  = {this.onValueChanged} 
                            onBlur    = {() => this.updateData("Name", entry.Name)}
                            onKeyDown = {this.onKeyDown}
                            fluid/>
                </Table.Cell>
                <Table.Cell textAlign="center" collapsing>
                <Popup content={LocalizedStrings.StoreSavedView}
                    trigger={
                        <Icon   name    = "file" 
                            link
                            onClick = {() => {this.update(entry.UUID)}}/>
                    }/>
                    <Popup content={LocalizedStrings.DeleteSavedView}
                            trigger={
                        <Icon   name    = "delete"
                            color   = "red"
                            onClick = {() => this.onDelete(entry.UUID)}
                        />
                    }/>
                </Table.Cell>
            </Table.Row>
        );
    }

    onValueChanged = (e, {name, value}) =>
    {
        this.setState({[name]:value});
    }

    updateData = (name, value, event) =>
    {
        let request = 
        {
            UUID: this.state.UUID
        };

        if(name)    { request[name] = value; }
        else        { request.Name  = this.state.Name; }

        
        window.LR_SetSavedView(request);
    }

    onKeyDown = (event) =>
    {
        if(event.keyCode === 13) { this.updateData(); event.target.blur()}
    }

    onDelete = (uuid) =>
    {
        let request = 
        {
            UUID: uuid,
        }

        window.LR_DeleteSavedView(request);
    }

    activate = (uuid) =>
    {
        let request = 
        {
            UUID: uuid,
        }

        window.LR_ShowSavedView(request);
    }

    update = (uuid) =>
    {
        let request = 
        {
            ...globalCallbacks.getRenderView(),
            UUID: uuid,
            ReadLayerVisibility: true
        }

        window.LR_SetSavedView(request);
    }

}

export default SavedViewNode;

