
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component, useEffect, useState } from 'react';
import { Icon, Button, Table, Form, Message, Loader, Grid, List, Divider, Header, Label } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/ResourceManager";
import { globalCallbacks } from '../../util/callback';
import { EMPTY_UUID, IsRunningAsBrowser } from '../../util/defines';
import LRModal from '../Basics/BasicModal';
import SymbolMapSelector from './SymbolMapSelector';
import { FetchGroups } from '../../redux/actions/fetch_actions';
import { connect, useSelector } from "react-redux"
import { GROUPS } from '../../redux/redux_defines';
import { lrServerConnection } from '../../redux/light_right_server_connection';
import { uuidStr } from '../../util/callbackTypes';

interface fromResourceSelectorInterface {
    newTo: string,
    newUUID: uuidStr,
    newIdent: string,
    User: string
}

export function SymbolMapDisplayAddEntryModal(this: unknown, {
    open,
    incomingSymbolName,
    value,
    updateIndex,
    onOkClick,
    onCancelClick,
}: {
    open: boolean,
    incomingSymbolName?: string,
    value?: fromResourceSelectorInterface,
    updateIndex?: number,
    onOkClick: (from: string, to: fromResourceSelectorInterface, updateIndex?: number) => void,
    onCancelClick: () => void
}) {
    let [from, setFrom] = useState(incomingSymbolName ?? "")
    let [to, setTo] = useState(value)
    let [title, setTitle] = useState(LocalizedStrings.AddMapKey)

    useEffect(()=>{
        setFrom(incomingSymbolName ?? "")
        setTo(value)

        if(updateIndex === undefined){
            setTitle(LocalizedStrings.AddMapKey)
        }else{
            setTitle(LocalizedStrings.ModifyMapKey.replace("$1", incomingSymbolName))
        }
    }, [incomingSymbolName, value, open])


    return <LRModal title={title}
        open={open}
        srcolling={false}
        onCancelClick={onCancelClick}
        onOkClick={()=>{
            if(from && to){
                onOkClick(from, to, updateIndex)
            }
        }}
        scrolling={false}>
        <Form fluid>
            <Form.Group widths='equal'>
                <Form.Input
                    value={from}
                    onChange={(e, { value }) => setFrom(value)}
                    label={LocalizedStrings.Incoming}
                />
                <SymbolMapSelector value={to?.newTo ?? ""} label={LocalizedStrings.ReplaceWith} updateData={updateObj => setTo(updateObj)} />
            </Form.Group>
        </Form>
    </LRModal>
}