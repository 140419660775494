//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React from "react"
import { Label, Table } from "semantic-ui-react"
import { BASE_UNIT_AREA, BASE_UNIT_VOLUME, BASE_UNIT_WEIGHT, BASE_UNIT_LENGTH } from "../../util/defines"
import { getUnit, getUnitName } from "./BasicUnitInput"
import { connect } from 'react-redux';
import { GLOBAL_SETTINGS as JestGS } from "../../redux/redux_defines";

let GLOBAL_SETTINGS
if(!process.env.JEST_WORKER_ID) { GLOBAL_SETTINGS  = JestGS }		

class LRTableHeaderCell extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state = {
            unit: 0
        }
    }

    componentDidMount()
    {
        if (process.env.JEST_WORKER_ID)
        {
            window.LR_GetGlobalSettings().then(settings => 
            {
                switch(this.props.baseUnit)
                {
                    case BASE_UNIT_LENGTH: this.setState({unit: settings.GlobalSettings.App_DisplayUnit_Length.Value}); break;
                    case BASE_UNIT_WEIGHT: this.setState({unit: settings.GlobalSettings.App_DisplayUnit_Weight.Value}); break;
                    case BASE_UNIT_VOLUME: this.setState({unit: settings.GlobalSettings.App_DisplayUnit_Volume.Value}); break;
                    case BASE_UNIT_AREA  : this.setState({unit: settings.GlobalSettings.App_DisplayUnit_Area.Value}); break;
                    default:;
                }
            })
        }
    }

    render()
    {
        let unit = getUnit(this.props.baseUnit, this.props.globalSettings ? this.props.globalSettings : this.state.globalSettings)

        const raw =  {...this.props}  
        delete raw.baseUnit
          
        let unitName = getUnitName(unit, this.props.baseUnit)

        return <Table.HeaderCell {...raw} style={{...this.props.style, position: "sticky", whiteSpace: "nowrap"}} data-printHidden={this.props.printHidden}>
                    {this.props.children}
                    {this.props.sorted ? <>{" "} <Label size="mini" circular>{this.props.sortedNumber}</Label></> : null}
                    {unitName && <Label size="tiny" content={unitName}  corner="right"/>}
                    <div onDoubleClick={this.props.resizeDoubleClick} style={{zIndex: 2, width: 10, position: "absolute", left: 0, height: "100%", top: 0, cursor: (this.props.index > 0 ? "ew-resize" : undefined)}} data-left></div>
                    <div onDoubleClick={this.props.resizeDoubleClick} style={{zIndex: 2, width: 10, position: "absolute", right: 0, height: "100%", top: 0, cursor: "ew-resize"}} data-right></div>
                </Table.HeaderCell>
    }
}

//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      globalSettings: state[GLOBAL_SETTINGS].data.GlobalSettings,
    };
}

let componentExport;
if(!process.env.JEST_WORKER_ID) { componentExport = connect(mapStateToProps)(LRTableHeaderCell) }
else                                         { componentExport = LRTableHeaderCell }


export default componentExport 