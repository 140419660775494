import * as THREE from "three";
import { SingleNotification } from "../NotificationDisplay/NotificationDisplay";
import { FRAME_COUNT } from "../../util/DateHelper";
import { VolumetricSpotLight } from "./js/VolumetricSpotLight";
import EventEmitter from "eventemitter3";
import Renderer from "./Renderer";
import LocalizedStrings from "../../localization/Renderer"

interface AnimationEvents {
  "start": void //On animation start
  "stop": void //On stop; Also called, when animation was aborted
  "abort": void //On abort
  "update": void //On update; Called every tick
}

let instance: AnimationControl | undefined = undefined


export function forceGlobalAnimationStop() {
  if (instance) {
    instance.forceStop()
  }
}

/**
 * One Single Animaton. Uses the emitter property to call start, stop and update
 */
export class SingleAnimation {
  progressPercent = 0
  progressTime = 0
  time: number
  emitter: EventEmitter<AnimationEvents> = new EventEmitter()

  constructor(timeInFrames: number) {
    this.time = timeInFrames;
  }

  doUpdate(elapsedTime: number) {
    this.progressTime += elapsedTime
    if (this.time) {
      this.progressPercent += elapsedTime / this.time
    } else {
      this.progressPercent = 1
    }
    this.emitter.emit("update")
  }

}

/**
 * Collection of SingleAnimations. Changes to a single property are unique here. Offers the same events like SingleAnimation
 */
export class AnimationBunch {
  private animationsToDo: Map<THREE.Object3D, Map<string, SingleAnimation>> = new Map()
  events: EventEmitter<AnimationEvents> = new EventEmitter()
  isStarted = false
  progress = 0
  estimatedTime = 0

  /**
   * For internal use only; Called by the main AnimationControl
   * @returns 
   */
  start() {
    if (this.isStarted) {
      return
    }

    this.isStarted = true
    for (const animation of this.animationsToDo.values()) {
      for (const singlean of animation.values()) {
        singlean.emitter.emit("start")
      }
    }
    this.events.emit("start")
  }

  /**
 * For internal use only; Called by the main AnimationControl
 * @returns 
 */
  stop() {
    if (!this.isStarted) {
      return
    }
    this.isStarted = false
    this.events.emit("stop")
  }

  /**
 * For internal use only; Called by the main AnimationControl
 * @returns 
 */
  forceStop() {
    if (!this.isStarted) {
      return
    }
    for (const [obj, animation] of this.animationsToDo) {
      for (const singlean of animation.values()) {
        singlean.emitter.emit("abort")
        singlean.emitter.emit("stop")
      }
      obj.updateMatrix()
      obj.updateMatrixWorld(true)
    }
    this.animationsToDo.clear()
    this.events.emit("abort")
    this.stop()
  }

  /**
 * For internal use only; Called by the main AnimationControl
 * @returns 
 */
  tick(delta: number) {
    let toRemove: any[] = []
    let shortest = 1;
    for (const [obj, animation] of this.animationsToDo) {
      for (const [t, singlean] of animation) {
        singlean.doUpdate(delta)
        if (singlean.progressPercent >= 1) {
          singlean.emitter.emit("stop")
          animation.delete(t)
        }

        if (singlean.progressPercent < shortest) {
          shortest = singlean.progressPercent
        }

        if (!animation.size) {
          toRemove.push(obj)
        }
      }
      obj.updateMatrix()
      obj.updateMatrixWorld(true)
    }

    for (let i of toRemove) {
      this.animationsToDo.delete(i)
    }

    this.progress = shortest

    if (!this.animationsToDo.size) {
      this.stop()
    }
  }

  addMoveAnimation(mainObj: THREE.Object3D, toVec: THREE.Vector3, timeInFrames: number) {
    if (toVec.equals(mainObj.position)) {
      return;
    }


    let animationObj = new SingleAnimation(timeInFrames)

    animationObj.emitter.on("start", () => {
      let from = mainObj.position.clone()

      animationObj.emitter.on("update", () => {
        mainObj.position.lerpVectors(from, toVec, animationObj.progressPercent)
      })

      animationObj.emitter.on("stop", () => {
        mainObj.position.copy(toVec)
      })
    })

    this.addAnimation(mainObj, animationObj, "move")

  }

  addScaleAnimation(mainObj: THREE.Object3D, toVec: THREE.Vector3, timeInFrames: number) {
    if (toVec.equals(mainObj.scale)) {
      return;
    }


    let animationObj = new SingleAnimation(timeInFrames)

    animationObj.emitter.on("start", () => {
      let from = mainObj.scale.clone()
      animationObj.emitter.on("update", () => {
        mainObj.scale.lerpVectors(from, toVec, animationObj.progressPercent)
      })

      animationObj.emitter.on("stop", () => {
        mainObj.scale.copy(toVec)
      })
    })


    this.addAnimation(mainObj, animationObj, "scale")
  }

  addRotationAnimation(mainObj: THREE.Object3D, toVec: THREE.Euler, timeInFrames: number) {
    if (toVec.equals(mainObj.rotation)) {
      return;
    }


    let animationObj = new SingleAnimation(timeInFrames)

    animationObj.emitter.on("start", () => {
      let start = new THREE.Quaternion().setFromEuler(mainObj.rotation)
      let curr = start.clone()
      let end = new THREE.Quaternion().setFromEuler(toVec)
      animationObj.emitter.on("update", () => {
        curr.slerpQuaternions(start, end, animationObj.progressPercent)
        mainObj.rotation.setFromQuaternion(curr, "XYZ")
      })

      animationObj.emitter.on("stop", () => {
        mainObj.rotation.copy(toVec)
      })
    })

    this.addAnimation(mainObj, animationObj, "rotation")
  }

  addColorAnimation(mainObj: VolumetricSpotLight, toVec: THREE.Color, timeInFrames: number) {
    if (mainObj.compColors(toVec)) {
      return;
    }


    let animationObj = new SingleAnimation(timeInFrames)

    animationObj.emitter.on("start", () => {
      let from = mainObj.CurrentColor.clone()

      animationObj.emitter.on("update", () => {
        mainObj.lerpColors(from, toVec, animationObj.progressPercent)
      })

      animationObj.emitter.on("stop", () => {
        mainObj.setColor(toVec)
      })

    })


    this.addAnimation(mainObj, animationObj, "color")
  }

  addAnimation(mainObj: THREE.Object3D, animation: SingleAnimation, animationType: string) {
    if (!this.animationsToDo.has(mainObj)) {
      this.animationsToDo.set(mainObj, new Map())
    }
    this.animationsToDo.get(mainObj)?.set(animationType, animation)
    if (animation.time > this.estimatedTime) {
      this.estimatedTime = animation.time
    }
  }
}

export default class AnimationControl {
  private mainObj: Renderer
  private notification: SingleNotification
  private clock: THREE.Clock = new THREE.Clock()
  private animationQueue: AnimationBunch[] = []
  private currAnimation: AnimationBunch = undefined
  private elapsedTime = 0;
  private estimatedTime = 0;
  private showMessage = true;

  events: EventEmitter<AnimationEvents> = new EventEmitter()


  isStarted = false

  constructor(mainObj: Renderer) {
    this.mainObj = mainObj
    instance = this
  }

  private tick() {
    let delta = this.clock.getDelta() * FRAME_COUNT
    this.elapsedTime += delta

    while (!this.currAnimation) {
      if (!this.animationQueue.length) {
        this.stop()
        return;
      }
      this.notification?.updateContent(`${LocalizedStrings.ControlsDisabled}. ${LocalizedStrings.RemainingAnimations}: ${this.animationQueue.length}`)

      this.currAnimation = this.animationQueue.shift()
      let anyStarted = false
      this.currAnimation.start()
      if(this.currAnimation.estimatedTime === 0){
        this.currAnimation.forceStop()
      }else{
        anyStarted = true
      }
      if(!anyStarted){
        this.currAnimation = undefined
      }
    }

    let anyStarted = false
    if (this.currAnimation.isStarted) {
      this.currAnimation.tick(delta)
      anyStarted = true
    }
    if (!anyStarted) {
      this.currAnimation = undefined
    }

    this.updateMessage()
    this.events.emit("update")
  }

  private async updateMessage() {
    if (this.showMessage && !this.notification && this.isStarted && this.estimatedTime) {
      this.notification = await SingleNotification.newNotification(LocalizedStrings.AnimatingText, LocalizedStrings.ControlsDisabled, "positive", 0)
      this.notification.addButton(LocalizedStrings.CancelAnimation, () => {
        this.forceStop()
      })
      this.notification.show()
    } else if (!this.showMessage || !this.isStarted) {
      this.notification?.delete()
      this.notification = undefined
    } else {
    }
  }

  /**
   * Starts the animation, if it wasnt started yet
   * @returns 
   */
  start() {
    this.updateMessage().then(()=>{
      this.notification?.updateContent(`${LocalizedStrings.ControlsDisabled}. ${LocalizedStrings.RemainingAnimations}: ${this.animationQueue.length}`)
    })

    if (this.isStarted || (!this.currAnimation && !this.animationQueue.length)) {
      return
    }
    this.elapsedTime = 0

    //In case the only jobs to do are one-time movements, dont use animation system
    if(this.estimatedTime === 0){
      this.isStarted = true
      this.events.emit("start")
      for(let i of this.animationQueue){
          i.start()
          i.forceStop()
      }
      this.stop()
      return;
    }

    this.clock.start()
    this.isStarted = true
    this.events.emit("start")
  }

  // Must be called by the main renderer in the animation loop
  doAnimation(){
    if(this.isStarted){
      this.tick();
      this.mainObj.updateMeshInstances()
      this.mainObj.forceNewFrame()
    }
  }

  /**
   * Internal function; Use forceStop() to stop animation
   * @returns 
   */
  private stop() {
    if (!this.isStarted) {
      return
    }

    this.estimatedTime = 0
    this.isStarted = false
    this.clock.stop()

    this.updateMessage()

    this.mainObj.forceNewFrame()
    this.events.emit("stop")
  }

  /**
   * Aborts any animation
   * @returns 
   */
  forceStop() {
    if (!this.isStarted) {
      return
    }
    this.currAnimation.forceStop()

    this.currAnimation = undefined
    this.animationQueue = []
    this.events.emit("abort")
    this.stop()
  }

  /**
   * Allows to disable messages, so an own can be displayed; Needs to be activated manually again
   * @param showMessage 
   */
  setShowMessage(showMessage: boolean) {
    this.showMessage = showMessage
    this.updateMessage()
  }

  /**
   * Adds a single Bunch to the animation queue
   * @param bunch 
   */
  addSingleAnimationBunch(bunch: AnimationBunch) {
    this.estimatedTime += bunch.estimatedTime
    this.animationQueue.push(bunch)
    this.start()
  }
}
