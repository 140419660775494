//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from "react"
import { Form } from "semantic-ui-react";
import LocalizedStrings from "../../localization/ChangeOriginModal";
import { globalCallbacks } from "../../util/callback";
import { BASE_UNIT_LENGTH } from "../../util/defines";
import LRModal from "../Basics/BasicModal";
import UnitInput from "../Basics/BasicUnitInput";

class ChangeOriginModal extends Component
{
    constructor(props)
    {
        super(props)

        this.state = {
            open: false,
            OffsetX: 0,
            OffsetY: 0,
            OffsetZ: 0,
        }

        this.objectUuid= undefined
    }

    componentDidMount()
    {
        this.setUpCallbacks()
    }

    render()
    {
        return (
            <LRModal    open={this.state.open}
                        title={LocalizedStrings.Header}
                        onOkClick={this.onOkClicked}
                        onCancelClick={this.close}>
                <Form fluid>
                    <UnitInput name="OffsetX" label="OffsetX" baseUnit={BASE_UNIT_LENGTH} value={this.state.OffsetX} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>
                    <UnitInput name="OffsetY" label="OffsetY" baseUnit={BASE_UNIT_LENGTH} value={this.state.OffsetY} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>
                    <UnitInput name="OffsetZ" label="OffsetZ" baseUnit={BASE_UNIT_LENGTH} value={this.state.OffsetZ} onStateUpdate={(name, val) => {this.setState({[name]: val})}}/>
                </Form>
            </LRModal>
        )
    }

    onOkClicked = () =>
    {
        if (this.objectUuid)
        {
            window.LR_ChangeOrigin({UUID: this.objectUuid, GlobalCoordinates: true, OffsetX: this.state.OffsetX, OffsetY: this.state.OffsetY, OffsetZ: this.state.OffsetZ})
        }
        this.close()
    }

    open = (globalOffset) =>
    {
        if (globalOffset)
        {
            this.setState({open: true, OffsetX: globalOffset.X, OffsetY: globalOffset.Y, OffsetZ: globalOffset.Z})
        }
        else
        {
            this.setState({open: true})
        }
    }

    close = () =>
    {
        this.setState({open: false})
    }

    setUpCallbacks()
    {
        globalCallbacks.openChangeOriginModal = (uuidObj) =>
        {
            this.objectUuid = uuidObj.UUID
            this.open(uuidObj.GlobalOffset);
        }

        globalCallbacks.changeOriginUnitUpdate = (settings) =>
        {
            window.LR_GetGlobalSettings().then(res => {
                this.setState({unti: res.GlobalSettings.Unit.Value})
            })
        }
    }
}

export default ChangeOriginModal;