 //-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React from 'react';
import { Divider, Icon } from 'semantic-ui-react';
import { globalCallbacks } from "../../util/callback";

const cornerRadius = 5
const menuColor = "rgba(200, 200, 200, 0.9)"
const selectedColor = "rgba(34, 34, 255, .7)"
class LongPressMenu extends React.Component 
{
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      position: { clientX: 300, clientY: 300 },
      menuDef: [],
      activeIndexMap: [],
      
    };

    this.oldTouchX = 0;
    this.oldTouchY = 0;
    this.menuWidths = []
    
    this.setUpCallback();

    window.addEventListener("mousedown", (e) => {
      let clickedMenuItem = !!e.target.getAttribute("ismenuitem")
      if (this.state.open && !clickedMenuItem)
      {
        e.preventDefault();
        e.stopPropagation();
        this.setState({open: false, activeIndexMap: []}) 
      }
    })
  }

  render() {
    return this.state.open ? this.renderContent() : null;
  }

  renderMenuEntry = (menuEntry, index, depth) =>
  {

    let indexMap = this.state.activeIndexMap

    let currentIndex = index.value;
    let activeIndex = indexMap[depth] ? indexMap[depth].index : undefined
    let isHovered = activeIndex === currentIndex
    if (!this.state.activeIndexMap[depth]) { isHovered = false; }
    let color = isHovered ? selectedColor : "transparent"
    index.value++;
    if (menuEntry.type === "separator")
    {
      //return <div style={{height: 1, margin: "0.4rem", padding: "0.2rem", backgroundColor: "darkgray", borderRadius: cornerRadius}}/>
      return <Divider />
    }
    let opacity = 1
    if(menuEntry.enabled === false)
    {
      color = menuColor
      opacity = 0.2
    }


    return (
      <div  index={currentIndex} 
            style={{margin: "0.4rem", padding: "0.2rem", backgroundColor: color, borderRadius: cornerRadius, opacity: opacity}}
            ismenuitem={1}
            onClick={() => { 
              if (menuEntry.cmd && !(menuEntry.enabled === false) ) 
              { 
                window[menuEntry.cmd](menuEntry.arg); this.setState({open: false, activeIndexMap: []})
              }
              else if(menuEntry.role)
              {
                if(menuEntry.role === "copy") document.execCommand("copy");
                if(menuEntry.role === "paste") document.execCommand("paste");
                if(menuEntry.role === "cut") document.execCommand("cut");
                this.setState({open: false, activeIndexMap: []})
                
              } }}>
          {menuEntry.label}
          {menuEntry.subMenu ? <Icon name="angle right" style={{float: "right"}}/> : null}
      </div>
    )
  }

  renderSingleSubMenu = (depth = 0) =>
  {
    let entryArray = this.state.menuDef
    let menuXOffset = 0
    let menuYOffset = this.state.position.clientY - 12
    if (depth > 0)
    {
      let selectedDepthIndex = this.state.activeIndexMap[depth - 1].index
      menuYOffset = Number(this.state.activeIndexMap[depth-1].yOffset)
      if (selectedDepthIndex === undefined) { return null; }
      for (let i = 0; i < depth; i++)
      {
        menuXOffset += this.menuWidths[i]
        let index = this.state.activeIndexMap[i].index
        entryArray = entryArray[index].subMenu
      }
    }
    let index = {value: 0}
    let maxHeight = undefined;
    maxHeight = window.innerHeight - menuYOffset

    if(maxHeight < 400 && depth === 0)
    {
      maxHeight += 400
      menuYOffset -=400
    }else if(maxHeight < 400 && depth !== 0)
    {
      maxHeight += 400
    }

    maxHeight -= 20 // Some extra margin

    return <div   style={{ position: "absolute",
                          left: this.state.position.clientX + menuXOffset,
                          top: menuYOffset,
                          width: "max-content",
                          maxHeight: maxHeight,
                          overflow: depth !== 0 ? "hidden" : "auto",
                          zIndex: "1000",
                          backgroundColor: menuColor,
                          borderRadius: cornerRadius,
                          MozUserSelect: "none", WebkitUserSelect: 'none', msUserSelect: 'none' 
                        }}
                    ref={(r) => {if (!r) { return; } this.menuWidths[depth] = r.clientWidth; }}
                    depth       = {depth}
                    onMouseMove={this.mouseMove}
                    onMouseLeave={()=>{}}>
                      {entryArray ? entryArray.map(menuEntry => this.renderMenuEntry(menuEntry, index, depth)) : null}
    </div>
  }

  renderContent() {
    let result = [this.renderSingleSubMenu(0)]
    let currentMenu = this.state.menuDef
    let currentDepth = 0;
    
    while (this.state.activeIndexMap[currentDepth] && currentMenu[this.state.activeIndexMap[currentDepth].index])
    {
      if (currentMenu[this.state.activeIndexMap[currentDepth].index].subMenu)
      {
        currentDepth++;
        result.push(this.renderSingleSubMenu(currentDepth))
      }
      else
      {
        break;
      }
    }


    /*if (this.state.activeIndex && this.state.menuDef[this.state.activeIndex].subMenu)
      result.push(<div style={{}}>
            {(isHovered && menuEntry.subMenu) ?
              menuEntry.subMenu.map(child => this.renderMenuEntry(child, index)) : null}
          </div>)*/
    return result;
  }

  mouseMove = (e) =>
  {
    let attribute = e.target.getAttribute("index");
    if (!attribute) { return; }
    let depth = e.target.parentElement.getAttribute("depth");
    
    let newActiveIndices = this.state.activeIndexMap.map(v => v)

    newActiveIndices[depth] = { 
                                index   : Number(attribute),
                                yOffset : e.target.getBoundingClientRect().top
                              }
    
    this.setState({activeIndexMap: newActiveIndices})
  }
  
  onClick = (e) =>
  {
    globalCallbacks.closeContextMenu(e)
  }

  setUpCallback = () => 
  {
    globalCallbacks.showContextMenu = (menuDef, clickPosition) => 
    {
      this.setState({ open: true, position: clickPosition, menuDef: menuDef });
    };

    globalCallbacks.closeContextMenu = (clickedPosition) => 
    {
      /*let index = this.getMenuIndexFromPosition(clickedPosition);
      if (index >= 0 && index < this.state.menuDef.length) 
      {
        this.state.menuDef[index].click();
      }*/

      this.setState({ open: false });
    };

    globalCallbacks.updateMenuItemSelection = (position) => 
    {
      /*let index = this.getMenuIndexFromPosition(position);
      this.setState({activeIndex: index})*/
    }
  };
}

export default LongPressMenu