
//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component, createRef } from 'react';
import { Table, Grid, Menu, Sticky, Message, Icon, Input } from "semantic-ui-react";
import LocalizedStrings from "../../../localization/LightingPaperwork";
import ColorInputField from '../../ColorPicker/ColorInputField';
import {escapeRegExp} from "../../../util/defines";
import { globalCallbacks } from "../../../util/callback";

class TrackingList extends Component 
{
    constructor(props)
    {
        super(props);

        this.state = 
        {
            fixtures            : [],
            activeFixtureIndex  : 0,

            searchText      : "",
            searchOptions   : [],
            searchResults   : [],
        };

        this.contextRef = createRef();
    }

    componentDidMount = () => 
    {
        this.setUpCallbacks();

        if(globalCallbacks.getAllActiveFixtures)
        {
            globalCallbacks.getAllActiveFixtures();
        }
    }

    componentWillUnmount = () => 
    {
        this.takeDownCallbacks();
    }

    render() 
    {
        return (
            <Grid columns={2}>
                <Grid.Column width={2}>
                    <div style={{height: '100%'}} ref={this.contextRef}>
                    <Sticky context={this.contextRef}>
                        <Menu fluid secondary vertical>
                            <Input
                                icon        = "search"
                                fluid
                                placeholder = "Search..."
                                value       = {this.state.searchText}
                                onChange    = {this.onChangeSearch}/>
                            {
                                this.state.searchResults.map(fixture =>
                                {
                                    return(
                                        <Menu.Item  key         = {fixture.UUID}
                                                    active      = {fixture.UUID === this.state.activeFixture}
                                                    onClick     = {() => this.onFixtureClicked(fixture)}>
                                                        
                                            {fixture.title}
                                        </Menu.Item>
                                    );
                                })
                            }
                        </Menu>
                    </Sticky>
                    </div>
                </Grid.Column>
                <Grid.Column width={14}>
                    {
                        this.state.fixtures.map((fixture) =>
                        {
                            return(this.renderFixture(fixture));
                        })
                    }
                    {
                        this.state.fixtures.length > 0 ? null : <Message warning><Icon name='warning'/>{LocalizedStrings.NoFixture}</Message>
                    }
                </Grid.Column>
            </Grid>

        );
    }

    renderFixture = (fixture) =>
    {
        if(fixture.TimePhaseChangeList.length === 0)
        {
            return null
        }
        return(<div id={fixture.UUID} key={fixture.UUID} style={{marginTop: "0.5em", marginLeft: "0.5em"}}>
            <Table fixed size="small" compact striped >
            <Table.Header>
                <Table.HeaderCell>{fixture.FixtureId + " " + fixture.Name}</Table.HeaderCell>
                <Table.HeaderCell>{LocalizedStrings.FixtureType + ": " + fixture.LinkedFixtureTypeName}</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>{LocalizedStrings.Position + ": " + fixture.AssemblyGroupName}</Table.HeaderCell>
            </Table.Header>
            <Table.Header>
                <Table.HeaderCell>{LocalizedStrings.CueName}</Table.HeaderCell>
                <Table.HeaderCell>{LocalizedStrings.Dimmer}</Table.HeaderCell>
                <Table.HeaderCell>{LocalizedStrings.Color}</Table.HeaderCell>
                <Table.HeaderCell>{LocalizedStrings.Gobo}</Table.HeaderCell>
                <Table.HeaderCell>{LocalizedStrings.Focus}</Table.HeaderCell>
                <Table.HeaderCell>{LocalizedStrings.Group}</Table.HeaderCell>
            </Table.Header>
            {
                fixture.TimePhaseChangeList.map((change) =>
                {
                    return(this.renderCue(change));
                })
            }
            </Table></div>
        )
    }

    renderCue = (change) =>
    {
        return(<React.Fragment key={change.UUID}>
             <Table.Row>
                <Table.Cell>{change.Name}</Table.Cell>
                <Table.Cell>{change.Dimmer}</Table.Cell>
                <Table.Cell>{this.renderColorField(change.LightColor)}</Table.Cell>
                <Table.Cell>{change.Gobo}</Table.Cell>
                <Table.Cell>{}</Table.Cell>
                <Table.Cell>{}</Table.Cell>
            </Table.Row>
        </React.Fragment>)
    }

    renderColorField = (color) =>
    {
        return(
            <ColorInputField    name            = "LightColor"
                                readOnly
                                size            = "mini"
                                label           = ""
                                colorX          = {color.X} 
                                colorY          = {color.Y} 
                                colorL          = {color.Z}/>
        );
    }

    onChangeSearch = (_, {value}) =>
    {
        let searchResults = this.state.searchOptions;

        if(value)
        {
            const re = new RegExp(escapeRegExp(value), 'i')
            const isMatch = (result) => re.test(result.title);

            searchResults = this.state.searchOptions.filter(isMatch);

            //Avoid crash
            if(searchResults.length === 0) { searchResults.push([]); }
        }

        this.setState({ searchText : value, searchResults });
    }

    onFixtureClicked = (fixture) =>
    {
        let jumpHere = fixture.UUID;
        window.location.href = window.location.origin + window.location.search + "#" + jumpHere;
        this.setState({activeFixture : fixture});
    }

    setUpCallbacks()
    {
        globalCallbacks.getAllActiveFixtures = async () =>
        {
            let fixtures = await window.LR_GetAllActiveFixtures();

            let searchOptions = fixtures.map(fixture =>
            {
                return({
                    UUID    : fixture.UUID,
                    title   : fixture.FixtureId + " " + fixture.Name,
                })
            })

            let searchResults = [...searchOptions];

            this.setState({fixtures, searchOptions, searchResults});
        }
    }

    takeDownCallbacks()
    {
        globalCallbacks.getAllActiveFixtures = undefined;
    }


}

export default TrackingList;

