//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React, { Component } from 'react';
import { Segment, Header, Icon, Table } from 'semantic-ui-react';
import LocalizedStrings from "../../../localization/TableViewComponent";
import { BASE_UNIT_WEIGHT } from '../../../util/defines';
import LRTableHeaderCell from '../../Basics/BasicTableView'
import UnitInput from '../../Basics/BasicUnitInput';


import { globalCallbacks as mainCB } from '../../../util/callback';
import { globalCallbacks as mockCB } from '../../../util/mock_callback';
let globalCallbacks = !process.env.JEST_WORKER_ID ? mainCB : mockCB

class CaseList extends Component
{
    constructor(prop)
    {
        super(prop);
        this.state = 
        {
            cases: [
            ],
            expandedContainers: []
        }        
    }

    componentDidMount()
    {
        this.setUpCallbacks()

        globalCallbacks.updateCaseList()
    }

    render()
    {
        return (
            <React.Fragment>
                {this.state.cases.length !== 0 && 
                <Table celled selectable style={{margin: "0"}}>
                    <Table.Header>
                        <Table.Row>
                            <LRTableHeaderCell >
                                {LocalizedStrings.CaseName}
                            </LRTableHeaderCell>
                            <LRTableHeaderCell>
                                {LocalizedStrings.ContainedObject}
                            </LRTableHeaderCell>
                            <LRTableHeaderCell>
                                {LocalizedStrings.LinkedAssemblyGroup}
                            </LRTableHeaderCell>
                            <LRTableHeaderCell>
                                {LocalizedStrings.UsedCapacity}
                            </LRTableHeaderCell>
                            <LRTableHeaderCell>
                                {LocalizedStrings.Capacity}
                            </LRTableHeaderCell>
                            <LRTableHeaderCell baseUnit={BASE_UNIT_WEIGHT}>
                                {LocalizedStrings.Weight}
                            </LRTableHeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {(this.state.cases.map((caseObject, index) => {
                        console.log("Rendering container objects: ", caseObject)
                        let weight = 0
                        let objectNames = [];
                        caseObject.ContainedObjects.forEach(resource=> {
                            weight += resource.Weight
                            if (!objectNames.includes(resource.DefiningResource.Name))
                            {
                                objectNames.push(resource.DefiningResource.Name)
                            }
                        })
                        let capacity = 0;
                        Object.entries(caseObject.ObjectMap).forEach(([key, value]) => {
                            capacity += value;
                        })
                        return (<React.Fragment key={index}>
                                <Table.Row active={this.state.selectedRowIndex === index}>
                                    <Table.Cell>
                                        {caseObject.Name}
                                        <Icon name={this.state.expandedContainers.includes(index) ? "caret down" : "caret right"} link onClick={(e) => {e.stopPropagation(); this.onExpandContainer(index) }}/>
                                    </Table.Cell>
                                    <Table.Cell>
                                        {objectNames.join(", ")}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {caseObject.AssemblyGroupName}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {caseObject.ContainedObjects.length}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {capacity}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <UnitInput  baseUnit = {BASE_UNIT_WEIGHT}
                                                    value = {weight}
                                                    label = {false}
                                                    transparent
                                                    readOnly
                                                    />
                                    </Table.Cell>
    
                                </Table.Row>
                                {this.state.expandedContainers.includes(index) ? caseObject.ContainedObjects.map((obj, i) => {
                                    console.log("Containing object: ", obj)
                                    if (!this.displayObject(obj)) { return null; }
                                    return <Table.Row key={i} selectable={false}>
                                                <Table.Cell>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {obj.NameIdentifier}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {obj.AssemblyGroupName}
                                                </Table.Cell>
                                                <Table.Cell>
                                                </Table.Cell>
                                                <Table.Cell>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <UnitInput  baseUnit = {BASE_UNIT_WEIGHT}
                                                                value = {obj.Weight}
                                                                label = {false}
                                                                transparent
                                                                readOnly/>
                                                </Table.Cell>
                                            </Table.Row>}) : null}
                                </React.Fragment>)}))}
                    </Table.Body>
                </Table>}
                {this.state.cases.length === 0 && <Segment placeholder><Header icon>{LocalizedStrings.EmptyCaseList}</Header></Segment>}
            </React.Fragment> );
    }

    displayObject(object)
    {
        return this.props.objects.find(key => key.UUID === object.UUID) !== undefined
    }

    renderRow(item)
    {
        return(
        <React.Fragment key={item.UUID}>
            <Table.Row>
                        <Table.Cell>{item.ObjectId}</Table.Cell>
                        <Table.Cell>{item.Name}</Table.Cell>
                        <Table.Cell>{item.OffsetX}</Table.Cell>
                        <Table.Cell>{item.OffsetY}</Table.Cell>
                        <Table.Cell>{item.Distance}</Table.Cell>
            </Table.Row>
        </React.Fragment>);
    }

    onExpandContainer = (index) =>
    {
        if (this.state.expandedContainers.includes(index)) 
        {
            let newExpanded = this.state.expandedContainers.filter(value => value !== index);
            this.setState({expandedContainers: newExpanded})
        }
        else
        {
            let newExpanded = this.state.expandedContainers
            newExpanded.push(index)
            this.setState({expandedContainers: newExpanded});
        }
    }

    setUpCallbacks()
    {
        globalCallbacks.updateCaseList = async () =>
        {
            let res = await window.LR_GetCases()
            console.log("Updating case list: ", res)
            this.setState({cases: res.Cases})
        }
    }
   
}

export default CaseList;