import React, { useEffect, useMemo, useState } from "react";
import { Checkbox, Dimmer, Divider, DropdownItemProps, Form, Loader, Message, Tab } from "semantic-ui-react";
import LocalizedStrings from "../../localization/AdminResourceManager";
import { globalWindowInterface, uuidStr } from "../../util/callbackTypes";
import LRModal from "../Basics/BasicModal";

declare const window: globalWindowInterface;

export function UploadExistingResourceToCloud({
    open,
    onClose,
    itemUUID
}: {
    open: boolean,
    onClose: () => void,
    itemUUID: uuidStr
}) {
    let [rawData, setRawData] = useState<any[]>([])
    let [avCategory, setAvCategory] = useState<DropdownItemProps[]>()
    let [avUsers, setAvUsers] = useState<DropdownItemProps[]>()

    let [category, setCategory] = useState<string>()
    let [user, setUser] = useState<string>()
    let [uploading, setUploading] = useState(true)

    let [name, setName] = useState<string>()
    let [manufacturer, setManufacturer] = useState<string>()
    let [description, setDescription] = useState<string>()
    let [triedOk, setTriedOk] = useState(false)

    let [objName, setObjName] = useState("")

    let [isOverwriting, setIsOverwriting] = useState(false)
    let [fileIdentToOverwrite, setFileIdentToOverwrite] = useState<string>(undefined)

    let files: DropdownItemProps[] = useMemo(() => {
        let f = rawData.find(j => j.identifier === category)?.files ?? []
        return f.map(i => ({ key: i._id, text: i.name, value: i.identifier }))
    }, [category, rawData])

    useEffect(() => {
        (async () => {
            setUploading(true)
            let data = await window.LR_GetOrganizationsAsync()
            let user = (await window.LR_GetLoggedInUser()).User
            if(data && user){
                const list = [
                    { key: user, text: user, value: user },
                    ...data.map(o => { return { key: o.username, text: o.username, value: o.username } })
                ]
                setAvUsers(list)
                if (list.length === 1) {
                    setUser(list[0].value)
                }
            }

            setObjName((await window.LR_GetObject({ UUID: itemUUID })).Name ?? "unknown")

            setUploading(false)
        })()
    }, [open])

    useEffect(() => {
        (async () => {
            if (!user) {
                return;
            }
            setUploading(true)
            let d = await window.LR_GetUserResourceAsync({ User: user, Async: true })
            let categoryList = []
            const flattenList = (data: any, depth = 0) => {
                categoryList.push({ key: data._id, text: `${Array(depth).fill("->").join()}${data.name}`, value: data.identifier })
                for (let i of data.subCategories) {
                    flattenList(i, depth + 1)
                }
            }

            for (let i of d) {
                flattenList(i)
            }

            setAvCategory(categoryList)
            setRawData(d)

            setUploading(false)
        })()
    }, [user])

    const onSelectCategory = async (cat: string) => {
        let f = avCategory.find(i => i.value === cat)
        if (f) {
            setCategory(f.value as string)
        } else {
            setUploading(true)
            let t = await window.LR_CreateResourceCategoryOnServer({
                Async: true,
                Category: cat,
                ParentCategory: "",
                Username: user
            })
            if (t) {
                setAvCategory(i => [...i, {
                    key: t._id,
                    text: t.name,
                    value: t.identifier
                }])
                setCategory(t.name)
            }
            setUploading(false)
        }
        setFileIdentToOverwrite(undefined)
    }

    const onOk = async () => {
        if (isOverwriting && (!fileIdentToOverwrite || !description || !category || !user)) {
            setTriedOk(true)
            return
        }
        else if (!isOverwriting && (!name || !manufacturer || !description || !category || !user)) {
            setTriedOk(true)
            return
        } else {
            setUploading(true)
            await window.LR_UploadSingleResourceToServerAsync({
                Async: true,
                UUID: itemUUID,
                Username: user,
                Filename: name,
                Manufacturer: manufacturer,
                Description: description,
                Category: category,
                OverwriteIdent: isOverwriting ? fileIdentToOverwrite : undefined
            })
            setUploading(false)
            onBeforeClose()
        }
    }

    const onBeforeClose = () => {
        setName(undefined)
        setManufacturer(undefined)
        setDescription(undefined)
        setCategory(undefined)
        setUser(undefined)
        setIsOverwriting(false)
        setFileIdentToOverwrite(undefined)
        setTriedOk(false)
        onClose()
    }

    return (
        <LRModal open={open}
            title={LocalizedStrings.UploadResourceWithName.replace("$1", objName)}
            onOkClick={onOk}
            onCancelClick={onBeforeClose}>
            <div>
                <Dimmer active={uploading}>
                    <Loader active={uploading} />
                </Dimmer>
                <Message>
                    {`${LocalizedStrings.Modal_Description} `}
                    <a
                        style={{
                            cursor: "pointer"
                        }}
                        onClick={() => window.LR_OpenLink({ UseBaseUrl: true, URL: "/documentation/print-label-as-background-tutorial" })}>
                        {LocalizedStrings.Modal_Description_More_Info}
                    </a>
                </Message>
                <Form>
                    <Form.Field>
                        <label>{LocalizedStrings.SelectUser}</label>
                        <Form.Dropdown
                            placeholder={LocalizedStrings.SelectUser}
                            fluid
                            selection
                            value={user}
                            options={avUsers}
                            onChange={(e, d) => { setUser(d.value as string) }}
                            error={(triedOk && !user) ? { content: LocalizedStrings.Error_Generic } : undefined}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>{LocalizedStrings.SelectCategory}</label>
                        <Form.Dropdown
                            disabled={!user}
                            placeholder={LocalizedStrings.SelectCategoryPlaceholder}
                            fluid
                            selection
                            search
                            allowAdditions
                            value={category}
                            options={avCategory}
                            selectOnBlur={false}
                            onChange={(e, d) => { onSelectCategory(d.value as string) }}
                            error={(triedOk && !category) ? { content: LocalizedStrings.Error_Generic } : undefined}
                        />
                    </Form.Field>
                    <Divider />
                    <Checkbox toggle name="Update Existing" label={LocalizedStrings.UpdateExistingResource} checked={isOverwriting} disabled={!user || !category} onChange={(v, { checked }) => setIsOverwriting(checked)} />
                    <Divider />
                    {
                        isOverwriting ?
                            <>
                                <Form.Field style={{ marginTop: 15 }}>
                                    <label>{LocalizedStrings.SelectExistingResource}</label>
                                    <Form.Dropdown
                                        placeholder={LocalizedStrings.SelectExistingResource}
                                        fluid
                                        selection
                                        value={fileIdentToOverwrite}
                                        options={files}
                                        onChange={(_, d) => setFileIdentToOverwrite(d.value as string)}
                                        error={(triedOk && !fileIdentToOverwrite) ? { content: LocalizedStrings.Error_Generic } : undefined}
                                        disabled={!user || !category}
                                    />
                                </Form.Field>
                            </>
                            :
                            <>
                                <Form.Input name='name' disabled={!user || !category} label={LocalizedStrings.Name} value={name} onChange={(_, { value }) => setName(value)} control={Form.Input} error={(triedOk && !name) ? { content: LocalizedStrings.Error_Name } : undefined} />
                                <Form.TextArea name='manufacturer' disabled={!user || !category} label={LocalizedStrings.Manufacturer} value={manufacturer} onChange={(_, { value }) => setManufacturer(String(value))} control={Form.Input} error={(triedOk && !manufacturer) ? { content: LocalizedStrings.Error_Manufacturer } : undefined} />
                            </>
                    }
                    <Form.TextArea name='desc' disabled={!user || !category} label={LocalizedStrings.Description} value={description} onChange={(_, { value }) => setDescription(String(value))} control={Form.Input} error={(triedOk && !description) ? { content: LocalizedStrings.Error_Desc } : undefined} />

                </Form>
            </div>
        </LRModal>
    )
}