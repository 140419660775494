//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Icon, Button, Table, Form } from 'semantic-ui-react'
import LocalizedStrings from "../../localization/ResourceManager";
import LRModal from '../Basics/BasicModal';
import { globalCallbacks } from '../../util/callback';
import { BASE_UNIT_LENGTH, BASE_UNIT_NUMBER, BASE_UNIT_WEIGHT, IsRunningAsBrowser } from '../../util/defines';
import UnitInput from '../Basics/BasicUnitInput';
import LRTableHeaderCell from '../Basics/BasicTableView';
class RackTemplateDisplay extends Component 
{
  constructor(props)
  {
    super(props);
    this.state = 
    {
      racks: [],
      createOpen:false,
      
      editRackName: "",
      
      currentName: "",
      currentSizeX: 0,
      currentSizeY: 0,
      currentSizeZ: 0,
      currentWeight: 0,
      currentHasWheels: false,
      currentAllowTip: false,
      currentHECount: 0,

      onlineResources: [],
      editSelectedResource: undefined,
    };

    this.isEditing = false;
  }

  componentDidMount()
  {
    this.setUpCallbacks();
    if (globalCallbacks.updateRackTemplate) { globalCallbacks.updateRackTemplate(); }
 }
  
  render() 
  {
    return(
      <React.Fragment>
      <Table selectable striped celled>
        <Table.Header>
          <Table.Row>
            <LRTableHeaderCell>{LocalizedStrings.Name}</LRTableHeaderCell>
            <LRTableHeaderCell baseUnit={BASE_UNIT_LENGTH}>{LocalizedStrings.SizeX}</LRTableHeaderCell>
            <LRTableHeaderCell baseUnit={BASE_UNIT_LENGTH}>{LocalizedStrings.SizeY}</LRTableHeaderCell>
            <LRTableHeaderCell baseUnit={BASE_UNIT_LENGTH}>{LocalizedStrings.SizeZ}</LRTableHeaderCell>
            <LRTableHeaderCell baseUnit={BASE_UNIT_WEIGHT}>{LocalizedStrings.Weight}</LRTableHeaderCell>
            <LRTableHeaderCell>{LocalizedStrings.RackHasWheels}</LRTableHeaderCell>
            <LRTableHeaderCell>{LocalizedStrings.RackAllowTip}</LRTableHeaderCell>
            <LRTableHeaderCell>{LocalizedStrings.RackHECount}</LRTableHeaderCell>
            <LRTableHeaderCell/>
          </Table.Row>
        </Table.Header>
        {this.state.racks.map(rack => this.render_Row(rack))}
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan='9'>
              <Button
              floated='right'
              icon
              labelPosition='left'
              primary
              onClick={()=>{this.isEditing = false; this.setState({createOpen:true, currentSizeX: 0, currentSizeY: 0, currentSizeZ: 0, currentWeight: 0, currentAllowTip: false, currentHasWheels: false, currentHECount: 0})}}
              size='small'><Icon name='box' />{LocalizedStrings.AddRack}</Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>

      <LRModal  open={this.state.createOpen} 
                title={this.isEditing ? LocalizedStrings.EditRackObject : LocalizedStrings.CreateRackObject}
                onCancelClick={this.onCloseCreate}
                onOkClick={this.onCreateRackTemplate}>
        <Form>
          {!this.isEditing ? <Form.Input label={LocalizedStrings.Name} value={this.state.currentName} onChange={(e, {value}) => {this.setState({currentName: value})}}/> : null}
          <Form.Group widths='equal'>
            <UnitInput name="currentSizeX" baseUnit={BASE_UNIT_LENGTH} label={LocalizedStrings.SizeX} min={0} value={this.state.currentSizeX} onStateUpdate={(name, value) => {this.setState({[name]: value})}}/>
            <UnitInput name="currentSizeY" baseUnit={BASE_UNIT_LENGTH} label={LocalizedStrings.SizeY} min={0} value={this.state.currentSizeY} onStateUpdate={(name, value) => {this.setState({[name]: value})}}/>
            <UnitInput name="currentSizeZ" baseUnit={BASE_UNIT_LENGTH} label={LocalizedStrings.SizeZ} min={0} value={this.state.currentSizeZ} onStateUpdate={(name, value) => {this.setState({[name]: value})}}/>
          </Form.Group>
          <Form.Group widths='equal'>
            <UnitInput name="Weight" baseUnit={BASE_UNIT_WEIGHT} label={LocalizedStrings.Weight} min={0} value={this.state.currentWeight} onStateUpdate={(_, value) => {this.setState({currentWeight: value})}}/>
            <UnitInput name="createHECount" baseUnit={BASE_UNIT_NUMBER} label={LocalizedStrings.RackHECount} value={this.state.currentHECount} min={0} onStateUpdate={(_, value) => {this.setState({currentHECount: value})}} />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Checkbox label={LocalizedStrings.HasWheels} checked={this.state.currentHasWheels} onChange={(e, {checked}) => {this.setState({currentHasWheels: checked})}}/>
            <Form.Checkbox label={LocalizedStrings.AllowTip} checked={this.state.currentAllowTip} onChange={(e, {checked}) => {this.setState({currentAllowTip: checked})}}/>
          </Form.Group>
        </Form>
      </LRModal>
      </React.Fragment>
    );
  }

  onCloseCreate  = () =>
  {
    this.setState({createOpen:false, disableTo: false})
  }

  onCreateRackTemplate = () =>
  {

    let newRack = 
    {
      Name: this.state.currentName, 
      SizeX: this.state.currentSizeX, 
      SizeY: this.state.currentSizeY, 
      SizeZ: this.state.currentSizeZ,
      HasWheels: this.state.currentHasWheels,
      AllowTip: this.state.currentAllowTip,
      HECount: this.state.currentHECount
    }

    if (this.isEditing)
    {
      window.LR_SetRackTemplate(newRack).then(() => {
        if (globalCallbacks.updateRackTemplate && IsRunningAsBrowser) { globalCallbacks.updateRackTemplate() }
      })
    }
    else
    {
      window.LR_AddRackTemplate(newRack).then(() => {
        if (globalCallbacks.updateRackTemplate && IsRunningAsBrowser) { globalCallbacks.updateRackTemplate() }
      })
    }
    this.setState({createOpen: false})
  }

  onCloseEdit = () =>
  {
    this.setState({editOpen: false})
  }

  onDeleteRackTemplate = (entry) => {
    window.LR_DeleteRackTemplate({Name: entry.Name}).then(() => {
      if (globalCallbacks.updateRackTemplate && IsRunningAsBrowser) { globalCallbacks.updateRackTemplate() }
    })
  } 

  render_Row(entry) 
  {
    return(<React.Fragment>
            <Table.Row>
              <Table.Cell>
                <Icon name="delete" color="red" link textAlign="right" onClick={() => this.onDeleteRackTemplate(entry)}></Icon>
                {entry.Name}
              </Table.Cell>
              <Table.Cell>
                <UnitInput baseUnit={BASE_UNIT_LENGTH} transparent readOnly label={false} value={entry.SizeX}/>
              </Table.Cell>
              <Table.Cell>
                <UnitInput baseUnit={BASE_UNIT_LENGTH} transparent readOnly label={false} value={entry.SizeY}/>
              </Table.Cell>
              <Table.Cell>
                <UnitInput baseUnit={BASE_UNIT_LENGTH} transparent readOnly label={false} value={entry.SizeZ}/>
              </Table.Cell>
              <Table.Cell>
                <UnitInput baseUnit={BASE_UNIT_WEIGHT} transparent readOnly label={false} value={entry.Weight}/>
              </Table.Cell>
              <Table.Cell>
                {entry.HasWheels ? LocalizedStrings.Yes : LocalizedStrings.No}
              </Table.Cell>
              <Table.Cell>
                {entry.AllowTip ? LocalizedStrings.Yes : LocalizedStrings.No}
              </Table.Cell>
              <Table.Cell>
                {entry.HECount}
              </Table.Cell>
              <Table.Cell><Button fluid size="tiny" onClick={()=> this.openEdit(entry)}>{LocalizedStrings.EditRackObject}</Button></Table.Cell>
            </Table.Row>
          </React.Fragment>)
  }

  openEdit(rack)
  {
    this.isEditing = true;
    this.setState({
      createOpen: true,
      currentSizeX: rack.SizeX,
      currentSizeY: rack.SizeY,
      currentSizeZ: rack.SizeZ,
      currentWeight: rack.Weight,
      currentAllowTip: rack.AllowTip,
      currentHasWheels: rack.HasWheels,
      currentHECount: rack.HECount,
      currentName: rack.Name
    })
  }

  setUpCallbacks()
  {
    globalCallbacks.updateRackTemplate = () =>
    {
      window.LR_GetRackTemplateMap().then(res => {
        this.setState({racks: res.ResourceMap.rackTemplate})
      })
    }
  }
}

export default RackTemplateDisplay;

