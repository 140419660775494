//----------------------------------------------------------------------------------------------------------------
// Copyright DeerSoft - 2019
//----------------------------------------------------------------------------------------------------------------
import React, { Component, } from 'react';
import { Breadcrumb, Header, Loader, Menu, Dropdown, Divider, Dimmer } from 'semantic-ui-react';
import LocalizedStrings from '../../localization/StartPageProject';
import { connect } from 'react-redux';
import WebCommit from './WebCommit';
import CallBackWrapper from '../../webApp/CallBackWrapper'
import { Link } from "react-router-dom";
import { hasFetched, ClearActiveProject, FetchProject } from '../../redux/actions/fetch_actions';
import { ACTIVE_PROJECT, ACTIVE_USER } from "../../redux/redux_defines"
import UserAvatar from '../WebComponents/UserAvatar';
import MediaQuery from 'react-responsive';
import { getProjectName, RESPONSIVE_PHONE } from '../../util/defines';
import NotFound from '../NotFound/NotFound';
import { withRouter } from '../../webApp/WebRouter';
import { lrServerConnection } from "../../redux/light_right_server_connection";
import { OnLightRightCoreCallback as MainCB } from '../../util/callback';
import { OnLightRightCoreCallback as MockCB } from '../../util/mock_callback';

let OnLightRightCoreCallback
if(!process.env.JEST_WORKER_ID) { OnLightRightCoreCallback  = MainCB }
else                                         { OnLightRightCoreCallback  = MockCB } 
class StartPageProject extends Component 
{
  constructor(props)
  {
      super(props);

      lrServerConnection
      .setUser(props.match.params.username)
      .setProject(props.match.params.projectname)
      .setFile(undefined, props.match.params.branch, undefined)

      this.state = 
      {
        projectname:  lrServerConnection.__PROJECT,
        username:     lrServerConnection.__USERNAME,
        fileId:       lrServerConnection.__FILE,
        branch:       lrServerConnection.__BRANCH,
        LoadingNewProject: false,
        hasShareSecret: false
      } 

      if(window.getQuery("sharesecret")) {
        lrServerConnection.setShareSecret(window.getQuery("sharesecret"))
        this.setState({hasShareSecret: true})
      }
  }

  componentDidUpdate()
  {
    if(this.state.branch !== this.props.match.params.branch)
    {
      this.connectWithNewProject();
    }
  }

  connectWithNewProject = async() =>
  {
    let branch = await lrServerConnection.getBranchById({branch: this.props.match.params.branch})

    lrServerConnection
    .setUser(this.props.match.params.username)
    .setProject(this.props.match.params.projectname)
    .setFile(branch && branch.Branch ? branch.Branch.current : undefined, this.props.match.params.branch, undefined)
    ClearActiveProject()

    this.setState(
    {
      projectname:  lrServerConnection.__PROJECT,
      username:     lrServerConnection.__USERNAME,
      fileId:       lrServerConnection.__FILE,
      branch:       lrServerConnection.__BRANCH,
      LoadingNewProject: true
    }, () =>  
    { 
      OnLightRightCoreCallback({Command: "read-file"}) 
      this.setState({LoadingNewProject: false})
    })
  }

  render() 
  {
    FetchProject()

    let loggedInUser = this.props.loggedInUser;
    if (!hasFetched(loggedInUser) && this.state.hasShareSecret) return <Loader/>

    window.document.title = `${this.state.username}/${this.state.projectname}`
    let isLoading = ! hasFetched(this.props.active_project)
    let valid = !isLoading && this.props.active_project.data._id

    let projectName = getProjectName(this.props.active_project.data)
    
    if(isLoading)
    {
      return <Dimmer active><Loader active>{LocalizedStrings.Branches}</Loader></Dimmer> 
    }

    if(!valid)
    {
      return <NotFound/>
    }

    return (
      <MediaQuery minWidth={RESPONSIVE_PHONE}>
        {match =>
        <>
        <Breadcrumb>
          <Header>
            <UserAvatar/>
            <Breadcrumb.Section as={Link} to={`/${this.state.username}`} link>{this.props.active_project.data?.owner?.name}</Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section link>{projectName}</Breadcrumb.Section>
            {!match ?  this.renderMenuMobile(): null}
          </Header>
        </Breadcrumb>          
          {match ?  this.renderMenu(): null}
          {!match ?        <Divider/>: null}
          <CallBackWrapper>
            <Dimmer active={this.state.LoadingNewProject}>
              <Loader active={this.state.LoadingNewProject}></Loader>
            </Dimmer>
            {valid ? React.Children.map(this.props.children, (child => React.cloneElement(child, { match: this.props.match, projectname: this.state.projectname, username: this.state.username, WebApp:true, fileId: this.state.fileId  }))) : null}
          </CallBackWrapper>
        <WebCommit />
        </>}
      </MediaQuery>

    );
  }

  renderMenu =() =>
  {
    let activeWorksheet  = this.props.activeMenu === "worksheet";
    let activeChangeList = this.props.activeMenu === "changelists";;
    let activeSettings   = this.props.activeMenu === "settings";;
  
    let restrictedAccess = this.dataRestriction();

    return(<Menu pointing secondary>
            <Menu.Item active={activeWorksheet} as={Link} to={`/${this.state.username}/${this.state.projectname}/worksheet`}>{LocalizedStrings.Data}</Menu.Item>
            <Menu.Item active={activeChangeList} disabled={restrictedAccess} as={Link} to={`/${this.state.username}/${this.state.projectname}/changelists`}>{LocalizedStrings.ChangeRequest}</Menu.Item>
            <Menu.Item active={activeSettings}   disabled={restrictedAccess} as={Link} to={`/${this.state.username}/${this.state.projectname}/settings/general`}>{LocalizedStrings.Settings}</Menu.Item>
          </Menu>)
  }
  renderMenuMobile =() =>
  {
    let text = LocalizedStrings.Data
    let restrictedAccess = this.dataRestriction();

    if(this.props.activeMenu === "changelists")
    {
      text = LocalizedStrings.ChangeRequest
    }
    else if(this.props.activeMenu === "settings")
    {
      text = LocalizedStrings.Settings
    }
    
    return(
      <>
      <Dropdown inline item text={text} compact>
        <Dropdown.Menu>
            <Dropdown.Item  as={Link} to={`/${this.state.username}/${this.state.projectname}/worksheet/${this.props.projectname}/worksheet/${this.props.match.params.branch ?? ""}`}>{LocalizedStrings.Data}</Dropdown.Item>
            <Dropdown.Item  as={Link} to={`/${this.state.username}/${this.state.projectname}/changelists/${this.props.projectname}/worksheet/${this.props.match.params.branch ?? ""}`}>{LocalizedStrings.ChangeRequest}</Dropdown.Item>    
            <Dropdown.Item  as={Link} to={`/${this.state.username}/${this.state.projectname}/settings/${this.props.projectname}/worksheet/${this.props.match.params.branch ?? ""}`}>{LocalizedStrings.Settings}</Dropdown.Item>
            <Dropdown.Item  as={Link} to={`/${this.props.username}/${this.props.projectname}/worksheet/${this.props.projectname}/worksheet/${this.props.match.params.branch ?? ""}`}>{LocalizedStrings.Worksheets}</Dropdown.Item>
            <Dropdown.Item  as={Link} to={`/${this.props.username}/${this.props.projectname}/lightplot/${this.props.projectname}/worksheet/${this.props.match.params.branch ?? ""}`}>{LocalizedStrings.LightPlot}</Dropdown.Item>
            <Dropdown.Item  as={Link} to={`/${this.props.username}/${this.props.projectname}/timeline/${this.props.projectname}/worksheet/${this.props.match.params.branch ?? ""}`}>{LocalizedStrings.Timeline}</Dropdown.Item>
            <Dropdown.Item  as={Link} to={`/${this.props.username}/${this.props.projectname}/electric/${this.props.projectname}/worksheet/${this.props.match.params.branch ?? ""}`}>{LocalizedStrings.ElectricalNetwork}</Dropdown.Item>
            <Dropdown.Item  as={Link} to={`/${this.props.username}/${this.props.projectname}/data/${this.props.projectname}/worksheet/${this.props.match.params.branch ?? ""}`}>{LocalizedStrings.Data}</Dropdown.Item>
            <Dropdown.Item  as={Link} disabled={restrictedAccess} to={`/${this.props.username}/${this.props.projectname}/tasks/${this.props.projectname}/worksheet/${this.props.match.params.branch ?? ""}`}>{LocalizedStrings.Tasks}</Dropdown.Item>
            <Dropdown.Item  as={Link} disabled={restrictedAccess} to={`/${this.props.username}/${this.props.projectname}/commits/${this.props.projectname}/worksheet/${this.props.match.params.branch ?? ""}`}>{LocalizedStrings.Commits}</Dropdown.Item>
            <Dropdown.Item  as={Link} disabled={restrictedAccess} to={`/${this.props.username}/${this.props.projectname}/symbolmap/${this.props.projectname}/symbolmap`}>{LocalizedStrings.SymbolMap}</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      </>)
  }

  dataRestriction = () =>
  {
    let restrictedAccess  = false;
    let shareLinkToken    = sessionStorage.getItem("last_sharetoken")
    
    // If user is not logged in and has share token, user is restricted to access all data
    if(!this.props.loggedInUser.data && shareLinkToken)
    {
      restrictedAccess = true
    }

    if(this.props.loggedInUser.data)
    {
      if(this.props.loggedInUser.data.username === this.props.active_project.data.owner.username)
      {
        return false
      }
      // Check if it is a member of the project
      let membersOfProject = this.props.active_project.data.members;
      let groupsOfProject  = this.props.active_project.data.groups;
      let thisMemberOfProject = membersOfProject.find(member => member.user.username === this.props.loggedInUser.data.username)

      if(!Array.isArray(groupsOfProject))
      {
        groupsOfProject = []
      }
      
      // Check if it is a member of a group of a project
      for(let i of groupsOfProject)
      {
        for(let member of i.group.members)
        {
          if(i.write && member.username === this.props.loggedInUser.data.username)
          {
            return false
          }
        }
      }
      
 
      if(!(thisMemberOfProject?.write))
      {
        restrictedAccess = true
      } 
    }

    return restrictedAccess
  }
}

//---------------------------------------------------------------------
// Redux Connection
const mapStateToProps = (state) => 
{
    return {
      loggedInUser: (state[ACTIVE_USER]),
      active_project: (state[ACTIVE_PROJECT])
    };
}

export default connect(mapStateToProps)(withRouter(StartPageProject))

