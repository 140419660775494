//-----------------------------------------------------------------------------
//----- Copyright deersoft 2015 - 2018 www.deersoft.de
//-----------------------------------------------------------------------------
import React from "react"
import LRModal from "../Basics/BasicModal";
import { globalCallbacks } from "../../util/callback";
import LocalizedStrings from "../../localization/PackObjectsIntoContainer";
import { Tab, Table, Checkbox, Select } from "semantic-ui-react";

const EInventoryContainerType_TRUCK = 0
const EInventoryContainerType_RACK  = 1
const EInventoryContainerType_CASE  = 2

class PackObjectsIntoContainer extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state = {
            open: false,
            name: "",
            trucks:[],
            cases:[],
            racks:[],
            templatetrucks:[],
            templatecases:[],
            templateracks:[],
            activeTab:0
        }           
    }

    componentDidMount()
    {
        this.setUpCallbacks()
    }

    render()
    {
        let panes = 
        [
            {
                menuItem: LocalizedStrings.Trucks, render: () =>
                <Tab.Pane key='1'>
                    {this.renderEntry("trucks")}
                </Tab.Pane>
            },
            {
                menuItem: LocalizedStrings.Cases, render: () =>
                <Tab.Pane key='2'>
                    {this.renderEntry("cases")}
                </Tab.Pane>
            },
            {
                menuItem: LocalizedStrings.Racks, render: () =>
                <Tab.Pane key='3'>
                    {this.renderEntry("racks")}
                </Tab.Pane>
            },
            {
                menuItem: LocalizedStrings.NewTrucks, render: () =>
                <Tab.Pane key='4'>
                    {this.renderNewEntry("templatetrucks", "selectedTruck")}
                </Tab.Pane>
            },
            {
                menuItem: LocalizedStrings.NewCases, render: () =>
                <Tab.Pane key='5'>
                    {this.renderNewEntry("templatecases", "selectedCase")}
                </Tab.Pane>
            },
            {
                menuItem: LocalizedStrings.NewRacks, render: () =>
                <Tab.Pane key='6'>
                    {this.renderNewEntry("templateracks", "selectedRack")}
                </Tab.Pane>
            }
        ]

        return  <LRModal    open={this.state.open}
                            onOkClick={this.onConfirm}
                            onCancelClick={this.close}
                            title={LocalizedStrings.Header}>
                            <Tab menu={{ secondary: true, pointing: true }} panes={panes} activeIndex={this.state.activeTab} onTabChange={(event, {activeIndex})=>{this.setState({activeTab: activeIndex})}}></Tab>
                </LRModal>
    }

    renderEntry = (arrayName) =>
    {
        return(
            <>
             <Table>
                <Table.Header>
                <Table.HeaderCell>{LocalizedStrings.ObjectName}</Table.HeaderCell>
                <Table.HeaderCell>{LocalizedStrings.PackIntoCheckbox}</Table.HeaderCell>
                </Table.Header>
                { this.state[arrayName].map( entry =>
                <Table.Row key={entry.UUID}>
                    <Table.Cell>{entry.Name}</Table.Cell>
                    <Table.Cell>
                    <Checkbox 
                        checked={entry.UseForRack}
                        onChange={()=>{this.onCheckboxClicked(entry.UUID,arrayName)}}/>
                    </Table.Cell>
                </Table.Row>) }
            </Table>
            </>
        )
    }

    renderNewEntry = (arrayName, selection) =>
    {
        return(
            <>
            <Select fluid options={this.state[arrayName]} value={this.state[selection]} onChange={(e, {value})=>{ this.setState({[selection]: value})}}/>
            </>
        )
    }

    onCheckboxClicked = (uuid, arrayName) =>
    {
        this.setState({[arrayName]: this.state[arrayName].map(entry => { let newEntry = {...entry};  if(entry.UUID === uuid) {newEntry.UseForRack = !entry.UseForRack}; return newEntry; })})
    }

    onConfirm = () =>
    {
        this.RunPack(this.state.activeTab)
        this.close()
    }

    RunPack = (state) =>
    {
        switch(state)
        {
            case 0: this.runCommandContainer(this.state.trucks); break;
            case 1: this.runCommandContainer(this.state.cases); break;
            case 2: this.runCommandContainer(this.state.racks); break;
            case 3: this.runCommandTemplate(this.state.selectedTruck, EInventoryContainerType_TRUCK); break;
            case 4: this.runCommandTemplate(this.state.selectedCase,  EInventoryContainerType_CASE); break;
            case 5: this.runCommandTemplate(this.state.selectedRack,  EInventoryContainerType_RACK); break;
            default: break;
        }
    }

    runCommandContainer = (container) =>
    {
        let active = []
        container.forEach(element => { if(element.UseForRack) { active.push(element.UUID) }});
        window.LR_PackObjectsIntoContainer({Container: active})
    }

    runCommandTemplate = (temp, type) =>
    {
        window.LR_PackObjectsIntoContainer({Template: temp, TemplateType: type})
    }

    open = () =>
    {
        this.setState({open: true,})
    }

    close = () =>
    {
        this.setState({open: false})
    }

    setUpCallbacks()
    {
        globalCallbacks.showPackObjects = () =>
        {
            window.LR_GetTrucks().then(res => {this.setState({trucks: res.InventoryContainers})})
            window.LR_GetCases().then(res => {this.setState({cases: res.Cases})})
            window.LR_GetRacks().then(res => {this.setState({racks: res.Racks})})

            window.LR_GetTruckTemplateMap().then(res => { let newList = res.ResourceMap.truckTemplate.map(entry => {return { text: entry.Name, value: entry.Name, key: entry.Name}}); this.setState({templatetrucks: newList, selectedTruck: newList.length > 0 ? newList[0].UUID : undefined})})
            window.LR_GetCaseTemplateMap().then(res => { let newList = res.ResourceMap.caseTemplate.map(entry => {return { text: entry.Name, value: entry.Name, key: entry.Name}}); this.setState({templatecases: newList, selectedCase: newList.length > 0 ? newList[0].UUID : undefined})})

            this.open()
        }
    }
}

export default PackObjectsIntoContainer;